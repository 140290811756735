"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.italic = void 0;
var FontData_js_1 = require("../../FontData.js");
var italic_js_1 = require("../../../common/fonts/tex/italic.js");
exports.italic = FontData_js_1.AddPaths(italic_js_1.italic, {
    0x21: '330 716Q351 716 365 703T380 670V659L304 433Q230 207 227 204Q225 198 205 198Q184 198 184 207T220 439T260 669Q270 694 297 708Q300 709 304 710T311 713T316 714Q324 716 330 716ZM107 51Q110 83 133 102T179 121Q207 121 219 105T231 72Q231 45 209 23T156 0Q138 0 123 11T107 51',
    0x22: '214 620Q214 647 236 670T289 694Q312 694 326 677T341 633V624Q341 588 327 550T294 482T253 428T216 392T196 379Q191 379 184 388T176 401Q176 404 195 421T243 472T287 547Q299 576 299 582L295 580Q291 578 282 576T264 573Q241 573 228 585T214 620ZM411 620Q411 647 433 670T486 694Q509 694 523 677T538 633V624Q538 588 524 550T491 482T450 428T413 392T393 379Q388 379 381 388T373 401Q373 404 392 421T440 472T484 547Q496 576 496 582L492 580Q488 578 479 576T461 573Q438 573 425 585T411 620',
    0x23: '281 327H228Q186 327 175 330T164 347Q164 359 176 365Q179 367 292 367H404L563 688Q569 694 578 694T593 686T597 670Q597 667 531 535L448 367H614L773 688Q779 694 787 694Q796 694 802 688T808 674V672L807 670Q807 667 732 517L658 368L736 367H814Q828 357 828 347Q828 336 812 327H637L599 250Q561 174 561 173H662H724Q760 173 769 170T779 153T770 136T729 132Q721 132 696 132T651 133H540L380 -188Q374 -194 366 -194Q357 -194 351 -188T345 -174L346 -172V-170Q346 -167 412 -35L495 133H330L170 -188Q164 -194 156 -194Q147 -194 141 -188T135 -174L136 -172V-170Q136 -167 211 -17L285 133H207L130 134Q115 140 115 153Q115 164 131 173H306L344 250Q382 326 382 327H281ZM592 327H427L389 250Q351 174 351 173H516L554 250Q592 326 592 327',
    0x25: '301 348Q257 348 231 383T205 480Q205 553 244 629T346 736Q370 750 394 750Q416 750 429 742T458 714Q512 644 606 644Q722 644 802 732Q817 750 827 750Q835 750 841 744T847 730Q847 725 827 700T689 540Q586 421 512 335Q180 -50 176 -52Q172 -56 165 -56Q157 -56 151 -50T145 -35Q145 -29 162 -9T330 186Q392 258 430 302Q711 627 711 628L701 624Q652 607 604 607Q551 607 494 634L479 642V624Q479 544 439 467Q414 416 377 382T301 348ZM270 443Q270 385 303 385H306H308Q326 385 348 407Q384 441 409 504T434 627Q434 713 390 713Q358 713 328 663Q307 631 289 556T270 451V443ZM608 -56Q573 -56 543 -23T513 76Q513 129 536 190T604 296L627 318Q670 347 704 347Q747 347 767 310T788 222Q788 126 732 35T608 -56ZM742 222Q742 310 699 310Q677 310 655 285T620 227Q608 197 593 138T578 42V36Q578 -18 613 -18Q657 -18 699 64T742 222',
    0x26: '209 444Q209 546 278 631T424 716Q473 716 501 683T530 601Q530 554 511 535T467 515Q446 515 437 527T427 553Q427 578 446 594T481 610H483V617Q480 641 464 660T419 679Q367 679 328 603Q316 578 305 538T288 470L282 443L295 449Q308 455 330 462T371 469Q397 469 413 450T430 404Q430 363 400 329T331 295Q291 295 256 322Q255 322 246 293T227 223T217 158Q217 117 232 88T273 43T327 22T387 15Q457 15 512 37T599 93T652 169T680 248T688 317T678 380T659 423T647 437Q643 435 637 431T613 416T581 392T554 364T538 332Q540 310 541 310Q554 335 587 335Q601 335 613 327T626 300Q626 290 622 279T603 255T563 242Q532 245 512 263T491 320Q494 351 511 377T547 418T600 456T652 494Q676 512 697 539T728 582T738 601Q738 602 736 602Q732 602 726 604T714 616T707 638Q707 661 724 677T764 694Q799 694 802 660Q802 625 767 562T688 467L694 458Q700 449 702 444T711 428T720 408T727 385T733 358T735 327Q735 281 724 235T685 141T617 59T515 1T375 -22Q270 -22 199 34T127 181Q127 216 147 270T207 374L216 386Q209 421 209 444ZM386 412Q386 432 366 432Q345 432 325 418T294 390T284 375Q284 371 289 362T306 343T335 332Q355 332 367 350T383 384T386 412',
    0x27: '250 620Q250 647 272 670T325 694Q348 694 362 677T377 633V624Q377 566 343 506T275 412T231 379Q226 379 220 388T213 401T232 421T279 472T323 547Q335 573 335 582L331 580Q327 578 318 576T300 573Q277 573 264 585T250 620',
    0x28: '241 -250Q203 -212 174 -140T144 39Q144 158 180 288T296 544T481 746L487 750H499Q517 750 517 740Q517 736 495 716Q399 630 331 491T236 228T208 3Q208 -73 224 -130T255 -214T271 -244Q271 -250 252 -250H241',
    0x29: '326 497Q326 546 320 588T304 655T285 699T269 728T262 740Q262 746 267 749L272 750Q276 750 281 750H293Q331 712 360 640T390 461Q390 332 339 171T188 -116Q161 -150 121 -188T47 -250H35Q17 -250 17 -240Q17 -236 39 -216Q135 -130 203 9T298 272T326 497',
    0x2A: '560 658Q569 658 576 649T584 631Q584 625 583 620T577 611T569 603T556 595T540 587T519 578T494 566L428 536Q427 535 433 531T479 502Q525 475 532 469T539 450Q538 435 525 424T497 412Q489 412 482 418T442 456Q400 497 400 494L387 420Q376 353 373 343T352 323Q345 320 336 320H331Q322 320 316 327T309 343Q309 347 334 420L359 496Q358 496 297 456T234 414Q228 411 221 411Q212 411 204 417T195 439Q198 458 209 465T283 502L353 534L300 566Q255 593 247 599T239 616Q239 631 252 644T282 658Q290 658 295 654T335 615L378 573L391 647Q393 657 395 671T398 691T400 706T404 720T408 730T414 739T423 744T434 749Q435 749 439 749T445 750Q467 748 469 728Q469 723 457 685T432 610L420 573L481 613Q548 658 560 658',
    0x2B: '139 237T139 250T151 266T198 270H293H431L465 407Q469 424 476 452Q494 528 500 542T519 557Q526 557 532 552T538 538Q538 536 507 409T472 272Q472 270 604 270Q737 270 741 268Q753 261 753 250Q753 237 742 233T696 229Q687 229 655 229T599 230H462L461 226Q461 224 427 91T392 -47Q387 -57 374 -57Q367 -57 361 -51T355 -37Q355 -31 388 99L421 230H288Q267 230 238 230T199 229Q163 229 151 233',
    0x2C: '106 46Q106 68 121 90T167 120Q168 120 173 120T180 121Q232 121 232 59V54Q232 18 219 -20T186 -88T145 -143T109 -181T88 -194Q84 -194 77 -185T69 -171Q69 -168 70 -166T76 -161T85 -154T101 -139T124 -114Q146 -88 162 -58T183 -12T188 7Q187 7 183 5T172 2T156 0Q129 0 118 14T106 46',
    0x2D: '205 180H131Q102 180 93 181T84 190Q90 238 103 251H334Q341 244 341 241Q341 236 336 214T327 186Q325 181 312 181T205 180',
    0x2E: '107 50Q107 76 129 98T181 121Q203 121 217 108T231 72Q231 47 210 24T156 0Q135 0 121 13T107 50',
    0x2F: '166 -215T159 -215T147 -212T141 -204T139 -197Q139 -190 144 -183Q157 -157 378 274T602 707Q605 716 618 716Q625 716 630 712T636 703T638 696Q638 691 406 241T170 -212Q166 -215 159 -215',
    0x30: '414 665Q562 665 562 490Q562 426 534 318Q451 -21 251 -21Q222 -21 202 -15Q155 2 134 40T110 144Q110 201 127 286T187 470T287 614Q348 665 414 665ZM187 98Q187 59 208 37T260 15Q320 15 365 83Q394 128 440 312T487 547Q487 580 471 600T433 627Q428 628 408 628Q381 628 353 609T311 569Q279 526 239 364T190 143Q187 120 187 98',
    0x31: '248 491Q228 491 228 502Q228 516 236 532Q237 536 246 537T275 541T314 552Q350 567 382 595T430 644L446 664Q450 666 454 666Q468 666 468 658Q468 647 395 359Q321 63 321 59Q321 52 334 50T388 46H422Q428 37 428 35Q428 19 421 5Q416 0 405 0Q400 0 361 1T263 2Q215 2 185 2T142 1T127 0Q110 0 110 11Q110 13 113 25T118 40Q120 46 146 46Q196 46 212 49T235 61Q238 66 295 295L353 526L340 519Q328 512 302 503T248 491',
    0x32: '159 404Q159 433 176 476T222 562T297 635T395 666Q466 666 508 617T551 497Q551 473 545 446Q534 388 482 333Q441 292 355 240T264 184Q216 151 179 101L171 91Q171 90 177 90Q206 90 269 77T366 64Q385 64 390 65Q418 73 441 98T475 156Q479 168 481 170T495 173H518Q524 167 524 166T521 152Q502 86 459 32T353 -22Q315 -22 259 15T172 53Q156 53 143 36T126 1L121 -16Q119 -22 98 -22H82Q76 -16 76 -13T80 5T98 50T132 111T189 178T274 242Q327 273 364 305T420 370T447 427T460 483Q466 514 466 538Q466 586 443 607T389 629Q338 629 293 584T226 487T204 399Q204 390 204 386T209 378T222 373Q258 376 282 422T307 493Q307 506 302 517T297 531Q297 537 308 546T327 551Q329 550 333 543T340 523T344 497Q344 450 306 393T216 336Q186 336 173 355T159 396V404',
    0x33: '296 531Q296 536 307 544T322 553Q330 553 338 534T346 501Q346 468 319 440T258 412Q232 412 216 430T200 478Q200 552 281 618Q345 666 416 666Q489 666 525 625T562 530Q562 473 525 419T430 335L416 329Q479 288 479 206Q479 142 440 89T344 7T229 -22Q173 -22 135 12T96 106Q96 192 157 192Q192 192 197 157Q197 134 184 117T142 96Q153 47 180 29Q201 15 232 15Q249 15 275 22Q307 34 331 57Q363 90 379 153T396 246Q396 261 393 272T384 290T371 301T355 308T341 311T326 312H316H307Q287 312 282 313T276 320Q276 323 279 337T283 352Q284 356 290 357T325 358Q364 359 368 360Q386 365 400 372T433 397T464 448T485 527Q487 535 487 556Q487 629 414 629Q350 629 298 580T245 476Q245 450 263 450H264Q280 450 294 463T308 496Q308 508 302 518T296 531',
    0x34: '448 34Q453 34 463 22T473 5Q473 -2 457 -7Q417 -22 383 -23H366L350 -91Q348 -98 345 -111T340 -130T335 -146T330 -161T325 -172T318 -182T310 -188T299 -193T286 -194Q256 -194 253 -165Q253 -159 271 -83T292 -5Q231 29 169 29Q114 29 91 14Q72 -2 65 1Q46 20 46 28Q46 35 55 43T77 60T96 74Q306 257 396 623Q410 666 444 666Q459 666 468 657T478 634Q478 627 470 595T440 504T387 381T303 239T187 99L164 75H178Q217 75 260 59L304 43Q304 48 325 127Q342 195 346 207T358 228Q372 242 391 242Q403 242 413 235T423 214Q423 205 402 116T378 25Q378 23 387 23Q405 23 418 25T439 31T448 34',
    0x35: '196 304Q189 309 189 314Q189 317 231 487T275 660Q278 666 283 666Q287 666 302 658T346 643T413 635Q447 635 481 642T537 658T559 666Q561 666 564 663T567 658Q565 637 557 629Q528 600 474 573T359 545Q342 545 327 546T304 550T294 552L291 540Q288 529 283 507T273 465L251 379Q307 420 364 420Q415 420 456 382T497 261Q497 165 429 82T262 -20Q256 -20 247 -21T233 -22Q176 -22 141 15T106 112Q106 208 173 208Q192 208 203 197T214 169Q214 143 195 125T156 107H153V100Q155 73 174 47T239 21Q245 21 259 23Q355 46 392 200Q393 205 394 207Q412 276 412 312Q412 352 396 367T358 383Q288 383 233 314Q226 306 224 305T209 304H196',
    0x36: '377 434Q425 434 457 404T499 341T509 278Q509 243 496 194T456 105T383 27Q322 -22 256 -22Q142 -22 122 114Q120 130 120 159Q120 221 135 292T195 452T310 599Q390 665 465 665Q565 665 565 583V574Q565 543 546 524Q528 506 504 506Q491 506 478 514T465 543Q465 585 515 602Q505 626 466 626Q419 626 372 587Q334 557 305 503T266 409L255 370Q287 410 339 429Q361 434 377 434ZM424 333Q424 359 411 378T365 397Q318 397 282 356T230 257T205 157T197 94Q197 67 211 45T260 22Q313 22 341 57T386 151Q424 283 424 333',
    0x37: '466 519Q448 519 435 528T416 550T400 571T376 581Q324 581 271 540T186 437Q185 435 183 432T181 428T179 426T177 424T174 423T171 422T165 422H159Q141 422 141 423Q136 423 136 431Q136 433 190 548T247 665Q249 666 266 666H282Q288 660 288 657Q288 655 284 646T276 628L273 620Q337 666 390 666Q413 666 425 652T438 620T444 584T457 559Q460 557 470 557Q497 557 524 582T571 635T594 665Q595 666 612 666H628Q634 660 634 657Q634 653 618 629T572 556T510 441T437 269T367 43Q356 -22 304 -22Q291 -22 278 -14T263 14Q263 36 281 95T354 269T486 507Q497 524 495 524Q482 519 466 519',
    0x38: '209 449Q209 545 278 605T416 666Q482 666 517 631T553 546Q553 513 539 482T504 430T463 394T426 370L410 360L430 343Q471 309 483 278T495 211Q495 141 441 75Q363 -21 253 -21Q182 -21 141 18T99 117Q99 161 119 201T170 268T222 308T259 331L272 338L259 349Q212 389 209 449ZM492 542Q492 586 469 605T415 625Q360 625 320 587T279 505Q279 495 281 487T286 474T295 460T306 449T321 436T337 422Q379 386 380 386Q389 386 420 412T472 471Q492 513 492 542ZM163 118Q163 76 189 49T258 21Q316 21 368 64T420 170Q420 193 412 208T395 233T350 271L302 312Q298 312 284 303T249 276T209 235T177 181T163 118',
    0x39: '297 211Q258 211 230 228T189 273T169 323T163 367Q163 411 183 472T254 585Q327 656 401 665Q403 665 412 665T427 666Q458 664 481 652T518 622T539 580T550 535T553 491Q553 448 544 395T515 277T454 148T358 37Q282 -22 213 -22Q166 -22 137 -1T107 55V64Q107 88 114 104T134 127T154 136T169 138Q185 138 196 128T207 101Q207 82 196 68T172 48L161 43Q161 40 167 36T187 26T219 21Q286 21 344 99Q364 126 382 169T408 241T417 275L412 269Q406 263 395 253T370 234T337 218T297 211ZM476 552Q476 626 417 626Q368 626 330 584Q312 563 300 533T270 433Q248 341 248 312Q248 286 262 267T310 248Q353 248 387 287T440 380T467 480T476 552',
    0x3A: '184 358Q184 385 206 408T258 431Q279 431 293 418T308 383Q308 354 284 332T233 310Q212 310 198 324T184 358ZM107 50Q107 76 129 98T181 121Q203 121 217 108T231 72Q231 47 210 24T156 0Q135 0 121 13T107 50',
    0x3B: '184 358Q184 385 206 408T258 431Q279 431 293 418T308 383Q308 354 284 332T233 310Q212 310 198 324T184 358ZM107 47Q107 77 130 99T180 121Q226 121 226 61Q226 25 214 -14T182 -84T144 -140T109 -180T88 -194T77 -185T70 -172Q70 -169 84 -155T121 -112T161 -48Q180 -10 180 3Q180 4 174 2Q172 2 166 1T156 0Q135 0 121 13T107 47',
    0x3D: '776 357T776 347T761 327H470Q180 327 176 329Q164 334 164 347Q164 359 176 365Q179 367 470 367H761Q776 357 776 347ZM116 143T116 153T131 173H422Q713 173 717 171Q728 166 728 153T717 135Q713 133 422 133H131Q116 143 116 153',
    0x3F: '235 431Q217 431 206 442T195 468Q195 490 215 537T280 638T380 707Q403 716 423 716Q425 716 429 716T436 715Q485 715 518 681T551 590Q551 543 530 503T482 439Q471 428 400 375T318 310Q300 287 300 259Q300 236 315 236Q333 236 352 251T384 300Q386 306 407 306H423Q429 300 429 297Q429 272 393 235T308 198Q287 198 269 215T251 270Q251 330 293 374L374 436Q377 438 401 456T432 480T457 503T481 531T494 561T501 598Q501 614 499 626Q482 678 430 678H426Q392 678 362 660T311 615T280 571T264 540L259 528Q259 527 266 526T283 516T294 492Q294 466 276 449T235 431ZM209 51Q212 83 235 102T281 121Q309 121 321 105T333 72Q333 45 311 23T258 0Q240 0 225 11T209 51',
    0x40: '198 250Q198 155 248 91T394 26Q514 26 640 80L650 84H675H683Q709 84 709 76Q709 73 708 71Q706 64 660 45T534 8T383 -11T260 24T181 115Q152 168 152 248Q152 410 268 552Q303 590 324 608Q439 705 551 705Q611 705 658 683T733 623T775 543T789 454Q789 380 766 304T720 192Q677 125 617 125Q591 125 573 137T548 160T541 176Q541 178 540 178L534 173Q527 168 515 160T488 144T454 131T417 125Q361 125 320 166T279 284Q279 393 356 481T523 569Q570 569 603 537Q623 515 632 490L637 480L657 479Q684 479 684 470Q684 465 650 333L617 199V185Q616 162 628 162Q677 162 712 278Q743 381 743 442Q743 555 687 611T553 668Q467 668 385 608T250 450T198 250ZM598 445Q598 453 594 470T569 510T518 532Q463 532 410 448T356 271Q356 220 374 191T423 162Q482 162 552 255L575 348Q598 440 598 445',
    0x5B: '205 -221Q205 -239 194 -250H137H106Q73 -250 73 -242Q73 -232 194 255T321 747L324 750H381H417Q435 750 440 748T446 739Q446 730 443 723T437 712L434 710H350L349 706Q349 704 235 249T120 -208Q120 -210 159 -210Q166 -210 175 -210T187 -209Q205 -209 205 -221',
    0x5D: '227 721Q227 739 238 750H295H326Q359 750 359 742Q359 732 238 245T111 -247L108 -250H51H15Q-3 -250 -8 -248T-14 -239Q-14 -230 -11 -223T-5 -212L-2 -210H82L83 -206Q83 -204 197 251T312 708Q312 710 273 710Q266 710 257 710T245 709Q227 709 227 721',
    0x5E: '528 555Q528 549 514 538T496 527Q491 527 470 554Q458 569 449 580L414 625L353 578Q339 567 323 555T298 536L290 529Q286 527 285 527Q279 527 273 533T264 546L260 553Q260 559 263 562Q265 564 342 628T421 693T425 694Q430 694 433 691Q528 563 528 555',
    0x5F: '98 -62Q91 -58 91 -51Q91 -31 100 -26Q102 -25 324 -25H442H500Q536 -25 545 -27T554 -36Q554 -50 548 -56Q546 -60 538 -61Q520 -62 319 -62H98',
    0x7E: '266 208Q262 208 255 215T247 228Q247 233 250 236T274 259Q335 318 369 318Q394 318 420 292T464 265Q485 265 516 291T550 318Q554 318 562 311T571 297Q570 293 551 273T502 231T451 209H447Q421 209 396 235T355 261Q334 261 301 235T266 208',
    0x131: '75 287Q75 292 82 313T103 362T142 413T196 441H214Q248 441 270 419T293 357Q292 338 289 330T245 208Q193 72 193 46Q193 26 209 26Q228 26 247 43Q273 71 292 136Q295 148 297 150T311 153H317Q327 153 330 153T337 150T340 143Q340 133 330 105T292 41T228 -8Q220 -10 204 -10Q160 -10 141 15T122 71Q122 98 171 227T221 384Q221 396 218 400T203 405Q175 403 156 374T128 312T116 279Q115 278 97 278H81Q75 284 75 287',
    0x237: '75 284T75 287T81 305T101 343T133 389T180 426T240 442Q273 440 300 420T327 350V332L278 134Q267 92 253 37T233 -45T225 -73Q208 -123 162 -163T54 -204Q8 -204 -15 -181Q-32 -164 -32 -140Q-32 -112 -14 -96T27 -79Q48 -79 57 -91T67 -114Q67 -146 39 -166L44 -167H59H60Q112 -167 145 -74Q148 -65 198 134T251 347Q252 353 252 370Q252 382 251 388T245 399T230 405Q204 405 175 378Q157 360 145 337T126 298T117 280T98 278H81Q75 284 75 287',
    0x300: '-222 651Q-222 668 -206 682T-174 697Q-155 697 -145 680Q-140 671 -107 599T-74 526Q-74 522 -88 511T-107 500Q-109 500 -113 502T-167 568T-219 637Q-222 643 -222 651',
    0x301: '-148 500Q-154 500 -163 511T-173 528Q-173 529 -172 530V532Q-170 534 -97 610T-21 688Q-8 697 4 697Q19 697 29 688T39 663T30 638Q26 631 -50 573L-135 507Q-144 500 -148 500',
    0x302: '17 555Q17 549 3 538T-15 527Q-20 527 -41 554Q-53 569 -62 580L-97 625L-158 578Q-172 567 -188 555T-212 536L-221 529Q-225 527 -226 527Q-232 527 -238 533T-248 546L-251 553Q-251 559 -248 562Q-246 564 -169 628T-90 693T-86 694Q-81 694 -78 691Q17 563 17 555',
    0x303: '-245 558Q-249 558 -256 565T-264 578Q-264 583 -261 586T-237 609Q-176 668 -142 668Q-117 668 -91 642T-47 615Q-26 615 5 641T39 668Q43 668 51 661T60 647Q59 643 40 623T-9 581T-60 559H-64Q-90 559 -115 585T-156 611Q-177 611 -210 585T-245 558',
    0x304: '-275 544Q-282 548 -282 554Q-282 561 -279 573T-271 588Q-269 589 -111 589H-27H12Q38 589 46 587T54 578Q54 574 51 563T47 550Q45 546 32 545Q15 544 -118 544H-275',
    0x306: '-237 641Q-237 694 -218 694H-213Q-195 694 -195 684Q-195 683 -195 679T-197 667T-198 650Q-198 611 -176 589T-117 566Q-74 566 -34 597T23 678Q27 689 30 691T43 694Q62 694 62 684Q62 671 49 645T14 589T-46 537T-123 515Q-175 515 -206 550T-237 641',
    0x307: '-165 599Q-162 631 -139 650T-93 669Q-65 669 -53 653T-41 620Q-41 593 -63 571T-116 548Q-134 548 -149 559T-165 599',
    0x308: '-251 601Q-251 626 -230 647T-180 669Q-139 669 -133 625Q-133 595 -155 575T-203 554Q-223 554 -237 567T-251 601ZM-72 599Q-72 632 -48 650T-2 669Q18 669 31 657T45 623Q45 592 22 573T-25 554Q-68 554 -72 599',
    0x30A: '-199 610Q-199 654 -161 685T-79 716Q-39 716 -16 693Q3 674 3 647Q3 607 -34 575T-118 542Q-199 542 -199 610ZM-41 631T-41 655T-83 679H-89Q-129 679 -142 656Q-146 650 -151 632T-156 604Q-156 578 -113 578H-108Q-94 578 -86 579T-69 586T-52 605Q-41 631 -41 655',
    0x30B: '-217 503Q-221 503 -234 510T-248 523Q-248 528 -205 602Q-200 610 -192 623T-180 644T-170 661T-159 676T-151 686T-142 694T-134 696Q-132 697 -121 697Q-88 694 -88 664Q-88 652 -97 640T-152 574Q-214 504 -217 503ZM-64 503Q-68 503 -81 510T-95 523Q-95 528 -52 602Q-47 610 -39 623T-27 644T-17 661T-6 676T2 686T11 694T19 696Q21 697 32 697Q65 694 65 664Q65 652 56 640T1 574Q-61 504 -64 503',
    0x30C: '11 637Q16 637 22 624T29 607Q29 606 27 602Q26 600 -47 552T-125 502H-127Q-133 502 -184 553Q-236 602 -236 608Q-236 612 -224 625T-206 638L-202 637L-196 632Q-190 628 -179 620T-158 603L-116 570Q-109 572 -52 604T11 637',
    0x3DD: '477 261Q477 257 473 256T455 253T417 251T348 250H235L155 -77L146 -82Q137 -85 109 -85Q55 -85 55 -77L139 261Q224 596 226 598Q229 603 239 603Q240 603 254 603T290 603T341 604T405 605T477 605Q656 603 687 602T719 596Q719 589 692 588T513 585H319L282 427L242 272Q242 270 351 270Q388 270 410 270T444 269T460 267T469 265T477 261',
    0x2013: '98 248Q91 252 91 259Q91 279 100 284Q102 285 324 285H442H500Q536 285 545 283T554 274Q554 260 548 254Q546 250 538 249Q520 248 319 248H98',
    0x2014: '124 248Q117 252 117 259Q117 279 126 284Q128 285 579 285T1033 284Q1037 280 1037 278Q1038 276 1038 274Q1038 253 1029 250Q1026 248 575 248H124',
    0x2015: '124 248Q117 252 117 259Q117 279 126 284Q128 285 579 285T1033 284Q1037 280 1037 278Q1038 276 1038 274Q1038 253 1029 250Q1026 248 575 248H124',
    0x2017: '98 -62Q91 -58 91 -51Q91 -31 100 -26Q102 -25 324 -25H442H500Q536 -25 545 -27T554 -36Q554 -50 548 -56Q546 -60 538 -61Q520 -62 319 -62H98',
    0x2018: '249 379Q228 379 213 396T197 448Q197 533 271 627L278 635Q286 643 295 652T314 671T332 687T344 694Q349 694 355 685T362 671Q362 668 345 654T301 608T256 537Q238 493 240 491Q241 491 245 493T258 498T275 500Q296 500 311 488T326 454Q326 426 304 403T249 379',
    0x2019: '250 620Q250 647 272 670T325 694Q348 694 362 677T377 633V624Q377 566 343 506T275 412T231 379Q226 379 220 388T213 401T232 421T279 472T323 547Q335 573 335 582L331 580Q327 578 318 576T300 573Q277 573 264 585T250 620',
    0x201C: '295 379Q274 379 259 396T243 448Q243 533 317 627Q326 638 354 666T391 694Q395 694 402 686T409 673Q409 668 392 654T348 608T302 537Q284 493 286 491Q287 491 291 493T304 498T321 500Q342 500 357 488T372 454Q372 426 350 403T295 379ZM492 379Q471 379 456 396T440 448Q440 533 514 627Q523 638 551 666T588 694Q592 694 599 685T606 672T589 654T544 608T499 537Q481 493 483 491Q484 491 488 493T501 498T518 500Q539 500 554 488T569 454Q569 426 547 403T492 379',
    0x201D: '214 620Q214 647 236 670T289 694Q312 694 326 677T341 633V624Q341 588 327 550T294 482T253 428T216 392T196 379Q191 379 184 388T176 401Q176 404 195 421T243 472T287 547Q299 576 299 582L295 580Q291 578 282 576T264 573Q241 573 228 585T214 620ZM411 620Q411 647 433 670T486 694Q509 694 523 677T538 633V624Q538 588 524 550T491 482T450 428T413 392T393 379Q388 379 381 388T373 401Q373 404 392 421T440 472T484 547Q496 576 496 582L492 580Q488 578 479 576T461 573Q438 573 425 585T411 620',
    0x2044: '166 -215T159 -215T147 -212T141 -204T139 -197Q139 -190 144 -183Q157 -157 378 274T602 707Q605 716 618 716Q625 716 630 712T636 703T638 696Q638 691 406 241T170 -212Q166 -215 159 -215',
    0x210F: '150 475Q147 475 118 466T82 457Q73 457 64 467T54 487Q54 490 55 492Q63 506 64 506Q67 512 118 526Q162 541 169 546Q173 559 175 575Q181 596 181 604Q181 613 166 617Q164 617 153 618T135 619Q119 619 114 621T109 630Q109 636 114 656T122 681Q125 685 202 688Q272 695 286 695Q304 695 304 684Q304 682 291 628L278 577L386 612Q466 635 476 635T492 627T499 607Q499 593 489 586Q485 583 373 546L262 512Q262 511 248 455T233 397T236 397T244 404Q295 441 357 441Q405 441 445 417T485 333Q485 284 449 178T412 58T426 44Q447 44 466 68Q485 87 500 130L509 152H531H543Q562 152 562 144Q562 128 546 93T494 23T415 -13Q385 -13 359 3T322 44Q318 52 318 77Q318 99 352 196T386 337Q386 386 346 386Q318 386 286 370Q267 361 245 338T211 292Q207 287 193 235T162 113T138 21Q128 7 122 4Q105 -12 83 -12Q66 -12 54 -2T42 26Q42 45 98 257L151 475H150',
    0x2206: '574 715L582 716Q589 716 595 716Q612 716 616 714Q621 712 621 709Q622 707 705 359T788 8Q786 5 785 3L781 0H416Q52 0 50 2T48 6Q48 9 305 358T567 711Q572 712 574 715ZM599 346L538 602L442 474Q347 345 252 217T157 87T409 86T661 88L654 120Q646 151 629 220T599 346',
    0x29F8: '166 -215T159 -215T147 -212T141 -204T139 -197Q139 -190 144 -183Q157 -157 378 274T602 707Q605 716 618 716Q625 716 630 712T636 703T638 696Q638 691 406 241T170 -212Q166 -215 159 -215',
}, {});
