import { createStore} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storageWeb from 'redux-persist/lib/storage' 

function storee(
  state={
    store:{
    }  
  }, action) {
  switch(action.type) {
    case "update":
      return {...state, 
         
          store:action.payload.input,

        };
    default:
      return state;
  }
}

const persistConfig = {
    key: 'root',
    storage: storageWeb,
  }
  
  const persistedReducer = persistReducer(persistConfig, storee)
  
  const store = createStore(persistedReducer)
  export const persistor = persistStore(store)

  export default store
// export default createStore(store);


