"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    IEcy: '\u0415',
    IJlig: '\u0132',
    IOcy: '\u0401',
    Iacute: '\u00CD',
    Icirc: '\u00CE',
    Icy: '\u0418',
    Idot: '\u0130',
    Igrave: '\u00CC',
    Imacr: '\u012A',
    Implies: '\u21D2',
    Int: '\u222C',
    Iogon: '\u012E',
    Iota: '\u0399',
    Itilde: '\u0128',
    Iukcy: '\u0406',
    Iuml: '\u00CF',
    iacute: '\u00ED',
    ic: '\u2063',
    icirc: '\u00EE',
    icy: '\u0438',
    iecy: '\u0435',
    iexcl: '\u00A1',
    iff: '\u21D4',
    igrave: '\u00EC',
    ii: '\u2148',
    iiiint: '\u2A0C',
    iiint: '\u222D',
    iinfin: '\u29DC',
    iiota: '\u2129',
    ijlig: '\u0133',
    imacr: '\u012B',
    image: '\u2111',
    imagline: '\u2110',
    imagpart: '\u2111',
    imof: '\u22B7',
    imped: '\u01B5',
    in: '\u2208',
    incare: '\u2105',
    infintie: '\u29DD',
    inodot: '\u0131',
    int: '\u222B',
    integers: '\u2124',
    intercal: '\u22BA',
    intlarhk: '\u2A17',
    intprod: '\u2A3C',
    iocy: '\u0451',
    iogon: '\u012F',
    iprod: '\u2A3C',
    iquest: '\u00BF',
    isin: '\u2208',
    isinE: '\u22F9',
    isindot: '\u22F5',
    isins: '\u22F4',
    isinsv: '\u22F3',
    isinv: '\u2208',
    it: '\u2062',
    itilde: '\u0129',
    iukcy: '\u0456',
    iuml: '\u00EF'
}, 'i');
