"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    HARDcy: '\u042A',
    Hcirc: '\u0124',
    HilbertSpace: '\u210B',
    HorizontalLine: '\u2500',
    Hstrok: '\u0126',
    hArr: '\u21D4',
    hairsp: '\u200A',
    half: '\u00BD',
    hamilt: '\u210B',
    hardcy: '\u044A',
    harr: '\u2194',
    harrcir: '\u2948',
    hcirc: '\u0125',
    hearts: '\u2665',
    heartsuit: '\u2665',
    hercon: '\u22B9',
    hksearow: '\u2925',
    hkswarow: '\u2926',
    hoarr: '\u21FF',
    homtht: '\u223B',
    horbar: '\u2015',
    hslash: '\u210F',
    hstrok: '\u0127',
    hybull: '\u2043',
    hyphen: '\u2010'
}, 'h');
