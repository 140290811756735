"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    VDash: '\u22AB',
    Vbar: '\u2AEB',
    Vcy: '\u0412',
    Vdashl: '\u2AE6',
    Verbar: '\u2016',
    Vert: '\u2016',
    VerticalLine: '\u007C',
    VerticalSeparator: '\u2758',
    VeryThinSpace: '\u200A',
    vArr: '\u21D5',
    vBar: '\u2AE8',
    vBarv: '\u2AE9',
    vDash: '\u22A8',
    vangrt: '\u299C',
    varepsilon: '\u03F5',
    varkappa: '\u03F0',
    varnothing: '\u2205',
    varphi: '\u03D5',
    varpi: '\u03D6',
    varpropto: '\u221D',
    varr: '\u2195',
    varrho: '\u03F1',
    varsigma: '\u03C2',
    varsubsetneq: '\u228A\uFE00',
    varsubsetneqq: '\u2ACB\uFE00',
    varsupsetneq: '\u228B\uFE00',
    varsupsetneqq: '\u2ACC\uFE00',
    vartheta: '\u03D1',
    vartriangleleft: '\u22B2',
    vartriangleright: '\u22B3',
    vcy: '\u0432',
    vdash: '\u22A2',
    vee: '\u2228',
    veeeq: '\u225A',
    verbar: '\u007C',
    vert: '\u007C',
    vltri: '\u22B2',
    vnsub: '\u2282\u20D2',
    vnsup: '\u2283\u20D2',
    vprop: '\u221D',
    vrtri: '\u22B3',
    vsubnE: '\u2ACB\uFE00',
    vsubne: '\u228A\uFE00',
    vsupnE: '\u2ACC\uFE00',
    vsupne: '\u228B\uFE00',
    vzigzag: '\u299A'
}, 'v');
