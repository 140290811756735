"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sm = require("../SymbolMap.js");
var TexConstants_js_1 = require("../TexConstants.js");
var BaseMethods_js_1 = require("./BaseMethods.js");
var ParseMethods_js_1 = require("../ParseMethods.js");
var MmlNode_js_1 = require("../../../core/MmlTree/MmlNode.js");
new sm.RegExpMap('letter', ParseMethods_js_1.default.variable, /[a-z]/i);
new sm.RegExpMap('digit', ParseMethods_js_1.default.digit, /[0-9.,]/);
new sm.RegExpMap('command', ParseMethods_js_1.default.controlSequence, /^\\/);
new sm.MacroMap('special', {
    '{': 'Open',
    '}': 'Close',
    '~': 'Tilde',
    '^': 'Superscript',
    '_': 'Subscript',
    ' ': 'Space',
    '\t': 'Space',
    '\r': 'Space',
    '\n': 'Space',
    '\'': 'Prime',
    '%': 'Comment',
    '&': 'Entry',
    '#': 'Hash',
    '\u00A0': 'Space',
    '\u2019': 'Prime'
}, BaseMethods_js_1.default);
new sm.CharacterMap('mathchar0mi', ParseMethods_js_1.default.mathchar0mi, {
    alpha: '\u03B1',
    beta: '\u03B2',
    gamma: '\u03B3',
    delta: '\u03B4',
    epsilon: '\u03F5',
    zeta: '\u03B6',
    eta: '\u03B7',
    theta: '\u03B8',
    iota: '\u03B9',
    kappa: '\u03BA',
    lambda: '\u03BB',
    mu: '\u03BC',
    nu: '\u03BD',
    xi: '\u03BE',
    omicron: '\u03BF',
    pi: '\u03C0',
    rho: '\u03C1',
    sigma: '\u03C3',
    tau: '\u03C4',
    upsilon: '\u03C5',
    phi: '\u03D5',
    chi: '\u03C7',
    psi: '\u03C8',
    omega: '\u03C9',
    varepsilon: '\u03B5',
    vartheta: '\u03D1',
    varpi: '\u03D6',
    varrho: '\u03F1',
    varsigma: '\u03C2',
    varphi: '\u03C6',
    S: ['\u00A7', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    aleph: ['\u2135', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    hbar: ['\u210F', { variantForm: true }],
    imath: '\u0131',
    jmath: '\u0237',
    ell: '\u2113',
    wp: ['\u2118', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    Re: ['\u211C', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    Im: ['\u2111', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    partial: ['\u2202', { mathvariant: TexConstants_js_1.TexConstant.Variant.ITALIC }],
    infty: ['\u221E', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    prime: ['\u2032', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL,
            variantForm: true }],
    emptyset: ['\u2205', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    nabla: ['\u2207', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    top: ['\u22A4', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    bot: ['\u22A5', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    angle: ['\u2220', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    triangle: ['\u25B3', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    backslash: ['\u2216', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL,
            variantForm: true }],
    forall: ['\u2200', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    exists: ['\u2203', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    neg: ['\u00AC', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    lnot: ['\u00AC', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    flat: ['\u266D', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    natural: ['\u266E', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    sharp: ['\u266F', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    clubsuit: ['\u2663', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    diamondsuit: ['\u2662', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    heartsuit: ['\u2661', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }],
    spadesuit: ['\u2660', { mathvariant: TexConstants_js_1.TexConstant.Variant.NORMAL }]
});
new sm.CharacterMap('mathchar0mo', ParseMethods_js_1.default.mathchar0mo, {
    surd: '\u221A',
    coprod: ['\u2210', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    bigvee: ['\u22C1', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    bigwedge: ['\u22C0', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    biguplus: ['\u2A04', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    bigcap: ['\u22C2', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    bigcup: ['\u22C3', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    'int': ['\u222B', { texClass: MmlNode_js_1.TEXCLASS.OP }],
    intop: ['\u222B', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true, movablelimits: true }],
    iint: ['\u222C', { texClass: MmlNode_js_1.TEXCLASS.OP }],
    iiint: ['\u222D', { texClass: MmlNode_js_1.TEXCLASS.OP }],
    prod: ['\u220F', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    sum: ['\u2211', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    bigotimes: ['\u2A02', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    bigoplus: ['\u2A01', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    bigodot: ['\u2A00', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    oint: ['\u222E', { texClass: MmlNode_js_1.TEXCLASS.OP }],
    bigsqcup: ['\u2A06', { texClass: MmlNode_js_1.TEXCLASS.OP,
            movesupsub: true }],
    smallint: ['\u222B', { largeop: false }],
    triangleleft: '\u25C3',
    triangleright: '\u25B9',
    bigtriangleup: '\u25B3',
    bigtriangledown: '\u25BD',
    wedge: '\u2227',
    land: '\u2227',
    vee: '\u2228',
    lor: '\u2228',
    cap: '\u2229',
    cup: '\u222A',
    ddagger: '\u2021',
    dagger: '\u2020',
    sqcap: '\u2293',
    sqcup: '\u2294',
    uplus: '\u228E',
    amalg: '\u2A3F',
    diamond: '\u22C4',
    bullet: '\u2219',
    wr: '\u2240',
    div: '\u00F7',
    odot: ['\u2299', { largeop: false }],
    oslash: ['\u2298', { largeop: false }],
    otimes: ['\u2297', { largeop: false }],
    ominus: ['\u2296', { largeop: false }],
    oplus: ['\u2295', { largeop: false }],
    mp: '\u2213',
    pm: '\u00B1',
    circ: '\u2218',
    bigcirc: '\u25EF',
    setminus: '\u2216',
    cdot: '\u22C5',
    ast: '\u2217',
    times: '\u00D7',
    star: '\u22C6',
    propto: '\u221D',
    sqsubseteq: '\u2291',
    sqsupseteq: '\u2292',
    parallel: '\u2225',
    mid: '\u2223',
    dashv: '\u22A3',
    vdash: '\u22A2',
    leq: '\u2264',
    le: '\u2264',
    geq: '\u2265',
    ge: '\u2265',
    lt: '\u003C',
    gt: '\u003E',
    succ: '\u227B',
    prec: '\u227A',
    approx: '\u2248',
    succeq: '\u2AB0',
    preceq: '\u2AAF',
    supset: '\u2283',
    subset: '\u2282',
    supseteq: '\u2287',
    subseteq: '\u2286',
    'in': '\u2208',
    ni: '\u220B',
    notin: '\u2209',
    owns: '\u220B',
    gg: '\u226B',
    ll: '\u226A',
    sim: '\u223C',
    simeq: '\u2243',
    perp: '\u22A5',
    equiv: '\u2261',
    asymp: '\u224D',
    smile: '\u2323',
    frown: '\u2322',
    ne: '\u2260',
    neq: '\u2260',
    cong: '\u2245',
    doteq: '\u2250',
    bowtie: '\u22C8',
    models: '\u22A8',
    notChar: '\u29F8',
    Leftrightarrow: '\u21D4',
    Leftarrow: '\u21D0',
    Rightarrow: '\u21D2',
    leftrightarrow: '\u2194',
    leftarrow: '\u2190',
    gets: '\u2190',
    rightarrow: '\u2192',
    to: ['\u2192', { accent: false }],
    mapsto: '\u21A6',
    leftharpoonup: '\u21BC',
    leftharpoondown: '\u21BD',
    rightharpoonup: '\u21C0',
    rightharpoondown: '\u21C1',
    nearrow: '\u2197',
    searrow: '\u2198',
    nwarrow: '\u2196',
    swarrow: '\u2199',
    rightleftharpoons: '\u21CC',
    hookrightarrow: '\u21AA',
    hookleftarrow: '\u21A9',
    longleftarrow: '\u27F5',
    Longleftarrow: '\u27F8',
    longrightarrow: '\u27F6',
    Longrightarrow: '\u27F9',
    Longleftrightarrow: '\u27FA',
    longleftrightarrow: '\u27F7',
    longmapsto: '\u27FC',
    ldots: '\u2026',
    cdots: '\u22EF',
    vdots: '\u22EE',
    ddots: '\u22F1',
    dotsc: '\u2026',
    dotsb: '\u22EF',
    dotsm: '\u22EF',
    dotsi: '\u22EF',
    dotso: '\u2026',
    ldotp: ['\u002E', { texClass: MmlNode_js_1.TEXCLASS.PUNCT }],
    cdotp: ['\u22C5', { texClass: MmlNode_js_1.TEXCLASS.PUNCT }],
    colon: ['\u003A', { texClass: MmlNode_js_1.TEXCLASS.PUNCT }]
});
new sm.CharacterMap('mathchar7', ParseMethods_js_1.default.mathchar7, {
    Gamma: '\u0393',
    Delta: '\u0394',
    Theta: '\u0398',
    Lambda: '\u039B',
    Xi: '\u039E',
    Pi: '\u03A0',
    Sigma: '\u03A3',
    Upsilon: '\u03A5',
    Phi: '\u03A6',
    Psi: '\u03A8',
    Omega: '\u03A9',
    '_': '\u005F',
    '#': '\u0023',
    '$': '\u0024',
    '%': '\u0025',
    '&': '\u0026',
    And: '\u0026'
});
new sm.DelimiterMap('delimiter', ParseMethods_js_1.default.delimiter, {
    '(': '(',
    ')': ')',
    '[': '[',
    ']': ']',
    '<': '\u27E8',
    '>': '\u27E9',
    '\\lt': '\u27E8',
    '\\gt': '\u27E9',
    '/': '/',
    '|': ['|', { texClass: MmlNode_js_1.TEXCLASS.ORD }],
    '.': '',
    '\\\\': '\\',
    '\\lmoustache': '\u23B0',
    '\\rmoustache': '\u23B1',
    '\\lgroup': '\u27EE',
    '\\rgroup': '\u27EF',
    '\\arrowvert': '\u23D0',
    '\\Arrowvert': '\u2016',
    '\\bracevert': '\u23AA',
    '\\Vert': ['\u2225', { texClass: MmlNode_js_1.TEXCLASS.ORD }],
    '\\|': ['\u2225', { texClass: MmlNode_js_1.TEXCLASS.ORD }],
    '\\vert': ['|', { texClass: MmlNode_js_1.TEXCLASS.ORD }],
    '\\uparrow': '\u2191',
    '\\downarrow': '\u2193',
    '\\updownarrow': '\u2195',
    '\\Uparrow': '\u21D1',
    '\\Downarrow': '\u21D3',
    '\\Updownarrow': '\u21D5',
    '\\backslash': '\\',
    '\\rangle': '\u27E9',
    '\\langle': '\u27E8',
    '\\rbrace': '}',
    '\\lbrace': '{',
    '\\}': '}',
    '\\{': '{',
    '\\rceil': '\u2309',
    '\\lceil': '\u2308',
    '\\rfloor': '\u230B',
    '\\lfloor': '\u230A',
    '\\lbrack': '[',
    '\\rbrack': ']'
});
new sm.CommandMap('macros', {
    displaystyle: ['SetStyle', 'D', true, 0],
    textstyle: ['SetStyle', 'T', false, 0],
    scriptstyle: ['SetStyle', 'S', false, 1],
    scriptscriptstyle: ['SetStyle', 'SS', false, 2],
    rm: ['SetFont', TexConstants_js_1.TexConstant.Variant.NORMAL],
    mit: ['SetFont', TexConstants_js_1.TexConstant.Variant.ITALIC],
    oldstyle: ['SetFont', TexConstants_js_1.TexConstant.Variant.OLDSTYLE],
    cal: ['SetFont', TexConstants_js_1.TexConstant.Variant.CALLIGRAPHIC],
    it: ['SetFont', '-tex-mathit'],
    bf: ['SetFont', TexConstants_js_1.TexConstant.Variant.BOLD],
    bbFont: ['SetFont', TexConstants_js_1.TexConstant.Variant.DOUBLESTRUCK],
    scr: ['SetFont', TexConstants_js_1.TexConstant.Variant.SCRIPT],
    frak: ['SetFont', TexConstants_js_1.TexConstant.Variant.FRAKTUR],
    sf: ['SetFont', TexConstants_js_1.TexConstant.Variant.SANSSERIF],
    tt: ['SetFont', TexConstants_js_1.TexConstant.Variant.MONOSPACE],
    tiny: ['SetSize', 0.5],
    Tiny: ['SetSize', 0.6],
    scriptsize: ['SetSize', 0.7],
    small: ['SetSize', 0.85],
    normalsize: ['SetSize', 1.0],
    large: ['SetSize', 1.2],
    Large: ['SetSize', 1.44],
    LARGE: ['SetSize', 1.73],
    huge: ['SetSize', 2.07],
    Huge: ['SetSize', 2.49],
    arcsin: ['NamedFn'],
    arccos: ['NamedFn'],
    arctan: ['NamedFn'],
    arg: ['NamedFn'],
    cos: ['NamedFn'],
    cosh: ['NamedFn'],
    cot: ['NamedFn'],
    coth: ['NamedFn'],
    csc: ['NamedFn'],
    deg: ['NamedFn'],
    det: 'NamedOp',
    dim: ['NamedFn'],
    exp: ['NamedFn'],
    gcd: 'NamedOp',
    hom: ['NamedFn'],
    inf: 'NamedOp',
    ker: ['NamedFn'],
    lg: ['NamedFn'],
    lim: 'NamedOp',
    liminf: ['NamedOp', 'lim&thinsp;inf'],
    limsup: ['NamedOp', 'lim&thinsp;sup'],
    ln: ['NamedFn'],
    log: ['NamedFn'],
    max: 'NamedOp',
    min: 'NamedOp',
    Pr: 'NamedOp',
    sec: ['NamedFn'],
    sin: ['NamedFn'],
    sinh: ['NamedFn'],
    sup: 'NamedOp',
    tan: ['NamedFn'],
    tanh: ['NamedFn'],
    limits: ['Limits', 1],
    nolimits: ['Limits', 0],
    overline: ['UnderOver', '00AF', null, 1],
    underline: ['UnderOver', '005F'],
    overbrace: ['UnderOver', '23DE', 1],
    underbrace: ['UnderOver', '23DF', 1],
    overparen: ['UnderOver', '23DC'],
    underparen: ['UnderOver', '23DD'],
    overrightarrow: ['UnderOver', '2192'],
    underrightarrow: ['UnderOver', '2192'],
    overleftarrow: ['UnderOver', '2190'],
    underleftarrow: ['UnderOver', '2190'],
    overleftrightarrow: ['UnderOver', '2194'],
    underleftrightarrow: ['UnderOver', '2194'],
    overset: 'Overset',
    underset: 'Underset',
    stackrel: ['Macro', '\\mathrel{\\mathop{#2}\\limits^{#1}}', 2],
    over: 'Over',
    overwithdelims: 'Over',
    atop: 'Over',
    atopwithdelims: 'Over',
    above: 'Over',
    abovewithdelims: 'Over',
    brace: ['Over', '{', '}'],
    brack: ['Over', '[', ']'],
    choose: ['Over', '(', ')'],
    frac: 'Frac',
    sqrt: 'Sqrt',
    root: 'Root',
    uproot: ['MoveRoot', 'upRoot'],
    leftroot: ['MoveRoot', 'leftRoot'],
    left: 'LeftRight',
    right: 'LeftRight',
    middle: 'Middle',
    llap: 'Lap',
    rlap: 'Lap',
    raise: 'RaiseLower',
    lower: 'RaiseLower',
    moveleft: 'MoveLeftRight',
    moveright: 'MoveLeftRight',
    ',': ['Spacer', TexConstants_js_1.TexConstant.Length.THINMATHSPACE],
    ':': ['Spacer', TexConstants_js_1.TexConstant.Length.MEDIUMMATHSPACE],
    '>': ['Spacer', TexConstants_js_1.TexConstant.Length.MEDIUMMATHSPACE],
    ';': ['Spacer', TexConstants_js_1.TexConstant.Length.THICKMATHSPACE],
    '!': ['Spacer', TexConstants_js_1.TexConstant.Length.NEGATIVETHINMATHSPACE],
    enspace: ['Spacer', '.5em'],
    quad: ['Spacer', '1em'],
    qquad: ['Spacer', '2em'],
    thinspace: ['Spacer', TexConstants_js_1.TexConstant.Length.THINMATHSPACE],
    negthinspace: ['Spacer', TexConstants_js_1.TexConstant.Length.NEGATIVETHINMATHSPACE],
    hskip: 'Hskip',
    hspace: 'Hskip',
    kern: 'Hskip',
    mskip: 'Hskip',
    mspace: 'Hskip',
    mkern: 'Hskip',
    rule: 'rule',
    Rule: ['Rule'],
    Space: ['Rule', 'blank'],
    big: ['MakeBig', MmlNode_js_1.TEXCLASS.ORD, 0.85],
    Big: ['MakeBig', MmlNode_js_1.TEXCLASS.ORD, 1.15],
    bigg: ['MakeBig', MmlNode_js_1.TEXCLASS.ORD, 1.45],
    Bigg: ['MakeBig', MmlNode_js_1.TEXCLASS.ORD, 1.75],
    bigl: ['MakeBig', MmlNode_js_1.TEXCLASS.OPEN, 0.85],
    Bigl: ['MakeBig', MmlNode_js_1.TEXCLASS.OPEN, 1.15],
    biggl: ['MakeBig', MmlNode_js_1.TEXCLASS.OPEN, 1.45],
    Biggl: ['MakeBig', MmlNode_js_1.TEXCLASS.OPEN, 1.75],
    bigr: ['MakeBig', MmlNode_js_1.TEXCLASS.CLOSE, 0.85],
    Bigr: ['MakeBig', MmlNode_js_1.TEXCLASS.CLOSE, 1.15],
    biggr: ['MakeBig', MmlNode_js_1.TEXCLASS.CLOSE, 1.45],
    Biggr: ['MakeBig', MmlNode_js_1.TEXCLASS.CLOSE, 1.75],
    bigm: ['MakeBig', MmlNode_js_1.TEXCLASS.REL, 0.85],
    Bigm: ['MakeBig', MmlNode_js_1.TEXCLASS.REL, 1.15],
    biggm: ['MakeBig', MmlNode_js_1.TEXCLASS.REL, 1.45],
    Biggm: ['MakeBig', MmlNode_js_1.TEXCLASS.REL, 1.75],
    mathord: ['TeXAtom', MmlNode_js_1.TEXCLASS.ORD],
    mathop: ['TeXAtom', MmlNode_js_1.TEXCLASS.OP],
    mathopen: ['TeXAtom', MmlNode_js_1.TEXCLASS.OPEN],
    mathclose: ['TeXAtom', MmlNode_js_1.TEXCLASS.CLOSE],
    mathbin: ['TeXAtom', MmlNode_js_1.TEXCLASS.BIN],
    mathrel: ['TeXAtom', MmlNode_js_1.TEXCLASS.REL],
    mathpunct: ['TeXAtom', MmlNode_js_1.TEXCLASS.PUNCT],
    mathinner: ['TeXAtom', MmlNode_js_1.TEXCLASS.INNER],
    vcenter: ['TeXAtom', MmlNode_js_1.TEXCLASS.VCENTER],
    buildrel: 'BuildRel',
    hbox: ['HBox', 0],
    text: 'HBox',
    mbox: ['HBox', 0],
    fbox: 'FBox',
    strut: 'Strut',
    mathstrut: ['Macro', '\\vphantom{(}'],
    phantom: 'Phantom',
    vphantom: ['Phantom', 1, 0],
    hphantom: ['Phantom', 0, 1],
    smash: 'Smash',
    acute: ['Accent', '00B4'],
    grave: ['Accent', '0060'],
    ddot: ['Accent', '00A8'],
    tilde: ['Accent', '007E'],
    bar: ['Accent', '00AF'],
    breve: ['Accent', '02D8'],
    check: ['Accent', '02C7'],
    hat: ['Accent', '005E'],
    vec: ['Accent', '2192'],
    dot: ['Accent', '02D9'],
    widetilde: ['Accent', '007E', 1],
    widehat: ['Accent', '005E', 1],
    matrix: 'Matrix',
    array: 'Matrix',
    pmatrix: ['Matrix', '(', ')'],
    cases: ['Matrix', '{', '', 'left left', null, '.1em', null,
        true],
    eqalign: ['Matrix', null, null, 'right left',
        TexConstants_js_1.TexConstant.Length.THICKMATHSPACE, '.5em', 'D'],
    displaylines: ['Matrix', null, null, 'center', null, '.5em', 'D'],
    cr: 'Cr',
    '\\': 'CrLaTeX',
    newline: ['CrLaTeX', true],
    hline: ['HLine', 'solid'],
    hdashline: ['HLine', 'dashed'],
    eqalignno: ['Matrix', null, null, 'right left',
        TexConstants_js_1.TexConstant.Length.THICKMATHSPACE, '.5em', 'D', null,
        'right'],
    leqalignno: ['Matrix', null, null, 'right left',
        TexConstants_js_1.TexConstant.Length.THICKMATHSPACE, '.5em', 'D', null,
        'left'],
    hfill: 'HFill',
    hfil: 'HFill',
    hfilll: 'HFill',
    bmod: ['Macro', '\\mmlToken{mo}[lspace="thickmathspace"' +
            ' rspace="thickmathspace"]{mod}'],
    pmod: ['Macro', '\\pod{\\mmlToken{mi}{mod}\\kern 6mu #1}', 1],
    mod: ['Macro', '\\mathchoice{\\kern18mu}{\\kern12mu}' +
            '{\\kern12mu}{\\kern12mu}\\mmlToken{mi}{mod}\\,\\,#1',
        1],
    pod: ['Macro', '\\mathchoice{\\kern18mu}{\\kern8mu}' +
            '{\\kern8mu}{\\kern8mu}(#1)', 1],
    iff: ['Macro', '\\;\\Longleftrightarrow\\;'],
    skew: ['Macro', '{{#2{#3\\mkern#1mu}\\mkern-#1mu}{}}', 3],
    mathcal: ['Macro', '{\\cal #1}', 1],
    mathscr: ['Macro', '{\\scr #1}', 1],
    mathrm: ['Macro', '{\\rm #1}', 1],
    mathbf: ['Macro', '{\\bf #1}', 1],
    mathbb: ['Macro', '{\\bbFont #1}', 1],
    Bbb: ['Macro', '{\\bbFont #1}', 1],
    mathit: ['Macro', '{\\it #1}', 1],
    mathfrak: ['Macro', '{\\frak #1}', 1],
    mathsf: ['Macro', '{\\sf #1}', 1],
    mathtt: ['Macro', '{\\tt #1}', 1],
    textrm: ['HBox', null, TexConstants_js_1.TexConstant.Variant.NORMAL],
    textit: ['HBox', null, TexConstants_js_1.TexConstant.Variant.ITALIC],
    textbf: ['HBox', null, TexConstants_js_1.TexConstant.Variant.BOLD],
    textsf: ['HBox', null, TexConstants_js_1.TexConstant.Variant.SANSSERIF],
    texttt: ['HBox', null, TexConstants_js_1.TexConstant.Variant.MONOSPACE],
    pmb: ['Macro', '\\rlap{#1}\\kern1px{#1}', 1],
    TeX: ['Macro', 'T\\kern-.14em\\lower.5ex{E}\\kern-.115em X'],
    LaTeX: ['Macro', 'L\\kern-.325em\\raise.21em' +
            '{\\scriptstyle{A}}\\kern-.17em\\TeX'],
    ' ': ['Macro', '\\text{ }'],
    not: 'Not',
    dots: 'Dots',
    space: 'Tilde',
    '\u00A0': 'Tilde',
    begin: 'BeginEnd',
    end: 'BeginEnd',
    label: 'HandleLabel',
    ref: 'HandleRef',
    nonumber: 'HandleNoTag',
    mathchoice: 'MathChoice',
    mmlToken: 'MmlToken'
}, BaseMethods_js_1.default);
new sm.EnvironmentMap('environment', ParseMethods_js_1.default.environment, {
    array: ['AlignedArray'],
    equation: ['Equation', null, true],
    'equation*': ['Equation', null, false],
    eqnarray: ['EqnArray', null, true, true, 'rcl',
        '0 ' + TexConstants_js_1.TexConstant.Length.THICKMATHSPACE, '.5em']
}, BaseMethods_js_1.default);
new sm.CharacterMap('not_remap', null, {
    '\u2190': '\u219A',
    '\u2192': '\u219B',
    '\u2194': '\u21AE',
    '\u21D0': '\u21CD',
    '\u21D2': '\u21CF',
    '\u21D4': '\u21CE',
    '\u2208': '\u2209',
    '\u220B': '\u220C',
    '\u2223': '\u2224',
    '\u2225': '\u2226',
    '\u223C': '\u2241',
    '\u007E': '\u2241',
    '\u2243': '\u2244',
    '\u2245': '\u2247',
    '\u2248': '\u2249',
    '\u224D': '\u226D',
    '\u003D': '\u2260',
    '\u2261': '\u2262',
    '\u003C': '\u226E',
    '\u003E': '\u226F',
    '\u2264': '\u2270',
    '\u2265': '\u2271',
    '\u2272': '\u2274',
    '\u2273': '\u2275',
    '\u2276': '\u2278',
    '\u2277': '\u2279',
    '\u227A': '\u2280',
    '\u227B': '\u2281',
    '\u2282': '\u2284',
    '\u2283': '\u2285',
    '\u2286': '\u2288',
    '\u2287': '\u2289',
    '\u22A2': '\u22AC',
    '\u22A8': '\u22AD',
    '\u22A9': '\u22AE',
    '\u22AB': '\u22AF',
    '\u227C': '\u22E0',
    '\u227D': '\u22E1',
    '\u2291': '\u22E2',
    '\u2292': '\u22E3',
    '\u22B2': '\u22EA',
    '\u22B3': '\u22EB',
    '\u22B4': '\u22EC',
    '\u22B5': '\u22ED',
    '\u2203': '\u2204'
});
