"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Fcy: '\u0424',
    FilledSmallSquare: '\u25FC',
    Fouriertrf: '\u2131',
    fallingdotseq: '\u2252',
    fcy: '\u0444',
    female: '\u2640',
    ffilig: '\uFB03',
    fflig: '\uFB00',
    ffllig: '\uFB04',
    filig: '\uFB01',
    fjlig: '\u0066\u006A',
    fllig: '\uFB02',
    fltns: '\u25B1',
    fnof: '\u0192',
    forall: '\u2200',
    forkv: '\u2AD9',
    fpartint: '\u2A0D',
    frac12: '\u00BD',
    frac13: '\u2153',
    frac14: '\u00BC',
    frac15: '\u2155',
    frac16: '\u2159',
    frac18: '\u215B',
    frac23: '\u2154',
    frac25: '\u2156',
    frac34: '\u00BE',
    frac35: '\u2157',
    frac38: '\u215C',
    frac45: '\u2158',
    frac56: '\u215A',
    frac58: '\u215D',
    frac78: '\u215E',
    frasl: '\u2044'
}, 'f');
