"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.smallop = void 0;
var FontData_js_1 = require("../../FontData.js");
var smallop_js_1 = require("../../../common/fonts/tex/smallop.js");
exports.smallop = FontData_js_1.AddPaths(smallop_js_1.smallop, {
    0x28: '152 251Q152 646 388 850H416Q422 844 422 841Q422 837 403 816T357 753T302 649T255 482T236 250Q236 124 255 19T301 -147T356 -251T403 -315T422 -340Q422 -343 416 -349H388Q359 -325 332 -296T271 -213T212 -97T170 56T152 251',
    0x29: '305 251Q305 -145 69 -349H56Q43 -349 39 -347T35 -338Q37 -333 60 -307T108 -239T160 -136T204 27T221 250T204 473T160 636T108 740T60 807T35 839Q35 850 50 850H56H69Q197 743 256 566Q305 425 305 251',
    0x2F: '481 838Q489 850 500 850Q508 850 515 844T522 827Q521 824 311 248T96 -337Q90 -349 77 -349Q68 -349 62 -343T55 -326Q56 -323 266 253T481 838',
    0x5B: '202 -349V850H394V810H242V-309H394V-349H202',
    0x5C: '522 -326Q522 -337 515 -343T500 -349Q487 -349 481 -337Q477 -328 267 248T55 827Q54 835 60 842T76 850Q89 850 96 838Q100 829 310 253T522 -326',
    0x5D: '22 810V850H214V-349H22V-309H174V810H22',
    0x7B: '477 -343L471 -349H458Q432 -349 367 -325T273 -263Q258 -245 250 -212L249 -51Q249 -27 249 12Q248 118 244 128Q243 129 243 130Q220 189 121 228Q109 232 107 235T105 250Q105 256 105 257T105 261T107 265T111 268T118 272T128 276T142 283T162 291Q224 324 243 371Q243 372 244 373Q248 384 249 469Q249 475 249 489Q249 528 249 552L250 714Q253 728 256 736T271 761T299 789T347 816T422 843Q440 849 441 849H443Q445 849 447 849T452 850T457 850H471L477 844V830Q477 820 476 817T470 811T459 807T437 801T404 785Q353 760 338 724Q333 710 333 550Q333 526 333 492T334 447Q334 393 327 368T295 318Q257 280 181 255L169 251L184 245Q318 198 332 112Q333 106 333 -49Q333 -209 338 -223Q351 -255 391 -277T469 -309Q477 -311 477 -329V-343',
    0x7D: '110 849L115 850Q120 850 125 850Q151 850 215 826T309 764Q324 747 332 714L333 552Q333 528 333 489Q334 383 338 373Q339 372 339 371Q353 336 391 310T469 271Q477 268 477 251Q477 241 476 237T472 232T456 225T428 214Q357 179 339 130Q339 129 338 128Q334 117 333 32Q333 26 333 12Q333 -27 333 -51L332 -212Q328 -228 323 -240T302 -271T255 -307T175 -338Q139 -349 125 -349T108 -346T105 -329Q105 -314 107 -312T130 -304Q233 -271 248 -209Q249 -203 249 -49V57Q249 106 253 125T273 167Q307 213 398 245L413 251L401 255Q265 300 250 389Q249 395 249 550Q249 710 244 724Q224 774 112 811Q105 813 105 830Q105 845 110 849',
    0x2C6: '279 669Q273 669 142 610T9 551L0 569Q-8 585 -8 587Q-8 588 -7 588L12 598Q30 608 66 628T136 666L277 744L564 587L555 569Q549 556 547 554T544 552Q539 555 410 612T279 669',
    0x2DC: '374 597Q337 597 269 627T160 658Q101 658 34 606L24 597L12 611Q1 624 1 626Q1 627 27 648T55 671Q120 722 182 722Q219 722 286 692T395 661Q454 661 521 713L531 722L543 708Q554 695 554 693Q554 692 528 671T500 648Q434 597 374 597',
    0x302: '-277 669Q-283 669 -414 610T-547 551L-556 569Q-564 585 -564 587Q-564 588 -563 588L-544 598Q-526 608 -490 628T-420 666L-279 744L8 587L-1 569Q-7 556 -9 554T-12 552Q-17 555 -146 612T-277 669',
    0x303: '-182 597Q-219 597 -287 627T-396 658Q-455 658 -522 606L-532 597L-544 611Q-555 624 -555 626Q-555 627 -529 648T-501 671Q-436 722 -374 722Q-337 722 -270 692T-161 661Q-102 661 -35 713L-25 722L-13 708Q-2 695 -2 693Q-2 692 -28 671T-56 648Q-122 597 -182 597',
    0x2016: '257 0V602H300V0H257ZM478 0V602H521V0H478',
    0x2044: '481 838Q489 850 500 850Q508 850 515 844T522 827Q521 824 311 248T96 -337Q90 -349 77 -349Q68 -349 62 -343T55 -326Q56 -323 266 253T481 838',
    0x2191: '112 421L120 424Q127 427 136 430T161 441T191 458T224 481T260 510T295 546T328 591L333 600L340 589Q380 527 431 489T555 421V377L543 381Q445 418 368 492L355 504V0H312V504L299 492Q222 418 124 381L112 377V421',
    0x2193: '312 96V600H355V96L368 108Q445 182 543 219L555 223V179L546 176Q538 173 529 169T505 158T475 141T442 119T407 90T372 53T339 9L334 0L327 11Q287 73 236 111T112 179V223L124 219Q222 182 299 108L312 96',
    0x21D1: '142 329Q300 419 389 599Q389 598 399 579T420 541T452 494T497 438T558 383T636 329T708 294L721 289V246Q718 246 694 256T623 293T532 356L522 364L521 182V0H478V405L466 417Q436 450 389 516Q388 515 378 500T352 463T312 417L300 405V0H257V364L247 356Q202 320 155 293T82 256L57 246V289L70 294Q101 305 142 329',
    0x21D3: '257 236V600H300V195L312 183Q342 150 389 84Q390 85 400 100T426 137T466 183L478 195V600H521V418L522 236L532 244Q576 280 623 307T696 344L721 354V311L708 306Q677 295 636 271Q478 181 389 1Q389 2 379 21T358 59T326 106T281 162T220 217T142 271T70 306L57 311V354Q60 354 83 345T154 308T247 244L257 236',
    0x220F: '158 656Q147 684 131 694Q110 707 69 710H55V750H888V710H874Q840 708 820 698T795 678T786 656V-155Q798 -206 874 -210H888V-250H570V-210H584Q618 -208 638 -197T663 -178T673 -155V710H270V277L271 -155Q283 -206 359 -210H373V-250H55V-210H69Q103 -208 123 -197T148 -178T158 -155V656',
    0x2210: '158 656Q147 684 131 694Q110 707 69 710H55V750H373V710H359Q325 708 305 698T280 678T271 656L270 223V-210H673V656Q666 672 663 679T639 697T584 710H570V750H888V710H874Q840 708 820 698T795 678T786 656V-155Q798 -206 874 -210H888V-250H55V-210H69Q103 -208 123 -197T148 -178T158 -155V656',
    0x2211: '61 748Q64 750 489 750H913L954 640Q965 609 976 579T993 533T999 516H979L959 517Q936 579 886 621T777 682Q724 700 655 705T436 710H319Q183 710 183 709Q186 706 348 484T511 259Q517 250 513 244L490 216Q466 188 420 134T330 27L149 -187Q149 -188 362 -188Q388 -188 436 -188T506 -189Q679 -189 778 -162T936 -43Q946 -27 959 6H999L913 -249L489 -250Q65 -250 62 -248Q56 -246 56 -239Q56 -234 118 -161Q186 -81 245 -11L428 206Q428 207 242 462L57 717L56 728Q56 744 61 748',
    0x221A: '263 249Q264 249 315 130T417 -108T470 -228L725 302Q981 837 982 839Q989 850 1001 850Q1008 850 1013 844T1020 832V826L741 243Q645 43 540 -176Q479 -303 469 -324T453 -348Q449 -350 436 -350L424 -349L315 -96Q206 156 205 156L171 130Q138 104 137 104L111 130L263 249',
    0x2223: '146 612Q151 627 166 627Q182 627 187 612Q188 610 188 306T187 0Q184 -15 166 -15Q149 -15 146 0V10Q146 19 146 35T146 73T146 122T145 179T145 241T145 306T145 370T145 433T145 489T146 538T146 576T146 602V612',
    0x2225: '146 612Q151 627 166 627Q182 627 187 612Q188 610 188 306T187 0Q184 -15 166 -15Q149 -15 146 0V10Q146 19 146 35T146 73T146 122T145 179T145 241T145 306T145 370T145 433T145 489T146 538T146 576T146 602V612ZM368 612Q373 627 388 627Q404 627 409 612Q410 610 410 306T409 0Q406 -15 389 -15Q371 -15 368 0V10Q368 19 368 35T368 73T368 122T367 179T367 241T367 306T367 370T367 433T367 489T368 538T368 576T368 602V612',
    0x222B: '113 -244Q113 -246 119 -251T139 -263T167 -269Q186 -269 199 -260Q220 -247 232 -218T251 -133T262 -15T276 155T297 367Q300 390 305 438T314 512T325 580T340 647T361 703T390 751T428 784T479 804Q481 804 488 804T501 805Q552 802 581 769T610 695Q610 669 594 657T561 645Q542 645 527 658T512 694Q512 705 516 714T526 729T538 737T548 742L552 743Q552 745 545 751T525 762T498 768Q475 768 460 756T434 716T418 652T407 559T398 444T387 300T369 133Q349 -38 337 -102T303 -207Q256 -306 169 -306Q119 -306 87 -272T55 -196Q55 -170 71 -158T104 -146Q123 -146 138 -159T153 -195Q153 -206 149 -215T139 -230T127 -238T117 -242L113 -244',
    0x222C: '113 -244Q113 -246 119 -251T139 -263T167 -269Q186 -269 199 -260Q220 -247 232 -218T251 -133T262 -15T276 155T297 367Q300 390 305 438T314 512T325 580T340 647T361 703T390 751T428 784T479 804Q481 804 488 804T501 805Q552 802 581 769T610 695Q610 669 594 657T561 645Q542 645 527 658T512 694Q512 705 516 714T526 729T538 737T548 742L552 743Q552 745 545 751T525 762T498 768Q475 768 460 756T434 716T418 652T407 559T398 444T387 300T369 133Q349 -38 337 -102T303 -207Q256 -306 169 -306Q119 -306 87 -272T55 -196Q55 -170 71 -158T104 -146Q123 -146 138 -159T153 -195Q153 -206 149 -215T139 -230T127 -238T117 -242L113 -244ZM460 -244Q460 -246 466 -251T486 -263T514 -269Q532 -269 546 -260Q567 -247 579 -218T598 -133T609 -15T623 155T644 367Q647 390 652 438T661 512T672 580T687 647T708 703T737 751T775 784T826 804Q828 804 835 804T848 805Q899 802 928 769T957 695Q957 669 941 657T908 645Q889 645 874 658T859 694Q859 705 863 714T873 729T885 737T895 742L899 743Q899 745 892 751T872 762T845 768Q822 768 807 756T781 716T765 652T754 559T745 444T734 300T716 133Q696 -38 684 -102T650 -207Q603 -306 516 -306Q466 -306 434 -272T402 -196Q402 -170 418 -158T451 -146Q470 -146 485 -159T500 -195Q500 -206 496 -215T486 -230T474 -238T464 -242L460 -244',
    0x222D: '113 -244Q113 -246 119 -251T139 -263T167 -269Q186 -269 199 -260Q220 -247 232 -218T251 -133T262 -15T276 155T297 367Q300 390 305 438T314 512T325 580T340 647T361 703T390 751T428 784T479 804Q481 804 488 804T501 805Q552 802 581 769T610 695Q610 669 594 657T561 645Q542 645 527 658T512 694Q512 705 516 714T526 729T538 737T548 742L552 743Q552 745 545 751T525 762T498 768Q475 768 460 756T434 716T418 652T407 559T398 444T387 300T369 133Q349 -38 337 -102T303 -207Q256 -306 169 -306Q119 -306 87 -272T55 -196Q55 -170 71 -158T104 -146Q123 -146 138 -159T153 -195Q153 -206 149 -215T139 -230T127 -238T117 -242L113 -244ZM460 -244Q460 -246 466 -251T486 -263T514 -269Q532 -269 546 -260Q567 -247 579 -218T598 -133T609 -15T623 155T644 367Q647 390 652 438T661 512T672 580T687 647T708 703T737 751T775 784T826 804Q828 804 835 804T848 805Q899 802 928 769T957 695Q957 669 941 657T908 645Q889 645 874 658T859 694Q859 705 863 714T873 729T885 737T895 742L899 743Q899 745 892 751T872 762T845 768Q822 768 807 756T781 716T765 652T754 559T745 444T734 300T716 133Q696 -38 684 -102T650 -207Q603 -306 516 -306Q466 -306 434 -272T402 -196Q402 -170 418 -158T451 -146Q470 -146 485 -159T500 -195Q500 -206 496 -215T486 -230T474 -238T464 -242L460 -244ZM807 -244Q807 -246 813 -251T833 -263T861 -269Q880 -269 893 -260Q914 -247 926 -218T945 -133T956 -15T970 155T991 367Q994 390 999 438T1008 512T1019 580T1034 647T1055 703T1084 751T1122 784T1173 804Q1175 804 1182 804T1195 805Q1246 802 1275 769T1304 695Q1304 669 1288 657T1255 645Q1236 645 1221 658T1206 694Q1206 705 1210 714T1220 729T1232 737T1242 742L1246 743Q1246 745 1239 751T1219 762T1192 768Q1169 768 1154 756T1128 716T1112 652T1101 559T1092 444T1081 300T1063 133Q1043 -38 1031 -102T997 -207Q950 -306 863 -306Q813 -306 781 -272T749 -196Q749 -170 765 -158T798 -146Q817 -146 832 -159T847 -195Q847 -206 843 -215T833 -230T821 -238T811 -242L807 -244',
    0x222E: '269 74L256 80Q244 85 227 97T191 128T161 179T148 250Q148 332 199 379T302 433L306 434L307 444Q309 456 313 495T321 553T331 607T345 664T365 712T393 756T431 785T479 804Q481 804 488 804T501 805Q552 802 581 769T610 695Q610 669 594 657T561 645Q542 645 527 658T512 694Q512 705 516 714T526 729T538 737T548 742L552 743Q552 745 545 751T525 762T498 768Q471 768 454 752T427 693T414 626T406 536Q405 530 405 527L397 425L404 422Q410 419 421 413T445 399T470 376T494 345T511 303T518 250Q518 205 502 169T460 112T410 80T364 66L360 65L359 55Q357 38 353 4T346 -43T340 -81T333 -118T326 -148T316 -179T303 -207Q256 -306 169 -306Q119 -306 87 -272T55 -196Q55 -170 71 -158T104 -146Q123 -146 138 -159T153 -195Q153 -206 149 -215T139 -230T127 -238T117 -242L113 -244Q113 -246 119 -251T139 -263T167 -269Q186 -269 199 -260Q231 -241 242 -183T266 33L269 74ZM272 122Q272 156 300 391Q300 392 299 392Q287 392 263 379T213 331T187 249Q187 211 205 180T239 137T272 116V122ZM366 107Q378 107 402 119T453 167T479 249Q479 340 394 383V377Q394 375 394 374T393 371T393 366T392 357T391 342T389 321T386 291T382 251T377 199T369 133Q366 112 366 107',
    0x22C0: '119 -249T97 -249T65 -235T55 -207Q55 -201 56 -198Q58 -190 218 268T380 729Q392 750 416 750Q438 750 451 732Q453 728 534 498T695 36L775 -194Q777 -204 777 -208Q777 -222 767 -235T735 -249Q713 -249 700 -231Q696 -225 557 177L416 579L276 177Q136 -226 132 -231Q119 -249 97 -249',
    0x22C1: '55 708Q55 729 68 739T96 750Q119 750 132 731Q136 726 276 323L416 -79L557 323Q696 725 700 731Q713 749 735 749Q756 749 766 736T777 708Q777 700 696 466T533 1T451 -232Q436 -249 416 -249Q402 -249 391 -241Q384 -236 380 -226Q368 -198 219 230Q55 697 55 708',
    0x22C2: '139 -217Q127 -241 114 -246Q106 -249 97 -249Q67 -249 57 -220Q55 -214 55 102Q55 152 55 221T54 312Q54 422 60 464T91 554Q120 612 165 654T257 714T337 741T392 749Q393 750 402 750Q414 750 422 749Q557 749 660 659T776 430Q777 422 777 102Q777 -214 775 -220Q765 -249 735 -249Q716 -249 708 -241T694 -217L692 428L690 441Q674 540 597 603T416 666H409Q388 666 364 662T294 638T212 581Q156 523 142 441L140 428L139 105V-217',
    0x22C3: '96 750Q103 750 109 748T120 744T127 737T133 730T137 723T139 718V395L140 73L142 60Q159 -43 237 -104T416 -166Q521 -166 597 -103T690 60L692 73L694 718Q708 749 735 749Q765 749 775 720Q777 714 777 398Q777 78 776 71Q766 -51 680 -140Q571 -249 416 -249H411Q261 -249 152 -140Q66 -51 56 71Q55 78 55 398Q55 714 57 720Q60 734 70 740Q80 750 96 750',
    0x2308: '202 -349V850H449V810H242V-349H202',
    0x2309: '22 810V850H269V-349H229V810H22',
    0x230A: '202 -349V850H242V-309H449V-349H202',
    0x230B: '229 -309V850H269V-349H22V-309H229',
    0x2329: '373 850Q392 850 394 832Q394 825 267 538L139 250L267 -38Q394 -325 394 -332Q392 -350 375 -350Q361 -350 356 -338Q354 -331 289 -186T161 103T97 250T160 397T289 685T356 838Q362 850 373 850',
    0x232A: '77 832Q77 837 82 843T98 850Q110 849 115 838Q117 831 182 686T310 397T374 250T311 103T182 -185T115 -338Q110 -350 96 -350Q79 -350 77 -332Q77 -325 204 -38L332 250L204 538Q77 825 77 832',
    0x23D0: '312 0V602H355V0H312',
    0x2758: '146 612Q151 627 166 627Q182 627 187 612Q188 610 188 306T187 0Q184 -15 166 -15Q149 -15 146 0V10Q146 19 146 35T146 73T146 122T145 179T145 241T145 306T145 370T145 433T145 489T146 538T146 576T146 602V612',
    0x27E8: '373 850Q392 850 394 832Q394 825 267 538L139 250L267 -38Q394 -325 394 -332Q392 -350 375 -350Q361 -350 356 -338Q354 -331 289 -186T161 103T97 250T160 397T289 685T356 838Q362 850 373 850',
    0x27E9: '77 832Q77 837 82 843T98 850Q110 849 115 838Q117 831 182 686T310 397T374 250T311 103T182 -185T115 -338Q110 -350 96 -350Q79 -350 77 -332Q77 -325 204 -38L332 250L204 538Q77 825 77 832',
    0x2A00: '555 -250Q420 -250 306 -185T124 -4T56 250Q56 453 193 595T526 749Q528 750 539 750Q554 750 562 749Q688 749 800 687T983 508T1054 250Q1054 112 987 -3T806 -184T555 -250ZM555 -165Q672 -165 767 -108T916 44T970 250Q970 418 861 532T600 664Q591 665 548 665Q446 665 353 614T200 466T140 250V243Q140 88 248 -30Q262 -46 280 -62T338 -105T434 -148T555 -165ZM478 250Q478 288 503 307T551 326Q586 326 609 305T632 250Q632 217 610 196T555 174T500 196T478 250',
    0x2A01: '555 -250Q420 -250 306 -185T124 -4T56 250Q56 453 193 595T526 749Q528 750 539 750Q554 750 562 749Q688 749 800 687T983 508T1054 250Q1054 112 987 -3T806 -184T555 -250ZM513 478Q513 664 512 664Q504 664 481 660T406 637T313 588Q281 564 255 537T211 483T181 431T161 382T150 342T144 310T141 292H513V478ZM798 588Q758 616 711 634T639 658T602 663L597 664V292H969Q969 293 967 309T960 341T949 381T930 430T900 482T856 537T798 588ZM513 -164V208H141Q142 205 144 189T149 160T158 125T173 83T196 39T229 -9Q249 -34 273 -55T318 -92T363 -119T405 -138T444 -150T475 -158T499 -162T513 -164ZM775 -103Q801 -87 823 -68T863 -30T894 10T919 49T937 88T950 123T959 154T964 180T968 198L969 208H597V-164Q599 -163 616 -161T647 -155T683 -145T728 -128T775 -103',
    0x2A02: '555 -250Q420 -250 306 -185T124 -4T56 250Q56 453 193 595T526 749Q528 750 539 750Q554 750 562 749Q688 749 800 687T983 508T1054 250Q1054 112 987 -3T806 -184T555 -250ZM600 664Q591 665 548 665Q414 665 306 583L292 573L423 441L555 310L687 441L818 573L804 583Q714 650 600 664ZM364 118L495 250L364 382L232 513L223 500Q140 391 140 250Q140 107 223 0L232 -13L364 118ZM970 250Q970 389 887 501L878 512Q878 513 861 496T812 447T746 381L615 250L746 118L878 -13L887 0Q970 109 970 250ZM687 59L555 190L423 59L292 -73L306 -83Q416 -166 555 -166T804 -83L818 -73L687 59',
    0x2A04: '96 750Q103 750 109 748T120 744T127 737T133 730T137 723T139 718V395L140 73L142 60Q159 -43 237 -104T416 -166Q521 -166 597 -103T690 60L692 73L694 718Q708 749 735 749Q765 749 775 720Q777 714 777 398Q777 78 776 71Q766 -51 680 -140Q571 -249 416 -249H411Q261 -249 152 -140Q66 -51 56 71Q55 78 55 398Q55 714 57 720Q60 734 70 740Q80 750 96 750ZM223 276Q223 282 224 287T227 296T232 302T238 308T243 313T250 316L254 319H374V376V406Q374 438 382 454T418 470Q443 467 450 453T458 410V376V319H579Q580 319 583 317T589 313T594 308T600 302T604 295T608 287T609 276Q609 253 587 241Q577 235 513 235H458V178Q458 176 458 166T459 148Q459 84 415 84Q401 84 390 93T375 117Q374 120 374 178V235H319Q317 235 307 235T290 234Q223 234 223 276',
    0x2A06: '777 -217Q766 -244 745 -249H88Q64 -242 57 -220Q55 -214 55 250T57 720Q60 734 70 740Q80 750 96 750Q127 750 137 720Q139 714 139 274V-166H693V274Q693 714 695 720Q705 749 735 749Q766 749 775 719Q777 713 777 248V-217',
    0x3008: '373 850Q392 850 394 832Q394 825 267 538L139 250L267 -38Q394 -325 394 -332Q392 -350 375 -350Q361 -350 356 -338Q354 -331 289 -186T161 103T97 250T160 397T289 685T356 838Q362 850 373 850',
    0x3009: '77 832Q77 837 82 843T98 850Q110 849 115 838Q117 831 182 686T310 397T374 250T311 103T182 -185T115 -338Q110 -350 96 -350Q79 -350 77 -332Q77 -325 204 -38L332 250L204 538Q77 825 77 832',
}, {
    0x2A0C: "\u222C\u222C",
});
