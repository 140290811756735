"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.texCalligraphicBold = void 0;
var FontData_js_1 = require("../../FontData.js");
var tex_calligraphic_bold_js_1 = require("../../../common/fonts/tex/tex-calligraphic-bold.js");
exports.texCalligraphicBold = FontData_js_1.AddPaths(tex_calligraphic_bold_js_1.texCalligraphicBold, {
    0x41: '761 751Q784 751 784 728V711Q784 570 795 417T820 191Q844 76 865 76Q868 76 902 93T962 112H973Q989 104 989 94Q989 92 987 86Q978 47 846 -11Q812 -25 779 -26Q722 -26 708 9Q688 47 669 161H524L379 162L359 136Q286 43 234 -3T142 -49T71 -19T39 55Q39 92 60 131T103 174Q113 174 117 167T124 149T136 128T166 110Q183 105 190 105Q230 105 341 246Q401 322 453 397T531 514T582 601T611 651H640V663Q640 692 676 718T745 750Q747 750 752 750T761 751ZM658 266Q653 312 649 377T644 489T641 541V556L557 415Q500 325 448 253Q467 261 524 261H568H658V266',
    0x42: '441 83Q571 83 571 195Q571 246 538 279T466 322T386 333Q378 333 357 330T329 327Q307 327 307 345Q307 354 313 365T347 396T419 430Q546 472 581 498Q594 508 594 535Q594 574 567 589T508 604Q469 604 442 583Q390 546 342 457T265 256Q237 148 186 60Q167 29 144 13Q105 -15 68 -17H65Q40 -17 40 1Q40 7 53 29T87 100T124 210Q162 373 190 575Q188 575 174 567T138 550T98 542Q75 542 75 560Q75 588 123 618Q135 625 203 659T281 696Q299 703 316 703Q339 703 339 685Q339 656 328 583L333 588Q338 592 346 599T367 615T394 634T428 654T467 674T511 690T559 701T611 705Q651 704 681 689Q739 659 739 598Q739 507 595 427L584 421Q585 420 595 416T610 410T626 402T644 392T660 380T677 365T691 347T703 325T710 299T715 268Q717 193 665 130Q622 73 531 28T348 -17Q275 -17 225 10Q206 19 200 24T193 36Q193 57 231 86T311 119H322Q386 83 441 83',
    0x43: '243 -20Q135 -20 78 48T20 218Q20 280 39 344T98 470T193 580T324 661T488 702H490Q491 702 493 702T498 703T507 703T518 702H526Q599 702 599 631Q599 597 577 550T541 486Q523 463 490 447T431 430Q423 430 419 433Q409 437 409 450Q410 456 432 499T454 567Q454 586 446 594T415 603Q316 603 254 532Q216 487 190 421T164 284Q164 228 181 186T226 122T282 90T340 80Q377 80 405 96T449 131T492 167T553 187H555Q580 187 580 168Q580 153 551 123T476 60T367 4T243 -20',
    0x44: '107 0Q92 5 92 18Q92 33 113 53T160 86Q170 91 182 94T197 100L206 120Q248 226 273 337T304 501T309 585Q278 585 234 577T179 557Q172 550 166 532T156 509Q140 484 105 466T44 447Q20 447 20 465Q20 482 34 510T76 565Q122 608 173 632Q281 686 447 686H480H517Q692 686 784 631Q885 571 885 450Q885 339 805 239T586 75T286 1Q276 0 187 0H107ZM741 391Q741 424 731 452T694 510T613 558T481 584Q476 584 468 584T457 585L449 586V579Q441 501 425 424T391 292T357 193T330 125T319 100H324Q511 100 628 175Q688 215 714 275T741 391',
    0x45: '495 516Q485 516 478 520T470 532Q470 537 476 550T482 570Q482 589 465 596T401 603Q344 603 319 582Q295 558 295 519Q295 493 312 474T355 445T407 432T455 427Q477 427 477 409Q477 395 453 371T389 333Q380 330 345 327T279 312T223 272Q181 223 181 176Q181 131 225 107T324 83Q366 83 395 98T448 136T487 167Q517 185 547 187H551Q574 187 574 170Q574 151 535 113T421 36T271 -15Q260 -16 226 -16Q181 -16 152 -9Q104 4 71 33T37 111Q37 140 50 176T106 263T216 356Q215 357 207 362T190 374T172 392T156 419T150 456Q150 521 208 580T341 670T474 702Q475 703 499 703Q528 703 547 701T586 693T615 673T627 637Q627 584 581 550T495 516',
    0x46: '812 567Q804 567 795 570T786 579Q786 586 647 586H559L558 582Q558 575 539 510T506 407L498 384H775Q788 378 790 368Q790 328 746 298T665 268Q646 268 642 284H457L447 261Q396 150 360 94Q329 46 270 8T149 -30Q123 -30 100 -24T63 -10T37 9T22 26T17 36Q17 59 56 88T135 119Q145 119 149 117T165 104Q187 78 227 72Q232 72 234 76Q245 93 273 145T350 323T424 570L428 586H276Q265 575 239 563T190 551Q180 551 174 556T167 569Q167 580 179 597T213 634T278 668T371 685Q374 686 624 686Q863 685 888 682Q917 678 927 663Q930 658 930 650Q930 624 888 596T812 567',
    0x47: '50 279Q50 361 88 438T190 570T335 661T503 702H514Q524 703 532 703Q671 703 671 626Q671 580 614 514T495 447Q472 447 472 465Q472 477 499 511T527 562Q527 582 507 592T433 602Q351 602 302 569Q252 535 223 469T194 344Q194 266 237 217T352 168Q401 168 442 205T505 316Q515 345 551 368T622 393H625Q649 393 649 376Q649 371 635 318T612 237Q580 129 540 62T442 -49Q353 -114 264 -114Q259 -114 252 -114L246 -113Q190 -113 142 -107T81 -96Q71 -90 71 -81Q71 -57 110 -30T187 2Q196 2 236 -4T338 -14Q371 -14 377 -9Q410 24 446 113L451 127Q353 68 253 68Q157 68 104 129T50 279',
    0x48: '42 447Q20 447 20 465Q20 481 47 515T119 589T239 657T392 686Q443 686 463 664T484 616Q484 570 473 506T452 401L441 360Q441 359 550 359H660L663 370Q684 435 716 522T758 624Q771 646 806 666T870 686Q894 686 894 668Q894 667 867 597T804 416T752 218Q737 135 737 93Q737 77 746 65T778 53Q799 53 803 54T814 63Q831 86 864 103T924 120Q946 120 946 100Q945 85 931 63T888 16T806 -27T684 -48H681Q625 -48 603 -10Q593 4 593 29Q593 71 603 131T624 230L634 269Q632 269 624 266Q610 261 600 261T507 259H411L399 222Q344 62 322 21Q301 -7 268 -24T209 -41H207Q187 -41 185 -25Q185 -17 192 2T220 71T261 184Q284 256 284 258Q284 259 227 259H170Q169 259 166 261T162 264T158 266T156 271T155 277Q155 296 184 320T250 356Q259 358 286 359Q312 359 312 360Q314 372 318 385Q332 450 339 526Q339 530 339 535T340 543Q340 586 296 586Q255 586 227 576T188 553T165 523T146 497Q127 476 97 462T42 447',
    0x49: '56 499Q32 499 32 516Q32 540 64 580T165 649Q241 682 365 685Q366 685 376 685T405 685T445 686T496 686T553 686H732Q746 677 746 668Q746 646 711 620T642 587L572 586H503Q479 546 458 479T424 352T383 224T318 111L309 101L412 100H514L523 109Q567 150 618 153Q644 153 644 135Q644 132 642 124Q629 86 581 52T476 6Q454 2 433 2T216 0Q-11 0 -15 2Q-27 6 -27 18Q-27 37 2 61T59 93Q77 100 142 100H198Q255 177 299 369Q337 513 382 574L391 586H348Q261 586 176 576Q163 543 124 521T56 499',
    0x4A: '286 390Q263 390 263 407Q263 432 293 481T367 566Q511 687 724 687Q738 687 761 687T793 686H923Q937 677 937 668Q937 648 905 623T842 589Q829 587 817 586T802 585T795 583T788 578Q709 506 632 189Q622 153 615 134T588 81T537 17Q482 -39 404 -76T247 -114Q192 -114 158 -100Q53 -61 53 32Q53 59 58 73T79 102Q126 147 177 147Q200 147 200 128Q200 123 198 112T196 96Q196 47 238 17T345 -13Q362 -13 377 -9T404 0T426 16T444 34T459 55T470 76T478 97T483 116T488 132L490 141Q511 222 520 257T554 364T608 486T675 576L685 586H634H612Q532 586 484 564Q453 549 436 526T409 478T395 447Q378 424 345 407T286 390',
    0x4B: '98 542Q75 542 75 560Q75 588 123 618Q132 624 199 657T275 694Q291 703 315 703Q327 703 332 699T338 690T339 670Q339 596 323 505T283 337T237 194T198 90L181 53Q170 31 136 8T68 -17H65Q40 -17 40 0L76 92Q112 185 150 322T194 564V578L168 565Q125 542 98 542ZM834 142Q834 125 819 100T774 48T692 3T576 -16H560Q540 -16 508 6Q469 33 422 108T342 267T309 398Q309 411 310 417T320 442T347 482Q401 542 517 615T710 702Q712 702 721 702T735 703Q772 703 791 690Q819 674 819 646T792 597T733 574H722Q704 584 704 599Q706 607 700 610T672 617L660 613Q609 595 524 538T423 450V440Q423 376 488 247T604 83Q621 70 640 70Q677 70 701 82Q713 87 718 101T737 132T783 160Q792 163 807 163Q834 163 834 142',
    0x4C: '63 -17Q41 -17 41 0Q41 22 85 54Q101 68 113 92T133 141T154 219T182 315Q230 462 306 553Q345 599 391 632T478 678T543 697T582 703Q584 703 589 703T598 702Q643 702 666 676T689 613Q689 588 683 575Q674 551 632 524T552 496Q530 496 530 512Q530 517 531 525T533 538Q533 559 522 577T480 596H476Q462 596 451 588T415 544Q350 447 310 281Q284 181 261 136L255 124H285Q342 123 441 107T583 90L596 89Q603 116 647 144T729 173Q751 173 751 157Q751 118 685 60T523 -15Q514 -16 479 -16Q421 -16 320 0T171 18H155L142 10Q98 -17 63 -17',
    0x4D: '38 20Q38 59 60 99T104 139Q106 139 126 125T176 106H181Q200 106 221 139T286 281Q322 370 342 451T368 581T376 634Q384 657 420 680T487 703Q502 703 507 696T522 649Q538 589 554 537Q579 453 609 372T660 248T686 202Q687 201 739 244T830 322L1166 642Q1225 700 1230 701Q1230 701 1237 703Q1258 703 1258 667L1253 637Q1248 607 1241 558T1227 451T1214 326T1209 202Q1209 77 1232 77Q1237 77 1269 94T1326 112H1329Q1353 112 1353 94Q1353 81 1334 60Q1311 37 1248 7T1150 -24H1141H1135Q1085 -24 1074 26Q1064 75 1064 134Q1064 239 1086 426Q1087 430 1087 434L1061 410Q871 227 783 149L694 76Q653 44 647 40T631 34Q620 34 616 37T594 63Q546 125 514 198Q467 307 423 449L418 466L412 444Q376 310 306 153Q278 88 251 45T201 -18T163 -43T131 -49Q102 -48 70 -31T38 20',
    0x4E: '47 139Q81 105 122 105Q137 105 147 117Q159 134 182 199T234 381T274 610Q275 634 284 647Q297 666 327 684T389 703Q403 703 408 695T428 645Q480 490 567 298Q628 163 673 103Q674 102 674 102T675 106Q732 331 803 551Q842 674 875 725Q908 775 966 807T1081 840H1084Q1105 840 1105 803Q1105 768 1088 733T1051 689Q1045 686 1032 686Q986 683 948 663T901 624Q881 579 837 430T760 154L726 28Q725 28 725 28T723 25Q716 0 682 -24T611 -48Q600 -48 595 -45T576 -23Q522 44 480 124Q417 243 332 463L328 473L325 457Q291 293 227 124Q159 -49 72 -49Q38 -49 5 -28Q-24 -8 -24 21Q-24 58 -3 98T41 139H47',
    0x4F: '433 703Q456 703 456 685Q456 672 441 655T407 627Q402 623 378 611T328 579T276 524Q207 434 207 324Q207 222 270 153T441 84Q566 84 651 177T737 400V405Q737 496 693 549T576 603Q542 603 510 560Q490 537 472 502T442 454Q397 412 346 409Q320 409 320 427Q320 430 322 436Q331 465 360 507T433 594T542 671T677 703Q776 703 829 636T882 468Q882 369 831 277T702 122T528 21T343 -17Q214 -17 139 61T63 257Q63 336 94 409T173 534T272 625T367 684T432 703H433',
    0x50: '170 -67Q147 -67 147 -49Q147 -42 162 -8T204 99T253 254Q274 332 288 415T305 542L308 585Q277 585 234 577T179 557Q172 550 166 532T156 509Q140 484 105 466T44 447Q20 447 20 465Q20 482 34 510T76 565Q122 608 173 632Q279 686 448 686H495H537Q622 686 678 677T784 637Q846 598 846 533Q846 452 776 375T597 252T378 206H366L358 181Q341 130 316 68T282 -7Q262 -33 230 -50T170 -67ZM701 468Q701 512 661 540T570 577T461 586H448V582Q446 576 443 545T428 447T395 301L389 280Q390 280 398 284T419 295T441 303Q443 304 484 306T572 321T651 359Q701 402 701 468',
    0x51: '874 453Q874 372 836 298T750 177T638 89T543 33T486 8L483 7Q485 5 523 -7T622 -32T726 -46Q741 -46 746 -45T755 -41T762 -27Q770 -1 806 23T878 50H890Q905 42 905 33Q905 -8 838 -68T670 -145Q662 -146 628 -146Q538 -146 389 -100T164 -50Q132 -50 132 -32T162 11T227 47Q231 48 286 51T394 62T518 100T641 180Q730 271 730 387Q730 478 673 540T520 602Q410 602 337 525T264 355Q264 284 310 244T420 203Q476 203 568 222Q594 222 594 204Q594 184 565 161T508 128Q433 103 316 103Q227 103 174 157T120 290Q120 382 182 471T343 620T548 697Q578 703 601 703Q604 703 611 703T623 702Q663 702 687 696Q760 679 817 618T874 453',
    0x52: '159 0Q159 5 172 34T205 114T245 229T284 386T309 575V585H304Q303 585 295 585T282 584Q233 579 207 570T175 553T165 531T156 509Q140 484 105 466T44 447Q20 447 20 465Q20 482 34 510T76 565Q122 608 173 632Q279 686 448 686H505H582Q683 686 745 672T834 611Q842 594 842 565Q842 523 824 484T780 419T722 370T669 336T632 318L619 312L626 302Q640 279 667 227T696 172Q717 133 735 112T762 88T784 84Q824 84 872 118T957 153Q981 153 981 136Q981 114 937 78T820 13T684 -17Q646 -17 616 8T569 66T526 151T477 234Q461 256 446 265Q437 272 421 274Q400 274 400 291Q400 311 430 336T495 371Q496 371 543 374T627 392T681 436Q699 467 699 503Q699 550 644 568T471 586H449V582Q449 581 447 559T438 499T422 413T393 298T348 165Q313 73 296 45Q282 24 249 4T185 -17Q159 -17 159 0',
    0x53: '204 476Q204 525 248 577T372 666T539 703T674 683T721 612Q721 588 714 569Q704 547 669 524T601 499Q573 499 573 516Q573 521 575 527T577 543Q577 563 568 574T548 588L539 590Q490 603 444 603Q418 603 394 597T364 583Q348 567 348 533Q348 493 382 466T459 425T555 387T633 330Q662 292 662 249Q662 153 544 69T257 -16Q218 -16 208 -15Q118 1 64 46Q25 76 25 126Q25 185 82 235T203 290H207Q229 290 231 274Q231 243 180 213Q173 209 172 206T170 189T171 170T183 150T216 121Q273 83 356 83Q412 83 459 100Q493 111 507 141Q518 165 518 185Q518 208 506 228T478 262T437 288T398 306T360 320Q316 335 285 352T239 384T215 416T205 443T204 467V476',
    0x54: '61 462H59Q38 462 38 479Q38 528 109 594T289 683L304 685L837 687L846 693Q889 720 923 720Q947 720 947 702Q945 671 892 631T776 583Q774 583 772 583T769 582T766 582L764 581H758Q753 581 744 581T722 580T693 580T662 580H563L514 385Q507 355 493 299T475 225T460 172T443 119T426 76T402 24Q386 -11 355 -33T304 -61T266 -69Q242 -69 242 -50Q243 -45 253 -25T278 32T307 115L364 340Q405 511 413 538T436 580H207Q202 572 200 568T197 561T195 552T190 537Q176 511 135 487T61 462',
    0x55: '124 586Q107 586 74 569T15 552H13Q-10 552 -10 570Q-10 605 70 645T222 686Q283 686 283 631Q283 590 246 504T172 326T135 181Q135 130 157 107T205 83Q221 83 259 106Q347 165 453 301T604 548Q607 557 612 569T619 587T624 600T628 612T632 621T637 628T641 634T647 640T654 645T662 652Q706 686 748 686Q771 686 771 669Q771 656 754 614T700 467T630 229Q615 168 610 105Q610 88 617 78L641 90Q681 111 706 112Q733 112 733 95Q733 82 714 60Q694 40 633 10Q567 -23 532 -24Q507 -24 495 -17Q466 -4 466 32Q466 96 500 225Q277 -17 102 -17Q56 -17 23 17T-10 118Q-10 164 13 234T64 363T115 481T139 567Q139 586 124 586',
    0x56: '25 608Q25 628 60 657T148 686Q184 683 213 671T273 625T327 538T363 394T380 184L381 134L399 148Q503 226 574 302T667 415T689 467Q688 474 684 482T672 502T645 521T600 532Q576 532 576 567Q576 604 597 644T641 685H649Q701 685 737 648T774 545Q774 457 703 333T461 66Q397 13 332 -32T255 -77Q237 -77 237 -30V-23Q241 20 241 109Q241 483 115 569Q91 586 50 589Q25 589 25 608',
    0x57: '25 607Q25 629 62 657T142 686Q205 686 248 647T312 541T339 411T347 275Q347 249 345 203V189Q375 219 449 316T587 516Q629 584 629 587Q629 589 626 597T622 607Q622 629 658 656T732 686H744Q755 680 757 678Q757 677 769 649T799 577T835 475T874 339T904 183Q908 157 910 151L925 169Q997 252 1059 343T1121 474Q1120 498 1103 513T1059 532Q1036 532 1036 568Q1036 600 1053 636T1090 683L1097 686H1109Q1147 684 1176 652T1206 551Q1206 460 1131 320T897 7Q859 -33 840 -52T816 -74T804 -77Q788 -77 784 -32Q783 -28 783 -26Q774 108 744 239T691 436T665 501Q664 501 649 475T602 400T528 289T420 146T280 -15Q243 -56 231 -66T210 -77Q191 -77 191 -40Q191 -38 195 -4T204 91T209 217Q209 290 202 351T177 469T126 557T45 589Q25 589 25 607',
    0x58: '762 562Q762 579 737 584T711 604Q711 630 753 658T834 686Q864 686 885 669T906 627Q906 580 834 522T614 379L584 362V357Q585 354 589 315T597 233T603 183Q610 132 627 116T671 100Q678 100 704 113T754 126T778 107Q776 79 733 45T626 2Q615 1 578 1Q542 1 535 3Q521 7 510 15T491 31T477 54T467 78T460 108T456 137T452 170T449 201Q447 220 445 240T442 270L441 281Q435 281 357 233Q240 165 206 135Q200 128 200 124Q200 113 208 108T226 101T244 96T252 82Q252 61 214 31T129 1H120Q97 1 77 16T56 60Q56 105 133 168T414 345Q428 352 431 354T433 359Q422 493 414 522Q407 551 395 566T373 583T350 586H341L332 580Q290 560 265 560Q243 560 243 577Q243 585 248 596T269 624T306 653T365 676T447 686H456Q472 686 484 683T514 671T543 637T562 576Q565 557 570 501L577 437Q577 436 613 457T694 506T756 551Q762 558 762 562',
    0x59: '73 555Q49 555 49 573Q49 602 110 644T239 686Q319 686 376 624Q416 584 444 511T483 361T499 240T503 173Q503 165 504 165Q506 165 524 184T556 218Q631 297 674 377T718 485Q718 505 699 526Q673 552 628 552Q619 552 613 562T607 590Q607 617 621 645T658 685Q661 686 671 686Q718 686 757 652T797 545Q797 476 749 369T602 146Q500 29 371 -67T176 -164Q112 -164 74 -120T36 -29Q36 5 55 36T95 67Q104 67 108 59T115 39T128 12T154 -12Q183 -30 216 -30Q239 -30 305 7L361 44L367 49V54Q367 95 364 143T351 273T312 429T243 546Q206 581 156 588L146 581Q108 555 73 555',
    0x5A: '622 574Q522 579 420 579H396Q373 579 364 574T351 550Q339 516 297 490T218 462Q195 462 195 479Q195 487 197 492Q218 565 313 625T509 685Q564 685 650 683T755 680Q787 680 807 683T831 686Q853 686 853 669Q853 657 826 626Q742 532 641 437L619 415L622 414Q626 414 631 414T642 414Q697 411 697 388Q697 367 670 345T607 323Q605 323 592 325T546 329H522L490 302Q457 274 400 226T289 136L260 113L318 112Q345 111 452 109T587 106H627Q650 143 656 170Q666 197 710 225T788 253Q811 253 811 237Q811 211 781 160T710 77Q619 0 515 0Q507 0 497 0T484 1Q434 1 319 3T177 6Q123 6 95 2Q83 2 71 0H68Q46 0 46 17Q46 28 58 44Q68 56 100 80T210 165T383 307L408 329H361L314 330Q297 338 297 350Q297 368 320 388T368 413Q375 415 441 415H506L647 555L664 574H622',
    0x131: '24 296Q24 305 34 328T63 380T115 430T187 452Q205 452 223 448T262 435T295 406T308 360Q308 345 287 290T240 170T207 87Q202 67 202 57Q202 42 215 42Q235 42 257 64Q288 92 302 140Q307 156 310 159T330 162H336H347Q367 162 367 148Q367 140 357 117T329 65T276 14T201 -8Q158 -8 121 15T83 84Q83 104 133 229T184 358Q189 376 189 388Q189 402 177 402Q156 402 134 380Q103 352 89 304Q84 288 81 285T61 282H55H44Q24 282 24 296',
    0x237: '297 360T297 373T294 392T288 400T278 401H276Q237 398 200 363Q181 343 170 325T156 299T149 287T129 282H123H116Q102 282 97 284T92 298Q93 303 98 315T118 349T151 390T201 427T267 451H279Q357 451 388 422T420 354V339L370 138Q321 -60 317 -69Q287 -157 163 -194Q133 -201 99 -201Q39 -201 14 -178T-12 -125Q-12 -94 11 -69T68 -43Q93 -43 108 -57T123 -95Q123 -121 100 -151H104Q131 -151 155 -125T193 -60Q195 -54 244 141T294 345Q297 360 297 373',
}, {});
