"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    SHCHcy: '\u0429',
    SHcy: '\u0428',
    SOFTcy: '\u042C',
    Sacute: '\u015A',
    Sc: '\u2ABC',
    Scaron: '\u0160',
    Scedil: '\u015E',
    Scirc: '\u015C',
    Scy: '\u0421',
    ShortDownArrow: '\u2193',
    ShortLeftArrow: '\u2190',
    ShortRightArrow: '\u2192',
    ShortUpArrow: '\u2191',
    Sub: '\u22D0',
    Sup: '\u22D1',
    sacute: '\u015B',
    sbquo: '\u201A',
    sc: '\u227B',
    scE: '\u2AB4',
    scaron: '\u0161',
    sccue: '\u227D',
    sce: '\u2AB0',
    scedil: '\u015F',
    scirc: '\u015D',
    scpolint: '\u2A13',
    scsim: '\u227F',
    scy: '\u0441',
    sdotb: '\u22A1',
    sdote: '\u2A66',
    seArr: '\u21D8',
    searhk: '\u2925',
    searrow: '\u2198',
    semi: '\u003B',
    seswar: '\u2929',
    setminus: '\u2216',
    setmn: '\u2216',
    sext: '\u2736',
    sfrown: '\u2322',
    shchcy: '\u0449',
    shcy: '\u0448',
    shortmid: '\u2223',
    shortparallel: '\u2225',
    shy: '\u00AD',
    sigmaf: '\u03C2',
    sim: '\u223C',
    simdot: '\u2A6A',
    sime: '\u2243',
    simeq: '\u2243',
    simg: '\u2A9E',
    simgE: '\u2AA0',
    siml: '\u2A9D',
    simlE: '\u2A9F',
    simplus: '\u2A24',
    simrarr: '\u2972',
    slarr: '\u2190',
    smallsetminus: '\u2216',
    smashp: '\u2A33',
    smeparsl: '\u29E4',
    smid: '\u2223',
    smt: '\u2AAA',
    smte: '\u2AAC',
    smtes: '\u2AAC\uFE00',
    softcy: '\u044C',
    sol: '\u002F',
    solb: '\u29C4',
    solbar: '\u233F',
    spadesuit: '\u2660',
    spar: '\u2225',
    sqcap: '\u2293',
    sqcaps: '\u2293\uFE00',
    sqcup: '\u2294',
    sqcups: '\u2294\uFE00',
    sqsub: '\u228F',
    sqsube: '\u2291',
    sqsubset: '\u228F',
    sqsubseteq: '\u2291',
    sqsup: '\u2290',
    sqsupe: '\u2292',
    sqsupset: '\u2290',
    sqsupseteq: '\u2292',
    squ: '\u25A1',
    square: '\u25A1',
    squarf: '\u25AA',
    squf: '\u25AA',
    srarr: '\u2192',
    ssetmn: '\u2216',
    ssmile: '\u2323',
    sstarf: '\u22C6',
    star: '\u2606',
    starf: '\u2605',
    straightepsilon: '\u03F5',
    straightphi: '\u03D5',
    strns: '\u00AF',
    subdot: '\u2ABD',
    sube: '\u2286',
    subedot: '\u2AC3',
    submult: '\u2AC1',
    subplus: '\u2ABF',
    subrarr: '\u2979',
    subset: '\u2282',
    subseteq: '\u2286',
    subseteqq: '\u2AC5',
    subsetneq: '\u228A',
    subsetneqq: '\u2ACB',
    subsim: '\u2AC7',
    subsub: '\u2AD5',
    subsup: '\u2AD3',
    succ: '\u227B',
    succapprox: '\u2AB8',
    succcurlyeq: '\u227D',
    succeq: '\u2AB0',
    succnapprox: '\u2ABA',
    succneqq: '\u2AB6',
    succnsim: '\u22E9',
    succsim: '\u227F',
    sum: '\u2211',
    sung: '\u266A',
    sup: '\u2283',
    sup1: '\u00B9',
    sup2: '\u00B2',
    sup3: '\u00B3',
    supdot: '\u2ABE',
    supdsub: '\u2AD8',
    supe: '\u2287',
    supedot: '\u2AC4',
    suphsol: '\u27C9',
    suphsub: '\u2AD7',
    suplarr: '\u297B',
    supmult: '\u2AC2',
    supplus: '\u2AC0',
    supset: '\u2283',
    supseteq: '\u2287',
    supseteqq: '\u2AC6',
    supsetneq: '\u228B',
    supsetneqq: '\u2ACC',
    supsim: '\u2AC8',
    supsub: '\u2AD4',
    supsup: '\u2AD6',
    swArr: '\u21D9',
    swarhk: '\u2926',
    swarrow: '\u2199',
    swnwar: '\u292A',
    szlig: '\u00DF'
}, 's');
