// import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect, Component } from 'react';
// import { jsonToCSV } from './jsonToCSV'
// console.log("jsonToCSV", jsonToCSV)
// import csvparser from 'csv-parser'
// import csvwriter from 'csv-writer'
// import csvjson from 'csvjson'
// import fs from 'fs'
// var readFile = fs.readFile
// var writeFile = fs.writeFile
// import { csvjson } from './csvjson'
// import { fs } from './fileSystemMod'
// import RNFS from 'react-native-fs';
// import * as FileSystem from 'expo-file-system';
// import CsvDownload from "react-json-to-csv";
import moment from "moment"
// import Moment from 'react-moment';
import { StyleSheet, Text,FlatList, TextInput, View, Image,TouchableOpacity,Button,Modal,Pressable,ActivityIndicator,Dimensions } from 'react-native';
import * as Clipboard from 'expo-clipboard'
import Ionicons from '@expo/vector-icons/Ionicons'
import Entypo from '@expo/vector-icons/Entypo'
import Feather from '@expo/vector-icons/Feather'
import AntDesign from '@expo/vector-icons/AntDesign'
import { Video, AVPlaybackStatus } from 'expo-av';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

// const redirectUrl = Linking.createURL('https://solver.toxeus.org/', 
//     {
//       queryParams: { hello: 'world' },
//     }
// );

// import {PersistGate} from 'redux-persist/integration/react'
// import {Provider} from 'react-redux'
// import store from './redux/store3'
// import persistor from './redux/store3'


// import { createStore} from 'redux'
// import { Provider } from 'react-redux';
// import { persistStore, persistReducer } from "redux-persist";
// import storageWeb from "redux-persist/lib/storage";

// const persistConfig = {
//   key: "root",
//   storage:storageWeb,
//   // stateReconciler: autoMergeLevel2,
//   // blacklist: ["business"]
// };

// // const persistedReducer = persistReducer(persistConfig, reducers);

// const store = createStore(
//   persistedReducer,
//   composeEnhancers(applyMiddleware(thunk, logger))
// );

// let persistor = persistStore(store);


// import store from './redux/store-simple'
import store from './redux/store3'



// OLD PRICES BELOW
// const TEST_SOLVER_BLOCK_UTILIZED_PRODUCT_ID = "price_1LNb6rIDZ4uFPnChW45PTNIR"
// const SOLVER_BLOCK_UTILIZED_PRODUCT_ID = TEST_SOLVER_BLOCK_UTILIZED_PRODUCT_ID
// const TEST_FREE_SOLVER_BLOCK_UTILIZED_PRODUCT_ID = "price_1LPY2HIDZ4uFPnChRfq7Up9Z"

// const TEST_SOLVER_USAGE_PRODUCT_ID = "price_1LNay8IDZ4uFPnChdGWXb4vz"
// const SOLVER_USAGE_PRODUCT_ID = TEST_SOLVER_USAGE_PRODUCT_ID
// const TEST_FREE_SOLVER_USAGE_PRODUCT_ID = "price_1LPXiGIDZ4uFPnChHJmtE79M"
// OLD PRICES ABOVE




// NEW PRICES BELOW
const TEST_SOLVER_USAGE_PRODUCT_ID = "price_1LVgjhIDZ4uFPnChctnr2FWY"
const SOLVER_USAGE_PRODUCT_ID = TEST_SOLVER_USAGE_PRODUCT_ID
const TEST_FREE_SOLVER_USAGE_PRODUCT_ID = "price_1LVgo0IDZ4uFPnChCu24PdQr"
// NEW PRICES ABOVE



















// import FontAwesome5 from '@expo/vector-icons/FontAwesome5'
// import {Picker} from '@react-native-picker/picker';
// import { MathJaxSvg } from 'react-native-mathjax-html-to-svg';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { MathJaxSvg } from './custom-react-native-mathjax-html-to-svg/react-native-mathjax-html-to-svg';
// import * as DocumentPicker from 'expo-document-picker';
// import * as Svg from 'react-native-svg';
const logo = require('./src/assets/white.png')
const long_logo = require('./images-and-vids/white-toxeus-cloud-solver.png')
//checkcirleo
//closecircleo
// import csvwriter from 'csv-writer'

import 'react-native-get-random-values'
// import './custom-whatwg-url-without-unicode/whatwg-url-without-unicode'
import 'react-native-url-polyfill/auto';
import {DynamoDBClient, BatchExecuteStatementCommand, QueryCommand  } from "@aws-sdk/client-dynamodb";

// import {LambdaClient, InvokeCommand, CodeSigningPolicies} from "@aws-sdk/client-lambda"

// const command = new BatchExecuteStatementCommand(dynamodb_query_params)
// const data = await client.batchExecuteStatement(params)

import * as Sentry from 'sentry-expo';
Sentry.init({
  dsn: 'https://0b132c4730ec41ed8c0c70306248862c@o1278377.ingest.sentry.io/6477703',
  tracesSampleRate: 1.0,
  enableInExpoDevelopment: true,
  // debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

// import { withAuthenticator,AmplifyTheme } from 'aws-amplify-react-native';
// import { Amplify, Auth } from 'aws-amplify';
import {Amplify, Auth } from  './custom-aws-amplify/aws-amplify'
// Get the aws resources configuration parameters
import awsconfig from './src/aws-exports'; // if you are using Amplify CLI
// import SignIn from 'aws-amplify-react-native/dist/Auth/SignIn';
// import SignUp from './custom-aws-amplify-react-native/dist/Auth/SignUp';
// import SignIn from './custom-aws-amplify-react-native/dist/Auth/SignIn';
// import VerifyContact from './custom-aws-amplify-react-native/dist/Auth/VerifyContact';
// import ForgotPassword from './custom-aws-amplify-react-native/dist/Auth/ForgotPassword';
// import ConfirmSignUp from './custom-aws-amplify-react-native/dist/Auth/ConfirmSitnUp';
// import ConfirmSignIn from './custom-aws-amplify-react-native/dist/Auth/ConfirmSitnUp';
import { withAuthenticator } from './src/dista/Auth';
import AmplifyTheme from './src/dista/AmplifyTheme';
import Authenticator from './src/dista/Auth/Authenticator';
// import storage from 'redux-persist/lib/storage';


// Show something on top of other
const window_width = Dimensions.get('window').width;
const window_height = Dimensions.get('window').height;

const dodgerblue = "rgba(0, 90, 156,1)"
const theme_blue_color = "#0B2E59"
const theme_blue_color_light = "rgba(164, 200, 244, 0.5)"
// theme_blue_color_light WITHOUT ALPHA: "#a4c8f4"
//
// const theme_blue_color_lightest = "#e8f1fc"
// const theme_blue_color_lightest = "#d2e4f9"
// const theme_blue_color_lightest = "#cceeff"
const theme_blue_color_lightest = "rgba(102, 204, 255, 0.225)"
const theme_transparent = "rgba(0, 0, 0, 0)"
const theme_light_pink_color = '#F2F2F2' 

const theme_gray_color = "#9E9E9E"

// import { useTransition } from "react-spring";
// import { AmplifyTheme } from 'aws-amplify-react-native';


// custom colors for components 
// const Mybutton = Object.assign({}, AmplifyTheme.button, { ...AmplifyTheme.button,backgroundColor: theme_blue_color, });
const Mybutton = Object.assign({}, AmplifyTheme.button, {		backgroundColor: theme_blue_color,
  alignItems: 'center',
  padding: 16 });
//console.log('My own design: ', Mybutton)
const MyTheme = Object.assign({}, AmplifyTheme, { button: Mybutton });

Amplify.configure({
  // awsconfig,
  ...awsconfig,
  Analytics: { 
    disabled: true
  }
});

const signUpConfig = {
  header: 'Sign Up',
  hideAllDefaults: true,
  // defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'Email',
      key: 'username',
      placeholder: 'Enter your email',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Password',
      key: 'password',
      placeholder: 'Enter your password',
      required: true,
      displayOrder: 2,
      type: 'password'
    }
    // ,{
    //   label: 'PhoneNumber',
    //   key: 'phone_number',
    //   required: true,
    //   displayOrder: 3,
    //   type: 'string'
    // },
    // {
    //   label: 'Email',
    //   key: 'email',
    //   required: true,
    //   displayOrder: 4,
    //   type: 'string'
    // }
  ],
  usernameAttributes: 'email',
  // theme:MyTheme
};

// async function signUpp () {
//     a = await Auth.signUp({
//       username,
//       password,
//       attributes: {
//           email,
//           'custom:favorite_flavor': 'Cookie Dough'  // custom attribute, not standard
//       }
//   })
// }




// const usernameAttributes = 'My user name';

// export default withAuthenticator(App, {
//   signUpConfig,
//   usernameAttributes
// });


// export const addBreadcrumb = Sentry.Browser.addBreadcrumb
// export const captureException = Sentry.Browser.captureException
// export const ErrorBoundary = SentryReact.ErrorBoundary
// Access any @sentry/react-native exports via:
// Sentry.Native.*

// Access any @sentry/browser exports via:
// Sentry.Browser.*



// import Axios from 'axios'


// import Latex from 'react-native-latex';
// import MathView, { MathText } from 'react-native-math-view';
// import MathView from 'react-native-math-view/src/MathView';
// import
// import MathViewFallback from 'react-native-math-view/src/fallback';
// import MathJax from 'react-native-mathjax';


// URL FOR masterjs-local-search-only BELOW
// var lambdaUrl = "https://bnnq9cqbw9.execute-api.us-west-2.amazonaws.com/dev/masterjs/"

// var lambdaUrl = "https://1xofox8yo2.execute-api.us-west-2.amazonaws.com/dev/handler/"
// var lambdaUrl = "https://ob667rn7t8.execute-api.us-west-2.amazonaws.com/default/js-handler-jsHandlerFunction-kTJvFKOKpTZJ"
// // var lambdaUrl = "https://ob667rn7t8.execute-api.us-west-2.amazonaws.com//js-handler-jsHandlerFunction-kTJvFKOKpTZJ"
// var lambdaUrl = "https://ob667rn7t8.execute-api.us-west-2.amazonaws.com/default/js-handler-jsHandlerFunction-kTJvFKOKpTZJ"
// // var lambdaUrl = 'https://ob667rn7t8.execute-api.us-west-2.amazonaws.com/default'
// // var lambdaUrl = 'https://ob667rn7t8.execute-api.us-west-2.amazonaws.com/default/'
// // var lambdaUrl = 'https://ob667rn7t8.execute-api.us-west-2.amazonaws.com/default/js-handler-jsHandlerFunction-kTJvFKOKpTZJ/'
// var lambdaUrl = 'https://ob667rn7t8.execute-api.us-west-2.amazonaws.com/js-handler-jsHandlerFunction-kTJvFKOKpTZJ/'
// var lambdaUrl = 'https://ob667rn7t8.execute-api.us-west-2.amazonaws.com/js-handler-jsHandlerFunction-kTJvFKOKpTZJ'
// var lambdaUrl = 'https://ob667rn7t8.execute-api.us-west-2.amazonaws.com/default/lambda'

// BELOW lambdaUrl WORKS VIA API KEY AUTHENTICATION
const lambdaUrl = 'https://api.toxeus.org/_'


// BELOW lambdaUrl WORKS VIA COGNITO SESSION TOKEN AUTHENTICATION
const session_token_lambdaUrl = 'https://1rxmgdwrf1.execute-api.us-west-2.amazonaws.com/default/_'

const create_stripe_user_lambdaUrl = 'https://vxjadgd70d.execute-api.us-west-2.amazonaws.com/default/_'

const create_stripe_checkout_session_lambdaUrl = "https://f5mq1pg1ba.execute-api.us-west-2.amazonaws.com/default/_"

const update_stripe_customer_accounts_table_lambdaUrl = 'https://vpe0yecat0.execute-api.us-west-2.amazonaws.com/default/_'

const createApiKeyLambdaUrl = 'https://lowymllutl.execute-api.us-west-2.amazonaws.com/default/_'
// var lambdaUrl = 'https://x3e2a1c119.execute-api.us-west-2.amazonaws.com/default/newlambda'
// const pickDocument = async () => {
// const pickDocument = async () => {
//   let result = await DocumentPicker.getDocumentAsync({});
//   console.log(result.uri);
//   console.log(result);
// };





// EXAMPLE RESULTS 
// (1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2:0<1:0.01:0.01:1:synchronous:8830083a-1b44-4b9b-9a09-7b4bbb5cc72a:80147b72-de04-44d4-a14b-a9f33aced562
// {"x":{"S":"0.9999894391574815"},"y":{"S":"0.9999779067697024"},"z":{"S":"0.9999733793638338"}}

export function MySignIn (){
  const video = React.useRef(null);
  // const [statuss, setStatus] = React.useState({});
  const [screenSize, getDimension] = useState({
    // dynamicWidth: Dimensions.get('window').width,
    // dynamicHeight: Dimensions.get('window').height
    dynamicWidth:window.innerWidth,
    dynamicHeight:window.innerHeight
  });
  const [dynamicWidth, setDynamicWidth] = useState(window.innerWidth);
  const [dynamicHeight, setDynamicHeight] = useState(window.innerHeight);
  const [dynamicViewHeight,setDynamicViewHeight] = useState(window.innerHeight);
  const [dynamicViewWidth,setDynamicViewWidth] = useState(window.innerWidth);
  const setDimension = () => {
    // console.log(window)
    // getDimension({
    //   dynamicWidth:window.innerWidth,
    //   dynamicHeight:window.innerHeight
    //   // dynamicWidth: Dimensions.get('window').width,
    //   // dynamicHeight: Dimensions.get('window').height
    // })
    setDynamicHeight(window.innerHeight)
    setDynamicWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])
  return (
    <View style={{
        top:'0%',
        left:'0%',
        height:'100%',
        // flex:1,
        width:'100%',
        
        justifyContent:'center', 
        alignItems:'center', 
        alignContent:'center',
        overflow:'hidden'
        // padding: 25, 
        // backgroundColor: '#fff', 
    }}
    onLayout={(event) => {
      var {x, y, width, height} = event.nativeEvent.layout;
      setDynamicViewHeight(height)
      setDynamicViewWidth(width)
    }}
    >
      {/* <View style={{position:'absolute',width:'100%',height:'100%',top:'0%',left:'0%',        
      justifyContent:'center', 
        alignItems:'center', 
        textAlignVertical:'center',
        
        }}> */}

        {/* {(screenSize.dynamicWidth < screenSize.dynamicHeight) ? */}
        {( ((dynamicWidth / dynamicHeight) < (1280/720)) || ((dynamicWidth / dynamicViewHeight) < (1280/720)) ) ?
                <View style={{
                  // width:'100%',
                  overflow:'hidden',
                  height:'100%',
                  // top:'0%',
                  // left:'0%',
                  
                  position:'absolute',
                  alignSelf:'center',
                  }}>
          <Video
            ref={video}
            style={{
              // width:screenSize.dynamicWidth,
              // top:'0%',
              // left:'0%',
              // right:'0%',
              // bottom:'0%',
              height:'100%',
              alignSelf:'center',
              // height:screenSize.dynamicHeight,
              // width:'100%',
              overflow:'hidden'
              // position:'absolute',
              // zIndex:-1
            }}
            // source={{
            //   uri: 'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
            // }}
            source={{
              uri: require('./images-and-vids/joined_skyline-skyscrapers-city-cityscape-traffic-road-street-aerial-view_s4dkw3c__e4ff81a4b71ed7b830304eeb3cee2a9c__P720_edit.mp4'),
            }}
            // useNativeControls
            // resizeMode="cover"
            resizeMode="cover"
            isLooping
            posterSource={{uri:require('./images-and-vids/home_image.jpg')}}
            shouldPlay={true}
            // onReadyForDisplay={statuss => setStatus(() => statuss)}
            // onPlaybackStatusUpdate={statuss => setStatus(() => statuss)}
          />
          </View>
        :
            <View style={{
              width:'100%',
              // top:'0%',
              // left:'0%',
              overflow:'hidden',
            position:'absolute',
            alignSelf:'center',
            }}>
            <Video
            ref={video}
            style={{
              // width:screenSize.dynamicWidth,
              // top:'0%',
              // left:'0%',
              alignSelf:'center',
              // right:'0%',
              // bottom:'0%',
              // height:'100%',
              // height:screenSize.dynamicHeight,
              width:'100%',
              overflow:'hidden'
              // position:'absolute',
              // zIndex:-1
            }}
            // source={{
            //   uri: 'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
            // }}
            source={{
              uri: require('./images-and-vids/joined_skyline-skyscrapers-city-cityscape-traffic-road-street-aerial-view_s4dkw3c__e4ff81a4b71ed7b830304eeb3cee2a9c__P720_edit.mp4'),
            }}
            // useNativeControls
            // resizeMode="contain"
            resizeMode="cover"
            isLooping
            posterSource={{uri:require('./images-and-vids/home_image.jpg')}}
            shouldPlay={true}
            // onReadyForDisplay={statuss => setStatus(() => statuss)}
            // onPlaybackStatusUpdate={statuss => setStatus(() => statuss)}
          />
          </View>
        }
          {/* <View style={{position:'absolute',top:'0%',left:'0%',height:'100%',width:'100%',backgroundColor:'rgba(0,0,0,0.4)'}}/> */}
      {/* </View> */}
      {/* </View> */}
      <View style={{
        // height:screenSize.dynamicHeight,
        // width:screenSize.dynamicWidth,
        width:'100%',
        height:'100%',
        top:'0%',
        left:'0%',
        // position:'absolute',
        backgroundColor:'rgba(0,0,0,0.4)',
        }} 

        >
            <View style= {{
              width: '100%',
              // top: '5%',
              left: '0%',
              // backgroundColor:theme_blue_color,
              // backgroundColor:'rgba(0,0,0,0.4)',
              height:100,
              flexDirection:'row',
              justifyContent:'space-around',
            }}>


                <View style={{
                  textAlign:'center',
                  textAlignVertical:'center',
                  
                  // height:'100%',
                  height:100,
                  width:'90%',
                  // position:'absolute',
                  alignSelf:'center',
                  alignItems:'center',
                  justifyContent:'center',
                  alignContent:'center',
                  // marginHorizontal:20,
                  // top:'15%',
                  // paddingHorizontal:20,
                }}> 
                      {/* <Text style={{
                        alignSelf:'center',
                        fontSize:40,
                        color:'#FFFFFF',
                        
                      // height:'100%',
                        width:'100%',
                        // height:100,
                        // position:'absolute',
                      textAlignVertical:'center',
                      textAlign:'center',
                      // fontWeight:'bold',
                      fontWeight:'600',
                      // top:'0%'
                      // long_logo
                      }}>
                        {"The TOXEUS Cloud Solver"}
                      </Text> */}
                      <Image 
                      style={{
                              resizeMode: "contain",
                              
                              top:'15%',
                              height:'75%',
                              width:'100%',
                              alignSelf:'center',
                              // top:'5%',
                      }} 
                      source={long_logo} 
                      />
                </View>

            </View>
            {/* <Authenticator hideDefault={true}>
                <SignIn/>
            </Authenticator> */}
            <Authenticator 

              // errorMessage={{
              //   "User does not exist.": "User does not exist.",
              // }}
              onStateChange={async(authState) => {
                console.log("authState CHANGED. authState:")
                console.log(authState)
                if(authState === 'signedIn'){

                } else if(authState === 'signedOut'){

                } else if (authState === "signIn") { 
                  
                } else if (authState === "signUp") {

                } else if (authState === "confirmSignUp") {

                } else if (authState === "forgotPassword") {

                } else {

                  // // signOut()
                  // await Auth.signOut()
                  //   .then(async() => {
                  //     // props.onStateChange('signedOut', null);
                  //     // count = 0
                  //     // no_subscriptions = false
                  //     await store.dispatch({ 
                  //       type: "update",
                  //       payload: {
                  //         input:{}
                  //       }
                  //     })
                  //   })
                  //   .catch(err => {
                  //     console.log('err: ', err)
                  //   })
                }
              }}
              
              signUpConfig={signUpConfig}
                // hideAllDefaults={true} 
                // signUpFields={[
                //     {
                //       label: 'Email',
                //       key: 'username',
                //       placeholder: 'Enter your email',
                //       required: true,
                //       displayOrder: 1,
                //       type: 'string'
                //     },
                //     {
                //       label: 'Password',
                //       key: 'password',
                //       placeholder: 'Enter your password',
                //       required: true,
                //       displayOrder: 2,
                //       type: 'password'
                //     }
                // ]}
                
                // usernameAttributes='email'
            >
                {/* <SignUp signUpFields={[
                    {
                      label: 'Email',
                      key: 'username',
                      placeholder: 'Enter your email',
                      required: true,
                      displayOrder: 1,
                      type: 'string'
                    },
                    {
                      label: 'Password',
                      key: 'password',
                      placeholder: 'Enter your password',
                      required: true,
                      displayOrder: 2,
                      type: 'password'
                    }
                ]} signUpConfig={signUpConfig}/> */}
            </Authenticator>
            <View style={{alignSelf:'center', flexDirection:'row', alignItems:'stretch',left:'0%',width:'100%',justifyContent:'flex-end'}}>
                  <TouchableOpacity style={{paddingBottom:10,marginHorizontal:15}} onPress={() => {
                    // setStatus(() => {
                    //   return {
                      WebBrowser.openBrowserAsync('https://www.toxeus.org/privacy-policy/');
                  }}>
                    <Text style={{color:"rgba(255,255,255,1)"}}>{"Privacy Policy"}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{paddingBottom:10,marginHorizontal:15}} onPress={() => {
                    // setStatus(() => {
                    //   return {
                      WebBrowser.openBrowserAsync('https://www.toxeus.org/terms-of-use/');
                  }}>
                    <Text style={{color:"rgba(255,255,255,1)"}}>{"Terms of Use"}</Text>
                  </TouchableOpacity>
                </View>
        </View>
     </View>
  )

}

// function  Show_modal_function (props) {
//   var modal_to_show = props.modal_to_show
//   console.log("hit")
//   if(typeof props.modalVisible == 'undefined'){
//     return null
//   }
//   var modalVisible = props.modalVisible
//   console.log("hit")
//    return (
//        <View>
//          <Modal
//          animationType="fade"
//          transparent={true}
//          // visible={modalVisible}
//          // onRequestClose={() => {
//          //   Alert.alert("Modal has been closed.");
//          //   setModalVisible(!modalVisible);
//          // }}
//        >
//          <View style={{
//                  flex: 1,
//                  justifyContent: "center",
//                  alignItems: "center",
//                  marginTop: 22
//                }}>
//            <View style={{
//                      margin: 20,
//                      backgroundColor: "white",
//                      borderRadius: 20,
//                      padding: 35,
//                      alignItems: "center",
//                      shadowColor: "#000",
//                      shadowOffset: {
//                        width: 0,
//                        height: 2
//                      },
//                      shadowOpacity: 0.25,
//                      shadowRadius: 4,
//                      elevation: 5
//                    }}>
//              <Text style={{
//                      marginBottom: 15,
//                      textAlign: "center"
//                    }}>{props.modalVisible[modal_to_show].text}</Text>
//              <Pressable
//                style={{
//                  borderRadius: 20,
//                  padding: 10,
//                  elevation: 2,  
//                  backgroundColor: "#2196F3"
//                }}
//                onPress={() => {
//                  // model_showing = false
//                  var modal_visible = props.modalVisible
//                  delete modal_visible[modal_to_show]
//                  props.setModalVisible(modal_visible)
//                }}
//              >
//                <Text style={{
//                    color: "white",
//                    fontWeight: "bold",
//                    textAlign: "center"
//                  }}>Close</Text>
//              </Pressable>
//            </View>
//          </View>
//        </Modal>
//        </View>
//      )
//  }

var first_render = false

var showDaytoCode = false



// var modal_visible3 = {decisionVariableString:{show:false,text:"Decision variables can only be defined using uppercase and lowercase letters."}}
// export default function App() {
  var dynamoDBclient = {}
  // var lambdaclient = {}
  var identityId = ""
  var dynamodb_query_params = {}
  var dynamoDBcommand = {}
  // var created_identityID = false
  var essential_credentials = {}
  var credentials = {}

  var  solution_results = {}
  var billing_json = {}

  var current_user = ""
  var initial_url = ''
  var count =0
  // var free_tier = false
  var redirect_from_successful_checkout = false
  var no_subscriptions = false
  var stripe_customer_accounts_table={}


// SEE THIS POST ABOUT HOW TO CATCH WARNINGS IN RN. 
// https://stackoverflow.com/questions/64158705/ignore-certain-console-errors-warnings-in-react
// HERE, WE CATCH THE WARNINGS THROWN BY THE AUTHENTICATOR WHEN THE USER DOES NOT EXIST
// AND WE SIGN THEM OUT. THIS PUSHES THEM BACK TO THE LOG-IN PAGE, OTHERWISE
// THE AUTHENTICATOR WILL LET THEM IN THE APP BUT JUST KEEP THROWING WARNINGS
  const consoleWarn = console.warn;
  const SUPPRESSED_WARNINGS = [
    // 'arning text - I will n',
    'Failed to verify contact'
  ];

  console.warn = function filterWarnings(msg, ...args) {
      if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
          consoleWarn(msg, ...args);
      } else {
            Auth.signOut().then(async() => {
              // props.onStateChange('signedOut', null);
              // count = 0
              // no_subscriptions = false
              // set_free_tier(false)
              await store.dispatch({ 
                type: "update",
                payload: {
                  input:{}
                }
              })
            })
            .catch(err => {
              console.log('err: ', err)
            })
      }
  };
  
  // console.warn('I\'ll appear as a warning');
  // console.warn('warning text - I will not');


function App() {
  useEffect(async() => {    
      // console.warn('I\'ll appear as a warning');
  // console.warn('warning text - I will not');

    // await set_show_privacy_policy_and_terms_of_use(true)
    // await set_hide_close_modal_button(true)
    // set_show_modal({show:true,text:create_stripe_user_text})
    // signOut()
    // return
      // var session = await Auth.currentSession()
      // console.log(session.accessToken.jwtToken)
      var new_user = await Auth.currentAuthenticatedUser();
      console.log("new_user")
      console.log(new_user)
      console.log("current_user")
      console.log(current_user)
      if(current_user != new_user){
        current_user = new_user
        // if (typeof new_user.attributes["custom:api_key"] != "undefined") {
        //   await setApiKey(new_user.attributes["custom:api_key"])
        //   await setHasApiKey(true)
        //   await setShow(false)
        //   await setShow(true)
        // }  


        












      // if(typeof user.attributes["custom:identity_ID"] == "undefined"){  
      //   // console.log("HIT")
      //     var created_identityID = true
      //     const credentials = await Auth.currentCredentials()
      //     essential_credentials =  await Auth.essentialCredentials(credentials)
          
      //     dynamoDBclient = await new DynamoDBClient({ 
      //       region: "us-west-2",
      //       // credentials: {sessionToken: jwtToken}
      //       credentials: essential_credentials
      //     });
      //     lambdaclient =await  new LambdaClient({ region: "us-west-2",credentials: essential_credentials});
      //     identityId = essential_credentials.identityId
      //     Auth.updateUserAttributes(user, {
      //       // 'custom:identity_ID': identityId,
      //       'custom:identity_ID': identityId,
      //     })
      // }
      // // FOUND HERE 
      // // https://stackoverflow.com/questions/66618911/getting-an-error-with-the-aws-sdk-for-translate
      // if(!created_identityID){
      //     const credentials = await Auth.currentCredentials()
      //     const essential_credentials =  await Auth.essentialCredentials(credentials)
      //     dynamoDBclient = await new DynamoDBClient({ 
      //       region: "us-west-2",
      //       // credentials: {sessionToken: jwtToken}
      //       credentials: essential_credentials
      //     });
      //     lambdaclient =await  new LambdaClient({ region: "us-west-2",credentials: essential_credentials});
      //     identityId = essential_credentials.identityId
      //     console.log(essential_credentials)
      // }

      var credentials = await Auth.currentCredentials()
      console.log("const credentials = await Auth.currentCredentials()")
      console.log(credentials)
      essential_credentials =  await Auth.essentialCredentials(credentials)
      console.log("essential_credentials =  await Auth.essentialCredentials(credentials)")
      console.log(essential_credentials) 



    //  var session = await Auth.currentSession()
      // var IdToken = await session.getIdToken()
      // var accesstoken = session.getAccessToken()
      // var jwtToken = await IdToken.getJwtToken()
      // console.log("var jwtToken = await IdToken.getJwtToken()")
      // console.log(jwtToken)
      
      // var dynamoDBclient = await new DynamoDBClient({ 
      //   region: "us-west-2",
      //   credentials: essential_credentials
      // });
      // lambdaclient =await  new LambdaClient({ 
      //   region: "us-west-2",
      //   credentials: essential_credentials
      // });
      // var payload = {AccessToken:accesstoken}
      // var paramss = {
      //   FunctionName: "linkIdentityidWithSub-dev",
      //   InvocationType: "RequestResponse",
      //   Payload:await JSON.stringify(payload)
      //   // Payload:{}
      // }
      // lambdaclient.send( new InvokeCommand(paramss))
      // console.log("lambdaclient.send(")



      // WE CAN REMOVE THIS NOW SINCE WE NO LONGER NEED TO CREATE AN api-key IMMEDIATELY
      // FOR EACH USER IF THEY DON'T HAVE ONE (WE CAN NOT GET IdentityId) IN LAMBDA FUNCTION
      // if (typeof new_user.attributes["custom:api_key"] == "undefined") {
      //     await createApiKey()
      // }






      // await store.dispatch({ 
      //   type: "update",
      //   payload: {
      //     input:{}
      //            }
      // })
      // console.log(" ")
      // console.log("store.getState().store")
      // console.log(store.getState().store)
      // console.log("store.getState().store.identityId")
      // console.log(store.getState().store.identityId)
      // var a = JSON.parse(JSON.stringify(await store.getState().store))
      // console.log("a")
      // console.log(Object.keys(a))
      // await store.dispatch({ 
      //   type: "update",
      //   payload: {
      //     input:{
      //       ...store.getState().store.identityId
      //     }
      //   }
      // })
      if(typeof store.getState().store.identityId == "undefined"){
          // console.log("identityID is undefined")
          identityId = essential_credentials.identityId
          // var state = await store.getState().store
          // state.identityId = identityId
          await store.dispatch({ 
            type: "update",
            payload: {
              input:{
                ...store.getState().store,
                identityId:identityId
              }
            }
          })
      } else {
          identityId = store.getState().store.identityId
          // console.log("identityID PULLED FROM REDUX")
      }




      initial_url = await Linking.getInitialURL()
      console.log("initial_url")
      console.log(initial_url)

      // IF THE USER WAS REDIRECTED FROM SUCCESSFUL STRIPE CHECKOUT, THE URL WILL BE
      // bbML5ujKmSnuE5YVCJPll2QbRkGMhTARRg-Aezd89v67IgbCdzLPQmmSayQHTPMthAoOw


      // console.log("initial_url")
      // console.log(initial_url)
      
      var url_data_indicating_user_was_redirected_from_stripe_billing_portal = "?redirect=bbML5ujKmSnuE5YVCJPll2QbRkGMhTARRg-Aezd89v67IgbCdzLPQmmSayQHTPMthAoOw"
      
      // CHECK IF THE initial_url CONTAINS THE STRING ABOVE
      if(initial_url.includes(url_data_indicating_user_was_redirected_from_stripe_billing_portal)){
        // SHOW THE MODAL THAT SAYS PLEASE WAIT A FEW SECONDS
        await set_hide_close_modal_button(true)
        await set_show_activity_indicator_in_modal(true)
        await set_show_modal({show:true,text:"Loading your account..."})
         // DELAY FOR 5 SECONDS BEFORE LOADING PAGE TO MAKE SURE BACKEND RESOURCES HAVE ENOUGH TIME TO UPDATE
         await new Promise(resolve => setTimeout(resolve, 5000));
          // HIDE THE MODAL
          await set_show_modal({show:false,text:""})
          await set_hide_close_modal_button(false)
          await set_show_activity_indicator_in_modal(false)
          // REDIRECT OR RESET THE URL TO THE HOME PAGE
          // await Linking.openURL("https://www.solver.toxeus.org/")
          // await Linking.openURL("http://192.168.0.134:19006")

      }


      var url_data_indicating_user_successfully_upgraded_account = "?active-subscription=true"
      
      // CHECK IF THE initial_url CONTAINS THE STRING ABOVE
      if(initial_url.includes(url_data_indicating_user_successfully_upgraded_account)){
        // SHOW THE MODAL THAT SAYS PLEASE WAIT A FEW SECONDS
        await set_hide_close_modal_button(false)
        await set_show_navigate_to_documentation_button(true)
        await set_show_modal({show:true,text:"Your Toxeus Cloud Solver account is all set! Ready to explore? Click the button below to learn how to make the most out of the Toxeus Cloud Solver. Let's get started!"})

      }



      var url_data_indicating_user_did_not_upgrade_account = "?active-subscription=false"

      // CHECK IF THE initial_url CONTAINS THE STRING ABOVE
      if(initial_url.includes(url_data_indicating_user_did_not_upgrade_account)){
        // SHOW THE MODAL THAT SAYS PLEASE WAIT A FEW SECONDS
        await set_hide_close_modal_button(false)
        await set_show_navigate_to_documentation_button(true)
        await set_show_upgrade_account_button(true)
        await set_show_modal({show:true,text:"You are currently using a free account with limited access to the Toxeus Cloud Solver. For guidance on getting started, please visit our documentation. If you need full access to all features, consider upgrading your account by clicking the button below. This upgrade will unlock advanced functionalities, enhancing your experience."})



      }



     
      // if(count==0){
      //   count = count +1
        await get_stripe_customer_account()
    //   }
    }


      // identityid = user.username
      // setShow(true)
      // console.log("user")
      // console.log(user)
      // var session = await Auth.currentSession()
      // // console.log("session")
      // // console.log(session)
      // identityid = session.accessToken.payload.username
      // console.log("identityid")
      // console.log(identityid)
      
      // console.log(user.attributes.custom:api_key)
      // var { attributes } = user;
      // console.log(user)
      // console.log(attributes["custom:api_key"])

      // console.log((Auth.currentAuthenticatedUser()).attributes["custom:api_key"])
      // accessToken
      // console.log(Auth.currentSession())
      // console.log(Auth.currentCredentials())
      // console.log(Auth.currentAuthenticatedUser())
      // console.log(Auth.currentUserCredentials())
      // console.log(Auth.Credentials.currentCredentials())
      // var session = await Auth.currentSession()
      // var IdToken = await session.getIdToken()
      // var accesstoken = session.getAccessToken()
      // var jwtToken = await IdToken.getJwtToken()

      // const client = new DynamoDBClient({ region: "us-west-2" });
      // const command = new ListTablesCommand({});
      
      // SEE LINKS BELOW FOR dynamoDB INPUT STRUCTURE
      // https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/LegacyConditionalParameters.KeyConditions.html
      // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-dynamodb/interfaces/querycommandinput.html#keyconditionexpression 
      
      // await dynamoDBclient.middlewareStack.add(
      //   (next, context) => (args) => {
      //     args.request.headers["Authorization"] = jwtToken;
      //     console.log("\n -- printed from inside middleware -- \n");
      //     return next(args);
      //   },
      //   {
      //     step: "build",
      //   }
      // )

      // console.log(results.TableNames.join("\n"));







//       // var jwtToken = ""
//       // console.log(jwtToken)
//       // console.log(session.accessToken.jwtToken)
//       // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{authentication:IdToken.jwtToken}})
//       //Authorization
//   //     var lambdaUrl = "https://ek6fv78l4d.execute-api.us-west-2.amazonaws.com/default/-"
//   //     var api_key = "XlwYKdiyaSxfV0wPreH2qlXZNAqvN0KywFVLsh3b0FrADMg2AXWG1uvWrVn820bk7BY2yABc8ociZAwBNoXlOZkRx"
//   // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{"x-api-key":api_key}})
//       // var payload = {IdToken:IdToken}
//       // console.log(limsParsed)
//       var payload  = {
//         // AccessToken:accesstoken,
//         // variables: Array.from(dec_var_list_set),
//         // objective: obj_func, 
//         // constraints: constraintss, 
//         // nPoints:Number(nPoints.value), 
//         // Lims:limsParsed, 
//         // minDistance:Number(minDistance.value),
//         // MaxObjectiveFunctionValue:Number(MaxObjectiveFunctionValue.value), 
//         // problemType:ProblemType,
//         // invocationType:invocationType,
//         // saveSolutions:saveSolutions
//         "TableName": "solutions-table",
//         "Key": {
//             "user_id": {"S": "7d093ec5-cf66-465a-974f-cab3aa49c620"},
//             "sort_key": {"S": "(1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2:0<1:0.01:0.01:10:synchronous:19c8e18d-d6cc-46dc-852f-f6106694cdfa:0f7e4caa-f93b-4844-ac48-c4b698dde5eb"}
//         },
//         "Key": {
//             "user_id": {"S": "7d093ec5"},
//             "sort_key": {"S": "(1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2:0<1:0.01:0.01:10:synchronous:19c8e18d-d6cc-46dc-852f-f6106694cdfa:f75f419a-1d40-4d29-b6c8-f0f296cb977e"}
//         }
//       }
//       // console.log(payload)
//       // var data = new FormData();
//       // await data.append( "json", JSON.stringify( payload ) );
  
  
//       // var jwtToken = ""
//       // console.log(jwtToken)
//       // console.log(session.accessToken.jwtToken)
//       // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{authentication:IdToken.jwtToken}})
//       //Authorization
//   //     var lambdaUrl = "https://ek6fv78l4d.execute-api.us-west-2.amazonaws.com/default/-"
//   //     var api_key = "XlwYKdiyaSxfV0wPreH2qlXZNAqvN0KywFVLsh3b0FrADMg2AXWG1uvWrVn820bk7BY2yABc8ociZAwBNoXlOZkRx"
//   // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{"x-api-key":api_key}})
  
//   // BELOW METHOD IS FOR INVOKING THE FUNCTION VIA COGNITO SESSION TOKEN. YOU NEED TO USE THE NON-api.toxeus.org/- URL
//   var lambdaUrlDynamoDB = "https://dynamodb.us-west-2.amazonaws.com//"
//   await fetch(lambdaUrlDynamoDB, {method:"POST",body:JSON.stringify(payload),headers:{Authorization:jwtToken,"X-Amz-Target":"DynamoDB_20120810.GetItem","Accept-Encoding":"identity"}})
//     .then(async(result) => {
//     var resultjson = await result.json()
//     console.log(resultjson)
//     // // resulttt = resultjson
//     // // resulttt = JSON.parse(resultjson.body)
//     // // console.log(resulttt)
//     // Auth.updateUserAttributes(user, {
//     //   'custom:api_key_ID': resultjson.api_key_ID,
//     // })
//     // // NEED TO ADD HERE INVOCATION OF LAMBDA FUNCTION WHICH ISSUES API KEY
//     // setApiKey(newkey)
//     // setHasApiKey(true)
//     // setShowApiKey(true)
//     // setAPIKeyButtonColor(theme_blue_color)
//     // setchangeAPIKeyWarningGiven(false)
// })

    }, []);



  const [show_change_api_key_button,set_show_change_api_key_button] = useState(false)
  const change_api_key_warning_text = 'Changing your API key will automatically disable your previously issued API key.'

  const [show_delete_api_key_button,set_show_delete_api_key_button] = useState(false)
  const delete_api_key_warning_text = 'Deleting your API key will automatically disable your current API key.'


  const [free_tier,set_free_tier] = useState(false)
  const [stripe_customer_accounts_table_query, set_stripe_customer_accounts_table_query] = useState({})
  const [has_stripe_account,set_has_stripe_account] = useState(false)

  const [objectiveFunction, setobjectiveFunction] = useState("(1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2");
  // const [mindistance, setminDistance] = useState("");

  const [sneminDistance, setsneminDistance] = useState(0.01);
  // const [nPoints, setnPoints] = useState("");

  const [snenPoints, setsnenPoints] = useState(10);
  // const [Lims, setLims] = useState([]);



  const [sneLims, setsneLims] = useState(JSON.parse('{"x":[-0.5,3], "y":[-0.5,3], "z":[-0.5,3]}'));

  // const [ProblemType, setProblemType] = useState("optimization")
  const [ProblemType, setProblemType] = useState("account")

  // const [LatexString, setLatexString] = useState("$$$$")

  const[sneCustomLims, setsneCustomLims] = useState(false)

  const [sneMaxObjectiveFunctionValue, setsneMaxObjectiveFunctionValue] = useState(0.01)
  const[isProcessing,setisProcessing] = useState(false)
  // const[SolverRuntime, setSolverRuntime] = useState(0)
  // var [textInput, setTextInput] = useState([])

  // const buttonColor = "black"
  // const [inputData, setTextInputText] = useState([])
  // const [inputdatastring,setids] = useState("")
  const [textInputValues, settextInputValues]= useState([""])
  // const [textInputValuess, settextInputValuess]= useState([""])
  // const [textInputValuess, settextInputValuess]= useState([])
  // const [inputsValid, setInputsValid]= useState([true])
  const [SNEInputsValid, setSNEInputsValid]= useState([false])
  const [focusedIndex, setfocusedIndex] = useState(null)
  const [apiKey,setApiKey]= useState(null)
  const [hasApiKey,setHasApiKey] = useState(false)
  const [showApiKey,setShowApiKey] = useState(false)
  const [changeAPIKeyWarningGiven,setchangeAPIKeyWarningGiven] = useState(false)
  // const [APIKeyButtonColor,setAPIKeyButtonColor] =useState("#0B2E59")
  const [APIKeyButtonColor,setAPIKeyButtonColor] = useState('dodgerblue')
  const [showAPIModal, setShowAPIModal] = useState(false)
  const [invocationType,setInvocationType] = useState("synchronous")
  const [saveSolutions,setSaveSolutions] = useState("true")
  // const [initialSearchRegion, setInitialSearchRegion]= useState(["-100 <= x <= 100", "-100 <= y <= 100", "-100 <= z <= 100",])
  // const [initialSearchRegion, setInitialSearchRegion]= useState([
  //   {limits:"[-0.5,3]",variable:"x",variable_is_defined:true,variable_is_defined_color:'black',is_parsable:true,non_default_limits:false},
  //   {limits:"[-0.5,3]",variable:"y",variable_is_defined:true,is_parsable:true,variable_is_defined_color:'black',non_default_limits:false},
  //   {limits:"[-0.5,3]",variable:"z",variable_is_defined:true,is_parsable:true,variable_is_defined_color:'black',non_default_limits:false}
  // ])
  

  // const [defaultSearchRegion, setDefaultSearchRegion]=useState('-0.5,3')
  // const [defaultSearchRegion, setDefaultSearchRegion]=useState({
  //   lower:{
  //     value:"-0.5",
  //     is_parsable:true
  //   },
  //   upper:{
  //     value:"3",
  //     is_parsable:true
  //   }
  // })
  const original_defaultSearchRegion={
    lower:{
      value:"-0.5",
      is_parsable:true
    },
    upper:{
      value:"3",
      is_parsable:true
    }
  }

  const default_initial_search_region ={
    x:{
      limits:{
        lower:{
          value:"-0.5",
          is_parsable:true
        },
        upper:{
          value:"3",
          is_parsable:true
        }
      },
      variable_is_defined:true,
      variable_is_defined_color:'black',
      non_default_limits:false,
      show_error_modal:false,
    },
    y:{
      limits:{
        lower:{
          value:"-0.5",
          is_parsable:true
        },
        upper:{
          value:"3",
          is_parsable:true
        }
      },
      variable_is_defined:true,
      variable_is_defined_color:'black',
      non_default_limits:false,
      show_error_modal:false,
    },
    z:{
      limits:{
        lower:{
          value:"-0.5",
          is_parsable:true
        },
        upper:{
          value:"3",
          is_parsable:true
        }
      },
      variable_is_defined:true,
      variable_is_defined_color:'black',
      non_default_limits:false,
      show_error_modal:false,
    }
  }

  // const defaultSearchRegion = {
  //   lower:{
  //     value:"-0.5",
  //     is_parsable:true
  //   },
  //   upper:{
  //     value:"3",
  //     is_parsable:true
  //   }
  // }

  // const [decisionVariableList,setDecisionVariableList]=useState(["x","y","z"])



  // const [show_initial_search_region_button_text,set_show_initial_search_region_button_text]=useState("Show All Decision Variables & The Initial Search Region")


  // const [initial_search_region_header_border_width, set_initial_search_region_header_border_width]=useState(0)

  // const [initial_search_region_header_color, set_initial_search_region_header_color]=useState("#1c76e3")
  // const [initial_search_region_header_color, set_initial_search_region_header_color]=useState("")
  // const [edit_initial_search_region, set_edit_initial_search_region] = useState(false)
  // const [edit_initial_search_region_button_text, set_edit_initial_search_region_button_text] = useState("Edit")
const [show_activity_indicator_in_modal,set_show_activity_indicator_in_modal] = useState(false)
const [show_navigate_to_documentation_button,set_show_navigate_to_documentation_button] = useState(false)

  const [initial_search_region_header_text, set_initial_search_region_header_text]=useState("Initial Search Region")
  const [undefined_variable_delete_warning_message_shown,set_undefined_variable_delete_warning_message_shown] = useState(false)
  const [undefined_variable_icon,set_undefined_variable_icon] = useState({name:"alert-triangle",color:"orange"})
  const[show_objective_function,set_show_objective_function]=useState(false)
  const[show_objective_function_button_name,set_show_objective_function_button_name]=useState("caretdown")
  const[show_objective_function_button_color,set_show_objective_function_button_color]=useState(theme_blue_color)
  // const [sne_decision_variable_string,set_sne_decision_variable_string]=useState({value:"x, y, z"})
  const [sne_decision_variable_string,set_sne_decision_variable_string]=useState({value:""})
  const [sne_constraints_section_title,set_sne_constraints_section_title]=useState({value:"Constraints (3)"})
  const sne_constraints_section_description = 'The system of nonlinear equations and inequalities to be solved. For example: \n1/x + y + z - 3 = 0\nx + 1/y + z - 3 >= 0\nx + y + 1/z - 3 < 0'
  const [sne_textInputValuess, set_sne_textInputValuess]=useState({values:["1/x + y + z - 3 = 0","x + 1/y + z - 3 >= 0","x + y + 1/z - 3 < 0"]})
  const [sne_showDecisionVariables,set_sne_showDecisionVariables] = useState({value:true})
  const [sne_dec_var_list_set,set_sne_dec_var_list_set] = useState({variables:new Set(["x","y","z"])})
  const [sne_show_decision_variables_button_color, set_sne_decision_variables_button_color]=useState({color:theme_blue_color})
  const [sne_show_decision_variables_button_name,set_sne_show_decision_variables_button_name]=useState({name:"caretup"})
  const [sne_show_constraints,set_sne_show_constraints]=useState({value:false})
  const [sne_show_constraints_button_name,set_sne_show_constraints_button_name]=useState({name:"caretdown"})
  const[sne_show_constraints_button_color,set_sne_show_constraints_button_color]=useState({color:theme_blue_color})
  const[sne_show_starting_points,set_sne_show_starting_points]=useState({value:false})
  const[sne_show_starting_points_button_name,set_sne_show_starting_points_button_name]=useState({name:"caretdown"})
  const[sne_show_starting_points_button_color,set_sne_show_starting_points_button_color]=useState({color:theme_blue_color})
  const[sne_show_minimum_distance,set_sne_show_minimum_distance]=useState({value:false})
  const[sne_show_minimum_distance_button_name,set_sne_show_minimum_distance_button_name]=useState({name:"caretdown"})
  const[sne_show_minimum_distance_button_color,set_sne_show_minimum_distance_button_color]=useState({color:theme_blue_color})
  const [sne_minDistance, set_sne_minDistance] = useState({value:0.01});

  const [sne_isInitialSearchRegionValid,set_sne_IsInitialSearchRegionValid]=useState({value:true})
  const [sne_show_initial_search_region, set_sne_show_initial_search_region]=useState({value:false})
  const [sne_show_initial_search_region_button_text_color, set_sne_show_initial_search_region_button_text_color]=useState({color:theme_blue_color})
  const [sne_initial_search_region_button_name,set_sne_initial_search_region_button_name]=useState({name:"caretdown"})
  const sne_initial_search_region_section_description = 'The region over which initial starting points for the nonlinear system solver will be selected.'
  const [sne_nPoints, set_sne_nPoints] = useState({value:1});
  const sne_n_starting_points_section_description = "The Number of Starting Points within the Initial Search Region to begin searching for solutions from."
  const [sne_initialSearchRegionv2, set_sne_InitialSearchRegionv2]= useState({region:JSON.parse(JSON.stringify(default_initial_search_region))})
  const [sne_inputsValid, set_sne_InputsValid]= useState({values:[true,true,true]})
  const [sne_MaxObjectiveFunctionValue, set_sne_MaxObjectiveFunctionValue] = useState({value:0.01})
  const[sne_show_max_obj_func_val_button_color,set_sne_show_max_obj_func_val_button_color]=useState({color:theme_blue_color})
  const [sne_show_max_obj_func_val_button_name,set_sne_show_max_obj_func_val_button_name]=useState({name:"caretdown"})
  const [sne_show_max_obj_func_val,set_sne_show_max_obj_func_val]=useState({value:false})
  const [sne_max_obj_func_val_section_description,set_sne_max_obj_func_val_section_description]=useState('Only approximate solutions to the nonlinear system with a smaller error value than the "Maximum Acceptable Error" will be returned.')// The error for a single constraint is defined as the amount that constraint is violated, and the total error for the nonlinear system is the summation of the violations for all constraints.this calculated by summing up the taking the sum mation of the summing the amount taking the summation taking the summation  of the absolute values of the left hand side of each equation.')
  const [sne_max_obj_func_val_section_title,set_sne_max_obj_func_val_section_title]=useState("Maximum Acceptable Error")
  const [sne_Lims, set_sne_Lims] = useState({value:JSON.parse('{"x":[-0.5,3], "y":[-0.5,3], "z":[-0.5,3]}')})
  const [sne_CustomLims, set_sne_CustomLims] = useState({value:false})
  const [sne_SolutionsReturned, set_sne_SolutionsReturned] = useState({value:false})
  const [sne_response, set_sne_Response] = useState({value:{}});
  const [sne_min_distance_warning, set_sne_min_distance_warning]=useState({value:false})
  const [sne_max_obj_func_val_warning, set_sne_max_obj_func_val_warning]=useState({value:false})
  const [sne_nPoints_warning, set_sne_nPoints_warning]=useState({value:false})
  const sne_minimum_distance_section_description = 'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions.'// If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower error will be returned.'
  const [sne_edit_constraints,set_sne_edit_constraints]=useState({value:false})
  const [sne_edit_constraints_button_name,set_sne_edit_constraints_button_name]=useState({name:'Edit Constraints'})
  const [sne_defaultSearchRegion,set_sne_defaultSearchRegion]=useState({region:JSON.parse(JSON.stringify(original_defaultSearchRegion))})
  const [sne_show_problem_tags_and_description_button_name,set_sne_show_problem_tags_and_description_button_name]=useState({name:"caretdown"})
  const [sne_show_problem_tags_and_description_button_color,set_sne_show_problem_tags_and_description_button_color]=useState({color:theme_blue_color})
  const [sne_show_problem_tags_and_description, set_sne_show_problem_tags_and_description]=useState({value:false})
  const [sne_problem_tags_and_description, set_sne_problem_tags_and_description] = useState({tags:[],description:''})
  const [sne_problem_tags_and_description_string, set_sne_problem_tags_and_description_string]=useState({value:''})
  const sne_max_obj_func_val_error = "Please ensure that the Maximum Acceptable Error is expressed as a positive number such as \n\n0.015\n\nor\n\n1.5e-2"
  const [sne_download_solutions_button_disabled, set_sne_download_solutions_button_disabled]=useState({value:true})
  const [sne_download_solutions_button_background_color, set_sne_download_solutions_button_background_color]=useState({color:theme_gray_color})


  // const [opt_decision_variable_string,set_opt_decision_variable_string]=useState({value:"x, y, z"})
  const [opt_decision_variable_string,set_opt_decision_variable_string]=useState({value:""})
  const [opt_constraints_section_title,set_opt_constraints_section_title]=useState({value:"Constraints (0)"})
  const opt_constraints_section_description = 'The constraints of the optimization problem. For example: \n-0.5 <= x\nx > 3\ny = 1'
  const [opt_textInputValuess, set_opt_textInputValuess]=useState({values:[]})
  const [opt_showDecisionVariables,set_opt_showDecisionVariables] = useState({value:true})
  const [opt_dec_var_list_set,set_opt_dec_var_list_set] = useState({variables:new Set(["x","y","z"])})
  const [opt_show_decision_variables_button_color, set_opt_decision_variables_button_color]=useState({color:theme_blue_color})
  const [opt_show_decision_variables_button_name,set_opt_show_decision_variables_button_name]=useState({name:"caretup"})
  const [opt_show_constraints,set_opt_show_constraints]=useState({value:false})
  const [opt_show_constraints_button_name,set_opt_show_constraints_button_name]=useState({name:"caretdown"})
  const[opt_show_constraints_button_color,set_opt_show_constraints_button_color]=useState({color:theme_blue_color})
  const[opt_show_starting_points,set_opt_show_starting_points]=useState({value:false})
  const[opt_show_starting_points_button_name,set_opt_show_starting_points_button_name]=useState({name:"caretdown"})
  const[opt_show_starting_points_button_color,set_opt_show_starting_points_button_color]=useState({color:theme_blue_color})
  const[opt_show_minimum_distance,set_opt_show_minimum_distance]=useState({value:false})
  const[opt_show_minimum_distance_button_name,set_opt_show_minimum_distance_button_name]=useState({name:"caretdown"})
  const[opt_show_minimum_distance_button_color,set_opt_show_minimum_distance_button_color]=useState({color:theme_blue_color})
  const [opt_minDistance, set_opt_Distance] = useState({value:0.01});

  const [opt_isInitialSearchRegionValid,set_opt_IsInitialSearchRegionValid]=useState({value:true})
  const [opt_show_initial_search_region, set_opt_show_initial_search_region]=useState({value:false})
  const [opt_show_initial_search_region_button_text_color, set_opt_show_initial_search_region_button_text_color]=useState({color:theme_blue_color})
  const [opt_initial_search_region_button_name,set_opt_initial_search_region_button_name]=useState({name:"caretdown"})
  const opt_initial_search_region_section_description = 'The region over which initial starting points for the optimization solver will be selected.'
  const [opt_nPoints, set_opt_nPoints] = useState({value:1});
  const [opt_initialSearchRegionv2, set_opt_InitialSearchRegionv2]= useState({region:JSON.parse(JSON.stringify(default_initial_search_region))})
  const [opt_inputsValid, set_opt_InputsValid]= useState({values:[]})
  const opt_n_starting_points_section_description = 'The Number of Starting Points within the Initial Search Region to perform optimization from.'

  const [opt_MaxObjectiveFunctionValue, set_opt_MaxObjectiveFunctionValue] = useState({value:""})

  const[opt_show_max_obj_func_val_button_color,set_opt_show_max_obj_func_val_button_color]=useState({color:theme_blue_color})
  const [opt_show_max_obj_func_val_button_name,set_opt_show_max_obj_func_val_button_name]=useState({name:"caretdown"})
  const [opt_show_max_obj_func_val,set_opt_show_max_obj_func_val]=useState({value:false})
  const [opt_max_obj_func_val_section_description,set_opt_max_obj_func_val_section_description]=useState('The Maximum Acceptable Objective Function Value that a solution can have for the solver to return it. The solver will only return solutions which have an objective function value lower than the Maximum Acceptable Objective Function Value parameter.')//  that the solver will return. If the solver finds a solution with an objective function value greater than the "Maximum Objective Function Value", it will return the best solution it has found so far.')
  const [opt_max_obj_func_val_section_title,set_opt_max_obj_func_val_section_title]=useState("Maximum Acceptable Objective Function Value")
  const [opt_Lims, set_opt_Lims] = useState({value:JSON.parse('{"x":[-0.5,3], "y":[-0.5,3], "z":[-0.5,3]}')});
  const [opt_CustomLims, set_opt_CustomLims] = useState({value:false})
  const [opt_SolutionsReturned, set_opt_SolutionsReturned] = useState({value:false})
  const [opt_response, set_opt_Response] = useState({value:{}});
  const [opt_min_distance_warning, set_opt_min_distance_warning]=useState({value:false})
  const [opt_max_obj_func_val_warning, set_opt_max_obj_func_val_warning]=useState({value:false})
  const [opt_nPoints_warning, set_opt_nPoints_warning]=useState({value:false})
  const opt_minimum_distance_section_description = 'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions.'// If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'
  const [opt_edit_constraints,set_opt_edit_constraints]=useState({value:false})
  const [opt_edit_constraints_button_name,set_opt_edit_constraints_button_name]=useState({name:'Edit Constraints'})
  const [opt_defaultSearchRegion,set_opt_defaultSearchRegion]=useState({region:JSON.parse(JSON.stringify(original_defaultSearchRegion))})
  const [opt_show_problem_tags_and_description_button_name,set_opt_show_problem_tags_and_description_button_name]=useState({name:"caretdown"})
  const [opt_show_problem_tags_and_description_button_color,set_opt_show_problem_tags_and_description_button_color]=useState({color:theme_blue_color})
  const [opt_show_problem_tags_and_description, set_opt_show_problem_tags_and_description]=useState({value:false})
  const [opt_problem_tags_and_description, set_opt_problem_tags_and_description] = useState({tags:[],description:''})
  const [opt_problem_tags_and_description_string, set_opt_problem_tags_and_description_string]=useState({value:''})
  const opt_max_obj_func_val_error = "Please ensure that the Maximum Acceptable Objective Function Value is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"
  const [opt_download_solutions_button_disabled, set_opt_download_solutions_button_disabled]=useState({value:true})
  const [opt_download_solutions_button_background_color, set_opt_download_solutions_button_background_color]=useState({color:theme_gray_color})

  // const [opt_show, set_opt_Show] = useState({value:true})
  // const [decisionVariableString,setDecisionVariableString]=useState("x, y, z")
  // var decision_variable_string = sne_decision_variable_string
  // const [decisionVariableString,setDecisionVariableString]=useState(sne_decision_variable_string.value)


  const [textInputValuess, settextInputValuess]= useState(opt_textInputValuess)
  const [constraints_section_description,set_constraints_section_description]=useState(opt_constraints_section_description)
  const [constraints_section_title,set_constraints_section_title]=useState(opt_constraints_section_title)
  const [decisionVariableString,setDecisionVariableString]=useState(opt_decision_variable_string)
  const [showDecisionVariables,setshowDecisionVariables] = useState(opt_showDecisionVariables)
  const [dec_var_list_set,set_dec_var_list_set] = useState(opt_dec_var_list_set)
  const [show_decision_variables_button_color, set_show_decision_variables_button_color]=useState(opt_show_decision_variables_button_color)
  const [show_decision_variables_button_name,set_show_decision_variables_button_name]=useState(opt_show_decision_variables_button_name)
  const [show_constraints,set_show_constraints]=useState(opt_show_constraints)
  const[show_constraints_button_name,set_show_constraints_button_name]=useState(opt_show_constraints_button_name)
  const[show_constraints_button_color,set_show_constraints_button_color]=useState(opt_show_constraints_button_color)
  const[show_starting_points,set_show_starting_points]=useState(opt_show_starting_points)
  const[show_starting_points_button_name,set_show_starting_points_button_name]=useState(opt_show_starting_points_button_name)
  const[show_starting_points_button_color,set_show_starting_points_button_color]=useState(opt_show_starting_points_button_color)
  const[show_minimum_distance,set_show_minimum_distance]=useState(opt_show_minimum_distance)
  const[show_minimum_distance_button_name,set_show_minimum_distance_button_name]=useState(opt_show_minimum_distance_button_name)
  const[show_minimum_distance_button_color,set_show_minimum_distance_button_color]=useState(opt_show_minimum_distance_button_color)
  const [minDistance, setminDistance] = useState(opt_minDistance);
  

  const [isInitialSearchRegionValid,setIsInitialSearchRegionValid]=useState(opt_isInitialSearchRegionValid)
  const [show_initial_search_region, set_show_initial_search_region]=useState(opt_show_initial_search_region)
  const [show_initial_search_region_button_text_color, set_show_initial_search_region_button_text_color]=useState(opt_show_initial_search_region_button_text_color)
  const [initial_search_region_button_name,set_initial_search_region_button_name]=useState(opt_initial_search_region_button_name)
  const [nPoints, setnPoints] = useState(opt_nPoints);
  const [initialSearchRegionv2, setInitialSearchRegionv2]= useState(opt_initialSearchRegionv2)
  const [inputsValid, setInputsValid]= useState(opt_inputsValid)
  const [n_starting_points_section_description,set_n_starting_points_section_description]=useState(opt_n_starting_points_section_description)
  const [MaxObjectiveFunctionValue, setMaxObjectiveFunctionValue] = useState(opt_MaxObjectiveFunctionValue)
  const[show_max_obj_func_val_button_color,set_show_max_obj_func_val_button_color]=useState(opt_show_max_obj_func_val_button_color)
  const [show_max_obj_func_val_button_name,set_show_max_obj_func_val_button_name]=useState(opt_show_max_obj_func_val_button_name)
  const [show_max_obj_func_val,set_show_max_obj_func_val]=useState(opt_show_max_obj_func_val)
  const [max_obj_func_val_section_description,set_max_obj_func_val_section_description]=useState(opt_max_obj_func_val_section_description)
  const [max_obj_func_val_section_title,set_max_obj_func_val_section_title]=useState(opt_max_obj_func_val_section_title)
  const [initial_search_region_section_description,set_initial_search_region_section_description]=useState(opt_initial_search_region_section_description)
  const [Lims, setLims] = useState(opt_Lims);
  const [CustomLims, setCustomLims] = useState(opt_CustomLims)
  const [SolutionsReturned, setSolutionsReturned] = useState(opt_SolutionsReturned)
  const [response, setResponse] = useState(opt_response);
  const [min_distance_warning, set_min_distance_warning]=useState(opt_min_distance_warning)
  const [max_obj_func_val_warning, set_max_obj_func_val_warning]=useState(opt_max_obj_func_val_warning)
  const [nPoints_warning, set_nPoints_warning]=useState(opt_nPoints_warning)
  const [minimum_distance_section_description,set_minimum_distance_section_description]=useState(opt_minimum_distance_section_description)
  const [edit_constraints,set_edit_constraints]=useState(opt_edit_constraints)
  const [edit_constraints_button_name,set_edit_constraints_button_name]=useState(opt_edit_constraints_button_name)
  const [defaultSearchRegion,set_defaultSearchRegion]=useState(opt_defaultSearchRegion)
  const [show_problem_tags_and_description_button_name,set_show_problem_tags_and_description_button_name]=useState(opt_show_problem_tags_and_description_button_name)
  const [show_problem_tags_and_description_button_color,set_show_problem_tags_and_description_button_color]=useState(opt_show_problem_tags_and_description_button_color)
  const [show_problem_tags_and_description, set_show_problem_tags_and_description]=useState(opt_show_problem_tags_and_description)
  const [problem_tags_and_description_string, set_problem_tags_and_description_string]=useState(opt_problem_tags_and_description_string)
  const [problem_tags_and_description, set_problem_tags_and_description] = useState(opt_problem_tags_and_description)
  const [recommended_problem_tags_and_description,set_recommended_problem_tags_and_description]=useState(new Set([]))
  const constraints_error_text = 'Please ensure each inequality or equality constraint is defined using one of the following operators: \n=\n>=\n<=\n>\n<'
  const initial_search_region_bounds_error_text = 'One or more of the Initial Search Region bounds contain undefined variables (variables not currently defined in the "Decision Variables" section) or upper or lower bounds that we can not interpet. Please resolve the issues shown in the "Initial Search Region" section so we can properly interpet the problem you would like to solve.'
  const default_lower_bound_error_text = "We are unable to interpret the Default Lower Bound for the Initial Search Region. Please ensure that the Default Lower Bound is expressed as a number such as \n\n-150\n\nor\n\n-1.5e2"
  const default_upper_bound_error_text = "We are unable to interpret the Default Upper Bound for the Initial Search Region. Please ensure that the Default Upper Bound is expressed as a number such as \n\n150\n\nor\n\n1.5e2"
  const minimum_distance_error_text = "Please ensure that the Minimum Acceptable Distance Between Unique Solutions is expressed as a non-negative number such as \n\n0\n\nor\n\n0.015\n\nor\n\n1.5e-2"
  const decision_variables_error_text = "Please define Decision Variables using only be defined using uppercase and lowercase letters."
  const n_starting_points_error_text = "Please ensure that the Number of Starting Points is expressed as a positive integer. For example:\n\n100\n\nor\n\n1e2"
  const free_tier_error_text = 'To prevent abuse of our free tier, unfortunately we must limit free tier users to entering problems with a maximum of 5 Decision variables, 5 Constraints, and 5 Starting Points.\n\nIf you would like to solve a larger problem, please upgrade your account by clicking the "UPGRADE ACCOUNT" button shown below.'
  const [show_upgrade_account_button,set_show_upgrade_account_button]=useState(false)
  const problem_tags_and_description_section_title = 'Problem Tags and Description'
  const problem_tags_and_description_section_description = 'Tag your problem with keywords that describe the problem. For example, if your problem is about finding the shortest path between two points on a graph, you might tag it as "shortest-path" or "graph". Tags can contain lowercase letters, numbers, hyphens, and underscores. You can add as many tags as you like.\n\nYou can also add a description of your problem. For example, if your problem is about finding the shortest path between two points on a graph, you might add a description of "Find the shortest path between two points on a graph".'
  const [download_solutions_button_disabled, set_download_solutions_button_disabled]=useState(opt_download_solutions_button_disabled)
  const [download_solutions_button_background_color, set_download_solutions_button_background_color]=useState(opt_download_solutions_button_background_color)


  const create_stripe_user_text = 'By using the Toxeus Cloud Solver, you agree to our Terms of Use and Privacy Policy.'
  const [show_privacy_policy_and_terms_of_use, set_show_privacy_policy_and_terms_of_use] = useState(false)

  const [sneSolutionsReturned, setsneSolutionsReturned] = useState(false)
  const [sneresponse, setsneResponse] = useState({});








  // const max_solutions_to_return_section_title = 'Maximum Runtime'
  // const max_solutions_to_return_section_description = 'The maximum amount of time (in seconds) that the solver will run before it stops. If the solver does not stop before the maximum runtime, it will stop after the maximum runtime.'
  // const [sne_max_solutions_to_return,set_sne_max_solutions_to_return]=useState({value:(60*15)})
  // const [opt_max_solutions_to_return,set_opt_max_solutions_to_return]=useState({value:(60*15)})
  // const [max_solutions_to_return, set_max_solutions_to_return] = useState(opt_max_solutions_to_return)
  const [sne_max_solutions_to_return_section_description,set_sne_max_solutions_to_return_section_description]=useState('The maximum number of feasible solutions the solver will return.')
  const [opt_max_solutions_to_return_section_description,set_opt_max_solutions_to_return_section_description]=useState('The maximum number of solutions to be returned by the solver. If this parameter is set to "1", the solver will only return the best solution found. If this parameter is set to a number greater than "1", the solver may also return additional solutions which have been found. The solutions returned will be sorted by objective function value from best to worst.')
  const [max_solutions_to_return_section_description,set_max_solutions_to_return_section_description]=useState(opt_max_solutions_to_return_section_description)
  const [sne_max_solutions_to_return_section_title,set_sne_max_solutions_to_return_section_title]=useState("Maximum Number of Feasible Solutions to Return")
  const [opt_max_solutions_to_return_section_title,set_opt_max_solutions_to_return_section_title]=useState("Maximum Number of Solutions to Return")
  const [max_solutions_to_return_section_title,set_max_solutions_to_return_section_title]=useState(opt_max_solutions_to_return_section_title)
  const [sne_show_max_solutions_to_return, set_sne_show_max_solutions_to_return] = useState({value:false})
  const [opt_show_max_solutions_to_return, set_opt_show_max_solutions_to_return] = useState({value:false})
  const [show_max_solutions_to_return, set_show_max_solutions_to_return] = useState(opt_show_max_solutions_to_return)
  const [sne_show_max_solutions_to_return_button_name, set_sne_show_max_solutions_to_return_button_name] = useState({name:'caretdown'})
  const [opt_show_max_solutions_to_return_button_name, set_opt_show_max_solutions_to_return_button_name] = useState({name:'caretdown'})
  const [show_max_solutions_to_return_button_name, set_show_max_solutions_to_return_button_name] = useState(opt_show_max_solutions_to_return_button_name)
  const [sne_show_max_solutions_to_return_button_color, set_sne_show_max_solutions_to_return_button_color] = useState({color:theme_blue_color})
  const [opt_show_max_solutions_to_return_button_color, set_opt_show_max_solutions_to_return_button_color] = useState({color:theme_blue_color})
  const [show_max_solutions_to_return_button_color, set_show_max_solutions_to_return_button_color] = useState(opt_show_max_solutions_to_return_button_color)
  const sne_max_solutions_to_return_error = 'Please ensure that the maximum number of solutions to return is expressed as a positive integer. For example:\n\n5\n\nor\n\n1e2'
  const opt_max_solutions_to_return_error = 'Please ensure that the maximum number of solutions to return is expressed as a positive integer. For example:\n\n5\n\nor\n\n1e2'
  const [sne_max_solutions_to_return_warning, set_sne_max_solutions_to_return_warning] = useState({value:false})
  const [opt_max_solutions_to_return_warning, set_opt_max_solutions_to_return_warning] = useState({value:false})
  const [max_solutions_to_return_warning, set_max_solutions_to_return_warning] = useState(opt_max_solutions_to_return_warning)
  const [sne_max_solutions_to_return,set_sne_max_solutions_to_return]=useState({value:10})
  const [opt_max_solutions_to_return,set_opt_max_solutions_to_return]=useState({value:1})
  const [max_solutions_to_return,set_max_solutions_to_return]=useState(opt_max_solutions_to_return)








  const max_runtime_section_title = 'Maximum Runtime'
  const max_runtime_section_description = 'The maximum amount of time (in seconds) that the solver will run before it stops. If the maximum runtime is reached, the solver will return the best solution(s) that have been found so far.'//it has found so far. If the solver does not stop before the maximum runtime, it will stop after the maximum runtime.'
  const [sne_show_max_runtime, set_sne_show_max_runtime] = useState({value:false})
  const [opt_show_max_runtime, set_opt_show_max_runtime] = useState({value:false})
  const [show_max_runtime, set_show_max_runtime] = useState(opt_show_max_runtime)
  const [sne_show_max_runtime_button_name, set_sne_show_max_runtime_button_name] = useState({name:'caretdown'})
  const [opt_show_max_runtime_button_name, set_opt_show_max_runtime_button_name] = useState({name:'caretdown'})
  const [show_max_runtime_button_name, set_show_max_runtime_button_name] = useState(opt_show_max_runtime_button_name)
  const [sne_show_max_runtime_button_color, set_sne_show_max_runtime_button_color] = useState({color:theme_blue_color})
  const [opt_show_max_runtime_button_color, set_opt_show_max_runtime_button_color] = useState({color:theme_blue_color})
  const [show_max_runtime_button_color, set_show_max_runtime_button_color] = useState(opt_show_max_runtime_button_color)
  const sne_max_runtime_error = 'Please ensure that the Maximum Runtime is expressed as a positive integer. For example:\n\n60\n\nor\n\n1e2'
  const opt_max_runtime_error = 'Please ensure that the Maximum Runtime is expressed as a positive integer. For example:\n\n60\n\nor\n\n1e2'
  const [sne_max_runtime_warning, set_sne_max_runtime_warning] = useState({value:false})
  const [opt_max_runtime_warning, set_opt_max_runtime_warning] = useState({value:false})
  const [max_runtime_warning, set_max_runtime_warning] = useState(opt_max_runtime_warning)
  const [sne_max_runtime,set_sne_max_runtime]=useState({value:(60*15)})
  const [opt_max_runtime,set_opt_max_runtime]=useState({value:(60*15)})
  const [max_runtime,set_max_runtime]=useState(opt_max_runtime)



  const ram_section_title = 'Solver RAM'
  const ram_section_description = 'The amount of RAM (in megabytes) that the solver will use.' // The solver will use this much RAM, and will not use more than this much RAM. If the solver uses more than this much RAM, it will stop.'
  const [sne_ram,set_sne_ram]=useState({value:4096})
  const [opt_ram,set_opt_ram]=useState({value:4096})
  const [ram, set_ram] = useState(opt_ram)
  const [sne_show_ram, set_sne_show_ram] = useState({value:false})
  const [opt_show_ram, set_opt_show_ram] = useState({value:false})
  const [show_ram, set_show_ram] = useState(opt_show_ram)
  const [sne_show_ram_button_name, set_sne_show_ram_button_name] = useState({name:'caretdown'})
  const [opt_show_ram_button_name, set_opt_show_ram_button_name] = useState({name:'caretdown'})
  const [show_ram_button_name, set_show_ram_button_name] = useState(opt_show_ram_button_name)
  const [sne_show_ram_button_color, set_sne_show_ram_button_color] = useState({color:theme_blue_color})
  const [opt_show_ram_button_color, set_opt_show_ram_button_color] = useState({color:theme_blue_color})
  const [show_ram_button_color, set_show_ram_button_color] = useState(opt_show_ram_button_color)
  const sne_ram_error = 'Please ensure that the RAM is expressed as a positive integer of at least 512. For example:\n\n4096\n\nor\n\n4.096e3'
  const opt_ram_error = 'Please ensure that the RAM is expressed as a positive integer of at least 512. For example:\n\n4096\n\nor\n\n4.096e3'
  const [sne_ram_warning, set_sne_ram_warning] = useState({value:false})
  const [opt_ram_warning, set_opt_ram_warning] = useState({value:false})
  const [ram_warning, set_ram_warning] = useState(opt_ram_warning)



















  const [show, setShow] = useState(true)
  const [sub_show, set_sub_show] = useState(true)
  
  // const [modalVisible, setModalVisible] = useState({});
  // const [modalVisible2, setModalVisible2]= useState({
  //   decisionVariableString:{
  //     show:false,
  //     text:"Decision variables can only be defined using uppercase and lowercase letters.",
  //   },

  // });
  // const [modalVisible, setmodalVisible]

  // const [show_decision_variable_modal,set_show_decision_variable_modal] = useState(false)
  const [show_modal,set_show_modal]=useState({show:false,text:""})
  const [hide_close_modal_button,set_hide_close_modal_button]=useState(false)
  const [upgrade_account_running,set_upgrade_account_running]=useState(false)

  const [solutionsTable, setSolutionsTable] = useState({Items:[],Count:0})
  //  [solutionsQuery, setSolutionsQuery] = useState(false)
  const [showSolutions, setShowSolutions] = useState(false)

  var [billingTable, setBillingTable] = useState()
  var [showBilling, setShowBilling] = useState(false)

  var [showProblemDetails, setShowProblemDetails] = useState(false)
  var [expandUniqueProblems, setExpandUniqueProblems] = useState(false)
  var [uniqueProblemConstraints, setUniqueProblemConstraints] = useState()
  
  var [showProblemConstraints, setShowProblemConstraints] = useState(false)
  var [renderPage, setRenderPage] = useState(false)

  // var [expandUniqueProblemAndConstraintSet, setExpandUniqueProblemAndConstraintSet] = useState(false)
  var [uniqueProblemAndConstraintArr, setUniqueProblemAndConstraintArr] = useState()


   var [uniqueObjectiveFunctions, setUniqueObjectiveFunctions] = useState()

  // var [showProblemConstraintSet, setShowProblemConstraintSet] = useState(false)
  
  // var [uniqueProblemConstraintsArray, setUniqueProblemConstraintsArray] = useState()
   // const transitions = useTransition(modalVisible, null, {
  //   from: { opacity: 0, transform: "translateY(-40px)" },
  //   enter: { opacity: 1, transform: "translateY(0px)" },
  //   leave: { opacity: 0, transform: "translateY(-40px)" }
  // });



  // const [snefocusedIndex, setsnefocusedIndex] = useState(null)
  // const picker
  // var textInputText = []
  // var customlims = false
  // var keyy = 0
  // var nequations = 0


  async function get_stripe_customer_account() {
    // console.log("get_stripe_customer_account FUNCTION STARTED")

    stripe_customer_accounts_table = await query_dynamodb_table('stripe_customer_accounts')
    // console.log("stripe_customer_accounts_table: ",stripe_customer_accounts_table)
    if(stripe_customer_accounts_table.Items.length>0){
      stripe_customer_accounts_table = stripe_customer_accounts_table.Items[0]
      stripe_customer_accounts_table.user_id = stripe_customer_accounts_table.user_id.S
      stripe_customer_accounts_table.email = stripe_customer_accounts_table.email.S
      stripe_customer_accounts_table.stripe_user_id = stripe_customer_accounts_table.stripe_user_id.S
      stripe_customer_accounts_table.api_key_id
      if(typeof stripe_customer_accounts_table.subscriptions == "undefined"){
          no_subscriptions=true
          stripe_customer_accounts_table.subscriptions = []
      } else {
          stripe_customer_accounts_table.subscriptions = stripe_customer_accounts_table.subscriptions.SS.map(item=>{return JSON.parse(item)})
      }
      if(typeof stripe_customer_accounts_table.api_key_id == "undefined"){
          stripe_customer_accounts_table.api_key_id = ''
          var user = await Auth.currentAuthenticatedUser();
          if(typeof user.attributes["custom:api_key"] != "undefined"){
            // console.log("delete API Key")
            await Auth.deleteUserAttributes(user, ['custom:api_key_ID','custom:api_key'])
            setApiKey("")
            setHasApiKey(false)
            setShowApiKey(false)
            setAPIKeyButtonColor(theme_blue_color)
            // setchangeAPIKeyWarningGiven(false)
          }        
      } else {
          stripe_customer_accounts_table.api_key_id = stripe_customer_accounts_table.api_key_id.S
      }
      // if(stripe_customer_accounts_table.subscriptions.length==0){
      //   await set_show_upgrade_account_button(true)
      //   await set_hide_close_modal_button(true)
      //   no_subscriptions=true
      //   set_show_modal({show:true,text:'Please upgrade your account to continue.'})
      // }
      // var state = await store.getState().store
      // state.stripe_customer_account = stripe_customer_accounts_table
  
      // set_stripe_customer_accounts_table_query(stripe_customer_accounts_table)
  
  } else {
      // console.log("NO STRIPE CUSTOMER ACCOUNTS FOUND. CALLING show_privacy_policy_and_terms_of_use_modal()")
      await show_privacy_policy_and_terms_of_use_modal()
      return

      await create_stripe_user_account() 
      // console.log("TRIED TO CREATE STRIPE USER ACCOUNT")
  
  }
  if(no_subscriptions){
        await set_show_upgrade_account_button(true)
        await set_hide_close_modal_button(true)
        set_show_modal({show:true,text:'Please upgrade your account to continue.'})
  } else {
        var user_subscription_IDs = stripe_customer_accounts_table.subscriptions
                await user_subscription_IDs.map( async(val,idx)=>{ 
                    if(user_subscription_IDs[idx].price_id == TEST_FREE_SOLVER_USAGE_PRODUCT_ID) {
                        // free_tier = true
                        await set_free_tier(true)
                    }  //else if(user_subscription_IDs[idx].price_id == TEST_FREE_SOLVER_BLOCK_UTILIZED_PRODUCT_ID) {
                    //     // free_tier = true
                    //     await set_free_tier(true)
                    // }
                    await set_sub_show(false)
                    await set_sub_show(true)
        } );
  }
  
  await store.dispatch({ 
    type: "update",
    payload: {
      // input:state
      input:{
        ...store.getState().store,
        no_subscriptions:no_subscriptions,
        free_tier:free_tier,
        stripe_customer_account:stripe_customer_accounts_table
      }
    }
  })
        // await store.dispatch({  
        //   type: "update",
        //   payload: {
        //     input:{}
        //   }
        // })
        // console.log("store.getState().")
        // console.log(store.getState())
        // // var a = await store.getState()
        // console.log("a.stripe_customer_account")
        // console.log(a.stripe_customer_account)
      //   if((typeof store.getState().store.stripe_customer_account == "undefined") ) {
      //       console.log("stripe_customer_account NOT IN REDUX, TRY TO QUERY stripe_customer_accounts TABLE:")
      //       stripe_customer_accounts_table = await query_dynamodb_table('stripe_customer_accounts')
      //       if(stripe_customer_accounts_table.Items.length>0){
      //           stripe_customer_accounts_table = stripe_customer_accounts_table.Items[0]
      //           stripe_customer_accounts_table.user_id = stripe_customer_accounts_table.user_id.S
      //           stripe_customer_accounts_table.email = stripe_customer_accounts_table.email.S
      //           stripe_customer_accounts_table.stripe_user_id = stripe_customer_accounts_table.stripe_user_id.S
      //           if(typeof stripe_customer_accounts_table.subscriptions != "undefined"){
      //               stripe_customer_accounts_table.subscriptions = []
      //           } else {
      //               stripe_customer_accounts_table.subscriptions = stripe_customer_accounts_table.subscriptions.SS.map(item=>{return JSON.parse(item)})
      //           }
      //           // if(stripe_customer_accounts_table.subscriptions.length==0){
      //           //   await set_show_upgrade_account_button(true)
      //           //   await set_hide_close_modal_button(true)
      //           //   no_subscriptions=true
      //           //   set_show_modal({show:true,text:'Please upgrade your account to continue.'})
      //           // }
      //           // var state = await store.getState().store
      //           // state.stripe_customer_account = stripe_customer_accounts_table
      //           await store.dispatch({ 
      //             type: "update",
      //             payload: {
      //               // input:state
      //               input:{
      //                 ...store.getState().store,
      //                 no_subscriptions:no_subscriptions,
      //                 stripe_customer_account:stripe_customer_accounts_table
      //               }
      //             }
      //           })
      //           set_stripe_customer_accounts_table_query(stripe_customer_accounts_table)
      //           set_has_stripe_account(true)
      //       } else {
      //           stripe_customer_accounts_table = await create_stripe_user_account() 
      //           console.log("TRIED TO CREATE STRIPE USER ACCOUNT")
      //           set_has_stripe_account(true)
      //       }
      //   } else {
      //       console.log("stripe_customer_account IS IN REDUX")
      //       stripe_customer_accounts_table = await store.getState().store.stripe_customer_account
      //       set_stripe_customer_accounts_table_query(stripe_customer_accounts_table)
      //       // console.log("stripe_customer_accounts_table")
      //       // console.log(stripe_customer_accounts_table)
      //   }
      //   // if(redirect_from_successful_checkout == true){
      //   //   update_stripe_customer_accounts_table()
      //   // } else 
      //   if(typeof store.getState().store.free_tier == "undefined" ) {
      //       var user_subscription_IDs = stripe_customer_accounts_table.subscriptions
      //       await user_subscription_IDs.map( (val,idx)=>{ 
      //           if(user_subscription_IDs[idx].price_id == TEST_FREE_SOLVER_USAGE_PRODUCT_ID) {
      //               free_tier = true
      //           }  else if(user_subscription_IDs[idx].price_id == TEST_FREE_SOLVER_BLOCK_UTILIZED_PRODUCT_ID) {
      //               free_tier = true
      //           }
      //       } );
      //       await store.dispatch({ 
      //         type: "update",
      //         payload: {
      //           // input:state
      //           input:{
      //             ...store.getState().store,
      //             free_tier:free_tier,
      //             // no_subscriptions:no_subscriptions
      //           }
      //         }
      //       })
      //   } else {
      //       free_tier = store.getState().store.free_tier
      //   }
      //   if(store.getState().store.no_subscriptions ){
      //     stripe_customer_accounts_table = await query_dynamodb_table('stripe_customer_accounts')
      //     if(typeof stripe_customer_accounts_table.subscriptions == "undefined"){
      //       stripe_customer_accounts_table.subscriptions = []
      //       await set_show_upgrade_account_button(true)
      //       await set_hide_close_modal_button(true)
      //       no_subscriptions=true
      //       set_show_modal({show:true,text:'Please upgrade your account to continue.'})
      //     } else {
      //         stripe_customer_accounts_table.subscriptions = stripe_customer_accounts_table.subscriptions.SS.map(item=>{return JSON.parse(item)})
      //         no_subscriptions=false
      //     }
      //     await store.dispatch({ 
      //       type: "update",
      //       payload: {
      //         // input:state
      //         input:{
      //           ...store.getState().store,
      //           stripe_customer_accounts_table:stripe_customer_accounts_table,
      //           no_subscriptions:no_subscriptions
      //         }
      //       }
      //     })
  
      // }
  }



  // throw new Error("My first Sentry error!");

async function upgrade_account () {
  count = 0
  no_subscriptions = false
  // set_free_tier(false)
  await store.dispatch({ 
    type: "update",
    payload: {
      input:{
        identityId:store.getState().store.identityId,
            }
    }
  })
  await set_upgrade_account_running(true)
  var payload  = {}
  var session = await Auth.currentSession()
  var IdToken = await session.getIdToken()
  var jwtToken = await IdToken.getJwtToken()

  const maxRetries = 5;
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const result = await fetch(create_stripe_checkout_session_lambdaUrl, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {Authorization: jwtToken}
      });

      if (!result.ok) {
        throw new Error(`HTTP error! status: ${result.status}`);
      }

      const resultjson = await result.json();
      var stripe_url = resultjson.url;
      window.open(stripe_url, "_self");
      set_upgrade_account_running(false);
      return; // Success, exit the function
    } catch (error) {
      console.error(`Attempt ${retries + 1} failed:`, error);
      retries++;
      if (retries < maxRetries) {
        console.log(`Retrying in 2 seconds...`);
        await new Promise(resolve => setTimeout(resolve, 2500)); // Wait for 2 seconds before retrying
      }
    }
  }

  // If we've exhausted all retries
  console.error("Failed to create Stripe checkout session after multiple attempts");
  set_upgrade_account_running(false);
  // You might want to show an error message to the user here
}

// async function update_stripe_customer_accounts_table () {
//   var payload  = {}
//   var session = await Auth.currentSession()
//   var IdToken = await session.getIdToken()
//   var jwtToken = await IdToken.getJwtToken()

//   await fetch(update_stripe_customer_accounts_table_lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{Authorization:jwtToken}})
//     .then(async(result) => {
//         var resultjson = await result.json()
//         console.log("resultjson FROM update_stripe_customer_accounts_table_lambdaUrl:")
//         console.log(resultjson)
//         // console.log("")
//         var subscriptionss = await resultjson.customer_subscriptions.map(item=>{
//           var json_item = JSON.parse(item)
//           if( (json_item.price_id==SOLVER_USAGE_PRODUCT_ID) || (json_item.price_id==SOLVER_BLOCK_UTILIZED_PRODUCT_ID) ) {
//               free_tier = false
//           }
//           return json_item
//         })
//         if(subscriptionss.length==0){
//           no_subscriptions = true
//         }
//         await store.dispatch({ 
//           type: "update",
//           payload: {
//             // input:state
//             input:{
//               ...store.getState().store,
//               stripe_customer_account:{
//                 ...store.getState().store.stripe_customer_account,
//                 no_subscriptions:no_subscriptions,
//                 subscriptions: subscriptionss,
//               },
//               free_tier:free_tier
//             }
//           }
//         })
//     }) 

// }

async function show_privacy_policy_and_terms_of_use_modal () {
  await set_show_privacy_policy_and_terms_of_use(true)
  await set_hide_close_modal_button(true)
   set_show_modal({show:true,text:create_stripe_user_text})
}


  async function create_stripe_user_account () {
    await setisProcessing(true)

    var payload  = {}
    var session = await Auth.currentSession()
    var IdToken = await session.getIdToken()
    var jwtToken = await IdToken.getJwtToken()

    const maxRetries = 5;
    const retryDelay = 2500; // 2.5 seconds

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const result = await fetch(create_stripe_user_lambdaUrl, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {Authorization: jwtToken}
        });

        const resultjson = await result.json();
        console.log("resultjson FROM create_stripe_user_lambdaUrl:");
        console.log(resultjson);
        console.log("");

        resultjson.subscriptions = resultjson.subscriptions.map(item => JSON.parse(item));
        stripe_customer_accounts_table = resultjson;

        await store.dispatch({ 
          type: "update",
          payload: {
            input: {
              ...store.getState().store,
              stripe_customer_account: resultjson
            }
          }
        });

        // If we reach here, the operation was successful
        break;
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed:`, error);

        if (attempt === maxRetries - 1) {
          // If this was the last attempt, throw the error
          throw error;
        }

        // Wait before the next retry
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      }
    }

    await setisProcessing(false);
  }



// OLD create_stripe_user_account BELOW BEFORE ADDING RETRIES
//
  // async function create_stripe_user_account () {
  //   await setisProcessing(true)


  //   //   <View style={{alignSelf:'center', flexDirection:'row', alignItems:'stretch',left:'0%',width:'100%',justifyContent:'flex-end'}}>
  //   //   <TouchableOpacity style={{paddingBottom:10,marginHorizontal:15}} onPress={() => {
  //   //     // setStatus(() => {
  //   //     //   return {
  //   //       WebBrowser.openBrowserAsync('https://www.toxeus.org/privacy-policy/');
  //   //   }}>
  //   //     <Text style={{color:"rgba(255,255,255,1)"}}>{"Privacy Policy"}</Text>
  //   //   </TouchableOpacity>
  //   //   <TouchableOpacity style={{paddingBottom:10,marginHorizontal:15}} onPress={() => {
  //   //     // setStatus(() => {
  //   //     //   return {
  //   //       WebBrowser.openBrowserAsync('https://www.toxeus.org/terms-of-use/');
  //   //   }}>
  //   //     <Text style={{color:"rgba(255,255,255,1)"}}>{"Terms of Use"}</Text>
  //   //   </TouchableOpacity>
  //   // </View>
  //     var payload  = {}
  //     var session = await Auth.currentSession()
  //     var IdToken = await session.getIdToken()
  //     // var accesstoken = session.getAccessToken()
  //     var jwtToken = await IdToken.getJwtToken()
  //     // OLD palyload SHOWN ABOVE WHICH WAS USED WITHIN FETCH
  //     await fetch(create_stripe_user_lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{Authorization:jwtToken}})
  //       .then(async(result) => {
  //         var resultjson = await result.json()
  //         console.log("resultjson FROM create_stripe_user_lambdaUrl:")
  //         console.log(resultjson)
  //         console.log("")
  //          resultjson.subscriptions = resultjson.subscriptions.map(item=>{return JSON.parse(item)})
  //         // await store.dispatch({ 
  //         //   type: "update",
  //         //   payload: {
  //         //     input:{stripe_customer_account:resultjson}
  //         //   }
  //         // })
  //         // var state = store.getState().store
  //         // state.stripe_customer_account = resultjson
  //         stripe_customer_accounts_table = resultjson
  //         // set_stripe_customer_accounts_table_query(resultjson)
  //         await store.dispatch({ 
  //           type: "update",
  //           payload: {
  //             input:{
  //               ...store.getState().store,
  //               stripe_customer_account:resultjson
  //             }
  //           }
  //         })
          
  //       })  
  //       // var user_subscription_IDs = stripe_customer_accounts_table.subscriptions
  //       // await user_subscription_IDs.map( async(val,idx)=>{ 
  //       //     if(user_subscription_IDs[idx].price_id == TEST_FREE_SOLVER_USAGE_PRODUCT_ID) {
  //       //         // free_tier = true
  //       //         await set_free_tier(true)
  //       //     }  //else if(user_subscription_IDs[idx].price_id == TEST_FREE_SOLVER_BLOCK_UTILIZED_PRODUCT_ID) {
  //       //     //     // free_tier = true
  //       //     //     await set_free_tier(true)
  //       //     // }
  //       //     await set_sub_show(false)
  //       //     await set_sub_show(true)
  //       //       } );
  //       // await store.dispatch({ 
  //       //   type: "update",
  //       //   payload: {
  //       //     // input:state
  //       //     input:{
  //       //       ...store.getState().store,
  //       //       no_subscriptions:no_subscriptions,
  //       //       free_tier:free_tier,
  //       //       stripe_customer_account:stripe_customer_accounts_table
  //       //     }
  //       //   }
  //       // })
  //       await setisProcessing(false)
  // }
  //
  // OLD create_stripe_user_account ABOVE BEFORE ADDING RETRIES




  async function createApiKey (action) {
          if(!isProcessing){
            await setisProcessing(true)
            if(free_tier) {
              var resultjson = "ERROR: You are on the free tier. You cannot create an API key."
              var error_text = ((resultjson.split("ERROR: "))[1])
              set_show_modal({show:true,text:error_text})
            }
            var user = await Auth.currentAuthenticatedUser();
            var session = await Auth.currentSession()
            var IdToken = await session.getIdToken()
            var accesstoken = session.getAccessToken()
            var jwtToken = await IdToken.getJwtToken()
            var payload = {AccessToken:accesstoken}
            if(action=="delete"){
              payload.action="delete"
            }
            await fetch(createApiKeyLambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{Authorization:jwtToken}})    
              .then(async(result) => {
                var results =await result.json() 
                var resultjson = results
  
                if(typeof resultjson == "string" ){
                  if(resultjson == "API KEY SUCCESSFULLY DELETED") {
                    // console.log("delete API Key")
                    await Auth.deleteUserAttributes(user, ['custom:api_key_ID','custom:api_key'])
                    setApiKey("")
                    setHasApiKey(false)
                    setShowApiKey(false)
                    setAPIKeyButtonColor(theme_blue_color)
                    // setchangeAPIKeyWarningGiven(false)
                    
                  } else {
                    var error_text = ((resultjson.split("ERROR: "))[1])
                    set_show_modal({show:true,text:error_text})
                  }
                } else {
  
  
                  // var resultjson = await result.json()
                  // // console.log(resultjson)
                  // // resulttt = resultjson
                  // // resulttt = JSON.parse(resultjson.body)
                  // // console.log(resulttt)
                  // Auth.updateUserAttributes(user, {
                  //   'custom:api_key_ID': resultjson.api_key_ID,
                  // })
  
          
  
            // OLD CODE BELOW WHICH INVOKED THE LAMBDA FUNCTION DIRECTLY
            // let user = await Auth.currentAuthenticatedUser();
            // var session = await Auth.currentSession()
            // var IdToken = await session.getIdToken()
            // var accesstoken = session.getAccessToken()
            // var jwtToken = await IdToken.getJwtToken()
            // var payload = {AccessToken:accesstoken}
            // var paramss = {
            //   FunctionName: "create-api-key-for-cognito-us-createAPIKeyFunction-GXzniXIiPA4r",
            //   InvocationType: "RequestResponse",
            //   Payload:await JSON.stringify(payload)
            // }
            // new LambdaClient({ 
            //   region: "us-west-2",
            //   credentials: essential_credentials
            // }).send( new InvokeCommand(paramss))
            //   .then(async(results)=>{
            //       results = await JSON.parse(new TextDecoder("utf-8").decode(results.Payload))
            //       results = await JSON.parse(results.body)
            // OLD CODE ABOVE WHICH INVOKED THE LAMBDA FUNCTION DIRECTLY
  
                  var newkey_ID = results.api_key_ID
                  var newkey = results.api_key
                  await Auth.updateUserAttributes(user, {
                        'custom:api_key_ID': newkey_ID,
                        'custom:api_key': newkey
                      })
                      setApiKey(newkey)
                      setHasApiKey(true)
                      setShowApiKey(true)
                      setAPIKeyButtonColor(theme_blue_color)
                      // setchangeAPIKeyWarningGiven(false)
                  }
            })
            await setisProcessing(false)
          }
  }


//   function makeid() {
//     var length = 31 + Math.floor(Math.random() * 50)
//     var result           = identityId+"_";
//     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     var charactersLength = characters.length;
//     for ( var i = 0; i < length; i++ ) {
//       result += characters.charAt(Math.floor(Math.random() * charactersLength));
//    }
//    return result;
// }
  // async function navigate_to_stripe_page () {
  //     count = 0
  //     // var stripe_url = "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_HxQZQZQZQZQZQZQZQZQZQZQZQZQZQZQZQ&scope=read_write"
  //     // var stripe_url = 'https://billing.stripe.com/session/test_YWNjdF8xRWVRaGFJRFo0dUZQbkNoLF9NNlpOTHBhcVBEMkIyc29YaTlua1VLd3dDUlNuRnZi010080CdsSxJ'
  //     // var stripe_url ='https://billing.stripe.com/login/test_7sIdUz12S5bm21q4gg'
  //     // console.log("NOT FINISHED")
  //     await store.dispatch({ 
  //       type: "update",
  //       payload: {
  //         input:{
  //           identityId:store.getState().store.identityId,
  //               }
  //       }
  //     })
  //     var stripe_url = 'https://billing.stripe.com/login/test_7sIdUz12S5bm21q4gg'
  //     window.open(stripe_url, '_blank')
  // }


    async function signOut() {
      Auth.signOut()
        .then(async() => {
          // props.onStateChange('signedOut', null);
          count = 0
          no_subscriptions = false
          set_free_tier(false)
          await store.dispatch({ 
            type: "update",
            payload: {
              input:{}
            }
          })
        })
        .catch(err => {
          console.log('err: ', err)
        })
    }

    
   function onchhhangetext (text, index) {
    // as there are going to be a lot of setState calls
    // we need access the prevState before we set the next state.
    // setShow(false)
  
    // console.log(textInputValues)


    // if(ProblemType=="sne"){
    //   var dataa = JSON.parse(JSON.stringify(textInputValues))
    //   dataa[index] = text
    //   settextInputValues(dataa)
    // } else if (ProblemType=="optimization") {

      // var dataa = JSON.parse(JSON.stringify(textInputValuess)) 
      // var dataa = textInputValuess
      // // var dataa = JSON.parse(JSON.stringify(textInputValuess)) 
      // dataa.values[index] = text
      // textInputValuess.values = dataa.values
      textInputValuess.values[index] = text
      settextInputValuess(textInputValuess)

      // COMMENTED THIS OUT SINCE textInputValuess SHOULD UPDATE AUTOMATICALLY AND THIS CHANGE
      // SHOULD BE REFLECTED IN THE sne_textInputValuess AND THE opt_textInputValuess
      // settextInputValuess(dataa)


    // }




    // for (let ivv = 0; ivv < constraints.length; ivv++) {
      // if(ivv==0){
    if(text.includes("<=")){
        var splitt = text.split("<=")
        spliteval(splitt,index)
    } else if(text.includes(">=")){
        var splitt = text.split(">=")
        spliteval(splitt,index)
    } else if(text.includes("=")){
        var splitt = text.split("=")
        spliteval(splitt,index)
    } else if(text.includes("<")){
        var splitt = text.split("<")
        spliteval(splitt,index)
    } else if(text.includes(">")){
        var splitt = text.split(">")
        spliteval(splitt,index)
    } else {
        showredx(index)
    }
    // await set_sub_show(false)
    // await set_sub_show(true)
    
  }

  async function spliteval (splitt,index) {
      if(splitt.length==2){
        if((splitt[0].length>0) && (splitt[1].length>0)){
            if(
                (splitt[0].split("=").length<2) && 
                (splitt[1].split("=").length<2) && 
                (splitt[0].split("<=").length<2) && 
                (splitt[1].split("<=").length<2) && 
                (splitt[0].split(">=").length<2) && 
                (splitt[1].split(">=").length<2) && 
                (splitt[0].split("<").length<2) && 
                (splitt[1].split("<").length<2) && 
                (splitt[0].split(">").length<2) && 
                (splitt[1].split(">").length<2)
            ){
              if((!/[~`!#$%\&=\\\';,{}|\\":<>\?]/g.test(splitt[0])) && ((!/[~`!#$%\&=\\\';,{}|\\":<>\?]/g.test(splitt[1])))) {
                showgreencheck(index)
              } else {
                showredx(index)
              }
                // showgreencheck(index)
            } else {
                showredx(index)
            }
          }  else {
            showredx(index)
        }
      }  else {
        showredx(index)
    }
  }

  async function showgreencheck (index){
    // await setShow(false)
    await set_sub_show(false)
    // if(ProblemType=="sne"){
    //   var inputss = SNEInputsValid
    //   inputss[index]=true
    //   setSNEInputsValid(inputss)
    // } else if (ProblemType=="optimization") {
      var inputss = inputsValid.values
      inputss[index]=true
      inputsValid.values = inputss
      setInputsValid(inputsValid)
      // setInputsValid(inputss)
      if(all_elements_true_checker(inputsValid.values)){
        if(free_tier && (textInputValuess.values.length > 5)) {
          set_show_upgrade_account_button(true)
          show_constraints_button_color.color="grey"
        } else {
          show_constraints_button_color.color=theme_blue_color
        }
        
      } else {
          show_constraints_button_color.color="grey"
      }
    // }
    await set_sub_show(true)
    // await setShow(true)
  }
  async function showredx (index){
    // await setShow(false)
    await set_sub_show(false)
    // if(ProblemType=="sne"){
    //   var inputss = SNEInputsValid
    //   inputss[index]=false
    //   setSNEInputsValid(inputss)
    // } else if (ProblemType=="optimization") {
      var inputss = inputsValid.values
      inputss[index]=false
      inputsValid.values = inputss
      setInputsValid(inputsValid)
      // setInputsValid(inputss)
      if(all_elements_true_checker(inputsValid.values)){
        if(free_tier && (textInputValuess.values.length > 5)) {
          set_show_upgrade_account_button(true)
          show_constraints_button_color.color="grey"
        } else {
          show_constraints_button_color.color=theme_blue_color
        }
      } else {
          show_constraints_button_color.color="grey"
      }
    // }
    // setShow(true)
    // await setShow(true)
    await set_sub_show(true)
  }


// async function onDecisionVariableDelete(key){
//   await setShow(false)
//   // show_initial_search_region.value = false
//   // set_show_initial_search_region(false)
//   var dataa = initialSearchRegionv2.region
//   delete dataa[key]
//   initialSearchRegionv2.region = dataa
//   // setInitialSearchRegionv2(dataa)
//   if(
//     all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].limits.lower.is_parsable})) &&
//     all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].limits.upper.is_parsable})) &&
//     all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].variable_is_defined})) //&&
//     // all_elements_true_checker(Object.keys(defaultSearchRegion.region).map(item=>{return defaultSearchRegion.region[item].is_parsable}))
//   ){
//     // setIsInitialSearchRegionValid(true)
//     isInitialSearchRegionValid.value = true
//     if(defaultSearchRegion.region.lower.is_parsable && defaultSearchRegion.region.upper.is_parsable){
//       // set_show_initial_search_region_button_text_color(theme_blue_color)
//       show_initial_search_region_button_text_color.color = theme_blue_color
//     }
//   }

//   var new_dec_var_list_set = dec_var_list_set.variables
//   if(new_dec_var_list_set.has(key)){
//     new_dec_var_list_set.delete(key)
//     // set_dec_var_list_set(new_dec_var_list_set)
//     dec_var_list_set.variables = new_dec_var_list_set
//   }
//   if(free_tier && (Array.from(dec_var_list_set.variables).length > 5)) {
//     // set_show_modal({show:true,text:free_tier_error_text })
//     set_show_upgrade_account_button(true)
//     show_decision_variables_button_color.color = "grey"
//     set_show_decision_variables_button_color(show_decision_variables_button_color)
//   } else {
//     show_decision_variables_button_color.color = theme_blue_color
//     set_show_decision_variables_button_color(show_decision_variables_button_color)
//   }
//   var text = Array.from(new_dec_var_list_set).join(", ")
//   // var text = Object.keys(dataa).join(", ")
//   decisionVariableString.value = text


//   // setDecisionVariableString(text)
//   // decision_variable_string.value = text
//   // set_show_initial_search_region(true)
//   // show_initial_search_region.value = true
//   await setShow(true)
// }




async function onDecisionVariableDelete2(key){
  await setShow(false)
  // show_initial_search_region.value = false
  // set_show_initial_search_region(false)
  var dataa = initialSearchRegionv2.region
  delete dataa[key]
  initialSearchRegionv2.region = dataa
  // setInitialSearchRegionv2(dataa)
  if(
    all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].limits.lower.is_parsable})) &&
    all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].limits.upper.is_parsable})) &&
    all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].variable_is_defined})) //&&
    // all_elements_true_checker(Object.keys(defaultSearchRegion.region).map(item=>{return defaultSearchRegion.region[item].is_parsable}))
  ){
    // setIsInitialSearchRegionValid(true)
    isInitialSearchRegionValid.value = true
    if(defaultSearchRegion.region.lower.is_parsable && defaultSearchRegion.region.upper.is_parsable){
      // set_show_initial_search_region_button_text_color(theme_blue_color)
      show_initial_search_region_button_text_color.color = theme_blue_color
    }
  }

  var new_dec_var_list_set = dec_var_list_set.variables
  if(new_dec_var_list_set.has(key)){
    new_dec_var_list_set.delete(key)
    // set_dec_var_list_set(new_dec_var_list_set)
    dec_var_list_set.variables = new_dec_var_list_set
  }
  if(free_tier && (Array.from(dec_var_list_set.variables).length > 5)) {
    // set_show_modal({show:true,text:free_tier_error_text })
    set_show_upgrade_account_button(true)
    show_decision_variables_button_color.color = "grey"
    set_show_decision_variables_button_color(show_decision_variables_button_color)
  } else {
    show_decision_variables_button_color.color = theme_blue_color
    set_show_decision_variables_button_color(show_decision_variables_button_color)
  }
  // var text = Array.from(new_dec_var_list_set).join(", ")
  // // var text = Object.keys(dataa).join(", ")
  // decisionVariableString.value = text


  // setDecisionVariableString(text)
  // decision_variable_string.value = text
  // set_show_initial_search_region(true)
  // show_initial_search_region.value = true
  await setShow(true)
}






async function on_tag_delete(key){
  await setShow(false)

  var new_problem_tags_and_description_set = problem_tags_and_description.tags
  if(new_problem_tags_and_description_set.has(key)){
    new_problem_tags_and_description_set.delete(key)
    problem_tags_and_description.tags = new_problem_tags_and_description_set
    set_problem_tags_and_description(problem_tags_and_description)
  }
  // if(free_tier && (Array.from(dec_var_list_set.variables).length > 5)) {
  //   set_show_upgrade_account_button(true)
  //   show_decision_variables_button_color.color = "grey"
  //   set_show_decision_variables_button_color(show_decision_variables_button_color)
  // } else {
  //   show_decision_variables_button_color.color = theme_blue_color
  //   set_show_decision_variables_button_color(show_decision_variables_button_color)
  // }
  // var text = Array.from(new_problem_tags_and_description_set).join(", ")
  // decisionVariableString.value = text
  await setShow(true)
}


async function on_tag_add(key){
  await setShow(false)

  problem_tags_and_description.tags = new Set([...Array.from(problem_tags_and_description.tags),key])
  await recommended_problem_tags_and_description.delete(key)
  set_problem_tags_and_description(problem_tags_and_description)
  problem_tags_and_description_string.value=""
  set_problem_tags_and_description_string(problem_tags_and_description_string)
  // if(new_problem_tags_and_description_set.has(key)){
  //   new_problem_tags_and_description_set.delete(key)
  //   problem_tags_and_description.tags = new_problem_tags_and_description_set
  //   set_problem_tags_and_description(problem_tags_and_description)
  // }
  // if(free_tier && (Array.from(dec_var_list_set.variables).length > 5)) {
  //   set_show_upgrade_account_button(true)
  //   show_decision_variables_button_color.color = "grey"
  //   set_show_decision_variables_button_color(show_decision_variables_button_color)
  // } else {
  //   show_decision_variables_button_color.color = theme_blue_color
  //   set_show_decision_variables_button_color(show_decision_variables_button_color)
  // }
  // var text = Array.from(new_problem_tags_and_description_set).join(", ")
  // decisionVariableString.value = text
  await setShow(true)
}
















const delay = millis => new Promise((resolve, reject) => {
  setTimeout(_ => resolve(), millis)
});

  // async function onDecisionVariablesChangeText (text) {
  //     // var texta = "{"+text+"}"
  //     // setLims(texta)
  //     // setCustomLims(true)
  //     // await setShow(false)
  //     decisionVariableString.value = text.replace(/[^A-Za-z,\s+]/g, '')
  //     setDecisionVariableString(decisionVariableString)




  //     // setDecisionVariableString(text.replace(/[^A-Za-z,\s+]/g, ''))







  //     // decision_variable_string.value = text.replace(/[^A-Za-z,\s+]/g, '')

  //     text = text.replace(/\s+/g, '').replace(/[^A-Za-z,]/g, '')
      
  //     // var dec_var_list = JSON.parse("["+text+"]")
  //     var dec_var_list = text.split(",")
  //     var non_empty_dec_var_list = []
  //     dec_var_list.map((item) => {if(item != ""){non_empty_dec_var_list.push(item)}})
  //     var new_dec_var_list_set = new Set(non_empty_dec_var_list)
  //     // var new_dec_var_list_set = new Set(dec_var_list)
  //     // set_dec_var_list_set(new_dec_var_list_set)
  //     dec_var_list_set.variables = new_dec_var_list_set
  //     if(free_tier && (Array.from(dec_var_list_set.variables).length > 5)) {
  //       // set_show_modal({show:true,text:free_tier_error_text })
  //       set_show_upgrade_account_button(true)
  //       show_decision_variables_button_color.color = "grey"
  //       set_show_decision_variables_button_color(show_decision_variables_button_color)
  //     } else {
  //       show_decision_variables_button_color.color = theme_blue_color
  //       set_show_decision_variables_button_color(show_decision_variables_button_color)
  //     }
  //     // setDecisionVariableList(dec_var_list)
      
  //     // var new_initial_search_region = dec_var_list.map(dec_var=>{
  //     //   return {
  //     //     // limits:function(){if(typeof initialSearchRegion.dec_var=="undefined"){return defaultSearchRegion.region}else{return [dec_var,dec_var]}}
  //     //     limits:  String(defaultSearchRegion.region),
  //     //     variable:dec_var,
  //     //     variable_is_defined:true,
  //     //     non_default_limits:false,
  //     //     is_parsable:true,
  //     //     // Valid:true
  //     //   }
  //     // })
  //     // set_show_initial_search_region(false)
  //     var new_initial_search_region = {}
      
  //     // var undefined_vars_in_search_region_limits = await get_undefined_vars_in_search_region_limits(dec_var_list)

  //     // Get all of the variables in the search region limits which are not default and which are not in the decision variables list
  //     // and add them to the top of the new initial search region with variable_is_defined set to false so the user can see that it is 
  //     // not defined
     
  //     Object.keys(initialSearchRegionv2.region).map(dec_var=>{
  //       // If the variable is not in the decision variables list and is not default we set variable_is_defined to false and 
  //       // add it to the top of the new initial search region so the user can see it is not defined
  //       if((!new_dec_var_list_set.has(dec_var)) && (initialSearchRegionv2.region[dec_var].non_default_limits)){
  //         new_initial_search_region[dec_var] =  initialSearchRegionv2.region[dec_var]
  //         new_initial_search_region[dec_var].variable_is_defined = false
  //         new_initial_search_region[dec_var].variable_is_defined_color=  'red' // 'orange' //
  //         isInitialSearchRegionValid.value = false
  //         // setIsInitialSearchRegionValid(false)
  //         show_initial_search_region_button_text_color.color="grey"
  //         // set_show_initial_search_region_button_text_color("grey")
  //       } else if((!initialSearchRegionv2.region[dec_var].limits.lower.is_parsable) || (!initialSearchRegionv2.region[dec_var].limits.upper.is_parsable)){
  //         new_initial_search_region[dec_var] =  initialSearchRegionv2.region[dec_var]
  //         isInitialSearchRegionValid.value=false
  //         // setIsInitialSearchRegionValid(false)
  //         show_initial_search_region_button_text_color.color="grey"
  //         // set_show_initial_search_region_button_text_color("grey")
  //       }
  //     })
  //     if(Object.keys(new_initial_search_region).length==0){
  //         var no_undefined_vars = true
  //     } else {
  //         var no_undefined_vars = false
  //         isInitialSearchRegionValid.value=false
  //         show_initial_search_region_button_text_color.color="grey"
  //         // setIsInitialSearchRegionValid(false)
  //         // set_show_initial_search_region_button_text_color("grey")
  //     }
  //     var no_blank_vars = true
  //     var no_unparsable_vars = true
  //     Array.from(new_dec_var_list_set).map(dec_var=>{
  //       if(typeof initialSearchRegionv2.region[dec_var] != "undefined"){
  //           // console.log('if(typeof initialSearchRegionv2[dec_var] != "undefined"){         FOR '+dec_var+"WITH typeof "+typeof initialSearchRegionv2[dec_var])
  //           // console.log('initialSearchRegionv2[dec_var]')
  //           // console.log(initialSearchRegionv2[dec_var])
  //           new_initial_search_region[dec_var] = initialSearchRegionv2.region[dec_var]
  //           new_initial_search_region[dec_var].variable_is_defined = true
  //           new_initial_search_region[dec_var].variable_is_defined_color='black'
  //           if((!new_initial_search_region[dec_var].limits.lower.is_parsable) || (!new_initial_search_region[dec_var].limits.upper.is_parsable)){
  //               no_unparsable_vars = false
  //               isInitialSearchRegionValid.value=false
  //               show_initial_search_region_button_text_color.color="grey"
  //               // setIsInitialSearchRegionValid(false)
  //               // set_show_initial_search_region_button_text_color("grey")
  //           }
  //       } else {
  //           if(dec_var == ""){
  //             var variable_is_defined =  false
  //             no_blank_vars = false
  //             isInitialSearchRegionValid.value=false
  //             show_initial_search_region_button_text_color.color="grey"
  //             // setIsInitialSearchRegionValid(false)
  //             // set_show_initial_search_region_button_text_color("grey")
  //           } else {
  //             var variable_is_defined =  true
  //             var variable_is_defined_color='black'
  //             // console.log('defaultSearchRegion.region')
  //             // console.log(defaultSearchRegion.region)
  //             // const dummy2 = {...defaultSearchRegion.region}
  //             // const dummy2 = Object.assign({},defaultSearchRegion.region)
  //             const dummy2 = JSON.parse(JSON.stringify(defaultSearchRegion.region))
  //             new_initial_search_region[dec_var] = {
  //               limits: dummy2,
  //               // Here we are not going to set variable_is_defined to be false if the variable is blank because all variables which are not defined are moved to the top of
  //               // the initial search region, and a blank decision variable will only be present if they have just added it.
  //               variable_is_defined:variable_is_defined,
  //               variable_is_defined_color:variable_is_defined_color,
  //               non_default_limits:false,
  //               show_error_modal:false,
  //               // is_parsable:is_parsable,
  //               // Valid:true
  //             }
  //             // console.log('JSON.stringify(new_initial_search_region[dec_var])   FOR   '+dec_var)
  //             // console.log(JSON.stringify(new_initial_search_region[dec_var]))
  //           }

  //       }
  //     })
  //     initialSearchRegionv2.region=new_initial_search_region
  //     // setInitialSearchRegionv2(new_initial_search_region)
  //     if(no_undefined_vars && no_blank_vars && no_unparsable_vars){
  //       isInitialSearchRegionValid.value=true
  //       // setIsInitialSearchRegionValid(true)
  //       if(defaultSearchRegion.region.lower.is_parsable && defaultSearchRegion.region.upper.is_parsable){
  //         show_initial_search_region_button_text_color.color=theme_blue_color
  //         // set_show_initial_search_region_button_text_color(theme_blue_color)
  //       }
  //       // set_show_initial_search_region_button_text_color(theme_blue_color)
  //     }

      
  //     // if(undefined_vars_in_search_region_limits.length>0){
  //     //     // console.log("undefined_vars_in_search_region_limits.length")
  //     //     // console.log(undefined_vars_in_search_region_limits.length)
  //     //     var dummy1 = undefined_vars_in_search_region_limits
  //     //     dummy1.push(...new_initial_search_region)
  //     //     var new_initial_search_region = dummy1
  //     //     setInitialSearchRegion(new_initial_search_region)
  //     //     setIsInitialSearchRegionValid(false)
  //     //     // var new_initial_search_region = undefined_vars_in_search_region_limits
  //     // } else {
  //     //   // HERE WE NEED TO ONLY SET THIS TO BE TRUE IF BOTH variable_is_defined AND is_parsable IS TRUE FOR ALL ELEMENTS IN initialSearchRegion
  //     //   // IF WE END UP IN THIS ELSE STATEMENT, THEN variable_is_defined FOR ALL ELEMENTS IN initialSearchRegion. IN THIS CASE WE ONLY NEED
  //     //   // TO CHECK IF is_parsable IS TRUE FOR ALL ELEMENTS.
  //     //   setInitialSearchRegion(new_initial_search_region)
  //     //   if(all_elements_true_checker(new_initial_search_region.map(item=>{return item.is_parsable}))){
  //     //     setIsInitialSearchRegionValid(true)
  //     //   }
  //     // }
  //     var aaa = ((Object.keys(new_initial_search_region).map(item=>{return '"'+item+'":['+new_initial_search_region[item].limits.lower.value+","+new_initial_search_region[item].limits.upper.value+'],'})).join("")).slice(0,-1)
  //     var texta = '{'+aaa+'}'
  //     Lims.value=texta
  //     CustomLims.value=true
  //     // setLims(texta)
  //     // setCustomLims(true)
  //     await set_sub_show(false)
  //     await set_sub_show(true)
  //     // await setShow(false)
  //     // await setShow(true)
  //     // console.log("new_initial_search_region")
  //     // console.log(new_initial_search_region)
  //     // set_show_initial_search_region(true)
      
  // }



  async function onDecisionVariablesChangeText2 (text) {
    // console.log("text")
    // console.log(text)
    if(typeof text == "object"){
      // console.log("text.code")
      // console.log(text.code)
      if(text.code == "Enter"){
        var text2 = decisionVariableString.value + " "
        // console.log('var text2 = decisionVariableString.value + " "')
        // console.log(text2)
        text = text2
      }
    }
    if((text[text.length-1]==" ") || (text[text.length-1]==",")){
        text = text.replace(/[^A-Za-z]/g, '')
        var new_dec_var_list_set = [...Array.from(dec_var_list_set.variables), text]
        dec_var_list_set.variables = new Set(new_dec_var_list_set)
        set_dec_var_list_set(dec_var_list_set)
        decisionVariableString.value = ""
        setDecisionVariableString(decisionVariableString)
    } else {
        decisionVariableString.value = text.replace(/[^A-Za-z]/g, '')
        setDecisionVariableString(decisionVariableString)
    }

    if(free_tier && (Array.from(dec_var_list_set.variables).length > 5)) {
      set_show_upgrade_account_button(true)
      show_decision_variables_button_color.color = "grey"
      set_show_decision_variables_button_color(show_decision_variables_button_color)
    } else {
      show_decision_variables_button_color.color = theme_blue_color
      set_show_decision_variables_button_color(show_decision_variables_button_color)
    }
    var new_initial_search_region = {}
    // Get all of the variables in the search region limits which are not default and which are not in the decision variables list
    // and add them to the top of the new initial search region with variable_is_defined set to false so the user can see that it is 
    // not defined
   
    Object.keys(initialSearchRegionv2.region).map(dec_var=>{
      // If the variable is not in the decision variables list and is not default we set variable_is_defined to false and 
      // add it to the top of the new initial search region so the user can see it is not defined
      if((!dec_var_list_set.variables.has(dec_var)) && (initialSearchRegionv2.region[dec_var].non_default_limits)){
        new_initial_search_region[dec_var] =  initialSearchRegionv2.region[dec_var]
        new_initial_search_region[dec_var].variable_is_defined = false
        new_initial_search_region[dec_var].variable_is_defined_color=  'red' // 'orange' //
        isInitialSearchRegionValid.value = false
        show_initial_search_region_button_text_color.color="grey"
      } else if((!initialSearchRegionv2.region[dec_var].limits.lower.is_parsable) || (!initialSearchRegionv2.region[dec_var].limits.upper.is_parsable)){
        new_initial_search_region[dec_var] =  initialSearchRegionv2.region[dec_var]
        isInitialSearchRegionValid.value=false
        show_initial_search_region_button_text_color.color="grey"
      }
    })
    if(Object.keys(new_initial_search_region).length==0){
        var no_undefined_vars = true
    } else {
        var no_undefined_vars = false
        isInitialSearchRegionValid.value=false
        show_initial_search_region_button_text_color.color="grey"
    }
    var no_blank_vars = true
    var no_unparsable_vars = true
    Array.from(dec_var_list_set.variables).map(dec_var=>{
      if(typeof initialSearchRegionv2.region[dec_var] != "undefined"){
          new_initial_search_region[dec_var] = initialSearchRegionv2.region[dec_var]
          new_initial_search_region[dec_var].variable_is_defined = true
          new_initial_search_region[dec_var].variable_is_defined_color='black'
          if((!new_initial_search_region[dec_var].limits.lower.is_parsable) || (!new_initial_search_region[dec_var].limits.upper.is_parsable)){
              no_unparsable_vars = false
              isInitialSearchRegionValid.value=false
              show_initial_search_region_button_text_color.color="grey"
          }
      } else {
          if(dec_var == ""){
            var variable_is_defined =  false
            no_blank_vars = false
            isInitialSearchRegionValid.value=false
            show_initial_search_region_button_text_color.color="grey"
          } else {
            var variable_is_defined =  true
            var variable_is_defined_color='black'
            const dummy2 = JSON.parse(JSON.stringify(defaultSearchRegion.region))
            new_initial_search_region[dec_var] = {
              limits: dummy2,
              // Here we are not going to set variable_is_defined to be false if the variable is blank because all variables which are not defined are moved to the top of
              // the initial search region, and a blank decision variable will only be present if they have just added it.
              variable_is_defined:variable_is_defined,
              variable_is_defined_color:variable_is_defined_color,
              non_default_limits:false,
              show_error_modal:false,
            }
          }
      }
    })
    initialSearchRegionv2.region=new_initial_search_region
    if(no_undefined_vars && no_blank_vars && no_unparsable_vars){
      isInitialSearchRegionValid.value=true
      if(defaultSearchRegion.region.lower.is_parsable && defaultSearchRegion.region.upper.is_parsable){
        show_initial_search_region_button_text_color.color=theme_blue_color
      }
    }
    var aaa = ((Object.keys(new_initial_search_region).map(item=>{return '"'+item+'":['+new_initial_search_region[item].limits.lower.value+","+new_initial_search_region[item].limits.upper.value+'],'})).join("")).slice(0,-1)
    var texta = '{'+aaa+'}'
    Lims.value=texta
    CustomLims.value=true
    await set_sub_show(false)
    await set_sub_show(true)
}




async function on_decision_variables_submit_editing (text) {
  console.log("text")
  console.log(text)
    var aaa = decisionVariableString.value.replace(/[^A-Za-z,\s+]/g, '')
    var new_dec_var_list_set = [...Array.from(dec_var_list_set.variables), aaa]
    dec_var_list_set.variables = new Set(new_dec_var_list_set)
    set_dec_var_list_set(dec_var_list_set)
    decisionVariableString.value = ""
    setDecisionVariableString(decisionVariableString)
    await set_sub_show(false)
    await set_sub_show(true)
}







async function on_tags_submit_editing (text) {
  set_recommended_problem_tags_and_description(new Set([]))
  var newtext = problem_tags_and_description_string.value.toLowerCase().replace(/[^a-z0-9_-]/g, "")
  var tagss = [...Array.from(problem_tags_and_description.tags), newtext]
  problem_tags_and_description.tags = new Set(tagss)
  set_problem_tags_and_description(problem_tags_and_description)
  // problem_tags_and_description.tags.push(text)
  problem_tags_and_description_string.value = ""
  set_problem_tags_and_description_string(problem_tags_and_description_string)
  get_tags('unique-problem-tags',"")
  await set_sub_show(false)
  await set_sub_show(true)
}


  async function on_tags_change_text (text) {

    if((text[text.length-1]==" ") || (text[text.length-1]==",")){
      set_recommended_problem_tags_and_description(new Set([]))
      text = text.toLowerCase().replace(/[^a-z0-9_-]/g, "")
      var tagss = [...Array.from(problem_tags_and_description.tags), text]
      problem_tags_and_description.tags = new Set(tagss)
      set_problem_tags_and_description(problem_tags_and_description)
      // problem_tags_and_description.tags.push(text)
      problem_tags_and_description_string.value = ""
      set_problem_tags_and_description_string(problem_tags_and_description_string)
      get_tags('unique-problem-tags',"")

    } else {
      problem_tags_and_description_string.value = text.toLowerCase().replace(/[^a-z0-9_-]/g, "")
      set_problem_tags_and_description_string(problem_tags_and_description_string)
      if(problem_tags_and_description_string.value.length>0){
        get_tags('unique-problem-tags',problem_tags_and_description_string.value)
      } else {
        get_tags('unique-problem-tags',"")
      }


    }
    // problem_tags_and_description_string.value = text.toLowerCase().replace(/[^a-z0-9_-]/g, "")
    // set_problem_tags_and_description_string(problem_tags_and_description_string)

    // text = text.replace(/\s+/g, '').replace(/[^A-Za-z,]/g, '')
    // var dec_var_list = text.split(",")
    // var non_empty_dec_var_list = []
    // dec_var_list.map((item) => {if(item != ""){non_empty_dec_var_list.push(item)}})
    // var new_dec_var_list_set = new Set(non_empty_dec_var_list)
    // dec_var_list_set.variables = new_dec_var_list_set
    // if(free_tier && (Array.from(dec_var_list_set.variables).length > 5)) {
    //   set_show_upgrade_account_button(true)
    //   show_decision_variables_button_color.color = "grey"
    //   set_show_decision_variables_button_color(show_decision_variables_button_color)
    // } else {
    //   show_decision_variables_button_color.color = theme_blue_color
    //   set_show_decision_variables_button_color(show_decision_variables_button_color)
    // }
    // var new_initial_search_region = {}

    // // Get all of the variables in the search region limits which are not default and which are not in the decision variables list
    // // and add them to the top of the new initial search region with variable_is_defined set to false so the user can see that it is 
    // // not defined
   
    // Object.keys(initialSearchRegionv2.region).map(dec_var=>{
    //   // If the variable is not in the decision variables list and is not default we set variable_is_defined to false and 
    //   // add it to the top of the new initial search region so the user can see it is not defined
    //   if((!new_dec_var_list_set.has(dec_var)) && (initialSearchRegionv2.region[dec_var].non_default_limits)){
    //     new_initial_search_region[dec_var] =  initialSearchRegionv2.region[dec_var]
    //     new_initial_search_region[dec_var].variable_is_defined = false
    //     new_initial_search_region[dec_var].variable_is_defined_color=  'red' // 'orange' //
    //     isInitialSearchRegionValid.value = false
    //     show_initial_search_region_button_text_color.color="grey"
    //   } else if((!initialSearchRegionv2.region[dec_var].limits.lower.is_parsable) || (!initialSearchRegionv2.region[dec_var].limits.upper.is_parsable)){
    //     new_initial_search_region[dec_var] =  initialSearchRegionv2.region[dec_var]
    //     isInitialSearchRegionValid.value=false
    //     show_initial_search_region_button_text_color.color="grey"
    //   }
    // })
    // if(Object.keys(new_initial_search_region).length==0){
    //     var no_undefined_vars = true
    // } else {
    //     var no_undefined_vars = false
    //     isInitialSearchRegionValid.value=false
    //     show_initial_search_region_button_text_color.color="grey"
    // }
    // var no_blank_vars = true
    // var no_unparsable_vars = true
    // Array.from(new_dec_var_list_set).map(dec_var=>{
    //   if(typeof initialSearchRegionv2.region[dec_var] != "undefined"){
    //       new_initial_search_region[dec_var] = initialSearchRegionv2.region[dec_var]
    //       new_initial_search_region[dec_var].variable_is_defined = true
    //       new_initial_search_region[dec_var].variable_is_defined_color='black'
    //       if((!new_initial_search_region[dec_var].limits.lower.is_parsable) || (!new_initial_search_region[dec_var].limits.upper.is_parsable)){
    //           no_unparsable_vars = false
    //           isInitialSearchRegionValid.value=false
    //           show_initial_search_region_button_text_color.color="grey"
    //       }
    //   } else {
    //       if(dec_var == ""){
    //         var variable_is_defined =  false
    //         no_blank_vars = false
    //         isInitialSearchRegionValid.value=false
    //         show_initial_search_region_button_text_color.color="grey"
    //       } else {
    //         var variable_is_defined =  true
    //         var variable_is_defined_color='black'
    //         const dummy2 = JSON.parse(JSON.stringify(defaultSearchRegion.region))
    //         new_initial_search_region[dec_var] = {
    //           limits: dummy2,
    //           // Here we are not going to set variable_is_defined to be false if the variable is blank because all variables which are not defined are moved to the top of
    //           // the initial search region, and a blank decision variable will only be present if they have just added it.
    //           variable_is_defined:variable_is_defined,
    //           variable_is_defined_color:variable_is_defined_color,
    //           non_default_limits:false,
    //           show_error_modal:false,
    //         }
    //       }
    //   }
    // })
    // initialSearchRegionv2.region=new_initial_search_region
    // if(no_undefined_vars && no_blank_vars && no_unparsable_vars){
    //   isInitialSearchRegionValid.value=true
    //   if(defaultSearchRegion.region.lower.is_parsable && defaultSearchRegion.region.upper.is_parsable){
    //     show_initial_search_region_button_text_color.color=theme_blue_color
    //   }
    // }
    // var aaa = ((Object.keys(new_initial_search_region).map(item=>{return '"'+item+'":['+new_initial_search_region[item].limits.lower.value+","+new_initial_search_region[item].limits.upper.value+'],'})).join("")).slice(0,-1)
    // var texta = '{'+aaa+'}'
    // Lims.value=texta
    // CustomLims.value=true
    await set_sub_show(false)
    await set_sub_show(true)
}



























let all_elements_true_checker = arr => arr.every(v => v === true);

// function get_undefined_vars_in_search_region_limits (dec_var_list) {
//   var undefined_vars_in_search_region_limits = []
//   initialSearchRegion.map(item=>{
//       // Return all initial search region entries that have already been deemed to be not valid 
//       // (such as those that the user edited and the user edits were not valid. 
//       // For example, a entry which is missing an upper or lower bound)
//       if(item.variable_is_defined==false){
//           undefined_vars_in_search_region_limits.push(item)
//       // If the item has not already been deemed to be invalid, if it is custom (meaning the user edited it),
//       // check to see if its variable is still defined in the list of decision variables
//       } else if(item.non_default_limits==true){
//           var indexx = dec_var_list.indexOf(item.variable)
//           item.variable_is_defined=true
//           if(indexx !== -1){
//               new_initial_search_region[indexx] = item
//           } else {
//               item.variable_is_defined=false
//               undefined_vars_in_search_region_limits.push(item)
//           }
//       }
//   })
//   return undefined_vars_in_search_region_limits
// }



async function onInitialSearchRegionChangeText (text, key, upper_or_lower) {
  text = text.replace(/\s+/g, '').replace(/[^0-9.e-]/g, '')
  // console.log(JSON.stringify(initialSearchRegionv2))
  // var dataa = JSON.parse(JSON.stringify(initialSearchRegionv2.region)) 
  var dataa = initialSearchRegionv2.region
  dataa[key].limits[upper_or_lower].value = text
  dataa[key].non_default_limits = true
  if(text=="" || isNaN(Number(text))){
    dataa[key].limits[upper_or_lower].is_parsable = false
    // setIsInitialSearchRegionValid(false)
    isInitialSearchRegionValid.value=false
    // set_show_initial_search_region_button_text_color("grey")
    show_initial_search_region_button_text_color.color="grey"
  } else {

    dataa[key].limits[upper_or_lower].is_parsable = true
  }

  // console.log(JSON.stringify(dataa))
  initialSearchRegionv2.region=dataa
  setInitialSearchRegionv2(initialSearchRegionv2)
  // setInitialSearchRegionv2(dataa)


  // try{
  //     var upper_and_lower_bounds = dataa[key].replace(/\s+/g, '').split(",")
  //     // Check to see if there is only one "," in the initial search region being edited by the user
  //     if((upper_and_lower_bounds.length==2)){
  //       // Check to see if the lower and upper bounds are non-empty (contain any characters at all)
  //       if((upper_and_lower_bounds[0].length>0) && (upper_and_lower_bounds[1].length>0)){
  //           // Check to see if both the upper and lower bounds can be converted to type Number 
  //           // (letters and special characters other than whitespace become NaN when converted to type Number)
  //           if((!isNaN(Number(upper_and_lower_bounds[0]))) && (!isNaN(Number(upper_and_lower_bounds[1])))){
  //             dataa[key].is_parsable = false
  //           }
  //       }
  //     }
  // } catch {
  //   dataa[key].is_parsable = false
  //   // setInitialSearchRegion(dataa)
  //   setIsInitialSearchRegionValid(false)
  // }
  // setInitialSearchRegion(dataa)
  if(
      all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].limits.lower.is_parsable})) &&
      all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].limits.upper.is_parsable})) &&
      all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].variable_is_defined})) //&&
      // all_elements_true_checker(Object.keys(defaultSearchRegion.region).map(item=>{return defaultSearchRegion.region[item].is_parsable}))
  ){
      // setIsInitialSearchRegionValid(true)
      isInitialSearchRegionValid.value = true
      if(defaultSearchRegion.region.lower.is_parsable && defaultSearchRegion.region.upper.is_parsable){
        // set_show_initial_search_region_button_text_color(theme_blue_color)
        show_initial_search_region_button_text_color.color = theme_blue_color
    }
      // set_show_initial_search_region_button_text_color(theme_blue_color)
  }
  await set_sub_show(false)
  await set_sub_show(true)
}


async function remove_initial_search_region_variable(key) {
    var dataa = JSON.parse(JSON.stringify(initialSearchRegionv2.region)) 
    delete dataa[key]
    initialSearchRegionv2.region=dataa
    setInitialSearchRegionv2(initialSearchRegionv2)
    // setInitialSearchRegionv2(dataa)
    if(
        all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].limits.lower.is_parsable})) &&
        all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].limits.upper.is_parsable})) &&
        all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].variable_is_defined})) //&&
        // all_elements_true_checker(Object.keys(defaultSearchRegion.region).map(item=>{return defaultSearchRegion.region[item].is_parsable}))
    ){
        // setIsInitialSearchRegionValid(true)
        isInitialSearchRegionValid.value = true
        if(defaultSearchRegion.region.lower.is_parsable && defaultSearchRegion.region.upper.is_parsable){
            // set_show_initial_search_region_button_text_color(theme_blue_color)
            show_initial_search_region_button_text_color.color = theme_blue_color
        }
        // set_show_initial_search_region_button_text_color(theme_blue_color)
    } else {
        // setIsInitialSearchRegionValid(false)
        isInitialSearchRegionValid.value = false
        // set_show_initial_search_region_button_text_color("grey")
        show_initial_search_region_button_text_color.color = "grey"
    }
    await setShow(false)
    await setShow(true)
}

 async function change_default_search_region (value, upper_or_lower)  {
    var dataa = JSON.parse(JSON.stringify(defaultSearchRegion.region))
    dataa[upper_or_lower].value = value.replace(/\s+/g, '').replace(/[^0-9.e-]/g, '')
    if(dataa[upper_or_lower].value=="" || isNaN(Number(dataa[upper_or_lower].value))){
        dataa[upper_or_lower].is_parsable = false
        // setIsInitialSearchRegionValid(false)
        // set_show_initial_search_region_button_text_color("grey")
        show_initial_search_region_button_text_color.color = "grey"
    } else {
        dataa[upper_or_lower].is_parsable = true
        if(isInitialSearchRegionValid.value && dataa.lower.is_parsable && dataa.upper.is_parsable){
            // set_show_initial_search_region_button_text_color(theme_blue_color)
            show_initial_search_region_button_text_color.color = theme_blue_color
        }
        // if(
        //   all_elements_true_checker(Object.keys(initialSearchRegionv2).map(item=>{return initialSearchRegionv2[item].limits.lower.is_parsable})) &&
        //   all_elements_true_checker(Object.keys(initialSearchRegionv2).map(item=>{return initialSearchRegionv2[item].limits.upper.is_parsable})) &&
        //   all_elements_true_checker(Object.keys(initialSearchRegionv2).map(item=>{return initialSearchRegionv2[item].variable_is_defined})) &&
        //   all_elements_true_checker(Object.keys(dataa).map(item=>{return dataa[item].is_parsable}))
        // ){
        //     setIsInitialSearchRegionValid(true)
        //     set_show_initial_search_region_button_text_color(theme_blue_color)
        // }
    }
    // setDefaultSearchRegion(dataa)
    defaultSearchRegion.region =dataa
    set_defaultSearchRegion(defaultSearchRegion)
    // setDefaultSearchRegion(defaultSearchRegion)
    await set_sub_show(false)
    await set_sub_show(true)
 }

// async function get_variable_is_defined_color(key) {
//   // ()=>{if(initialSearchRegionv2[key].variable_is_defined==false){return "red"}else{return "black"}}
//     // set_show_initial_search_region(false)
//     console.log("get_variable_is_defined_color CALLED")
//     if(initialSearchRegionv2[key].variable_is_defined){
//         // return '#00ff00'
//         console.log("HIT")
//         return 'red'
//     } else {
//         return '#ff0000'
//         // return 'red'
//     }
//     // set_show_initial_search_region(true)
// }


  // function onInitialSearchRegionChangeText (text, index) {
  //     var dataa = initialSearchRegion
  //     dataa[index] = text
  //     setInitialSearchRegion(dataa)
  //     try{
  //         JSON.parse(dataa[index])
  //     } catch {
  //         dataa[index].valid = false
  //         setIsInitialSearchRegionValid(dataa)
  //     }
  // }





  // const onchhangetext = (text, index) => {
  //   // as there are going to be a lot of setState calls
  //   // we need access the prevState before we set the next state.
  //   // setShow(false)
  
  //   // console.log(textInputValues)
  //   var dataa = textInputValues
  //   dataa[index] = text
  //   settextInputValues(dataa)
  //   // console.log(textInputValues)
  //   // return Promise
  //   // setShow(true)
  //   // this.setState(prevState => {
  //   //   prevState.textArray[index] = text
  //   //   return {
  //   //     textArray: prevState.textArray
  //   //   }
  //   // }, () => console.log(this.state.textArray))
  // }


  const convert_to_csv = async(keys, arr)  =>  {  
      // // (*) -----  Write Header Row  ---- (*)
      var csv_data = ""
      console.log("[+] Creating CSV Data...")
      csv_data += keys.join(',') + '\n';
      console.log("Header Values: ", csv_data) 

      // (*) ----- Convert Promise Object to String ---- (*) //
      for (var i = 0; i < arr.length; i++) {
          // var dec_vars = JSON.stringify(arr[i]["Decision_Variable_Solutions"])   
          // console.log("Decision Variables: ", dec_vars)
          // var dec_vars_json = JSON.parse(dec_vars)
          // console.log("Decision Variables: ", dec_vars_json)
          // var decVarSols = "" + arr[i]["Decision_Variable_Solutions"]
          var decVarSols = JSON.stringify(arr[i]["Decision_Variable_Solutions"])
          decVarSols = decVarSols.split('"').join('').split("'").join('').split(",").join(', ')
          // var dummy = JSON.stringify(arr[i]["Decision_Variable_Solutions"])
          // dummy = await dummy.replace('"','').replace("'", '')
          console.log("decVarSols: ", JSON.stringify(decVarSols))
          
          var constraints = JSON.stringify(arr[i]["Constraints"]) 
          constraints = await constraints.split('"').join('').split("'").join('').split(",").join(', ')
          // constraints = await constraints.replace('"', '').replace("'", '')
          console.log("constraints: ", constraints)

          // arr[i]["Decision_Variable_Solutions"] = '\"'  +JSON.stringify(arr[i]["Decision_Variable_Solutions"]).replace('"','') + '\"';
          arr[i]["Decision_Variable_Solutions"] = '\"'  + decVarSols + '\"';
          console.log("Decision Variables: ", arr[i]["Decision_Variable_Solutions"])
          
          // arr[i]["Constraints"] = '\"'  + JSON.stringify(arr[i]["Constraints"]).replace('"','') + '\"';   
          arr[i]["Constraints"] = '\"'  + constraints + '\"';           
          
          
          // csvData.push( "\\"" + strData.replace( /\\"/g , "\\"\\"" ) + "\\"" )  
          // arr[i]["Decision_Variable_Solutions"] =  "\\"" + JSON.stringify(arr[i]["Decision_Variable_Solutions"]).replace( /\\"/g , "\\"\\"" ) + "\\""
          // arr[i]["Decision_Variable_Solutions"] = "\\"" + JSON.stringify(arr[i]["Decision_Variable_Solutions"]).replace( /\\"/g , "\\"\\"" ) + "\\"" )  
          
          
          
          // row += '\"' + index + '\",';
          // row += '\"' + index + '\"';
        }
        console.log("arr: ", arr)
        
        // (*) ----- Iterate items in solutions_arr[] and write rows to csv file  ---- (*)
      for (var item of arr) { 
        console.log("[+] Writing row to csv file ")  
        console.log("item: ", item)       
        // Ignore the commas in string values
        var row = Object.values(item).join(',') + '\n';
        console.log("row: ", row)        
        // csv_data += keys.map(keys =>  item[keys]).join(',') + '\n';
        csv_data += row

      }
   
      
      return csv_data

}

// const convertToCSV = async(objArray) => {
//   console.log("[+] Checking the data structure of objArray: ", typeof objArray)
//   var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
//   var csv_str = '';

//   var keys = Object.keys(array[0]);
//   console.log("[+] Keys: ", keys)

//   objArray.forEach(function(item) {
//       console.log("item: ", item)
//       var row = Object.values(item).join(',');
//       console.log("row: ", row)
//       for (var i = 0; i < row.length; i++) {
//           if (row[i] == ',') {
//               // row = row.replace(',', ';');
//               row = row.replace(/"/g, '""');
//               console.log("new row: ", row)
//           }
//       }
    
//         console.log("[+] Returning CSV String...: ", csv_str)
//   })

//   for (var i = 0; i < array.length; i++) {
//       var line = '';
//       for (var index in array[i]) {
//           if (line != '') line += ','

//           line += array[i][index];
//       }

//       str += line + '\r\n';
//   }
//   console.log("[+] str: ", str)

//   return str;
// // }

// const exportCSVFile = async(headers, items, fileTitle) => {
//   if (headers) {
//     items.unshift(headers);
//   }
  
//   console.log("[+] Converting object to JSON format...")
//   var jsonObject = JSON.stringify(items);
//   // var csv_data = convertToCSV(jsonObject);
//   var keys = Object.keys(jsonObject[0])
//   var csv_data = convert_to_csv(keys, jsonObject);
//   console.log("[+] csv_data: ", csv_data)
//   var csvData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_data);
//   console.log("csvData: ", csvData)

//   var blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;'})
//   if (navigator.msSaveBlob) { // IE 10+
//     navigator.msSaveBlob(blob, fileTitle);
// } else {
//     var link = document.createElement("a");
//     if (link.download !== undefined) { // feature detection
//         // Browsers that support HTML5 download attribute
//         var csvLink = URL.createObjectURL(blob);
//         csvLink.setAttribute("href", url);
//         csvLink.setAttribute("download", fileTitle);
//         csvLink.style.visibility = 'hidden';
//         document.body.appendChild(csvLink);
//         csvLink.click();
//         document.body.removeChild(csvLink);
//     }
// }
// }

// const export_csv_file = async(items) => {
//   console.log("[+] Exporting  objArray to CSV...", objArray)
//   var jsonObject = JSON.stringify(items);

//   var csv = convert_to_csv(jsonObject);
//   // var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
//   var exportedFileName = 'solutions.csv'
//   var blob = Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
  
//   try {
//     var url = URL.createObjectURL(blob);
//     link.setAttribute("href", url);
//     link.setAttribute("download", exportedFileName);
//     link.style.visibility = 'hidden';
//     document.body.appendChild(link);
//     // link.click();

//   } catch(error) {
//     console.log("[-] export_csv_file error: ", error)

//   }

//   // if (navigator.msSaveBlob) { // IE 10+
//   //     navigator.msSaveBlob(blob, exportedFilenmae);
//   // } else {
//   //     var link = document.createElement("a");
//   //     if (link.download !== undefined) { // feature detection
//   //         // Browsers that support HTML5 download attribute
//   //         var url = URL.createObjectURL(blob);
//   //         link.setAttribute("href", url);
//   //         link.setAttribute("download", exportedFilenmae);
//   //         link.style.visibility = 'hidden';
//   //         document.body.appendChild(link);
//   //         link.click();
//   //         document.body.removeChild(link);
//   //     }
//   // }
// }

const format_constraints = async(constr_arr) => {  
  const constr_keys = Object.keys(constr_arr)    
  console.log("Formatting Constraint Keys: ", constr_keys)
  
  var constraints_obj = {}
  constr_keys.forEach((c_key, c_index) => {
    // console.log("c_key: ", c_key)
    // console.log("c_index: ", c_index)
    // const constraint_expression = constr_arr[c_index]
    // console.log("forEach()")
    // constraints_obj[c_key] = constr_arr[c_key]
    var constraint = constr_arr[c_key]
    console.log("constraint: ", constraint)
    var const_name = 'Constraint ' + c_key
    constraints_obj[const_name] = constraint
  })
    // console.log("Returning constraints_obj{}: ", constraints_obj)

  return constraints_obj

}




async function get_my_solutions() {
      const solutions_table = await query_dynamodb_table('solutions-table')
      // console.log("[+] solutions_table: ", solutions_table)
      setSolutionsTable(solutions_table)
      // console.log("[+] Updating the state of the SolutionsTable...")

      // console.log("new_user.attributes")
      // console.log(new_user.attributes)
      // console.log("essential_credentials")
      // console.log(essential_credentials)
      // console.log("new_user.attributes.sub")
      // console.log(new_user.attributes.sub)
      // console.log("identityId")
      // console.log(identityId)

    //       dynamodb_query_params = {
    //         TableName: 'solutions-table',
    //         ExpressionAttributeNames: {
    //           "#userId": "user_id",
    //           // "#sortKey": "sort_key"
    //         },
    //         ExpressionAttributeValues: {
    //           ":v1": {
    //               S: identityId
    //           },
    //           // ":v2": {
    //           //     S: "("
    //           // }
    //         },
    //         KeyConditionExpression: "#userId = :v1",
    //         // KeyConditionExpression: "#userId = :v1 AND  begins_with(#sortKey, :v2)",
    //       }


            
    //       // // console.log(essential_credentials)
    //       dynamoDBcommand = await new QueryCommand(dynamodb_query_params)


    //       // THE NEXT TWO LINES ARE USED TO QUERY THE SOLUTIONS-TABLE
    //       // const results = await dynamoDBclient.send(dynamoDBcommand)
    //       // console.log(results)
    //       solution_results = await dynamoDBclient.send(dynamoDBcommand)
    //       // console.log("solution_results: ", solution_results.Items) // --> Warning: Each child in a list should have a unique "key" prop
    //       // console.log("Solution Results:", JSON.stringify(solution_results))
    //       await setSolutionsTable(solution_results) 
      // setSolutionsQuery(true)
      // await setShowSolutions(true)
      // console.log("Solutions Table: ", solutionsTable)
      
      var billing_query_params = {
        TableName: 'api-usage-table',
        ExpressionAttributeNames: {
          "#userId": "user_id"
        },
        ExpressionAttributeValues: {
          ":v1": {
            S: identityId
            // S: new_user.attributes.sub
          }
        },
        KeyConditionExpression: "#userId = :v1"
      }
      const billing_query = await new QueryCommand(billing_query_params)
      billing_json = await dynamoDBclient.send(billing_query)
      // console.log("billing_json")
      // console.log(billing_json)
      // const billing_string  = JSON.stringify(billing_json.Items)
      // const billing_items = billing_results.Items
      // console.log("Billing Items", billing_items)
      // console.log("Billing Query Results: ", billing_string)
      await setBillingTable(billing_json)
      await setShowBilling(true)
}


  // ERROR - Invalid KeyConditionExpression: 
  // An expression attribute name used in the document path is not defined; 
      // attribute name: #sort_key
      // uniqueObjectiveFunctions undefined  

  const query_unique_problem = async (table_name, sort_key) => {
    console.log('[+] Querying solutions from Table Name: %s....', table_name)
    console.log("[+] sort_key: ", sort_key);

        const dynamodb_query_params = {
      TableName: table_name,
      ExpressionAttributeNames: {
        "#userId": "user_id",
       "#sortKey": "sort_key"
    },
      ExpressionAttributeValues: {
        ":v1": { S: identityId },
        // ":v2": { S: objective_function }
        ":v2": { S: sort_key },
      },
      // KeyConditionExpression: "#userId = :v1 and begins_with(#sort_key, :v2)",
      // KeyConditionExpression: "#userId = :v1",
      KeyConditionExpression: "#userId = :v1 and begins_with(#sortKey, :v2)",
      // Limit: 10  
      ScanIndexForward: false,
    }
    const dynamoDBcommand = await new QueryCommand(dynamodb_query_params)
    // console.log("dynamoDBcommand: ", dynamoDBcommand)
    const response_JSON = await dynamoDBclient.send(dynamoDBcommand)
    // console.log("DynamoDB Query Response (JSON) : ", response_JSON)
    console.log(`[+] Queried %s items from ${table_name}`, response_JSON.Items.length)
    // const response_string = JSON.stringify(response_JSON)    
    return response_JSON
}


const Get_Unique_Obj_Functions = async (objective_function, key) => {
  console.log("[+] Get_Unique_Obj_Functions() called... key -> ", key)
if (key.showAllUniqueProblems == false) {                                             
  console.log("========== [+] Clicked 'Show Unique Problems' Button... key ===== ", key);    
  key.showAllUniqueProblems = true;                                    
  // console.log("[+] key.uniqueProblemConstraints", JSON.stringify(key.uniqueProblemConstraints))
} else {
  console.log("[-] Shrink the problem list")
  //  solutionsTable.Items[indexxx].showAllUniqueProblems = false
  key.showAllUniqueProblems = false;
  //  setSolutionsTable(solutionsTable)
}

console.log("Fetching unique combination of constraints for unique problem: ", objective_function)
const query_unique_constraints = await query_unique_problem('unique_objective_plus_constraint', objective_function)      
 console.log("[+] Query response from 'unique_objective_plus_constraint' table: ", query_unique_constraints)                                        
  setUniqueProblemConstraints(query_unique_constraints.Items);
  setShow(false)
  setShow(true)   
  return query_unique_constraints;
}

async function get_constraints_for_obj_func (objective_function, key, indexxx) {

      if(key.showAllUniqueProblems == true) {
        solutionsTable.Items[indexxx].showAllUniqueProblems = false;
      } else {
        solutionsTable.Items[indexxx].showAllUniqueProblems = true;
        console.log("Fetching unique combination of constraints for unique problem: ", objective_function)
        const query_unique_constraints = await query_unique_problem('unique_objective_plus_constraint', objective_function)      
        console.log("[+] Query response from 'unique_objective_plus_constraint' table: ", query_unique_constraints)  
        const unique_constraint_sets = query_unique_constraints.Items;
        var non_empty_unique_constraint_sets = []
        unique_constraint_sets.map(unique_constraint_set => {
          if ( (unique_constraint_set.sort_key.S.split(':')[1]) != "") {
            non_empty_unique_constraint_sets.push(unique_constraint_set)
          }
        })   
        solutionsTable.Items[indexxx].uniqueProblemConstraints = non_empty_unique_constraint_sets;                                         
          // setUniqueProblemConstraints(query_unique_constraints.Items);
          // return query_unique_constraints;
      }
      await setSolutionsTable(solutionsTable)
      await  set_sub_show(false)
      await  set_sub_show(true)  
}





async function get_solutions_for_problem_constraint_combo (objective_function,  indexxx, showSolutionss, con_index, constraint_string) {
  // console.log("[+] get_solutions_for_problem_constraint_combo() called... objective_function -> ", objective_function)
  // console.log("[+] get_solutions_for_problem_constraint_combo() called... constraint_string -> ", constraint_string)
  // console.log("[+] get_solutions_for_problem_constraint_combo() called... indexxx -> ", indexxx)
  // console.log("[+] get_solutions_for_problem_constraint_combo() called... showSolutionss -> ", showSolutionss)
  // console.log("[+] get_solutions_for_problem_constraint_combo() called... con_index -> ", con_index)
  // key.uniqueProblemConstraints[con_key].showSolutions
  
  
  
  if(solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].showSolutions == true) {
    solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].showSolutions = false;
  } else {
    solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].showSolutions = true;
    var solutions_for_objective_constraint_combo = await query_unique_problem('solutions-table', (objective_function+":"+constraint_string))      
    solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions= solutions_for_objective_constraint_combo.Items;
  }
  await setSolutionsTable(solutionsTable)
  await  set_sub_show(false)
  await  set_sub_show(true)  

  console.log("[+] Returning data back from get_solutions_for_problem_constraint_combo()...")
  // return solutions_for_objective_constraint_combo
  
// console.log("[+] Finished get_solutions_for_problem_constraint_combo()... ->", JSON.stringify(probAndConstraintSolutions))                                          
  // key["obj_cons_solutions_combo"] = probAndConstraintSolutions.Items
  // console.log("[+] key.obj_cons_solutions_combo: ", JSON.stringify(key["obj_cons_solutions_combo"]))
  // console.log("[+] data type: ", typeof(key.obj_cons_solutions_combo))
}










const get_tags = async (table_name,tag_to_query) => {
   var newsett = new Set(problem_tags_and_description.tags)
  var dynamodb_query_params = {}
  var response_items = []
  if(tag_to_query == "") {
      tag_to_query = "EMPTY"

     dynamodb_query_params = {
      TableName: table_name,
      ExpressionAttributeNames: {
        "#userId": "user_id",
        // "#sortKey": "sort_key"
        },
      ExpressionAttributeValues: {
        ":v1": { S: identityId },
        // ":v2": { S: tag_to_query }

      },
      // KeyConditionExpression: "#userId = :v1 and begins_with(#sortKey, :v2)",
      KeyConditionExpression: "#userId = :v1",
      Limit: 10,  
      // ScanIndexForward: false,
    }
  } else {
     dynamodb_query_params = {
      TableName: table_name,
      ExpressionAttributeNames: {
        "#userId": "user_id",
        "#sortKey": "sort_key"
        },
      ExpressionAttributeValues: {
        ":v1": { S: identityId },
        ":v2": { S: tag_to_query }

      },
      KeyConditionExpression: "#userId = :v1 and begins_with(#sortKey, :v2)",
      // KeyConditionExpression: "#userId = :v1",
      Limit: 10,  
      // ScanIndexForward: false,
    }
  }
    try{
        
        const dynamoDBcommand = await new QueryCommand(dynamodb_query_params)
        const response_JSON = await dynamoDBclient.send(dynamoDBcommand)
        await response_JSON.Items.map(item=>{
          if(!newsett.has(item.sort_key.S)){
            response_items.push(item.sort_key.S)
          }
        })
        await set_recommended_problem_tags_and_description(new Set(response_items))
        await set_sub_show(false)
        await set_sub_show(true)
        // return response_JSON
    } catch {
        const credentials = await Auth.currentCredentials()
        essential_credentials =  await Auth.essentialCredentials(credentials)
        dynamoDBclient = await new DynamoDBClient({ 
          region: "us-west-2",
          credentials: essential_credentials
        });
        const dynamoDBcommand = await new QueryCommand(dynamodb_query_params)
        const response_JSON = await dynamoDBclient.send(dynamoDBcommand)
        await response_JSON.Items.map(item=>{
          if(!newsett.has(item.sort_key.S)){
            response_items.push(item.sort_key.S)
          }
        })
        await set_recommended_problem_tags_and_description(new Set(response_items))
        await set_sub_show(false)
        await set_sub_show(true)
        // return response_JSON
    }
}






















  // async function query_dynamodb_table(table_name) {
  const query_dynamodb_table = async (table_name) => {
    console.log("[+] query_dynamodb_table() called...")
    console.log("[+] table_name: ", table_name)
    try {
        // console.log('[+] Querying DynamoDB Table: %s....', table_name)
        // ===== DynamoDB Query ====== //
          // (*) Hash/Partition Key -> UserID
          // (*) Sort Key -> Objective Function
              //  --> KeyConditionExpression: "#userId = :v1 and begins_with(#sort_key, :v2)",
              var credentials = await Auth.currentCredentials()
              console.log("[+] credentials: ")
              console.log(credentials)
              var essential_credentials =  await Auth.essentialCredentials(credentials)
              console.log ("[+] essential_credentials: ", essential_credentials)
              dynamoDBclient = await new DynamoDBClient({ 
                region: "us-west-2",
                credentials: essential_credentials
              });
              var identityId = essential_credentials.identityId
              console.log("[+] identityId: ", identityId)
        // const dynamodb_query_params = {
        //   // TableName: 'solutions-table',
        //   TableName: table_name,
        //   ExpressionAttributeNames: {
        //     "#userId": "user_id",
        //     "#sort_key": "sort_key"
        //     },
        //   ExpressionAttributeValues: {
        //     ":v1": { S: identityId },
        //     ":v2": { S: objective_function }
        //   },
        //   KeyConditionExpression: "#userId = :v1 and begins_with(#sort_key, :v2)",
        //   // FilterExpression: "#sort_key = :v2",
        //     // (*) Filter Expression can only contain non-primary key atributes 
        // }

        const dynamodb_query_params = {
          TableName: table_name,
          ExpressionAttributeNames: {
            "#userId": "user_id"
            },
          ExpressionAttributeValues: {
            ":v1": { S: identityId }
          },
          // KeyConditionExpression: "#userId = :v1 and begins_with(#sort_key, :v2)",
          KeyConditionExpression: "#userId = :v1",
          // Limit: 10  
          ScanIndexForward: false,
        }
        console.log("dynamodb_query_params: ", dynamodb_query_params)

        const dynamoDBcommand = await new QueryCommand(dynamodb_query_params)
        // console.log("dynamoDBcommand: ", dynamoDBcommand)
        const response_JSON = await dynamoDBclient.send(dynamoDBcommand)
        // console.log("DynamoDB Query Response (JSON) : ", response_JSON)
        // console.log("[+] Queried %s items from DynamoDB Table", response_JSON.Items.length)
        // const response_string = JSON.stringify(response_JSON)

      // STEP (1) Determine the amount of unique objective functions in table
        // console.log("[+] Determining the number of unique problems in solution table...")
        // var unique_probs = 0
        //  for (var ix = 0; i < response_JSON.solutions.length; ix++) {
      //     } 
          
          // STEP (2) For each unique objective function, send query that groups solutions by objective function
          // STEP (3) Display array of solutions for each batch query in the 'My Solutions' page
          
          // Dayto UserID: us-west-2:f754aef7-da46-4fec-8076-39c4123de596
          // var sort_key = '(1/x+y+z-3)^2+(x+1/y+z-3)^2+(x+y+1/z-3)^2:x+y<5:0.01:0.01:1:synchronous:afc04b6b-0b80-492f-abb7-d5cbf4845edb:589bb282-93cc-4c92-b993-57938dd04f71'
          // var objective_function = '(1/x+y+z-3)^2+(x+1/y+z-3)^2+(x+y+1/z-3)^2'
        
        return response_JSON
    } catch (error) {
      console.log("Error: request failed.")
      console.log("Error: ")
      console.log(error)
      await signOut()
      return {Items: []}
    }

      
  }



{/*
download_solutions_table() 
  Inputs: 
    - table: the 'solutionsTable' state from the DynamoDB query (solutions-table)  
        Step (1) Get the repsonse data from the DynamoDB 'solutions' table using the solutionsTable state
        Step (2) downloads the solutions to the device as a JSON file
        Step (3) opens the downloaded file in a new tab
     */}

  const download_solutions_table = async(table) => {
    console.log("Solutions Table Data: ", JSON.stringify(table.Items))
    console.log("Number of solutions in table: ", table.Items.length)

    var solutions_arr = []     
    var num_items = table.Items.length
    // (*) --- Loop through every item in the solutions table  ---- (*)
    for (var ii = 0; ii < num_items; ii++) {
      // console.log("Item: ", table.Items[ii].solution)
      var M = table.Items[ii].solution.M  
      
      // (*) --- Map Decision Variable Solutions to JSON File ---- (*)
      var decision_variable_solutions = {}
      // var decision_variable_solutions = []   
          for (const decision_variable in M) {                
              // console.log("Map(M) -> [decision_variable]: ): ", decision_variable)        
              // console.log(`Solution for ${decision_variable}: `, decision_variable_solution)
              var decision_variable_solution = Number(M[decision_variable].S)
              decision_variable_solutions[decision_variable] = decision_variable_solution
              // decision_variable_solutions.push(decision_variable_solution)
              // console.log("decision_variable_solutions: ", decision_variable_solutions)
            }
      
      // (*) ---  Map Objective Function to JSON File  ---- (*)
      var obj_func = table.Items[ii].sort_key.S.split(':')[0]   
      // sort_key: Object { 
                  // S: "(1/x+y+z-3)^2+(x+1/y+z-3)^2+(x+y+1/z-3)^2:0<1:0.01:0.01:1:synchronous:0722111f-60b5-4103-a544-c23622ea8dee:0e560876-bd20-4e0d-996c-16f8e5e34cfd" }
                       // user_id: Object { S: "us-west-2:f754aef7-da46-4fec-8076-39c4123de596" }


      // (*) ---  Map Constraints to JSON File ---- (*)
        // map() -> Creates a new array with the results of calling a function for every array element.
          // array.map(function(currentValue, index, arr), thisValue)
      var constr_arr = table.Items[ii].sort_key.S.split(':')[1].split(',')
      // (*) Format the items in the constraints array (*)
      // var constraints_array = []
      // for(var c = 0; c < constr_arr.length; c++){
      //   var constraint = constr_arr[c]
      //   constraints_array.push(constraint)          
      // }        
      var constraints_obj = {}
      // for (const constr_key in constr_arr) {
      //     var constr_val = constr_arr[constr_key]
      //     constraints_obj[constr_key] = constr_val
      // }
      // console.log("constraints_obj: ", constraints_obj)  
      // var constraints_obj = await format_constraints(constr_arr)    
      var constraints_obj = {}      
      var constr_keys = Object.keys(constr_arr)    

      constr_keys.forEach((c_key, c_index) => {
          // console.log("c_key: ", c_key)
          // console.log("c_index: ", c_index)
          // const constraint_expression = constr_arr[c_index]
          // console.log("forEach()")
          // constraints_obj[c_key] = constr_arr[c_key]
          var constraint = constr_arr[c_key]
          // console.log("constraint: ", constraint)
          var const_name = 'Constraint ' + c_key
          constraints_obj[const_name] = constraint
      })

      // console.log("[+] constraints_obj: ", constraints_obj)          

      // Map Number of Starting Points to JSON File
        // var initial_starting_points = table.Items[ii].initial_starting_points.N
        // console.log("initial_starting_points: ", initial_starting_points)
            // 4th index 
        var num_start_pts = Number(table.Items[ii].sort_key.S.split(':')[4])
        // console.log("num_start_pts: ", num_start_pts)


      // Add JSON item to solutions_arr[] array
      solutions_arr.push({
                "Objective_Function": obj_func,
                "Objective_Function_Value": Number(table.Items[ii].obj_func_val.S),
                "Decision_Variable_Solutions": decision_variable_solutions,
                // "constraints": constraints_array,
                "Constraints": constraints_obj,
                "Number_of_Starting_Points ": num_start_pts             
            })
          }

      // (*) ---- Export JSON Data to JSON File ---- (*) 
      console.log("[+] Exporting solutions to JSON file...")
      var jsonString = JSON.stringify(solutions_arr, null, "\t");
      var jsonUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(jsonString);
      // window.open(jsonUri);
      // let exportFileDefaultName = 'solutions.json';  
      var jsonLinkElement = document.createElement('a');
      jsonLinkElement.setAttribute('href', jsonUri);
      jsonLinkElement.setAttribute('download', 'solutions.json');
      jsonLinkElement.click();

      // (*) ----- Create CSV file from JSON data ---- (*)
      // console.log("[+] Creating CSV File from JSON data...")
      console.log("[+] Creating CSV File... ")
      var csv_keys = (solutions_arr[0] && Object.keys(solutions_arr[0])) || [];      
      // var csv_data = ""

      var json_arr = Array.from(solutions_arr)      

      await convert_to_csv(csv_keys, json_arr)
      .then((csv_data) => {
        window.URL = window.webkitURL || window.URL;
        var csvFile = new Blob([csv_data], {type: "text/CSV"})
        var csvLinkElement = document.createElement('a')
        // csvLinkElement.download = "solutions.csv"
        var csvUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(csv_data);
        // csvLinkElement.href = window.URL.createObjectURL(csvFile)
        csvLinkElement.setAttribute('href', csvUri)
        csvLinkElement.setAttribute('download', 'solutions.csv')
        csvLinkElement.click()
        console.log("[+] Exported solutions to CSV file.")

      })
     
    

      
      
      // // // (*) -----  Write Header Row  ---- (*)
      // console.log("[+] Writing header row to csv file")
      // csv_data += csv_keys.join(',') + '\n';
      // console.log("Header Values: ", csv_data)      
      // // // (*) ----- Iterate items in solutions_arr[] and write rows to csv file  ---- (*)
      // for (var sol_item of solutions_arr) { 
      //   console.log("[+] Writing row to csv file ")  
      //   // console.log("sol_item: ", sol_item)          
      //     // console.log("sol_item[csv_keys]: ", sol_item[csv_keys])
      //     csv_data += csv_keys.map(csv_keys => sol_item[csv_keys]).join(',') + '\n';
      // }


      // window.URL = window.webkitURL || window.URL;
      // var csvFile = new Blob([csv_data], {type: "text/CSV"})
      // var csvLinkElement = document.createElement('a')
      // // csvLinkElement.download = "solutions.csv"
      // var csvUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(csv_data);
      // // csvLinkElement.href = window.URL.createObjectURL(csvFile)
      // csvLinkElement.setAttribute('href', csvUri)
      // csvLinkElement.setAttribute('download', 'solutions.csv')
      // csvLinkElement.click()
      // console.log("[+] Exported solutions to CSV file.")
        
      // csvLinkElement.download = "solutions.csv"


      // console.log("csvUri: ", csvUri)      
      // csvLinkElement.setAttribute('href', csvUri)
      // csvLinkElement.setAttribute('download', 'solutions.csv')
      // csvLinkElement.click();
          
      // var csv_keys = (solutions_arr[0] && Object.keys(solutions_arr[0])) || []
      // fs.readFile('solutions.json', 'utf-8', (err, fileContent) => {
      //   if (err) {
      //     console.log("[-] Error reading file: ", err)
      //     throw new Error(err)
      //   }
      //   console.log("fileContent: ", fileContent)

      //   // Convert JSON to CSV 
      //   const csvData = csvjson.toCSV(fileContent, {
      //     headers: csv_keys
      //   })
      //   console.log("csvData: ", csvData)
      // })
      // console.log("[+] Fetching data from json uri")
      // fetch(jsonUri)
      //       .then(res => res.json())
      //       .then((out) => {
      //           console.log("output: ", out)
      //       }).catch(err => console.log(err))   
      // var csv_keys = (solutions_arr[0] && Object.keys(solutions_arr[0])) || []
      // var readFile = fs.readFile
      // var writeFile = fs.writeFile
     
      // console.log("[+] Reading json data from uri")
  //     FileSystem.readFile(jsonUri, 'utf-8', (err, fileContent) => {
  //       if (err) {
  //         console.log("[-] Error reading file: ", err)
  //       }
        
  //       console.log("[+] Converting JSON to CSV")
  //       // --- (*) Convert JSON to CSV --- (*) ---- //
  //       const csvData = csvjson.toCSV(fileContent, {
  //         headers: csv_keys
  //     })   

  // })
  // FileSystem.downloadAsync(uri, fileUri, options)
      // url (string) -- The remote URI to download from.
      // fileUri (string) -- The local URI of the file to download to. If there is no file at this URI, a new one is created. If there is a file at this URI, its contents are replaced. The directory for the file must exist.
      // <CsvDownload data={jsonUri}/>
}

const download_solution = async (solution) => {
console.log("[+] Downloading solution: ", solution)

    var decision_variable_solutions = {}
    var solution_map = solution.solution.M  
    
      // console.log("Solutions: ", Object.entries)
      Object.entries(solution_map).forEach(([key, value]) => {
          // console.log("key: ", key)
          // console.log("value: ", value)
        decision_variable_solutions[key] = value.S
      })
     
    var sort_key = solution.sort_key
    var obj_func = sort_key.S.split(':')[0]   
    var constraints = sort_key.S.split(':')[1].split(',')
    var num_start_pts = Number(sort_key.S.split(':')[4])

    var solutionObj = {
        "Objective_Function": obj_func,
        "Objective_Function_Value": Number(solution.obj_func_val.S),
        "Decision_Variable_Solutions": decision_variable_solutions,
        "Constraints": constraints,
        "Number_of_Starting_Points ": num_start_pts
    }

    var jsonString = JSON.stringify(solutionObj, null, "\t");
    var jsonUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(jsonString);    
    var jsonFileName = 'solution.json'
    var jsonLinkElement = document.createElement('a');
    jsonLinkElement.setAttribute('href', jsonUri);
    jsonLinkElement.setAttribute('download', jsonFileName);
    jsonLinkElement.click();

    // var jsonArray = [JSON.parse(jsonString)]
    console.log("[+] Creating CSV File... ")
    // var csv_keys = (jsonArray[0] && Object.keys(jsonArray[0])) || [];   
    // var csv_keys = Object.keys(jsonArray[0])

    var csv_keys = Object.keys(solutionObj)



    // await convert_to_csv(csv_keys, jsonArray)
    // await convertToCSV(jsonArray)
    var csv_dataa =   await convert_to_csv(csv_keys, [solutionObj])
    console.log("csv_dataa: ", csv_dataa)

    // await convert_to_csv(csv_keys, [solutionObj])
    // .then((csv_data) => {
      window.URL = window.webkitURL || window.URL;
      // var csvFile = new Blob([csv_dataa], {type: "text/CSV"})
      var csvLinkElement = document.createElement('a')
      var csvUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(csv_dataa);
      
      csvLinkElement.setAttribute('href', csvUri)
      csvLinkElement.setAttribute('download', 'solution2.csv')
      csvLinkElement.click()
      console.log("[+] Exported solutions to CSV file.")

    // })

}


  const solve = async()=>{
    // let checker = arr => arr.every(v => v === true);
    // if( inputsValid.every(v => v === true)){
    //   setisProcessing(true)

          // if(ProblemType=="optimization"){
            // optimizationsolve()
            if(!no_subscriptions){
            // TEST FREE LIMITS
          if(! (free_tier && ((nPoints.value > 5) || (textInputValuess.values.length > 5) || (Array.from(dec_var_list_set.variables).length > 5)) ) ) {
            // TEST CONSTRAINTS
            if( inputsValid.values.every(v => v === true) || (inputsValid.values.length==0)){
              // TEST INITIAL SEARCH REGION
              if(isInitialSearchRegionValid.value) {
                // TEST N STARTING POINTS
                if(!nPoints_warning.value){
                  // TEST MIN DISTANCE
                  if(!min_distance_warning.value){
                    // TEST MAX OBJ FUNC VAL
                    if(!max_obj_func_val_warning.value){
                          if(!max_runtime_warning.value){     
                              if(!max_solutions_to_return_warning.value){
                                    if(!ram_warning.value){
                                        // TEST DECISION VARIABLES
                                        if(!(/[~`!#$%\^&*+=\-\[\]\\';/{}|\\":<>\?]/g.test(decisionVariableString.value))){
                                          if(all_elements_true_checker(inputsValid.values)) {
                                            await setisProcessing(true)
                                            newsolve()
                                          } else {
                                            set_show_modal({show:true,text:constraints_error_text})
                                          }
                                        } else {
                                          set_show_modal({show:true,text:decision_variables_error_text })
                                        }
                                    } else {
                                        if(ProblemType=="sne"){
                                          set_show_modal({show:true,text:sne_ram_error})
                                        } else if(ProblemType=="optimization"){
                                          set_show_modal({show:true,text:opt_ram_error})
                                        }
                                    }
                                
                              } else {
                                if(ProblemType=="sne"){
                                  set_show_modal({show:true,text:sne_max_solutions_to_return_error})
                                } else if(ProblemType=="optimization"){
                                  set_show_modal({show:true,text:opt_max_solutions_to_return_error})
                                }
                              }
                                             

                          } else {
                              if(ProblemType=="sne"){
                                set_show_modal({show:true,text:sne_max_runtime_error})
                              } else if(ProblemType=="optimization"){
                                set_show_modal({show:true,text:opt_max_runtime_error})
                              }
                          }
                    } else {
                      if(ProblemType=="sne"){
                        set_show_modal({show:true,text:sne_max_obj_func_val_error})
                      } else if(ProblemType=="optimization"){
                        set_show_modal({show:true,text:opt_max_obj_func_val_error})
                      }
                    }
                  } else {
                    set_show_modal({show:true,text:minimum_distance_error_text })
                  }
                } else {
                  set_show_modal({show:true,text:n_starting_points_error_text })
                }

              } else {
                set_show_modal({show:true,text:initial_search_region_bounds_error_text})
              }
            } else {
              set_show_modal({show:true,text:constraints_error_text})
              // 
              // alert('One or more constraints are not formatted in a way that we can interpret. Please ensure all constraints are formatted in accordance with the instructions in the "Constraints" section.')
            }
          } else {
            set_show_upgrade_account_button(true)
            set_show_modal({show:true,text:free_tier_error_text })

          }
        } else {
          await set_show_upgrade_account_button(true)
          await set_hide_close_modal_button(true)
          set_show_modal({show:true,text:"Please upgrade your account to continue." })
        }

          // } else {
          //   await setisProcessing(true)
          //   snesolve()
          // }
      // } else {
      //   alert('One or more constraints are not formatted in a way that we can interpret. Please ensure all constraints are formatted in accordance with the instructions in the "Constraints" section.')
      // }
  }

// const optimizationsolve = async()=>{
//     // var time =  await new Date().getTime() / 1000
//     var obj_func = objectiveFunction
  
//     try{
//       // var paramss = JSON.stringify({"Lims":Lims,"nPoints":nPoints})
//       // var paramss = ({"Lims":Lims,"nPoints":nPoints}).toString()
//       // var obj = objectiveFunction.replaceAll("+","%2b").replaceAll(" ","%20")
//       // var data = JSON.stringify({objective: objectiveFunction, Lims:Lims, nPoints:nPoints, minDistance:minDistance})
//       if(CustomLims){
//         var limsParsed= JSON.parse(Lims)
//         // console.log("if")
//       } else {
//         var limsParsed = Lims
//         // console.log("else")
//       }
      
//       // console.log(limsParsed)
//       var payload  = {objective: obj_func, Lims:limsParsed, MaxObjectiveFunctionValue:MaxObjectiveFunctionValue, nPoints:nPoints, minDistance:minDistance}
//       // console.log(payload)
//       // var data = new FormData();
//       // await data.append( "json", JSON.stringify( payload ) );
//       // var session = await Auth.currentSession()
//       // var IdToken = await session.getIdToken()
//       // console.log(IdToken)
//       // console.log(session.accessToken.jwtToken)
//   await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload)})
//         // console.log('result =  ', result)
//         // resultjson = JSON.parse(result)  
//         // bodyy = resultjson.body
//         // console.log(bodyy)
//         // console.log(bodyy.solutions)
//         // setResponse(JSON.stringify(result))
//       // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload)})
//       // var finalresult = await JSON.stringify(result)
//       // setResponse(finalresult)
//       // setResponse(result)
//       // const rawResponse = await fetch(lambdaUrl, {
//       //   method: 'POST',
//       //   headers: {
//       //     'Accept': 'application/json',
//       //     'Content-Type': 'application/json'
//       //   },
//       //   body: JSON.stringify({a: 1, b: 'Textual content'})
//       // })
      
      
//       // await Axios.post(lambdaUrl, {objective: objectiveFunction, Lims:Lims, nPoints:nPoints, minDistance:minDistance}) 
//       // await Axios({
//       //   method: 'GET',
//       //   // url: lambdaUrl+"?"+
//       //   url: lambdaUrl,
//       //   data: data, 
//       //     headers: {
//       //       // Overwrite Axios's automatically set Content-Type
//       //       'Content-Type': 'application/json'
//       //     }
        
//       //   // data: {objective: objectiveFunction, Lims:Lims, nPoints:nPoints, minDistance:minDistance}
//       //   // "objective="+obj+
//       //   // "&paramss="+paramss+
//       //   // // {%22Lims%22:{%20%22x%22:[-0.5,3],%20%22y%22:[-0.5,3],%20%22z%22:[-0.5,3]%20},%22tol%22:0.0000001,%22md%22:0.01,%22nPoints%22:500}&minDistance=0.01"
//       //   // "&minDistance="+mindistance
//       //   // "sessionid="+this.state.session.id+
//       //   // "&pagename="+this.state.pagename+
//       //   // "&pageid="+this.state.page.id+
//       //   // "&pagebio="+this.state.pagebio+
//       //   // "&profilepic="+this.state.connections.publicbucketurl+this.state.profilepic+
//       //   // "&profileheaderpic="+this.state.connections.publicbucketurl+this.state.profileheaderpic+
//       //   // "&edgeid="+edgeid+
//       //   // "&starttime="+time
//       // })
//       // .then(response => setResponse(response.text()))
//       .then(async(result) => {
//         var resultjson = await result.json()
//         // var newtime =  await new Date().getTime() / 1000
//         // var timedif = newtime-time
//         // setSolverRuntime(newtime-time)
//           // console.log('result =  ', resultjson)
//           // var resultjson = result.json()
//           // console.log(resultjson.objective)
//           // console.log(resultjson)
//           // console.log(addjds)
//           await setResponse(resultjson)
//         //   setResponse(resultjson.map(function(e) {
//         //     return Object.keys(e).reduce(function(p,n){
//         //        if(n.startsWith("solution"))
//         //            p[n] = Math.round(e[n]*100)/100;
//         //        else
//         //            p[n] = e[n];
//         //        return p;
//         //     },{})
//         // });)
//           await setSolutionsReturned(true)

//           setisProcessing(false)

//           // setResponse(JSON.stringify(resultjson))
//           // var b = JSON.stringify(resultjson)
//           // console.log(b[0])
//           // console.log(b[0].objective)
//           // // b
//           // setDATA([
//           //   {
//           //     id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
//           //     // title: b.solutions[0].solution,
//           //     title: b.objective,
//           //   },
//           //   {
//           //     id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
//           //     title: 'Fifth Item',
//           //   },
//           //   {
//           //     id: '58694a0f-3da1-471f-bd96-145571e29d72',
//           //     title: 'Sixth Item',
//           //   },
//           // ])
//           // var a = await JSON.stringify(result)
//           // setResponse(a)



//       //     // setResponse("ya digg")
//       //     // console.log('result.data =  ', result.data)
          
//       //     //console.log(result.data[0].page)
//       //     //return
//       //     //result.data[0].page.ownpage=true
//       //     // if(result.data=='A page with that name exists already'){
//       //     //   alert ('A page with that name exists already')
//       //     //   await this.setState({
//       //     //     pagenameselected: false,
//       //     //     refreshing: false,
//       //     //     pagename:'',
//       //     //     processing:false,
//       //     //     isFetching:false
//       //     //    })
//       //     //    this.state.failed=true
//       //     //    return
//       //     // }
          
//       //     // this.props.navigation.navigate('MainFeed', {screen: 'PageClick', params:{page:{
//       //     //   profileheaderpic:this.state.nextpageprofileheaderpic,
//       //     //   profilepic:this.state.nextpageprofilepic,
//       //     //   name:this.state.pagename,
//       //     //   nameid:this.state.pagename.toLowerCase(),
//       //     //   bio:this.state.pagebio,
//       //     //   id:this.state.page.id,
//       //     //   ownpage:true,
//       //     //   newpage:true,
//       //     //   posts:[],
//       //     //   followers:0
        
//       //     // }}})
    
//       //     // await pagestore.default.dispatch({ 
//       //     //     type: "update",
//       //     //     payload: {
//       //     //         page : {properties:result.data[0].page, ownpage:true}
//       //     //     } 
//       //     // })
//       //     //this.state.processing=false
//       })
//     } catch (err){
//       console.log(err)
//       setResponse(JSON.stringify(err))
//       setisProcessing(false)
//       // setResponse(err.text())
//       // setResponse("ya digg")
//     }
// }






















const newsolve = async()=>{

  // var time =  await new Date().getTime() / 1000
  if(ProblemType == "optimization" ){
    var obj_func = objectiveFunction

    if(MaxObjectiveFunctionValue.value == ""){

      var max_acceptable_objective_function_value = "NA"
    } else {
      var max_acceptable_objective_function_value = Number(MaxObjectiveFunctionValue.value)
    }
    
    
  } else {
    var obj_func = ""
    var max_acceptable_objective_function_value = Number(MaxObjectiveFunctionValue.value)
  }


  try{
    // var paramss = JSON.stringify({"Lims":Lims,"nPoints":nPoints})
    // var paramss = ({"Lims":Lims,"nPoints":nPoints}).toString()
    // var obj = objectiveFunction.replaceAll("+","%2b").replaceAll(" ","%20")
    // var data = JSON.stringify({objective: objectiveFunction, Lims:Lims, nPoints:nPoints, minDistance:minDistance})
    // if(CustomLims.value){
      
    //   var limsParsed= JSON.parse(Lims.value)
      
    //   // console.log("if")
    // } else {
    //   var limsParsed = Lims.value
    //   // console.log("else")
    // }
    // var limsParsed = initialSearchRegionv2.region





    // var aaa = ((Object.keys(initialSearchRegionv2.region).map(item=>{return '"'+item+'":['+initialSearchRegionv2.region[item].limits.lower.value+","+initialSearchRegionv2.region[item].limits.upper.value+'],'})).join("")).slice(0,-1)
    // var limsParsed = JSON.parse('{'+aaa+'}')
    
    
    var limsParsed = {}
    await (Object.keys(initialSearchRegionv2.region).map(item=>{limsParsed[item]=[Number(initialSearchRegionv2.region[item].limits.lower.value),Number(initialSearchRegionv2.region[item].limits.upper.value)]}))

    

    // console.log("limsParsed")
    // console.log(limsParsed)
    // if (textInputValuess.length == 0){
      if (textInputValuess.values.length == 0){
      // settextInputValues=["0<1"]
      // settextInputValues()
      // var constraintss = ["0<1"]
      var constraintss = []
    } else {
      // var constraintss = textInputValuess
      var constraintss = textInputValuess.values
    }
    



    // var jwtToken = ""
    // console.log(jwtToken)
    // console.log(session.accessToken.jwtToken)
    // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{authentication:IdToken.jwtToken}})
    //Authorization
//     var lambdaUrl = "https://ek6fv78l4d.execute-api.us-west-2.amazonaws.com/default/-"
//     var api_key = "XlwYKdiyaSxfV0wPreH2qlXZNAqvN0KywFVLsh3b0FrADMg2AXWG1uvWrVn820bk7BY2yABc8ociZAwBNoXlOZkRx"
// await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{"x-api-key":api_key}})
    // var payload = {IdToken:IdToken}


    // console.log(limsParsed)




    // console.log(payload)
    // var data = new FormData();
    // await data.append( "json", JSON.stringify( payload ) );


    // var jwtToken = ""
    // console.log(jwtToken)
    // console.log(session.accessToken.jwtToken)
    // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{authentication:IdToken.jwtToken}})
    //Authorization
//     var lambdaUrl = "https://ek6fv78l4d.execute-api.us-west-2.amazonaws.com/default/-"
//     var api_key = "7d093ec5-cf66-465a-974f-cab3aa49c620_xYJIcL3Qcv20sZEYygIdpRRmiDHaX1gBCSzwairTixiWKPII9kDd0HN6d0FDL8a58D7wOzlZInM6WilrVR3Lg"
// await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{"x-api-key":api_key}})

// const lambdaclient = new LambdaClient({ region: "us-west-2",credentials: essential_credentials});

// const paramsss = {
//   /** input parameters */
//   // FunctionName: "sne-local-search-sneLocalSearchFunction-pHDEKdlXdwwk",
//   FunctionName: "js-handler-jsHandlerFunction-kTJvFKOKpTZJ",
//   InvocationType: "RequestResponse",
//   // region: "us-west-2",
//   // credentials: {sessionToken: jwtToken}
//   // credentials: essential_credentials
// };










// // BELOW METHOD IS FOR INVOKING THE FUNCTION VIA COGNITO SESSION TOKEN. YOU NEED TO USE THE NON-api.toxeus.org/- URL
// var session = await Auth.currentSession()
// var IdToken = await session.getIdToken()
// var accesstoken = session.getAccessToken()
// var jwtToken = await IdToken.getJwtToken()
// OLD payload SHOWN BELOW WHICH WAS USED WITHIN FETCH
// var payload  = {
//   AccessToken:accesstoken,
//   variables: Array.from(dec_var_list_set),
//   objective: obj_func.replace(/\s/g, ''), 
//   constraints: constraintss, 
//   nPoints:Number(nPoints.value), 
//   Lims:limsParsed, 
//   minDistance:Number(minDistance.value),
//   MaxObjectiveFunctionValue:Number(MaxObjectiveFunctionValue.value), 
//   problemType:ProblemType,
//   invocationType:invocationType,
//   saveSolutions:saveSolutions
// }
// OLD palyload SHOWN ABOVE WHICH WAS USED WITHIN FETCH
// await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{Authorization:jwtToken}})

// BELOW METHOD IS FOR INVOKING THE URL VIA API KEY. YOU NEED TO USE THE DOMAIN api.toxeus.org/- FOR API KEY INVOCATION
// await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{"x-api-key":apiKey}})












var payload  = {
  // body:{
    metadata:{
      tags:[...Array.from(problem_tags_and_description.tags)],
      description:problem_tags_and_description.description
    },
    start_time: (new Date().getTime() / 1000).toFixed(0),
    variables: Array.from(dec_var_list_set.variables),
    objective: obj_func.replace(/\s/g, ''), 
    constraints: constraintss, 
    nPoints:Number(nPoints.value), 
    Lims:limsParsed, 
    minDistance:Number(minDistance.value),
    MaxObjectiveFunctionValue:max_acceptable_objective_function_value,
    maximum_solutions_to_return:Number(max_solutions_to_return.value),
    maximum_runtime_in_seconds:Number(max_runtime.value),
    problemType:ProblemType,
    invocationType:invocationType,
    saveSolutions:saveSolutions
  // }

}
var session = await Auth.currentSession()
var IdToken = await session.getIdToken()
// var accesstoken = session.getAccessToken()
var jwtToken = await IdToken.getJwtToken()
// OLD palyload SHOWN ABOVE WHICH WAS USED WITHIN FETCH
await fetch(session_token_lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{Authorization:jwtToken}})

  // console.log("apiKey")
  // console.log(apiKey)
    // BELOW METHOD IS FOR INVOKING THE URL VIA API KEY. YOU NEED TO USE THE DOMAIN api.toxeus.org/- FOR API KEY INVOCATION
    // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{"x-api-key":apiKey}})

    // CODE BELOW WAS USED IN PLACE OF THE await fetch(.... ) 
    // ABOVE

    // var lambdaclient =await  new LambdaClient({ 
    //   region: "us-west-2",
    //   credentials: essential_credentials
    // });
    // var paramss = {//...paramsss, 
    //   FunctionName: "js-handler-jsHandlerFunction-kTJvFKOKpTZJ",
    //   InvocationType: "RequestResponse",
    //   // LogType:'Tail',
    //   Payload:await JSON.stringify(payload)
    // }
    // lambdaclient.send( new InvokeCommand(paramss))
    //     .then(async(results)=>{
    //             results = await JSON.parse(new TextDecoder("utf-8").decode(results.Payload))
    //             var resultjson = JSON.parse(results.body)

    // END OF CODE FOR INVOCATION VIA aws-sdk



    // COMMENT OUT BELOW TWO COMMANDS AND UNCOMMENT
    // CODE IN ABOVE SECTION FOR lambdaclient INVOCATION
    //
    // THIS CODE IS USED TO PROCESS THE FETCH RESPONSE
    .then(async(result) => {
      var resultjson = await result.json()





      // var newtime =  await new Date().getTime() / 1000
      // var timedif = newtime-time
      // setSolverRuntime(newtime-time)
        // console.log('result =  ', resultjson)
        // var resultjson = result.json()
        // console.log(resultjson.objective)
        // console.log(resultjson)
        // console.log(addjds)
        // await setResponse(resultjson)
        if(typeof resultjson == "string" ){
          var error_text = ((resultjson.split("Error: "))[1])
          if(error_text.includes("Please upgrade your account to continue.")){
            await set_show_upgrade_account_button(true)
            if(error_text==="Please upgrade your account to continue."){
              await set_hide_close_modal_button(true)
              await store.dispatch({ 
                type: "update",
                payload: {
                  input:{
                    ...store.getState().store,
                    no_subscriptions:true
                  }
                }
              })
              no_subscriptions=true
            }
            set_show_modal({show:true,text:error_text})
            await store.dispatch({ 
              type: "update",
              payload: {
                input:{
                  ...store.getState().store
                }
              }
            })
          } else {
            set_show_modal({show:true,text:error_text})
          }
          
          setisProcessing(false)
        } else {
          response.value = resultjson
          // console.log("response.value")
          // console.log(response.value)
          // console.log(typeof response.value)
          setResponse(response)
          if(response.value.solutions.length==0){
            // console.log("NO SOLUTIONS")
            download_solutions_button_disabled.value = true
            download_solutions_button_background_color.color = theme_gray_color
          } else {
            download_solutions_button_disabled.value = false
            download_solutions_button_background_color.color = theme_blue_color
          }
          await set_download_solutions_button_disabled(download_solutions_button_disabled)
          await set_download_solutions_button_background_color(download_solutions_button_background_color)
          SolutionsReturned.value=true
          setSolutionsReturned(SolutionsReturned)
          setisProcessing(false)
        }


    // BELOW }) WAS NEEDED WHEN PROCESSING FETCH RESPONSE
    })
  } catch (err){
    console.log(err)
    // set_show_modal({value:true,text:"Error: \n\n"+JSON.stringify(err)})
    set_show_modal({show:true,text:"Error: \n\n"+JSON.stringify(err) })
    setResponse({value:{}})
    setSolutionsReturned({value:false})
    // setResponse(JSON.stringify(err))
    // response.value = JSON.stringify(err)
    setisProcessing(false)
    // setResponse(err.text())
    // setResponse("ya digg")
  }
}

// BELOW ROUND BRACKET WAS ADDED WHEN SWITCHING FROM fetch TO invoke
  























// const snesolve = async()=>{
    
//   // var time =  await new Date().getTime() / 1000
//       var objectiveFunctionConstructor = ""
//       // for(var i = 0; i <  textInputValues.length; i++) {
//       //   var splitt = textInputValues[i].replace(/\s+/g, '').split("=")
//       //   if( splitt.length>2 | splitt[splitt.length-1]!="0") {
//       //     alert("Equation "+String(i+1)+' is not structured properly. Please structure each equation in a form such as "a^2-b^2-1=0"')
//       //     setisProcessing(false)
//       //     return 
//       //   }
  
//       //   if (objectiveFunctionConstructor.length == 0){
//       //     objectiveFunctionConstructor = "abs(" + splitt[0] + ")"
//       //   } else {
//       //     objectiveFunctionConstructor = objectiveFunctionConstructor + "+ abs(" + splitt[0] + ")"
//       //   }
//       // }
//       var obj_func = objectiveFunctionConstructor
//       var max_obj_func_val = sneMaxObjectiveFunctionValue
//       // var max_obj_func_val = MaxObjectiveFunctionValue.value
//       // var min_obj_func_val = sneMinObjectiveFunctionValue.value
//       var snnPoints = snenPoints
//       var snminDistance = sneminDistance
  
//   try{
//     // var paramss = JSON.stringify({"Lims":Lims,"nPoints":nPoints})
//     // var paramss = ({"Lims":Lims,"nPoints":nPoints}).toString()
//     // var obj = objectiveFunction.replaceAll("+","%2b").replaceAll(" ","%20")
//     // var data = JSON.stringify({objective: objectiveFunction, Lims:Lims, nPoints:nPoints, minDistance:minDistance})
//     if(sneCustomLims){
//       var limsParsed= JSON.parse(sneLims)
//       // console.log("if")
//     } else {
//       var limsParsed = sneLims
//       // console.log("else")
//     }




//     if (textInputValues.length == 0){
//       // settextInputValues=["0<1"]
//       // settextInputValues()
//       var constraintss = ["0<1"]
//     } else {
//       var constraintss = textInputValues
//     }





//     // var constraintss = ["0<1"]
//     // console.log(limsParsed)
//     var session = await Auth.currentSession()
//     console.log("session")
//     console.log(session)
//     var IdToken = await session.getIdToken()
//     console.log("IdToken")
//     console.log(IdToken)

//     var accesstoken = session.getAccessToken()
//     console.log("accesstoken")
//     console.log(accesstoken)
//     // console.log(IdToken)
//     var jwtToken = await IdToken.getJwtToken()

//     var payload  = {
//       AccessToken:accesstoken,
//       objective: obj_func, 
//       Lims:limsParsed, 
//       MaxObjectiveFunctionValue:max_obj_func_val, 
//       nPoints:snnPoints, 
//       minDistance:snminDistance,
//       constraints: constraintss,
//       problemType:ProblemType,
//       invocationType:invocationType,
//       saveSolutions:saveSolutions
//     }
//     console.log(payload)
  
//     // console.log(payload)
//     // var data = new FormData();
//     // await data.append( "json", JSON.stringify( payload ) );
//     // var payload  = {objective: obj_func, Lims:limsParsed, MaxObjectiveFunctionValue:MaxObjectiveFunctionValue, nPoints:nPoints, minDistance:minDistance}
//     // console.log(payload)
//     // var data = new FormData();
//     // await data.append( "json", JSON.stringify( payload ) );


//     // var jwtToken = ""
//     // console.log(jwtToken)
//     // console.log(session.accessToken.jwtToken)
//     // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{authentication:IdToken.jwtToken}})
//     //Authorization
// await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload),headers:{Authorization:jwtToken}})
//     // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload)})
//       .then(async(result) => {
//         var resultjson = await result.json()
//         // var newtime =  await new Date().getTime() / 1000
//         // var timedif = newtime-time
//         // setSolverRuntime(newtime-time)
//         await setsneResponse(resultjson)
//         await setsneSolutionsReturned(true)
//         setisProcessing(false)
//     })
//   } catch (err){
//     console.log(err)
//     setsneResponse(JSON.stringify(err))
//     setisProcessing(false)
//     // setResponse(err.text())
//     // setResponse("ya digg")
//   }
// }














  // var addValues = async (text, index) => {
  //   // let dataArray = {...inputData};
  //   let dataArray = inputData;
  //   console.log("JSON.stringify(dataArray)")
  //   console.log(JSON.stringify(dataArray))
  //   let checkBool = false;
  //   if (dataArray.length !== 0){
  //     await dataArray.forEach(element => {
  //       if (element.index === index ){
  //         element.text = text;
  //         checkBool = true;
  //       }
  //     });
  //   }
  //   if (checkBool){
  //     console.log("if (checkBool)")
  //     console.log(JSON.stringify(dataArray))
  //     await setTextInputText(dataArray)
  //     // var a = dataArray.toString()
  //     var a = JSON.stringify(dataArray)
  //     setids(a)
  //   }
  //   else {
  //     await dataArray.push({'text':text,'index':index});
  //     await setTextInputText(dataArray)
  //     // var a = dataArray.toString()
  //     var a = JSON.stringify(dataArray)
  //     setids(a)
  //   }
  // }


  // var addTextInput = async (key) => {
  //   let textInputt = textInput;
  //   // nequations = nequations + 1
  //   var equation_number = textInput.length+1
  //   // console.log(key)
  //   console.log(JSON.stringify(inputData))
  //   if (typeof inputData[key] == "undefined") {
  //     console.log('inputData[key] == "undefined"')
  //      await addValues("", key)
  //  } else {
  //     console.log(inputData[key].text.value)
  //  }
    
  //   textInputt.push(
  //   // textInputt.push(
  //     <View style={{
  //       flexDirection:'row',
  //       justifyContent:'center',
  //       alignContent:'center',
  //       textAlign:'center',
  //       alignItems:'center',
  //       width:'80%',
  //       marginTop:20,
  //     }}
  //     key={key}>
  //       <Text style={{fontSize:20}}> {"Equation "+(textInput.length+1)+": "} </Text>
  //       <TextInput 
  //           style={styles.textinput} 
  //           // key={key} 
  //           // value={inputData.slice(key).text}
  //           // defaultValue={inputData[textInput.length].text}
  //           value={inputData[key].text}
  //           onChangeText={async(text) => {
  //             // console.log(text)
  //             // console.log(inputData.slice(key).values())
  //             // console.log("key")
  //             // console.log(key)
  //             // console.log("inputData[key].text")
  //             // console.log(inputData[key].text)
  //             // inputData[key].text = text
  //             // setTextInputText(inputData)
  //             // var textt = text
  //             var dataArray = inputData
  //             console.log(dataArray[key].text)
  //             dataArray[key].text = text
  //             console.log(dataArray[key].text)
  //             await setTextInputText(dataArray)
  //             console.log(inputData[key].text)
  //             await setShow(false)
  //             setShow(true)
  //             // console.log()
  //             // dataArray.forEach(element => {
  //             //     if (element.index === key ){
  //             //       element.text = text;
  //             //     }
  //             // })
  //             // await setTextInputText(dataArray)
  //             // console.log(inputData) 
  //             // addValues(textt, key)
  //             // console.log(inputData[key].text)
  //           }} 
  //           // onChangeText={(text)=>{
  //           //   textInputText[key] = text
  //           //   console.log(textInputText.toString())
  //           // }}  
  //       />
  //     </View>
  //   );
  //   setTextInput( textInputt )
  //   setShow(true)
  //   // console.log(textInput.length)
  // }
  
  return (
    // <Provider store={store}>
    <View style={{    
          top:'0%',
          // flex:1,
          width:'100%',
          justifyContent:'center', 
          alignItems:'center', 
          // padding: 25, 
          // backgroundColor: '#fff', 
          // backgroundColor:'#F2F2F2'
          // backgroundColor:'rgba(0,0,0,0.5)',
      }}>
      {/* START OF HEADER SECTION */}
          <View style= {{
            width: '100%',
            // width:100,
            // borderWidth: 2.5,
            // borderColor: 'white',
            // position: 'absolute',
            top: '0%',
            left: '0%',
         
            // zIndex: 999,
            // backgroundColor: 'rgba(20, 20, 20, 0)',
            // backgroundColor:'black',
            backgroundColor:theme_blue_color,
            height:100,
            // height:'%',
            // justifyContent:'center',
            flexDirection:'row',
            justifyContent:'space-around',
            // justifyContent:'flex-start'
            // justifyContent:'space-evenly'
            // alignItems: 'center',
            
          }}>
              {/* <View style={{ 
                // flex:1,
                // flexDirection: 'row', 
                // justifyContent: 'space-between' ,
                justifyContent:'center',
                // top:'30%',
                zIndex:-1,
                left:'0%',
                right:'0%',
                height:'100%',
                position:'absolute',
                // marginVertical:'3%'
                // width:'100%'
              }}> 
                          <Image style={{
                            right:'36%',
                            // top:'25%',
                            resizeMode: "contain",
                            // maxHeight: "100%",
                            // height:80,
                            height:'75%',
                            // flexShrink: 2,
                            // flexGrow: 0,
                          }} source={logo} />
              </View> */}
              <View style={{ 
                // flex:1,
                // flexDirection: 'row', 
                // justifyContent: 'space-between' ,
                justifyContent:'center',
                // top:'30%',
                // zIndex:-1,
                // left:'0%',
                // right:'0%',
                height:'100%',
                
                width:250,
                // backgroundColor:'red'
                // position:'absolute',
                // marginVertical:'3%'
                // width:'100%'
              }}> 

                          <Image style={{
                            // right:'36%',
                            // top:'25%',
                            resizeMode: "contain",
                            // maxHeight: "100%",
                            // height:80,
                            // height:100
                            height:'75%',
                            // flexShrink: 2,
                            // flexGrow: 0,
                          }} source={logo} />
              </View>
              {/* <Picker
                  selectedValue={ProblemType}
                  onValueChange={(itemValue, itemIndex) =>
                    setProblemType(itemValue)
                  }>
                  <Picker.Item label="Global Optimization Solver" value="optimization" />
                  <Picker.Item label="system of nonlinear equations and inequalities Solver" value="sne" />
                  
              </Picker> */}
<View></View>

<View></View>





              {/* <TouchableOpacity style={{ 
                // flex:1,
                // flexDirection: 'row', 
                // justifyContent: 'space-between' ,
                top:'0%',
                // right:'30%',
                // right:'0%',
                height:'100%',
                // position:'absolute',
                justifyContent:'center',
                alignItems:'center',
                textAlign:'center',
                
                // marginVertical:'3%'
                // width:'100%'
              }} 
              onPress = {async()=>{
                if(ProblemType != "optimization"){
                  // console.log("opt first decisionVariableString setting")
                  // console.log(decisionVariableString.value)
                  setProblemType("optimization")
                  settextInputValuess(opt_textInputValuess)
                  set_constraints_section_description(opt_constraints_section_description)
                  set_constraints_section_title(opt_constraints_section_title)
                  setDecisionVariableString(opt_decision_variable_string)
                  setshowDecisionVariables(opt_showDecisionVariables)
                  set_dec_var_list_set(opt_dec_var_list_set)
                  set_show_decision_variables_button_color(opt_show_decision_variables_button_color)
                  set_show_decision_variables_button_name(opt_show_decision_variables_button_name)
                  set_show_constraints(opt_show_constraints)
                  set_show_constraints_button_name(opt_show_constraints_button_name)
                  set_show_constraints_button_color(opt_show_constraints_button_color)
                  set_show_starting_points(opt_show_starting_points)
                  set_show_starting_points_button_name(opt_show_starting_points_button_name)
                  set_show_starting_points_button_color(opt_show_starting_points_button_color)
                  set_show_minimum_distance(opt_show_minimum_distance)
                  set_show_minimum_distance_button_name(opt_show_minimum_distance_button_name)
                  set_show_minimum_distance_button_color(opt_show_minimum_distance_button_color)
                  setminDistance(opt_minDistance)
                  set_max_solutions_to_return(opt_max_solutions_to_return)
                  set_max_runtime(opt_max_runtime)
                  setIsInitialSearchRegionValid(opt_isInitialSearchRegionValid)
                  set_show_initial_search_region(opt_show_initial_search_region)
                  set_show_initial_search_region_button_text_color(opt_show_initial_search_region_button_text_color)
                  set_initial_search_region_button_name(opt_initial_search_region_button_name)
                  setnPoints(opt_nPoints)
                  setInitialSearchRegionv2(opt_initialSearchRegionv2)
                  setInputsValid(opt_inputsValid)
                  set_n_starting_points_section_description(opt_n_starting_points_section_description)
                  setMaxObjectiveFunctionValue(opt_MaxObjectiveFunctionValue)
                  set_show_max_obj_func_val_button_color(opt_show_max_obj_func_val_button_color)
                  set_show_max_obj_func_val_button_name(opt_show_max_obj_func_val_button_name)
                  set_show_max_obj_func_val(opt_show_max_obj_func_val)
                  set_max_obj_func_val_section_description(opt_max_obj_func_val_section_description)
                  set_max_obj_func_val_section_title(opt_max_obj_func_val_section_title)
                  set_initial_search_region_section_description(opt_initial_search_region_section_description)
                  setLims(opt_Lims)
                  setCustomLims(opt_CustomLims)
                  setSolutionsReturned(opt_SolutionsReturned)
                  setResponse(opt_response)
                  set_minimum_distance_section_description(opt_minimum_distance_section_description)
                  set_edit_constraints(opt_edit_constraints)
                  set_edit_constraints_button_name(opt_edit_constraints_button_name)
                  set_defaultSearchRegion(opt_defaultSearchRegion)
                  set_problem_tags_and_description_string(opt_problem_tags_and_description_string)
                  set_show_problem_tags_and_description_button_name(opt_show_problem_tags_and_description_button_name)
                  set_show_problem_tags_and_description_button_color(opt_show_problem_tags_and_description_button_color)
                  set_show_problem_tags_and_description(opt_show_problem_tags_and_description)
                  set_problem_tags_and_description(opt_problem_tags_and_description)
                  set_min_distance_warning(opt_min_distance_warning)
                  set_max_obj_func_val_warning(opt_max_obj_func_val_warning)
                  set_nPoints_warning(opt_nPoints_warning)
                  set_recommended_problem_tags_and_description(new Set([]))
                  set_download_solutions_button_disabled(opt_download_solutions_button_disabled)
                  set_download_solutions_button_background_color(opt_download_solutions_button_background_color)

                 
                 
                 
                  set_max_solutions_to_return_section_description(opt_max_solutions_to_return_section_description)
                  set_max_solutions_to_return_section_title(opt_max_solutions_to_return_section_title)
                  set_max_solutions_to_return(opt_max_solutions_to_return)
                  set_max_solutions_to_return_warning(opt_max_solutions_to_return_warning)
                  set_show_max_solutions_to_return(opt_show_max_solutions_to_return)
                  set_show_max_solutions_to_return_button_name(opt_show_max_solutions_to_return_button_name)
                  set_show_max_solutions_to_return_button_color(opt_show_max_solutions_to_return_button_color)
                  set_max_solutions_to_return_warning(opt_max_solutions_to_return_warning)



                  set_max_runtime(opt_max_runtime)
                  // set_max_runtime_section_description(opt_max_runtime_section_description)
                  // set_max_runtime_section_title(opt_max_runtime_section_title)
                  set_max_runtime_warning(opt_max_runtime_warning)
                  set_show_max_runtime(opt_show_max_runtime)
                  set_show_max_runtime_button_name(opt_show_max_runtime_button_name)
                  set_show_max_runtime_button_color(opt_show_max_runtime_button_color)
                  set_max_runtime_warning(opt_max_runtime_warning)



                  set_ram(opt_ram)
                  // set_ram_section_description(opt_ram_section_description)
                  // set_ram_section_title(opt_ram_section_title)
                  set_ram_warning(opt_ram_warning)
                  set_show_ram(opt_show_ram)
                  set_show_ram_button_name(opt_show_ram_button_name)
                  set_show_ram_button_color(opt_show_ram_button_color)
                  set_ram_warning(opt_ram_warning)



                  // console.log("opt first decision_variable_string setting")
                  // console.log(decision_variable_string)
                  // setDecisionVariableString(opt_decision_variable_string)
                  // decision_variable_string = opt_decision_variable_string
                  // console.log("opt second decision_variable_string setting")
                  // console.log(decision_variable_string)

                  // setDecisionVariableString(opt_decision_variable_string)
                  
                  // delay(1000000)
                  // decisionVariableString = opt_decision_variable_string
                  // console.log("opt second decisionVariableString setting")
                  // console.log(decisionVariableString.value)
                }

              }}>


                {ProblemType == "optimization" && 
                  <Text style={{color:'white',fontSize:18, borderBottomColor:'white',borderBottomWidth:1,textAlign:'right',fontWeight:'bold'}}>
                    {'Global Optimization Solver'}
                  </Text>
                }
                {ProblemType == "sne" && 
                  <Text style={{color:'#EFEFEF',fontSize:18,textAlign:'right'}}>
                    {'Global Optimization Solver'}
                  </Text>
                }
                {ProblemType == "account" && 
                  <Text style={{color:'#EFEFEF',fontSize:18, textAlign:'right' }}>
                    {'Global Optimization Solver'}
                  </Text>
                }
                     {ProblemType == "mysolutions" && 
                  <Text style={{color:'#EFEFEF',fontSize:18, textAlign:'right' }}>
                    {'Global Optimization Solver'}
                  </Text>
                }
                
                
              </TouchableOpacity>


              <TouchableOpacity style={{ 
                // flex:1,
                // flexDirection: 'row', 
                // justifyContent: 'space-between' ,
                top:'0%',
                // left:'10%',
                // alignItems:'stretch',
                
                // right:'0%',
                height:'100%',
                // position:'absolute',
                justifyContent:'center',
                // alignItems:'right',
                // textAlign:'right'
                // marginVertical:'3%'
                // width:'100%'
              }}
              onPress = {()=>{
                if(ProblemType != "sne"){
                  // console.log("sne first decision_variable_string setting")
                  // console.log(decisionVariableString.value)
                  // console.log(sne_decision_variable_string.value)
                  setProblemType("sne")
                  settextInputValuess(sne_textInputValuess)
                  set_constraints_section_description(sne_constraints_section_description)
                  set_constraints_section_title(sne_constraints_section_title)
                  setDecisionVariableString(sne_decision_variable_string)
                  setshowDecisionVariables(sne_showDecisionVariables)
                  set_dec_var_list_set(sne_dec_var_list_set)
                  set_show_decision_variables_button_color(sne_show_decision_variables_button_color)
                  set_show_decision_variables_button_name(sne_show_decision_variables_button_name)
                  set_show_constraints(sne_show_constraints)
                  set_show_constraints_button_name(sne_show_constraints_button_name)
                  set_show_constraints_button_color(sne_show_constraints_button_color)
                  set_show_starting_points(sne_show_starting_points)
                  set_show_starting_points_button_name(sne_show_starting_points_button_name)
                  set_show_starting_points_button_color(sne_show_starting_points_button_color)
                  set_show_minimum_distance(sne_show_minimum_distance)
                  set_show_minimum_distance_button_name(sne_show_minimum_distance_button_name)
                  set_show_minimum_distance_button_color(sne_show_minimum_distance_button_color)
                  setminDistance(sne_minDistance)
                  set_max_solutions_to_return(sne_max_solutions_to_return)
                  set_max_runtime(sne_max_runtime)
                  setIsInitialSearchRegionValid(sne_isInitialSearchRegionValid)
                  set_show_initial_search_region(sne_show_initial_search_region)
                  set_show_initial_search_region_button_text_color(sne_show_initial_search_region_button_text_color)
                  set_initial_search_region_button_name(sne_initial_search_region_button_name)
                  setnPoints(sne_nPoints)
                  setInitialSearchRegionv2(sne_initialSearchRegionv2)
                  setInputsValid(sne_inputsValid)
                  set_n_starting_points_section_description(sne_n_starting_points_section_description)
                  setMaxObjectiveFunctionValue(sne_MaxObjectiveFunctionValue)
                  set_show_max_obj_func_val_button_color(sne_show_max_obj_func_val_button_color)
                  set_show_max_obj_func_val_button_name(sne_show_max_obj_func_val_button_name)
                  set_show_max_obj_func_val(sne_show_max_obj_func_val)
                  set_max_obj_func_val_section_description(sne_max_obj_func_val_section_description)
                  set_max_obj_func_val_section_title(sne_max_obj_func_val_section_title)
                  set_initial_search_region_section_description(sne_initial_search_region_section_description)
                  setLims(sne_Lims)
                  setCustomLims(sne_CustomLims)
                  setSolutionsReturned(sne_SolutionsReturned)
                  setResponse(sne_response)
                  set_minimum_distance_section_description(sne_minimum_distance_section_description)
                  set_edit_constraints(sne_edit_constraints)
                  set_edit_constraints_button_name(sne_edit_constraints_button_name)
                  set_defaultSearchRegion(sne_defaultSearchRegion)
                  set_problem_tags_and_description_string(sne_problem_tags_and_description_string)
                  set_show_problem_tags_and_description_button_name(sne_show_problem_tags_and_description_button_name)
                  set_show_problem_tags_and_description_button_color(sne_show_problem_tags_and_description_button_color)
                  set_show_problem_tags_and_description(sne_show_problem_tags_and_description)
                  set_problem_tags_and_description(sne_problem_tags_and_description)
                  set_min_distance_warning(sne_min_distance_warning)
                  set_max_obj_func_val_warning(sne_max_obj_func_val_warning)
                  set_nPoints_warning(sne_nPoints_warning)
                  set_recommended_problem_tags_and_description(new Set([]))
                  set_download_solutions_button_disabled(sne_download_solutions_button_disabled)
                  set_download_solutions_button_background_color(sne_download_solutions_button_background_color)

                  
                  set_max_solutions_to_return_section_description(sne_max_solutions_to_return_section_description)
                  set_max_solutions_to_return_section_title(sne_max_solutions_to_return_section_title)
                  set_max_solutions_to_return(sne_max_solutions_to_return)
                  set_max_solutions_to_return_warning(sne_max_solutions_to_return_warning)
                  set_show_max_solutions_to_return(sne_show_max_solutions_to_return)
                  set_show_max_solutions_to_return_button_name(sne_show_max_solutions_to_return_button_name)
                  set_show_max_solutions_to_return_button_color(sne_show_max_solutions_to_return_button_color)
                  set_max_solutions_to_return_warning(sne_max_solutions_to_return_warning)



                  set_max_runtime(sne_max_runtime)
                  // set_max_runtime_section_description(sne_max_runtime_section_description)
                  // set_max_runtime_section_title(sne_max_runtime_section_title)
                  set_max_runtime_warning(sne_max_runtime_warning)
                  set_show_max_runtime(sne_show_max_runtime)
                  set_show_max_runtime_button_name(sne_show_max_runtime_button_name)
                  set_show_max_runtime_button_color(sne_show_max_runtime_button_color)
                  set_max_runtime_warning(sne_max_runtime_warning)


                  set_ram(sne_ram)
                  // set_ram_section_description(sne_ram_section_description)
                  // set_ram_section_title(sne_ram_section_title)
                  set_ram_warning(sne_ram_warning)
                  set_show_ram(sne_show_ram)
                  set_show_ram_button_name(sne_show_ram_button_name)
                  set_show_ram_button_color(sne_show_ram_button_color)
                  set_ram_warning(sne_ram_warning)




                  // console.log("sne first decision_variable_string setting")
                  // console.log(decision_variable_string)
                  // setDecisionVariableString(sne_decision_variable_string)
                  
                  // decision_variable_string = sne_decision_variable_string
                  // console.log("sne second decision_variable_string setting")
                  // console.log(decision_variable_string)

                  // setDecisionVariableString(sne_decision_variable_string)
                  // delay(1000000)
                  // decision_variable_string = sne_decision_variable_string
                  // console.log("sne second decision_variable_string setting")
                  // console.log(decisionVariableString.value)
                  // console.log(sne_decision_variable_string.value)
                }
              
              }}>
                {ProblemType == "sne" && 
                  <Text style={{color:'white',fontSize:18, borderBottomColor:'white',borderBottomWidth:1,textAlign:'right',fontWeight:'bold' }}>
                   
                    {'Nonlinear System Solver'}
                  </Text>
                }
                {ProblemType == "optimization" && 
                  <Text style={{color:'#EFEFEF',fontSize:18,textAlign:'right'}}>
                    {'Nonlinear System Solver'}
                  </Text>
                }
                {ProblemType == "account" && 
                  <Text style={{color:'#EFEFEF',fontSize:18, textAlign:'right' }}>
                    {'Nonlinear System Solver'}
                  </Text>
                }

                {ProblemType == "mysolutions" && 
                  <Text style={{color:'#EFEFEF',fontSize:18,textAlign:'right'}}>
                  {'Nonlinear System Solver'}
                  </Text>
                }

              </TouchableOpacity> */}

              <TouchableOpacity style={{ 
                // flex:1,
                // flexDirection: 'row', 
                // justifyContent: 'space-between' ,
                top:'0%',
                // left:'10%',
                // alignItems:'stretch',
                
                // right:'0%',
                height:'100%',
                // position:'absolute',
                justifyContent:'center',
                // alignItems:'right',
                // textAlign:'right'
                // marginVertical:'3%'
                // width:'100%'
              }}
              // onPress = {signOut}
              onPress={()=>{setProblemType("account")}}
              >
                {/* {ProblemType == "sne" && 
                  <Text style={{color:'white',fontSize:18,textAlign:'right' }}>
                    {'My Account'}
                  </Text>
                }
                {ProblemType == "optimization" && 
                  <Text style={{color:'#EFEFEF',fontSize:18,textAlign:'right'}}>
                    {'My Account'}
                  </Text>
                }
                {ProblemType == "account" && 
                  <Text style={{color:'#EFEFEF',fontSize:18, textAlign:'right' , borderBottomColor:'white',borderBottomWidth:1,textAlign:'right',fontWeight:'bold' }}>
                    {'My Account'}
                  </Text>
                }


              {ProblemType == "mysolutions" &&  */}
                  {/* <Text style={{color:'#EFEFEF',fontSize:18,textAlign:'right'}}> */}
                  <Text style={{color:'#EFEFEF',fontSize:18, textAlign:'right' , borderBottomColor:'white',borderBottomWidth:1,textAlign:'right',fontWeight:'bold' }}>
                  {'My Account'}
                  </Text>
                {/* } */}
               </TouchableOpacity>

               {/* <TouchableOpacity  
                        onPress={async()=>{
                          if(ProblemType != "mysolutions"){
                            await setShowSolutions(false)
                            setProblemType("mysolutions")
                            // const solutions_table = await query_dynamodb_table('solutions-table')
                            // await setSolutionsTable(solutions_table)
                            const unique_problems = await query_dynamodb_table('unique_objectives');
                            console.log("unique_problems")
                            console.log(unique_problems)
                            const unique_problems2 = await unique_problems.Items.map((item)=>{
                              item.showAllUniqueProblems=false
                              return item
                            })
                            console.log("unique_problems2")
                            console.log(unique_problems2)
                            await setSolutionsTable({Items:unique_problems2})
                            setShowSolutions(true)
                          }                           
                        }}
                       style={{ top:'0%', height:'100%', justifyContent:'center', alignItems:'center' }}>
              
              {ProblemType == "optimization" && 
                   <Text style={{color:'#EFEFEF',fontSize:18,textAlign:'right'}}>
                    {'My Solutions'}
                  </Text>
                }
                {ProblemType == "sne" && 
                  <Text style={{color:'#EFEFEF',fontSize:18,textAlign:'right'}}>
                    {'My Solutions'}
                  </Text>
                }
                {ProblemType == "account" && 
                  <Text style={{color:'#EFEFEF',fontSize:18, textAlign:'right' }}>
                    {'My Solutions'}
                  </Text>
                }
                    {ProblemType == "mysolutions" && 
                  <Text style={{color:'#EFEFEF',fontSize:18, textAlign:'right' , borderBottomColor:'white',borderBottomWidth:1,textAlign:'right',fontWeight:'bold' }}>
                    {'My Solutions'}
                  </Text>
                }
             
           
               
               </TouchableOpacity> */}
      
      {/* END OF HEADER SECTION */}

              </View>










              {/* <TouchableOpacity style={{ 
                // flex:1,
                // flexDirection: 'row', 
                // justifyContent: 'space-between' ,
                top:'0%',
                right:'30%',
                // right:'0%',
                height:'100%',
                position:'absolute',
                justifyContent:'center',
                alignItems:'center',
                textAlign:'center'
                // marginVertical:'3%'
                // width:'100%'
              }} 
              onPress = {async()=>{
                if(ProblemType != "optimization")
                setProblemType("optimization")
              }}>
                {ProblemType == "optimization" && 
                  <Text style={{color:'white',fontSize:18, borderBottomColor:'white',borderBottomWidth:1}}>
                    {'Global Optimization Solver'}
                  </Text>
                }
                {ProblemType == "sne" && 
                  <Text style={{color:'grey',fontSize:18}}>
                    {'Global Optimization Solver'}
                  </Text>
                }
              </TouchableOpacity>


              <TouchableOpacity style={{ 
                // flex:1,
                // flexDirection: 'row', 
                // justifyContent: 'space-between' ,
                top:'0%',
                right:'10%',
                // right:'0%',
                height:'100%',
                position:'absolute',
                justifyContent:'center',
                alignItems:'center',
                textAlign:'center'
                // marginVertical:'3%'
                // width:'100%'
              }}
              onPress = {async()=>{
                if(ProblemType != "sne")
                setProblemType("sne")
              }}>
                {ProblemType == "sne" && 
                  <Text style={{color:'white',fontSize:18, borderBottomColor:'white',borderBottomWidth:1}}>
                    {'system of nonlinear equations and inequalities Solver'}
                  </Text>
                }
                {ProblemType == "optimization" && 
                  <Text style={{color:'grey',fontSize:18}}>
                    {'system of nonlinear equations and inequalities Solver'}
                  </Text>
                }
              </TouchableOpacity> */}
          
      {/* <Latex style={{
      // width: '100%',
      width:100,
      height: 100,
      backgroundColor:'red',
      color:'red'
      
    }}>
      {"\\frac{1}{2\\pi}\\int_{-\\infty}^{\\infty}e^{-\\frac{x^2}{2}}dx"}
    </Latex> */}
        	{/* <MathView
	   math={'x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}'}
	/> 
	<MathView
	   math='\cos\left(x\right)=\frac{b}{c}'
	/> 
	<MathView
	   math='\cos\left(x=\frac{b}{c}'
	   renderError={({ error }) => <Text style={[{ fontWeight: 'bold' }]}>{error}</Text>}
	/> 
	<MathText
            value={`This text includes math notations and should be wrapped correctly for \\( \\alpha \\) and $\\beta$ within the view. \nThe following formula shouldn't be inline:$$x_{1,2} = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}$$However the following formula should be inline with the text: \\( a^2 + b^2 = c^2 \\)`}
            direction="ltr"
            CellRendererComponent={<TouchableOpacity />}
        /> */}







{/* 
      <TouchableOpacity>
          <Button
            title="upload your file"
            color="black"
            onPress={pickDocument}
          />
        </TouchableOpacity> */}
    





      {/* <Image style={{
    resizeMode: "contain",
    // maxHeight: "100%",
    height:200,
    flexShrink: 2,
    flexGrow: 0,
  }} source={source} /> */}
          {/* <Text >
        Objective Function
      </Text>
        <TextInput
        style={{height: 40,
          margin: 12,
          borderWidth: 1,
          padding: 10,
        fontSize:18}}
          onChangeText={(text)=> {
            // objectiveFunction = '$$'+text+'$$'
            keyy = keyy + 1
            var a = '$$'+text+'$$'
            setobjectiveFunction(a)
            // this.setState({pagename:text})
            // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
            //   this.setState({ create_disabled: true, create_opacity: 0.45})
            // } else {
            //   this.setState({ create_disabled: false, create_opacity: 1})
            // }
          }}
        // value={objectiveFunction}
        placeholder="4sen(α)cos^2(α/2)"
        // keyboardType="numeric"
      /> */}
      
{/* <MathJaxSvg 
  fontSize={8}
  // color="red"
  fontCache={true}
>
  
{objectiveFunction}
  
</MathJaxSvg> */}
    {show && 
    <View style={{
      top:'0%',
      // flex:1,
      width:'100%',
      justifyContent:'center', 
      alignItems:'center', 
      // padding: 25, 
      // backgroundColor: '#fff', 
      // backgroundColor:'#F2F2F2'
      // backgroundColor:'rgba(0,0,0,0.05)',
    }}>
    {/* ERROR ISNT HERE */}
    



  {/* START OF ACCOUNT PAGE */}

{ (ProblemType=="account") && show &&
<View style={{width:'100%',justifyContent:'center',alignItems:'center',textAlign:'center',marginTop:20}}> 
{/* HERE HERE HERE */}
{/* {(isProcessing) ? ( <View style={{  alignItems: 'center',paddingVertical:20}}> <ActivityIndicator size="large" color={theme_blue_color} /> </View>  ) : null } */}

<View style={{width:'50%',justifyContent:'center',alignContent:'center',textAlign:'center',top:'5%'}}>
                <Text style={styles.topheading}>
                        Account Management
                      </Text>
                <View style={{borderRadius:10, overflow:'hidden',marginVertical:20,marginBottom:50}}>
                  {free_tier ?
                      <View>
                        {!upgrade_account_running ?
                              <Button
                              title="Manage Account"
                              color={theme_blue_color}
                              onPress={async()=>{
                                // navigate_to_stripe_page()
                                // if(upgrade_account_running == false) {
                                  await set_show_activity_indicator_in_modal(true)
                                  await set_hide_close_modal_button(true)
                                  await set_show_modal({show:true,text:"Loading Stripe Checkout..."})
                                 
                                  await upgrade_account()

                                  

                                  await set_show_modal({show:false,text:""})
                                  await set_hide_close_modal_button(false)
                                  await set_show_activity_indicator_in_modal(false)
                                // }
                              }}
                          >                                        
                          </Button>
                          :
                          // <View style={{paddingVertical:20}}>
                          //   <ActivityIndicator size="large" color={theme_blue_color} />
                          // </View>
                          <Button
                          title="Manage Account"
                          color={"grey"}
                          onPress={async()=>{

                          }}>                                        
                          </Button>
                        }
                      </View>
                    :
                      <View>
                        {!upgrade_account_running ?
                          <Button
                              title="Manage Account"
                              color={theme_blue_color}
                              onPress={async()=>{
                                // navigate_to_stripe_page()
                                await set_show_activity_indicator_in_modal(true)
                                await set_show_modal({show:true,text:"Loading Stripe Checkout..."})
                                await set_hide_close_modal_button(true)
                                await upgrade_account()
                                await set_show_modal({show:false,text:""})
                                await set_hide_close_modal_button(false)
                              }}
                          >                                        
                          </Button>
                        :
                          //                           <View style={{paddingVertical:20}}>
                          //   <ActivityIndicator size="large" color={theme_blue_color} />
                          // </View>
                          <Button
                          title="Manage Account"
                          color={"grey"}
                          onPress={async()=>{

                          }}>                                        
                          </Button>
                        }
                      </View>
                  }
                </View>

                  {/* {!free_tier && 
                    <View>
                      <Text style={styles.topheading}>
                        API Key
                      </Text>
                              {hasApiKey &&
                                <View style={{width:'100%',marginVertical:20,justifyContent:'center',alignContent:'center'}}>
                                  <View style={{width:'100%',flexDirection:'row',alignContent:'space-between'}}>
                                    
                                      <View style={{alignSelf:"left", paddingVertical:10,textAlign:'left',padding:25}} >
                                          {!showApiKey &&
                                          // <Text style={{fontWeight:'bold'}}>
                                          // {"Show Current API Key:"}
                                          // </Text>
                                          <View style={{borderRadius:10, overflow:'hidden',}}>
                                          <Button
                                          title="Show Current API Key:"
                                          color={theme_blue_color}
                                          onPress={()=>{setShowApiKey(!showApiKey)}}
                                      >                                        
                                      </Button>
                                          </View>
                                          }
                                          {showApiKey &&
                                          //   <Text  style={{fontWeight:'bold'}}>
                                          //   {"Your Current API Key:"}
                                          // </Text>
                                          <View style={{borderRadius:10, overflow:'hidden',}}>
                                            <Button
                                            title="Hide Current API Key:"
                                            color={theme_blue_color}
                                            onPress={()=>{setShowApiKey(!showApiKey)}}
                                            >
                                            </Button>
                                            </View>
                                          }
                                      </View>
                                    
                                      <View style={{backgroundColor:'#f1f1f1',paddingVertical:10,textAlign:'center',alignSelf:'center',width:'50%'}} >
                                          {!showApiKey &&
                                            <Text >
                                            {"*******************************************"}
                                          </Text>
                                          }
                                          {showApiKey &&
                                          <TouchableOpacity style={{flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                                            <Text style={{width:'100%',textAlign:'center'}}
                                              onPress={() => {Clipboard.setString(apiKey)}} 
                                              // selectable={true}
                                            
                                            >
                                            {apiKey}
                                          </Text>
                                          
                                          </TouchableOpacity>
                                          }
                                      </View>
                                      {showApiKey &&
                                      <TouchableOpacity style={{paddingHorizontal:20, alignContent:'center',justifyContent:'center'}}  onPress={() => {Clipboard.setString(apiKey)}}>
                                                <AntDesign name="copy1" size={32} color="grey" />
                                      </TouchableOpacity>
                                       
                                       }
                                  </View>
                                  {(!isProcessing)&&
                                  <View style={{flexDirection:'row',alignContent:'center',alignSelf:'center',width:'100%',alignItems:'center',justifyContent:'center'}}>
                                      <TouchableOpacity style={{alignSelf:'center', paddingVertical:25, width:'25%',paddingHorizontal:15}}>
                                      <View style={{borderRadius:10, overflow:'hidden',}}>
                                        <Button
                                            title ="Change API Key"
                                            // color={APIKeyButtonColor}
                                            color={theme_blue_color}
                                            onPress={async()=>{
                                              await set_show_change_api_key_button(true)
                                              set_show_modal({show:true,text:change_api_key_warning_text})
                                              // if(changeAPIKeyWarningGiven==false){
                                              //   set_show_modal({show:true,text:'Changing your API key will automatically disable your previously issued API key. Please click the "CHANGE API KEY" button again if you would like to disable your current API key and generate a new API key.'})
                                              //   //Changing or deleting your API key will automatically disable your previously issued API key. Click the "CHANGE API KEY" button or the "DELETE API KEY" button again if you would like to disable your current API key and generate a new API key.
                                              //   // alert('Changing your API key will automatically disable your previously issued API key. Please click the "CHANGE API KEY" button again if you would like to disable your current API key and generate a new API key.')
                                              //   setAPIKeyButtonColor("red")
                                              //   setchangeAPIKeyWarningGiven(true)
                                              // } else {
                                              //   // setisProcessing(true)
                                              //   await createApiKey()
                                              //   // setisProcessing(false)
                                              // }
                                                                                        
                                              
                                            }}
                                        >
                                        </Button>
                                        </View>
                                      </TouchableOpacity>
                                      <TouchableOpacity style={{alignSelf:'center', paddingVertical:25, width:'25%',paddingHorizontal:15}}>
                                      <View style={{borderRadius:10, overflow:'hidden',}}>
                                        <Button
                                            title ="Delete API Key"
                                            // color={APIKeyButtonColor}
                                            color={theme_blue_color}
                                            onPress={async()=>{
                                              await set_show_delete_api_key_button(true)
                                              set_show_modal({show:true,text:delete_api_key_warning_text})
                                              // if(changeAPIKeyWarningGiven==false){
                                              //   set_show_modal({show:true,text:'Changing your API key will automatically disable your previously issued API key. Please click the "CHANGE API KEY" button again if you would like to disable your current API key and generate a new API key.'})
                                              //   // alert('Changing your API key will automatically disable your previously issued API key. Please click the "CHANGE API KEY" button again if you would like to disable your current API key and generate a new API key.')
                                              //   setAPIKeyButtonColor("red")
                                              //   setchangeAPIKeyWarningGiven(true)
                                              // } else {
                                              //   // setisProcessing(true)
                                              //   await createApiKey()
                                              //   // setisProcessing(false)
                                              // }
                                                                                        
                                              
                                            }}
                                        >
                                        </Button>
                                        </View>
                                      </TouchableOpacity>
                                  </View>
                                  }{(isProcessing)&&
                                    <View style={{ 
                                      // width: '80%',
                                      // height: '100%',
                                      // backgroundColor: '#131315',
                                      justifyContent:'center',
                                      alignItems: 'center',
                                      paddingVertical:20
                                    }}>

                                <ActivityIndicator size="large" color={theme_blue_color} />
                              </View>
                                  }
                                </View>
                              }
                              
                              
                    </View>
                  } */}
                              <View style={{paddingTop:10}}/>
                              <Text style={styles.heading}>
                                Sign Out
                              </Text>
                              <TouchableOpacity style={{alignSelf:'center', paddingVertical:25, width:'15%'}}>
                              <View style={{borderRadius:10, overflow:'hidden',}}>
                                    <Button
                                        title="Sign Out"
                                        color={theme_blue_color}
                                    onPress={()=>{signOut()}}
                                    >
                                    </Button>
                                    </View>
                              </TouchableOpacity>


                              {/* {showBilling ? 
                                (
                                <View style={{height:'100%', width:'100%'}}> 
                                  <Text style={{fontWeight:'500', fontSize: 22}}> Billing </Text>

                                  {showBilling && (billingTable.Items).map((key,bill_index) => {
                                        if(key!=""){
                                            return(
                                                <View style={{ flexDirection:'row', justifyContent:'center', alignContent:'center', textAlign:'center',
                                                      textAlignVertical:'center', alignItems:'center', backgroundColor:'white', borderRadius:10,  padding:5, marginRight:5, 
                                                      marginTop:5, overflow:'hidden', flexWrap:'wrap' }}
                                                      key={bill_index}                          >

                                                <View>  
                                                <Text style={{fontWeight: '600'}}> Sort Key</Text>
                                              </View>  

                                              <View> 
                                                  <Text style={{fontWeight: '600'}}> Request Start Time</Text>
                                                  <Text> {JSON.stringify(key.request_start_time)}</Text>
                                              </View>
                                              
                                              <View> 
                                                  <Text style={{fontWeight: '600'}}> MB x Milliseconds Used</Text>
                                                  <Text> {JSON.stringify(key.MB_times_ms_used)}</Text>
                                              </View>                         
                                            </View>
                                            )
                                          }
                                      })}
                                </View>
                                ) : null} */}
                      </View>
                     
      </View>

      
 }

{/* END OF 'MY ACCOUNT' PAGE */}

{/* START OF MY SOLUTIONS PAGE */}
{(show && (ProblemType=='mysolutions')) ? (
 <View style={{width:'100%', alignItems:'center', marginTop:15, padding:10  }}>

  {/* START OF PAGE LOADER/SPINNER */}
  
 {/* <View style={{  alignItems: 'center', backgroundColor:'#F7F7F7', width:'100%', height:250, justifyContent:'center'}}>  */}
       {/* {(isProcessing) ? ( <View style={{  alignItems: 'center',paddingVertical:20}}> <ActivityIndicator size="large" color={theme_blue_color} /> </View>  ) : null } */}
    
   {/* <View style={{ flexDirection:'row', alignItems: 'center', backgroundColor:'#F7F7F7', width:'75%', height:50, justifyContent:'center'}}> 
       <Text style={{paddingHorizontal:'1.5%', fontWeight:'600', fontSize: 18, color:'black'}}>API KEY</Text>
   </View>  */}

   {/* <View style={{ flexDirection:'row', alignItems: 'center', backgroundColor:'white', width:'90%', height:50, justifyContent:'center'}}> 
       <Text>{!showApiKey ? '*******************************************' : apiKey }</Text>

       <TouchableOpacity style={{ marginLeft:'5%', flexDirection:'row',}} onPress={() => {Clipboard.setString(apiKey)}}>
           <Ionicons style={{ left: 5}} name={'copy'} size={16} color={'C4CADE'}/>
           <Text style={{fontSize:14, fontWeight:'400', color: 'black', marginLeft: 8.5}}>Copy Key</Text>
       </TouchableOpacity>
   </View> */}

   {/* <View style={{ flexDirection:'row', alignItems: 'center', width:'75%', height:50, justifyContent:'center'}}>       
    <TouchableOpacity style={{ marginTop:'5%', paddingHorizontal:'9.5%', marginHorizontal: 15, paddingVertical:'1.5%', backgroundColor: APIKeyButtonColor, borderRadius: 5}}
       onPress={(async) => {
          setShowAPIModal(true)
          setAPIKeyButtonColor("red")
          setchangeAPIKeyWarningGiven(true)

       }}
       // onPress={async()=>{ set_show_modal({show:true,text:'Changing your API key will automatically disable your previously issued API key. Please click the "CHANGE API KEY" button again if you would like to disable your current API key and generate a new API key.'})
             // if(changeAPIKeyWarningGiven==false) {
             //   set_show_modal({show:true,text:'Changing your API key will automatically disable your previously issued API key. Please click the "CHANGE API KEY" button again if you would like to disable your current API key and generate a new API key.'})
             //   // alert('Changing your API key will automatically disable your previously issued API key. Please click the "CHANGE API KEY" button again if you would like to disable your current API key and generate a new API key.')
             //   setAPIKeyButtonColor("red")
             //   setchangeAPIKeyWarningGiven(true)
             // } else {
             //   setisProcessing(true)
             //   await createApiKey()
             //   setisProcessing(false)
             // }
           // }}> 
       >
       <Text style={{color:'white', fontSize:16, fontWeight:'500'}}>Change API Key </Text>
   </TouchableOpacity>
    
       <TouchableOpacity
         style={{ marginTop:'5%', paddingHorizontal:'9.5%',  marginHorizontal: 15, paddingVertical:'1.5%', backgroundColor:APIKeyButtonColor, borderRadius: 5}}
         onPress={() => setShowApiKey(!showApiKey)}> 
         <Text style={{color:'white', fontSize:16, fontWeight:'500'}}> {showApiKey ? 'Hide API Key' : 'Show API Key'}</Text>
     </TouchableOpacity> 

   </View> */}

{/* </View> */}


<View style={{ paddingVertical: 15,  width: '80%', justifyContent:'center', alignContent:'center', textAlign:'center', textAlignVertical:'center', alignItems:'center'}}>



{/* <View style={{ flexDirection:'row', alignItems: 'center', width:'75%', height:50, justifyContent:'center'}}>       
         <TouchableOpacity style={{ marginTop:'5%', paddingHorizontal:'9.5%', marginHorizontal: 15, paddingVertical:'1.5%', backgroundColor: '#152E56', borderRadius: 5}}
                 onPress={() => {
                                 setShowBilling(false)
                                 setShowSolutions(true)
                               }}>
               <Text style={{color:'white', fontSize:16, fontWeight:'500'}}>Show Solutions </Text>
         </TouchableOpacity>
    
       <TouchableOpacity
         style={{ marginTop:'5%', paddingHorizontal:'9.5%',  marginHorizontal: 15, paddingVertical:'1.5%', backgroundColor:'#152E56', borderRadius: 5}}
         onPress={() => {
                       setShowBilling(true)
                       setShowSolutions(false)
                     }}> 
               <Text style={{color:'white', fontSize:16, fontWeight:'500'}}> Show Billing</Text>
     </TouchableOpacity> 

</View> */}

{/* putRequestConstructor.PutRequest.Item.sort_key = await {S:eventt.objective+":"+eventt.constraints+":"+eventt.MaxObjectiveFunctionValue+":"+eventt.minDistance+":"+eventt.nPoints+":"+eventt.invocationType+":"+ */}
  {/* sort_key":{"S":"(1/x+y+z-3)^2+(x+1/y+z-3)^2+(x+y+1/z-3)^2:0<1:0.01:0.01:1:synchronous:0722111f-60b5-4103-a544-c23622ea8dee:0e560876-bd20-4e0d-996c-16f8e5e34cfd"} */}
  {/* sort_key.S = {objective_function: constraints : max_objective_function_value : min_distance : n_points : invocation_type : api_key : api_secret} */}
  {/* ======== sort_key_array = sort_key.S.split(':') ========
           sort_key_array[2]  = max_objective_function_value 
           sort_key_array[3] = min_distance
            sort_key_array[4] = n_points
            
            */}
{/* START OF SOLUTIONS TABLE */}
<View style={{ paddingTop: 15, width: '100%'}}>

{showSolutions ? ( 
    <View style={{height:'100%', width:'100%', justifyContent: 'center', alignItems: 'center'}}> 
      {/* <TouchableOpacity style = {{ paddingVertical: 35, marginBottom: 25, backgroundColor: '#ECEBF1', width: 200, height: 45, borderRadius: 15, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 5,  }}
                            onPress={async() => { download_solutions_table(solutionsTable) }} >    
        <Text style = {{ color: 'black', fontSize: 16, fontWeight: '600'}}> DOWNLOAD ALL SOLUTIONS </Text>
    </TouchableOpacity> */}
                                 


    <View style={{ paddingHorizontal: '3.5%',flexDirection:'row', alignItems: 'center', borderBottomColor:'#F3F3F3', borderBottomWidth: 1.5, width:'100%', height: 35, justifyContent: 'space-between'}}> 
        <Text style={{ fontSize: 18, color:'#708D9B', fontWeight: '600', paddingVertical: '2.5%' }}>UNIQUE PROBLEM SETS: ({solutionsTable.Items.length}) </Text>
            
            
        <TouchableOpacity onPress={async() => {
          // Get all solutions from solutions-table 
          const table = await query_dynamodb_table('solutions-table')
          console.log("[+] Response from table query... ", JSON.stringify(table))
           download_solutions_table(table) 
        }}
          style={{  flexDirection:'row', alignItems: 'center', justifyContent:'center', paddingHorizontal: '2.5%'}}>
            <AntDesign style={{ paddingHorizontal: 1}} name={'clouddownload'} size={18} color={'gray'}/>
            <Text style={{ fontSize: 16, color:'gray', fontWeight: '600', paddingHorizontal: 3.5 }}> 
                Download All Solutions
           </Text>
                <AntDesign style={{ paddingHorizontal: 1 }} name={'clouddownload'} size={18} color={'gray'}/>                     
      </TouchableOpacity>
    </View>       
  
     {(solutionsTable.Items.length != 0) && solutionsTable.Items.map((key, indexxx) => {         
                // ATTRIBUTE 1 -> sort_key: Object { S: "(1/x+y+z-3)^2+(x+1/y+z-3)^2+(x+y+1/z-3)^2+0+0+0" }
                // ATTRIBUTE 2 -> user_id: Object { S: "us-west-2:f754aef7-da46-4fec-8076-39c4123de596" }
                // ATTRIBUTE 3 -> request_start_time: Object { S: "1659173638511" }                
      // =============================================================== //

      // console.log("[+] solutionsTable ------- ", solutionsTable)
         // ====== PARSING OPERATIONS ======= //        
        var objective_function = key.sort_key.S
        var unique_prob_num = Number(indexxx + 1)
       

        if(((key!="") && sub_show)){
              return(                
                 <View key={indexxx}
                  style={{ width: '100%', borderRadius:5, paddingVertical: '1.5%',  justifyContent:'center',   alignItems:'center',   textAlign:'center', textAlignVertical:'center', }}>    
                        <TouchableOpacity onPress={ async()=> {  await get_constraints_for_obj_func (objective_function, key, indexxx)}}
                         style={{ flexDirection: 'row', paddingHorizontal: '25%',  paddingHorizontal: 7.5,flexDirection:'row', alignItems: 'center', width:'100%', borderBottomRightRadius: 9.5, backgroundColor: '#F8F8FC',   height:50, justifyContent: 'space-evenly' }}> 
                              <Text style={{ fontSize: 20, color:'#708D9B', fontWeight: '600', paddingVertical: '5%' }}> PROBLEM SET: {indexxx+1} </Text>                     
                             <Text style={{ flexDirection: 'row', paddingHorizontal: 3.5, fontSize: 14, color: '#708D9B', fontWeight: '500', marginLeft: '65%', }}> 
                                <AntDesign name={key.showAllUniqueProblems==true ? 'up' : 'right'} size={14} color={'gray'}/> 
                                {/* <AntDesign style={{ paddingHorizontal: 0.5}} name={key.showAllUniqueProblems==true ? 'up' : 'right'} size={14} color={'gray'}/>  */}
                                  { (key.showAllUniqueProblems== true) ?` Hide details` : `  Show details`}
                            
                             </Text>
                 
                     
                        </TouchableOpacity>               
  
                 <View style={{  paddingLeft: 35, borderBottomColor:'#FBFBFC', borderBottomWidth: 2.5, alignItems: 'center', width:'100%', justifyContent:'center', paddingVertical: 5, alignItems: 'flex-start'}}> 
                  {/* <Text style={{ fontSize: 14, color:'black', fontWeight: '600', paddingTop: 2.5, paddingLeft: '2.5%' }}> OBJECTIVE FUNCTION </Text> */}
              
                    <View style={{ width: '100%', alignItems: 'flex-start', }}>
                      <MathJaxSvg style={{  paddingVertical: 5, borderBottomColor:'#F3F3F3',  }} fontSize = {22} color={'black'} fontCache={true}>
                          {objective_function}
                      </MathJaxSvg>
                      </View>

                      <View style = {{ borderBottomLeftRadius: 9.5, borderBottomRightRadius: 9.5, width: '100%', backgroundColor: '#FAFAFE', paddingVertical: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                          <TouchableOpacity onPress={async() => {
                            // Download Solutions for a Given Problem Set  
                            console.log("[+] Getting solutions for problem set: ", objective_function)
                            const solutions = await query_unique_problem('solutions-table', objective_function)
                            console.log("[+] solutions: ", JSON.stringify(solutions))
                            download_solutions_table(solutions) 
                            // const table = await query_dynamodb_table('solutions-table')
                            // download_solutions_table(table) 
                          }}
                                    style={{  flexDirection:'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent:'center', paddingLeft: '2.5%', paddingVertical: 5}}>
                                      {/* <AntDesign style={{ paddingHorizontal: 1}} name={'clouddownload'} size={18} color={'gray'}/> */}
                                  <AntDesign style={{ paddingHorizontal: 1 }} name={'clouddownload'} size={18} color={'gray'}/>                     
                                      <Text style={{ fontSize: 16, color:'gray', fontWeight: '600', paddingHorizontal: 5 }}> 
                                          {/* Download Solutions (Problem Set {indexxx+1})  */}
                                          Download Solutions
                                    </Text>
                         
                        </TouchableOpacity>

                          <Text style={{ color: 'gray',  paddingVertical: 5, paddingRight: '2.5%' }}> POWERED BY TOXEUS </Text>
                        </View>
                      
         
            </View>
                   
            
         
           
  
  
             {/*  ======================== TABLE: unique_objective_plus_constraint =============================================================================                     
                      query_unique_constraints:  {"Count":2, "Items":[{"sort_key":{"S":"-((M*30+Z*6+C*85)-(M*15+Z*3+C*60)):(M*15+Z*3+C*60)<=1000,M>=0,Z>=0,C>=1"},"user_id":{"S":"us-west-2:f754aef7-da46-4fec-8076-39c4123de596"},"request_start_time":{"S":"1659179170136"}},{"sort_key":{"S":"-((M*30+Z*6+C*85)-(M*15+Z*3+C*60)):(M*15+Z*3+C*60)<=1000,M>=0,Z>=0,C>=0"},"user_id":{"S":"us-west-2:f754aef7-da46-4fec-8076-39c4123de596"},"request_start_time":{"S":"1659177073171"}}],"ScannedCount":2}
             =====================================================================================================================================================
            */}
      
             {/* <View style={{ marginTop: '1.5%', width: '100%', height: 5 }}/>       */}

                { key.showAllUniqueProblems == true ? (     
                  <View style= {{ paddingLeft: '1.5%', width: '100%', justifyContent: 'center', alignItems:'center',  marginBottom: 5}}>
                  {/* <View style={{paddingHorizontal: '3.5%', flexDirection:'row', alignItems: 'center',  backgroundColor: '#F8F8FC', borderBottomColor: '#F3F3F4', borderBottomWidth: 2.5, width:'100%', height: 35, justifyContent: 'space-between'}}> 
                    <Text style={{ fontSize: 16, color:'#708D9B', fontWeight: '600', }}> 
                        UNIQUE CONSTRAINT SETS: ({  key.uniqueProblemConstraints.length }) 
                      </Text>
                  </View>                        */}
                    
                            {Object.keys(key.uniqueProblemConstraints).map((con_key, con_index) => {
                              // var uniqueConstraintArr = key.uniqueProblemConstraint[con_key].sort_key.S.split(':')[1].split(',')
                              // console.log("uniqueConstraintArr: ", uniqueConstraintArr)
                              // console.log("[+] Mapping key.uniqueProblemConstraints", key.uniqueProblemConstraints)
                              //     console.log("[+] con_key: ", con_key)
                              //     console.log("[+] con_index: ", con_index)
                              
                              // console.log("[+] \n\n Attempting to map uniqueConstraintArr: ", uniqueConstraintArr)
                              // var uniqueConstraintsObj = uniqueProblemConstraints[con_key].sort_key.S          
                              // var showSolutionss = solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].showSolutions                    

                              var constraint_set_number = Number(con_key) + 1     
                              // console.log("[+] constraint_set_number: ", constraint_set_number) 
                              
                              var uniqueConstraintString = key.uniqueProblemConstraints[con_key].sort_key.S.split(':')[1]
                              // console.log("[+] uniqueConstraintString: ", uniqueConstraintString)

                              var uniqueConstraintArr = uniqueConstraintString.split(',')
                              // console.log("[+] uniqueConstraintArr: ", uniqueConstraintArr)

                                                         
                              key.showSolutions = false;
                              
                              return(
                                <View key={con_key}  style={{ paddingLeft: 45, width: '100%', borderRadius: 20, paddingVertical: 15,  justifyContent:'center',   alignItems:'flex-start',   textAlign:'center', textAlignVertical:'center', }}>    
                               
                                        <TouchableOpacity style={{ paddingVertical: 15, paddingLeft: '3.5%', backgroundColor: '#F8F8FC',  flexDirection:'row', alignItems: 'center', width:'100%', }}
                                        onPress={async() => {
                                          await get_solutions_for_problem_constraint_combo (objective_function, indexxx, con_key, con_index,uniqueConstraintString)                                                                                                   
                                                  
                                        }}
                                        >                                         
                                            <Text style={{  fontSize: 16, color:'#708D9B', fontWeight: '600', paddingRight: '3.5%' }}> 
                                                 CONSTRAINT SET: {constraint_set_number}
                                            </Text>


                                                {/* <TouchableOpacity onPress={ async()=> {  await get_constraints_for_obj_func (objective_function, key, indexxx)}} */}
                                                <View style={{ flexDirection:'row', alignItems: 'center', justifyContent: 'center'}}> 
                                                    <Text style={{ left: 15,flexDirection: 'row',  fontSize: 14, color: '#708D9B', fontWeight: '500' , left: 5}}> 
                                                      <AntDesign style={{ left: '5%'}} name=  { (key.uniqueProblemConstraints[con_index].showSolutions== true) ?'up' : 'right'} size={14} color={'gray'}/> 
                                                      {/* <AntDesign style={{ paddingHorizontal: 0.5}} name={key.showAllUniqueProblems==true ? 'up' : 'right'} size={14} color={'gray'}/>  */}
                                                        { (key.uniqueProblemConstraints[con_index].showSolutions== true) ?` Hide solutions ` : `  See problem solutions`}
                                                        {/* solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].showSolutions */}
                                                    </Text>


                                                </View>
                                        </TouchableOpacity>     


                                     <View style={{ paddingVertical: 5, flexDirection: 'row', marginLeft: '1.5%', paddingHorizontal: 5, 
                                      alignItems: 'flex-start', borderBottomColor:'#F3F3F3', borderBottomWidth: 2, flexDirection: 'row',
                                      borderTopWidth: 2, borderTopColor: '#F3F3F3',  width:'100%' }}>                                       
                                             {Object.keys(uniqueConstraintArr).map((uniqueConKey, uniqueConIndex) => {
                                          return(
                                              <View key={uniqueConKey} style={{ marginHorizontal: '1%',  marginVertical: 5, borderColor: '#A9C0CC', backgroundColor: 'rgba(140, 178, 234, 0.05)', borderWidth: 1, paddingHorizontal: 9.5, borderRadius: 14.5, justifyContent: 'center', alignItems: 'center', paddingVertical: 10}}> 
                                                    <Text style={{ paddingHorizontal: 5, paddingVertical: 9.5, fontSize: 14, color: '#416F8C', fontWeight: '500'}}>
                                                        {uniqueConstraintArr[uniqueConKey]} 
                                                    </Text>                                                                              
                                              </View>
                                            )})}                      
                                     </View>                                    

                                        <TouchableOpacity style={{  flexDirection:'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent:'center', paddingLeft: '3.5%', paddingVertical: 15}}
                                              onPress={async() => { console.log(`[+] Downloading solutions.... ---- PARAMETERS ---- \n objective_function: ${objective_function} \n indexxx: ${indexxx} \n con_key: ${con_key} \n con_index: ${con_index} \n uniqueConstraintString: ${uniqueConstraintString}`)
                                              // const solutions = await query_unique_problem('solutions-table', objective_function)
                                                // const solutions = await get_solutions_for_problem_constraint_combo (objective_function, indexxx, con_key, con_index,uniqueConstraintString)    
                                                const solutions = await query_unique_problem('solutions-table', (objective_function+":"+uniqueConstraintString))        
                                                console.log("[+]  solutions: ", JSON.stringify(solutions))
                                                download_solutions_table(solutions)                                          
                                                }}>                                                   
                                                <AntDesign style={{ paddingHorizontal: 1 }} name={'clouddownload'} size={16} color={'gray'}/>                     
                                                <Text style={{ fontSize: 14, color:'gray', fontWeight: '600', paddingLeft: 5 }}> Download Solutions (Constraint Set {constraint_set_number}) </Text>
                                        </TouchableOpacity>

                                     {/* <View style={{ paddingLeft: 25, flexDirection:'row', alignItems: 'center', width:'100%', height: 45, justifyContent:'flex-start', paddingVertical: '1.5%',}}>  */}
                                      {/* <TouchableOpacity 
                                      onPress = { async() => {   
                                          console.log("[+] Clicked 'Display Solutions' button....")  
                                        await get_solutions_for_problem_constraint_combo (objective_function, indexxx, con_key, con_index,uniqueConstraintString)      

                                        }}

                                     style={{ paddingHorizontal: 5, paddingVertical: 2.5, flexDirection:'row', alignItems: 'center',  height: 35, justifyContent:'center',  alignItems: 'center', borderRadius: 5.5, marginLeft: '2.5%'}}>
                                          <AntDesign style={{ paddingHorizontal: 3.5}} name={key.uniqueProblemConstraints[con_key].showSolutions==true ? 'upcircle' : 'downcircle'}  size={14} color={'gray'}/>
                                              <Text style={{ paddingVertical: 2.5, paddingHorizontal: 3.5, fontSize: 16, color: 'gray', fontWeight: '600', }}> 
                                             
                                                 { key.uniqueProblemConstraints[con_key].showSolutions == true ? `Click to hide solutions for constraint set ${constraint_set_number})` : `Click to show all problems using constraint set ${constraint_set_number})`}

                                              </Text>
                                              <AntDesign style={{ paddingHorizontal: 3.5}} name={key.uniqueProblemConstraints[con_key].showSolutions==true ? 'upcircle' : 'downcircle'}  size={14} color={'gray'}/>
                                          </TouchableOpacity> */}
                                          
                                      {/* </View> */}
                                      {/* START OF SOLUTIONS FOR UNIQUE OBJECTIVE FUNCTION AND CONSTRAINT COMBO */}
                                            { key.uniqueProblemConstraints[con_key].showSolutions == true ? (
                                                  Object.keys(solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions).map((uniqueProbConstrSolKey, UniqueProbConstSolIndex) => {
                                                    console.log("[+] Mapping Unique Objective Function and Constraint Combo... \n uniqueProbConstrSolKey:", uniqueProbConstrSolKey)
                                                    console.log("[+] solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey]: ", solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey])
                                                   var numSolutions = solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions.length;
                                                   console.log("[+] Nuber of solutions: ", JSON.stringify(numSolutions))
                                                    var objective_function_value = solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey].obj_func_val.S
                                                    // var uniqueProbConstComboIndividualSolution = solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey].solution.M
                                                    // console.log("[+] uniqueProbConstComboIndividualSolution: ", uniqueProbConstComboIndividualSolution)
                                                    var constraints_arr = solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey].sort_key.S.split(':')[1].split(',')
                                                   var timeEp = Number(solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey].start_time.S)
                                                   var timeStamp = new Date(timeEp)
                                                   var startDate = moment(timeStamp).format('MMMM Do YYYY, h:mm a')
                                                   console.log("[+] startDate: ", startDate)
                                                    // var timeEp = Number(key.start_time.S)    
                                                    // var timeStamp = new Date(timeEp)
                                                    // var startDate = moment(timeStamp).format('MMMM Do YYYY, h:mm a')
                                                    return(
                                                             <View key={uniqueProbConstrSolKey}  style={{width:'100%', justifyContent:'center', paddingVertical: 5, paddingLeft: 65, paddingTop: 15 }}> 
                                                        
                                                                <View style={{  flexDirection: 'row',  paddingHorizontal: '2.5%',  flexDirection:'row', alignItems: 'center',  backgroundColor: '#F8F8FC',  borderBottomColor:'#F3F3F3', paddingVertical: 5, borderBottomWidth: 3.5,  width:'100%', justifyContent: 'space-between', marginTop: 10,  }}> 
                                                                      <Text style={{ fontSize: 18, color:'#708D9B', fontWeight: '600',}}> SOLUTION SET: ({Number(uniqueProbConstrSolKey)+1}/{numSolutions}) </Text>
                                                                      <Text style={{ fontSize: 18, color:'#708D9B', fontWeight: '500', paddingVertical: 3.5, paddingHorizontal: 10 }}> {startDate} </Text>
                                                                  </View>

                                                                       {/* ===== START OF SOLUTION MAPPING FOR EACH DECISION VARIABLE ===== */}
                                                                       {Object.keys(solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey].solution.M).map((u_key,u_index) =>  {
                                                                  // var rounded_sol =  Number(uniqueProbConstComboIndividualSolution[uniqueProbConstComboIndividualSolution].S).toFixed(3)
                                                                  return (
                                                                        <View key={u_key} style={{  borderBottomColor:'#F3F3F3', borderBottomWidth: 2, flexDirection:'row', alignItems: 'center', width:'100%', height: 65, justifyContent:'flex-start', paddingVertical: 15, paddingLeft: '3.5%',}}> 
                                                                            <MathJaxSvg fontCache={true} fontSize = {18} color={'black'}  style={{ paddingLeft: '1.5%', paddingVertical: 10, fontWeight: '600', flexDirection: 'row' }} >                                   
                                                                              {/* {uniqueProbConstComboIndividualSolutionKey + ' = ' +  uniqueProbConstComboIndividual_roundedSolution}  */}
                                                                              {u_key + ' = ' + solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey].solution.M[u_key].S}
                                                                            </MathJaxSvg>
                                                                        </View>
                                                                        )
                                                                       })}

                                                                <View style={{ paddingHorizontal: '2.5%', flexDirection:'row', alignItems: 'center',  backgroundColor: '#F8F8FC', borderBottomColor:'#F3F3F3',  borderBottomColor: '#F8F8FC', borderBottomWidth: 2.5, borderBottomWidth: 2.5, paddingVertical: 15, width:'100%', justifyContent: 'space-between'}}> 
                                                                      <Text style={{ fontSize: 16, color:'#708D9B', fontWeight: '600', paddingVertical: 5}}> OBJECTIVE FUNCTION VALUE  </Text> 
                                                                </View>
                                                                <View style={{ flexDirection:'row', alignItems: 'center', width:'100%', height: 65, justifyContent:'flex-start', paddingVertical: 15, paddingLeft: '2.5%'}}> 
                                                                    <MathJaxSvg style={{  paddingLeft: '3.5%',   paddingVertical: 10,  }} fontSize = {18} color={'black'}>
                                                                        {objective_function_value} 
                                                                    </MathJaxSvg>
                                                                    <TouchableOpacity onPress={async() => {
                                                                      console.log("[+] Getting solutions for solution set: ", Number(uniqueProbConstrSolKey)+1)
                                                                      const uniqueSolution = solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey].sort_key.S
                                                                      console.log("[+] uniqueSolution: ", JSON.stringify(uniqueSolution))
                                                                      const solutions = await query_unique_problem('solutions-table', uniqueSolution)
                                                                      console.log("[+] Response from query --> \n solutions: ", JSON.stringify(solutions))
                                                                      download_solutions_table(solutions) 
                                                                            
                                                                          }}
                                                                            style={{  flexDirection:'row', alignItems: 'center', justifyContent:'center', paddingLeft: '45%',}}>
                                                                              {/* <AntDesign style={{ paddingHorizontal: 1}} name={'clouddownload'} size={18} color={'gray'}/> */}
                                                                          <AntDesign style={{ paddingHorizontal: 1 }} name={'clouddownload'} size={18} color={'gray'}/>                     
                                                                              <Text style={{ fontSize: 16, color:'gray', fontWeight: '600', paddingHorizontal: 5 }}> 
                                                                                  Download Solution Set ({Number(uniqueProbConstrSolKey)+1}) 
                                                                            </Text>
                                                                </TouchableOpacity>

                                                              </View>  
                                                              {/*  ===== END OF SOLUTION MAPPING FOR EACH DECISION VARIABLE ===== */}
                                                                    {/* {Object.keys(constraints_arr).map((con_arr_key, con_arr_index) => {
                                                                return(
                                                                  <View key={con_arr_key}  style={{width:'100%', justifyContent:'center', paddingTop: 5, }}> 
                                                                          <View style={{  borderBottomColor: '#F8F8FC', borderBottomWidth: 2.5, paddingHorizontal: '3.5%', flexDirection:'row', alignItems: 'center', borderBottomColor:'#F3F3F3', borderBottomWidth: 2,  width:'100%', height:50, justifyContent: 'space-between', top: 5}}> 
                                                                              <Text style={{ fontSize: 15, color:'#708D9B', fontWeight: '500', paddingVertical: 15 }}> 
                                                                                  CONSTRAINT {Number(con_arr_key)+1}
                                                                              </Text>
                                                                        </View>    
                                                                        <View style={{ flexDirection:'row', alignItems: 'center', width:'100%', height: 50, justifyContent:'flex-start'}}> 
                                                                             
                                                                          <MathJaxSvg style={{  paddingLeft: '7.5%', paddingLeft: '3.5%',  marginVertical: 5, paddingVertical: 13.5, justifyContent:'flex-start', flexDirection: 'row' }} fontSize = {22} color={'black'} fontCache={true}>

                                                                                  {constraints_arr[con_arr_key]}
                                                                          </MathJaxSvg>
                                                                        </View>
                                                                  </View>
                                                                )
                                                              })} */}

                                                          {/* <View style={{  borderBottomColor: '#F8F8FC', borderBottomWidth: 2.5,  paddingVertical: 10, paddingLeft: '3.5%',  flexDirection:'row', alignItems: 'center',  borderBottomColor:'#F3F3F3',
                                                                borderBottomWidth: 2.5,  width:'100%', justifyContent: 'space-between'}}> 
                                                                  <Text style={{ fontSize: 15, color:'#708D9B', fontWeight: '600', paddingVertical: 10 }}> MAX OBJECTIVE FUNCTION VALUE </Text>
                                                              </View> 

                                                              <View style={{ flexDirection:'row', alignItems: 'center', width:'100%', height: 35, justifyContent:'flex-start', paddingVertical: 15 ,}}> 
                                                                  <MathJaxSvg fontSize = {20} color={'black'} fontCache={true} style={{  paddingHorizontal: '3.5%',  paddingVertical: 5 , justifyContent:'flex-start', flexDirection: 'row' }} >                                   

                                                                      {solutionsTable.Items[indexxx].uniqueProblemConstraints[con_index].solutions[uniqueProbConstrSolKey].sort_key.S.split(':')[2]}
                                                                  </MathJaxSvg>
                                                              </View> */}
         
                                                              
                                                          </View>
                                                        )
                                                       }) 
                                                ): null}
                                              {/* ======== END OF SOLUTIONS FOR UNIQUE OBJECTIVE FUNCTION AND CONSTRAINT COMBO =============== */}
                                          
                                      {/* <Text> {JSON.stringify(key.obj_cons_solutions_combo)} </Text> */}
                                </View>
                              )
                            })}


                            
                    

                    {/* { Object.keys(uniqueProblemAndConstraintArr).map((uniqueConProbKey, uniqueConProbIndex) => { */}
                      {/* // solutionsTable.Items[indexxx].showSolutions = false;
                      // console.log("uniqueProblemAndConstraintArr[uniqueConProbKey]: ", uniqueProblemAndConstraintArr[uniqueConProbKey])
                            //  var obj_func_value = uniqueConKey.obj_func_val.S
                            //  var rounded_value = Number(obj_func_value).toFixed(3)
                            //  var max_obj_func_value = uniqueConKey.sort_key.S.split(':')[2]
                        
                            //  var min_acceptable_distance = uniqueConKey.sort_key.S.split(':')[3]
                            //  var n_points = uniqueConKey.sort_key.S.split(':')[4]
                            //  var solver_runtime = uniqueConKey.solver_runtime.S
                            //  var operation_type = uniqueConKey.sort_key.S.split(':')[5]
                            //   var timeEp = Number(uniqueConKey.start_time.S)     */}
                      {/* return( */}
{/* 
                      // <View style={{width: '100%', flex: 1 }}>
                      <View key={uniqueConProbKey} style={{width:'100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: '5%', paddingVertical: '1.5%', borderBottomColor: '#F3F3F3', borderBottomWidth: 2, borderTopWidth: 2, borderTopColor: '#F3F3F3', }}>
                          <View style={{ paddingHorizontal: '2.5%', flexDirection:'row', alignItems: 'center', backgroundColor:'#F3F3F3', paddingVertical: '3.5%' , width:'100%', height:50, justifyContent: 'space-between'}}> 
                          <Text style={{ fontSize: 24, color:'#708D9B', fontWeight: '600', paddingVertical: '5%'}}> Objective Function Value </Text> 
                      </View> */}
                      {/* <Text style={{  paddingLeft: '12.5%',  marginVertical: 5, paddingVertical: 13.5, justifyContent:'flex-start', flexDirection: 'row' }}>
                        {obj_func_value} 
                      </Text>
                      <View style={{ flexDirection:'row', alignItems: 'center', width:'100%', height:50, justifyContent:'flex-start', paddingVertical: '3.5%' ,}}> 
                          <MathJaxSvg style={{  paddingLeft: '3.5%',   marginVertical: 5,  paddingVertical: '5%' , justifyContent:'flex-start', flexDirection: 'row' }} fontSize = {22} color={'black'} fontCache={true}>
                          {rounded_value} 
                          </MathJaxSvg>
                      </View>

                      <View style={{  paddingLeft: '3.5%',  flexDirection:'row', alignItems: 'center', backgroundColor:'#F3F3F3', width:'100%', height:50, justifyContent: 'space-between', }}> 
                          <Text style={{ fontSize: 24, color:'#708D9B', fontWeight: '600', paddingVertical: '5%'}}> Solutions </Text>
                      </View>
                      {Object.keys(solutions).map((sol_key, sol_index) =>  {
                              var rounded_solution =  Number(solutions[sol_key].S).toFixed(3)
                              return (
                                    <View key={sol_index} style={{  borderBottomColor:'#F3F3F3', borderBottomWidth: 2, flexDirection:'row', alignItems: 'center', width:'100%', height:50, justifyContent:'flex-start', paddingVertical: '5%' ,}}> 
                                        <MathJaxSvg fontSize = {22} color={'black'} fontCache={true} style={{ paddingLeft: '3.5%', paddingVertical: '5%' , justifyContent:'flex-start', flexDirection: 'row' }} >                                   
                                          {sol_key + ' = ' +  rounded_solution} 
                                        </MathJaxSvg>
                                    </View>
                                    )
                        })}

                      <View style={{  paddingLeft: '3.5%',  flexDirection:'row', alignItems: 'center', backgroundColor:'#F3F3F3', width:'100%', height:50, justifyContent: 'space-between'}}> 
                          <Text style={{ fontSize: 24, color:'#708D9B', fontWeight: '600', paddingVertical: '5%' }}> Max Objective Function Value </Text>
                      </View> 
                      <View style={{ flexDirection:'row', alignItems: 'center', width:'100%', height:50, justifyContent:'flex-start', paddingVertical: '3.5%' ,}}> 
                        <MathJaxSvg fontSize = {22} color={'black'} fontCache={true} style={{  paddingHorizontal: '2.5%',  paddingVertical: '5%' , justifyContent:'flex-start', flexDirection: 'row' }} >                                   
                        {max_obj_func_value}
                        </MathJaxSvg>
                      </View>

                        <View style={{ paddingLeft: '3.5%',  flexDirection:'row', alignItems: 'center', backgroundColor:'#F3F3F3', width:'100%', height:50, justifyContent: 'space-between'}}> 
                        <Text style={{  paddingLeft: '2.5%', fontSize: 24, color:'#708D9B', fontWeight: '600', paddingVertical: '5%' }}> 
                            Minimum Acceptable Distance Between Unique Solutions 
                          </Text>
                      </View> 
                      <View style={{ flexDirection:'row', alignItems: 'center', width:'100%', height:50, justifyContent:'flex-start', paddingVertical: '3.5%' ,}}> 
                          <MathJaxSvg fontSize = {22} color={'black'} fontCache={true} style={{  paddingLeft: '3.5%', paddingVertical: '5%' ,justifyContent:'flex-start', flexDirection: 'row' }} >                                   
                                {min_acceptable_distance}
                          </MathJaxSvg>                    
                      </View>

                      <View style={{  backgroundColor:'#F3F3F3', flexDirection:'row', alignItems: 'center', width:'100%', height:50, justifyContent:'flex-start', paddingVertical: '3.5%' ,}}> 
                      <Text style={{  paddingLeft: '2.5%', fontSize: 24, color:'#708D9B', fontWeight: '600', paddingVertical: '5%' }}> 
                            Number of Starting Points
                          </Text>
                      </View> 
                      <View style={{ flexDirection:'row', alignItems: 'center', width:'100%', height:50, justifyContent:'flex-start', paddingVertical: '3.5%' ,}}> 
                      <MathJaxSvg fontSize = {22} color={'black'} fontCache={true} style={{  paddingLeft: '3.5%', paddingVertical: '5%' ,justifyContent:'flex-start', flexDirection: 'row' }} >                                   
                            {n_points}
                        </MathJaxSvg>
                      </View> 



) : (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center',paddingVertical:20 }}>
        <ActivityIndicator size="large" color={theme_blue_color} />
  </View>
)
}


                      <View style={{ flexDirection:'row', alignItems: 'center', width:'100%', height:50, justifyContent:'flex-start', paddingVertical: '3.5%' ,}}> 

                      <Text  style={{ fontSize: 22, fontWeight: '400', paddingLeft: '3.5%', paddingVertical: '5%' ,justifyContent:'flex-start', flexDirection: 'row' }} >                                   
                        {solver_runtime} Milliseconds
                        </Text>
                        </View> 

                      <View style={{ flexDirection:'row', alignItems: 'center', width:'100%', height:50, justifyContent:'flex-start', paddingVertical: '3.5%' ,}}> 
                      <Text style={{ fontSize: 22, fontWeight: '400', paddingLeft: '3.5%', paddingVertical: '5%' ,justifyContent:'flex-start', flexDirection: 'row' }}> 
                            {operation_type} 
                        </Text>                   
                      </View>  */}
                  
                  {/* </View> */}


                    {/* // ) : null } */}
                      {/* ) */}

                    {/* })} */}
                            




                            </View>
                ) : null
                }
  
             </View>
              )
            } else {
              return null
            }
        })}
  
  
  
  </View>
  
  ) : null }
</View>
{/* END OF SOLUTIONS TABLE  */}
                      
                


   </View>
 </View>

) : null }
{/*  END OF 'MY SOLUTIONS PAGE' */}



{/* {(show_modal.show && show) && */}
{/* ERROR ISNT HERE */}
{(show_modal.show && show) &&//?
    <View style={{width:'100%',height:'100%',
    // backgroundColor:'rgba(0,0,0,1)',
    position:'absolute',top:'0%'}}>
          <View style={{overflow:'hidden',width:'50%'}}>
                   <Modal
                   animationType="fade"
                   transparent={true}
                  //  visible={modalVisible2.decisionVariableString.show}
                  //  visible={modal_visible3.decisionVariableString.show}
                  //  visible={show_decision_variable_modal}
                   visible={show_modal.show}
                   onRequestClose={() => {
                    //  Alert.alert("Modal has been closed.");
                    //  var modal_visible2 = modalVisible2
                    //  modal_visible2.decisionVariableString.show = false
                    //  setModalVisible2(modal_visible2);
                    //  set_show_decision_variable_modal(false)
                     set_show_modal({show:false,text:""})
                   }}
                 >
                   <View style={{
                           flex: 1,
                           justifyContent: "center",
                           alignItems: "center",
                          //  marginTop: 22,
                           height:'100%', 
                           width:'100%',
                           top:'0%',
                           backgroundColor:'rgba(0,0,0,0.5)',
                         }}>
                     <View style={{
                               margin: 20,
                               backgroundColor: "white",
                               borderRadius: 20,
                               padding: 35,
                               alignItems: "center",
                               shadowColor: "#000",
                               shadowOffset: {
                                 width: 0,
                                 height: 2
                               },
                               shadowOpacity: 0.25,
                               shadowRadius: 4,
                               elevation: 5,
                                width:'50%',
                             }}>

                        {(!show_privacy_policy_and_terms_of_use) ?
                            <Text style={{
                                  marginBottom: 15,
                                  textAlign: "center"
                                }}>
                                  {show_modal.text}
                                {/* {modalVisible2.decisionVariableString.text} */}
                                {/* {!(free_tier && show_upgrade_account_button) ? show_modal.text : free_tier_error_text} */}
                            </Text>
                          :
                            <View style={{
                              marginBottom: 15,
                              textAlign: "center",
                              flexDirection:'row',
                            }}>
                                <Text style={{}}>
                                    {"By using the Toxeus Cloud Solver, you are agreeing to our "}
                                </Text>
                                      <Text style={{color:dodgerblue,fontWeight:'750'}} onPress={() => {
                                          WebBrowser.openBrowserAsync('https://www.toxeus.org/privacy-policy/');
                                        }}>
                                          {"Privacy Policy"}
                                      </Text>

                                <Text style={{
                                  
                                  }}>
                                    {" and our "}
                                </Text>
                                    <Text style={{color:dodgerblue,fontWeight:'750'}}  onPress={() => {
                                          WebBrowser.openBrowserAsync('https://www.toxeus.org/terms-of-use/');
                                      }}>{"Terms of Use"}</Text>
                            </View>

                        }

                       {/* <Pressable
                         style={{
                           borderRadius: 20,
                           padding: 10,
                           elevation: 2,  
                           backgroundColor: "#2196F3"
                         }}
                         onPress={() => {
                           // model_showing = false
                          //  var modal_visible2 = modalVisible2
                          //  modal_visible2.decisionVariableString.show = false
                          //  setModalVisible2(modal_visible2);
                          //  set_show_decision_variable_modal(false)
                           set_show_modal({show:false,text:""})
                         }}
                       >
                         <Text style={{
                             color: "white",
                             fontWeight: "bold",
                             textAlign: "center"
                           }}>Close</Text>
                       </Pressable> */}
                       <View style={{borderRadius:10,overflow:'hidden',flexDirection:'row'}}>
                        {show_activity_indicator_in_modal &&
                          <View style={{marginHorizontal:10,borderRadius:10,overflow:'hidden',}}>
                            <ActivityIndicator size="large" color={theme_blue_color} />
                          </View>
                        }
                        {show_upgrade_account_button &&
                            <View style={{        
                              marginHorizontal: 50,
                              flexDirection: 'row', // Arrange buttons horizontally
                              alignItems: 'center', // Center the buttons vertically
                          }}>
                              {!upgrade_account_running ? (
                              <>
                                  <View style={{borderRadius: 10, overflow: 'hidden'}}>
                                    <Button
                                      title="Upgrade Account"
                                      color={theme_blue_color}
                                      style={{marginHorizontal: 50}}
                                      // onPress={pickDocument}
                                      onPress={async()=>{
                                          await set_show_modal({show:true,text:"Loading Stripe Checkout..."})
                                          await set_show_navigate_to_documentation_button(false)
                                          await set_hide_close_modal_button(true)
                                          await upgrade_account()
        
                                          set_show_navigate_to_documentation_button(false)
        
                                          await set_show_modal({show:false,text:""})
                                          await set_hide_close_modal_button(false)
                                          set_show_upgrade_account_button(false)
                                      }}
                                    />
                                  </View>
                                <View style={{width: 50}} /> {/* Spacer to add space between buttons */}
                                  <View style={{borderRadius: 10, overflow: 'hidden'}}>
                                    <Button
                                      title="Sign Out"
                                      style={{marginHorizontal: 50}}
                                      color="grey"
                                      onPress={() => {
                                          signOut(); // Ensure that the `signOut` function is properly defined and handles user logout
                                      }}
                                    />
                                  </View>
                              </>)
                                :(
                                <ActivityIndicator size="large" color={theme_blue_color} />
                              )}
                            </View>
                        }
                        
                        {/* {show_change_api_key_button &&
                            <View style={{marginHorizontal:10,borderRadius:10,overflow:'hidden',}}>
                              {!isProcessing ?
                                    <Button
                                        title ="Change API Key"
                                        // color={APIKeyButtonColor}
                                        color={"red"}
                                        onPress={async()=>{
                                            await createApiKey()
                                            set_show_change_api_key_button(false)
                                            set_show_modal({show:false,text:""})
                                            
                                        }}
                                    />
                                :
                                <ActivityIndicator size="large" color={theme_blue_color} />
                              }
                            </View>
                        }
                        {show_delete_api_key_button &&
                            <View style={{marginHorizontal:10,borderRadius:10,overflow:'hidden',}}>
                              {!isProcessing ?
                                    <Button
                                        title ="Delete API Key"
                                        // color={APIKeyButtonColor}
                                        color={"red"}
                                        onPress={async()=>{
                                            await createApiKey("delete")
                                            set_show_delete_api_key_button(false)
                                            set_show_modal({show:false,text:""})
                                        }}
                                    />
                                :
                                <ActivityIndicator size="large" color={theme_blue_color} />
                              }
                            </View>
                        } */}
                        {show_privacy_policy_and_terms_of_use &&
                          <View>
                              {/* <View style={{alignSelf:'center', flexDirection:'row', alignItems:'stretch',left:'0%',width:'100%',justifyContent:'flex-end'}}>
                                    <TouchableOpacity style={{paddingBottom:10,marginHorizontal:15}} onPress={() => {
                                          WebBrowser.openBrowserAsync('https://www.toxeus.org/privacy-policy/');
                                        }}>
                                      <Text style={{color:theme_blue_color}}>{"Privacy Policy"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{paddingBottom:10,marginHorizontal:15}} onPress={() => {
                                          WebBrowser.openBrowserAsync('https://www.toxeus.org/terms-of-use/');
                                      }}>
                                    <Text style={{color:theme_blue_color}}>{"Terms of Use"}</Text>
                                  </TouchableOpacity>
                              </View> */}
                              <View style={{marginHorizontal:10,borderRadius:10,overflow:'hidden',}}>
                                  <Button
                                      title="I have read and I agree to the Privacy Policy and Terms of Use"
                                      color={theme_blue_color}
                                      // onPress={pickDocument}
                                      onPress={async()=>{
                                        
                                        // set_show_modal({show:false,text:""})
                                        // set_hide_close_modal_button(false)
                                        await set_show_privacy_policy_and_terms_of_use(false)
                                        await set_hide_close_modal_button(true)
                                        // await setisProcessing(true)
                                        await set_show_activity_indicator_in_modal(true)
                                        await set_show_modal({show:true,text:"Please wait a few seconds while we create your account..."})
                                        await create_stripe_user_account()
                                        await set_show_modal({show:false,text:""})
                                        await set_hide_close_modal_button(false)
                                        // await setisProcessing(false)
                                        await set_show_activity_indicator_in_modal(false)
                                        await set_show_navigate_to_documentation_button(false)
                                        await set_show_upgrade_account_button(false)

      
                                        

                                        

                                      }}
                                    />
                              </View>
                             </View>


                        }
                        {show_navigate_to_documentation_button &&
                        <View style={{marginHorizontal:10,borderRadius:10,overflow:'hidden',}}>
                            <Button
                                title="See Documentation"
                                color={theme_blue_color}
                                // onPress={pickDocument}
                                onPress={async()=>{
                                  if(upgrade_account_running == false) {
                                    set_show_navigate_to_documentation_button(false)
                                    set_show_upgrade_account_button(false)
                                    set_show_delete_api_key_button(false)
                                    set_show_change_api_key_button(false)
                                    set_show_modal({show:false,text:""})
                                    await Linking.openURL("https://docs.toxeus.org/")
                                  }
                                }}
                              />
                        </View>
                        }
                        {!hide_close_modal_button &&
                        <View style={{marginHorizontal:10,borderRadius:10,overflow:'hidden',}}>
                            <Button
                                title="Close"
                                color={theme_blue_color}
                                // onPress={pickDocument}
                                onPress={async()=>{
                                  if(upgrade_account_running == false) {
                                    set_show_navigate_to_documentation_button(false)

                                    set_show_upgrade_account_button(false)
                                    set_show_delete_api_key_button(false)
                                    set_show_change_api_key_button(false)
                                    
                                    set_show_modal({show:false,text:""})
                                  }
                                }}
                              />
                        </View>
                        }


                       </View>
                     </View>
                   </View>
                 </Modal>
                  </View>
        </View>
  //     : 
  //     ( show ?
  //       <View>
        
  //       </View>
  // : null)
  
      
      
      }
  { showAPIModal ? (

  
    <View style={{width:'100%',height:'100%', position:'absolute',top:'0%'}}>
          <View style={{overflow:'hidden',width:'50%'}}>
                   <Modal
                   animationType="fade"               
                   visible={show_modal.show}
                   onRequestClose={() => {
                     set_show_modal({show:false,text:""})
                   }}
                 >
                   <View style={{ flex: 1, justifyContent: "center", alignItems: "center", height:'100%', width:'100%', top:'0%', backgroundColor:'rgba(0,0,0,0.5)'}}>
                     <View style={{ margin: 20, backgroundColor: "white", borderRadius: 20, padding: 35, alignItems: "center", shadowColor: "#000", 
                     shadowOffset: { 
                                 width: 0,
                                 height: 2
                               },
                               shadowOpacity: 0.25,
                               shadowRadius: 4,
                               elevation: 5,
                                width:'50%',
                             }}>
                       <Text style={{
                               marginBottom: 15,
                               textAlign: "center"
                             }}>
                              {show_modal.text}
                             </Text>
                       <View style={{borderRadius:10,overflow:'hidden'}}>
                        <Button
                            title="Close"
                            color={theme_blue_color}
                            // onPress={pickDocument}
                            onPress={()=>{set_show_modal({show:false,text:""})}}
                          />
                       </View>
                     </View>
                   </View>
                 </Modal>
                  </View>
        </View>
        ) : null}
  
      
      
      








{/* ERROR ISNT HERE */}
{/* {(ProblemType != "account" && show) && */}
{(ProblemType == "optimization" || ProblemType=="sne" && ProblemType!="mysolutions" && show) &&
<View style={{width:'100%',justifyContent:"center", paddingTop:25, alignItems:'center'}}>

<View style={{
      width:"80%", 
      justifyContent:"center", 
      alignItems:'center',
      backgroundColor:'#FFFFFF',
      borderRadius:10,  
      marginTop:15, 
      // paddingBottom:15, 
      borderWidth:1,
      overflow:'hidden'
  }}>
  <View style={{
    width:'100%',
    alignItems:'center',
    padding:10,
    backgroundColor:theme_blue_color_lightest,
    overflow:'hidden',
  }}>
    <View style={{
                        // flexDirection:'row',
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        // width:'100%',
                        // marginTop:20,
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                {"Decision Variables ("+Array.from(dec_var_list_set.variables).length+")"}
                </Text>
                {showDecisionVariables.value &&
                <Text style={styles.subheading}>
                  {'The decision variables of the problem.'}
                  {/* {'The decision variables of the problem. For example: \nx, y, z'} */}
                </Text>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ async() => {
                  await setShow(false)
                  // console.log(showDecisionVariables.value)
                  // console.log("showDecisionVariables.value")
                  // console.log(showDecisionVariables.value)
                  if(showDecisionVariables.value){
                    // set_show_initial_search_region_button_text("+")
                    // if(isInitialSearchRegionValid && defaultSearchRegion.region.upper.is_parsable && defaultSearchRegion.region.lower.is_parsable){
                      // set_show_decision_variables_button_name("caretdown")
                      if(free_tier && (Array.from(dec_var_list_set.variables).length > 5)) {
                        set_show_upgrade_account_button(true)
                        set_show_modal({show:true,text:free_tier_error_text })
                      } else if((/[~`!#$%\^&*+=\-\[\]\\';/{}|\\":<>\?]/g.test(decisionVariableString.value))){
                        set_show_modal({show:true,text:decision_variables_error_text })
                      } else {
                        show_decision_variables_button_name.name = "caretdown"
                        // set_show_decision_variables_button_name(show_decision_variables_button_name)
                        showDecisionVariables.value=(!showDecisionVariables.value)
                        // setshowDecisionVariables(showDecisionVariables)
                        // console.log("showDecisionVariables.value")
                        // console.log(showDecisionVariables.value)
                      }

                  //     set_show_initial_search_region(!show_initial_search_region)
                  //   } else {
                  //     if(!isInitialSearchRegionValid){
                  //       set_show_modal({show:true,text:initial_search_region_bounds_error_text})
                  //     } else if(!defaultSearchRegion.region.lower.is_parsable) {
                  //       set_show_modal({show:true,text:"We are unable to interpret the Default Lower Bound for the Initial Search Region. Please ensure that the Default Lower Bound is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"})
                  //     } else if(!defaultSearchRegion.region.upper.is_parsable){
                  //       set_show_modal({show:true,text:"We are unable to interpret the Default Upper Bound for the Initial Search Region. Please ensure that the Default Upper Bound is expressed as a number such as \n\n150\n\nor\n\n1.5e2"})
                  //     }
                      
                  //   }
                    
                  } else {
                    // set_show_decision_variables_button_name("caretup")
                    show_decision_variables_button_name.name = "caretup"
                    // set_show_decision_variables_button_name(show_decision_variables_button_name)
                    showDecisionVariables.value=(!showDecisionVariables.value)
                    // setshowDecisionVariables(showDecisionVariables)

                  }
                  // console.log("showDecisionVariables.value")
                  // console.log(showDecisionVariables.value)
                  await setShow(true)
                  
                }}
            >
              {sub_show &&
                    <AntDesign name={show_decision_variables_button_name.name} size={32} color={show_decision_variables_button_color.color}></AntDesign>
              }
            </TouchableOpacity>


    </View>

      {/* <View style={styles.textview}>
          <Text style={styles.heading}>
            {"Decision Variables ("+Array.from(dec_var_list_set).length+")"}
          </Text>
          <Text style={styles.subheading}>
            {'The decision variables of the problem.'}
            {'The decision variables of the problem. For example: \nx, y, z'}
          </Text>
      </View> */}
{/* ERROR ISNT HERE */}
{showDecisionVariables.value &&

<View style={{width:'100%',justifyContent:'center',alignContent:'center', alignItems:'center'}}>
      {/* <View style={styles.textview}>
          <Text style={styles.subheading}>
            {'The decision variables of the problem.'}
            {'The decision variables of the problem. For example: \nx, y, z'}
          </Text>
      </View> */}
          <View 
          // key={decisionVariableString+String(modal_visible3)}
          style={{
                          flexDirection:'row',
                          justifyContent:'left',
                          alignContent:'center',
                          textAlign:'center',
                          textAlignVertical:'center',
                          alignItems:'center',
                          width:'80%',
                          marginTop:5,
            }}>
                <TextInput
                    style={{height: 40,
                      // margin: 12,
                      width:"100%",
                      borderWidth: 1,
                      padding: 10,
                      fontSize:18,
                      backgroundColor:'white'
                  }}
                      // defaultValue = {decisionVariableString}

                      blurOnSubmit={false}
                      // onChangeText={text => onDecisionVariablesChangeText(text)}
                      onChangeText = {text=>onDecisionVariablesChangeText2(text)}
                      onSubmitEditing={(text)=> onDecisionVariablesChangeText2(text)}
                      // onSubmitEditing={(text)=> on_decision_variables_submit_editing(text)}
                      // value={decisionVariableString}
                      // defaultValue={decisionVariableString.value}
                      value={decisionVariableString.value}
                      // value={decision_variable_string.value}
                    // placeholder='add decision variables'
                    placeholder='x, y, z'

                />
                {/* {((!/[~`!#$%\^&*+=\-\[\]\\';/{}|\\":<>\?]/g.test(decisionVariableString)) && false) &&
                          <View opacity={'100%'} >
                            <Feather style={{opacity:1}} name="alert-triangle" size={24} color="orange" />
                          </View>
                } */}
                {(((/[~`!#$%\^&*+=\-\[\]\\';/{}|\\":<>\?]/g.test(decisionVariableString.value)) || (free_tier && (Array.from(dec_var_list_set.variables).length > 5))) && sub_show)?(
                    <TouchableOpacity style={{marginHorizontal:5}} 
                        onPress={()=>{ 
                          if(free_tier && (Array.from(dec_var_list_set.variables).length > 5)) {
                            set_show_upgrade_account_button(true)
                            set_show_modal({show:true,text:free_tier_error_text })
                          } else {
                            set_show_modal({show:true,text:decision_variables_error_text })
                          }
                          
                        }}
                    >
                      <Feather  name="alert-triangle" size={24} color="orange"/>
                    </TouchableOpacity>
                  ):(<View style={{marginHorizontal:5}} 
                        // onPress={()=>{
                        //   set_show_modal({show:true,text:"Please ensure that the minimum distance is expressed as a non-negative number such as \n\n0\n\nor\n\n-0.015\n\nor\n\n-1.5e-2"})
                        // }}
                    >
                      <Feather  name="alert-triangle" size={24} color={theme_transparent}/>
                    </View>
                )}
</View>
                
                {/* {(modalVisible2.decisionVariableString.show) && */}
                {//(modal_visible3.decisionVariableString.show) &&
                    // <View>
                  
  }
                {/* {(typeof modalVisible['decisionVariableString'] != "undefined") &&  <Show_modal_function modal_to_show='decisionVariableString' setModalVisible={setModalVisible} modalVisible={()=>modalVisible}  /> } */}
                {/* {true &&  <Show_modal_function modal_to_show='decisionVariableString'/> } */}


                {/* {(modalVisible==true) &&  (show_modal_function('decisionVariableString') )} */}
                {/* <Ionicons  name="alert-triangle" size={24} color="yellow"></Ionicons> */}
          
  
          <View style={{
                        flexDirection:'row',
                        justifyContent:'left',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        width:'80%',
                        marginTop:5,
                      }}>
                    {(dec_var_list_set.variables.length == 0) &&
                        <View>
                          <Text style={{fontSize:16}}> {""} </Text>
                        </View>
                    }
                    <View style={{
                        overflow:'hidden',
                        flexDirection:'row', 
                        // flexDirection:
                        flexWrap:'wrap',
                        
                        justifyContent:'left',
                        
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        
                        alignItems:'center',
                        marginRight:15,
                        width:'100%',

                        }}>

                    {sub_show && Array.from(dec_var_list_set.variables).map((key,index) => {
                              if(key!=""){
                                // console.log(key,index)
                                  return <View style={{
                                    flexDirection:'row',
                                    justifyContent:'center',
                                    alignContent:'center',
                                    textAlign:'center',
                                    textAlignVertical:'center',
                                    alignItems:'center',
                                    // width:'100%',
                                    // marginTop:20,
                                    // borderWidth:1,
                                    // backgroundColor:'#F2F2F2',
                                    backgroundColor:'white',
                                    borderColor:theme_blue_color,
                                    // borderColor:theme_blue_color_light,
                                    borderWidth:1.5,
                                    borderRadius:10, 
                                    padding:5,
                                    marginRight:5,
                                    marginTop:5,
                                    overflow:'hidden',
                                    flexWrap:'wrap',
                                  }}
                                  key={key}>
                                  
                                    <View style={{flexWrap:'wrap'}}>
                                            <MathJaxSvg 
                                                // fontSize={2.5}
                                                fontSize={3.5}
                                                // color="red"
                                                fontWeight="bold"
                                                fontCache={true}
                                              >
                                              {/* {'$$ = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}.$$'} */}
                                              {'$$'+String(key)+'$$'}
                                                
                                              </MathJaxSvg>
                                        {/* <Text style={{fontSize:20}}> {" <= "+key+" <= "} </Text> */}
                                        {/* {(key.length>0)&& <Text style={{fontSize:20}}> {" <= "+key+" <= "} </Text>} */}
                                        {/* {(key.length==0)&& <Text style={{fontSize:20}}> {" <= "+key+" <= "} </Text>} */}
                                    </View>
                                    {/* <View style={{marginHorizontal:5}}> */}
                                        {/* <Ionicons name="md-checkmark-circle-outline" size={32} color="green" /> */}
                                          <TouchableOpacity style={{marginLeft:5}} 
                                              onPress={async()=>{
                                                  // await onDecisionVariableDelete(key)
                                                  await onDecisionVariableDelete2(key)
                                              }}>
                                              <Ionicons name="md-close-circle-outline" size={24} color="black" />
                                          </TouchableOpacity>
                                      {/* </View> */}
                                    
                                  </View>
                              }
                      })}
                      
                      </View>
                      {/* <View style={{
                          position:'absolute',
                          right:'0%',
                          // paddingHorizontal:5,
                          // marginHorizontal:5,
                          // height:'80%',
                          // textAlign:'left',
                          // textAlignVertical:'center',
                          // width:'4%',
                          // color:theme_blue_color,
                          // backgroundColor:theme_blue_color,
                          borderRadius:10,
                          overflow:'hidden',
                          // alignContent:'center',
                          // alignItems:'center',
                          // justifyContent:'center',
                          // flex:1
                          
                        }}>
                                  <Button 
                                  title={show_initial_search_region_button_text}
                                  // style={{borderRadius:10}}
                                  // color='#026BC7'
                                  // color=theme_blue_color
                                  color={show_initial_search_region_button_text_color}
                                  // style={{color:buttonColor,backgroundColor:buttonColor}}
                                  // color=buttonColor 
                                  onPress={ () => {
                                    if(show_initial_search_region){
                                      // set_show_initial_search_region_button_text("+")
                                      if(isInitialSearchRegionValid && defaultSearchRegion.region.upper.is_parsable && defaultSearchRegion.region.lower.is_parsable){
                                        set_show_initial_search_region_button_text("Show all decision variables & The Initial Search Region")
                                        set_show_initial_search_region_button_text_color(theme_blue_color)
                                        set_initial_search_region_header_border_width(1)
                                        set_show_initial_search_region(!show_initial_search_region)
                                      } else {
                                        if(!isInitialSearchRegionValid){
                                          set_show_modal({show:true,text:initial_search_region_bounds_error_text})
                                        } else if(!defaultSearchRegion.region.lower.is_parsable) {
                                          set_show_modal({show:true,text:"We are unable to interpret the Default Lower Bound for the Initial Search Region. Please ensure that the Default Lower Bound is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"})
                                        } else if(!defaultSearchRegion.region.upper.is_parsable){
                                          set_show_modal({show:true,text:"We are unable to interpret the Default Upper Bound for the Initial Search Region. Please ensure that the Default Upper Bound is expressed as a number such as \n\n150\n\nor\n\n1.5e2"})
                                        }
                                        
                                      }
                                      
                                    } else {
                                      // set_show_initial_search_region_button_text("-")
                                      set_show_initial_search_region_button_text("Hide Initial Search Region")
                                      set_initial_search_region_header_border_width(0)
                                      set_show_initial_search_region(!show_initial_search_region)
                                    }
                                    
                                  }} />

                        </View> */}
                  </View>
                  
            </View>
            
  }
</View>
</View>
</View>
  }


{/* ERROR ISNT HERE */}
  {((ProblemType == "optimization") && (show)) && 
  <View style={{
    width:"80%", 
    justifyContent:"center", 
    marginTop:15, 
    alignItems:'center',
    borderRadius:10,
    overflow:'hidden',
    backgroundColor:'white',
    borderWidth:1,
  }}>
<View style={{
  width:'100%',
  alignItems:'center',
  padding:10,
  // paddingBottom:15, 
  backgroundColor:theme_blue_color_lightest,
  overflow:'hidden'
  }}>
    <View style={{
                        // flexDirection:'row',
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        // width:'100%',
                        // marginTop:20,
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                {"Objective Function"}
                </Text>
                {show_objective_function &&
                <Text style={styles.subheading}>
                  {'The objective function to be minimized. For example: \n(1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2'}
                  {/* {'The decision variables of the problem. For example: \nx, y, z'} */}
                </Text>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
      
    }}>
          <TouchableOpacity style={{width:'100%', }}
                onPress={ () => {
                  
                  if(show_objective_function){
                    // set_show_initial_search_region_button_text("+")
                    // if(isInitialSearchRegionValid && defaultSearchRegion.region.upper.is_parsable && defaultSearchRegion.region.lower.is_parsable){
                      
                      set_show_objective_function_button_name("caretdown")
                      set_show_objective_function(!show_objective_function)
                      // show_objective_function.value = (!show_objective_function.value)
                  //     set_show_initial_search_region(!show_initial_search_region)
                  //   } else {
                  //     if(!isInitialSearchRegionValid){
                  //       set_show_modal({show:true,text:initial_search_region_bounds_error_text})
                  //     } else if(!defaultSearchRegion.region.lower.is_parsable) {
                  //       set_show_modal({show:true,text:"We are unable to interpret the Default Lower Bound for the Initial Search Region. Please ensure that the Default Lower Bound is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"})
                  //     } else if(!defaultSearchRegion.region.upper.is_parsable){
                  //       set_show_modal({show:true,text:"We are unable to interpret the Default Upper Bound for the Initial Search Region. Please ensure that the Default Upper Bound is expressed as a number such as \n\n150\n\nor\n\n1.5e2"})
                  //     }
                      
                  //   }
                    
                  } else {
                    set_show_objective_function_button_name("caretup")
                    
                    set_show_objective_function(!show_objective_function)
                    // show_objective_function.value = (!show_objective_function.value)
                  }
                  
                }}
            >
                    <AntDesign name={show_objective_function_button_name} size={32} color={show_objective_function_button_color}></AntDesign>
            </TouchableOpacity>


    </View>

{(show_objective_function && show) && 

    // <View style={styles.textview}>
    //   <Text style={styles.topheading}>
    //     Objective Function
    //   </Text>
    //   <Text style={styles.subheading}>
    //     {'The objective function to be minimized. For example: \n(1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2'}
    //   </Text>
    // </View>
    <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',textAlign:'center', alignItems:'center',width:'80%',}}>
        <View style={{top:'0%',paddingRight:15}}>
          <MathJaxSvg 
              fontSize={4}
              // color="red"
              // fontCache={true}
            >
              
            {/* {LatexString} */}
            {/* {"$$"+"\\min_{"+(Array.from(dec_var_list_set.variables).join(","))+"}:"+"$$"} */}
            {/* {"$$"+"\\min_{x\\in\\mathbb{R}^"+(Array.from(dec_var_list_set.variables).length)+"}:"+"$$"} */}
            {"$$"+"minimize:"+"$$"}
            </MathJaxSvg> 
        </View>
        <TextInput
        style={{height: 40,
          backgroundColor:'white',
          width:"80%",
          // margin: 12,
          borderWidth: 1,
          padding: 10,
        fontSize:18}}
          onChangeText={async(text)=> {
            // objectiveFunction = '$$'+text+'$$'
            setobjectiveFunction(text)
            // keyy = keyy + 1
            // var a = '$$'+text+'$$'
            // setLatexString(a)
            
            // this.setState({pagename:text})
            // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
            //   this.setState({ create_disabled: true, create_opacity: 0.45})
            // } else {
            //   this.setState({ create_disabled: false, create_opacity: 1})
            // }
          }}
        value={objectiveFunction}
        placeholder="(1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2"
        // keyboardType="numeric"
      />
      </View>
        }


        {/* <MathJaxSvg 
          fontSize={8}
          // color="red"
          fontCache={true}
        >
          
        {LatexString}
          
        </MathJaxSvg> */}

</View>
</View>
  }























{/* ERROR IS HERE */}
{/* {(ProblemType != "account" && show) && <View style={{width:"80%", justifyContent:"center", alignItems:'center'}}> */}
{( (ProblemType == "optimization") || (ProblemType=="sne") && show) && 
<View style={{width:"80%", justifyContent:"center", alignItems:'center'}}>

<View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  // paddingBottom:15, 
  borderRadius:10,
  borderWidth:1,
  backgroundColor:'white',
  overflow:'hidden',}}>
    <View style={{backgroundColor:theme_blue_color_lightest,overflow:'hidden',padding:10,height:'100%',width:'100%'}}>
    <View style={{
                        // flexDirection:'row',
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        // width:'100%',
                        // marginTop:20,
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                    {/* {"Constraints ("+textInputValuess.length+")"} */}
                    {constraints_section_title.value}
                </Text>
                {show_constraints.value &&
                <Text style={styles.subheading}>
                  {constraints_section_description}
                  {/* {'The constraints of the optimization problem. For example: \n-0.5 <= x\nx <= 3'} */}
                  {/* {'The decision variables of the problem. For example: \nx, y, z'} */}
                </Text>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ async() => {
                  await setShow(false)
                  if(show_constraints.value){
                    // set_show_initial_search_region_button_text("+")
                    // if(isInitialSearchRegionValid && defaultSearchRegion.region.upper.is_parsable && defaultSearchRegion.region.lower.is_parsable){
                      // set_show_constraints_button_name("caretdown")
                      if(free_tier && (textInputValuess.values.length > 5)) {
                        set_show_upgrade_account_button(true)
                        set_show_modal({show:true,text:free_tier_error_text })
                      } 
                      if(all_elements_true_checker(inputsValid.values)) {
                        show_constraints_button_name.name="caretdown"
                        // set_show_constraints(!show_constraints)
                        show_constraints.value = (!show_constraints.value)
                      } else {
                        set_show_modal({show:true,text:constraints_error_text})

                      }

                  //     set_show_initial_search_region(!show_initial_search_region)
                  //   } else {
                  //     if(!isInitialSearchRegionValid){
                  //       set_show_modal({show:true,text:initial_search_region_bounds_error_text})
                  //     } else if(!defaultSearchRegion.region.lower.is_parsable) {
                  //       set_show_modal({show:true,text:"We are unable to interpret the Default Lower Bound for the Initial Search Region. Please ensure that the Default Lower Bound is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"})
                  //     } else if(!defaultSearchRegion.region.upper.is_parsable){
                  //       set_show_modal({show:true,text:"We are unable to interpret the Default Upper Bound for the Initial Search Region. Please ensure that the Default Upper Bound is expressed as a number such as \n\n150\n\nor\n\n1.5e2"})
                  //     }
                      
                  //   }
                    
                  } else {
                    // set_show_constraints_button_name("caretup")
                    show_constraints_button_name.name="caretup"
                    show_constraints.value = (!show_constraints.value)
                    // set_show_constraints(!show_constraints)
                  }
                  await setShow(true)
                }}
            >
                  {sub_show &&
                    <AntDesign name={show_constraints_button_name.name} size={32} color={show_constraints_button_color.color}></AntDesign>
                  }
            </TouchableOpacity>


    </View>











        {/* <View style={styles.textview}>
                <Text style={styles.topheading}>
                  {"Constraints ("+textInputValuess.length+")"}
                </Text>
          <Text style={styles.subheading}>
            {'The constraints to the optimization problem. For example: \n-0.5 <= x\nx <= 3\n-0.5 <= y\ny <= 3\n-0.5 <= z\nz <= 3\ny = z\nx <= y\nx <= z'}
            {'The constraints of the optimization problem. For example: \n-0.5 <= x\nx <= 3'}
            {'The system of nonlinear equations and inequalities to be solved. For example: \n(1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2'}
          </Text>
        </View> */}

        {/* {show && */}
        {show_constraints.value &&
        <View //key={textInputValuess.length} 
              style={{
                width:'100%',
                justifyContent:'center',
                alignItems:'center'
              }}>
                  <View style={{
                    width:'75%',
                    justifyContent:'space-evenly',
                    alignContent:'center',
                    alignItems:'center',
                    flexDirection:'row',
                    
                  }}>
                    <View style={{borderRadius:10, overflow:'hidden',width:'40%',left:'0%'}}>
                        <Button 
                        title='Add Constraint' 
                        // color='#026BC7'
                        color={theme_blue_color}
                        // style={{color:buttonColor,backgroundColor:buttonColor}}
                        // color=buttonColor 
                        onPress={async () => {
                          await setShow(false)
                          await inputsValid.values.push(false)
                          await textInputValuess.values.push("")
                          constraints_section_title.value="Constraints ("+textInputValuess.values.length+")"
                          if(all_elements_true_checker(inputsValid.values)){
                              show_constraints_button_color.color=theme_blue_color
                          } else {
                              show_constraints_button_color.color="grey"
                          }
                          if(free_tier && (textInputValuess.values.length > 5)) {
                            set_show_upgrade_account_button(true)
                            show_constraints_button_color.color="grey"
                            // set_show_modal({show:true,text:free_tier_error_text })
                          } 
                          
                          // // var texttt = textInputValuess
                          // var texttt = textInputValuess.values
                          // // console.log(textInputValuess)
                          // await texttt.push("")
                          // // console.log(textInputValuess)
                          // textInputValuess.values = texttt
                          // constraints_section_title.value="Constraints ("+textInputValuess.values.length+")"
                          // // settextInputValuess(texttt)
                          // // var iconss = inputsValid
                          // var iconss = inputsValid.values
                          // // console.log(textInputValuess)
                          // await iconss.push(false)
                          // // console.log(textInputValuess)
                          // inputsValid.values = iconss
                          // // await setInputsValid(iconss)
                          await setShow(true)
                        }} />
                    </View>
                    {/* <View style={{borderRadius:10, overflow:'hidden',}}>
                        <Button 
                        title='Remove Constraint'
                        color={theme_blue_color}
                        onPress={async () => {
                          await setShow(false)
                          
                          textInputValuess.values = textInputValuess.values.slice(0,-1)
                          constraints_section_title.value="Constraints ("+textInputValuess.values.length+")"
                          inputsValid.values = inputsValid.values.slice(0,-1)



                          // // var texttt = textInputValuess
                          // var texttt = textInputValuess.values
                          // texttt = texttt.slice(0,-1)
                          // // if(texttt.length==1){
                          // //   texttt=[]
                          // // }
                          // textInputValuess.values = texttt
                          // constraints_section_title.value="Constraints ("+textInputValuess.values.length+")"
                          // // await settextInputValuess(texttt)
                          // // var iconss = inputsValid
                          // var iconss = inputsValid.values
                          // iconss = iconss.slice(0,-1)
                          // inputsValid.values=iconss
                          // // await setInputsValid(iconss)
                          await setShow(true)
                        }} />
                      </View> */}
                      <View style={{width:'20%'}}/>
                      <View style={{borderRadius:10, overflow:'hidden',width:'40%'}}>
                        <Button 
                        title={edit_constraints_button_name.name}
                        color={theme_blue_color}
                        onPress={async () => {
                          await setShow(false)
                          edit_constraints.value = (!edit_constraints.value)
                          if(!edit_constraints.value){
                            edit_constraints_button_name.name="Edit Constraints"
                          } else {
                            edit_constraints_button_name.name="Stop Editing"
                          }
                          
                          // textInputValuess.values = textInputValuess.values.slice(0,-1)
                          // constraints_section_title.value="Constraints ("+textInputValuess.values.length+")"
                          // inputsValid.values = inputsValid.values.slice(0,-1)

                          await setShow(true)
                        }} />
                      </View>
                  </View>

                  
                  
                  {textInputValuess.values.map((text, key) => {
                      // return <TextInput
                      //   style={{height: 40, marginVertical: 10, borderColor: this.handleBorderColor(index), borderWidth: 1}}
                      //   onChangeText={text => this.onChangeText(text, index)} 
                      //   value={this.state.textArray[index]}
                      //   placeholder={`placeholder for ${index}`}
                      //   onFocus={() => this.setState({focusedIndex: index})}
                      //   onBlur={() => this.setState({focusedIndex: null})}
                      // />
                      return <View style={{
                        flexDirection:'row',
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        alignItems:'center',
                        width:'80%',
                        marginTop:20,
                      }}
                      key={key}>
                        
                          <View style={{marginHorizontal:5,opacity:'100%'}}>
                            {/* <Ionicons name="md-checkmark-circle-outline" size={32} color="green" /> */}
                            {(sub_show && ((!inputsValid.values[key])  || (free_tier && (textInputValuess.values.length > 5)))) ?
                                <TouchableOpacity 
                                onPress={()=>{
                                  if((free_tier && (textInputValuess.values.length > 5))) {
                                    set_show_upgrade_account_button(true)
                                    set_show_modal({show:true,text:free_tier_error_text })
                                  } else {
                                    set_show_modal({show:true,text:'"Constraint '+(key+1)+'" is formatted in a way that we can not interpret.'})
                                  }
                                    
                                 }}>
                                    <Feather name="alert-triangle" size={32} color={"orange"} />
                                </TouchableOpacity>
                            :
                              <View >
                                <Feather name="alert-triangle" size={32} color={theme_transparent} />
                              </View>
                            }
                            
                          </View>
                        
                        <Text style={{fontSize:20}}> {"Constraint "+(key+1)+": "} </Text>
                        <TextInput 
                            style={styles.textinput} 
                            // key={key} 
                            // value={inputData.slice(key).text}
                            // defaultValue={inputData[textInput.length].text}
                            // value={inputData[key].text}
                            // defaultValue = {textInputValuess[key]}
                            value = {textInputValuess.values[key]}
                            // defaultValue = {textInputValuess.values[key]}
                            onFocus={() => setfocusedIndex(key)}
                            // onBlur={() => setfocusedIndex(null)}
                            // onChangeText={(text)=>{
                            //   // as there are going to be a lot of setState calls
                            //   // we need access the prevState before we set the next state.
                            //   // setShow(false)
                            
                              
                            //   var dataa = textInputValuess
                            //   dataa[key] = text
                            //   settextInputValues(dataa)
                            
                            //   // setShow(true)
                            //   // this.setState(prevState => {
                            //   //   prevState.textArray[index] = text
                            //   //   return {
                            //   //     textArray: prevState.textArray
                            //   //   }
                            //   // }, () => console.log(this.state.textArray))
                            // }}
                            onChangeText={textd => onchhhangetext(textd,key)}
                            // onChangeText={async(text) => {
                            //   // console.log(text)
                            //   // console.log(inputData.slice(key).values())
                            //   // console.log("key")
                            //   // console.log(key)
                            //   // console.log("inputData[key].text")
                            //   // console.log(inputData[key].text)
                            //   // inputData[key].text = text
                            //   // setTextInputText(inputData)
                            //   // var textt = text
                            //   var dataArray = inputData
                            //   console.log(dataArray[key].text)
                            //   dataArray[key].text = text
                            //   console.log(dataArray[key].text)
                            //   await setTextInputText(dataArray)
                            //   console.log(inputData[key].text)
                            //   await setShow(false)
                            //   setShow(true)
                            //   // console.log()
                            //   // dataArray.forEach(element => {
                            //   //     if (element.index === key ){
                            //   //       element.text = text;
                            //   //     }
                            //   // })
                            //   // await setTextInputText(dataArray)
                            //   // console.log(inputData) 
                            //   // addValues(textt, key)
                            //   // console.log(inputData[key].text)
                            // }} 
                            // onChangeText={(text)=>{
                            //   textInputText[key] = text
                            //   console.log(textInputText.toString())
                            // }}  
                            
                        />
                        {/* {sub_show && (!edit_constraints.value) && inputsValid.values[key] && */}
                        {(sub_show && !edit_constraints.value) &&
                          <View style={{marginHorizontal:5,opacity:'100%'}}>
                            {/* <Ionicons name="md-checkmark-circle-outline" size={32} color="green" /> */}
                            <Ionicons name="md-checkmark-circle-outline" size={32} color={theme_transparent} />
                          </View>
                        }
                        {/* {((sub_show && !inputsValid.values[key]) || edit_constraints.value) && */}
                        {(sub_show && edit_constraints.value) &&
                        <TouchableOpacity style={{marginHorizontal:5}} 
                              onPress={async()=>{
                                  // if(edit_constraints.value){
                                    await setShow(false)
                                    if(textInputValuess.values.length ==1){
                                          
                                          var texttt = textInputValuess.values
                                          texttt = texttt.slice(0,-1)
                                          textInputValuess.values = texttt
                                          constraints_section_title.value="Constraints ("+textInputValuess.values.length+")"
                                          // settextInputValuess(texttt)
                                          var iconss = inputsValid.values
                                          iconss = iconss.slice(0,-1)
                                          inputsValid.values=iconss
                                          // await setInputsValid(iconss)
                                          
                                    } else {
                                          // var texttt = textInputValuess
                                          var texttt = textInputValuess.values
                                          texttt.splice(key,1)
                                          textInputValuess.values = texttt
                                          constraints_section_title.value="Constraints ("+textInputValuess.values.length+")"
                                          // await settextInputValuess(texttt)
                                          // var iconss = inputsValid
                                          var iconss = inputsValid.values
                                          iconss.splice(key,1)
                                          inputsValid.values=iconss
                                          // await setInputsValid(iconss)
                                    }
                                    if(all_elements_true_checker(inputsValid.values)){
                                      show_constraints_button_color.color=theme_blue_color
                                    } else {
                                        show_constraints_button_color.color="grey"
                                    }
                                    if(free_tier && (textInputValuess.values.length > 5)) {
                                      set_show_upgrade_account_button(true)
                                      show_constraints_button_color.color="grey"
                                      // set_show_modal({show:true,text:free_tier_error_text })
                                    } 
                                    await setShow(true)
                                  // } else {
                                  //   set_show_modal({show:true,text:'"Constraint '+(key+1)+'" is formatted in a way that we can not interpret.'})
                                  // }

                              }}>
                                  <Ionicons name="md-close-circle-outline" size={32} color="red" />
                              </TouchableOpacity>
                                
                              }
                      </View>
                  })} 
                  {/* <Text >{textInputValuess}</Text> */}
              </View>
  }
              {/* {show &&<View></View>} */}
</View>
</View>
             






























{/* <View style={styles.textview}>
      <Text style={styles.heading}>
        Bounds
      </Text>
      <Text style={styles.subheading}>
        {'The upper and lower limits for each decision variable / dimension. For example: \n"x":[-0.5,3], "y":[-0.5,3], "z":[-0.5,3]\nNote that this format must be followed strictly. '}
      </Text>
  </View>
        <TextInput
        style={{height: 40,
          // margin: 12,
          width:"80%",
          borderWidth: 1,
          padding: 10,
        fontSize:18}}
          onChangeText={(text)=> {
            // objectiveFunction = '$$'+text+'$$'
            // keyy = keyy + 1
            
            var texta = "{"+text+"}"
            setLims(texta)
            setCustomLims(true)
            // var a = '$$'+text+'$$'
            // setLims(textt)
            // setobjectiveFunction()
            // this.setState({pagename:text})
            // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
            //   this.setState({ create_disabled: true, create_opacity: 0.45})
            // } else {
            //   this.setState({ create_disabled: false, create_opacity: 1})
            // }
          }}
        // value={objectiveFunction}
        placeholder='"x":[-0.5,3], "y":[-0.5,3], "z":[-0.5,3]'
        // keyboardType="numeric"
      /> */}









<View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  borderRadius:10,
  borderWidth:1,
  backgroundColor:'white',
  overflow:'hidden',}}>
    <View style={{
      padding:10,
      backgroundColor:theme_blue_color_lightest,
      overflow:'hidden',
      width:'100%',
      height:'100%'
    }}>
    <View style={{
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                    {"Number of Starting Points"}
                </Text>
                {show_starting_points.value &&
                <Text style={styles.subheading}>
                  {n_starting_points_section_description}
                  {/* {'The number of starting points within the Initial Search Region to perform optimization from.'} */}
                  {/* {'The decision variables of the problem. For example: \nx, y, z'} */}
                </Text>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ async() => {
                  await setShow(false)
                  if(show_starting_points.value){
                    
                    if(free_tier && (nPoints.value > 5)) {
                      set_show_upgrade_account_button(true)
                      show_starting_points_button_color.color = "grey"
                    }
                    if(nPoints_warning.value){
                        set_show_modal({show:true,text:n_starting_points_error_text })
                    } else {
                        show_starting_points.value = (!show_starting_points.value)
                        show_starting_points_button_name.name = "caretdown"   
                    }  
               
                  } else {
                    show_starting_points.value = (!show_starting_points.value)
                    show_starting_points_button_name.name = "caretup"
                  }
                  await setShow(true)
                  // if(show_starting_points){
                  //     set_show_starting_points_button_name("caretdown")
                  //     set_show_starting_points(!show_starting_points)                    
                  // } else {
                  //   set_show_starting_points_button_name("caretup")
                  //   set_show_starting_points(!show_starting_points)
                  // }
                }}
            >
                  {sub_show &&
                    <AntDesign name={show_starting_points_button_name.name} size={32} color={show_starting_points_button_color.color}></AntDesign>
                  }
            </TouchableOpacity>
    </View>



{show_starting_points.value && 
<View>
  <View style={styles.textview}>
        {/* <Text  style={styles.heading}>
          Maximum Number of Starting Points
        </Text>
        <Text style={styles.subheading}>
            {'The maximum number of uniformly distributed starting points that local search will start from.'}
        </Text> */}
        {/* <Text  style={styles.heading}>
          Maximum Number of Solutions
        </Text>
        <Text style={styles.subheading}>
            {'The maximum number of solutions that the solver will attempt to return.'}
        </Text> */}
    </View>
        <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
              <TextInput
                style={{height: 40,
                  // margin: 12,
                  width:"80%",
                  backgroundColor:'white',
                  borderWidth: 1,
                  padding: 10,
                fontSize:18}}
                  onChangeText={async(text)=> {
                    // objectiveFunction = '$$'+text+'$$'
                    // keyy = keyy + 1
                    // textt = JSON.parse(text)
                    // var a = '$$'+text+'$$'
                    // setnPoints(Number(text))
                    var nPoints_processed = text.replace(/[^0-9e-]/g, '')
                    if((nPoints_processed == "") || isNaN(Number(nPoints_processed)) || (Number(nPoints_processed)<1)){
                      // BELOW LINES ARE COMMENTED OUT BECAUSE BY HAVING THE DEFAULT STARTING POINTS AT
                      // 10 EVEN IF THEY TRY TO MAKE THE TEXT INPUT AN EMPTY STRING, THEY CANT
                      nPoints_warning.value=true
                      show_starting_points_button_color.color = "grey"
                    } else {
                      nPoints_warning.value=false
                      show_starting_points_button_color.color = theme_blue_color

                    }
                    nPoints.value = nPoints_processed
                    if(free_tier && (nPoints.value > 5)) {
                      set_show_upgrade_account_button(true)
                      show_starting_points_button_color.color = "grey"
                      nPoints_warning.value=true
                      // set_show_modal({show:true,text:free_tier_error_text })
                    }
                    setnPoints(nPoints)
                    // setobjectiveFunction()
                    // this.setState({pagename:text})
                    // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
                    //   this.setState({ create_disabled: true, create_opacity: 0.45})
                    // } else {
                    //   this.setState({ create_disabled: false, create_opacity: 1})
                    // }
                    await set_sub_show(false)
                    await set_sub_show(true)
                  }}
                // value={objectiveFunction}
                value={nPoints.value}
                // defaultValue={nPoints.value}
                // placeholder="10"
                keyboardType="numeric"
              />
              {nPoints_warning.value?(<TouchableOpacity style={{marginHorizontal:5}} 
                        onPress={()=>{
                          if(free_tier && (nPoints.value > 5)) {
                            set_show_upgrade_account_button(true)
                            set_show_modal({show:true,text:free_tier_error_text })
                          } else {
                            set_show_modal({show:true,text:n_starting_points_error_text })
                          }
                         
                        }}
                    >
                      <Feather  name="alert-triangle" size={32} color="orange"/>
                </TouchableOpacity>):(<View style={{marginHorizontal:5}} 
                    >
                      <Feather  name="alert-triangle" size={32} color={theme_transparent} />
                </View>)
              }
          </View>
      </View>
}
</View>
</View>




{/* {(show_initial_search_region)&& */}
<View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  // padding:10,
  // paddingBottom:15, 
  borderRadius:10,
  borderWidth:1,
  backgroundColor:'white',
  overflow:'hidden',
  // opacity:0.75,
  // borderWidth:(Math.abs(initial_search_region_header_border_width-1))
  // ,borderColor:'red'
  }}>  
      <View style={{
      padding:10,
      backgroundColor:theme_blue_color_lightest,
      overflow:'hidden',
      width:'100%',
      height:'100%'
    }}>
{/* {(show_initial_search_region)&&
            <View style={{
              position:'absolute',
              right:'0%',
              // height:'80%',
              // textAlign:'left',
              // textAlignVertical:'center',
              // width:'4%',
              // color:theme_blue_color,
              // backgroundColor:theme_blue_color,
              borderRadius:10,
              overflow:'hidden',
              // alignContent:'center',
              // alignItems:'center',
              // justifyContent:'center',
              // flex:1
              
            }}>
                        <Button 
                        title={edit_initial_search_region_button_text}
                        // style={{borderRadius:10}}
                        // color='#026BC7'
                        color={theme_blue_color}
                        // style={{color:buttonColor,backgroundColor:buttonColor}}
                        // color=buttonColor 
                        onPress={async () => {
                          if(edit_initial_search_region){
                            set_edit_initial_search_region_button_text("Edit")
                            set_edit_initial_search_region(false)
                          } else {
                            set_edit_initial_search_region_button_text("Stop Editing")
                            set_edit_initial_search_region(true)
                          }
                        }} />

              </View>
} */}
    <View style={{
                        flexDirection:'row',
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        // width:'100%',
                        // marginTop:20,
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                  {initial_search_region_header_text}
                  {/* Initial Search Region */}
                </Text>
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      
      // height:'80%',
      // textAlign:'left',
      // textAlignVertical:'center',
      // width:'4%',
      // color:theme_blue_color,
      // backgroundColor:theme_blue_color,
      borderRadius:10,
      overflow:'hidden',
      // alignContent:'center',
      // alignItems:'center',
      // justifyContent:'center',
      // flex:1
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                //style={{width:'100%',alignItems:'center',justifyContent:'center',borderWidth:1,marginVertical:10}}
                onPress={ async() => {
                  await setShow(false)
                  if(show_initial_search_region.value){
                    // set_show_initial_search_region_button_text("+")
                    if(isInitialSearchRegionValid.value && defaultSearchRegion.region.upper.is_parsable && defaultSearchRegion.region.lower.is_parsable){
                      // set_initial_search_region_button_name("caretdown")
                      initial_search_region_button_name.name="caretdown"
                      // set_show_initial_search_region_button_text("Show all decision variables & The Initial Search Region")
                      // set_show_initial_search_region_button_text_color(theme_blue_color)
                      // set_initial_search_region_header_border_width(1)
                      // set_show_initial_search_region(!show_initial_search_region.value)
                      show_initial_search_region.value = !show_initial_search_region.value
                    } else {
                      if(!isInitialSearchRegionValid.value){
                        set_show_modal({show:true,text:initial_search_region_bounds_error_text})
                      } else if(!defaultSearchRegion.region.lower.is_parsable) {
                        set_show_modal({show:true,text:default_lower_bound_error_text})
                        // set_show_modal({show:true,text:"We are unable to interpret the Default Lower Bound for the Initial Search Region. Please ensure that the Default Lower Bound is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"})
                      } else if(!defaultSearchRegion.region.upper.is_parsable){
                        set_show_modal({show:true,text:default_upper_bound_error_text })
                        // set_show_modal({show:true,text:"We are unable to interpret the Default Upper Bound for the Initial Search Region. Please ensure that the Default Upper Bound is expressed as a number such as \n\n150\n\nor\n\n1.5e2"})
                      }
                    }
                  } else {
                    // set_show_initial_search_region_button_text("-")
                    // set_initial_search_region_button_name("caretup")
                    initial_search_region_button_name.name="caretup"
                    // set_show_initial_search_region_button_text("Hide Initial Search Region")
                    // set_initial_search_region_header_border_width(0)
                    // set_show_initial_search_region(!show_initial_search_region)
                    show_initial_search_region.value = !show_initial_search_region.value
                  }
                  await setShow(true)
                  
                }}
            >
                    <AntDesign name={initial_search_region_button_name.name} size={32} color={show_initial_search_region_button_text_color.color}></AntDesign>
            </TouchableOpacity>


    </View>






{show_initial_search_region.value &&
<View style={{
    width:'75%',
    justifyContent:"center",
    alignItems:'center',
    textAlign:'center',
    // backgroundColor:'#F2F2F2',
    
    // borderWidth:initial_search_region_header_border_width,
    borderRadius:10,
    // backgroundColor:initial_search_region_header_color,
  }}>
  {/* <TouchableOpacity>
      <Text style={styles.topheading}>
        {show_initial_search_region_button_text}
      </Text>

  </TouchableOpacity> */}
          {
            // There should be a toggle button to show this section or not
          // textInputValuess.length==0 &&
          
          // <TouchableOpacity style={{width:'100%'}}
          // //style={{width:'100%',alignItems:'center',justifyContent:'center',borderWidth:1,marginVertical:10}}
          // onPress={ () => {
            
          //   if(show_initial_search_region){
          //     // set_show_initial_search_region_button_text("+")
          //     if(isInitialSearchRegionValid && defaultSearchRegion.region.upper.is_parsable && defaultSearchRegion.region.lower.is_parsable){
          //       set_initial_search_region_button_name("caretdown")
          //       // set_show_initial_search_region_button_text("Show all decision variables & The Initial Search Region")
          //       // set_show_initial_search_region_button_text_color(theme_blue_color)
          //       set_initial_search_region_header_border_width(1)
          //       set_show_initial_search_region(!show_initial_search_region)
          //     } else {
          //       if(!isInitialSearchRegionValid){
          //         set_show_modal({show:true,text:initial_search_region_bounds_error_text})
          //       } else if(!defaultSearchRegion.region.lower.is_parsable) {
          //         set_show_modal({show:true,text:"We are unable to interpret the Default Lower Bound for the Initial Search Region. Please ensure that the Default Lower Bound is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"})
          //       } else if(!defaultSearchRegion.region.upper.is_parsable){
          //         set_show_modal({show:true,text:"We are unable to interpret the Default Upper Bound for the Initial Search Region. Please ensure that the Default Upper Bound is expressed as a number such as \n\n150\n\nor\n\n1.5e2"})
          //       }
                
          //     }
              
          //   } else {
          //     // set_show_initial_search_region_button_text("-")
          //     set_initial_search_region_button_name("caretup")
          //     set_show_initial_search_region_button_text("Hide Initial Search Region")
          //     set_initial_search_region_header_border_width(0)
          //     set_show_initial_search_region(!show_initial_search_region)
          //   }
            
          // }}
          // >
            // <View style={{
            //             flexDirection:'row',
            //             justifyContent:'center',
            //             alignContent:'center',
            //             textAlign:'center',
            //             textAlignVertical:'center',
            //             alignItems:'center',
            //             width:'100%',
            //             // marginTop:20,
            //             justifyContent:'center',
            //           }}
            //   >
            //     {/* <View style={{
            //       position:'absolute',
            //       left:'0%',
            //       // height:'80%',
            //       // textAlign:'left',
            //       // textAlignVertical:'center',
            //       width:'4%',
            //       color:theme_blue_color,
            //       backgroundColor:theme_blue_color,
            //       borderRadius:10,
            //       alignContent:'center',
            //       alignItems:'center',
            //       justifyContent:'center',
            //       flex:1
            //     }}>
            //       <Text style={{
                            
            //                 // alignSelf:'center',
            //                 fontSize:24, 
            //                 // width:"100%", 
            //                 // height:'100%',
            //                 // textAlign:'center',
            //                 // textAlignVertical:'center',
            //                 fontWeight:'bold',
            //                 // color:theme_blue_color
            //                 color:'white'
            //                 // color:'blue'
            //                 // color: show_initial_search_region_button_text_color
            //                 // color:{show_initial_search_region_button_text_color}
            //         }}>
                      
            //           {show_initial_search_region_button_text}
            //         </Text>
            //     </View> */}
            //     {/* <AntDesign name={initial_search_region_button_name} size={32} color={theme_blue_color}></AntDesign> */}
            //     <Text style={styles.topheading}>
            //       {initial_search_region_header_text}
            //       {/* Initial Search Region */}
            //     </Text>
            // </View>
          //  </TouchableOpacity>
          
          }
          {show_initial_search_region.value && 
            <View>
              <Text style={styles.subheading}>
                {initial_search_region_section_description}
                {/* {'The region over which initial starting points for the optimization will be selected.'} */}
                {/* {'The region over which starting points for the optimization are selected. For example: \nx: [-1, 1] \ny: [0, 2] \nz: [-1, 1]\n Note: The left and right square brackets will be added automatically.'} */}
                {/* {'The system of nonlinear equations and inequalities to be solved. For example: \n(1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2'} */}
              </Text>
                  <View style={{
                    flexDirection:'row',
                    justifyContent:'center',
                    alignContent:'center',
                    textAlign:'center',
                    textAlignVertical:'center',
                    alignItems:'center',
                    width:'100%',
                    marginTop:20,
                  }}
                >
                  <View style={{flexDirection:'row',width:'45%', justifyContent:'center',alignItems:'center',alignContent:'center'}}>
                          <View style={{width:'10%'}}>
                                {((!defaultSearchRegion.region.lower.is_parsable) && sub_show)?(
                                  <TouchableOpacity 
                                      onPress={()=>{
                                        set_show_modal({show:true,text:default_lower_bound_error_text})
                                            // set_show_modal({show:true,text:"We are unable to interpret the Default Lower Bound. Please ensure that the Default Lower Bound is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"})
                                      }}
                                  >
                                    <Feather  name="alert-triangle" size={32} color="orange"/>
                                  </TouchableOpacity>
                                  ):(
                                  <View>
                                    <Feather  name="alert-triangle" size={32} color={theme_transparent}/>
                                  </View>
                                  )}
                        </View>
                        <Text style={{fontSize:18,marginRight:5}}> {"Default Lower Bound: "} </Text>
                        <TextInput 
                            // style={styles.textinput} 
                            style={{    
                              height: 40,  
                              // width:"80%",
                              // margin: 12,
                              backgroundColor: 'white',
                              borderWidth: 1,
                              padding: 10,
                              fontSize:18,
                            }}
                            // defaultValue = {initialSearchRegionv2[key].limits.lower.value}
                            // defaultValue = {defaultSearchRegion.region.lower.value}  
                            value = {defaultSearchRegion.region.lower.value}    
                            onChangeText={text => change_default_search_region(text,"lower")}
                        />

                  </View>
                  <View style={{width:'10%'}}/>
                  <View style={{flexDirection:'row',width:'45%', justifyContent:'center',alignItems:'center',alignContent:'center'}}>
                          <View style={{width:'10%'}}>
                                {((!defaultSearchRegion.region.upper.is_parsable) && sub_show)?(
                                  <TouchableOpacity 
                                      onPress={()=>{
                                        set_show_modal({show:true,text:default_upper_bound_error_text })
                                            // set_show_modal({show:true,text:"We are unable to interpret the Default Lower Bound. Please ensure that the Default Lower Bound is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"})
                                      }}
                                  >
                                    <Feather  name="alert-triangle" size={32} color="orange"/>
                                  </TouchableOpacity>
                                  ):(
                                  <View>
                                    <Feather  name="alert-triangle" size={32} color={theme_transparent}/>
                                  </View>
                                  )
                                }
                        </View>
                        <Text style={{fontSize:18,marginRight:5}}> {"Default Upper Bound: "} </Text>
                        <TextInput 
                            // style={styles.textinput} 
                            style={{    
                              height: 40,  
                              // width:"80%",
                              // margin: 12,
                              backgroundColor: 'white',
                              borderWidth: 1,
                              padding: 10,
                              fontSize:18,
                            }}
                            // defaultValue = {initialSearchRegionv2[key].limits.lower.value}
                            value = {defaultSearchRegion.region.upper.value} 
                            // defaultValue = {defaultSearchRegion.region.upper.value}      
                            onChangeText={text => change_default_search_region(text,"upper")}
                        />
                        {/* <View style={{marginLeft:5,width:'10%'}}>
                                {(!defaultSearchRegion.region.upper.is_parsable) && sub_show &&
                                  <TouchableOpacity 
                                      onPress={()=>{
                                        set_show_modal({show:true,text:"We are unable to interpret the Default Uower Bound for the Initial Search Region. Please ensure that the Default Uower Bound is expressed as a number such as \n\n150\n\nor\n\n1.5e2"})
                                            // set_show_modal({show:true,text:"We are unable to interpret the Default Upper Bound. Please ensure that the Default Upper Bound is expressed as a number such as \n\n150\n\nor\n\n1.5e2"})
                                      }}
                                  >
                                    
                                    <Feather  name="alert-triangle" size={32} color="orange"/>
                                  </TouchableOpacity>
                                }
                        </View> */}
                  </View>
                </View>
            </View>
          }
        </View>
  }

        {show_initial_search_region.value && <View 
              style={{
                width:'100%',
                justifyContent:'center',
                alignItems:'center'
              }}>
                  {/* <View style={{
                    width:'75%',
                    justifyContent:'space-evenly',
                    alignContent:'center',
                    alignItems:'center',
                    flexDirection:'row'
                  }}>
      
                        <Button 
                        title='Add Constraint' 
                        // color='#026BC7'
                        color={theme_blue_color}
                        // style={{color:buttonColor,backgroundColor:buttonColor}}
                        // color=buttonColor 
                        onPress={async () => {
                          await setShow(false)
                          // addTextInput(textInput.length)
                          var texttt = textInputValuess
                          // console.log(textInputValuess)
                          await texttt.push("")
                          // console.log(textInputValuess)
                          settextInputValuess(texttt)
                          var iconss = inputsValid
                          // console.log(textInputValuess)
                          await iconss.push(false)
                          // console.log(textInputValuess)
                          await setInputsValid(iconss)
                          await setShow(true)
                        }} />

                        <Button 
                        title='Remove Constraint'
                        color={theme_blue_color}
                        onPress={async () => {
                          await setShow(false)
                          var texttt = textInputValuess
                          texttt = texttt.slice(0,-1)
                          // if(texttt.length==1){
                          //   texttt=[]
                          // }
                          await settextInputValuess(texttt)
                          var iconss = inputsValid
                          iconss = iconss.slice(0,-1)
                          await setInputsValid(iconss)
                          await setShow(true)
                        }} />
                  </View> */}
                  
                  {Object.keys(initialSearchRegionv2.region).map((key,index) => {
                    // console.log(key,index)
                      return <View style={{
                        flexDirection:'row',
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        width:'100%',
                        marginTop:20,
                      }}
                      key={key}>
                          
                            <View style={{marginRight:5,width:'10%'}}>
                                {(initialSearchRegionv2.region[key].limits.lower.is_parsable==false && initialSearchRegionv2.region[key].variable_is_defined && sub_show) &&
                                  <TouchableOpacity 
                                      onPress={()=>{
                                            set_show_modal({show:true,text:'We are unable to interpret the Lower Bound of the Initial Search Region for "'+key+'". Please ensure that the Lower Bound of each Initial Search Region is expressed as a number such as \n\n-150\n\nor\n\n-1.5e2'})
                                      }}
                                  >
                                    {/* <Ionicons name="md-checkmark-circle-outline" size={32} color="green" /> */}
                                    <Feather  name="alert-triangle" size={32} color="orange"/>
                                  </TouchableOpacity>
                                }
                            </View>
                        
                        <TextInput 
                            // style={styles.textinput} 
                            style={{    
                              height: 40,  
                              width:"80%",
                              // margin: 12,
                              borderWidth: 1,
                              padding: 10,
                              fontSize:18,
                              backgroundColor: 'white',
                              
                            }}
                            // defaultValue = {initialSearchRegionv2.region[key].limits.lower.value}
                            value = {initialSearchRegionv2.region[key].limits.lower.value}
                            // defaultValue = {initialSearchRegionv2.region[key].limits.lower.value}
                            onFocus={() => setfocusedIndex(index)}        
                            onChangeText={text => onInitialSearchRegionChangeText(text,key,"lower")}
                        />
                        <View style={{paddingHorizontal:7.5}}>
                                <MathJaxSvg 
                                    fontSize={4}
                                    // color="red"
                                    color={initialSearchRegionv2.region[key].variable_is_defined_color} 
                                    fontWeight="bold"
                                    // color={()=>{if(initialSearchRegionv2.region[key].variable_is_defined==false){return "red"}else{return "black"}}} 
                                    // color={get_variable_is_defined_color(key)} 
                                    // color={colorr}
                                    // fontCache={true}
                                  >
                                  {/* {'$$ = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}.$$'} */}
                                  {/* {'{$$\\boldsymbol{\\leq '+String(key)+'\\leq}$$}'} */}
                                  {'{$$\\leq '+String(key)+'\\leq$$}'}
                                  </MathJaxSvg>
                            {/* <Text style={{fontSize:20}}> {" <= "+key+" <= "} </Text> */}
                            {/* {(key.length>0)&& <Text style={{fontSize:20}}> {" <= "+key+" <= "} </Text>} */}
                            {/* {(key.length==0)&& <Text style={{fontSize:20}}> {" <= "+key+" <= "} </Text>} */}
                          
                        </View>
                        <TextInput 
                            // style={styles.textinput} 
                            style={{    
                              height: 40,  
                              width:"80%",
                              // margin: 12,
                              borderWidth: 1,
                              padding: 10,
                              backgroundColor: 'white',
                              fontSize:18
                            }}
                            // defaultValue = {initialSearchRegionv2.region[key].limits.upper.value}
                            value = {initialSearchRegionv2.region[key].limits.upper.value}
                            onFocus={() => setfocusedIndex(index)}        
                            onChangeText={text => onInitialSearchRegionChangeText(text,key,"upper")}
                        />
                        <View style={{marginLeft:5,width:'10%'}}>
                            {(initialSearchRegionv2.region[key].limits.upper.is_parsable==false && initialSearchRegionv2.region[key].variable_is_defined && sub_show) &&
                                <TouchableOpacity 
                                    onPress={()=>{
                                        // alert("We are unable to interpret the upper  bound of the this decision variable's initial search region. Please ensure that the upper  bound is expressed as a number such as \n-0.015 or -1.5e-2")
                                        set_show_modal({show:true,text:'We are unable to interpret the Upper Bound of the Initial Search Region for "'+key+'". Please ensure that the Upper Bound of each Initial Search Region is expressed as a number such as \n\n150\n\nor\n\n1.5e2'})
                                          // set_show_modal({show:true,text:"We are unable to interpret the upper bound of this decision variable's initial search region. Please ensure that the upper bound is expressed as a number such as \n\n-0.015\n\nor\n\n-1.5e-2"})
                                    }}
                                >
                                  <Feather  name="alert-triangle" size={32} color="orange"/>
                                </TouchableOpacity>
                            }
                            {/* {edit_initial_search_region &&
                                <TouchableOpacity 
                                      onPress={()=>{
                                            // set_show_modal({
                                            //   show:true,
                                            //   // text:"Please define the variable "+key+" before defining its initial search region. If you do not want to define the variable "+key+", you can remove "+key+" from the intiial search region by clicking this button again."                                              
                                            //   text:"The variable "+key+" is no longer defined as a Decision Variable. Variables with search region bounds that you have manually specified will appear in red if they are no longer defined in the list of Decision Variables. If you do not want to define the variable "+key+", you can remove "+key+" from the intiial search region by clicking this button again."
                                            // })
                                          remove_initial_search_region_variable(key)
                                          if(initialSearchRegionv2.region[key].variable_is_defined){
                                              onDecisionVariableDelete(key)
                                          }
                                          
                                      }}
                                  >
                                  <Ionicons name={undefined_variable_icon.name} size={32} color={undefined_variable_icon.color} />
                                </TouchableOpacity>

                            } */}
                            {/* {((!initialSearchRegionv2.region[key].variable_is_defined) && (!edit_initial_search_region)) && */}
                            {(!initialSearchRegionv2.region[key].variable_is_defined && sub_show) &&
                                <TouchableOpacity 
                                      onPress={()=>{

                                            if(undefined_variable_delete_warning_message_shown==true){
                                                remove_initial_search_region_variable(key)
                                            } else {
                                              set_show_modal({
                                                show:true,
                                                // text:"Please define the variable "+key+" before defining its initial search region. If you do not want to define the variable "+key+", you can remove "+key+" from the intiial search region by clicking this button again."                                              
                                                // text:'The variable "'+key+'" is no longer defined as a Decision Variable. Decision Variables with Initial Search Region bounds that you have manually edited will appear in red if they are no longer defined in the list of Decision Variables. If you do not want to define "'+key+'" as a Decision Variable, you can remove the Initial Search Region bounds for "'+key+'" by clicking the red button to the right hand side of the Initial Search Region bounds for"'+key+'".'
                                                text:'"'+key+'" is no longer defined as a Decision Variable. Decision Variables with Initial Search Region bounds that you have manually edited will appear in red if they are no longer defined in the list of Decision Variables. If you do not want to define "'+key+'" as a Decision Variable, you can remove the Initial Search Region bounds for "'+key+'" by clicking the red button to the right hand side of the Initial Search Region bounds for "'+key+'".'

                                              })
                                              set_undefined_variable_delete_warning_message_shown(true)
                                              set_undefined_variable_icon({name:"md-close-circle-outline",size:32,color:"red"})
                                            }

                                          
                                      }}
                                  >
                                    {undefined_variable_delete_warning_message_shown ?(
                                        <Ionicons name={undefined_variable_icon.name} size={32} color={undefined_variable_icon.color} />
                                    ):(
                                        <Feather name="alert-triangle" size={32} color="orange"/>
                                    )}
                                  
                                </TouchableOpacity>
                            }
                        </View>



                        {/* {(initialSearchRegionv2.region[key].is_parsable && initialSearchRegionv2.region[key].variable_is_defined) &&
                          <View style={{marginHorizontal:5}}>
                            <Ionicons name="md-checkmark-circle-outline" size={32} color="white" />
                          </View>
                        } */}
                        {/* {(
                          ((initialSearchRegionv2.region[key].limits.lower.is_parsable==false) || (initialSearchRegionv2.region[key].limits.upper.is_parsable==false)) && 
                          initialSearchRegionv2.region[key].variable_is_defined
                         ) &&
                          <View style={{marginHorizontal:5}}>
                            <TouchableOpacity 
                                onPress={()=>{
                                  if(initialSearchRegionv2.region[key].limits.lower.is_parsable==false){
                                    alert("We are unable to interpret the lower bound of the this decision variable's initial search region. Please ensure that the lower bound is expressed as a number such as \n-0.015 or -1.5e-2")
                                  } else if(initialSearchRegionv2.region[key].limits.upper.is_parsable==false){
                                    alert("We are unable to interpret the upper bound of the this decision variable's initial search region. Please ensure that the upper bound is expressed as a number such as \n150 or 1.5e2")
                                  }
                                }}
                            >
                              <Feather  name="alert-triangle" size={32} color="orange"/>
                            </TouchableOpacity>
                          </View>
                        } */}
                        {/* {!initialSearchRegionv2.region[key].variable_is_defined &&
                        // <TouchableOpacity style={{marginHorizontal:5}} onPress={async()=>{
                        //     if(textInputValuess.length ==1){
                        //           await setShow(false)
                        //           var texttt = textInputValuess
                        //           texttt = texttt.slice(0,-1)
                        //           settextInputValuess(texttt)
                        //           var iconss = inputsValid
                        //           iconss = iconss.slice(0,-1)
                        //           await setInputsValid(iconss)
                        //           await setShow(true)
                        //     } else {
                        //           await setShow(false)
                        //           var texttt = textInputValuess
                        //           texttt.splice(key,1)
                        //           await settextInputValuess(texttt)
                        //           var iconss = inputsValid
                        //           iconss.splice(key,1)
                        //           await setInputsValid(iconss)
                        //           await setShow(true)
                        //     }
                        // }}>
                        <View style={{position:'absolute',left:'92.5%'}}>
                            <TouchableOpacity 
                                  onPress={()=>{
                                      remove_initial_search_region_variable(key)
                                  }}
                              >
                              <Ionicons name="md-close-circle-outline" size={32} color="red" />
                            </TouchableOpacity>
                        </View>
                        // </TouchableOpacity>
                          
                        } */}
                      </View>
                  })}
                  {/* <Text >{textInputValuess}</Text> */}
              </View>
  }
  </View>
</View>












<View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  // padding:10,
  borderRadius:10,
  borderWidth:1,
  backgroundColor:'white',
  overflow:'hidden',}}>
          <View style={{
      padding:10,
      backgroundColor:theme_blue_color_lightest,
      overflow:'hidden',
      width:'100%',
      height:'100%'
    }}>
    <View style={{
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                    {"Minimum Acceptable Distance Between Distinct Solutions"}
                </Text>
                {show_minimum_distance.value &&
                <View style={{width:'75%'}}>
                <Text style={styles.subheading}>
                  
                  {minimum_distance_section_description}
                </Text>
                </View>
                }
    </View>

    {/* ERROR IS HERE */}
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ async() => {
                  await setShow(false)
                  if(show_minimum_distance.value){
                      if(min_distance_warning.value){
                        set_show_modal({show:true,text:minimum_distance_error_text })
                        
                      } else {
                        // set_show_minimum_distance_button_name("caretdown")
                        show_minimum_distance_button_name.name="caretdown"
                        // set_show_minimum_distance(!show_minimum_distance)           
                        show_minimum_distance.value = (!show_minimum_distance.value)     
                      }
    
                  } else {
                    show_minimum_distance_button_name.name="caretup"
                    // set_show_minimum_distance_button_name("caretup")
                    // set_show_minimum_distance(!show_minimum_distance)
                    show_minimum_distance.value = (!show_minimum_distance.value)
                  }
                  await setShow(true)
                }}
            >     
            {sub_show &&
                    <AntDesign name={show_minimum_distance_button_name.name} size={32} color={show_minimum_distance_button_color.color}></AntDesign>
                  }
                    
            </TouchableOpacity>
    </View>



{show_minimum_distance.value && 
<View>
{/* <View style={styles.textview}>
      <Text style={styles.heading}>
        Minimum Acceptable Distance Between Unique Solutions
      </Text>
      <Text style={styles.subheading}>
        {'The minimum acceptable euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered to be "distinct". The solver will attempt to return only "distinct" solutions. If two approximate solutions are found and are closer than the "Minimum Acceptable Distance Between Solutions", the solution with the lower objective function value will be returned. Note that the Minimum Acceptable Distance Between Solutions is not enforced strictly, and that some solutions returned by the solver may slighly violate the "Minimum Acceptable Distance Between Solutions".'}
        {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'}
      </Text>
  </View> */}
      <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
          <TextInput
            style={{height: 40,
              // margin: 12,
              width:"80%",
              borderWidth: 1,
              backgroundColor:'white',
              padding: 10,
            fontSize:18}}
              onChangeText={async(text)=> {
                // objectiveFunction = '$$'+text+'$$'
                // keyy = keyy + 1
                // textt = JSON.parse(text)
                // var a = '$$'+text+'$$'
                // setminDistance(Number(text))
                var text_processed = text.replace(/[^0-9.e-]/g, '')
                if((text_processed.length == 0) || isNaN(Number(text_processed)) || Number(text_processed) < 0){
                  // NO ERRORS SHOULD BE PRODUCED IF ONLY PARSABLE VALUES ARE SAVED
                  min_distance_warning.value = true
                  show_minimum_distance_button_color.color = "grey"
                  // minDistance.value=text_processed
                  // await setminDistance(minDistance)
                } else {
                  min_distance_warning.value = false
                  show_minimum_distance_button_color.color = theme_blue_color
                  
                  
                }
                minDistance.value=text_processed
                await setminDistance(minDistance)
                // setobjectiveFunction()
                // this.setState({pagename:text})
                // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
                //   this.setState({ create_disabled: true, create_opacity: 0.45})
                // } else {
                //   this.setState({ create_disabled: false, create_opacity: 1})
                // }
                await set_sub_show(false)
                await set_sub_show(true)
              }}
            // value={objectiveFunction}
            value={minDistance.value}
            // defaultValue={minDistance.value}
            // placeholder="0.1"
            // keyboardType="numeric"
          />
          {min_distance_warning.value ?(
            <TouchableOpacity style={{marginHorizontal:5}} 
                    onPress={()=>{
                      set_show_modal({show:true,text:minimum_distance_error_text })
                    }}
                >
                  <Feather  name="alert-triangle" size={32} color="orange"/>
            </TouchableOpacity>
            ):(
            <View style={{marginHorizontal:5}} 
                    // onPress={()=>{
                    //   set_show_modal({show:true,text:"Please ensure that the minimum distance is expressed as a non-negative number such as \n\n0\n\nor\n\n-0.015\n\nor\n\n-1.5e-2"})
                    // }}
                >
                  <Feather  name="alert-triangle" size={32} color={theme_transparent}/>
            </View>
          )}
      </View>
</View>
  }
  </View>
</View>















<View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  // padding:10,
  borderRadius:10,
  borderWidth:1,
  backgroundColor:'white',
  overflow:'hidden',}}>
          <View style={{
      padding:10,
      backgroundColor:theme_blue_color_lightest,
      overflow:'hidden',
      width:'100%',
      height:'100%'
    }}>
    <View style={{
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                    {max_obj_func_val_section_title}
                </Text>
                {show_max_obj_func_val.value &&
                <View style={{width:'75%'}}>
                <Text style={styles.subheading}>
                  {max_obj_func_val_section_description}
                  {/* {'The maximum objective function value that the solver will return. If the solver finds a solution with an objective function value greater than the "Maximum Objective Function Value", it will return the best solution it has found so far.'} */}
                  {/* {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'} */}
                </Text>
                </View>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ async() => {
                  await setShow(false)
                  if(show_max_obj_func_val.value){
                      // set_show_max_obj_func_val_button_name("caretdown")
                      // set_show_max_obj_func_val(!show_max_obj_func_val)
                      if(max_obj_func_val_warning.value){
                          if(ProblemType=="sne"){
                            set_show_modal({show:true,text:sne_max_obj_func_val_error})
                          } else if(ProblemType=="optimization"){
                            set_show_modal({show:true,text:opt_max_obj_func_val_error})
                          }
                          
                      } else {
                          show_max_obj_func_val_button_name.name="caretdown"
                          show_max_obj_func_val.value = (!show_max_obj_func_val.value)        
                      }  
                  } else {
                      // set_show_max_obj_func_val_button_name("caretup")
                      // set_show_max_obj_func_val(!show_max_obj_func_val)
                      show_max_obj_func_val_button_name.name="caretup"
                      show_max_obj_func_val.value = (!show_max_obj_func_val.value)  
                  }
                  await setShow(true)
                }}
            >
                  {sub_show &&
                      <AntDesign name={show_max_obj_func_val_button_name.name} size={32} color={show_max_obj_func_val_button_color.color}></AntDesign>
                  }
          </TouchableOpacity>
    </View>



{show_max_obj_func_val.value && 
<View>
{/* <View style={styles.textview}>
      <Text style={styles.heading}>
        Minimum Acceptable Distance Between Unique Solutions
      </Text>
      <Text style={styles.subheading}>
        {'The minimum acceptable euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered to be "distinct". The solver will attempt to return only "distinct" solutions. If two approximate solutions are found and are closer than the "Minimum Acceptable Distance Between Solutions", the solution with the lower objective function value will be returned. Note that the Minimum Acceptable Distance Between Solutions is not enforced strictly, and that some solutions returned by the solver may slighly violate the "Minimum Acceptable Distance Between Solutions".'}
        {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'}
      </Text>
  </View> */}
            <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                <TextInput
                style={{height: 40,
                  // margin: 12,
                  width:"80%",
                  borderWidth: 1,
                  backgroundColor:'white',
                  padding: 10,
                fontSize:18}}
                onChangeText={async(text)=> {
                  // objectiveFunction = '$$'+text+'$$'
                  // keyy = keyy + 1
                  // textt = JSON.parse(text)
                  // var a = '$$'+text+'$$'
                  // setMaxObjectiveFunctionValue(Number(text))


                  var processed_text = text.replace(/[^0-9.e-]/g, '')
                  if((text=="") || isNaN(Number(processed_text))){
                      max_obj_func_val_warning.value=true
                      show_max_obj_func_val_button_color.color = "grey"
                  } else {
                      if(ProblemType=="sne"){
                          if(Number(processed_text)<=0){
                            max_obj_func_val_warning.value=true
                            show_max_obj_func_val_button_color.color = "grey"
                          } else {
                            max_obj_func_val_warning.value=false
                            show_max_obj_func_val_button_color.color = theme_blue_color
                          }
                      } else if (ProblemType=="optimization"){
                          max_obj_func_val_warning.value=false
                          show_max_obj_func_val_button_color.color = theme_blue_color
                      } 
                  }
                  MaxObjectiveFunctionValue.value = processed_text
                  setMaxObjectiveFunctionValue(MaxObjectiveFunctionValue)
                  // setobjectiveFunction()
                  // this.setState({pagename:text})
                  // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
                  //   this.setState({ create_disabled: true, create_opacity: 0.45})
                  // } else {
                  //   this.setState({ create_disabled: false, create_opacity: 1})
                  // }
                  await set_sub_show(false)
                  await set_sub_show(true)
                }}
              // value={objectiveFunction}
                // value={objectiveFunction}
                value = {MaxObjectiveFunctionValue.value}
                // defaultValue={MaxObjectiveFunctionValue.value}
                // placeholder="0.1"
                // keyboardType="numeric"
              />
              {max_obj_func_val_warning.value &&
              <TouchableOpacity style={{marginHorizontal:5}} 
                      onPress={()=>{
                          if(ProblemType=="sne"){
                            set_show_modal({show:true,text:sne_max_obj_func_val_error})
                          } else if(ProblemType=="optimization"){
                            set_show_modal({show:true,text:opt_max_obj_func_val_error})
                          }
                      }}
                  >
                    <Feather  name="alert-triangle" size={32} color="orange"/>
              </TouchableOpacity>
              }
              {!max_obj_func_val_warning.value &&
                      <View style={{marginHorizontal:5}} 
                      // onPress={()=>{
                      //   set_show_modal({show:true,text:"Please ensure that the number of starting points is expressed as a positive integer. For example:\n\n100\n\nor\n\n1e2"})
                      // }}
                  >
                    <Feather  name="alert-triangle" size={32} color={theme_transparent} />
              </View>
              }
          </View>
</View>
  }

  </View>
</View>
































<View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  // padding:10,
  borderRadius:10,
  borderWidth:1,
  backgroundColor:'white',
  overflow:'hidden',}}>
          <View style={{
      padding:10,
      backgroundColor:theme_blue_color_lightest,
      overflow:'hidden',
      width:'100%',
      height:'100%'
    }}>
    <View style={{
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                    {max_solutions_to_return_section_title}
                </Text>
                {show_max_solutions_to_return.value &&
                <View style={{width:'75%'}}>
                <Text style={styles.subheading}>
                  {max_solutions_to_return_section_description}
                  {/* {'The maximum objective function value that the solver will return. If the solver finds a solution with an objective function value greater than the "Maximum Objective Function Value", it will return the best solution it has found so far.'} */}
                  {/* {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'} */}
                </Text>
                </View>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ async() => {
                  await setShow(false)
                  if(show_max_solutions_to_return.value){
                      // set_show_max_obj_func_val_button_name("caretdown")
                      // set_show_max_obj_func_val(!show_max_obj_func_val)
                      if(max_solutions_to_return_warning.value){
                          if(ProblemType=="sne"){
                            set_show_modal({show:true,text:sne_max_solutions_to_return_error})
                          } else if(ProblemType=="optimization"){
                            set_show_modal({show:true,text:opt_max_solutions_to_return_error})
                          }
                          
                      } else {
                          show_max_solutions_to_return_button_name.name="caretdown"
                          show_max_solutions_to_return.value = (!show_max_solutions_to_return.value)        
                      }  
                  } else {
                      // set_show_max_obj_func_val_button_name("caretup")
                      // set_show_max_obj_func_val(!show_max_obj_func_val)
                      show_max_solutions_to_return_button_name.name="caretup"
                      show_max_solutions_to_return.value = (!show_max_solutions_to_return.value)  
                  }
                  await setShow(true)
                }}
            >
                  {sub_show &&
                      <AntDesign name={show_max_solutions_to_return_button_name.name} size={32} color={show_max_solutions_to_return_button_color.color}></AntDesign>
                  }
          </TouchableOpacity>
    </View>



{show_max_solutions_to_return.value && 
<View>
{/* <View style={styles.textview}>
      <Text style={styles.heading}>
        Minimum Acceptable Distance Between Unique Solutions
      </Text>
      <Text style={styles.subheading}>
        {'The minimum acceptable euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered to be "distinct". The solver will attempt to return only "distinct" solutions. If two approximate solutions are found and are closer than the "Minimum Acceptable Distance Between Solutions", the solution with the lower objective function value will be returned. Note that the Minimum Acceptable Distance Between Solutions is not enforced strictly, and that some solutions returned by the solver may slighly violate the "Minimum Acceptable Distance Between Solutions".'}
        {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'}
      </Text>
  </View> */}
            <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                <TextInput
                style={{height: 40,
                  // margin: 12,
                  width:"80%",
                  borderWidth: 1,
                  backgroundColor:'white',
                  padding: 10,
                fontSize:18}}
                onChangeText={async(text)=> {
                  // objectiveFunction = '$$'+text+'$$'
                  // keyy = keyy + 1
                  // textt = JSON.parse(text)
                  // var a = '$$'+text+'$$'
                  // setMaxObjectiveFunctionValue(Number(text))


                  var processed_text = text.replace(/[^0-9.e-]/g, '')
                  if((text=="") || isNaN(Number(processed_text)) || (Number(processed_text)<1)){
                      max_solutions_to_return_warning.value=true
                      show_max_solutions_to_return_button_color.color = "grey"
                  } else {
                      if(ProblemType=="sne"){
                          if(Number(processed_text)<=0){
                            max_solutions_to_return_warning.value=true
                            show_max_solutions_to_return_button_color.color = "grey"
                          } else {
                            max_solutions_to_return_warning.value=false
                            show_max_solutions_to_return_button_color.color = theme_blue_color
                          }
                      } else if (ProblemType=="optimization"){
                          max_solutions_to_return_warning.value=false
                          show_max_solutions_to_return_button_color.color = theme_blue_color
                      } 
                  }
                  max_solutions_to_return.value = processed_text
                  set_max_solutions_to_return(max_solutions_to_return)
                  // setobjectiveFunction()
                  // this.setState({pagename:text})
                  // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
                  //   this.setState({ create_disabled: true, create_opacity: 0.45})
                  // } else {
                  //   this.setState({ create_disabled: false, create_opacity: 1})
                  // }
                  await set_sub_show(false)
                  await set_sub_show(true)
                }}
              // value={objectiveFunction}
                // value={objectiveFunction}
                value = {max_solutions_to_return.value}
                // defaultValue={MaxObjectiveFunctionValue.value}
                // placeholder="0.1"
                // keyboardType="numeric"
              />
              {max_solutions_to_return_warning.value &&
              <TouchableOpacity style={{marginHorizontal:5}} 
                      onPress={()=>{
                          if(ProblemType=="sne"){
                            set_show_modal({show:true,text:sne_max_solutions_to_return_error})
                          } else if(ProblemType=="optimization"){
                            set_show_modal({show:true,text:opt_max_solutions_to_return_error})
                          }
                      }}
                  >
                    <Feather  name="alert-triangle" size={32} color="orange"/>
              </TouchableOpacity>
              }
              {!max_solutions_to_return_warning.value &&
                      <View style={{marginHorizontal:5}} 
                      // onPress={()=>{
                      //   set_show_modal({show:true,text:"Please ensure that the number of starting points is expressed as a positive integer. For example:\n\n100\n\nor\n\n1e2"})
                      // }}
                  >
                    <Feather  name="alert-triangle" size={32} color={theme_transparent} />
              </View>
              }
          </View>
</View>
  }

  </View>
</View>




























<View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  // padding:10,
  borderRadius:10,
  borderWidth:1,
  backgroundColor:'white',
  overflow:'hidden',}}>
          <View style={{
      padding:10,
      backgroundColor:theme_blue_color_lightest,
      overflow:'hidden',
      width:'100%',
      height:'100%'
    }}>
    <View style={{
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                    {max_runtime_section_title}
                </Text>
                {show_max_runtime.value &&
                <View style={{width:'75%'}}>
                <Text style={styles.subheading}>
                  {max_runtime_section_description}
                  {/* {'The maximum objective function value that the solver will return. If the solver finds a solution with an objective function value greater than the "Maximum Objective Function Value", it will return the best solution it has found so far.'} */}
                  {/* {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'} */}
                </Text>
                </View>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ async() => {
                  await setShow(false)
                  if(show_max_runtime.value){
                      // set_show_max_runtime_button_name("caretdown")
                      // set_show_max_runtime(!show_max_runtime)
                      if(max_runtime_warning.value){
                          if(ProblemType=="sne"){
                            set_show_modal({show:true,text:sne_max_runtime_error})
                          } else if(ProblemType=="optimization"){
                            set_show_modal({show:true,text:opt_max_runtime_error})
                          }
                          
                      } else {
                          show_max_runtime_button_name.name="caretdown"
                          show_max_runtime.value = (!show_max_runtime.value)        
                      }  
                  } else {
                      // set_show_max_runtime_button_name("caretup")
                      // set_show_max_runtime(!show_max_runtime)
                      show_max_runtime_button_name.name="caretup"
                      show_max_runtime.value = (!show_max_runtime.value)  
                  }
                  await setShow(true)
                }}
            >
                  {sub_show &&
                      <AntDesign name={show_max_runtime_button_name.name} size={32} color={show_max_runtime_button_color.color}></AntDesign>
                  }
          </TouchableOpacity>
    </View>



{show_max_runtime.value && 
<View>
{/* <View style={styles.textview}>
      <Text style={styles.heading}>
        Minimum Acceptable Distance Between Unique Solutions
      </Text>
      <Text style={styles.subheading}>
        {'The minimum acceptable euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered to be "distinct". The solver will attempt to return only "distinct" solutions. If two approximate solutions are found and are closer than the "Minimum Acceptable Distance Between Solutions", the solution with the lower objective function value will be returned. Note that the Minimum Acceptable Distance Between Solutions is not enforced strictly, and that some solutions returned by the solver may slighly violate the "Minimum Acceptable Distance Between Solutions".'}
        {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'}
      </Text>
  </View> */}
            <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                <TextInput
                style={{height: 40,
                  // margin: 12,
                  width:"80%",
                  borderWidth: 1,
                  backgroundColor:'white',
                  padding: 10,
                fontSize:18}}
                onChangeText={async(text)=> {
                  // objectiveFunction = '$$'+text+'$$'
                  // keyy = keyy + 1
                  // textt = JSON.parse(text)
                  // var a = '$$'+text+'$$'
                  // setMaxObjectiveFunctionValue(Number(text))


                  var processed_text = text.replace(/[^0-9.e-]/g, '')
                  if((text=="") || isNaN(Number(processed_text)) || (Number(processed_text)<1)){
                      max_runtime_warning.value=true
                      show_max_runtime_button_color.color = "grey"
                  } else {
                      max_runtime_warning.value=false
                      show_max_runtime_button_color.color = theme_blue_color                      
                  }
                  max_runtime.value = processed_text
                  set_max_runtime(max_runtime)
                  // setobjectiveFunction()
                  // this.setState({pagename:text})
                  // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
                  //   this.setState({ create_disabled: true, create_opacity: 0.45})
                  // } else {
                  //   this.setState({ create_disabled: false, create_opacity: 1})
                  // }
                  await set_sub_show(false)
                  await set_sub_show(true)
                }}
              // value={objectiveFunction}
                // value={objectiveFunction}
                value = {max_runtime.value}
                // defaultValue={MaxObjectiveFunctionValue.value}
                // placeholder="0.1"
                // keyboardType="numeric"
              />
              {max_runtime_warning.value &&
              <TouchableOpacity style={{marginHorizontal:5}} 
                      onPress={()=>{
                          if(ProblemType=="sne"){
                            set_show_modal({show:true,text:sne_max_runtime_error})
                          } else if(ProblemType=="optimization"){
                            set_show_modal({show:true,text:opt_max_runtime_error})
                          }
                      }}
                  >
                    <Feather  name="alert-triangle" size={32} color="orange"/>
              </TouchableOpacity>
              }
              {!max_runtime_warning.value &&
                      <View style={{marginHorizontal:5}} 
                      // onPress={()=>{
                      //   set_show_modal({show:true,text:"Please ensure that the number of starting points is expressed as a positive integer. For example:\n\n100\n\nor\n\n1e2"})
                      // }}
                  >
                    <Feather  name="alert-triangle" size={32} color={theme_transparent} />
              </View>
              }
          </View>
</View>
  }

  </View>
</View>
























<View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  // padding:10,
  borderRadius:10,
  borderWidth:1,
  backgroundColor:'white',
  overflow:'hidden',}}>
          <View style={{
      padding:10,
      backgroundColor:theme_blue_color_lightest,
      overflow:'hidden',
      width:'100%',
      height:'100%'
    }}>
    <View style={{
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                    {ram_section_title}
                </Text>
                {show_ram.value &&
                <View style={{width:'75%'}}>
                <Text style={styles.subheading}>
                  {ram_section_description}
                  {/* {'The maximum objective function value that the solver will return. If the solver finds a solution with an objective function value greater than the "Maximum Objective Function Value", it will return the best solution it has found so far.'} */}
                  {/* {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'} */}
                </Text>
                </View>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ async() => {
                  await setShow(false)
                  if(show_ram.value){
                      // set_show_ram_button_name("caretdown")
                      // set_show_ram(!show_ram)
                      if(ram_warning.value){
                          if(ProblemType=="sne"){
                            set_show_modal({show:true,text:sne_ram_error})
                          } else if(ProblemType=="optimization"){
                            set_show_modal({show:true,text:opt_ram_error})
                          }
                          
                      } else {
                          show_ram_button_name.name="caretdown"
                          show_ram.value = (!show_ram.value)        
                      }  
                  } else {
                      // set_show_ram_button_name("caretup")
                      // set_show_ram(!show_ram)
                      show_ram_button_name.name="caretup"
                      show_ram.value = (!show_ram.value)  
                  }
                  await setShow(true)
                }}
            >
                  {sub_show &&
                      <AntDesign name={show_ram_button_name.name} size={32} color={show_ram_button_color.color}></AntDesign>
                  }
          </TouchableOpacity>
    </View>



{show_ram.value && 
<View>
{/* <View style={styles.textview}>
      <Text style={styles.heading}>
        Minimum Acceptable Distance Between Unique Solutions
      </Text>
      <Text style={styles.subheading}>
        {'The minimum acceptable euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered to be "distinct". The solver will attempt to return only "distinct" solutions. If two approximate solutions are found and are closer than the "Minimum Acceptable Distance Between Solutions", the solution with the lower objective function value will be returned. Note that the Minimum Acceptable Distance Between Solutions is not enforced strictly, and that some solutions returned by the solver may slighly violate the "Minimum Acceptable Distance Between Solutions".'}
        {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'}
      </Text>
  </View> */}
            <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                <TextInput
                style={{height: 40,
                  // margin: 12,
                  width:"80%",
                  borderWidth: 1,
                  backgroundColor:'white',
                  padding: 10,
                fontSize:18}}
                onChangeText={async(text)=> {
                  // objectiveFunction = '$$'+text+'$$'
                  // keyy = keyy + 1
                  // textt = JSON.parse(text)
                  // var a = '$$'+text+'$$'
                  // setMaxObjectiveFunctionValue(Number(text))


                  var processed_text = text.replace(/[^0-9.e-]/g, '')
                  if((text=="") || isNaN(Number(processed_text)) || (Number(processed_text)<512)){
                      ram_warning.value=true
                      show_ram_button_color.color = "grey"
                  } else {
                      ram_warning.value=false
                      show_ram_button_color.color = theme_blue_color
                  }
                  ram.value = processed_text
                  set_ram(ram)
                  // setobjectiveFunction()
                  // this.setState({pagename:text})
                  // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
                  //   this.setState({ create_disabled: true, create_opacity: 0.45})
                  // } else {
                  //   this.setState({ create_disabled: false, create_opacity: 1})
                  // }
                  await set_sub_show(false)
                  await set_sub_show(true)
                }}
              // value={objectiveFunction}
                // value={objectiveFunction}
                value = {ram.value}
                // defaultValue={MaxObjectiveFunctionValue.value}
                // placeholder="0.1"
                // keyboardType="numeric"
              />
              {ram_warning.value &&
              <TouchableOpacity style={{marginHorizontal:5}} 
                      onPress={()=>{
                          if(ProblemType=="sne"){
                            set_show_modal({show:true,text:sne_ram_error})
                          } else if(ProblemType=="optimization"){
                            set_show_modal({show:true,text:opt_ram_error})
                          }
                      }}
                  >
                    <Feather  name="alert-triangle" size={32} color="orange"/>
              </TouchableOpacity>
              }
              {!ram_warning.value &&
                      <View style={{marginHorizontal:5}} 
                      // onPress={()=>{
                      //   set_show_modal({show:true,text:"Please ensure that the number of starting points is expressed as a positive integer. For example:\n\n100\n\nor\n\n1e2"})
                      // }}
                  >
                    <Feather  name="alert-triangle" size={32} color={theme_transparent} />
              </View>
              }
          </View>
</View>
  }

  </View>
</View>














 

















{/* <View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  padding:10,
  borderRadius:10,
  borderWidth:1,
  backgroundColor:theme_blue_color_lightest,
  overflow:'hidden',}}>
    <View style={{
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                    {"Minimum Acceptable Distance Between Unique Solutions"}
                </Text>
                {show_minimum_distance &&
                <Text style={styles.subheading}>
                  {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'}
                </Text>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ () => {
                  if(show_minimum_distance){
                      set_show_minimum_distance_button_name("caretdown")
                      set_show_minimum_distance(!show_minimum_distance)                    
                  } else {
                    set_show_minimum_distance_button_name("caretup")
                    set_show_minimum_distance(!show_minimum_distance)
                  }
                }}
            >
                    <AntDesign name={show_minimum_distance_button_name} size={32} color={show_minimum_distance_button_color}></AntDesign>
            </TouchableOpacity>
    </View>
</View> */}










{/* ERROR ISNT HERE */}
<View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  // padding:10,
  borderRadius:10,
  borderWidth:1,
  backgroundColor:'white',
  overflow:'hidden',}}>
          <View style={{
      padding:10,
      backgroundColor:theme_blue_color_lightest,
      overflow:'hidden',
      width:'100%',
      height:'100%'
    }}>
    <View style={{
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                    {problem_tags_and_description_section_title}
                </Text>
                {show_problem_tags_and_description.value &&
                <View style={{width:'75%'}}>
                <Text style={styles.subheading}>
                  {problem_tags_and_description_section_description}
                  {/* {'The maximum objective function value that the solver will return. If the solver finds a solution with an objective function value greater than the "Maximum Objective Function Value", it will return the best solution it has found so far.'} */}
                  {/* {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'} */}
                </Text>
                </View>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ async() => {
                  await setShow(false)
                  if(show_problem_tags_and_description.value){
                      // set_show_max_obj_func_val_button_name("caretdown")
                      // set_show_max_obj_func_val(!show_max_obj_func_val)
                      // if(max_obj_func_val_warning.value){
                      //     if(ProblemType=="sne"){
                      //       set_show_modal({show:true,text:sne_max_obj_func_val_error})
                      //     } else if(ProblemType=="optimization"){
                      //       set_show_modal({show:true,text:opt_max_obj_func_val_error})
                      //     }
                          
                      // } else {
                        show_problem_tags_and_description_button_name.name="caretdown"
                        show_problem_tags_and_description.value = (!show_problem_tags_and_description.value)        
                      // }  
                  } else {
                      // set_show_max_obj_func_val_button_name("caretup") 
                      // set_show_max_obj_func_val(!show_max_obj_func_val)
                      show_problem_tags_and_description_button_name.name="caretup"
                      show_problem_tags_and_description.value = (!show_problem_tags_and_description.value)  
                      get_tags('unique-problem-tags',"")
                  }
                  await setShow(true)
                }}
            >
                  {sub_show &&
                      <AntDesign name={show_problem_tags_and_description_button_name.name} size={32} color={show_problem_tags_and_description_button_color.color}></AntDesign>
                  }
          </TouchableOpacity>
    </View>



{show_problem_tags_and_description.value && 
<View>
{/* <View style={styles.textview}>
      <Text style={styles.heading}>
        Minimum Acceptable Distance Between Unique Solutions
      </Text>
      <Text style={styles.subheading}>
        {'The minimum acceptable euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered to be "distinct". The solver will attempt to return only "distinct" solutions. If two approximate solutions are found and are closer than the "Minimum Acceptable Distance Between Solutions", the solution with the lower objective function value will be returned. Note that the Minimum Acceptable Distance Between Solutions is not enforced strictly, and that some solutions returned by the solver may slighly violate the "Minimum Acceptable Distance Between Solutions".'}
        {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'}
      </Text>
  </View> */}
            <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                <View style={{width:'15%'}}>
                     <Text style={{fontSize:18,marginRight:5}}>{"Problem Tags: "}</Text>
                </View>
                <TextInput
                style={{height: 40,
                  // margin: 12,
                  width:"80%",
                  borderWidth: 1,
                  backgroundColor:'white',
                  padding: 10,
                fontSize:18}}
                onChangeText={text=> on_tags_change_text(text)}
                onSubmitEditing={(text)=> on_tags_submit_editing(text)}
                blurOnSubmit={false}
              // value={objectiveFunction}
                // value={objectiveFunction}
                value = {problem_tags_and_description_string.value}
              />
          </View>


















          <View style={{
                        flexDirection:'row',
                        justifyContent:'left',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        width:'100%',
                        left:'0%',
                        marginVertical:15,
                      }}>
                    {/* {(problem_tags_and_description.tags.length == 0) && */}
                        <View style={{width:'20%',marginTop:5,padding:5,}}>
                          <Text style={{fontSize:16}}> {""} </Text>
                        </View>
                    {/* } */}
                    <View style={{
                        overflow:'hidden',
                        flexDirection:'row', 
                        // flexDirection:
                        flexWrap:'wrap',
                        
                        justifyContent:'left',
                        
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        
                        alignItems:'center',
                        // marginRight:15,
                        width:'80%',

                        }}>

                    {sub_show && ([...Array.from(problem_tags_and_description.tags), ...Array.from(recommended_problem_tags_and_description)]).map((key,index) => {
                              if(key!=""){
                                
                                // console.log(key,index)
                                  return (
                                    <View key={key}>
                                  {!recommended_problem_tags_and_description.has(key) 
                                  //(index<=(problem_tags_and_description.tags.length-1)) 
                                    ?
                                        <View style={{
                                          flexDirection:'row',
                                          justifyContent:'center',
                                          alignContent:'center',
                                          textAlign:'center',
                                          textAlignVertical:'center',
                                          alignItems:'center',
                                          backgroundColor:'white',
                                          // backgroundColor:dodgerblue,
                                          // backgroundColor:theme_blue_color_light,
                                          // backgroundColor:{backgroundColorr},
                                          borderColor:theme_blue_color,
                                          // borderColor:theme_blue_color_light,
                                          borderWidth:2.5,
                                          borderRadius:10, 
                                          padding:5,
                                          marginRight:5,
                                          marginTop:5,
                                          overflow:'hidden',
                                          flexWrap:'wrap',
                                        }}
                                        key={key}
                                        >
                                        
                                          <View style={{flexWrap:'wrap'}}>
                                            <Text style={{fontSize:18}}>
                                              {key} 
                                            </Text>
                                          </View> 
                                            <TouchableOpacity style={{marginLeft:5}} 
                                                onPress={async()=>{
                                                    await on_tag_delete(key)
                                                    // await onDecisionVariableDelete(key)
                                                }}>
                                                <Ionicons name="md-close-circle-outline" size={28} color="black" />
                                            </TouchableOpacity>
                                        </View>
                                  :
                                  <TouchableOpacity  
                                  onPress={async()=>{
                                      await on_tag_add(key)
                                      // await onDecisionVariableDelete(key)
                                  }}>
                                        <View style={{
                                          flexDirection:'row',
                                          justifyContent:'center',
                                          alignContent:'center',
                                          textAlign:'center',
                                          textAlignVertical:'center',
                                          alignItems:'center',
                                          backgroundColor:'rgba(255,255,255,0.75)',
                                          // backgroundColor:{backgroundColorr},
                                          borderRadius:10, 
                                          padding:5,
                                          marginRight:5,
                                          marginTop:5,
                                          overflow:'hidden',
                                          flexWrap:'wrap',
                                        }}
                                        key={key}>
                                        
                                          <View style={{flexWrap:'wrap'}}>
                                            <Text style={{fontSize:18,
                                              color:'rgba(0,0,0,0.45)'
                                              }}>
                                               {key} 
                                            </Text>
                                          </View>
                                                
                                          <View style={{marginLeft:5}} 
                                        onPress={async()=>{
                                            await on_tag_add(key)
                                            // await onDecisionVariableDelete(key)
                                        }}>
                                        {/* <Ionicons name="md-checkmark-circle-outline" size={24} 
                                        // color={theme_blue_color} 
                                        color="green"
                                        /> */}
                                        <AntDesign name="pluscircleo" size={24} color="rgba(0,0,0,0.45)" />
                                    </View>
      
      
                                        </View>
                                        </TouchableOpacity>
     
                                   }
                                   </View>
                                  
                                )
                              }
                      })}
                      </View>
                  </View>
                


























          <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                <View style={{width:'15%'}}>
                     <Text style={{fontSize:18,marginRight:5}}>{"Problem Description: "}</Text>
                </View>
                <TextInput
                style={{height: 40,
                  // margin: 12,
                  width:"80%",
                  borderWidth: 1,
                  backgroundColor:'white',
                  padding: 10,
                fontSize:18}}
                onChangeText={async(text)=> {
                  problem_tags_and_description.description = text
                  set_problem_tags_and_description(problem_tags_and_description)
                  await set_sub_show(false)
                  await set_sub_show(true)
                }}
              // value={objectiveFunction}
                // value={objectiveFunction}
                value = {problem_tags_and_description.description}
                // defaultValue={MaxObjectiveFunctionValue.value}
                // placeholder="0.1"
                // keyboardType="numeric"
              />
              {/* {max_obj_func_val_warning.value &&
              <TouchableOpacity style={{marginHorizontal:5}} 
                      onPress={()=>{
                          if(ProblemType=="sne"){
                            set_show_modal({show:true,text:sne_max_obj_func_val_error})
                          } else if(ProblemType=="optimization"){
                            set_show_modal({show:true,text:opt_max_obj_func_val_error})
                          }
                      }}
                  >
                    <Feather  name="alert-triangle" size={32} color="orange"/>
              </TouchableOpacity>
              }
              {!max_obj_func_val_warning.value &&
                      <View style={{marginHorizontal:5}} 
                      // onPress={()=>{
                      //   set_show_modal({show:true,text:"Please ensure that the number of starting points is expressed as a positive integer. For example:\n\n100\n\nor\n\n1e2"})
                      // }}
                  >
                    <Feather  name="alert-triangle" size={32} color={theme_transparent} />
              </View>
              } */}
          </View>
</View>
  }
  </View>
</View>






















{/* 





<View style={styles.textview}>
      <Text style={styles.heading}>
        {"Maximum Acceptable Objective Function Value"}
      </Text>
      <Text style={styles.subheading}>
          {'All local minima with a larger objective function value than the "Maximum Acceptable Objective Function Value" will not be returned.'}
      </Text>
</View> */}
{/* {show_minimum_distance && 
<View>
        <TextInput
        style={{height: 40,
          // margin: 12,
          width:"80%",
          borderWidth: 1,
          padding: 10,
        fontSize:18}}
          onChangeText={(text)=> {
            // objectiveFunction = '$$'+text+'$$'
            // keyy = keyy + 1
            // textt = JSON.parse(text)
            // var a = '$$'+text+'$$'
            setMaxObjectiveFunctionValue(Number(text))
            // setobjectiveFunction()
            // this.setState({pagename:text})
            // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
            //   this.setState({ create_disabled: true, create_opacity: 0.45})
            // } else {
            //   this.setState({ create_disabled: false, create_opacity: 1})
            // }
          }}
        // value={objectiveFunction}
        placeholder="0.1"
        keyboardType="numeric"
      />
      </View>
  } */}
</View>}























































































{/* 


{ProblemType == "sne" && <View style={styles.subview}>
{/* <View style={{width:'100%',alignItems:'center',
  marginTop:15, 
  padding:10,
  borderRadius:10,
  borderWidth:1,
  backgroundColor:theme_blue_color_lightest,
  overflow:'hidden',}}>
    <View style={{
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        justifyContent:'center',
                      }}
              >
                <Text style={styles.topheading}>
                {"Decision Variables ("+Array.from(dec_var_list_set).length+")"}
                </Text>
                {showDecisionVariables &&
                <Text style={styles.subheading}>
                  {'The decision variables of the problem.'}
                </Text>
                }
    </View>
    <View style={{
      position:'absolute',
      left:'1.5%',
      width:'100%',
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      borderRadius:10,
      overflow:'hidden',
      
    }}>
          <TouchableOpacity style={{width:'100%'}}
                onPress={ () => {
                  if(showDecisionVariables){
                      set_show_decision_variables_button_name("caretdown")
                      setshowDecisionVariables(!showDecisionVariables)
                  } else {
                    set_show_decision_variables_button_name("caretup")
                    setshowDecisionVariables(!showDecisionVariables)
                  }
                }}
            >
                    <AntDesign name={show_decision_variables_button_name} size={32} color={show_decision_variables_button_color}></AntDesign>
            </TouchableOpacity>
    </View>

{showDecisionVariables &&
<View style={{width:'100%',justifyContent:'center',alignContent:'center', alignItems:'center'}}>
          <View 
          style={{
                          flexDirection:'row',
                          justifyContent:'left',
                          alignContent:'center',
                          textAlign:'center',
                          textAlignVertical:'center',
                          alignItems:'center',
                          width:'80%',
                          marginTop:5,
            }}>
                <TextInput
                    style={{height: 40,
                      width:"100%",
                      borderWidth: 1,
                      padding: 10,
                      fontSize:18,
                      backgroundColor:'white'
                  }}
                      onChangeText={text => onDecisionVariablesChangeText(text)}
                      // value={decisionVariableString}
                      value={decisionVariableString.value}
                      // value={decision_variable_string.value}
                    placeholder='x, y, z'
                />
                {(/[~`!#$%\^&*+=\-\[\]\\';/{}|\\":<>\?]/g.test(decisionVariableString)) &&
                  <TouchableOpacity style={{marginHorizontal:5}} 
                      onPress={()=>{
                        set_show_modal({show:true,text:"Decision variables can only be defined using uppercase and lowercase letters."})
                      }}
                  >
                    <Feather  name="alert-triangle" size={24} color="orange"/>
                  </TouchableOpacity>
                }
</View>
               
  
          <View style={{
                        flexDirection:'row',
                        justifyContent:'left',
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',
                        alignItems:'center',
                        width:'80%',
                        marginTop:5,
                      }}>
                    {(dec_var_list_set.length == 0) &&
                        <View>
                          <Text style={{fontSize:16}}> {""} </Text>
                        </View>
                    }
                    <View style={{
                        overflow:'hidden',
                        flexDirection:'row', 
                        flexWrap:'wrap',                 
                        justifyContent:'left',             
                        alignContent:'center',
                        textAlign:'center',
                        textAlignVertical:'center',           
                        alignItems:'center',
                        marginRight:15,
                        width:'100%',
                        }}>

                    {Array.from(dec_var_list_set).map((key,index) => {
                              if(key!=""){
                                  return <View style={{
                                    flexDirection:'row',
                                    justifyContent:'center',
                                    alignContent:'center',
                                    textAlign:'center',
                                    textAlignVertical:'center',
                                    alignItems:'center',
                                    backgroundColor:'white',
                                    borderRadius:10, 
                                    padding:5,
                                    marginRight:5,
                                    marginTop:5,
                                    overflow:'hidden',
                                    flexWrap:'wrap',
                                  }}
                                  key={key}>
                                  
                                    <View style={{flexWrap:'wrap'}}>
                                            <MathJaxSvg 
                                                fontSize={3.5}
                                                fontWeight="bold"
                                                fontCache={true}
                                              >
                                              {'$$'+String(key)+'$$'}
                                                
                                              </MathJaxSvg>
                                    </View>
                                          <TouchableOpacity style={{marginLeft:5}} 
                                              onPress={async()=>{
                                                  await onDecisionVariableDelete(key)
                                              }}>
                                              <Ionicons name="md-close-circle-outline" size={24} color="black" />
                                          </TouchableOpacity>                        
                                  </View>
                              }
                      })}
                      </View>
                  </View>
            </View>
  }
</View> */}












{/* <View style={styles.textview}>
  {textInputValues.length==0 &&
        <Text style={styles.topheading}>
          Equations
        </Text>
}
{textInputValues.length!=0 &&
        (<Text style={styles.topheading}>
          {"Equations ("+textInputValues.length+")"}
        </Text>)

  }

  <Text style={styles.subheading}>
    {'The system of nonlinear equations and inequalities to be solved. For example: \n1/x + y + z - 3 = 0\nx + 1/y + z -3 = 0\nx + y + 1/z - 3 = 0'}
    
  </Text>
</View> */}



    
      {/* <View key={textInput.length} 
      style={{
        width:'100%',
        justifyContent:'center',
        alignItems:'center'
      }}>
          <View style={{
            width:'75%',
            justifyContent:'space-evenly',
            alignContent:'center',
            alignItems:'center',
            flexDirection:'row'
          }}>
                <Button 
                title='Add Equation' 
                color="black"
                // style={{color:buttonColor,backgroundColor:buttonColor}}
                // color=buttonColor 
                onPress={async () => {
                  await setShow(false)
                  addTextInput(textInput.length)
                }} />
                <Button 
                title='Remove Equation'
                color="black"
                onPress={async () => {
                  // await setShow(false)
                  // var a = 
                  setTextInput(textInput.slice(0,-1))
                  // await setShow(true)
                }} />
          </View>
          {textInput.map((value, index) => {
            // setLatexString("a"+LatexString)
            return value
          })}
          <Text >{inputdatastring}</Text>
      </View>
      {show &&<View></View>} */}










{/* 
<View key={textInputValues.length} 
      style={{
        width:'100%',
        justifyContent:'center',
        alignItems:'center'
      }}>
          <View style={{
            width:'75%',
            justifyContent:'space-evenly',
            alignContent:'center',
            alignItems:'center',
            flexDirection:'row'
          }}>
              <View style={{borderRadius:10, overflow:'hidden',}}>
                  <Button 
                  title='Add Constraint' 
                  color={theme_blue_color}
                  // style={{color:buttonColor,backgroundColor:buttonColor}}
                  // color=buttonColor 
                  onPress={async () => {
                    await setShow(false)
                    // addTextInput(textInput.length)
                    var texttt = textInputValues
                    // console.log(textInputValues)
                    await texttt.push("")
                    // console.log(textInputValues)
                    await settextInputValues(texttt)
                    await setShow(true)
                  }} />
              </View>
              <View style={{borderRadius:10, overflow:'hidden',}}>
                  <Button 
                  title='Remove Constraint'
                  color={theme_blue_color}
                  onPress={async () => {
                    await setShow(false)
                    var texttt = textInputValues
                    texttt = texttt.slice(0,-1)
                    await settextInputValues(texttt)
                    await setShow(true)
                  }} />
              </View>
          </View>
          {textInputValues.map((text, key) => {
                      // return <TextInput
                      //   style={{height: 40, marginVertical: 10, borderColor: this.handleBorderColor(index), borderWidth: 1}}
                      //   onChangeText={text => this.onChangeText(text, index)} 
                      //   value={this.state.textArray[index]}
                      //   placeholder={`placeholder for ${index}`}
                      //   onFocus={() => this.setState({focusedIndex: index})}
                      //   onBlur={() => this.setState({focusedIndex: null})}
                      // />
                      return <View style={{
                        flexDirection:'row',
                        justifyContent:'center',
                        alignContent:'center',
                        textAlign:'center',
                        alignItems:'center',
                        width:'80%',
                        marginTop:20,
                      }}
                      key={key}>
                        <Text style={{fontSize:20}}> {"Constraint "+(key+1)+": "} </Text>
                        <TextInput 
                            style={styles.textinput} 
                            // key={key} 
                            // value={inputData.slice(key).text}
                            // defaultValue={inputData[textInput.length].text}
                            // value={inputData[key].text}
                            // defaultValue = {textInputValues[key]}
                            value = {textInputValues[key]}
                            onFocus={() => setfocusedIndex(key)}
                            // onBlur={() => setfocusedIndex(null)}
                            // onChangeText={(text)=>{
                            //   // as there are going to be a lot of setState calls
                            //   // we need access the prevState before we set the next state.
                            //   // setShow(false)
                            
                              
                            //   var dataa = textInputValuess
                            //   dataa[key] = text
                            //   settextInputValues(dataa)
                            
                            //   // setShow(true)
                            //   // this.setState(prevState => {
                            //   //   prevState.textArray[index] = text
                            //   //   return {
                            //   //     textArray: prevState.textArray
                            //   //   }
                            //   // }, () => console.log(this.state.textArray))
                            // }}
                            onChangeText={textd => onchhhangetext(textd,key)}
                            // onChangeText={async(text) => {
                            //   // console.log(text)
                            //   // console.log(inputData.slice(key).values())
                            //   // console.log("key")
                            //   // console.log(key)
                            //   // console.log("inputData[key].text")
                            //   // console.log(inputData[key].text)
                            //   // inputData[key].text = text
                            //   // setTextInputText(inputData)
                            //   // var textt = text
                            //   var dataArray = inputData
                            //   console.log(dataArray[key].text)
                            //   dataArray[key].text = text
                            //   console.log(dataArray[key].text)
                            //   await setTextInputText(dataArray)
                            //   console.log(inputData[key].text)
                            //   await setShow(false)
                            //   setShow(true)
                            //   // console.log()
                            //   // dataArray.forEach(element => {
                            //   //     if (element.index === key ){
                            //   //       element.text = text;
                            //   //     }
                            //   // })
                            //   // await setTextInputText(dataArray)
                            //   // console.log(inputData) 
                            //   // addValues(textt, key)
                            //   // console.log(inputData[key].text)
                            // }} 
                            // onChangeText={(text)=>{
                            //   textInputText[key] = text
                            //   console.log(textInputText.toString())
                            // }}  
                            
                        />
                        {SNEInputsValid[key] &&
                          <View style={{marginHorizontal:5}}>
                            <Ionicons name="md-checkmark-circle-outline" size={32} color="white" />
                          </View>
                        }
                        {!SNEInputsValid[key] &&
                        <TouchableOpacity style={{marginHorizontal:5}} onPress={async()=>{
                            if(textInputValues.length ==1){
                                  await setShow(false)
                                  var texttt = textInputValues
                                  texttt = texttt.slice(0,-1)
                                  settextInputValues(texttt)
                                  var iconss = SNEInputsValid
                                  iconss = iconss.slice(0,-1)
                                  await setSNEInputsValid(iconss)
                                  await setShow(true)
                            } else {
                                  await setShow(false)
                                  var texttt = textInputValues
                                  texttt.splice(key,1)
                                  await settextInputValues(texttt)
                                  var iconss = SNEInputsValid
                                  iconss.splice(key,1)
                                  await setSNEInputsValid(iconss)
                                  await setShow(true)
                            }
                        }}>
                            <Ionicons name="md-close-circle-outline" size={32} color="red" />
                        </TouchableOpacity>
                          
                        }
                      </View>
                  })} */}
          {/* {textInputValues.map((text, key) => {
              // return <TextInput
              //   style={{height: 40, marginVertical: 10, borderColor: this.handleBorderColor(index), borderWidth: 1}}
              //   onChangeText={text => this.onChangeText(text, index)} 
              //   value={this.state.textArray[index]}
              //   placeholder={`placeholder for ${index}`}
              //   onFocus={() => this.setState({focusedIndex: index})}
              //   onBlur={() => this.setState({focusedIndex: null})}
              // />
              return <View style={{
                flexDirection:'row',
                justifyContent:'center',
                alignContent:'center',
                textAlign:'center',
                alignItems:'center',
                width:'80%',
                marginTop:20,
              }}
              key={key}>
                <Text style={{fontSize:20}}> {"Equation "+(key+1)+": "} </Text>
                <TextInput 
                    style={styles.textinput} 
                    // key={key} 
                    // value={inputData.slice(key).text}
                    // defaultValue={inputData[textInput.length].text}
                    // value={inputData[key].text}
                    defaultValue = {textInputValues[key]}
                    // value = {textInputValues[key]}
                    onFocus={() => setfocusedIndex(key)}
                    // onBlur={() => setfocusedIndex(null)}
                    // onChangeText={(text)=>{
                    //   // as there are going to be a lot of setState calls
                    //   // we need access the prevState before we set the next state.
                    //   // setShow(false)
                    
                      
                    //   var dataa = textInputValues
                    //   dataa[key] = text
                    //   settextInputValues(dataa)
                    
                    //   // setShow(true)
                    //   // this.setState(prevState => {
                    //   //   prevState.textArray[index] = text
                    //   //   return {
                    //   //     textArray: prevState.textArray
                    //   //   }
                    //   // }, () => console.log(this.state.textArray))
                    // }}
                    onChangeText={textd => onchhangetext(textd,key)}
                    // onChangeText={async(text) => {
                    //   // console.log(text)
                    //   // console.log(inputData.slice(key).values())
                    //   // console.log("key")
                    //   // console.log(key)
                    //   // console.log("inputData[key].text")
                    //   // console.log(inputData[key].text)
                    //   // inputData[key].text = text
                    //   // setTextInputText(inputData)
                    //   // var textt = text
                    //   var dataArray = inputData
                    //   console.log(dataArray[key].text)
                    //   dataArray[key].text = text
                    //   console.log(dataArray[key].text)
                    //   await setTextInputText(dataArray)
                    //   console.log(inputData[key].text)
                    //   await setShow(false)
                    //   setShow(true)
                    //   // console.log()
                    //   // dataArray.forEach(element => {
                    //   //     if (element.index === key ){
                    //   //       element.text = text;
                    //   //     }
                    //   // })
                    //   // await setTextInputText(dataArray)
                    //   // console.log(inputData) 
                    //   // addValues(textt, key)
                    //   // console.log(inputData[key].text)
                    // }} 
                    // onChangeText={(text)=>{
                    //   textInputText[key] = text
                    //   console.log(textInputText.toString())
                    // }}  
                />
              </View>
          })} */}
          {/* <Text >{textInputValues}</Text> */}
      {/* </View> */}
      {/* {show &&<View></View>} */}

















    {/* <TextInput
    style={styles.textinput}
      onChangeText={(text)=> {
        // objectiveFunction = '$$'+text+'$$'
        setobjectiveFunction(text)
      }}
    // value={objectiveFunction}
    placeholder="(1/x + y + z - 3)^2 + (x + 1/y + z -3)^2 + (x + y + 1/z - 3)^2"
    // keyboardType="numeric"
  /> */}



    {/* <MathJaxSvg 
      fontSize={8}
      // color="red"
      fontCache={true}
    >
      
    {LatexString}
      
    </MathJaxSvg> */}



{/* 

<View style={styles.textview}>
  <Text style={styles.heading}>
    Bounds
  </Text>
  <Text style={styles.subheading}>
    {'The upper and lower limits for each decision variable / dimension. For example: \n"x":[-0.5,3], "y":[-0.5,3], "z":[-0.5,3]\nNote that this format must be followed strictly. '}
  </Text>
</View>
    <TextInput
    style={{height: 40,
      // margin: 12,
      width:"80%",
      borderWidth: 1,
      padding: 10,
    fontSize:18}}
      onChangeText={(text)=> {
        // objectiveFunction = '$$'+text+'$$'
        // keyy = keyy + 1
        
        var texta = "{"+text+"}"
        setsneLims(texta)
        setsneCustomLims(true)
        // var a = '$$'+text+'$$'
        // setLims(textt)
        // setobjectiveFunction()
        // this.setState({pagename:text})
        // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
        //   this.setState({ create_disabled: true, create_opacity: 0.45})
        // } else {
        //   this.setState({ create_disabled: false, create_opacity: 1})
        // }
      }}
    // value={objectiveFunction}
    placeholder='"x":[-0.5,3], "y":[-0.5,3], "z":[-0.5,3]'
    // keyboardType="numeric"
  /> */}

{/* <View style={styles.textview}> */}
  {/* <Text  style={styles.heading}>
    Maximum Number of Starting Points
  </Text>
  <Text style={styles.subheading}>
      {'The maximum number of uniformly distributed starting points that local search will start from.'}
  </Text> */}
  {/* <Text  style={styles.heading}>
    Maximum Number of Solutions
  </Text>
  <Text style={styles.subheading}>
      {'The maximum number of solutions that the solver will attempt to return.'}
  </Text>
</View>
    <TextInput
    style={{height: 40,
      // margin: 12,
      width:"80%",
      borderWidth: 1,
      padding: 10,
    fontSize:18}}
      onChangeText={(text)=> {
        // objectiveFunction = '$$'+text+'$$'
        // keyy = keyy + 1
        // textt = JSON.parse(text)
        // var a = '$$'+text+'$$'
        setsnenPoints(Number(text))
        // setobjectiveFunction()
        // this.setState({pagename:text})
        // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
        //   this.setState({ create_disabled: true, create_opacity: 0.45})
        // } else {
        //   this.setState({ create_disabled: false, create_opacity: 1})
        // }
      }}
    // value={objectiveFunction}
    placeholder="10"
    keyboardType="numeric"
  />

<View style={styles.textview}>
  <Text style={styles.heading}>
    Minimum Acceptable Distance Between Unique Solutions
  </Text>
  <Text style={styles.subheading}> */}
    {/* {'The minimum acceptable euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered to be "distinct". The solver will attempt to return only "distinct" solutions. If two approximate solutions are found and are closer than the "Minimum Acceptable Distance Between Solutions", the solution with the lower objective function value will be returned. Note that the Minimum Acceptable Distance Between Solutions is not enforced strictly, and that some solutions returned by the solver may slighly violate the "Minimum Acceptable Distance Between Solutions".'} */}
    {/* {'The minimum acceptable Euclidean distance (L-2 norm) that approximate solutions need to be from each other to be considered "distinct". The solver will only return "distinct" solutions. If two approximate solutions are found that are closer than the "Minimum Acceptable Distance Between Unique Solutions", the solution with the lower objective function value will be returned.'} */}
  {/* </Text> */}
{/* </View> */}
    {/* <TextInput
    style={{height: 40,
      // margin: 12,
      width:"80%",
      borderWidth: 1,
      padding: 10,
    fontSize:18}}
      onChangeText={(text)=> {
        // objectiveFunction = '$$'+text+'$$'
        // keyy = keyy + 1
        // textt = JSON.parse(text)
        // var a = '$$'+text+'$$'
        setsneminDistance(Number(text))
        // setobjectiveFunction()
        // this.setState({pagename:text})
        // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
        //   this.setState({ create_disabled: true, create_opacity: 0.45})
        // } else {
        //   this.setState({ create_disabled: false, create_opacity: 1})
        // }
      }}
    // value={objectiveFunction}
    placeholder="0.1"
    keyboardType="numeric"
  /> */}
{/* 
<View style={styles.textview}>
  <Text style={styles.heading}>
    {"Maximum Acceptable Error"}
  </Text>
  <Text style={styles.subheading}>
      {'All approximate solutions to the system of nonlinear equations and inequalities with a larger error value than the "Maximum Acceptable Error" will not be returned. The error is calculated by taking the sum of the absolute values of the left hand side of each equation.'}
  </Text>
</View>
    <TextInput
    style={{height: 40,
      // margin: 12,
      width:"80%",
      borderWidth: 1,
      padding: 10,
    fontSize:18}}
      onChangeText={(text)=> {
        // objectiveFunction = '$$'+text+'$$'
        // keyy = keyy + 1
        // textt = JSON.parse(text)
        // var a = '$$'+text+'$$'
        setsneMaxObjectiveFunctionValue(Number(text))
        // setobjectiveFunction()
        // this.setState({pagename:text})
        // if(this.state.pagename.length == 0 && this.state.pagebio.length == 0){
        //   this.setState({ create_disabled: true, create_opacity: 0.45})
        // } else {
        //   this.setState({ create_disabled: false, create_opacity: 1})
        // }
      }}
    // value={objectiveFunction}
    placeholder="0.1"
    keyboardType="numeric"
  />
  
</View> */}





{/* } */}






{/* {(!isProcessing && (ProblemType!="account"))?( */}
  {(!isProcessing && (ProblemType=="optimization" || ProblemType=="sne"))?(
<TouchableOpacity style={{width:'20%',paddingVertical:25}}>
  <View style={{borderRadius:10, overflow:'hidden',}}>
          <Button
          title="Solve"
          color={theme_blue_color}
          
          // onPress={pickDocument}
  onPress={solve}
//   onPress = {async()=>{
//     setisProcessing(true)
//     var time =  await new Date().getTime() / 1000
//     if (ProblemType == "sne"){
//         var objectiveFunctionConstructor = ""
//         for(var i = 0; i <  textInputValues.length; i++) {
//           var splitt = textInputValues[i].replace(/\s+/g, '').split("=")
//           if( splitt.length>2 | splitt[splitt.length-1]!="0") {
//             alert("Equation "+String(i+1)+' is not structured properly. Please structure each equation in a form such as "a^2-b^2-1=0"')
//             setisProcessing(false)
//             return 
//           }
    
//           if (objectiveFunctionConstructor.length == 0){
//             objectiveFunctionConstructor = "abs(" + splitt[0] + ")"
//           } else {
//             objectiveFunctionConstructor = objectiveFunctionConstructor + "+ abs(" + splitt[0] + ")"
//           }
//         }
//         var obj_func = objectiveFunctionConstructor
//         // objectiveFunction = objectiveFunctionConstructor
//         // // setobjectiveFunction(objectiveFunctionConstructor)
//         // // setSolverRuntime(time)
//         // console.log(objectiveFunctionConstructor)
//         // console.log(objectiveFunction)
//     } else {
//       var obj_func = objectiveFunction
//     }
    
//     try{
//       // var paramss = JSON.stringify({"Lims":Lims,"nPoints":nPoints})
//       // var paramss = ({"Lims":Lims,"nPoints":nPoints}).toString()
//       // var obj = objectiveFunction.replaceAll("+","%2b").replaceAll(" ","%20")
//       // var data = JSON.stringify({objective: objectiveFunction, Lims:Lims, nPoints:nPoints, minDistance:minDistance})
//       if(CustomLims){
//         var limsParsed= JSON.parse(Lims)
//         // console.log("if")
//       } else {
//         var limsParsed = Lims
//         // console.log("else")
//       }
      
//       // console.log(limsParsed)
//       var payload  = {objective: obj_func, Lims:limsParsed, MaxObjectiveFunctionValue:MaxObjectiveFunctionValue, nPoints:nPoints, minDistance:minDistance}
//       // console.log(payload)
//       // var data = new FormData();
//       // await data.append( "json", JSON.stringify( payload ) );
// await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload)})
//         // console.log('result =  ', result)
//         // resultjson = JSON.parse(result)  
//         // bodyy = resultjson.body
//         // console.log(bodyy)
//         // console.log(bodyy.solutions)
//         // setResponse(JSON.stringify(result))
//       // await fetch(lambdaUrl, {method:"POST",body:JSON.stringify(payload)})
//       // var finalresult = await JSON.stringify(result)
//       // setResponse(finalresult)
//       // setResponse(result)
//       // const rawResponse = await fetch(lambdaUrl, {
//       //   method: 'POST',
//       //   headers: {
//       //     'Accept': 'application/json',
//       //     'Content-Type': 'application/json'
//       //   },
//       //   body: JSON.stringify({a: 1, b: 'Textual content'})
//       // })
      
      
//       // await Axios.post(lambdaUrl, {objective: objectiveFunction, Lims:Lims, nPoints:nPoints, minDistance:minDistance}) 
//       // await Axios({
//       //   method: 'GET',
//       //   // url: lambdaUrl+"?"+
//       //   url: lambdaUrl,
//       //   data: data, 
//       //     headers: {
//       //       // Overwrite Axios's automatically set Content-Type
//       //       'Content-Type': 'application/json'
//       //     }
        
//       //   // data: {objective: objectiveFunction, Lims:Lims, nPoints:nPoints, minDistance:minDistance}
//       //   // "objective="+obj+
//       //   // "&paramss="+paramss+
//       //   // // {%22Lims%22:{%20%22x%22:[-0.5,3],%20%22y%22:[-0.5,3],%20%22z%22:[-0.5,3]%20},%22tol%22:0.0000001,%22md%22:0.01,%22nPoints%22:500}&minDistance=0.01"
//       //   // "&minDistance="+mindistance
//       //   // "sessionid="+this.state.session.id+
//       //   // "&pagename="+this.state.pagename+
//       //   // "&pageid="+this.state.page.id+
//       //   // "&pagebio="+this.state.pagebio+
//       //   // "&profilepic="+this.state.connections.publicbucketurl+this.state.profilepic+
//       //   // "&profileheaderpic="+this.state.connections.publicbucketurl+this.state.profileheaderpic+
//       //   // "&edgeid="+edgeid+
//       //   // "&starttime="+time
//       // })
//       // .then(response => setResponse(response.text()))
//       .then(async(result) => {
//         var resultjson = await result.json()
//         var newtime =  await new Date().getTime() / 1000
//         // var timedif = newtime-time
//         setSolverRuntime(newtime-time)
//           // console.log('result =  ', resultjson)
//           // var resultjson = result.json()
//           // console.log(resultjson.objective)
//           // console.log(resultjson)
//           await setResponse(resultjson)
//         //   setResponse(resultjson.map(function(e) {
//         //     return Object.keys(e).reduce(function(p,n){
//         //        if(n.startsWith("solution"))
//         //            p[n] = Math.round(e[n]*100)/100;
//         //        else
//         //            p[n] = e[n];
//         //        return p;
//         //     },{})
//         // });)
//           await setSolutionsReturned(true)

//           setisProcessing(false)

//           // setResponse(JSON.stringify(resultjson))
//           // var b = JSON.stringify(resultjson)
//           // console.log(b[0])
//           // console.log(b[0].objective)
//           // // b
//           // setDATA([
//           //   {
//           //     id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
//           //     // title: b.solutions[0].solution,
//           //     title: b.objective,
//           //   },
//           //   {
//           //     id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
//           //     title: 'Fifth Item',
//           //   },
//           //   {
//           //     id: '58694a0f-3da1-471f-bd96-145571e29d72',
//           //     title: 'Sixth Item',
//           //   },
//           // ])
//           // var a = await JSON.stringify(result)
//           // setResponse(a)



//       //     // setResponse("ya digg")
//       //     // console.log('result.data =  ', result.data)
          
//       //     //console.log(result.data[0].page)
//       //     //return
//       //     //result.data[0].page.ownpage=true
//       //     // if(result.data=='A page with that name exists already'){
//       //     //   alert ('A page with that name exists already')
//       //     //   await this.setState({
//       //     //     pagenameselected: false,
//       //     //     refreshing: false,
//       //     //     pagename:'',
//       //     //     processing:false,
//       //     //     isFetching:false
//       //     //    })
//       //     //    this.state.failed=true
//       //     //    return
//       //     // }
          
//       //     // this.props.navigation.navigate('MainFeed', {screen: 'PageClick', params:{page:{
//       //     //   profileheaderpic:this.state.nextpageprofileheaderpic,
//       //     //   profilepic:this.state.nextpageprofilepic,
//       //     //   name:this.state.pagename,
//       //     //   nameid:this.state.pagename.toLowerCase(),
//       //     //   bio:this.state.pagebio,
//       //     //   id:this.state.page.id,
//       //     //   ownpage:true,
//       //     //   newpage:true,
//       //     //   posts:[],
//       //     //   followers:0
        
//       //     // }}})
    
//       //     // await pagestore.default.dispatch({ 
//       //     //     type: "update",
//       //     //     payload: {
//       //     //         page : {properties:result.data[0].page, ownpage:true}
//       //     //     } 
//       //     // })
//       //     //this.state.processing=false
//       })
//     } catch (err){
//       console.log(err)
//       setResponse(JSON.stringify(err))
//       setisProcessing(false)
//       // setResponse(err.text())
//       // setResponse("ya digg")
//     }
//   }}
  >
  </Button>
  </View>
{/* </TouchableOpacity>):((isProcessing&&((ProblemType!="account"))&& */}
</TouchableOpacity>):((isProcessing&&(true))&&

      <View style={{ 
        // width: '80%',
        // height: '100%',
        // backgroundColor: '#131315',
        justifyContent:'center',
        alignItems: 'center', 
        width:'20%',
        paddingVertical:25
    
      }}>
      
      {/* <ActivityIndicator size="large" color={theme_blue_color}/> */}
      </View>
      )}


{/* <FlatList data={DATA} renderItem={renderItem2} keyExtractor={item => item.id} /> */}


{/* <FlatList data={response.solutions} renderItem={renderItem} keyExtractor={item => item.obj_func_val} /> */}




{((ProblemType=="sne")  && show && SolutionsReturned.value) && 
<View style={{width:'80%',marginTop:0, justifyContent:"center", alignItems:'center'}}>

{/* <View style={styles.textview}>
  <Text style={styles.heading}>{"Solver Runtime: "}</Text>
  <Text style={styles.subheading}>{(Math.round(SolverRuntime*1000000)/1000000)+" seconds"}</Text>
</View> */}
<View style={{    width:'75%',
    justifyContent:"center",
    alignItems:'center',
    textAlign:'center',
    // backgroundColor:'#F2F2F2',
    marginTop:15,
    }}>
  <Text style={{    
    fontSize:24, 
    width:"100%",
    textAlign:'center',
    fontWeight:'bold',
    }}>
      {"Solutions ("+response.value.solutions.length+"):"}
  </Text>
  <TouchableOpacity style={{width:'20%',marginVertical:10}}>
  <View style={{borderRadius:10, overflow:'hidden',}}>
  <Button
          title="Download Solutions"
          // color={theme_blue_color}
          color={download_solutions_button_background_color.color}
          disabled={download_solutions_button_disabled.value}
    onPress={async()=>{
      // // const rows = [
      // //   ["name1", "city1", "some other info"],
      // //   ["name2", "city2", "more info"]
      // // ];
      // var sols = response.solutions
      // var rows = [[Object.keys(sols[0])]]
      // // const headers = Object.values(sols[0])
      // for(var i = 0; i <  sols.length; i++) {
      //     var newvals = []
      //     var vals = Object.values(sols[i])
      //     console.log("vals:")
      //     console.log(vals)
      //     for(var ii = 0; ii <  sols.length; ii++) {
      //         newvals.push([JSON.stringify(vals[ii])])
      //         // newvals.push()
      //     }
      //     console.log("newvals")
      //     console.log(newvals)
      //     rows.push([newvals])
      //     // rows.push([Object.values(sols[i])])
      // }
      // console.log("rows")
      // console.log(rows)
      // // const rows = Object.values(response.solutions)
      // let csvContent = "data:text/csv;charset=utf-8,";
      
      // rows.forEach(function(rowArray) {
      //     let row = rowArray.join(",");
      //     csvContent += row + "\r\n";
      // });
      // var encodedUri = encodeURI(csvContent);
      // window.open(encodedUri);



      // var sols = sneresponse.solutions
      var sols = []
      for(var ii = 0; ii <  response.value.solutions.length; ii++) {
        sols.push({solution:response.value.solutions[ii].solution,error:response.value.solutions[ii].obj_func_val})
      }
      // function exportToJsonFile(jsonData) {
      let dataStr = JSON.stringify(sols, null, "\t");
      let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      // window.open(dataUri);
      let exportFileDefaultName = 'data.json';
  
      let linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    





      // var json = response.solutions
      // var fields = Object.keys(json[0])
      // var replacer = function(key, value) { return value === null ? '' : value } 
      // var csv = json.map(function(row){
      //   return fields.map(function(fieldName){
      //     return JSON.stringify(row[fieldName], replacer)
      //   }).join(',')
      // })
      // csv.unshift(fields.join(',')) // add header column
      // csv = csv.join('\r\n');
      // var encodedUri = encodeURI(csv);
      // window.open(encodedUri);
    }}
  />
  </View>
    {/* <Text>Downlad Solutions</Text> */}
  </TouchableOpacity>
</View>

<FlatList
// refreshing={loadState.isFetching}
// showsVerticalScrollIndicator = {false} 
// ListHeaderComponent =  {ProfileHeader}
// initialNumToRender = {6}
// maxToRenderPerBatch = {3}
// contentContainerStyle={{ flexGrow: 1 }}
// ListEmptyComponent={EmptyComponent}
// // numColumns = {this.state.mediacolumns}
// numColumns = {3}
// refreshControl={
//     <RefreshControl
//       refreshing={loadState.isFetching}
//       onRefresh={handleRefresh}
//       tintColor = {'gray'}
//     />
// }
// extraData = {profileSwitch.switch2}
// extraData={response}
data={response.value.solutions}
// onScroll={this._onScroll}
renderItem={(item)=>{
  // console.log(item)
  item.item = {
    obj_func_val: Math.round(item.item.obj_func_val*1000)/1000, 
    // starting_point: Math.round(item.item.starting_point*1000)/1000,
    solution: [item.item.solution].map(function(e) {
      return Object.keys(e).reduce(function(p,n){
        //  if(n.startsWith("solution"))
             p[n] = Math.round(e[n]*1000)/1000
        //  else
            //  p[n] = e[n];
         return p;
      },{})
    }),
    // starting_point:  [item.item.starting_point].map(function(e) {
    //   return Object.keys(e).reduce(function(p,n){
    //     //  if(n.startsWith("solution"))
    //          p[n] = Math.round(e[n]*1000)/1000
    //     //  else
    //         //  p[n] = e[n];
    //      return p;
    //   },{})
    // }),
    // performance_metrics:  [item.item.performance_metrics].map(function(e) {
    //   return Object.keys(e).reduce(function(p,n){
    //     //  if(n.startsWith("solution"))
    //          p[n] = Math.round(e[n]*1000)/1000
    //     //  else
    //         //  p[n] = e[n];
    //      return p;
    //   },{})
    // }),
  }
  return (
  //   <View style={styles.item}>
  //   <Text style={{textAlign:'left'}} >{"Solution "+(item.index+1)+": "+(JSON.stringify(item.item.solution[0])).slice(1,-1)+",   Approximate Error: "+JSON.stringify(item.item.obj_func_val)}</Text>
  //   {/* <Text >{"Solution:"+JSON.stringify(item.item.solution)}</Text>
  //   <Text >{"Objective Function Value:"+JSON.stringify(item.item.obj_func_val)}</Text>
  //   <Text >{"Objective Function Value:"+JSON.stringify(item.item.obj_func_val)}</Text> */}
  // </View>
    <View style={{justifyContent:'center',}}>
      <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',width:'100%'}}>
        <View style={{justifyContent:'center'}}>
        {/* <View> */}
            {/* <Text style={{textAlign:'right'}}>{"Solution "+(item.index+1)+": "}</Text> */}
            <Text style={{textAlign:'right',fontSize:22}}>{(item.index+1)+":   "}</Text>
        </View>
        
      {/* <Text style={styles.item}> {(JSON.stringify(item.item.solution[0])).slice(1,-1)+"\nObjective Function Value: "+JSON.stringify(item.item.obj_func_val)}</Text> */}

        <View style={styles.item}>
          <Text style={{textAlign:'left'}}>{"Solution: "+(JSON.stringify(item.item.solution[0])).slice(1,-1)+"\n Error: "+JSON.stringify(item.item.obj_func_val)}</Text>
          {/* <Text style={{textAlign:'left'}} >{"Solution "+(item.index+1)+": "+(JSON.stringify(item.item.solution[0])).slice(1,-1)+";   \nObjective Function Value: "+JSON.stringify(item.item.obj_func_val)}</Text> */}
          {/* <Text >{"Solution:"+JSON.stringify(item.item.solution)}</Text>
          <Text >{"Objective Function Value:"+JSON.stringify(item.item.obj_func_val)}</Text>
        <Text >{"Objective Function Value:"+JSON.stringify(item.item.obj_func_val)}</Text> */}
      </View>
    </View>
    </View>
  )
}
}
// renderItem={renderItem}
keyExtractor={(item, index, numColumns ) => {
return index.toString() }}
/>
{/* <View style={styles.textview}>
  <Text style={styles.heading}>{"Pre-processing Time: "}</Text>
  <Text style={styles.subheading}>{(Math.round(response.pre_processing_time*1000000)/1000000)+" seconds"}</Text>
</View>
<View style={styles.textview}>
  <Text style={styles.heading}>{"Starting Points Used: "}</Text>
  <Text style={styles.subheading}>{response.nPoints}</Text> */}
  {/* <Text>{"Total Starting Points"+solutions.nPoints}</Text> */}
{/* </View> */}
{/* <View style={{height:50}}></View> */}
{/* <Text style={styles.title}>{"Raw Output"}</Text> */}
{/* <Text>{JSON.stringify(response)}</Text> */}
</View>}





















{((ProblemType=="optimization")  && show &&SolutionsReturned.value) && 
<View style={{width:'80%',marginTop:0, justifyContent:"center", alignItems:'center'}}>

{/* <View style={styles.textview}>
  <Text style={styles.heading}>{"Solver Runtime: "}</Text>
  <Text style={styles.subheading}>{(Math.round(SolverRuntime*1000000)/1000000)+" seconds"}</Text>
</View> */}
<View style={{    width:'75%',
    justifyContent:"center",
    alignItems:'center',
    textAlign:'center',
    // backgroundColor:'#F2F2F2',
    marginTop:15,
    }}>
  <Text style={{    
    fontSize:24, 
    width:"100%",
    textAlign:'center',
    fontWeight:'bold',
    }}>
      {"Solutions ("+response.value.solutions.length+"):"}
  </Text>
  <TouchableOpacity style={{width:'20%',marginVertical:10}}>
  <View style={{borderRadius:10, overflow:'hidden',}}>
  <Button
          title="Download Solutions"
          // color={theme_blue_color}
          color={download_solutions_button_background_color.color}
          disabled={download_solutions_button_disabled.value}
    onPress={async()=>{
      // // const rows = [
      // //   ["name1", "city1", "some other info"],
      // //   ["name2", "city2", "more info"]
      // // ];
      // var sols = response.solutions
      // var rows = [[Object.keys(sols[0])]]
      // // const headers = Object.values(sols[0])
      // for(var i = 0; i <  sols.length; i++) {
      //     var newvals = []
      //     var vals = Object.values(sols[i])
      //     console.log("vals:")
      //     console.log(vals)
      //     for(var ii = 0; ii <  sols.length; ii++) {
      //         newvals.push([JSON.stringify(vals[ii])])
      //         // newvals.push()
      //     }
      //     console.log("newvals")
      //     console.log(newvals)
      //     rows.push([newvals])
      //     // rows.push([Object.values(sols[i])])
      // }
      // console.log("rows")
      // console.log(rows)
      // // const rows = Object.values(response.solutions)
      // let csvContent = "data:text/csv;charset=utf-8,";
      
      // rows.forEach(function(rowArray) {
      //     let row = rowArray.join(",");
      //     csvContent += row + "\r\n";
      // });
      // var encodedUri = encodeURI(csvContent);
      // window.open(encodedUri);

      var sols = []
      // for(item in response.solutions){
      for(var ii = 0; ii <  response.value.solutions.length; ii++) {
          sols.push({solution:response.value.solutions[ii].solution,objective_function_value:response.value.solutions[ii].obj_func_val})
        // }
        // sols.push({solution:item.solution,objective_function_value:item.obj_func_val})
      }
      // var sols = {
      //   solution: response.solutions
      // }
      
      // function exportToJsonFile(jsonData) {
      let dataStr = JSON.stringify(sols, null, "\t");
      let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      // window.open(dataUri);
      let exportFileDefaultName = 'data.json';
  
      let linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    





      // var json = response.solutions
      // var fields = Object.keys(json[0])
      // var replacer = function(key, value) { return value === null ? '' : value } 
      // var csv = json.map(function(row){
      //   return fields.map(function(fieldName){
      //     return JSON.stringify(row[fieldName], replacer)
      //   }).join(',')
      // })
      // csv.unshift(fields.join(',')) // add header column
      // csv = csv.join('\r\n');
      // var encodedUri = encodeURI(csv);
      // window.open(encodedUri);
    }}
  />

  </View>
    {/* <Text>Downlad Solutions</Text> */}
  </TouchableOpacity>
</View>

<FlatList
style={{width:'100%'}}
// refreshing={loadState.isFetching}
// showsVerticalScrollIndicator = {false} 
// ListHeaderComponent =  {ProfileHeader}
// initialNumToRender = {6}
// maxToRenderPerBatch = {3}
// contentContainerStyle={{ flexGrow: 1 }}
// ListEmptyComponent={EmptyComponent}
// // numColumns = {this.state.mediacolumns}
// numColumns = {3}
// refreshControl={
//     <RefreshControl
//       refreshing={loadState.isFetching}
//       onRefresh={handleRefresh}
//       tintColor = {'gray'}
//     />
// }
// extraData = {profileSwitch.switch2}
// extraData={response.value}
data={response.value.solutions}
// onScroll={this._onScroll}
renderItem={(item)=>{
  // console.log(item)
  item.item = {
    obj_func_val: Math.round(item.item.obj_func_val*1000)/1000, 
    // starting_point: Math.round(item.item.starting_point*1000)/1000,
    solution: [item.item.solution].map(function(e) {
      return Object.keys(e).reduce(function(p,n){
        //  if(n.startsWith("solution"))
             p[n] = Math.round(e[n]*1000)/1000
        //  else
            //  p[n] = e[n];
         return p;
      },{})
    }),
    // starting_point:  [item.item.starting_point].map(function(e) {
    //   return Object.keys(e).reduce(function(p,n){
    //     //  if(n.startsWith("solution"))
    //          p[n] = Math.round(e[n]*1000)/1000
    //     //  else
    //         //  p[n] = e[n];
    //      return p;
    //   },{})
    // }),
    // performance_metrics:  [item.item.performance_metrics].map(function(e) {
    //   return Object.keys(e).reduce(function(p,n){
    //     //  if(n.startsWith("solution"))
    //          p[n] = Math.round(e[n]*1000)/1000
    //     //  else
    //         //  p[n] = e[n];
    //      return p;
    //   },{})
    // }),
  }
  return (
    <View style={{justifyContent:'center',}}>
      <View style={{flexDirection:'row',justifyContent:'center',alignContent:'center',width:'100%'}}>
        <View style={{justifyContent:'center'}}>
        {/* <View> */}
            {/* <Text style={{textAlign:'right'}}>{"Solution "+(item.index+1)+": "}</Text> */}
            <Text style={{textAlign:'right',fontSize:22}}>{(item.index+1)+":   "}</Text>
        </View>
        
      {/* <Text style={styles.item}> {(JSON.stringify(item.item.solution[0])).slice(1,-1)+"\nObjective Function Value: "+JSON.stringify(item.item.obj_func_val)}</Text> */}

        <View style={styles.item}>
          <Text style={{textAlign:'left'}}>{"Solution: "+(JSON.stringify(item.item.solution[0])).slice(1,-1)+"\n Objective Function Value: "+JSON.stringify(item.item.obj_func_val)}</Text>
        {/* <Text style={{textAlign:'left'}} >{"Solution "+(item.index+1)+": "+(JSON.stringify(item.item.solution[0])).slice(1,-1)+";   \nObjective Function Value: "+JSON.stringify(item.item.obj_func_val)}</Text> */}
        {/* <Text >{"Solution:"+JSON.stringify(item.item.solution)}</Text>
        <Text >{"Objective Function Value:"+JSON.stringify(item.item.obj_func_val)}</Text>
        <Text >{"Objective Function Value:"+JSON.stringify(item.item.obj_func_val)}</Text> */}
      </View>
    </View>
  </View>
  )
}
}
// renderItem={renderItem}
keyExtractor={(item, index, numColumns ) => {
return index.toString() }}
/>
{/* <View style={styles.textview}>
  <Text style={styles.heading}>{"Pre-processing Time: "}</Text>
  <Text style={styles.subheading}>{(Math.round(response.pre_processing_time*1000000)/1000000)+" seconds"}</Text>
</View>
<View style={styles.textview}>
  <Text style={styles.heading}>{"Starting Points Used: "}</Text>
  <Text style={styles.subheading}>{response.nPoints}</Text> */}
  {/* <Text>{"Total Starting Points"+solutions.nPoints}</Text> */}
{/* </View> */}
{/* <View style={{height:50}}></View> */}
{/* <Text style={styles.title}>{"Raw Output"}</Text> */}
{/* <Text>{JSON.stringify(response)}</Text> */}
</View>}



{/* <Text>{JSON.stringify(response)}</Text> */}

      {/* <MathJaxSvg 
  fontSize={16}
  // color="#000000"
  color='red'
  fontCache={true}
>

  {`
    <p>When \\(a \\ne 0\\), there are <u>two solutions</u> to \\(ax^2 + bx + c = 0\\) <span style="color:red;">and</span> they are $$x = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}.$$</p>
    <b>In-line Mathematics</b><br/><p>Finally, while display equations look good for a page of samples, the ability to mix math <mark>and text in a paragraph is also important.</mark><br/><b>This expression \\(\\sqrt{3x-1}+(1+x)^2\\) is an <span style="color:red;font-style:italic;">example of an inline equation</span>.</b>As you see, MathJax equations can be used this way as well, without unduly disturbing the <s>spacing between lines</s>.</p>
  `}
</MathJaxSvg> */}


      {/* <MathJax
  mathJaxOptions={mmlOptions}
  html={
    "$\(ax^2 + bx + c = 0\)$"
  }
/>
<MathJax
  mathJaxOptions={mmlOptions}
  html={
    "$x = (-b +- \\sqrt(b^2-4ac))/(2a)$"
  }
/>
<MathJax
  mathJaxOptions={mmlOptions}
  html={
    "z = $\\frac{1}{\\sqrt{x^2 + 1}}$"
  }
/>
      <MathJax
          mathJaxOptions={mmlOptions}
          html={
            "$sum_{i=0}^n i^2 = \\frac{(n^2+n)(2n+1)}{6}$"
          }
        /> */}
    
    </View>}
    </View>
    // </Provider>
  )
  // return null
}




const styles = StyleSheet.create({
  container: {
    // flex: 1,
    width:'80%',
    padding: 25,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // color:'red'
  },
  item: {
    // backgroundColor: '#f9c2ff',
    justifyContent:'center',
    // alignItems:'center',
    // backgroundColor:theme_light_pink_color,
    backgroundColor:theme_blue_color_light,
    // width:'100%',
    padding: 15,
    marginVertical: 8,
    // marginHorizontal: 16,
  },
  title: {
    fontSize: 24,
  },
  textview: {

  },
  subheading: {
    // fontSize:14,
    fontSize:18,
    // backgroundColor:'white',
    // fontWeight:'bold',
    // color:'#262626', 
    // backgroundColor:'#F2F2F2',
    // backgroundColor:theme_light_pink_color,
    // backgroundColor:theme_blue_color_light,
    // width:"100%",
    textAlign:'center',
    // marginVertical: 5,
    paddingVertical:5
  },
  heading: {
    fontSize:24, 
    width:"100%",
    textAlign:'center',
    fontWeight:'bold',
    // fontFamily:'Montserrat',
    
  },
  topheading: {
    fontSize:24, 
    // backgroundColor:'white',
    width:"100%", 
    textAlign:'center',
    fontWeight:'bold',
  },
  mainview: {
    top:'0%',
    // flex:1,
    width:'100%',
    justifyContent:'center', 
    alignItems:'center', 
    // padding: 25, 
    backgroundColor: '#fff', 
    // backgroundColor:'#F2F2F2'
    // backgroundColor:'rgba(0,0,0,0.05)',
  },
  subview: {
    width:"80%",
     justifyContent:"center", 
     padding:25, 
     alignItems:'center'
  },
  textinput: {
    height: 40,  
    width:"80%",
    // margin: 12,
    borderWidth: 1,
    padding: 10,
    fontSize:18,
    backgroundColor:'white',
},
button:{
  // color:"#0389ff"
  // color:'#026BC7'
  // color:theme_blue_color
  // color:'#0D6759',
  // color:'green'
}
})
;
export default withAuthenticator(App,{
  // ...signUpConfig,
  // includeGreetings: true,
  signUpConfig,
  theme:MyTheme,
  // theme:MyTheme
  authenticatorComponents:[<MySignIn/>]
},

);
// export default withAuthenticator(App,{...signUpConfig});