"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Barv: '\u2AE7',
    Barwed: '\u2306',
    Bcy: '\u0411',
    Bernoullis: '\u212C',
    Beta: '\u0392',
    Bumpeq: '\u224E',
    bNot: '\u2AED',
    backcong: '\u224C',
    backepsilon: '\u03F6',
    barvee: '\u22BD',
    barwed: '\u2305',
    barwedge: '\u2305',
    bbrk: '\u23B5',
    bbrktbrk: '\u23B6',
    bcong: '\u224C',
    bcy: '\u0431',
    bdquo: '\u201E',
    becaus: '\u2235',
    because: '\u2235',
    bemptyv: '\u29B0',
    bepsi: '\u03F6',
    bernou: '\u212C',
    bigcap: '\u22C2',
    bigcup: '\u22C3',
    bigvee: '\u22C1',
    bigwedge: '\u22C0',
    bkarow: '\u290D',
    blacksquare: '\u25AA',
    blacktriangleright: '\u25B8',
    blank: '\u2423',
    blk12: '\u2592',
    blk14: '\u2591',
    blk34: '\u2593',
    block: '\u2588',
    bne: '\u003D\u20E5',
    bnequiv: '\u2261\u20E5',
    bnot: '\u2310',
    bot: '\u22A5',
    bottom: '\u22A5',
    boxDL: '\u2557',
    boxDR: '\u2554',
    boxDl: '\u2556',
    boxDr: '\u2553',
    boxH: '\u2550',
    boxHD: '\u2566',
    boxHU: '\u2569',
    boxHd: '\u2564',
    boxHu: '\u2567',
    boxUL: '\u255D',
    boxUR: '\u255A',
    boxUl: '\u255C',
    boxUr: '\u2559',
    boxV: '\u2551',
    boxVH: '\u256C',
    boxVL: '\u2563',
    boxVR: '\u2560',
    boxVh: '\u256B',
    boxVl: '\u2562',
    boxVr: '\u255F',
    boxbox: '\u29C9',
    boxdL: '\u2555',
    boxdR: '\u2552',
    boxh: '\u2500',
    boxhD: '\u2565',
    boxhU: '\u2568',
    boxhd: '\u252C',
    boxhu: '\u2534',
    boxuL: '\u255B',
    boxuR: '\u2558',
    boxv: '\u2502',
    boxvH: '\u256A',
    boxvL: '\u2561',
    boxvR: '\u255E',
    boxvh: '\u253C',
    boxvl: '\u2524',
    boxvr: '\u251C',
    bprime: '\u2035',
    breve: '\u02D8',
    brvbar: '\u00A6',
    bsemi: '\u204F',
    bsim: '\u223D',
    bsime: '\u22CD',
    bsolb: '\u29C5',
    bsolhsub: '\u27C8',
    bullet: '\u2022',
    bump: '\u224E',
    bumpE: '\u2AAE',
    bumpe: '\u224F',
    bumpeq: '\u224F'
}, 'b');
