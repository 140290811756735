"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Aopf: '\uD835\uDD38',
    Bopf: '\uD835\uDD39',
    Copf: '\u2102',
    Dopf: '\uD835\uDD3B',
    Eopf: '\uD835\uDD3C',
    Fopf: '\uD835\uDD3D',
    Gopf: '\uD835\uDD3E',
    Hopf: '\u210D',
    Iopf: '\uD835\uDD40',
    Jopf: '\uD835\uDD41',
    Kopf: '\uD835\uDD42',
    Lopf: '\uD835\uDD43',
    Mopf: '\uD835\uDD44',
    Nopf: '\u2115',
    Oopf: '\uD835\uDD46',
    Popf: '\u2119',
    Qopf: '\u211A',
    Ropf: '\u211D',
    Sopf: '\uD835\uDD4A',
    Topf: '\uD835\uDD4B',
    Uopf: '\uD835\uDD4C',
    Vopf: '\uD835\uDD4D',
    Wopf: '\uD835\uDD4E',
    Xopf: '\uD835\uDD4F',
    Yopf: '\uD835\uDD50',
    Zopf: '\u2124',
    aopf: '\uD835\uDD52',
    bopf: '\uD835\uDD53',
    copf: '\uD835\uDD54',
    dopf: '\uD835\uDD55',
    eopf: '\uD835\uDD56',
    fopf: '\uD835\uDD57',
    gopf: '\uD835\uDD58',
    hopf: '\uD835\uDD59',
    iopf: '\uD835\uDD5A',
    jopf: '\uD835\uDD5B',
    kopf: '\uD835\uDD5C',
    lopf: '\uD835\uDD5D',
    mopf: '\uD835\uDD5E',
    nopf: '\uD835\uDD5F',
    oopf: '\uD835\uDD60',
    popf: '\uD835\uDD61',
    qopf: '\uD835\uDD62',
    ropf: '\uD835\uDD63',
    sopf: '\uD835\uDD64',
    topf: '\uD835\uDD65',
    uopf: '\uD835\uDD66',
    vopf: '\uD835\uDD67',
    wopf: '\uD835\uDD68',
    xopf: '\uD835\uDD69',
    yopf: '\uD835\uDD6A',
    zopf: '\uD835\uDD6B'
}, 'opf');
