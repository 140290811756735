"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    KHcy: '\u0425',
    KJcy: '\u040C',
    Kappa: '\u039A',
    Kcedil: '\u0136',
    Kcy: '\u041A',
    kcedil: '\u0137',
    kcy: '\u043A',
    kgreen: '\u0138',
    khcy: '\u0445',
    kjcy: '\u045C'
}, 'k');
