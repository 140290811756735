"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SymbolMap_js_1 = require("../SymbolMap.js");
var TexConstants_js_1 = require("../TexConstants.js");
var TextMacrosMethods_js_1 = require("./TextMacrosMethods.js");
new SymbolMap_js_1.MacroMap('text-special', {
    '$': 'Math',
    '%': 'Comment',
    '^': 'MathModeOnly',
    '_': 'MathModeOnly',
    '&': 'Misplaced',
    '#': 'Misplaced',
    '~': 'Tilde',
    ' ': 'Space',
    '\t': 'Space',
    '\r': 'Space',
    '\n': 'Space',
    '\u00A0': 'Tilde',
    '{': 'OpenBrace',
    '}': 'CloseBrace',
    '`': 'OpenQuote',
    '\'': 'CloseQuote'
}, TextMacrosMethods_js_1.TextMacrosMethods);
new SymbolMap_js_1.CommandMap('text-macros', {
    '(': 'Math',
    '$': 'SelfQuote',
    '_': 'SelfQuote',
    '%': 'SelfQuote',
    '{': 'SelfQuote',
    '}': 'SelfQuote',
    ' ': 'SelfQuote',
    '&': 'SelfQuote',
    '#': 'SelfQuote',
    '\\': 'SelfQuote',
    '\'': ['Accent', '\u00B4'],
    '\u2019': ['Accent', '\u00B4'],
    '`': ['Accent', '\u0060'],
    '\u2018': ['Accent', '\u0060'],
    '^': ['Accent', '^'],
    '\"': ['Accent', '\u00A8'],
    '~': ['Accent', '~'],
    '=': ['Accent', '\u00AF'],
    '.': ['Accent', '\u02D9'],
    'u': ['Accent', '\u02D8'],
    'v': ['Accent', '\u02C7'],
    emph: 'Emph',
    rm: ['SetFont', TexConstants_js_1.TexConstant.Variant.NORMAL],
    mit: ['SetFont', TexConstants_js_1.TexConstant.Variant.ITALIC],
    oldstyle: ['SetFont', TexConstants_js_1.TexConstant.Variant.OLDSTYLE],
    cal: ['SetFont', TexConstants_js_1.TexConstant.Variant.CALLIGRAPHIC],
    it: ['SetFont', '-tex-mathit'],
    bf: ['SetFont', TexConstants_js_1.TexConstant.Variant.BOLD],
    bbFont: ['SetFont', TexConstants_js_1.TexConstant.Variant.DOUBLESTRUCK],
    scr: ['SetFont', TexConstants_js_1.TexConstant.Variant.SCRIPT],
    frak: ['SetFont', TexConstants_js_1.TexConstant.Variant.FRAKTUR],
    sf: ['SetFont', TexConstants_js_1.TexConstant.Variant.SANSSERIF],
    tt: ['SetFont', TexConstants_js_1.TexConstant.Variant.MONOSPACE],
    tiny: ['SetSize', 0.5],
    Tiny: ['SetSize', 0.6],
    scriptsize: ['SetSize', 0.7],
    small: ['SetSize', 0.85],
    normalsize: ['SetSize', 1.0],
    large: ['SetSize', 1.2],
    Large: ['SetSize', 1.44],
    LARGE: ['SetSize', 1.73],
    huge: ['SetSize', 2.07],
    Huge: ['SetSize', 2.49],
    mathcal: 'MathModeOnly',
    mathscr: 'MathModeOnly',
    mathrm: 'MathModeOnly',
    mathbf: 'MathModeOnly',
    mathbb: 'MathModeOnly',
    mathit: 'MathModeOnly',
    mathfrak: 'MathModeOnly',
    mathsf: 'MathModeOnly',
    mathtt: 'MathModeOnly',
    Bbb: ['Macro', '{\\bbFont #1}', 1],
    textrm: ['Macro', '{\\rm #1}', 1],
    textit: ['Macro', '{\\it #1}', 1],
    textbf: ['Macro', '{\\bf #1}', 1],
    textsf: ['Macro', '{\\sf #1}', 1],
    texttt: ['Macro', '{\\tt #1}', 1],
    dagger: ['Insert', '\u2020'],
    ddagger: ['Insert', '\u2021'],
    S: ['Insert', '\u00A7'],
    ',': ['Spacer', TexConstants_js_1.TexConstant.Length.THINMATHSPACE],
    ':': ['Spacer', TexConstants_js_1.TexConstant.Length.MEDIUMMATHSPACE],
    '>': ['Spacer', TexConstants_js_1.TexConstant.Length.MEDIUMMATHSPACE],
    ';': ['Spacer', TexConstants_js_1.TexConstant.Length.THICKMATHSPACE],
    '!': ['Spacer', TexConstants_js_1.TexConstant.Length.NEGATIVETHINMATHSPACE],
    enspace: ['Spacer', '.5em'],
    quad: ['Spacer', '1em'],
    qquad: ['Spacer', '2em'],
    thinspace: ['Spacer', TexConstants_js_1.TexConstant.Length.THINMATHSPACE],
    negthinspace: ['Spacer', TexConstants_js_1.TexConstant.Length.NEGATIVETHINMATHSPACE],
    hskip: 'Hskip',
    hspace: 'Hskip',
    kern: 'Hskip',
    mskip: 'Hskip',
    mspace: 'Hskip',
    mkern: 'Hskip',
    rule: 'rule',
    Rule: ['Rule'],
    Space: ['Rule', 'blank'],
    color: 'CheckAutoload',
    textcolor: 'CheckAutoload',
    colorbox: 'CheckAutoload',
    fcolorbox: 'CheckAutoload',
    href: 'CheckAutoload',
    style: 'CheckAutoload',
    class: 'CheckAutoload',
    cssId: 'CheckAutoload',
    unicode: 'CheckAutoload',
    ref: ['HandleRef', false],
    eqref: ['HandleRef', true],
}, TextMacrosMethods_js_1.TextMacrosMethods);
