"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Uacute: '\u00DA',
    Uarr: '\u219F',
    Uarrocir: '\u2949',
    Ubrcy: '\u040E',
    Ubreve: '\u016C',
    Ucirc: '\u00DB',
    Ucy: '\u0423',
    Udblac: '\u0170',
    Ugrave: '\u00D9',
    Umacr: '\u016A',
    UnderBracket: '\u23B5',
    UnderParenthesis: '\u23DD',
    Uogon: '\u0172',
    UpArrowBar: '\u2912',
    UpArrowDownArrow: '\u21C5',
    UpEquilibrium: '\u296E',
    UpTeeArrow: '\u21A5',
    UpperLeftArrow: '\u2196',
    UpperRightArrow: '\u2197',
    Upsi: '\u03D2',
    Uring: '\u016E',
    Utilde: '\u0168',
    Uuml: '\u00DC',
    uArr: '\u21D1',
    uHar: '\u2963',
    uacute: '\u00FA',
    uarr: '\u2191',
    ubrcy: '\u045E',
    ubreve: '\u016D',
    ucirc: '\u00FB',
    ucy: '\u0443',
    udarr: '\u21C5',
    udblac: '\u0171',
    udhar: '\u296E',
    ufisht: '\u297E',
    ugrave: '\u00F9',
    uharl: '\u21BF',
    uharr: '\u21BE',
    uhblk: '\u2580',
    ulcorn: '\u231C',
    ulcorner: '\u231C',
    ulcrop: '\u230F',
    ultri: '\u25F8',
    umacr: '\u016B',
    uml: '\u00A8',
    uogon: '\u0173',
    uparrow: '\u2191',
    updownarrow: '\u2195',
    upharpoonleft: '\u21BF',
    upharpoonright: '\u21BE',
    uplus: '\u228E',
    upsih: '\u03D2',
    upsilon: '\u03C5',
    urcorn: '\u231D',
    urcorner: '\u231D',
    urcrop: '\u230E',
    uring: '\u016F',
    urtri: '\u25F9',
    utdot: '\u22F0',
    utilde: '\u0169',
    utri: '\u25B5',
    utrif: '\u25B4',
    uuarr: '\u21C8',
    uuml: '\u00FC',
    uwangle: '\u29A7'
}, 'u');
