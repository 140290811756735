"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    ENG: '\u014A',
    ETH: '\u00D0',
    Eacute: '\u00C9',
    Ecaron: '\u011A',
    Ecirc: '\u00CA',
    Ecy: '\u042D',
    Edot: '\u0116',
    Egrave: '\u00C8',
    Emacr: '\u0112',
    EmptySmallSquare: '\u25FB',
    EmptyVerySmallSquare: '\u25AB',
    Eogon: '\u0118',
    Epsilon: '\u0395',
    Equal: '\u2A75',
    Esim: '\u2A73',
    Eta: '\u0397',
    Euml: '\u00CB',
    eDDot: '\u2A77',
    eDot: '\u2251',
    eacute: '\u00E9',
    easter: '\u2A6E',
    ecaron: '\u011B',
    ecirc: '\u00EA',
    ecolon: '\u2255',
    ecy: '\u044D',
    edot: '\u0117',
    ee: '\u2147',
    eg: '\u2A9A',
    egrave: '\u00E8',
    egsdot: '\u2A98',
    el: '\u2A99',
    elinters: '\u23E7',
    elsdot: '\u2A97',
    emacr: '\u0113',
    emptyset: '\u2205',
    emptyv: '\u2205',
    emsp: '\u2003',
    emsp13: '\u2004',
    emsp14: '\u2005',
    eng: '\u014B',
    ensp: '\u2002',
    eogon: '\u0119',
    epar: '\u22D5',
    eparsl: '\u29E3',
    eplus: '\u2A71',
    epsilon: '\u03B5',
    eqcirc: '\u2256',
    eqcolon: '\u2255',
    eqsim: '\u2242',
    eqslantgtr: '\u2A96',
    eqslantless: '\u2A95',
    equals: '\u003D',
    equest: '\u225F',
    equiv: '\u2261',
    equivDD: '\u2A78',
    eqvparsl: '\u29E5',
    erarr: '\u2971',
    esdot: '\u2250',
    esim: '\u2242',
    euml: '\u00EB',
    euro: '\u20AC',
    excl: '\u0021',
    exist: '\u2203',
    expectation: '\u2130',
    exponentiale: '\u2147'
}, 'e');
