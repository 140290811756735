"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    AElig: '\u00C6',
    AMP: '\u0026',
    Aacute: '\u00C1',
    Abreve: '\u0102',
    Acirc: '\u00C2',
    Acy: '\u0410',
    Agrave: '\u00C0',
    Alpha: '\u0391',
    Amacr: '\u0100',
    And: '\u2A53',
    Aogon: '\u0104',
    Aring: '\u00C5',
    Assign: '\u2254',
    Atilde: '\u00C3',
    Auml: '\u00C4',
    aacute: '\u00E1',
    abreve: '\u0103',
    ac: '\u223E',
    acE: '\u223E\u0333',
    acd: '\u223F',
    acirc: '\u00E2',
    acy: '\u0430',
    aelig: '\u00E6',
    af: '\u2061',
    agrave: '\u00E0',
    alefsym: '\u2135',
    amacr: '\u0101',
    andand: '\u2A55',
    andd: '\u2A5C',
    andslope: '\u2A58',
    andv: '\u2A5A',
    ange: '\u29A4',
    angle: '\u2220',
    angmsdaa: '\u29A8',
    angmsdab: '\u29A9',
    angmsdac: '\u29AA',
    angmsdad: '\u29AB',
    angmsdae: '\u29AC',
    angmsdaf: '\u29AD',
    angmsdag: '\u29AE',
    angmsdah: '\u29AF',
    angrt: '\u221F',
    angrtvb: '\u22BE',
    angrtvbd: '\u299D',
    angst: '\u00C5',
    angzarr: '\u237C',
    aogon: '\u0105',
    ap: '\u2248',
    apE: '\u2A70',
    apacir: '\u2A6F',
    apid: '\u224B',
    apos: '\u0027',
    approx: '\u2248',
    approxeq: '\u224A',
    aring: '\u00E5',
    ast: '\u002A',
    asymp: '\u2248',
    asympeq: '\u224D',
    atilde: '\u00E3',
    auml: '\u00E4',
    awconint: '\u2233',
    awint: '\u2A11'
}, 'a');
