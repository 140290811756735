"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    THORN: '\u00DE',
    TRADE: '\u2122',
    TSHcy: '\u040B',
    TScy: '\u0426',
    Tab: '\u0009',
    Tau: '\u03A4',
    Tcaron: '\u0164',
    Tcedil: '\u0162',
    Tcy: '\u0422',
    ThickSpace: '\u205F\u200A',
    ThinSpace: '\u2009',
    TripleDot: '\u20DB',
    Tstrok: '\u0166',
    target: '\u2316',
    tbrk: '\u23B4',
    tcaron: '\u0165',
    tcedil: '\u0163',
    tcy: '\u0442',
    tdot: '\u20DB',
    telrec: '\u2315',
    there4: '\u2234',
    therefore: '\u2234',
    thetasym: '\u03D1',
    thickapprox: '\u2248',
    thicksim: '\u223C',
    thinsp: '\u2009',
    thkap: '\u2248',
    thksim: '\u223C',
    thorn: '\u00FE',
    timesb: '\u22A0',
    timesbar: '\u2A31',
    timesd: '\u2A30',
    tint: '\u222D',
    toea: '\u2928',
    top: '\u22A4',
    topbot: '\u2336',
    topcir: '\u2AF1',
    topfork: '\u2ADA',
    tosa: '\u2929',
    tprime: '\u2034',
    trade: '\u2122',
    triangledown: '\u25BF',
    triangleleft: '\u25C3',
    trianglelefteq: '\u22B4',
    triangleright: '\u25B9',
    trianglerighteq: '\u22B5',
    tridot: '\u25EC',
    trie: '\u225C',
    triminus: '\u2A3A',
    triplus: '\u2A39',
    trisb: '\u29CD',
    tritime: '\u2A3B',
    trpezium: '\u23E2',
    tscy: '\u0446',
    tshcy: '\u045B',
    tstrok: '\u0167',
    twixt: '\u226C',
    twoheadleftarrow: '\u219E',
    twoheadrightarrow: '\u21A0'
}, 't');
