"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normal = void 0;
var FontData_js_1 = require("../../FontData.js");
var normal_js_1 = require("../../../common/fonts/tex/normal.js");
exports.normal = FontData_js_1.AddPaths(normal_js_1.normal, {
    0x20: '',
    0x21: '78 661Q78 682 96 699T138 716T180 700T199 661Q199 654 179 432T158 206Q156 198 139 198Q121 198 119 206Q118 209 98 431T78 661ZM79 61Q79 89 97 105T141 121Q164 119 181 104T198 61Q198 31 181 16T139 1Q114 1 97 16T79 61',
    0x22: '34 634Q34 659 50 676T93 694Q121 694 144 668T168 579Q168 525 146 476T101 403T73 379Q69 379 60 388T50 401Q50 404 62 417T88 448T116 500T131 572Q131 584 130 584T125 581T112 576T94 573Q69 573 52 590T34 634ZM238 634Q238 659 254 676T297 694Q325 694 348 668T372 579Q372 525 350 476T305 403T277 379Q273 379 264 388T254 401Q254 404 266 417T292 448T320 500T335 572Q335 584 334 584T329 581T316 576T298 573Q273 573 256 590T238 634',
    0x23: '56 347Q56 360 70 367H313L355 524Q394 676 401 686Q406 694 416 694Q434 694 436 676Q436 672 396 522Q355 374 355 369L354 367H543L585 524Q626 679 630 685Q636 694 646 694Q653 694 659 689T665 678Q665 668 626 522Q585 374 585 369L584 367H762Q777 359 777 347Q777 334 767 331T722 327H667H572L552 251L531 174Q531 173 647 173H720Q756 173 766 170T777 153T762 133H519L477 -24Q436 -179 432 -185Q426 -194 416 -194Q409 -194 403 -189T397 -177Q397 -167 436 -21Q477 125 477 131L478 133H289L247 -24Q206 -179 202 -185Q196 -194 186 -194Q179 -194 173 -189T167 -177Q167 -167 206 -21Q247 125 247 131L248 133H70Q56 140 56 153Q56 168 72 173H260L280 249L301 326Q301 327 186 327H72Q56 332 56 347ZM531 326Q531 327 437 327H342L322 251L301 174Q301 173 395 173H490L510 249L531 326',
    0x24: '162 187Q162 164 146 149T109 133H103V130Q108 115 115 105Q122 92 131 82T150 64T170 52T190 44T206 40T220 37L227 36V313Q190 320 162 335Q116 358 86 404T55 508Q55 567 85 614T165 685Q186 696 225 704H227V750H273V704L286 703Q369 690 413 631Q441 588 444 531Q444 514 443 509Q439 490 425 479T391 468Q368 468 353 483T337 522Q337 546 353 560T390 575L394 576V578Q386 599 372 614T342 637T314 649T288 656L273 658V408L288 405Q329 394 355 376Q396 348 420 300T444 199Q444 130 408 76T313 1Q286 -9 276 -9H273V-56H227V-10H221Q202 -6 193 -4T155 11T108 41T74 94T55 176V182Q55 227 95 238Q103 240 108 240Q129 240 145 226T162 187ZM225 657Q219 657 204 651T169 632T135 594T121 538Q121 512 131 491T156 457T187 435T213 423T227 420V539Q227 657 225 657ZM378 169Q378 230 339 265T274 301Q273 301 273 169V37Q324 50 351 87T378 169',
    0x25: '465 605Q428 605 394 614T340 632T319 641Q332 608 332 548Q332 458 293 403T202 347Q145 347 101 402T56 548Q56 637 101 693T202 750Q241 750 272 719Q359 642 464 642Q580 642 650 732Q662 748 668 749Q670 750 673 750Q682 750 688 743T693 726Q178 -47 170 -52Q166 -56 160 -56Q147 -56 142 -45Q137 -36 142 -27Q143 -24 363 304Q469 462 525 546T581 630Q528 605 465 605ZM207 385Q235 385 263 427T292 548Q292 617 267 664T200 712Q193 712 186 709T167 698T147 668T134 615Q132 595 132 548V527Q132 436 165 403Q183 385 203 385H207ZM500 146Q500 234 544 290T647 347Q699 347 737 292T776 146T737 0T646 -56Q590 -56 545 0T500 146ZM651 -18Q679 -18 707 24T736 146Q736 215 711 262T644 309Q637 309 630 306T611 295T591 265T578 212Q577 200 577 146V124Q577 -18 647 -18H651',
    0x26: '156 540Q156 620 201 668T302 716Q354 716 377 671T401 578Q401 505 287 386L274 373Q309 285 416 148L429 132L437 142Q474 191 543 309L562 341V349Q562 368 541 376T498 385H493V431H502L626 428Q709 428 721 431H727V385H712Q688 384 669 379T639 369T618 354T603 337T591 316T578 295Q537 223 506 176T464 117T454 104Q454 102 471 85T497 62Q543 24 585 24Q618 24 648 48T682 113V121H722V112Q721 94 714 75T692 32T646 -7T574 -22Q491 -19 414 42L402 51L391 42Q312 -22 224 -22Q144 -22 93 25T42 135Q42 153 46 169T55 197T74 225T96 249T125 278T156 308L195 347L190 360Q185 372 182 382T174 411T165 448T159 491T156 540ZM361 576Q361 613 348 646T305 679Q272 679 252 649T232 572Q232 497 255 426L259 411L267 420Q361 519 361 576ZM140 164Q140 103 167 64T240 24Q271 24 304 36T356 61T374 77Q295 156 235 262L220 292L210 310L193 293Q177 277 169 268T151 229T140 164',
    0x27: '78 634Q78 659 95 676T138 694Q166 694 189 668T212 579Q212 525 190 476T146 403T118 379Q114 379 105 388T95 401Q95 404 107 417T133 448T161 500T176 572Q176 584 175 584T170 581T157 576T139 573Q114 573 96 590T78 634',
    0x28: '94 250Q94 319 104 381T127 488T164 576T202 643T244 695T277 729T302 750H315H319Q333 750 333 741Q333 738 316 720T275 667T226 581T184 443T167 250T184 58T225 -81T274 -167T316 -220T333 -241Q333 -250 318 -250H315H302L274 -226Q180 -141 137 -14T94 250',
    0x29: '60 749L64 750Q69 750 74 750H86L114 726Q208 641 251 514T294 250Q294 182 284 119T261 12T224 -76T186 -143T145 -194T113 -227T90 -246Q87 -249 86 -250H74Q66 -250 63 -250T58 -247T55 -238Q56 -237 66 -225Q221 -64 221 250T66 725Q56 737 55 738Q55 746 60 749',
    0x2A: '215 721Q216 732 225 741T248 750Q263 750 273 742T284 721L270 571L327 613Q383 654 388 657T399 660Q412 660 423 650T435 624T424 600T376 575Q363 569 355 566L289 534L355 504L424 470Q435 462 435 447Q435 431 424 420T399 409Q393 409 388 412T327 456L270 498L277 423L284 348Q280 320 250 320T215 348L229 498L172 456Q116 415 111 412T100 409Q87 409 76 420T64 447Q64 461 75 470L144 504L210 534L144 566Q136 570 122 576Q83 593 74 600T64 624Q64 639 75 649T100 660Q106 660 111 657T172 613L229 571Q229 578 222 643T215 721',
    0x2B: '56 237T56 250T70 270H369V420L370 570Q380 583 389 583Q402 583 409 568V270H707Q722 262 722 250T707 230H409V-68Q401 -82 391 -82H389H387Q375 -82 369 -68V230H70Q56 237 56 250',
    0x2C: '78 35T78 60T94 103T137 121Q165 121 187 96T210 8Q210 -27 201 -60T180 -117T154 -158T130 -185T117 -194Q113 -194 104 -185T95 -172Q95 -168 106 -156T131 -126T157 -76T173 -3V9L172 8Q170 7 167 6T161 3T152 1T140 0Q113 0 96 17',
    0x2D: '11 179V252H277V179H11',
    0x2E: '78 60Q78 84 95 102T138 120Q162 120 180 104T199 61Q199 36 182 18T139 0T96 17T78 60',
    0x2F: '423 750Q432 750 438 744T444 730Q444 725 271 248T92 -240Q85 -250 75 -250Q68 -250 62 -245T56 -231Q56 -221 230 257T407 740Q411 750 423 750',
    0x30: '96 585Q152 666 249 666Q297 666 345 640T423 548Q460 465 460 320Q460 165 417 83Q397 41 362 16T301 -15T250 -22Q224 -22 198 -16T137 16T82 83Q39 165 39 320Q39 494 96 585ZM321 597Q291 629 250 629Q208 629 178 597Q153 571 145 525T137 333Q137 175 145 125T181 46Q209 16 250 16Q290 16 318 46Q347 76 354 130T362 333Q362 478 354 524T321 597',
    0x31: '213 578L200 573Q186 568 160 563T102 556H83V602H102Q149 604 189 617T245 641T273 663Q275 666 285 666Q294 666 302 660V361L303 61Q310 54 315 52T339 48T401 46H427V0H416Q395 3 257 3Q121 3 100 0H88V46H114Q136 46 152 46T177 47T193 50T201 52T207 57T213 61V578',
    0x32: '109 429Q82 429 66 447T50 491Q50 562 103 614T235 666Q326 666 387 610T449 465Q449 422 429 383T381 315T301 241Q265 210 201 149L142 93L218 92Q375 92 385 97Q392 99 409 186V189H449V186Q448 183 436 95T421 3V0H50V19V31Q50 38 56 46T86 81Q115 113 136 137Q145 147 170 174T204 211T233 244T261 278T284 308T305 340T320 369T333 401T340 431T343 464Q343 527 309 573T212 619Q179 619 154 602T119 569T109 550Q109 549 114 549Q132 549 151 535T170 489Q170 464 154 447T109 429',
    0x33: '127 463Q100 463 85 480T69 524Q69 579 117 622T233 665Q268 665 277 664Q351 652 390 611T430 522Q430 470 396 421T302 350L299 348Q299 347 308 345T337 336T375 315Q457 262 457 175Q457 96 395 37T238 -22Q158 -22 100 21T42 130Q42 158 60 175T105 193Q133 193 151 175T169 130Q169 119 166 110T159 94T148 82T136 74T126 70T118 67L114 66Q165 21 238 21Q293 21 321 74Q338 107 338 175V195Q338 290 274 322Q259 328 213 329L171 330L168 332Q166 335 166 348Q166 366 174 366Q202 366 232 371Q266 376 294 413T322 525V533Q322 590 287 612Q265 626 240 626Q208 626 181 615T143 592T132 580H135Q138 579 143 578T153 573T165 566T175 555T183 540T186 520Q186 498 172 481T127 463',
    0x34: '462 0Q444 3 333 3Q217 3 199 0H190V46H221Q241 46 248 46T265 48T279 53T286 61Q287 63 287 115V165H28V211L179 442Q332 674 334 675Q336 677 355 677H373L379 671V211H471V165H379V114Q379 73 379 66T385 54Q393 47 442 46H471V0H462ZM293 211V545L74 212L183 211H293',
    0x35: '164 157Q164 133 148 117T109 101H102Q148 22 224 22Q294 22 326 82Q345 115 345 210Q345 313 318 349Q292 382 260 382H254Q176 382 136 314Q132 307 129 306T114 304Q97 304 95 310Q93 314 93 485V614Q93 664 98 664Q100 666 102 666Q103 666 123 658T178 642T253 634Q324 634 389 662Q397 666 402 666Q410 666 410 648V635Q328 538 205 538Q174 538 149 544L139 546V374Q158 388 169 396T205 412T256 420Q337 420 393 355T449 201Q449 109 385 44T229 -22Q148 -22 99 32T50 154Q50 178 61 192T84 210T107 214Q132 214 148 197T164 157',
    0x36: '42 313Q42 476 123 571T303 666Q372 666 402 630T432 550Q432 525 418 510T379 495Q356 495 341 509T326 548Q326 592 373 601Q351 623 311 626Q240 626 194 566Q147 500 147 364L148 360Q153 366 156 373Q197 433 263 433H267Q313 433 348 414Q372 400 396 374T435 317Q456 268 456 210V192Q456 169 451 149Q440 90 387 34T253 -22Q225 -22 199 -14T143 16T92 75T56 172T42 313ZM257 397Q227 397 205 380T171 335T154 278T148 216Q148 133 160 97T198 39Q222 21 251 21Q302 21 329 59Q342 77 347 104T352 209Q352 289 347 316T329 361Q302 397 257 397',
    0x37: '55 458Q56 460 72 567L88 674Q88 676 108 676H128V672Q128 662 143 655T195 646T364 644H485V605L417 512Q408 500 387 472T360 435T339 403T319 367T305 330T292 284T284 230T278 162T275 80Q275 66 275 52T274 28V19Q270 2 255 -10T221 -22Q210 -22 200 -19T179 0T168 40Q168 198 265 368Q285 400 349 489L395 552H302Q128 552 119 546Q113 543 108 522T98 479L95 458V455H55V458',
    0x38: '70 417T70 494T124 618T248 666Q319 666 374 624T429 515Q429 485 418 459T392 417T361 389T335 371T324 363L338 354Q352 344 366 334T382 323Q457 264 457 174Q457 95 399 37T249 -22Q159 -22 101 29T43 155Q43 263 172 335L154 348Q133 361 127 368Q70 417 70 494ZM286 386L292 390Q298 394 301 396T311 403T323 413T334 425T345 438T355 454T364 471T369 491T371 513Q371 556 342 586T275 624Q268 625 242 625Q201 625 165 599T128 534Q128 511 141 492T167 463T217 431Q224 426 228 424L286 386ZM250 21Q308 21 350 55T392 137Q392 154 387 169T375 194T353 216T330 234T301 253T274 270Q260 279 244 289T218 306L210 311Q204 311 181 294T133 239T107 157Q107 98 150 60T250 21',
    0x39: '352 287Q304 211 232 211Q154 211 104 270T44 396Q42 412 42 436V444Q42 537 111 606Q171 666 243 666Q245 666 249 666T257 665H261Q273 665 286 663T323 651T370 619T413 560Q456 472 456 334Q456 194 396 97Q361 41 312 10T208 -22Q147 -22 108 7T68 93T121 149Q143 149 158 135T173 96Q173 78 164 65T148 49T135 44L131 43Q131 41 138 37T164 27T206 22H212Q272 22 313 86Q352 142 352 280V287ZM244 248Q292 248 321 297T351 430Q351 508 343 542Q341 552 337 562T323 588T293 615T246 625Q208 625 181 598Q160 576 154 546T147 441Q147 358 152 329T172 282Q197 248 244 248',
    0x3A: '78 370Q78 394 95 412T138 430Q162 430 180 414T199 371Q199 346 182 328T139 310T96 327T78 370ZM78 60Q78 84 95 102T138 120Q162 120 180 104T199 61Q199 36 182 18T139 0T96 17T78 60',
    0x3B: '78 370Q78 394 95 412T138 430Q162 430 180 414T199 371Q199 346 182 328T139 310T96 327T78 370ZM78 60Q78 85 94 103T137 121Q202 121 202 8Q202 -44 183 -94T144 -169T118 -194Q115 -194 106 -186T95 -174Q94 -171 107 -155T137 -107T160 -38Q161 -32 162 -22T165 -4T165 4Q165 5 161 4T142 0Q110 0 94 18T78 60',
    0x3C: '694 -11T694 -19T688 -33T678 -40Q671 -40 524 29T234 166L90 235Q83 240 83 250Q83 261 91 266Q664 540 678 540Q681 540 687 534T694 519T687 505Q686 504 417 376L151 250L417 124Q686 -4 687 -5Q694 -11 694 -19',
    0x3D: '56 347Q56 360 70 367H707Q722 359 722 347Q722 336 708 328L390 327H72Q56 332 56 347ZM56 153Q56 168 72 173H708Q722 163 722 153Q722 140 707 133H70Q56 140 56 153',
    0x3E: '84 520Q84 528 88 533T96 539L99 540Q106 540 253 471T544 334L687 265Q694 260 694 250T687 235Q685 233 395 96L107 -40H101Q83 -38 83 -20Q83 -19 83 -17Q82 -10 98 -1Q117 9 248 71Q326 108 378 132L626 250L378 368Q90 504 86 509Q84 513 84 520',
    0x3F: '226 668Q190 668 162 656T124 632L114 621Q116 621 119 620T130 616T145 607T157 591T162 567Q162 544 147 529T109 514T71 528T55 566Q55 625 100 661T199 704Q201 704 210 704T224 705H228Q281 705 320 692T378 656T407 612T416 567Q416 503 361 462Q267 395 247 303Q242 279 242 241V224Q242 205 239 202T222 198T205 201T202 218V249Q204 320 220 371T255 445T292 491T315 537Q317 546 317 574V587Q317 604 315 615T304 640T277 661T226 668ZM162 61Q162 89 180 105T224 121Q247 119 264 104T281 61Q281 31 264 16T222 1Q197 1 180 16T162 61',
    0x40: '56 347Q56 429 86 498T164 612T270 680T386 705Q522 705 622 603T722 349Q722 126 608 126Q541 126 513 176Q512 177 512 179T510 182L509 183Q508 183 503 177T487 163T464 146T429 132T385 126Q311 126 251 186T190 347Q190 448 251 508T385 568Q426 568 460 548T509 511T531 479H555Q580 479 582 478Q586 477 587 468Q588 454 588 338V260Q588 200 593 182T619 163Q641 163 655 178T674 223T680 273T682 325V330Q682 426 647 500Q611 569 544 618T388 668Q271 668 184 577T96 347Q96 216 180 121T396 26Q421 26 446 28T493 34T535 43T573 52T605 63T629 72T647 80T657 84H716Q722 78 722 74Q722 65 675 45T547 7T392 -11Q255 -11 156 90T56 347ZM274 347Q274 266 308 214T390 162Q420 162 449 182T498 235L504 245V449L498 459Q453 532 387 532Q347 532 311 483T274 347',
    0x41: '255 0Q240 3 140 3Q48 3 39 0H32V46H47Q119 49 139 88Q140 91 192 245T295 553T348 708Q351 716 366 716H376Q396 715 400 709Q402 707 508 390L617 67Q624 54 636 51T687 46H717V0H708Q699 3 581 3Q458 3 437 0H427V46H440Q510 46 510 64Q510 66 486 138L462 209H229L209 150Q189 91 189 85Q189 72 209 59T259 46H264V0H255ZM447 255L345 557L244 256Q244 255 345 255H447',
    0x42: '131 622Q124 629 120 631T104 634T61 637H28V683H229H267H346Q423 683 459 678T531 651Q574 627 599 590T624 512Q624 461 583 419T476 360L466 357Q539 348 595 302T651 187Q651 119 600 67T469 3Q456 1 242 0H28V46H61Q103 47 112 49T131 61V622ZM511 513Q511 560 485 594T416 636Q415 636 403 636T371 636T333 637Q266 637 251 636T232 628Q229 624 229 499V374H312L396 375L406 377Q410 378 417 380T442 393T474 417T499 456T511 513ZM537 188Q537 239 509 282T430 336L329 337H229V200V116Q229 57 234 52Q240 47 334 47H383Q425 47 443 53Q486 67 511 104T537 188',
    0x43: '56 342Q56 428 89 500T174 615T283 681T391 705Q394 705 400 705T408 704Q499 704 569 636L582 624L612 663Q639 700 643 704Q644 704 647 704T653 705H657Q660 705 666 699V419L660 413H626Q620 419 619 430Q610 512 571 572T476 651Q457 658 426 658Q322 658 252 588Q173 509 173 342Q173 221 211 151Q232 111 263 84T328 45T384 29T428 24Q517 24 571 93T626 244Q626 251 632 257H660L666 251V236Q661 133 590 56T403 -21Q262 -21 159 83T56 342',
    0x44: '130 622Q123 629 119 631T103 634T60 637H27V683H228Q399 682 419 682T461 676Q504 667 546 641T626 573T685 470T708 336Q708 210 634 116T442 3Q429 1 228 0H27V46H60Q102 47 111 49T130 61V622ZM593 338Q593 439 571 501T493 602Q439 637 355 637H322H294Q238 637 234 628Q231 624 231 344Q231 62 232 59Q233 49 248 48T339 46H350Q456 46 515 95Q561 133 577 191T593 338',
    0x45: '128 619Q121 626 117 628T101 631T58 634H25V680H597V676Q599 670 611 560T625 444V440H585V444Q584 447 582 465Q578 500 570 526T553 571T528 601T498 619T457 629T411 633T353 634Q266 634 251 633T233 622Q233 622 233 621Q232 619 232 497V376H286Q359 378 377 385Q413 401 416 469Q416 471 416 473V493H456V213H416V233Q415 268 408 288T383 317T349 328T297 330Q290 330 286 330H232V196V114Q232 57 237 52Q243 47 289 47H340H391Q428 47 452 50T505 62T552 92T584 146Q594 172 599 200T607 247T612 270V273H652V270Q651 267 632 137T610 3V0H25V46H58Q100 47 109 49T128 61V619',
    0x46: '128 619Q121 626 117 628T101 631T58 634H25V680H582V676Q584 670 596 560T610 444V440H570V444Q563 493 561 501Q555 538 543 563T516 601T477 622T431 631T374 633H334H286Q252 633 244 631T233 621Q232 619 232 490V363H284Q287 363 303 363T327 364T349 367T372 373T389 385Q407 403 410 459V480H450V200H410V221Q407 276 389 296Q381 303 371 307T348 313T327 316T303 317T284 317H232V189L233 61Q240 54 245 52T270 48T333 46H360V0H348Q324 3 182 3Q51 3 36 0H25V46H58Q100 47 109 49T128 61V619',
    0x47: '56 342Q56 428 89 500T174 615T283 681T391 705Q394 705 400 705T408 704Q499 704 569 636L582 624L612 663Q639 700 643 704Q644 704 647 704T653 705H657Q660 705 666 699V419L660 413H626Q620 419 619 430Q610 512 571 572T476 651Q457 658 426 658Q401 658 376 654T316 633T254 592T205 519T177 411Q173 369 173 335Q173 259 192 201T238 111T302 58T370 31T431 24Q478 24 513 45T559 100Q562 110 562 160V212Q561 213 557 216T551 220T542 223T526 225T502 226T463 227H437V273H449L609 270Q715 270 727 273H735V227H721Q674 227 668 215Q666 211 666 108V6Q660 0 657 0Q653 0 639 10Q617 25 600 42L587 54Q571 27 524 3T406 -22Q317 -22 238 22T108 151T56 342',
    0x48: '128 622Q121 629 117 631T101 634T58 637H25V683H36Q57 680 180 680Q315 680 324 683H335V637H302Q262 636 251 634T233 622L232 500V378H517V622Q510 629 506 631T490 634T447 637H414V683H425Q446 680 569 680Q704 680 713 683H724V637H691Q651 636 640 634T622 622V61Q628 51 639 49T691 46H724V0H713Q692 3 569 3Q434 3 425 0H414V46H447Q489 47 498 49T517 61V332H232V197L233 61Q239 51 250 49T302 46H335V0H324Q303 3 180 3Q45 3 36 0H25V46H58Q100 47 109 49T128 61V622',
    0x49: '328 0Q307 3 180 3T32 0H21V46H43Q92 46 106 49T126 60Q128 63 128 342Q128 620 126 623Q122 628 118 630T96 635T43 637H21V683H32Q53 680 180 680T328 683H339V637H317Q268 637 254 634T234 623Q232 620 232 342Q232 63 234 60Q238 55 242 53T264 48T317 46H339V0H328',
    0x4A: '89 177Q115 177 133 160T152 112Q152 88 137 72T102 52Q99 51 101 49Q106 43 129 29Q159 15 190 15Q232 15 256 48T286 126Q286 127 286 142T286 183T286 238T287 306T287 378Q287 403 287 429T287 479T287 524T286 563T286 593T286 614V621Q281 630 263 633T182 637H154V683H166Q187 680 332 680Q439 680 457 683H465V637H449Q422 637 401 634Q393 631 389 623Q388 621 388 376T387 123Q377 61 322 20T194 -22Q188 -22 177 -21T160 -20Q96 -9 61 29T25 110Q25 144 44 160T89 177',
    0x4B: '128 622Q121 629 117 631T101 634T58 637H25V683H36Q57 680 180 680Q315 680 324 683H335V637H313Q235 637 233 620Q232 618 232 462L233 307L379 449Q425 494 479 546Q518 584 524 591T531 607V608Q531 630 503 636Q501 636 498 636T493 637H489V683H499Q517 680 630 680Q704 680 716 683H722V637H708Q633 633 589 597Q584 592 495 506T406 419T515 254T631 80Q644 60 662 54T715 46H736V0H728Q719 3 615 3Q493 3 472 0H461V46H469Q515 46 515 72Q515 78 512 84L336 351Q332 348 278 296L232 251V156Q232 62 235 58Q243 47 302 46H335V0H324Q303 3 180 3Q45 3 36 0H25V46H58Q100 47 109 49T128 61V622',
    0x4C: '128 622Q121 629 117 631T101 634T58 637H25V683H36Q48 680 182 680Q324 680 348 683H360V637H333Q273 637 258 635T233 622L232 342V129Q232 57 237 52Q243 47 313 47Q384 47 410 53Q470 70 498 110T536 221Q536 226 537 238T540 261T542 272T562 273H582V268Q580 265 568 137T554 5V0H25V46H58Q100 47 109 49T128 61V622',
    0x4D: '132 622Q125 629 121 631T105 634T62 637H29V683H135Q221 683 232 682T249 675Q250 674 354 398L458 124L562 398Q666 674 668 675Q671 681 683 682T781 683H887V637H854Q814 636 803 634T785 622V61Q791 51 802 49T854 46H887V0H876Q855 3 736 3Q605 3 596 0H585V46H618Q660 47 669 49T688 61V347Q688 424 688 461T688 546T688 613L687 632Q454 14 450 7Q446 1 430 1T410 7Q409 9 292 316L176 624V606Q175 588 175 543T175 463T175 356L176 86Q187 50 261 46H278V0H269Q254 3 154 3Q52 3 37 0H29V46H46Q78 48 98 56T122 69T132 86V622',
    0x4E: '42 46Q74 48 94 56T118 69T128 86V634H124Q114 637 52 637H25V683H232L235 680Q237 679 322 554T493 303L578 178V598Q572 608 568 613T544 627T492 637H475V683H483Q498 680 600 680Q706 680 715 683H724V637H707Q634 633 622 598L621 302V6L614 0H600Q585 0 582 3T481 150T282 443T171 605V345L172 86Q183 50 257 46H274V0H265Q250 3 150 3Q48 3 33 0H25V46H42',
    0x4F: '56 340Q56 423 86 494T164 610T270 680T388 705Q521 705 621 601T722 341Q722 260 693 191T617 75T510 4T388 -22T267 3T160 74T85 189T56 340ZM467 647Q426 665 388 665Q360 665 331 654T269 620T213 549T179 439Q174 411 174 354Q174 144 277 61Q327 20 385 20H389H391Q474 20 537 99Q603 188 603 354Q603 411 598 439Q577 592 467 647',
    0x50: '130 622Q123 629 119 631T103 634T60 637H27V683H214Q237 683 276 683T331 684Q419 684 471 671T567 616Q624 563 624 489Q624 421 573 372T451 307Q429 302 328 301H234V181Q234 62 237 58Q245 47 304 46H337V0H326Q305 3 182 3Q47 3 38 0H27V46H60Q102 47 111 49T130 61V622ZM507 488Q507 514 506 528T500 564T483 597T450 620T397 635Q385 637 307 637H286Q237 637 234 628Q231 624 231 483V342H302H339Q390 342 423 349T481 382Q507 411 507 488',
    0x51: '56 341Q56 499 157 602T388 705Q521 705 621 601T722 341Q722 275 703 218T660 127T603 63T555 25T525 9Q524 8 524 8H523Q524 5 526 -1T537 -21T555 -47T581 -67T615 -76Q653 -76 678 -56T706 -3Q707 10 716 10Q721 10 728 5L727 -13Q727 -88 697 -140T606 -193Q563 -193 538 -166T498 -83Q483 -23 483 -8L471 -11Q459 -14 435 -18T388 -22Q254 -22 155 81T56 341ZM607 339Q607 429 586 496T531 598T461 649T390 665T318 649T248 598T192 496T170 339Q170 143 277 57Q301 39 305 39L304 42Q304 44 304 46Q301 53 301 68Q301 101 325 128T391 155Q454 155 495 70L501 58Q549 91 578 164Q607 234 607 339ZM385 18Q404 18 425 23T459 33T472 40Q471 47 468 57T449 88T412 115Q398 117 386 117Q367 117 353 102T338 67Q338 48 351 33T385 18',
    0x52: '130 622Q123 629 119 631T103 634T60 637H27V683H202H236H300Q376 683 417 677T500 648Q595 600 609 517Q610 512 610 501Q610 468 594 439T556 392T511 361T472 343L456 338Q459 335 467 332Q497 316 516 298T545 254T559 211T568 155T578 94Q588 46 602 31T640 16H645Q660 16 674 32T692 87Q692 98 696 101T712 105T728 103T732 90Q732 59 716 27T672 -16Q656 -22 630 -22Q481 -16 458 90Q456 101 456 163T449 246Q430 304 373 320L363 322L297 323H231V192L232 61Q238 51 249 49T301 46H334V0H323Q302 3 181 3Q59 3 38 0H27V46H60Q102 47 111 49T130 61V622ZM491 499V509Q491 527 490 539T481 570T462 601T424 623T362 636Q360 636 340 636T304 637H283Q238 637 234 628Q231 624 231 492V360H289Q390 360 434 378T489 456Q491 467 491 499',
    0x53: '55 507Q55 590 112 647T243 704H257Q342 704 405 641L426 672Q431 679 436 687T446 700L449 704Q450 704 453 704T459 705H463Q466 705 472 699V462L466 456H448Q437 456 435 459T430 479Q413 605 329 646Q292 662 254 662Q201 662 168 626T135 542Q135 508 152 480T200 435Q210 431 286 412T370 389Q427 367 463 314T500 191Q500 110 448 45T301 -21Q245 -21 201 -4T140 27L122 41Q118 36 107 21T87 -7T78 -21Q76 -22 68 -22H64Q61 -22 55 -16V101Q55 220 56 222Q58 227 76 227H89Q95 221 95 214Q95 182 105 151T139 90T205 42T305 24Q352 24 386 62T420 155Q420 198 398 233T340 281Q284 295 266 300Q261 301 239 306T206 314T174 325T141 343T112 367T85 402Q55 451 55 507',
    0x54: '36 443Q37 448 46 558T55 671V677H666V671Q667 666 676 556T685 443V437H645V443Q645 445 642 478T631 544T610 593Q593 614 555 625Q534 630 478 630H451H443Q417 630 414 618Q413 616 413 339V63Q420 53 439 50T528 46H558V0H545L361 3Q186 1 177 0H164V46H194Q264 46 283 49T309 63V339V550Q309 620 304 625T271 630H244H224Q154 630 119 601Q101 585 93 554T81 486T76 443V437H36V443',
    0x55: '128 622Q121 629 117 631T101 634T58 637H25V683H36Q57 680 180 680Q315 680 324 683H335V637H302Q262 636 251 634T233 622L232 418V291Q232 189 240 145T280 67Q325 24 389 24Q454 24 506 64T571 183Q575 206 575 410V598Q569 608 565 613T541 627T489 637H472V683H481Q496 680 598 680T715 683H724V637H707Q634 633 622 598L621 399Q620 194 617 180Q617 179 615 171Q595 83 531 31T389 -22Q304 -22 226 33T130 192Q129 201 128 412V622',
    0x56: '114 620Q113 621 110 624T107 627T103 630T98 632T91 634T80 635T67 636T48 637H19V683H28Q46 680 152 680Q273 680 294 683H305V637H284Q223 634 223 620Q223 618 313 372T404 126L490 358Q575 588 575 597Q575 616 554 626T508 637H503V683H512Q527 680 627 680Q718 680 724 683H730V637H723Q648 637 627 596Q627 595 515 291T401 -14Q396 -22 382 -22H374H367Q353 -22 348 -14Q346 -12 231 303Q114 617 114 620',
    0x57: '792 683Q810 680 914 680Q991 680 1003 683H1009V637H996Q931 633 915 598Q912 591 863 438T766 135T716 -17Q711 -22 694 -22Q676 -22 673 -15Q671 -13 593 231L514 477L435 234Q416 174 391 92T358 -6T341 -22H331Q314 -21 310 -15Q309 -14 208 302T104 622Q98 632 87 633Q73 637 35 637H18V683H27Q69 681 154 681Q164 681 181 681T216 681T249 682T276 683H287H298V637H285Q213 637 213 620Q213 616 289 381L364 144L427 339Q490 535 492 546Q487 560 482 578T475 602T468 618T461 628T449 633T433 636T408 637H380V683H388Q397 680 508 680Q629 680 650 683H660V637H647Q576 637 576 619L727 146Q869 580 869 600Q869 605 863 612T839 627T794 637H783V683H792',
    0x58: '270 0Q252 3 141 3Q46 3 31 0H23V46H40Q129 50 161 88Q165 94 244 216T324 339Q324 341 235 480T143 622Q133 631 119 634T57 637H37V683H46Q64 680 172 680Q297 680 318 683H329V637H324Q307 637 286 632T263 621Q263 618 322 525T384 431Q385 431 437 511T489 593Q490 595 490 599Q490 611 477 622T436 637H428V683H437Q455 680 566 680Q661 680 676 683H684V637H667Q585 634 551 599Q548 596 478 491Q412 388 412 387Q412 385 514 225T620 62Q628 53 642 50T695 46H726V0H717Q699 3 591 3Q466 3 445 0H434V46H440Q454 46 476 51T499 64Q499 67 463 124T390 238L353 295L350 292Q348 290 343 283T331 265T312 236T286 195Q219 88 218 84Q218 70 234 59T272 46H280V0H270',
    0x59: '518 0Q497 3 374 3Q253 3 232 0H221V46H254Q313 47 321 58Q324 62 324 167V273L221 446Q117 620 114 623Q106 631 91 634T31 637H11V683H20Q29 680 148 680Q273 680 294 683H305V637H287Q239 636 236 621Q236 619 321 475L407 332L483 460Q502 492 527 534Q563 594 563 604Q563 632 517 637H508V683H517H525Q533 683 545 683T571 682T600 681T626 681Q695 681 731 683H738V637H723Q640 633 613 588Q612 587 517 427L425 273V169V95Q425 66 428 59T444 49Q459 46 506 46H528V0H518',
    0x5A: '69 443Q69 452 74 554T80 683H549Q555 677 555 664Q555 649 554 648Q552 645 366 348T179 50T192 49T263 49H275H302Q333 49 353 50T401 59T447 78T482 115T507 173Q513 200 520 273V282H560V274Q560 272 552 143T543 8V0H302L61 1L58 3Q55 8 55 21V35Q59 43 153 193T340 489T432 637H343Q259 637 214 625T141 573Q109 523 109 445Q109 443 89 443H69',
    0x5B: '118 -250V750H255V710H158V-210H255V-250H118',
    0x5C: '56 731Q56 740 62 745T75 750Q85 750 92 740Q96 733 270 255T444 -231Q444 -239 438 -244T424 -250Q414 -250 407 -240Q404 -236 230 242T56 731',
    0x5D: '22 710V750H159V-250H22V-210H119V710H22',
    0x5E: '112 560L249 694L257 686Q387 562 387 560L361 531Q359 532 303 581L250 627L195 580Q182 569 169 557T148 538L140 532Q138 530 125 546L112 560',
    0x5F: '0 -62V-25H499V-62H0',
    0x60: '106 655Q106 671 119 685T150 699Q166 699 177 688Q190 671 222 629T275 561T295 533T282 519L267 505L196 563Q119 626 113 634Q106 643 106 655',
    0x61: '137 305T115 305T78 320T63 359Q63 394 97 421T218 448Q291 448 336 416T396 340Q401 326 401 309T402 194V124Q402 76 407 58T428 40Q443 40 448 56T453 109V145H493V106Q492 66 490 59Q481 29 455 12T400 -6T353 12T329 54V58L327 55Q325 52 322 49T314 40T302 29T287 17T269 6T247 -2T221 -8T190 -11Q130 -11 82 20T34 107Q34 128 41 147T68 188T116 225T194 253T304 268H318V290Q318 324 312 340Q290 411 215 411Q197 411 181 410T156 406T148 403Q170 388 170 359Q170 334 154 320ZM126 106Q126 75 150 51T209 26Q247 26 276 49T315 109Q317 116 318 175Q318 233 317 233Q309 233 296 232T251 223T193 203T147 166T126 106',
    0x62: '307 -11Q234 -11 168 55L158 37Q156 34 153 28T147 17T143 10L138 1L118 0H98V298Q98 599 97 603Q94 622 83 628T38 637H20V660Q20 683 22 683L32 684Q42 685 61 686T98 688Q115 689 135 690T165 693T176 694H179V543Q179 391 180 391L183 394Q186 397 192 401T207 411T228 421T254 431T286 439T323 442Q401 442 461 379T522 216Q522 115 458 52T307 -11ZM182 98Q182 97 187 90T196 79T206 67T218 55T233 44T250 35T271 29T295 26Q330 26 363 46T412 113Q424 148 424 212Q424 287 412 323Q385 405 300 405Q270 405 239 390T188 347L182 339V98',
    0x63: '370 305T349 305T313 320T297 358Q297 381 312 396Q317 401 317 402T307 404Q281 408 258 408Q209 408 178 376Q131 329 131 219Q131 137 162 90Q203 29 272 29Q313 29 338 55T374 117Q376 125 379 127T395 129H409Q415 123 415 120Q415 116 411 104T395 71T366 33T318 2T249 -11Q163 -11 99 53T34 214Q34 318 99 383T250 448T370 421T404 357Q404 334 387 320',
    0x64: '376 495Q376 511 376 535T377 568Q377 613 367 624T316 637H298V660Q298 683 300 683L310 684Q320 685 339 686T376 688Q393 689 413 690T443 693T454 694H457V390Q457 84 458 81Q461 61 472 55T517 46H535V0Q533 0 459 -5T380 -11H373V44L365 37Q307 -11 235 -11Q158 -11 96 50T34 215Q34 315 97 378T244 442Q319 442 376 393V495ZM373 342Q328 405 260 405Q211 405 173 369Q146 341 139 305T131 211Q131 155 138 120T173 59Q203 26 251 26Q322 26 373 103V342',
    0x65: '28 218Q28 273 48 318T98 391T163 433T229 448Q282 448 320 430T378 380T406 316T415 245Q415 238 408 231H126V216Q126 68 226 36Q246 30 270 30Q312 30 342 62Q359 79 369 104L379 128Q382 131 395 131H398Q415 131 415 121Q415 117 412 108Q393 53 349 21T250 -11Q155 -11 92 58T28 218ZM333 275Q322 403 238 411H236Q228 411 220 410T195 402T166 381T143 340T127 274V267H333V275',
    0x66: '273 0Q255 3 146 3Q43 3 34 0H26V46H42Q70 46 91 49Q99 52 103 60Q104 62 104 224V385H33V431H104V497L105 564L107 574Q126 639 171 668T266 704Q267 704 275 704T289 705Q330 702 351 679T372 627Q372 604 358 590T321 576T284 590T270 627Q270 647 288 667H284Q280 668 273 668Q245 668 223 647T189 592Q183 572 182 497V431H293V385H185V225Q185 63 186 61T189 57T194 54T199 51T206 49T213 48T222 47T231 47T241 46T251 46H282V0H273',
    0x67: '329 409Q373 453 429 453Q459 453 472 434T485 396Q485 382 476 371T449 360Q416 360 412 390Q410 404 415 411Q415 412 416 414V415Q388 412 363 393Q355 388 355 386Q355 385 359 381T368 369T379 351T388 325T392 292Q392 230 343 187T222 143Q172 143 123 171Q112 153 112 133Q112 98 138 81Q147 75 155 75T227 73Q311 72 335 67Q396 58 431 26Q470 -13 470 -72Q470 -139 392 -175Q332 -206 250 -206Q167 -206 107 -175Q29 -140 29 -75Q29 -39 50 -15T92 18L103 24Q67 55 67 108Q67 155 96 193Q52 237 52 292Q52 355 102 398T223 442Q274 442 318 416L329 409ZM299 343Q294 371 273 387T221 404Q192 404 171 388T145 343Q142 326 142 292Q142 248 149 227T179 192Q196 182 222 182Q244 182 260 189T283 207T294 227T299 242Q302 258 302 292T299 343ZM403 -75Q403 -50 389 -34T348 -11T299 -2T245 0H218Q151 0 138 -6Q118 -15 107 -34T95 -74Q95 -84 101 -97T122 -127T170 -155T250 -167Q319 -167 361 -139T403 -75',
    0x68: '41 46H55Q94 46 102 60V68Q102 77 102 91T102 124T102 167T103 217T103 272T103 329Q103 366 103 407T103 482T102 542T102 586T102 603Q99 622 88 628T43 637H25V660Q25 683 27 683L37 684Q47 685 66 686T103 688Q120 689 140 690T170 693T181 694H184V367Q244 442 328 442Q451 442 463 329Q464 322 464 190V104Q464 66 466 59T477 49Q498 46 526 46H542V0H534L510 1Q487 2 460 2T422 3Q319 3 310 0H302V46H318Q379 46 379 62Q380 64 380 200Q379 335 378 343Q372 371 358 385T334 402T308 404Q263 404 229 370Q202 343 195 315T187 232V168V108Q187 78 188 68T191 55T200 49Q221 46 249 46H265V0H257L234 1Q210 2 183 2T145 3Q42 3 33 0H25V46H41',
    0x69: '69 609Q69 637 87 653T131 669Q154 667 171 652T188 609Q188 579 171 564T129 549Q104 549 87 564T69 609ZM247 0Q232 3 143 3Q132 3 106 3T56 1L34 0H26V46H42Q70 46 91 49Q100 53 102 60T104 102V205V293Q104 345 102 359T88 378Q74 385 41 385H30V408Q30 431 32 431L42 432Q52 433 70 434T106 436Q123 437 142 438T171 441T182 442H185V62Q190 52 197 50T232 46H255V0H247',
    0x6A: '98 609Q98 637 116 653T160 669Q183 667 200 652T217 609Q217 579 200 564T158 549Q133 549 116 564T98 609ZM28 -163Q58 -168 64 -168Q124 -168 135 -77Q137 -65 137 141T136 353Q132 371 120 377T72 385H52V408Q52 431 54 431L58 432Q62 432 70 432T87 433T108 434T133 436Q151 437 171 438T202 441T214 442H218V184Q217 -36 217 -59T211 -98Q195 -145 153 -175T58 -205Q9 -205 -23 -179T-55 -117Q-55 -94 -40 -79T-2 -64T36 -79T52 -118Q52 -143 28 -163',
    0x6B: '36 46H50Q89 46 97 60V68Q97 77 97 91T97 124T98 167T98 217T98 272T98 329Q98 366 98 407T98 482T98 542T97 586T97 603Q94 622 83 628T38 637H20V660Q20 683 22 683L32 684Q42 685 61 686T98 688Q115 689 135 690T165 693T176 694H179V463L180 233L240 287Q300 341 304 347Q310 356 310 364Q310 383 289 385H284V431H293Q308 428 412 428Q475 428 484 431H489V385H476Q407 380 360 341Q286 278 286 274Q286 273 349 181T420 79Q434 60 451 53T500 46H511V0H505Q496 3 418 3Q322 3 307 0H299V46H306Q330 48 330 65Q330 72 326 79Q323 84 276 153T228 222L176 176V120V84Q176 65 178 59T189 49Q210 46 238 46H254V0H246Q231 3 137 3T28 0H20V46H36',
    0x6C: '42 46H56Q95 46 103 60V68Q103 77 103 91T103 124T104 167T104 217T104 272T104 329Q104 366 104 407T104 482T104 542T103 586T103 603Q100 622 89 628T44 637H26V660Q26 683 28 683L38 684Q48 685 67 686T104 688Q121 689 141 690T171 693T182 694H185V379Q185 62 186 60Q190 52 198 49Q219 46 247 46H263V0H255L232 1Q209 2 183 2T145 3T107 3T57 1L34 0H26V46H42',
    0x6D: '41 46H55Q94 46 102 60V68Q102 77 102 91T102 122T103 161T103 203Q103 234 103 269T102 328V351Q99 370 88 376T43 385H25V408Q25 431 27 431L37 432Q47 433 65 434T102 436Q119 437 138 438T167 441T178 442H181V402Q181 364 182 364T187 369T199 384T218 402T247 421T285 437Q305 442 336 442Q351 442 364 440T387 434T406 426T421 417T432 406T441 395T448 384T452 374T455 366L457 361L460 365Q463 369 466 373T475 384T488 397T503 410T523 422T546 432T572 439T603 442Q729 442 740 329Q741 322 741 190V104Q741 66 743 59T754 49Q775 46 803 46H819V0H811L788 1Q764 2 737 2T699 3Q596 3 587 0H579V46H595Q656 46 656 62Q657 64 657 200Q656 335 655 343Q649 371 635 385T611 402T585 404Q540 404 506 370Q479 343 472 315T464 232V168V108Q464 78 465 68T468 55T477 49Q498 46 526 46H542V0H534L510 1Q487 2 460 2T422 3Q319 3 310 0H302V46H318Q379 46 379 62Q380 64 380 200Q379 335 378 343Q372 371 358 385T334 402T308 404Q263 404 229 370Q202 343 195 315T187 232V168V108Q187 78 188 68T191 55T200 49Q221 46 249 46H265V0H257L234 1Q210 2 183 2T145 3Q42 3 33 0H25V46H41',
    0x6E: '41 46H55Q94 46 102 60V68Q102 77 102 91T102 122T103 161T103 203Q103 234 103 269T102 328V351Q99 370 88 376T43 385H25V408Q25 431 27 431L37 432Q47 433 65 434T102 436Q119 437 138 438T167 441T178 442H181V402Q181 364 182 364T187 369T199 384T218 402T247 421T285 437Q305 442 336 442Q450 438 463 329Q464 322 464 190V104Q464 66 466 59T477 49Q498 46 526 46H542V0H534L510 1Q487 2 460 2T422 3Q319 3 310 0H302V46H318Q379 46 379 62Q380 64 380 200Q379 335 378 343Q372 371 358 385T334 402T308 404Q263 404 229 370Q202 343 195 315T187 232V168V108Q187 78 188 68T191 55T200 49Q221 46 249 46H265V0H257L234 1Q210 2 183 2T145 3Q42 3 33 0H25V46H41',
    0x6F: '28 214Q28 309 93 378T250 448Q340 448 405 380T471 215Q471 120 407 55T250 -10Q153 -10 91 57T28 214ZM250 30Q372 30 372 193V225V250Q372 272 371 288T364 326T348 362T317 390T268 410Q263 411 252 411Q222 411 195 399Q152 377 139 338T126 246V226Q126 130 145 91Q177 30 250 30',
    0x70: '36 -148H50Q89 -148 97 -134V-126Q97 -119 97 -107T97 -77T98 -38T98 6T98 55T98 106Q98 140 98 177T98 243T98 296T97 335T97 351Q94 370 83 376T38 385H20V408Q20 431 22 431L32 432Q42 433 61 434T98 436Q115 437 135 438T165 441T176 442H179V416L180 390L188 397Q247 441 326 441Q407 441 464 377T522 216Q522 115 457 52T310 -11Q242 -11 190 33L182 40V-45V-101Q182 -128 184 -134T195 -145Q216 -148 244 -148H260V-194H252L228 -193Q205 -192 178 -192T140 -191Q37 -191 28 -194H20V-148H36ZM424 218Q424 292 390 347T305 402Q234 402 182 337V98Q222 26 294 26Q345 26 384 80T424 218',
    0x71: '33 218Q33 308 95 374T236 441H246Q330 441 381 372L387 364Q388 364 404 403L420 442H457V156Q457 -132 458 -134Q462 -142 470 -145Q491 -148 519 -148H535V-194H527L504 -193Q480 -192 453 -192T415 -191Q312 -191 303 -194H295V-148H311Q339 -148 360 -145Q369 -141 371 -135T373 -106V-41V49Q313 -11 236 -11Q154 -11 94 53T33 218ZM376 300Q346 389 278 401Q275 401 269 401T261 402Q211 400 171 350T131 214Q131 137 165 82T253 27Q296 27 328 54T376 118V300',
    0x72: '36 46H50Q89 46 97 60V68Q97 77 97 91T98 122T98 161T98 203Q98 234 98 269T98 328L97 351Q94 370 83 376T38 385H20V408Q20 431 22 431L32 432Q42 433 60 434T96 436Q112 437 131 438T160 441T171 442H174V373Q213 441 271 441H277Q322 441 343 419T364 373Q364 352 351 337T313 322Q288 322 276 338T263 372Q263 381 265 388T270 400T273 405Q271 407 250 401Q234 393 226 386Q179 341 179 207V154Q179 141 179 127T179 101T180 81T180 66V61Q181 59 183 57T188 54T193 51T200 49T207 48T216 47T225 47T235 46T245 46H276V0H267Q249 3 140 3Q37 3 28 0H20V46H36',
    0x73: '295 316Q295 356 268 385T190 414Q154 414 128 401Q98 382 98 349Q97 344 98 336T114 312T157 287Q175 282 201 278T245 269T277 256Q294 248 310 236T342 195T359 133Q359 71 321 31T198 -10H190Q138 -10 94 26L86 19L77 10Q71 4 65 -1L54 -11H46H42Q39 -11 33 -5V74V132Q33 153 35 157T45 162H54Q66 162 70 158T75 146T82 119T101 77Q136 26 198 26Q295 26 295 104Q295 133 277 151Q257 175 194 187T111 210Q75 227 54 256T33 318Q33 357 50 384T93 424T143 442T187 447H198Q238 447 268 432L283 424L292 431Q302 440 314 448H322H326Q329 448 335 442V310L329 304H301Q295 310 295 316',
    0x74: '27 422Q80 426 109 478T141 600V615H181V431H316V385H181V241Q182 116 182 100T189 68Q203 29 238 29Q282 29 292 100Q293 108 293 146V181H333V146V134Q333 57 291 17Q264 -10 221 -10Q187 -10 162 2T124 33T105 68T98 100Q97 107 97 248V385H18V422H27',
    0x75: '383 58Q327 -10 256 -10H249Q124 -10 105 89Q104 96 103 226Q102 335 102 348T96 369Q86 385 36 385H25V408Q25 431 27 431L38 432Q48 433 67 434T105 436Q122 437 142 438T172 441T184 442H187V261Q188 77 190 64Q193 49 204 40Q224 26 264 26Q290 26 311 35T343 58T363 90T375 120T379 144Q379 145 379 161T380 201T380 248V315Q380 361 370 372T320 385H302V431Q304 431 378 436T457 442H464V264Q464 84 465 81Q468 61 479 55T524 46H542V0Q540 0 467 -5T390 -11H383V58',
    0x76: '338 431Q344 429 422 429Q479 429 503 431H508V385H497Q439 381 423 345Q421 341 356 172T288 -2Q283 -11 263 -11Q244 -11 239 -2Q99 359 98 364Q93 378 82 381T43 385H19V431H25L33 430Q41 430 53 430T79 430T104 429T122 428Q217 428 232 431H240V385H226Q187 384 184 370Q184 366 235 234L286 102L377 341V349Q377 363 367 372T349 383T335 385H331V431H338',
    0x77: '90 368Q84 378 76 380T40 385H18V431H24L43 430Q62 430 84 429T116 428Q206 428 221 431H229V385H215Q177 383 177 368Q177 367 221 239L265 113L339 328L333 345Q323 374 316 379Q308 384 278 385H258V431H264Q270 428 348 428Q439 428 454 431H461V385H452Q404 385 404 369Q404 366 418 324T449 234T481 143L496 100L537 219Q579 341 579 347Q579 363 564 373T530 385H522V431H529Q541 428 624 428Q692 428 698 431H703V385H697Q696 385 691 385T682 384Q635 377 619 334L559 161Q546 124 528 71Q508 12 503 1T487 -11H479Q460 -11 456 -4Q455 -3 407 133L361 267Q359 263 266 -4Q261 -11 243 -11H238Q225 -11 220 -3L90 368',
    0x78: '201 0Q189 3 102 3Q26 3 17 0H11V46H25Q48 47 67 52T96 61T121 78T139 96T160 122T180 150L226 210L168 288Q159 301 149 315T133 336T122 351T113 363T107 370T100 376T94 379T88 381T80 383Q74 383 44 385H16V431H23Q59 429 126 429Q219 429 229 431H237V385Q201 381 201 369Q201 367 211 353T239 315T268 274L272 270L297 304Q329 345 329 358Q329 364 327 369T322 376T317 380T310 384L307 385H302V431H309Q324 428 408 428Q487 428 493 431H499V385H492Q443 385 411 368Q394 360 377 341T312 257L296 236L358 151Q424 61 429 57T446 50Q464 46 499 46H516V0H510H502Q494 1 482 1T457 2T432 2T414 3Q403 3 377 3T327 1L304 0H295V46H298Q309 46 320 51T331 63Q331 65 291 120L250 175Q249 174 219 133T185 88Q181 83 181 74Q181 63 188 55T206 46Q208 46 208 23V0H201',
    0x79: '69 -66Q91 -66 104 -80T118 -116Q118 -134 109 -145T91 -160Q84 -163 97 -166Q104 -168 111 -168Q131 -168 148 -159T175 -138T197 -106T213 -75T225 -43L242 0L170 183Q150 233 125 297Q101 358 96 368T80 381Q79 382 78 382Q66 385 34 385H19V431H26L46 430Q65 430 88 429T122 428Q129 428 142 428T171 429T200 430T224 430L233 431H241V385H232Q183 385 185 366L286 112Q286 113 332 227L376 341V350Q376 365 366 373T348 383T334 385H331V431H337H344Q351 431 361 431T382 430T405 429T422 429Q477 429 503 431H508V385H497Q441 380 422 345Q420 343 378 235T289 9T227 -131Q180 -204 113 -204Q69 -204 44 -177T19 -116Q19 -89 35 -78T69 -66',
    0x7A: '42 263Q44 270 48 345T53 423V431H393Q399 425 399 415Q399 403 398 402L381 378Q364 355 331 309T265 220L134 41L182 40H206Q254 40 283 46T331 77Q352 105 359 185L361 201Q361 202 381 202H401V196Q401 195 393 103T384 6V0H209L34 1L31 3Q28 8 28 17Q28 30 29 31T160 210T294 394H236Q169 393 152 388Q127 382 113 367Q89 344 82 264V255H42V263',
    0x7B: '434 -231Q434 -244 428 -250H410Q281 -250 230 -184Q225 -177 222 -172T217 -161T213 -148T211 -133T210 -111T209 -84T209 -47T209 0Q209 21 209 53Q208 142 204 153Q203 154 203 155Q189 191 153 211T82 231Q71 231 68 234T65 250T68 266T82 269Q116 269 152 289T203 345Q208 356 208 377T209 529V579Q209 634 215 656T244 698Q270 724 324 740Q361 748 377 749Q379 749 390 749T408 750H428Q434 744 434 732Q434 719 431 716Q429 713 415 713Q362 710 332 689T296 647Q291 634 291 499V417Q291 370 288 353T271 314Q240 271 184 255L170 250L184 245Q202 239 220 230T262 196T290 137Q291 131 291 1Q291 -134 296 -147Q306 -174 339 -192T415 -213Q429 -213 431 -216Q434 -219 434 -231',
    0x7C: '139 -249H137Q125 -249 119 -235V251L120 737Q130 750 139 750Q152 750 159 735V-235Q151 -249 141 -249H139',
    0x7D: '65 731Q65 745 68 747T88 750Q171 750 216 725T279 670Q288 649 289 635T291 501Q292 362 293 357Q306 312 345 291T417 269Q428 269 431 266T434 250T431 234T417 231Q380 231 345 210T298 157Q293 143 292 121T291 -28V-79Q291 -134 285 -156T256 -198Q202 -250 89 -250Q71 -250 68 -247T65 -230Q65 -224 65 -223T66 -218T69 -214T77 -213Q91 -213 108 -210T146 -200T183 -177T207 -139Q208 -134 209 3L210 139Q223 196 280 230Q315 247 330 250Q305 257 280 270Q225 304 212 352L210 362L209 498Q208 635 207 640Q195 680 154 696T77 713Q68 713 67 716T65 731',
    0x7E: '179 251Q164 251 151 245T131 234T111 215L97 227L83 238Q83 239 95 253T121 283T142 304Q165 318 187 318T253 300T320 282Q335 282 348 288T368 299T388 318L402 306L416 295Q375 236 344 222Q330 215 313 215Q292 215 248 233T179 251',
    0xA0: '',
    0xA3: '699 578Q699 473 635 473Q597 473 595 508Q595 559 654 569V576Q654 619 637 648T581 677Q545 677 513 647T463 561Q460 554 437 464T414 371Q414 370 458 370H502Q508 364 508 362Q505 334 495 324H402L382 241Q377 224 373 206T366 180T361 163T358 151T354 142T350 133T344 120Q340 112 338 107T336 101L354 90Q398 63 422 54T476 44Q515 44 539 73T574 133Q578 144 580 146T598 148Q622 148 622 139Q622 138 620 130Q602 74 555 32T447 -11Q395 -11 317 38L294 51Q271 28 233 9T155 -10Q117 -10 103 5T88 39Q88 73 126 106T224 139Q236 139 247 138T266 134L273 132Q275 132 302 239L323 324H259Q253 330 253 332Q253 350 265 370H300L334 371L355 453Q356 457 360 477T366 501T372 522T379 545T387 565T397 587T409 606T425 627Q453 664 497 689T583 714Q640 714 669 676T699 578ZM245 76Q211 85 195 85Q173 85 158 71T142 42Q142 26 160 26H163Q211 30 245 76',
    0xA5: '515 0Q494 3 374 3Q256 3 235 0H224V46H257Q316 47 324 58Q327 62 327 137V213H133Q121 213 113 213T97 213T86 213T78 213T73 214T70 215T69 216T68 218T67 220Q64 225 66 231T73 240Q76 242 202 242H327V273L247 407H115Q81 407 75 408T67 414Q64 419 66 425T73 434Q76 436 153 436Q228 436 228 437Q227 440 173 530T115 623Q101 637 31 637H11V683H20Q66 681 153 681Q169 681 202 681T262 682L288 683H298V637H280Q230 636 230 621Q230 619 250 584Q255 576 264 561T286 526T305 494L340 437L403 436H467L513 514Q564 596 564 605Q564 608 560 616Q550 634 517 637H508V683H516Q531 680 633 680Q722 680 731 683H738V637H723Q644 632 617 595Q614 591 568 515T521 437T597 436T676 434Q681 432 683 426T682 414T671 409T589 407H503L422 273V242H547Q673 242 676 240Q681 238 683 232T682 220Q682 219 682 218T681 217T679 216T677 215T672 214T664 213T652 213T637 213T616 213H422V139V87Q422 64 425 58T441 49Q456 46 503 46H525V0H515ZM449 406Q449 407 403 407Q358 407 358 406L370 387Q381 368 392 350L404 331Q447 404 449 406',
    0xA8: '95 612Q95 633 112 651T153 669T193 652T210 612Q210 588 194 571T152 554L127 560Q95 577 95 612ZM289 611Q289 634 304 649T335 668Q336 668 340 668T346 669Q369 669 386 652T404 612T387 572T346 554Q323 554 306 570T289 611',
    0xAC: '56 323T56 336T70 356H596Q603 353 611 343V102Q598 89 591 89Q587 89 584 90T579 94T575 98T572 102L571 209V316H70Q56 323 56 336',
    0xAE: '915 266Q915 140 852 38T689 -120T474 -175Q312 -175 188 -71T38 190Q32 220 32 266V287Q32 345 57 416T129 545Q192 624 282 666T464 709Q513 709 522 708Q599 698 665 666T776 590T853 493T900 387T915 287V266ZM875 285Q875 339 853 399T789 517T676 616T519 668Q510 669 465 669Q380 669 299 630T155 514T77 336Q72 312 72 285V266V256Q72 123 163 11Q290 -135 474 -135Q614 -135 727 -46Q875 81 875 266V285ZM276 457Q275 458 274 460T272 463T270 465T267 467T264 469T258 471T252 472T243 473T232 474T218 474H204V514H335Q477 514 499 510Q560 502 610 467T661 375Q661 362 658 350T648 327T635 308T618 292T601 280T583 269T568 262T554 256L547 253Q548 252 556 247T570 237T586 223T602 202T614 174Q616 169 626 123T638 72Q652 23 683 23Q715 23 720 68Q721 78 724 81T740 84T756 82T760 70Q760 47 747 25T715 -7Q700 -14 673 -14Q672 -14 662 -14T643 -12T619 -7T593 2T568 16T547 37T534 67Q531 80 531 97Q531 103 531 116T532 136Q532 218 472 236Q466 238 413 239H360V148L361 58Q366 47 375 44T418 40H432V0H424Q409 3 318 3T212 0H204V40H218Q242 40 253 42T268 47T276 58V457ZM376 473Q365 471 363 464T360 430V366V276H416Q421 276 434 276T453 276T469 277T486 279T501 282T517 287T529 294T542 305Q561 324 561 375Q561 424 545 444T482 472Q478 473 427 474Q415 474 403 474T384 474L376 473',
    0xAF: '69 544V590H430V544H69',
    0xB0: '147 628Q147 669 179 692T244 715Q298 715 325 689T352 629Q352 592 323 567T249 542Q202 542 175 567T147 628ZM313 628Q313 660 300 669T259 678H253Q248 678 242 678T234 679Q217 679 207 674T192 659T188 644T187 629Q187 600 198 590Q210 579 250 579H265Q279 579 288 581T305 595T313 628',
    0xB1: '56 320T56 333T70 353H369V502Q369 651 371 655Q376 666 388 666Q402 666 405 654T409 596V500V353H707Q722 345 722 333Q722 320 707 313H409V40H707Q722 32 722 20T707 0H70Q56 7 56 20T70 40H369V313H70Q56 320 56 333',
    0xB4: '349 699Q367 699 380 686T393 656Q393 651 392 647T387 637T380 627T367 616T351 602T330 585T303 563L232 505L217 519Q203 533 204 533Q204 534 229 567T282 636T313 678L316 681Q318 684 321 686T328 692T337 697T349 699',
    0xB7: '78 250Q78 274 95 292T138 310Q162 310 180 294T199 251Q199 226 182 208T139 190T96 207T78 250',
    0xD7: '630 29Q630 9 609 9Q604 9 587 25T493 118L389 222L284 117Q178 13 175 11Q171 9 168 9Q160 9 154 15T147 29Q147 36 161 51T255 146L359 250L255 354Q174 435 161 449T147 471Q147 480 153 485T168 490Q173 490 175 489Q178 487 284 383L389 278L493 382Q570 459 587 475T609 491Q630 491 630 471Q630 464 620 453T522 355L418 250L522 145Q606 61 618 48T630 29',
    0xF0: '75 566V604Q75 624 79 629T102 635Q124 635 127 629T131 588L133 550L191 588L249 628L231 635Q176 654 124 657Q116 657 106 658L95 659Q94 661 94 687T95 715Q99 717 113 717Q195 717 282 679L309 668L331 681Q351 697 391 721Q428 748 435 748Q437 749 446 749Q470 749 473 746Q478 744 478 681V621Q466 615 456 615Q435 615 424 624L422 661V699L382 675L344 648Q353 639 366 630Q480 538 504 413Q509 393 509 333V313Q509 284 507 257T495 184T466 102T413 33T329 -16Q311 -21 275 -21Q226 -21 195 -10Q150 7 110 50T53 141Q42 179 42 227Q42 332 101 403T245 474Q282 474 314 461T359 436T380 415Q386 405 389 408Q389 426 378 475Q368 505 355 529T329 567T306 590T288 603L282 606L120 501Q116 500 102 500Q84 500 75 506V566ZM388 225Q388 376 309 410Q299 416 273 419Q216 419 191 390Q174 371 168 342T162 218Q162 112 184 79Q212 39 273 39Q312 39 342 62T380 121Q388 159 388 225',
    0xF7: '318 466Q318 500 339 518T386 537Q418 537 438 517T458 466Q458 438 440 417T388 396Q355 396 337 417T318 466ZM56 237T56 250T70 270H706Q721 262 721 250T706 230H70Q56 237 56 250ZM318 34Q318 68 339 86T386 105Q418 105 438 85T458 34Q458 6 440 -15T388 -36Q355 -36 337 -15T318 34',
    0x131: '247 0Q232 3 143 3Q132 3 106 3T56 1L34 0H26V46H42Q70 46 91 49Q100 53 102 60T104 102V205V293Q104 345 102 359T88 378Q74 385 41 385H30V408Q30 431 32 431L42 432Q52 433 70 434T106 436Q123 437 142 438T171 441T182 442H185V62Q190 52 197 50T232 46H255V0H247',
    0x237: '28 -163Q58 -168 64 -168Q124 -168 135 -77Q137 -65 137 141T136 353Q132 371 120 377T72 385H52V408Q52 431 54 431L58 432Q62 432 70 432T87 433T108 434T133 436Q151 437 171 438T202 441T214 442H218V184Q217 -36 217 -59T211 -98Q195 -145 153 -175T58 -205Q9 -205 -23 -179T-55 -117Q-55 -94 -40 -79T-2 -64T36 -79T52 -118Q52 -143 28 -163',
    0x2B9: '79 43Q73 43 52 49T30 61Q30 68 85 293T146 528Q161 560 198 560Q218 560 240 545T262 501Q262 496 260 486Q259 479 173 263T84 45T79 43',
    0x2C6: '112 560L249 694L257 686Q387 562 387 560L361 531Q359 532 303 581L250 627L195 580Q182 569 169 557T148 538L140 532Q138 530 125 546L112 560',
    0x2C7: '114 611L127 630L136 644Q138 644 193 612Q248 581 250 581L306 612Q361 644 363 644L385 611L318 562L249 513L114 611',
    0x2C9: '69 544V590H430V544H69',
    0x2CA: '349 699Q367 699 380 686T393 656Q393 651 392 647T387 637T380 627T367 616T351 602T330 585T303 563L232 505L217 519Q203 533 204 533Q204 534 229 567T282 636T313 678L316 681Q318 684 321 686T328 692T337 697T349 699',
    0x2CB: '106 655Q106 671 119 685T150 699Q166 699 177 688Q190 671 222 629T275 561T295 533T282 519L267 505L196 563Q119 626 113 634Q106 643 106 655',
    0x2D8: '250 515Q179 515 138 565T92 683V694H129V689Q129 688 129 683T130 675Q137 631 169 599T248 567Q304 567 337 608T370 689V694H407V683Q403 617 361 566T250 515',
    0x2D9: '190 609Q190 637 208 653T252 669Q275 667 292 652T309 609Q309 579 292 564T250 549Q225 549 208 564T190 609',
    0x2DA: '147 628Q147 669 179 692T244 715Q298 715 325 689T352 629Q352 592 323 567T249 542Q202 542 175 567T147 628ZM313 628Q313 660 300 669T259 678H253Q248 678 242 678T234 679Q217 679 207 674T192 659T188 644T187 629Q187 600 198 590Q210 579 250 579H265Q279 579 288 581T305 595T313 628',
    0x2DC: '179 601Q164 601 151 595T131 584T111 565L97 577L83 588Q83 589 95 603T121 633T142 654Q165 668 187 668T253 650T320 632Q335 632 348 638T368 649T388 668L402 656L416 645Q375 586 344 572Q330 565 313 565Q292 565 248 583T179 601',
    0x300: '-394 655Q-394 671 -381 685T-350 699Q-334 699 -323 688Q-310 671 -278 629T-225 561T-205 533T-218 519L-233 505L-304 563Q-381 626 -387 634Q-394 643 -394 655',
    0x301: '-151 699Q-133 699 -120 686T-107 656Q-107 651 -108 647T-113 637T-120 627T-133 616T-149 602T-170 585T-197 563L-268 505L-283 519Q-297 533 -296 533Q-296 534 -271 567T-218 636T-187 678L-184 681Q-182 684 -179 686T-172 692T-163 697T-151 699',
    0x302: '-388 560L-251 694L-243 686Q-113 562 -113 560L-139 531Q-141 532 -197 581L-250 627L-305 580Q-318 569 -331 557T-352 538L-360 532Q-362 530 -375 546L-388 560',
    0x303: '-321 601Q-336 601 -349 595T-369 584T-389 565L-403 577L-417 588Q-417 589 -405 603T-379 633T-358 654Q-335 668 -313 668T-247 650T-180 632Q-165 632 -152 638T-132 649T-112 668L-98 656L-84 645Q-125 586 -156 572Q-170 565 -187 565Q-208 565 -252 583T-321 601',
    0x304: '-431 544V590H-70V544H-431',
    0x306: '-250 515Q-321 515 -362 565T-408 683V694H-371V689Q-371 688 -371 683T-370 675Q-363 631 -331 599T-252 567Q-196 567 -163 608T-130 689V694H-93V683Q-97 617 -139 566T-250 515',
    0x307: '-310 609Q-310 637 -292 653T-248 669Q-225 667 -208 652T-191 609Q-191 579 -208 564T-250 549Q-275 549 -292 564T-310 609',
    0x308: '-405 612Q-405 633 -388 651T-347 669T-307 652T-290 612Q-290 588 -306 571T-348 554L-373 560Q-405 577 -405 612ZM-211 611Q-211 634 -196 649T-165 668Q-164 668 -160 668T-154 669Q-131 669 -114 652T-96 612T-113 572T-154 554Q-177 554 -194 570T-211 611',
    0x30A: '-353 628Q-353 669 -321 692T-256 715Q-202 715 -175 689T-148 629Q-148 592 -177 567T-251 542Q-298 542 -325 567T-353 628ZM-187 628Q-187 660 -200 669T-241 678H-247Q-252 678 -258 678T-266 679Q-283 679 -293 674T-308 659T-312 644T-313 629Q-313 600 -302 590Q-290 579 -250 579H-235Q-221 579 -212 581T-195 595T-187 628',
    0x30B: '-292 701Q-278 701 -262 690T-246 658Q-246 649 -250 641Q-252 637 -297 574T-344 510L-378 528Q-378 530 -355 598T-327 676Q-316 701 -292 701ZM-126 701Q-112 701 -96 690T-80 658Q-80 649 -84 641Q-86 637 -131 574T-178 510L-212 528Q-212 530 -189 598T-161 676Q-150 701 -126 701',
    0x30C: '-386 611L-373 630L-364 644Q-362 644 -307 612Q-252 581 -250 581L-194 612Q-139 644 -137 644L-115 611L-182 562L-251 513L-386 611',
    0x338: '-612 -215T-619 -215T-631 -212T-637 -204T-639 -197Q-639 -190 -634 -183Q-621 -157 -400 274T-176 707Q-173 716 -160 716Q-153 716 -148 712T-142 703T-140 696Q-140 691 -372 241T-608 -212Q-612 -215 -619 -215',
    0x391: '255 0Q240 3 140 3Q48 3 39 0H32V46H47Q119 49 139 88Q140 91 192 245T295 553T348 708Q351 716 366 716H376Q396 715 400 709Q402 707 508 390L617 67Q624 54 636 51T687 46H717V0H708Q699 3 581 3Q458 3 437 0H427V46H440Q510 46 510 64Q510 66 486 138L462 209H229L209 150Q189 91 189 85Q189 72 209 59T259 46H264V0H255ZM447 255L345 557L244 256Q244 255 345 255H447',
    0x392: '131 622Q124 629 120 631T104 634T61 637H28V683H229H267H346Q423 683 459 678T531 651Q574 627 599 590T624 512Q624 461 583 419T476 360L466 357Q539 348 595 302T651 187Q651 119 600 67T469 3Q456 1 242 0H28V46H61Q103 47 112 49T131 61V622ZM511 513Q511 560 485 594T416 636Q415 636 403 636T371 636T333 637Q266 637 251 636T232 628Q229 624 229 499V374H312L396 375L406 377Q410 378 417 380T442 393T474 417T499 456T511 513ZM537 188Q537 239 509 282T430 336L329 337H229V200V116Q229 57 234 52Q240 47 334 47H383Q425 47 443 53Q486 67 511 104T537 188',
    0x393: '128 619Q121 626 117 628T101 631T58 634H25V680H554V676Q556 670 568 560T582 444V440H542V444Q542 445 538 478T523 545T492 598Q454 634 349 634H334Q264 634 249 633T233 621Q232 618 232 339L233 61Q240 54 245 52T270 48T333 46H360V0H348Q324 3 182 3Q51 3 36 0H25V46H58Q100 47 109 49T128 61V619',
    0x394: '51 0Q46 4 46 7Q46 9 215 357T388 709Q391 716 416 716Q439 716 444 709Q447 705 616 357T786 7Q786 4 781 0H51ZM507 344L384 596L137 92L383 91H630Q630 93 507 344',
    0x395: '128 619Q121 626 117 628T101 631T58 634H25V680H597V676Q599 670 611 560T625 444V440H585V444Q584 447 582 465Q578 500 570 526T553 571T528 601T498 619T457 629T411 633T353 634Q266 634 251 633T233 622Q233 622 233 621Q232 619 232 497V376H286Q359 378 377 385Q413 401 416 469Q416 471 416 473V493H456V213H416V233Q415 268 408 288T383 317T349 328T297 330Q290 330 286 330H232V196V114Q232 57 237 52Q243 47 289 47H340H391Q428 47 452 50T505 62T552 92T584 146Q594 172 599 200T607 247T612 270V273H652V270Q651 267 632 137T610 3V0H25V46H58Q100 47 109 49T128 61V619',
    0x396: '69 443Q69 452 74 554T80 683H549Q555 677 555 664Q555 649 554 648Q552 645 366 348T179 50T192 49T263 49H275H302Q333 49 353 50T401 59T447 78T482 115T507 173Q513 200 520 273V282H560V274Q560 272 552 143T543 8V0H302L61 1L58 3Q55 8 55 21V35Q59 43 153 193T340 489T432 637H343Q259 637 214 625T141 573Q109 523 109 445Q109 443 89 443H69',
    0x397: '128 622Q121 629 117 631T101 634T58 637H25V683H36Q57 680 180 680Q315 680 324 683H335V637H302Q262 636 251 634T233 622L232 500V378H517V622Q510 629 506 631T490 634T447 637H414V683H425Q446 680 569 680Q704 680 713 683H724V637H691Q651 636 640 634T622 622V61Q628 51 639 49T691 46H724V0H713Q692 3 569 3Q434 3 425 0H414V46H447Q489 47 498 49T517 61V332H232V197L233 61Q239 51 250 49T302 46H335V0H324Q303 3 180 3Q45 3 36 0H25V46H58Q100 47 109 49T128 61V622',
    0x398: '56 340Q56 423 86 494T164 610T270 680T388 705Q521 705 621 601T722 341Q722 260 693 191T617 75T510 4T388 -22T267 3T160 74T85 189T56 340ZM610 339Q610 428 590 495T535 598T463 651T384 668Q332 668 289 638T221 566Q168 485 168 339Q168 274 176 235Q189 158 228 105T324 28Q356 16 388 16Q415 16 442 24T501 54T555 111T594 205T610 339ZM223 263V422H263V388H514V422H554V263H514V297H263V263H223',
    0x399: '328 0Q307 3 180 3T32 0H21V46H43Q92 46 106 49T126 60Q128 63 128 342Q128 620 126 623Q122 628 118 630T96 635T43 637H21V683H32Q53 680 180 680T328 683H339V637H317Q268 637 254 634T234 623Q232 620 232 342Q232 63 234 60Q238 55 242 53T264 48T317 46H339V0H328',
    0x39A: '128 622Q121 629 117 631T101 634T58 637H25V683H36Q57 680 180 680Q315 680 324 683H335V637H313Q235 637 233 620Q232 618 232 462L233 307L379 449Q425 494 479 546Q518 584 524 591T531 607V608Q531 630 503 636Q501 636 498 636T493 637H489V683H499Q517 680 630 680Q704 680 716 683H722V637H708Q633 633 589 597Q584 592 495 506T406 419T515 254T631 80Q644 60 662 54T715 46H736V0H728Q719 3 615 3Q493 3 472 0H461V46H469Q515 46 515 72Q515 78 512 84L336 351Q332 348 278 296L232 251V156Q232 62 235 58Q243 47 302 46H335V0H324Q303 3 180 3Q45 3 36 0H25V46H58Q100 47 109 49T128 61V622',
    0x39B: '320 708Q326 716 340 716H348H355Q367 716 372 708Q374 706 423 547T523 226T575 62Q581 52 591 50T634 46H661V0H653Q644 3 532 3Q411 3 390 0H379V46H392Q464 46 464 65Q463 70 390 305T316 539L246 316Q177 95 177 84Q177 72 198 59T248 46H253V0H245Q230 3 130 3Q47 3 38 0H32V46H45Q112 51 127 91Q128 92 224 399T320 708',
    0x39C: '132 622Q125 629 121 631T105 634T62 637H29V683H135Q221 683 232 682T249 675Q250 674 354 398L458 124L562 398Q666 674 668 675Q671 681 683 682T781 683H887V637H854Q814 636 803 634T785 622V61Q791 51 802 49T854 46H887V0H876Q855 3 736 3Q605 3 596 0H585V46H618Q660 47 669 49T688 61V347Q688 424 688 461T688 546T688 613L687 632Q454 14 450 7Q446 1 430 1T410 7Q409 9 292 316L176 624V606Q175 588 175 543T175 463T175 356L176 86Q187 50 261 46H278V0H269Q254 3 154 3Q52 3 37 0H29V46H46Q78 48 98 56T122 69T132 86V622',
    0x39D: '42 46Q74 48 94 56T118 69T128 86V634H124Q114 637 52 637H25V683H232L235 680Q237 679 322 554T493 303L578 178V598Q572 608 568 613T544 627T492 637H475V683H483Q498 680 600 680Q706 680 715 683H724V637H707Q634 633 622 598L621 302V6L614 0H600Q585 0 582 3T481 150T282 443T171 605V345L172 86Q183 50 257 46H274V0H265Q250 3 150 3Q48 3 33 0H25V46H42',
    0x39E: '47 509L55 676Q55 677 333 677T611 676L619 509Q619 508 599 508T579 510Q579 529 575 557T564 589Q550 594 333 594T102 589Q95 586 91 558T87 510Q87 508 67 508T47 509ZM139 260V445H179V394H487V445H527V260H487V311H179V260H139ZM50 0L42 180H62Q82 180 82 178Q82 133 89 105Q92 93 95 90T108 86Q137 83 333 83Q530 83 558 86Q568 87 571 90T577 105Q584 133 584 178Q584 180 604 180H624L616 0H50',
    0x39F: '56 340Q56 423 86 494T164 610T270 680T388 705Q521 705 621 601T722 341Q722 260 693 191T617 75T510 4T388 -22T267 3T160 74T85 189T56 340ZM467 647Q426 665 388 665Q360 665 331 654T269 620T213 549T179 439Q174 411 174 354Q174 144 277 61Q327 20 385 20H389H391Q474 20 537 99Q603 188 603 354Q603 411 598 439Q577 592 467 647',
    0x3A0: '128 619Q121 626 117 628T101 631T58 634H25V680H724V634H691Q651 633 640 631T622 619V61Q628 51 639 49T691 46H724V0H713Q692 3 569 3Q434 3 425 0H414V46H447Q489 47 498 49T517 61V634H232V348L233 61Q239 51 250 49T302 46H335V0H324Q303 3 180 3Q45 3 36 0H25V46H58Q100 47 109 49T128 61V619',
    0x3A1: '130 622Q123 629 119 631T103 634T60 637H27V683H214Q237 683 276 683T331 684Q419 684 471 671T567 616Q624 563 624 489Q624 421 573 372T451 307Q429 302 328 301H234V181Q234 62 237 58Q245 47 304 46H337V0H326Q305 3 182 3Q47 3 38 0H27V46H60Q102 47 111 49T130 61V622ZM507 488Q507 514 506 528T500 564T483 597T450 620T397 635Q385 637 307 637H286Q237 637 234 628Q231 624 231 483V342H302H339Q390 342 423 349T481 382Q507 411 507 488',
    0x3A3: '666 247Q664 244 652 126T638 4V0H351Q131 0 95 0T57 5V6Q54 12 57 17L73 36Q89 54 121 90T182 159L305 299L56 644L55 658Q55 677 60 681Q63 683 351 683H638V679Q640 674 652 564T666 447V443H626V447Q618 505 604 543T559 605Q529 626 478 631T333 637H294H189L293 494Q314 465 345 422Q400 346 400 340Q400 338 399 337L154 57Q407 57 428 58Q476 60 508 68T551 83T575 103Q595 125 608 162T624 225L626 251H666V247',
    0x3A4: '36 443Q37 448 46 558T55 671V677H666V671Q667 666 676 556T685 443V437H645V443Q645 445 642 478T631 544T610 593Q593 614 555 625Q534 630 478 630H451H443Q417 630 414 618Q413 616 413 339V63Q420 53 439 50T528 46H558V0H545L361 3Q186 1 177 0H164V46H194Q264 46 283 49T309 63V339V550Q309 620 304 625T271 630H244H224Q154 630 119 601Q101 585 93 554T81 486T76 443V437H36V443',
    0x3A5: '55 551Q55 604 91 654T194 705Q240 705 277 681T334 624T367 556T385 498L389 474L392 488Q394 501 400 521T414 566T438 615T473 659T521 692T584 705Q620 705 648 689T691 647T714 597T722 551Q722 540 719 538T699 536Q680 536 677 541Q677 542 677 544T676 548Q676 576 650 596T588 616H582Q538 616 505 582Q466 543 454 477T441 318Q441 301 441 269T442 222V61Q448 55 452 53T478 48T542 46H569V0H557Q533 3 389 3T221 0H209V46H236Q256 46 270 46T295 47T311 48T322 51T328 54T332 57T337 61V209Q337 383 333 415Q313 616 189 616Q154 616 128 597T101 548Q101 540 97 538T78 536Q63 536 59 538T55 551',
    0x3A6: '312 622Q310 623 307 625T303 629T297 631T286 634T270 635T246 636T211 637H184V683H196Q220 680 361 680T526 683H538V637H511Q468 637 447 635T422 631T411 622V533L425 531Q525 519 595 466T665 342Q665 301 642 267T583 209T506 172T425 152L411 150V61Q417 55 421 53T447 48T511 46H538V0H526Q502 3 361 3T196 0H184V46H211Q231 46 245 46T270 47T286 48T297 51T303 54T307 57T312 61V150H310Q309 151 289 153T232 166T160 195Q149 201 136 210T103 238T69 284T56 342Q56 414 128 467T294 530Q309 532 310 533H312V622ZM170 342Q170 207 307 188H312V495H309Q301 495 282 491T231 469T186 423Q170 389 170 342ZM415 188Q487 199 519 236T551 342Q551 384 539 414T507 459T470 481T434 491T415 495H410V188H415',
    0x3A7: '270 0Q252 3 141 3Q46 3 31 0H23V46H40Q129 50 161 88Q165 94 244 216T324 339Q324 341 235 480T143 622Q133 631 119 634T57 637H37V683H46Q64 680 172 680Q297 680 318 683H329V637H324Q307 637 286 632T263 621Q263 618 322 525T384 431Q385 431 437 511T489 593Q490 595 490 599Q490 611 477 622T436 637H428V683H437Q455 680 566 680Q661 680 676 683H684V637H667Q585 634 551 599Q548 596 478 491Q412 388 412 387Q412 385 514 225T620 62Q628 53 642 50T695 46H726V0H717Q699 3 591 3Q466 3 445 0H434V46H440Q454 46 476 51T499 64Q499 67 463 124T390 238L353 295L350 292Q348 290 343 283T331 265T312 236T286 195Q219 88 218 84Q218 70 234 59T272 46H280V0H270',
    0x3A8: '340 622Q338 623 335 625T331 629T325 631T314 634T298 635T274 636T239 637H212V683H224Q248 680 389 680T554 683H566V637H539Q479 637 464 635T439 622L438 407Q438 192 439 192Q443 193 449 195T474 207T507 232T536 276T557 344Q560 365 562 417T573 493Q587 536 620 544Q627 546 671 546H715L722 540V515Q714 509 708 509Q680 505 671 476T658 392T644 307Q599 177 451 153L438 151V106L439 61Q446 54 451 52T476 48T539 46H566V0H554Q530 3 389 3T224 0H212V46H239Q259 46 273 46T298 47T314 48T325 51T331 54T335 57T340 61V151Q126 178 117 406Q115 503 69 509Q55 509 55 526Q55 541 59 543T86 546H107H120Q150 546 161 543T184 528Q198 514 204 493Q212 472 213 420T226 316T272 230Q287 216 303 207T330 194L339 192Q340 192 340 407V622',
    0x3A9: '55 454Q55 503 75 546T127 617T197 665T272 695T337 704H352Q396 704 404 703Q527 687 596 615T666 454Q666 392 635 330T559 200T499 83V80H543Q589 81 600 83T617 93Q622 102 629 135T636 172L637 177H677V175L660 89Q645 3 644 2V0H552H488Q461 0 456 3T451 20Q451 89 499 235T548 455Q548 512 530 555T483 622T424 656T361 668Q332 668 303 658T243 626T193 560T174 456Q174 380 222 233T270 20Q270 7 263 0H77V2Q76 3 61 89L44 175V177H84L85 172Q85 171 88 155T96 119T104 93Q109 86 120 84T178 80H222V83Q206 132 162 199T87 329T55 454',
    0x2000: '',
    0x2001: '',
    0x2002: '',
    0x2003: '',
    0x2004: '',
    0x2005: '',
    0x2006: '',
    0x2009: '',
    0x200A: '',
    0x200B: '',
    0x200C: '',
    0x2013: '0 248V285H499V248H0',
    0x2014: '0 248V285H999V248H0',
    0x2015: '0 248V285H999V248H0',
    0x2016: '133 736Q138 750 153 750Q164 750 170 739Q172 735 172 250T170 -239Q164 -250 152 -250Q144 -250 138 -244L137 -243Q133 -241 133 -179T132 250Q132 731 133 736ZM329 739Q334 750 346 750Q353 750 361 744L362 743Q366 741 366 679T367 250T367 -178T362 -243L361 -244Q355 -250 347 -250Q335 -250 329 -239Q327 -235 327 250T329 739',
    0x2017: '0 -62V-25H499V-62H0',
    0x2018: '64 494Q64 548 86 597T131 670T160 694Q163 694 172 685T182 672Q182 669 170 656T144 625T116 573T101 501Q101 489 102 489T107 491T120 497T138 500Q163 500 180 483T198 440T181 397T139 379Q110 379 87 405T64 494',
    0x2019: '78 634Q78 659 95 676T138 694Q166 694 189 668T212 579Q212 525 190 476T146 403T118 379Q114 379 105 388T95 401Q95 404 107 417T133 448T161 500T176 572Q176 584 175 584T170 581T157 576T139 573Q114 573 96 590T78 634',
    0x201C: '128 494Q128 528 137 560T158 616T185 658T209 685T223 694T236 685T245 670Q244 668 231 654T204 622T178 571T164 501Q164 489 165 489T170 491T183 497T201 500Q226 500 244 483T262 440T245 397T202 379Q173 379 151 405T128 494ZM332 494Q332 528 341 560T362 616T389 658T413 685T427 694T439 685T449 672Q449 669 437 656T411 625T383 573T368 501Q368 489 369 489T374 491T387 497T405 500Q430 500 448 483T466 440T449 397T406 379Q377 379 355 405T332 494',
    0x201D: '34 634Q34 659 50 676T93 694Q121 694 144 668T168 579Q168 525 146 476T101 403T73 379Q69 379 60 388T50 401Q50 404 62 417T88 448T116 500T131 572Q131 584 130 584T125 581T112 576T94 573Q69 573 52 590T34 634ZM238 634Q238 659 254 676T297 694Q325 694 348 668T372 579Q372 525 350 476T305 403T277 379Q273 379 264 388T254 401Q254 404 266 417T292 448T320 500T335 572Q335 584 334 584T329 581T316 576T298 573Q273 573 256 590T238 634',
    0x2020: '182 675Q195 705 222 705Q234 705 243 700T253 691T263 675L262 655Q262 620 252 549T240 454V449Q250 451 288 461T346 472T377 461T389 431Q389 417 379 404T346 390Q327 390 288 401T243 412H240V405Q245 367 250 339T258 301T261 274T263 225Q263 124 255 -41T239 -213Q236 -216 222 -216H217Q206 -216 204 -212T200 -186Q199 -175 199 -168Q181 38 181 225Q181 265 182 280T191 327T204 405V412H201Q196 412 157 401T98 390Q76 390 66 403T55 431T65 458T98 472Q116 472 155 462T205 449Q204 452 204 460T201 490T193 547Q182 619 182 655V675',
    0x2021: '181 658Q181 705 222 705T263 658Q263 633 252 572T240 497Q240 496 241 496Q243 496 285 507T345 519Q365 519 376 508T388 478Q388 466 384 458T375 447T361 438H344Q318 438 282 448T241 459Q240 458 240 456Q240 449 251 384T263 297Q263 278 255 267T238 253T222 250T206 252T190 266T181 297Q181 323 192 383T204 458Q204 459 203 459Q198 459 162 449T101 438H84Q74 443 70 446T61 457T56 478Q56 497 67 508T99 519Q117 519 159 508T203 496Q204 496 204 499Q204 507 193 572T181 658ZM181 202Q181 249 222 249T263 202Q263 185 259 161T249 103T240 48V41H243Q248 41 287 52T346 63T377 52T389 22Q389 8 379 -5T346 -19Q327 -19 288 -8T243 3H240V-4Q243 -24 249 -58T259 -117T263 -158Q263 -177 255 -188T238 -202T222 -205T206 -203T190 -189T181 -158Q181 -141 185 -117T195 -59T204 -4V3H201Q196 3 157 -8T98 -19Q76 -19 66 -6T55 22T65 49T98 63Q117 63 156 52T201 41H204V48Q201 68 195 102T185 161T181 202',
    0x2022: '55 251Q55 328 112 386T249 444T386 388T444 249Q444 171 388 113T250 55Q170 55 113 112T55 251',
    0x2026: '78 60Q78 84 95 102T138 120Q162 120 180 104T199 61Q199 36 182 18T139 0T96 17T78 60ZM525 60Q525 84 542 102T585 120Q609 120 627 104T646 61Q646 36 629 18T586 0T543 17T525 60ZM972 60Q972 84 989 102T1032 120Q1056 120 1074 104T1093 61Q1093 36 1076 18T1033 0T990 17T972 60',
    0x2032: '79 43Q73 43 52 49T30 61Q30 68 85 293T146 528Q161 560 198 560Q218 560 240 545T262 501Q262 496 260 486Q259 479 173 263T84 45T79 43',
    0x2035: '12 501Q12 527 31 542T63 558Q73 560 77 560Q114 560 128 528Q133 518 188 293T244 61Q244 56 223 50T195 43Q192 43 190 45T102 263T14 486Q12 496 12 501',
    0x203E: '69 544V590H430V544H69',
    0x2044: '423 750Q432 750 438 744T444 730Q444 725 271 248T92 -240Q85 -250 75 -250Q68 -250 62 -245T56 -231Q56 -221 230 257T407 740Q411 750 423 750',
    0x2060: '',
    0x2061: '',
    0x2062: '',
    0x2063: '',
    0x2064: '',
    0x20D7: '377 694Q377 702 382 708T397 714Q404 714 409 709Q414 705 419 690Q429 653 460 633Q471 626 471 615Q471 606 468 603T454 594Q411 572 379 531Q377 529 374 525T369 519T364 517T357 516Q350 516 344 521T337 536Q337 555 384 595H213L42 596Q29 605 29 615Q29 622 42 635H401Q377 673 377 694',
    0x2102: '684 131Q684 125 672 109T633 71T573 29T489 -5T386 -19Q330 -19 276 -3T174 46T91 134T44 261Q39 283 39 341T44 421Q66 538 143 611T341 699Q344 699 364 700T395 701Q449 698 503 677T585 655Q603 655 611 662T620 678T625 694T639 702Q650 702 657 690V481L653 474Q640 467 628 472Q624 476 618 496T595 541Q562 587 507 625T390 663H381Q337 663 299 625Q212 547 212 336Q212 249 233 179Q274 30 405 30Q533 30 641 130Q658 147 666 147Q671 147 677 143T684 131ZM250 625Q264 643 261 643Q238 635 214 620T161 579T110 510T79 414Q74 384 74 341T79 268Q89 213 113 169T164 101T217 61T260 39L277 34Q270 41 264 48Q199 111 181 254Q178 281 178 344T181 434Q200 559 250 625ZM621 565V625Q617 623 613 623Q603 619 590 619H575L588 605Q608 583 610 579L621 565',
    0x210B: '331 505Q331 519 382 574T472 629H480Q512 629 529 614T547 576Q547 555 534 532T520 504Q520 493 549 493Q590 493 623 506T668 533L681 546Q731 600 772 641T832 700T852 717Q857 717 860 711T865 697L866 690Q866 688 813 617T708 475T656 403Q682 403 714 404H771L780 416Q1004 707 1167 707Q1209 707 1225 689T1241 646Q1241 604 1209 547T1118 447Q1081 422 1034 405T952 382T888 374T857 370H852L826 334Q733 204 708 144Q691 104 691 76Q691 29 748 29Q768 31 791 48T831 83T862 122T881 146Q883 148 900 148H917Q921 143 921 140T914 127Q810 -8 723 -8Q611 -4 611 100Q611 142 631 191T676 275T721 337T742 367Q716 367 685 366H628L620 355Q618 352 558 268Q486 168 461 141Q405 79 339 34T215 -28Q188 -36 153 -36Q86 -36 58 -11T29 46Q29 82 55 120T123 158Q144 158 154 146T164 119Q164 102 143 89T100 75Q92 75 86 76T77 80T72 82Q67 82 67 60Q67 28 99 14T170 0Q214 0 272 47T419 224L505 340L518 357Q513 357 504 356T467 347T415 330T360 300T308 253Q296 238 295 237H278H274Q256 237 256 243Q256 248 263 256Q291 294 330 321T407 362T476 382T530 393T552 398Q556 402 573 423T600 454Q602 457 604 460T608 465L610 467Q565 455 532 455Q465 455 449 483Q447 487 447 498Q447 513 463 538T479 579Q479 593 463 593Q436 593 385 519Q374 504 371 502T360 499H353H349Q331 499 331 505ZM1195 634Q1195 643 1195 648T1185 662T1157 671Q1130 671 1092 644T1019 579T952 502T901 436L882 409L891 410Q900 411 913 412T934 415Q1081 439 1144 520Q1195 590 1195 634',
    0x210C: '11 -16L1 0Q7 6 25 27T57 62T91 88T128 101Q159 99 195 66L203 59L211 67Q239 95 239 133Q239 158 210 213T152 330T123 430Q123 477 173 536T269 630T320 666Q376 610 440 606H443Q457 606 466 611T519 647L542 664Q543 664 543 654V643L522 622Q434 537 403 537Q388 537 366 543T329 555T293 570T270 580L261 585L253 574Q206 517 206 475Q206 452 218 416T242 356L255 331Q256 331 270 345T324 391T421 459L437 468H453Q545 463 608 421L618 415L623 392Q644 307 644 233Q644 97 612 9Q604 -10 601 -15T581 -35Q505 -104 467 -124Q446 -133 431 -133Q414 -132 399 -126T376 -115T368 -107Q368 -106 392 -75L415 -43Q432 -67 444 -73T472 -79H474Q479 -79 484 -78T501 -69T521 -50T538 -13T551 46Q558 97 558 180Q558 232 557 245Q553 277 547 300T528 349T488 389T424 404Q344 404 276 295Q272 288 273 285Q300 216 300 168Q300 161 300 156T298 145T297 137T293 129T289 123T283 116T277 107Q212 23 178 -13L166 -26L149 -9Q108 32 81 32Q63 32 21 -7L11 -16',
    0x210D: '14 666Q14 675 26 683H344L351 679Q361 665 351 655Q344 648 317 648Q287 645 282 641Q270 637 269 623T266 497V370H511V497Q511 519 510 553Q509 615 507 626T496 641H495Q489 645 459 648Q420 648 420 665Q420 672 426 679L433 683H751Q762 676 762 666Q762 648 724 648Q684 645 677 632Q675 626 675 341Q675 57 677 52Q684 38 724 35Q762 35 762 16Q762 6 751 -1H433L426 3Q420 10 420 17Q420 35 459 35Q501 38 506 52Q511 64 511 190V323H266V190Q266 60 271 52Q276 38 317 35Q342 35 351 28Q360 17 351 3L344 -1H26Q14 5 14 16Q14 35 53 35Q94 38 99 52Q104 60 104 341T99 632Q93 645 53 648Q14 648 14 666ZM233 341V553Q233 635 239 648H131Q134 641 135 638T137 603T139 517T139 341Q139 131 138 89T132 37Q131 36 131 35H239Q233 47 233 129V341ZM639 341V489Q639 548 639 576T640 620T642 639T646 648H537L542 639Q546 625 546 341Q546 130 545 88T538 37Q537 36 537 35H646Q643 41 643 42T641 55T639 84T639 140V341',
    0x210E: '137 683Q138 683 209 688T282 694Q294 694 294 685Q294 674 258 534Q220 386 220 383Q220 381 227 388Q288 442 357 442Q411 442 444 415T478 336Q478 285 440 178T402 50Q403 36 407 31T422 26Q450 26 474 56T513 138Q516 149 519 151T535 153Q555 153 555 145Q555 144 551 130Q535 71 500 33Q466 -10 419 -10H414Q367 -10 346 17T325 74Q325 90 361 192T398 345Q398 404 354 404H349Q266 404 205 306L198 293L164 158Q132 28 127 16Q114 -11 83 -11Q69 -11 59 -2T48 16Q48 30 121 320L195 616Q195 629 188 632T149 637H128Q122 643 122 645T124 664Q129 683 137 683',
    0x210F: '150 475Q147 475 118 466T82 457Q73 457 64 467T54 487Q54 490 55 492Q63 506 64 506Q67 512 118 526Q162 541 169 546Q173 559 175 575Q181 596 181 604Q181 613 166 617Q164 617 153 618T135 619Q119 619 114 621T109 630Q109 636 114 656T122 681Q125 685 202 688Q272 695 286 695Q304 695 304 684Q304 682 291 628L278 577L386 612Q466 635 476 635T492 627T499 607Q499 593 489 586Q485 583 373 546L262 512Q262 511 248 455T233 397T236 397T244 404Q295 441 357 441Q405 441 445 417T485 333Q485 284 449 178T412 58T426 44Q447 44 466 68Q485 87 500 130L509 152H531H543Q562 152 562 144Q562 128 546 93T494 23T415 -13Q385 -13 359 3T322 44Q318 52 318 77Q318 99 352 196T386 337Q386 386 346 386Q318 386 286 370Q267 361 245 338T211 292Q207 287 193 235T162 113T138 21Q128 7 122 4Q105 -12 83 -12Q66 -12 54 -2T42 26Q42 45 98 257L151 475H150',
    0x2110: '829 148Q845 148 845 143T841 130T823 109T788 83T730 54T644 22Q637 20 634 19T627 16T622 13T618 10T612 3T604 -6Q510 -112 396 -203T201 -312Q190 -314 171 -314H166Q156 -314 147 -312T123 -304T101 -283T92 -245Q92 -203 117 -160T175 -93Q214 -65 280 -41T390 -6T509 22L532 27L547 47Q673 219 673 225L665 228Q657 231 648 235T627 249T606 270T591 300T584 341Q584 389 614 447T686 544Q735 588 799 615T959 667T1108 713Q1118 717 1119 717Q1125 717 1129 705T1133 689Q1133 686 1115 673Q1051 627 1001 579T918 481T867 403T828 328T805 277Q802 271 801 267T798 261T798 259Q849 270 900 317Q956 371 956 421Q956 438 953 457T950 480Q950 481 950 482L951 484Q953 487 966 492T985 498Q989 498 994 473Q1000 441 1000 423Q1000 362 934 302T790 223L775 220L767 207Q757 191 731 158T685 98T662 63Q662 61 690 70T755 99T811 142L816 148H829ZM646 332Q646 308 659 291T685 268L698 263L735 320Q828 465 865 511Q923 582 1003 643L1005 645Q1004 645 924 620Q775 572 716 492Q646 401 646 332ZM184 -278Q233 -278 311 -212T444 -80L498 -15H496Q468 -20 424 -30T293 -70T174 -133Q167 -140 158 -153T138 -190T127 -232Q127 -278 184 -278',
    0x2111: '190 601Q161 601 137 587T97 553T71 512T55 477T48 463Q44 465 39 468L30 473L35 488Q73 594 106 636T199 685Q200 686 211 686Q250 686 326 652T417 617Q435 617 455 626T497 652T522 670Q532 660 532 654Q469 591 390 550L378 543L343 556Q223 601 190 601ZM378 208Q378 249 369 318T360 424Q360 430 360 439T361 451L362 462Q416 526 482 571L495 580L503 577L511 575L499 562Q442 502 442 465Q442 436 452 368T462 246Q462 169 442 128T385 56Q292 -26 195 -26Q150 -26 104 14L96 21L43 -16Q43 -15 43 -14T41 -10T38 0L48 13Q76 50 123 97L150 125Q154 131 159 131Q166 131 171 116T182 81T193 53Q199 43 216 33T261 22Q307 22 344 68Q378 113 378 208',
    0x2112: '572 704Q607 704 607 693Q607 681 590 664H588Q586 664 584 664T578 663Q504 658 434 592T363 457Q363 426 386 401Q417 371 481 361Q490 360 527 360H562Q565 363 595 404T666 494T755 596T854 682T945 717Q986 717 1010 696T1035 637Q1035 593 996 531T873 414Q809 378 753 360T674 338T651 333Q650 333 633 308T588 245T544 185Q498 126 426 78L413 68H414Q498 47 575 47Q626 47 676 74T755 139L762 148H779H783Q802 148 802 142Q802 137 795 129Q760 81 691 33T544 -16Q470 -16 366 20L341 29L331 24Q239 -17 155 -17H141Q90 -17 61 -12T23 1T14 22Q14 44 39 65T103 95Q126 101 180 101Q224 101 258 98T309 90T330 86Q332 86 353 103T389 135Q401 146 412 158T431 179T450 203T466 225T485 252T505 280L535 322H509Q391 322 340 362T289 452Q289 495 321 547T396 630Q438 665 486 684T572 704ZM978 635Q978 644 977 650T973 661T968 668T961 673T954 676T946 678T938 680Q929 680 925 677Q893 659 795 531T682 377Q683 377 711 385T755 401T801 421T856 453T906 495Q927 516 952 557T978 635ZM274 50Q274 51 258 54T216 61T166 65Q160 65 151 65T140 64Q115 58 102 48T88 31Q88 20 159 20Q191 20 219 27T261 42L274 50',
    0x2113: '345 104T349 104T361 95T369 80T352 59Q268 -20 206 -20Q170 -20 146 3T113 53T99 104L94 129Q94 130 79 116T48 86T28 70Q22 70 15 79T7 94Q7 98 12 103T58 147L91 179V185Q91 186 91 191T92 200Q92 282 128 400T223 612T336 705Q397 705 397 636V627Q397 453 194 233Q185 223 180 218T174 211T171 208T165 201L163 186Q159 142 159 123Q159 17 208 17Q228 17 253 30T293 56T335 94Q345 104 349 104ZM360 634Q360 655 354 661T336 668Q328 668 322 666T302 645T272 592Q252 547 229 467T192 330L179 273Q179 272 186 280T204 300T221 322Q327 453 355 590Q360 612 360 634',
    0x2115: '20 664Q20 666 31 683H142Q256 683 258 681Q259 680 279 653T342 572T422 468L582 259V425Q582 451 582 490T583 541Q583 611 573 628T522 648Q500 648 493 654Q484 665 493 679L500 683H691Q702 676 702 666Q702 657 698 652Q688 648 680 648Q633 648 627 612Q624 601 624 294V-8Q616 -20 607 -20Q601 -20 596 -15Q593 -13 371 270L156 548L153 319Q153 284 153 234T152 167Q152 103 156 78T172 44T213 34Q236 34 242 28Q253 17 242 3L236 -1H36Q24 6 24 16Q24 34 56 34Q58 35 69 36T86 40T100 50T109 72Q111 83 111 345V603L96 619Q72 643 44 648Q20 648 20 664ZM413 419L240 648H120L136 628Q137 626 361 341T587 54L589 68Q589 78 589 121V192L413 419',
    0x2118: '300 74Q300 133 338 133Q350 133 356 126T363 109Q363 88 340 76Q340 71 342 62T358 39T393 26Q435 26 474 67T532 182T551 290Q551 325 535 349T484 373Q430 373 378 348T291 289T228 218T187 157T174 130Q254 30 265 10Q276 -15 276 -41Q276 -101 235 -158T142 -216Q112 -216 90 -195T67 -118Q67 -40 104 64L110 81Q81 118 81 174Q81 268 134 360T247 453Q252 453 255 451T258 447L259 445Q259 432 253 420Q251 416 242 416Q209 411 176 341T142 203Q142 193 143 184T146 170T149 165L158 180Q215 280 303 345T485 410Q548 410 586 368T625 255Q625 157 553 74T389 -10H383Q349 -10 325 14Q302 37 300 74ZM105 -123Q105 -134 106 -141T110 -158T122 -173T145 -178Q155 -178 160 -176Q184 -163 199 -132T214 -73Q214 -69 214 -66T213 -59T212 -53T209 -47T205 -41T199 -33T193 -25T184 -14T174 -1L165 10Q156 22 148 32L139 43Q138 43 130 15T113 -54T105 -123',
    0x2119: '16 666Q16 675 28 683H195Q334 683 370 682T437 672Q511 657 554 611T597 495Q597 343 404 309Q402 308 401 308Q381 303 319 303H261V181Q261 157 262 120Q262 60 267 50T304 36Q310 35 313 35Q352 35 352 17Q352 10 346 3L339 -1H28Q16 5 16 16Q16 35 53 35Q68 36 75 37T87 42T95 52Q98 61 98 341T95 630Q91 640 83 643T53 648Q16 648 16 666ZM235 35Q228 46 227 84Q226 129 226 337V621L230 635L237 648H128Q128 647 133 632Q136 620 136 341Q136 64 133 50L128 35H235ZM301 341H313Q339 341 354 344T389 362T417 410T426 498Q426 586 401 616T322 647Q301 647 293 643Q271 637 264 621Q261 617 261 479V341H301ZM429 350Q431 350 443 353T476 367T515 391T548 432T562 490Q562 550 524 592Q507 607 484 619Q481 621 448 635L433 639L439 621Q462 578 462 506Q462 448 454 413T437 366T428 350H429',
    0x211A: '480 -10Q480 -13 486 -24T507 -50T541 -80T588 -104T648 -114Q666 -114 688 -110T714 -106Q724 -106 728 -114T729 -130Q723 -145 663 -163T548 -181Q503 -181 463 -169T395 -139T343 -97T307 -56T284 -19L280 -3L262 1Q188 24 131 81Q57 155 37 275Q34 292 34 342T37 410Q58 528 131 601Q179 652 248 676T388 701Q485 701 562 661Q698 595 731 448Q742 410 742 341T731 235Q707 141 646 81Q616 50 575 27T493 -5L480 -10ZM568 342Q568 613 437 659L395 666Q329 666 286 626Q232 570 213 439Q210 408 210 342T213 246Q231 113 286 57Q309 37 342 23Q357 19 389 19Q420 19 437 23Q469 38 491 57Q568 134 568 342ZM174 341V354Q174 393 175 419T183 484T205 561T246 635L249 639Q246 639 224 627T193 608Q189 606 183 601T169 589T155 577Q69 491 69 344Q69 133 231 52Q247 42 247 46Q247 46 246 48Q231 69 222 85T200 141T177 239Q174 269 174 341ZM708 341Q708 410 689 467T640 556T588 606T546 630Q532 638 531 638Q530 638 531 635Q563 590 577 543Q602 472 602 341V316Q602 264 599 230T580 144T531 48Q529 44 532 45T546 52Q575 68 596 84T642 128T683 200T706 299Q708 327 708 341ZM391 -17H333Q329 -15 326 -15Q324 -15 324 -17Q324 -21 362 -68Q424 -130 506 -143Q518 -144 544 -144Q569 -144 577 -143L589 -141L575 -139Q544 -127 509 -101T453 -37L442 -19L391 -17',
    0x211B: '224 266Q185 266 156 286T127 354Q127 419 176 487T282 594Q346 642 433 679T615 717Q732 717 802 680L815 673Q824 680 840 690T860 700Q864 700 867 693T872 680L873 673Q873 668 858 659L845 651L853 642Q887 605 887 561Q887 500 840 439Q790 379 681 336Q693 312 693 292Q692 276 689 263T672 229T653 198T620 152L575 87Q557 57 557 33Q557 24 560 17T566 8L569 5Q546 5 508 25T470 76Q470 83 473 92T545 198T616 310Q616 317 615 318T612 319Q603 319 575 315H560L545 291Q492 201 429 135T277 23Q202 -17 142 -17H130Q50 -17 16 17Q-2 35 -2 57Q-2 95 24 126T88 158Q106 158 116 147T127 121Q127 110 122 102Q116 93 99 84T63 75Q58 75 53 76T47 77T45 75T44 67Q45 52 57 42T88 27T120 21T144 19Q174 19 208 36T267 76T324 134T369 189T406 239Q462 319 504 374T616 503T755 631L770 644Q767 647 753 654T697 670T602 680Q493 680 399 631T247 516Q218 485 193 440T168 359Q168 328 188 316T234 303Q255 303 273 315T304 340T343 389T390 448Q428 490 441 510T456 548Q456 557 458 559Q459 560 476 567T496 575Q505 575 505 558Q505 511 434 412Q429 406 427 403Q397 360 378 343Q342 308 300 287T224 266ZM819 564Q819 595 800 619L784 606Q729 557 692 512T605 387L591 365L610 364Q622 364 631 363T641 361Q643 361 651 363Q725 388 772 449T819 564ZM794 141Q794 123 725 63T612 3Q609 3 612 5Q612 5 615 7Q639 19 678 57T742 131L755 148H772H780Q794 148 794 141ZM588 -3Q590 0 593 0H594L593 -1Q592 -1 590 -2L588 -3',
    0x211C: '27 496Q31 569 102 627T234 685Q236 685 241 685T251 686Q287 686 318 672T367 638T399 598T418 564L423 550Q424 554 434 567T463 601T505 639T561 671T626 685Q672 685 688 659T710 572Q713 533 721 523T766 513Q781 513 787 514T794 516Q796 512 798 509T801 504T802 501T787 493Q702 461 624 401L607 389Q655 383 688 358L697 352V342Q699 330 699 297Q704 209 710 173T734 103Q751 69 765 69Q769 69 806 83L824 90V74Q823 73 759 24T693 -26Q692 -26 660 32L628 90L629 111Q631 159 631 177Q631 278 614 300Q584 340 523 340Q500 340 467 333T431 325Q429 325 429 322Q428 321 426 308T420 275T410 230T392 178T366 125L358 112L342 99Q306 70 269 38T213 -10T193 -26Q192 -26 163 0T116 26Q82 26 50 -8L42 -16L35 -8L27 0L35 10Q43 21 58 38T104 80T158 106Q179 106 218 65L235 48Q238 48 255 60T295 99T329 158Q352 231 352 359Q352 555 242 614Q210 628 187 628Q140 628 116 600T91 548Q91 522 138 464T185 382V376Q185 345 158 313T103 263L76 246Q74 244 64 253L54 260L65 267Q91 285 100 302Q111 318 111 337Q111 355 69 410T27 496ZM562 628Q504 628 443 507L435 491L436 479Q437 471 437 446Q437 396 432 351L529 389L602 426Q673 462 673 463H672Q644 470 637 483T622 553Q608 628 562 628',
    0x211D: '17 665Q17 672 28 683H221Q415 681 439 677Q461 673 481 667T516 654T544 639T566 623T584 607T597 592T607 578T614 565T618 554L621 548Q626 530 626 497Q626 447 613 419Q578 348 473 326L455 321Q462 310 473 292T517 226T578 141T637 72T686 35Q705 30 705 16Q705 7 693 -1H510Q503 6 404 159L306 310H268V183Q270 67 271 59Q274 42 291 38Q295 37 319 35Q344 35 353 28Q362 17 353 3L346 -1H28Q16 5 16 16Q16 35 55 35Q96 38 101 52Q106 60 106 341T101 632Q95 645 55 648Q17 648 17 665ZM241 35Q238 42 237 45T235 78T233 163T233 337V621L237 635L244 648H133Q136 641 137 638T139 603T141 517T141 341Q141 131 140 89T134 37Q133 36 133 35H241ZM457 496Q457 540 449 570T425 615T400 634T377 643Q374 643 339 648Q300 648 281 635Q271 628 270 610T268 481V346H284Q327 346 375 352Q421 364 439 392T457 496ZM492 537T492 496T488 427T478 389T469 371T464 361Q464 360 465 360Q469 360 497 370Q593 400 593 495Q593 592 477 630L457 637L461 626Q474 611 488 561Q492 537 492 496ZM464 243Q411 317 410 317Q404 317 401 315Q384 315 370 312H346L526 35H619L606 50Q553 109 464 243',
    0x2124: '39 -1Q29 9 29 12Q29 23 60 77T219 337L410 648H364Q261 648 210 628Q168 612 142 588T109 545T97 509T88 490Q85 489 80 489Q72 489 61 503L70 588Q72 607 75 628T79 662T81 675Q84 677 88 681Q90 683 341 683H592Q604 673 604 666Q604 662 412 348L221 37Q221 35 301 35Q406 35 446 48Q504 68 543 111T597 212Q602 239 617 239Q624 239 629 234T635 223Q635 215 621 113T604 8L597 1Q595 -1 317 -1H39ZM148 637L166 648H112V632Q111 629 110 622T108 612Q108 608 110 608T116 612T129 623T148 637ZM552 646Q552 648 504 648Q452 648 450 643Q448 639 266 343T77 37Q77 35 128 35H179L366 339L552 646ZM572 35Q581 89 581 97L561 77Q542 59 526 48L508 37L539 35H572',
    0x2126: '55 454Q55 503 75 546T127 617T197 665T272 695T337 704H352Q396 704 404 703Q527 687 596 615T666 454Q666 392 635 330T559 200T499 83V80H543Q589 81 600 83T617 93Q622 102 629 135T636 172L637 177H677V175L660 89Q645 3 644 2V0H552H488Q461 0 456 3T451 20Q451 89 499 235T548 455Q548 512 530 555T483 622T424 656T361 668Q332 668 303 658T243 626T193 560T174 456Q174 380 222 233T270 20Q270 7 263 0H77V2Q76 3 61 89L44 175V177H84L85 172Q85 171 88 155T96 119T104 93Q109 86 120 84T178 80H222V83Q206 132 162 199T87 329T55 454',
    0x2127: '126 584Q119 584 110 539T97 493Q95 490 73 490Q44 490 44 501Q44 515 62 590Q75 672 82 679Q84 684 177 684Q193 684 214 684T241 685Q265 685 271 682T277 664V648Q271 572 229 434T186 231Q186 173 203 132T247 70T302 42T360 33Q391 33 419 42T474 72T517 133T533 231Q533 297 491 437T442 648Q442 675 446 679Q448 684 542 684Q635 684 637 681Q640 678 657 594T675 501Q675 490 646 490Q624 490 622 493Q620 493 609 538T593 584Q591 585 585 585T569 586T551 588H513Q514 586 518 573T538 531T582 453Q647 340 660 277Q663 259 663 232Q663 194 657 177Q652 151 629 112T560 39Q495 -5 424 -19Q403 -22 360 -22Q318 -22 297 -19Q239 -8 193 18T120 74T80 131T62 177Q56 194 56 229Q56 281 74 328T137 453Q160 491 174 518T193 555T201 575T206 588H168Q160 587 150 587T134 586T126 584',
    0x2128: '148 590Q95 592 91 627V633L160 729H176Q169 713 169 705Q169 670 244 670Q269 670 305 672T357 675Q405 675 432 661T468 609Q469 605 469 596Q469 572 460 540Q433 463 301 372Q325 378 359 378Q431 378 472 350T519 297Q532 249 532 198Q532 115 500 40T442 -57Q335 -139 202 -139Q165 -139 125 -131L112 -129V-100Q112 -49 106 -33T75 -17Q55 -17 31 -35L22 -42L11 -26L22 -18Q94 36 151 36H160Q171 36 178 33T188 27T194 13T196 -5T197 -32Q198 -79 206 -90Q217 -107 251 -107Q336 -107 389 -33T442 155Q442 240 407 274Q362 319 285 319Q236 319 192 298Q188 298 181 309L224 372Q227 373 234 374T246 376T257 379T271 384T285 391T302 402T321 417Q384 471 384 540Q384 562 366 581T306 600Q292 600 233 595T148 590',
    0x212C: '256 262Q161 262 161 351Q161 408 203 471T289 570Q380 645 475 676T617 707L627 708Q637 708 644 708Q759 708 831 675L844 669L857 677Q892 700 896 700Q902 700 907 685Q907 683 907 681T908 678T909 676T909 673Q909 671 909 670T906 667T903 664T897 660T889 655L878 647L889 636Q928 598 928 548Q928 529 923 510T907 474T886 442T861 412T837 388T815 368T800 355Q847 323 847 270V263Q847 205 806 145Q766 82 695 37T564 -8Q527 -8 506 10T484 58Q484 85 501 117T543 172Q607 226 685 228Q695 228 698 226Q703 220 692 206Q684 194 682 193T665 191Q625 189 595 172T550 133T529 93T522 66Q522 29 576 29Q642 29 705 109Q785 211 785 270Q785 287 779 300T769 316T755 327L740 319Q682 290 634 290Q611 290 592 294H588L565 261Q559 252 544 231T522 201T504 178T481 151T455 123Q394 63 314 18T159 -28Q103 -28 67 -6T31 54Q31 88 57 123T123 158Q144 158 154 146T164 119Q164 102 142 89T100 75Q94 75 87 77T76 80L72 81Q69 78 69 65Q69 35 102 22T175 9Q184 9 198 11Q248 23 300 70T403 187T508 331T636 489T789 629L801 639Q796 642 786 647T732 661T633 670Q592 670 558 665Q481 651 409 613T286 520Q274 507 258 485T222 424T202 354Q202 299 269 299Q282 299 295 301T318 307T339 317T358 329T376 345T391 362T406 380T420 398T433 417T445 435Q496 512 496 547Q496 559 497 560T516 569Q526 574 530 574Q538 574 538 540Q538 414 427 325Q342 262 256 262ZM689 382Q708 382 753 375L765 387Q860 482 860 555Q860 594 839 610L822 592Q794 563 752 511T680 420T651 380Q655 381 660 381Q664 382 689 382ZM697 344Q692 345 681 345H675Q671 345 665 345T655 344T650 344L648 342Q646 339 645 338Q643 333 639 327H653Q670 329 676 330Q706 342 706 343Q702 344 697 344',
    0x212D: '299 585Q333 609 384 634T470 672L505 685Q506 685 513 662T531 613T548 580Q553 576 563 576Q575 576 605 585Q607 585 607 575V564Q537 532 496 527Q475 542 456 567T427 610T415 627Q410 627 398 618T382 603Q373 588 373 558T386 475T400 399Q400 337 366 303Q343 281 309 266T254 247T226 242L214 257Q214 258 223 260T251 272T287 299Q304 316 304 360Q304 396 289 451T274 532Q274 553 277 561V564H269Q205 558 172 501T139 358Q139 207 226 127T443 46Q448 46 457 46T470 47L485 48L601 106Q602 106 602 93V80Q551 48 517 25T474 -4T460 -13T443 -19Q409 -24 367 -24Q360 -24 351 -24T335 -23T326 -22Q190 -2 125 87T59 319V328Q62 412 96 487L101 500L118 512Q189 563 245 591L266 601L299 585',
    0x2130: '280 398L279 400Q278 402 277 405T275 413T272 426T271 443Q271 494 302 544T379 629T472 685T553 707H565H573Q630 707 664 689Q718 661 718 604Q718 548 662 492T553 436Q525 436 508 451T490 492Q490 534 531 579T619 630Q632 630 632 623Q632 619 624 606Q614 593 602 592T578 580Q566 568 549 541T532 497Q532 474 565 474Q577 474 587 476Q600 481 611 489Q630 503 651 535T672 596Q672 660 553 660H548Q494 660 450 616Q421 587 384 531T343 439Q341 420 344 415H345Q346 415 352 415T369 417T391 418Q421 418 440 412T466 398T473 382Q473 367 452 353T398 339Q370 339 348 345T315 359L304 366Q297 365 284 360T234 321T163 234Q120 160 120 117Q120 83 149 57T252 30Q311 30 357 60Q386 79 414 114T452 179Q454 186 454 200Q454 230 415 242Q401 246 373 246Q353 246 347 244Q328 236 313 219T288 184T274 149T265 121T261 109Q260 107 247 102T230 97Q223 97 223 105Q223 148 271 216T386 284Q446 284 483 260T520 195Q520 121 427 57T239 -8Q192 -8 152 2T79 46T46 133Q46 212 107 285T269 394L280 398ZM427 376Q427 377 402 380Q386 380 386 379L425 375L427 376',
    0x2131: '258 428Q258 489 322 562T482 685T661 735Q726 735 824 693T977 651Q990 651 990 644Q990 639 971 612T948 581Q947 580 938 580Q878 580 784 621T617 663Q544 663 480 635T379 568T320 492T299 431Q299 387 362 387Q404 387 438 402T493 438T527 486T546 531T551 563Q551 569 550 575T549 584T549 590Q551 593 563 602T579 611Q584 611 592 605T608 584T616 548Q616 513 595 477T554 423Q518 392 464 372T349 351Q258 351 258 428ZM324 187T305 187T286 196Q286 202 301 217Q327 242 383 262T484 290L527 297L567 356Q624 441 643 467T688 521Q715 550 752 581T795 613T804 603T808 587T778 547T702 444T626 300H637Q663 302 685 306L697 308L703 317Q745 376 792 400Q806 406 818 406Q849 406 849 375Q847 355 831 338T797 312T763 296L747 290Q744 289 735 266T724 241Q722 240 702 232T664 217T645 210Q638 210 638 218Q638 224 653 246T669 270Q669 271 668 271Q663 270 624 264L607 263Q570 199 529 152Q513 133 484 106T409 45T305 -13T193 -36Q109 -36 74 -10T39 50Q39 86 64 121T128 158Q171 158 171 121Q171 97 141 83Q125 75 107 75Q93 75 80 83Q76 71 76 62Q76 29 117 15T207 0Q324 0 494 248L501 258H495Q368 239 330 195Q324 187 305 187ZM775 335Q806 358 806 368Q805 369 804 369Q800 369 791 360Q774 336 775 335',
    0x2132: '457 681Q471 695 477 695Q485 695 497 681V12L484 -1H68Q55 14 55 19T68 39H457V328H215L211 335Q198 346 211 359L217 368H457V681',
    0x2133: '112 -7Q86 -7 58 6T30 48T54 103T113 130Q129 130 141 121T153 94Q153 71 132 59T90 47H80Q95 30 133 30Q180 30 228 63T311 137T402 249T500 361Q566 425 703 529T910 693Q942 721 945 721T958 716T970 709Q974 704 964 691Q961 688 905 622T847 554L595 181Q553 121 527 77T496 19L492 5Q497 5 531 46Q579 98 685 224T850 409L972 524Q994 543 1004 556Q1012 567 1097 643T1186 720Q1194 720 1206 715T1215 703Q1215 701 1191 671T1133 599T1080 530Q1036 461 983 357T862 152Q802 64 799 17Q799 7 800 5T811 2Q836 2 882 37T969 126Q972 130 974 134T978 138T983 139T996 140H1012Q1018 134 1018 132Q1018 122 981 83T889 4T795 -35Q761 -35 745 -12T728 48Q728 122 781 190Q833 269 890 370L927 434L914 422Q848 360 752 245Q643 117 582 51T498 -33T461 -50Q424 -48 424 -4Q424 84 481 172L714 495Q591 406 523 333Q507 316 430 226T313 95Q263 48 221 24T162 -4T120 -7H112',
    0x2135: '55 613Q55 643 61 663T74 688T85 694Q94 694 94 681Q98 632 134 588L412 285Q416 311 430 397T447 509V519L438 526Q407 554 398 571T388 617T394 664T407 688T418 694Q425 694 427 684Q429 675 454 635T488 586Q490 584 496 579T513 563T537 540Q555 516 555 487Q555 460 549 441T537 416T528 409Q519 409 517 415T513 435T503 463Q492 481 490 481Q454 264 454 246Q454 237 479 212T529 152T555 79Q555 32 538 9Q531 1 524 1Q516 1 516 13Q512 62 476 106Q468 115 337 258T195 412L193 406Q191 401 189 394T183 377T176 352T171 322T167 284T165 240Q165 224 166 220Q171 199 211 152T252 70Q252 45 235 29T203 8T175 1Q170 0 115 0H79Q60 0 58 3T55 20Q55 31 58 34Q60 37 76 37Q112 39 126 46T140 70Q140 96 112 148T83 236Q83 281 102 334T140 419T159 452Q55 556 55 613',
    0x2136: '56 706V726Q56 763 76 763Q83 763 87 759T98 741Q108 726 116 721L127 717L340 715Q547 712 564 709Q575 705 587 692Q599 680 605 663L609 650V137H676Q687 124 687 115Q687 110 678 100T622 43L558 -21H-9Q-22 -6 -22 -1T-13 14T42 72L107 137H569V339Q569 541 567 546Q558 555 554 557L545 563H329Q118 566 101 569Q90 573 78 586Q54 610 54 661Q54 670 56 706',
    0x2137: '56 750Q68 764 76 764Q88 764 97 743T125 717Q131 715 240 715T358 713Q421 691 421 640Q421 608 399 588T358 566Q353 566 352 565T351 557L356 526Q356 488 379 346T402 97Q400 21 385 -12Q366 -43 351 -43Q335 -43 329 -10Q316 40 316 64Q316 67 315 67Q313 67 269 26L222 -21H-9Q-22 -7 -22 -1Q-22 4 -14 14T42 73L107 137H311V564H211H164Q115 564 93 573T60 615Q56 630 56 690V750',
    0x2138: '62 757Q69 764 75 764Q87 764 97 741Q102 731 105 728T117 721L129 715H349Q569 715 580 710Q618 701 635 670Q640 661 640 639Q640 609 622 590Q617 583 604 575T580 566H573V553Q575 547 576 531T582 469T600 353Q624 205 624 104Q624 46 617 17T591 -32Q581 -43 573 -43Q550 -43 540 44Q535 73 533 319V564H322Q117 566 100 570Q90 573 77 586Q54 609 54 663Q54 689 55 706Q55 738 56 745T62 757',
    0x2141: '239 665Q194 665 154 653T90 629T66 617Q59 617 53 623T46 637Q46 652 66 659Q129 695 197 701Q218 705 248 705Q293 705 335 693Q371 684 435 644Q543 562 573 417Q577 393 577 341Q577 290 573 266Q531 83 384 10Q346 -9 315 -16T234 -23H206Q202 -23 183 -23T152 -21T120 -18T88 -10T63 3T44 24L37 35V297L50 310H235Q248 297 248 290Q248 285 235 270H77V103Q77 88 77 80T77 63T78 50T80 43T82 38T85 35T89 32T95 30Q126 20 206 17Q289 17 330 30Q407 55 460 120T533 275Q538 305 538 342Q538 486 452 575T239 665',
    0x2190: '944 261T944 250T929 230H165Q167 228 182 216T211 189T244 152T277 96T303 25Q308 7 308 0Q308 -11 288 -11Q281 -11 278 -11T272 -7T267 2T263 21Q245 94 195 151T73 236Q58 242 55 247Q55 254 59 257T73 264Q121 283 158 314T215 375T247 434T264 480L267 497Q269 503 270 505T275 509T288 511Q308 511 308 500Q308 493 303 475Q293 438 278 406T246 352T215 315T185 287T165 270H929Q944 261 944 250',
    0x2191: '27 414Q17 414 17 433Q17 437 17 439T17 444T19 447T20 450T22 452T26 453T30 454T36 456Q80 467 120 494T180 549Q227 607 238 678Q240 694 251 694Q259 694 261 684Q261 677 265 659T284 608T320 549Q340 525 363 507T405 479T440 463T467 455T479 451Q483 447 483 433Q483 413 472 413Q467 413 458 416Q342 448 277 545L270 555V-179Q262 -193 252 -193H250H248Q236 -193 230 -179V555L223 545Q192 499 146 467T70 424T27 414',
    0x2192: '56 237T56 250T70 270H835Q719 357 692 493Q692 494 692 496T691 499Q691 511 708 511H711Q720 511 723 510T729 506T732 497T735 481T743 456Q765 389 816 336T935 261Q944 258 944 250Q944 244 939 241T915 231T877 212Q836 186 806 152T761 85T740 35T732 4Q730 -6 727 -8T711 -11Q691 -11 691 0Q691 7 696 25Q728 151 835 230H70Q56 237 56 250',
    0x2193: '473 86Q483 86 483 67Q483 63 483 61T483 56T481 53T480 50T478 48T474 47T470 46T464 44Q428 35 391 14T316 -55T264 -168Q264 -170 263 -173T262 -180T261 -184Q259 -194 251 -194Q242 -194 238 -176T221 -121T180 -49Q169 -34 155 -21T125 2T95 20T67 33T44 42T27 47L21 49Q17 53 17 67Q17 87 28 87Q33 87 42 84Q158 52 223 -45L230 -55V312Q230 391 230 482T229 591Q229 662 231 676T243 693Q244 694 251 694Q264 692 270 679V-55L277 -45Q307 1 353 33T430 76T473 86',
    0x2194: '263 479Q267 501 271 506T288 511Q308 511 308 500Q308 493 303 475Q293 438 278 406T246 352T215 315T185 287T165 270H835Q729 349 696 475Q691 493 691 500Q691 511 711 511Q720 511 723 510T729 506T732 497T735 481T743 456Q765 389 816 336T935 261Q944 258 944 250Q944 244 939 241T915 231T877 212Q836 186 806 152T761 85T740 35T732 4Q730 -6 727 -8T711 -11Q691 -11 691 0Q691 7 696 25Q728 151 835 230H165Q167 228 182 216T211 189T244 152T277 96T303 25Q308 7 308 0Q308 -11 288 -11Q281 -11 278 -11T272 -7T267 2T263 21Q245 94 195 151T73 236Q58 242 55 247Q55 254 59 257T73 264Q144 292 194 349T263 479',
    0x2195: '27 492Q17 492 17 511Q17 515 17 517T17 522T19 525T20 528T22 530T26 531T30 532T36 534Q80 545 120 572T180 627Q210 664 223 701T238 755T250 772T261 762Q261 757 264 741T282 691T319 628Q352 589 390 566T454 536L479 529Q483 525 483 511Q483 491 472 491Q467 491 458 494Q342 526 277 623L270 633V-133L277 -123Q307 -77 353 -45T430 -2T473 8Q483 8 483 -11Q483 -15 483 -17T483 -22T481 -25T480 -28T478 -30T474 -31T470 -32T464 -34Q407 -49 364 -84T300 -157T270 -223T261 -262Q259 -272 250 -272Q242 -272 239 -255T223 -201T180 -127Q169 -112 155 -99T125 -76T95 -58T67 -45T44 -36T27 -31L21 -29Q17 -25 17 -11Q17 9 28 9Q33 9 42 6Q158 -26 223 -123L230 -133V633L223 623Q192 577 146 545T70 502T27 492',
    0x2196: '204 662Q257 662 301 676T369 705T394 720Q398 720 407 711T417 697Q417 688 389 671T310 639T212 623Q176 623 153 628Q151 628 221 557T546 232Q942 -164 943 -168Q944 -170 944 -174Q944 -182 938 -188T924 -195Q922 -195 916 -193Q912 -191 517 204Q440 281 326 394T166 553L121 598Q126 589 126 541Q126 438 70 349Q59 332 52 332Q48 332 39 341T29 355Q29 358 38 372T57 407T77 464T86 545Q86 583 78 614T63 663T55 683Q55 693 65 693Q73 693 82 688Q136 662 204 662',
    0x2197: '582 697Q582 701 591 710T605 720Q607 720 630 706T697 677T795 662Q830 662 863 670T914 686T934 694Q942 694 944 685Q944 680 936 663T921 615T913 545Q913 490 927 446T956 379T970 355Q970 351 961 342T947 332Q940 332 929 349Q874 436 874 541Q874 590 878 598L832 553Q787 508 673 395T482 204Q87 -191 83 -193Q77 -195 75 -195Q67 -195 61 -189T55 -174Q55 -170 56 -168Q58 -164 453 232Q707 487 777 557T847 628Q824 623 787 623Q689 623 599 679Q582 690 582 697',
    0x2198: '55 675Q55 683 60 689T75 695Q77 695 83 693Q87 691 482 296Q532 246 605 174T717 62T799 -20T859 -80T878 -97Q874 -93 874 -41Q874 64 929 151Q940 168 947 168Q951 168 960 159T970 145Q970 143 956 121T928 54T913 -45Q913 -83 920 -114T936 -163T944 -185Q942 -194 934 -194Q932 -194 914 -186T864 -170T795 -162Q743 -162 698 -176T630 -205T605 -220Q601 -220 592 -211T582 -197Q582 -187 611 -170T691 -138T787 -123Q824 -123 847 -128Q848 -128 778 -57T453 268Q58 664 56 668Q55 670 55 675',
    0x2199: '126 -41Q126 -92 121 -97Q121 -98 139 -80T200 -20T281 61T394 173T517 296Q909 690 916 693Q922 695 924 695Q932 695 938 689T944 674Q944 670 943 668Q942 664 546 268Q292 13 222 -57T153 -128Q176 -123 212 -123Q310 -123 400 -179Q417 -190 417 -197Q417 -201 408 -210T394 -220Q392 -220 369 -206T302 -177T204 -162Q131 -162 67 -194Q63 -195 59 -192T55 -183Q55 -180 62 -163T78 -115T86 -45Q86 10 72 54T44 120T29 145Q29 149 38 158T52 168Q59 168 70 151Q126 62 126 -41',
    0x219A: '942 250Q942 244 928 230H511L457 148Q440 124 420 93Q404 68 400 64T389 60Q381 60 375 66T368 81Q368 88 415 159L462 230H175L188 214Q210 188 235 145T264 85Q264 75 260 74T231 72L206 74L191 103Q169 142 164 150Q130 195 64 239Q56 244 56 250T64 261Q115 294 142 323T191 397L206 428H231Q255 428 259 426T264 414Q260 397 235 355T188 288L175 272L331 270Q488 270 491 272Q491 275 542 352T597 432Q602 437 609 437Q617 437 622 432T628 417T582 341L537 272L735 270H931Q942 257 942 250',
    0x219B: '54 250Q54 258 66 270H277L488 272L542 350Q596 431 602 435Q604 437 609 437Q617 437 622 432T628 417T582 341L537 272L608 270H751L822 272L808 288Q786 313 761 355T733 414Q733 424 737 426T766 428H793L806 397Q829 354 864 314Q896 284 928 263Q942 257 942 250T928 237Q887 208 864 185Q829 147 806 103L793 74L766 72Q742 72 738 73T733 85Q735 102 756 137T797 198L817 225L822 230H511L457 148Q440 124 420 93Q404 68 400 64T389 60Q381 60 375 66T368 81Q368 88 415 159L462 230H264L66 232Q54 239 54 250',
    0x219E: '56 250Q103 277 142 322T199 417H221Q244 417 244 416Q244 414 237 397T208 344T158 278L151 270H276L285 277Q322 306 349 345T388 417H434Q434 413 424 392T393 338T349 279L340 270H634Q933 270 937 266L938 265Q944 259 944 250T938 235L937 234Q933 230 634 230H340L349 221Q372 196 393 163T424 108T434 83H388Q377 116 350 155T285 223L276 230H151L158 222Q186 191 207 156T236 104T244 84Q244 83 221 83H199Q181 133 142 178T56 250',
    0x21A0: '943 250Q895 221 856 177T801 83H778Q755 83 755 84Q755 86 762 103T791 156T841 222L848 230H723L714 223Q677 194 650 155T611 83H565Q565 87 575 108T606 162T650 221L659 230H365Q66 230 62 234L61 235Q55 241 55 250T61 265L62 266Q66 270 365 270H659L650 279Q627 304 606 337T575 392T565 417H611Q622 384 649 345T714 277L723 270H848L841 278Q813 309 792 344T763 396T755 416Q755 417 778 417H801Q817 367 856 323T943 250',
    0x21A2: '56 250Q103 277 142 322T199 417H221Q244 417 244 416Q244 414 237 397T208 344T158 278L151 270H873L882 277Q919 306 946 345T985 417H1031Q1031 413 1021 392T990 338T946 279L937 270V230L946 221Q969 196 990 163T1021 108T1031 83H985Q974 116 947 155T882 223L873 230H151L158 222Q186 191 207 156T236 104T244 84Q244 83 221 83H199Q181 133 142 178T56 250',
    0x21A3: '1054 250Q1006 221 967 177T912 83H889Q866 83 866 84Q866 86 873 103T902 156T952 222L959 230H237L228 223Q191 194 164 155T125 83H79Q79 87 89 108T120 162T164 221L173 230V270L164 279Q141 304 120 337T89 392T79 417H125Q136 384 163 345T228 277L237 270H959L952 278Q924 309 903 344T874 396T866 416Q866 417 889 417H912Q928 367 967 323T1054 250',
    0x21A6: '95 155V109Q95 83 92 73T75 63Q61 63 58 74T54 130Q54 140 54 180T55 250Q55 421 57 425Q61 437 75 437Q88 437 91 428T95 393V345V270H835Q719 357 692 493Q692 494 692 496T691 499Q691 511 708 511H711Q720 511 723 510T729 506T732 497T735 481T743 456Q765 389 816 336T935 261Q944 258 944 250Q944 244 939 241T915 231T877 212Q836 186 806 152T761 85T740 35T732 4Q730 -6 727 -8T711 -11Q691 -11 691 0Q691 7 696 25Q728 151 835 230H95V155',
    0x21A9: '903 424T903 444T929 464Q976 464 1023 434T1070 347Q1070 316 1055 292T1016 256T971 237T929 230H165Q167 228 182 216T211 189T244 152T277 96T303 25Q308 7 308 0Q308 -11 288 -11Q281 -11 278 -11T272 -7T267 2T263 21Q245 94 195 151T73 236Q58 242 55 247Q55 254 59 257T73 264Q121 283 158 314T215 375T247 434T264 480L267 497Q269 503 270 505T275 509T288 511Q308 511 308 500Q308 493 303 475Q293 438 278 406T246 352T215 315T185 287T165 270H926Q929 270 941 271T960 275T978 280T998 290T1015 307Q1030 325 1030 347Q1030 355 1027 364T1014 387T983 411T929 424H928Q903 424 903 444',
    0x21AA: '55 347Q55 380 72 404T113 441T159 458T197 464Q222 464 222 444Q222 429 204 426T157 417T110 387Q95 369 95 347Q95 339 98 330T111 307T142 283T196 270H961Q845 357 818 493Q818 494 818 496T817 499Q817 511 834 511H837Q846 511 849 510T855 506T858 497T861 481T869 456Q891 389 942 336T1061 261Q1070 258 1070 250Q1070 244 1065 241T1041 231T1003 212Q962 186 932 152T887 85T866 35T858 4Q856 -6 853 -8T837 -11Q817 -11 817 0Q817 7 822 25Q854 151 961 230H196Q149 230 102 260T55 347',
    0x21AB: '56 250Q103 277 142 322T199 417H221Q244 417 244 416Q244 414 237 397T208 344T158 278L151 270H622V305Q622 356 624 388T635 460T661 521T709 559T785 575Q813 575 833 573T880 561T923 534T952 483T964 405Q964 374 959 350T942 307T918 276T884 255T847 242T804 235T760 231T713 230H662V-27Q654 -41 644 -41H642H640Q628 -41 622 -27V230H151L158 222Q186 191 207 156T236 104T244 84Q244 83 221 83H199Q181 133 142 178T56 250ZM924 403Q924 474 894 505T794 536Q758 536 734 526T696 500T675 453T665 395T662 319V270H699Q826 270 875 295T924 403',
    0x21AC: '35 405Q35 454 48 489T86 542T137 567T195 575Q229 575 251 571T301 554T345 510T370 429Q377 384 377 305V270H848L841 278Q813 309 792 344T763 396T755 416Q755 417 778 417H801Q817 367 856 323T943 250Q896 221 857 177T801 83H778Q755 83 755 84Q755 86 762 103T791 156T841 222L848 230H377V-27Q369 -41 359 -41H357Q342 -41 337 -25V230H286Q247 231 225 232T169 238T115 255T75 284T45 333T35 405ZM75 406Q75 322 123 296T300 270H337V319Q335 432 317 477T240 534Q232 535 197 535Q140 535 108 507T75 406',
    0x21AD: '57 250Q159 311 200 417H246L242 407Q215 340 159 278L152 270H276L315 310Q354 349 358 351Q366 356 376 351Q378 350 455 273L530 196L606 273Q683 350 686 351Q694 354 703 351Q705 350 782 273L858 196L933 273Q1010 350 1012 351Q1022 356 1030 351Q1034 349 1073 310L1112 270H1236L1229 278Q1173 340 1146 407L1142 417H1188Q1233 306 1331 250Q1231 192 1188 83H1142L1146 93Q1173 160 1229 222L1236 230H1168Q1155 230 1139 230T1119 229Q1112 229 1108 229T1099 231T1092 233T1085 238T1078 245T1068 256T1056 269L1021 304L984 267Q948 230 910 191T867 149Q857 144 848 150Q844 151 770 227T694 304T618 228T540 150Q531 144 521 149Q517 152 479 191T404 267L367 304L332 269Q328 264 320 256T310 246T303 239T296 234T289 231T280 229T269 229Q265 229 249 229T220 230H152L159 222Q215 160 242 93L246 83H223L200 84L195 96Q152 190 57 250',
    0x21AE: '491 272Q491 275 542 352T597 432Q602 437 609 437Q617 437 622 432T628 417T582 341L537 272L608 270H751L822 272L808 288Q786 313 761 355T733 414Q733 424 737 426T766 428H793L806 397Q829 354 864 314Q896 284 928 263Q942 257 942 250T928 237Q887 208 864 185Q829 147 806 103L793 74L766 72Q742 72 738 73T733 85Q735 102 756 137T797 198L817 225L822 230H511L457 148Q440 124 420 93Q404 68 400 64T389 60Q381 60 375 66T368 81Q368 88 415 159L462 230H175L188 214Q210 188 235 145T264 85Q264 75 260 74T231 72L206 74L191 103Q169 142 164 150Q130 195 64 239Q56 244 56 250T64 261Q115 294 142 323T191 397L206 428H231Q255 428 259 426T264 414Q260 397 235 355T188 288L175 272L331 270Q488 270 491 272',
    0x21B0: '56 555Q74 567 79 570T107 592T141 625T170 667T198 722H221Q244 722 244 721Q244 718 236 699T207 647T161 587L151 576L291 575H292H293H294H296H297H298H299H300H301H302H304H305H306H307H308H309H310H311H312H314H315H316H317H318H319H320H321H322H323H324H325H327H328H329H330H331H332H333H334H335H336H337H338H339H340H341H342H343H345Q435 574 438 570L439 569L440 568Q444 564 444 287Q444 15 442 12Q436 0 424 0T406 12Q404 15 404 275V535H151L162 523Q187 495 207 462T236 410T244 389H198L193 402Q171 457 131 497T56 555',
    0x21B1: '301 722Q339 618 443 555L437 551Q431 547 422 541T401 526T377 504T352 477T327 443T306 402L301 389H255Q255 392 263 410T291 461T337 523L348 535H95V275Q95 15 93 12Q87 0 75 0T57 12Q55 15 55 287Q55 564 59 568L60 569Q64 573 76 573T208 575L348 576L338 587Q314 613 294 646T264 698T255 721Q255 722 278 722H301',
    0x21B6: '361 210Q373 210 373 182V177Q373 155 370 151T348 139Q303 118 267 84T216 28T201 1Q197 -1 196 -1Q189 -1 184 8Q166 39 143 64T99 104T61 129T32 144T19 150Q17 152 17 179Q17 203 21 208Q28 210 39 206Q106 178 157 135L175 119V126Q179 130 179 155Q182 173 193 201Q228 305 312 374T510 459Q532 461 551 461H567Q678 461 784 386Q835 344 861 301Q902 245 926 173T950 32Q950 15 944 8Q930 -6 917 8Q910 12 910 43Q901 208 801 314T561 421Q453 421 359 359Q300 319 263 258T217 126L216 125Q216 124 216 123T217 122Q219 122 229 131T260 156T301 181Q314 189 336 199T361 210',
    0x21B7: '972 209Q980 209 981 204T982 179Q982 155 979 151T957 139Q915 121 878 86T815 8Q808 -1 803 -1Q801 -1 797 1Q797 6 783 28T732 84T650 139L628 150Q626 152 626 177Q626 201 630 206Q636 210 637 210Q650 210 697 181Q727 166 764 137L784 119L782 132Q767 239 689 318T499 417Q474 421 442 421Q343 421 261 369T130 219Q86 121 86 28Q86 15 79 8Q73 1 66 1T53 8Q46 15 46 30Q46 102 77 192T186 361Q274 443 386 459Q396 460 426 460Q515 460 588 431T703 361T773 271T812 187T822 132Q822 123 825 123Q936 209 972 209',
    0x21BA: '369 543T369 563T397 583Q408 583 440 579L454 577L464 581Q492 592 516 609T552 638T565 650Q604 638 607 637Q606 636 598 628T585 614T570 601T548 584T523 568L510 560L516 558Q522 555 527 553T541 546T559 536T580 523T603 506T626 485Q722 384 722 250Q722 106 622 12T387 -83Q253 -83 155 12T56 250Q56 357 110 433T235 545Q244 550 252 550Q270 550 270 531Q270 522 261 515T238 501T202 477T159 433Q95 352 95 250Q95 131 178 45T388 -42Q511 -42 596 43T682 250Q682 340 636 408T522 511Q495 526 488 526Q488 525 488 525T487 522T485 515L490 506Q505 481 516 451T531 404T535 384L532 385Q529 386 524 387T513 390L491 397L488 408Q472 483 413 542L399 543Q369 543 369 563',
    0x21BB: '170 637L213 650Q270 597 313 581L323 577L337 579Q369 583 380 583Q408 583 408 563T380 543H378L364 542Q305 483 289 408L286 397L264 390Q259 389 254 388T245 385L242 384Q242 387 246 403T261 450T287 506L292 515Q291 519 291 521T290 524T289 526Q284 526 265 517T216 486T160 434T114 354T95 249Q95 132 178 45T388 -42Q513 -42 597 44T682 250Q682 337 638 404T532 506Q529 508 525 510T519 514T515 516T511 519T509 522T508 526T507 531Q507 550 525 550Q533 550 542 545Q569 532 596 511T653 454T702 366T721 250Q721 151 672 74T547 -43T388 -83Q254 -83 155 12T56 250Q56 385 151 485Q164 498 179 509T205 528T228 542T247 551T260 558L267 560L254 568Q215 590 170 637',
    0x21BC: '62 230Q56 236 55 244Q55 252 57 255T69 265Q114 292 151 326T208 391T243 448T265 491T273 509Q276 511 288 511Q304 511 306 505Q309 501 303 484Q293 456 279 430T251 383T223 344T196 313T173 291T156 276L148 270H929Q944 261 944 250T929 230H62',
    0x21BD: '55 256Q56 264 62 270H929Q944 261 944 250T929 230H148Q149 229 165 215T196 185T231 145T270 87T303 16Q309 -1 306 -5Q304 -11 288 -11Q279 -11 276 -10T269 -4T264 10T253 36T231 75Q172 173 69 235Q59 242 57 245T55 256',
    0x21BE: '188 258V694H208L215 682Q246 628 293 594T375 551V528Q375 505 374 505Q369 505 351 510T299 534T237 578L228 587V205Q228 -178 226 -182Q221 -194 208 -194T190 -182Q188 -178 188 258',
    0x21BF: '41 551Q76 559 123 592T201 682L208 694H228V258Q228 -178 226 -182Q221 -194 208 -194T190 -182Q188 -178 188 205V587L179 578Q151 552 117 534T65 511T42 505Q41 505 41 528V551',
    0x21C0: '691 500Q691 511 711 511Q720 511 723 510T730 504T735 490T746 464T768 425Q796 378 835 339T897 285T933 263Q941 258 942 256T944 245T937 230H70Q56 237 56 250T70 270H852Q802 308 762 364T707 455T691 500',
    0x21C1: '56 237T56 250T70 270H937Q944 263 944 256Q944 251 944 250T943 246T940 242T933 238Q794 153 734 7Q729 -7 726 -9T711 -11Q695 -11 693 -5Q690 -1 696 16Q721 84 763 139T852 230H70Q56 237 56 250',
    0x21C2: '190 682Q195 694 208 694T226 683Q228 679 228 296V-87L237 -78Q265 -52 299 -34T351 -11T374 -5Q375 -5 375 -28V-51Q340 -60 293 -92T215 -182L208 -194H188V242Q188 678 190 682',
    0x21C3: '188 295V573Q188 657 189 672T200 692Q206 694 208 694Q221 694 226 683Q228 679 228 242V-194H208L201 -182Q170 -128 123 -94T41 -51V-28Q41 -5 42 -5Q47 -5 65 -10T117 -34T179 -78L188 -87V295',
    0x21C4: '943 500Q895 471 856 427T801 333H778Q755 333 755 334Q755 336 762 353T791 406T841 472L848 480H459Q70 480 67 482Q55 488 55 500T67 518Q70 520 459 520H848L841 528Q813 559 792 594T763 646T755 666Q755 667 778 667H801Q817 617 856 573T943 500ZM56 167Q102 194 141 238T198 333H221Q244 333 244 332Q221 265 161 198L151 187H539Q928 187 930 186Q944 182 944 167Q944 155 934 149Q930 147 541 147H151L160 137Q185 110 205 77T235 24T244 1Q244 0 221 0H199Q158 106 56 167',
    0x21C6: '56 500Q103 527 142 572T199 667H221Q244 667 244 666Q244 664 237 647T208 594T158 528L151 520H539Q928 520 932 518Q944 513 944 500T932 482Q928 480 539 480H151L158 472Q186 441 207 406T236 354T244 334Q244 333 221 333H199Q181 383 142 428T56 500ZM943 167Q835 101 801 0H778Q755 0 755 1T758 9T765 25T771 39Q800 94 839 137L848 147H458Q68 147 66 149Q55 154 55 167Q55 182 69 186Q71 187 460 187H848L838 198Q811 228 791 261T762 314L755 332Q755 333 778 333H801Q841 227 943 167',
    0x21C7: '930 437Q944 426 944 416T934 399Q930 397 540 397H150L159 387Q185 360 205 328T234 277T243 252Q243 237 217 191T159 113L150 103H540Q930 103 934 101Q944 94 944 84Q944 71 930 64L540 63H151Q180 34 203 -2T236 -61L244 -83H198Q178 -31 142 11T66 77L55 83L65 89Q157 145 197 246Q199 250 190 269Q150 359 65 411L55 417L66 423Q106 447 142 489T198 583H244Q202 488 151 437H930',
    0x21C8: '83 551Q190 590 250 694Q251 689 263 671T307 621T380 567Q409 551 416 551Q422 551 447 563T511 608T577 684L582 694Q642 591 749 551V528Q749 505 748 505Q745 505 724 515T669 546T612 590L602 599V-181Q595 -193 585 -193H582H581Q568 -193 565 -183L563 -179L562 209V598L552 589Q517 556 473 531T414 506H412Q411 506 393 514T361 530T324 553T280 589L270 598V-179Q255 -192 250 -193H247Q237 -193 230 -181V599L220 590Q197 567 164 546T110 515T84 505Q83 505 83 528V551',
    0x21C9: '55 416Q55 427 70 437H848Q819 466 796 502T764 561L755 583H801Q821 531 857 489T933 423L944 417L934 411Q843 355 802 254Q800 250 809 231Q849 141 934 89L944 83L933 77Q893 53 857 11T801 -83H755Q797 12 848 63H459L70 64Q55 70 55 84Q55 94 65 101Q69 103 459 103H849L840 113Q806 148 779 196T756 254Q756 255 760 264T770 286T786 315T809 351T840 387L849 397H459Q69 397 65 399Q55 406 55 416',
    0x21CA: '230 681Q240 694 251 694Q260 693 270 680V-98L280 -89Q297 -73 314 -60T348 -38T374 -24T397 -13T412 -6H414Q428 -6 473 -32T552 -89L562 -98V291L563 680Q570 693 582 693Q593 694 602 681V-99L612 -90Q635 -68 668 -47T723 -15T748 -5Q749 -5 749 -28V-51Q642 -91 582 -194L577 -184Q551 -141 512 -108T447 -63T416 -51T385 -63T321 -108T255 -184L250 -194Q189 -89 83 -51V-28Q83 -5 84 -5Q88 -5 109 -15T164 -46T220 -90L230 -99V681',
    0x21CB: '195 504L198 514H221Q244 514 244 512Q244 508 239 490T215 437T171 376L162 367H545Q928 367 932 365Q944 360 944 347T932 329Q928 327 492 327H55V347L67 354Q113 379 146 420T195 504ZM67 171Q70 173 507 173H944V153L932 146Q839 95 804 -4L801 -14H778Q755 -14 755 -12Q768 59 828 124L837 133H454Q71 133 67 135Q55 140 55 153Q55 165 67 171',
    0x21CC: '691 660Q691 671 711 671Q720 671 723 670T730 664T735 650T746 624T768 585Q797 538 836 499T897 445T933 423Q941 418 942 416T944 405T937 390H70Q56 397 56 410T70 430H852Q802 468 762 524T707 615T691 660ZM55 256Q56 264 62 270H929Q944 261 944 250T929 230H148Q149 229 165 215T196 185T231 145T270 87T303 16Q309 -1 306 -5Q304 -11 288 -11Q279 -11 276 -10T269 -4T264 10T253 36T231 75Q172 173 69 235Q59 242 57 245T55 256',
    0x21CD: '397 525Q410 525 414 524T418 516Q418 506 394 467T331 381L319 367H473L624 369L657 445Q674 487 684 507T699 531T709 534Q717 534 722 528T728 516Q728 510 695 434Q689 418 683 402T672 377T668 367H928Q942 355 942 347Q942 341 928 327H791Q651 327 651 325Q649 324 620 251T586 174Q586 172 757 172H928Q942 158 942 152Q942 143 928 132H568L537 54Q510 -9 503 -22T486 -35Q479 -35 473 -29T466 -17T495 61L526 132H319L331 118Q364 81 391 37T418 -17Q418 -23 415 -24T401 -26Q398 -26 397 -26L384 -24L377 -13Q344 49 301 97T218 170T143 210T84 233T55 245Q54 253 59 256T86 267Q281 327 377 512L384 525H397ZM606 325Q606 327 439 327H275Q258 312 179 265L148 249Q228 206 262 181L275 172H544L575 247L606 325',
    0x21CE: '395 -24T395 -19T417 57T440 132H255L266 116Q308 64 340 -6Q342 -17 337 -21Q335 -26 320 -26T302 -19Q302 -15 294 4T265 54T217 117T145 182T49 236Q30 243 33 254Q40 261 49 263Q98 283 142 315T214 379T263 442T293 493T302 519Q305 525 320 525T337 521Q342 516 340 505Q308 435 266 383L255 370L384 367H515Q561 522 569 530Q574 534 580 534Q587 534 594 528T602 516Q602 512 580 441T557 367H651L742 370L731 383Q689 435 657 505Q655 516 660 521Q662 525 677 525T695 519Q695 515 703 496T732 446T780 383T853 317T949 263Q967 258 964 245Q959 240 949 236Q897 215 852 182T779 116T731 52T703 3T695 -19Q692 -26 677 -26T660 -21Q655 -17 657 -6Q670 21 682 42T702 77T717 99T728 114T735 122T739 126T740 130T613 132H482L460 54Q440 -9 433 -23T415 -37Q408 -37 402 -31ZM502 325Q502 327 360 327H217L195 310Q173 291 120 256L111 250Q114 248 143 229T195 190L217 172H335L453 174L502 325ZM886 250Q885 251 865 263T831 286T802 310L780 327H544L535 299Q531 283 511 223L495 174L637 172H780L802 190Q843 225 877 243L886 250',
    0x21CF: '346 174Q348 176 378 249T411 325Q411 327 239 327H68Q55 342 55 347Q55 354 68 367H428L459 445Q487 509 494 521T510 534Q517 534 524 527T531 516Q531 515 502 438L471 367H677L666 381Q631 421 605 463T578 516Q578 522 582 523T599 525H615L619 512Q659 437 714 383T812 309T896 272T942 254Q943 246 938 243T911 232Q718 172 619 -13L615 -24L599 -26Q578 -26 578 -17Q578 -11 587 6T617 53T666 118L677 132H373L339 54Q323 12 313 -8T298 -32T288 -35Q280 -35 275 -29T269 -17Q269 -14 298 57T328 132H68Q55 145 55 152Q55 156 56 158T62 165T68 172H206Q346 172 346 174ZM848 249Q763 297 735 318L722 327H455L422 252L391 174Q391 172 557 172H722L735 181Q773 210 819 234L848 249',
    0x21D0: '944 153Q944 140 929 133H318L328 123Q379 69 414 0Q419 -13 419 -17Q419 -24 399 -24Q388 -24 385 -23T377 -12Q332 77 253 144T72 237Q62 240 59 242T56 250T59 257T70 262T89 268T119 278T160 296Q303 366 377 512Q382 522 385 523T401 525Q419 524 419 515Q419 510 414 500Q379 431 328 377L318 367H929Q944 359 944 347Q944 336 930 328L602 327H274L264 319Q225 289 147 250Q148 249 165 241T210 217T264 181L274 173H930Q931 172 933 171T936 169T938 167T941 164T942 162T943 158T944 153',
    0x21D1: '228 -179Q227 -180 226 -182T223 -186T221 -189T218 -192T214 -193T208 -194Q196 -194 189 -181L188 125V430L176 419Q122 369 59 338Q46 330 40 330Q38 330 31 337V350Q31 362 33 365T46 374Q60 381 77 390T128 426T190 484T247 567T292 677Q295 688 298 692Q302 694 305 694Q313 694 318 677Q334 619 363 568T420 485T481 427T532 391T564 374Q575 368 577 365T579 350V337Q572 330 570 330Q564 330 551 338Q487 370 435 419L423 430L422 125V-181Q409 -194 401 -194Q397 -194 394 -193T388 -189T385 -184T382 -180V-177V475L373 487Q331 541 305 602Q304 601 300 591T290 571T278 548T260 519T238 488L229 476L228 148V-179',
    0x21D2: '580 514Q580 525 596 525Q601 525 604 525T609 525T613 524T615 523T617 520T619 517T622 512Q659 438 720 381T831 300T927 263Q944 258 944 250T935 239T898 228T840 204Q696 134 622 -12Q618 -21 615 -22T600 -24Q580 -24 580 -17Q580 -13 585 0Q620 69 671 123L681 133H70Q56 140 56 153Q56 168 72 173H725L735 181Q774 211 852 250Q851 251 834 259T789 283T735 319L725 327H72Q56 332 56 347Q56 360 70 367H681L671 377Q638 412 609 458T580 514',
    0x21D3: '401 694Q412 694 422 681V375L423 70L435 81Q487 130 551 162Q564 170 570 170Q572 170 579 163V150Q579 138 577 135T564 126Q541 114 518 99T453 48T374 -46T318 -177Q313 -194 305 -194T293 -178T272 -119T225 -31Q158 70 46 126Q35 132 33 135T31 150V163Q38 170 40 170Q46 170 59 162Q122 131 176 81L188 70V375L189 681Q199 694 208 694Q219 694 228 680V352L229 25L238 12Q279 -42 305 -102Q344 -23 373 13L382 25V678Q387 692 401 694',
    0x21D4: '308 524Q318 526 323 526Q340 526 340 514Q340 507 336 499Q326 476 314 454T292 417T274 391T260 374L255 368Q255 367 500 367Q744 367 744 368L739 374Q734 379 726 390T707 416T685 453T663 499Q658 511 658 515Q658 525 680 525Q687 524 690 523T695 519T701 507Q766 359 902 287Q921 276 939 269T961 259T966 250Q966 246 965 244T960 240T949 236T930 228T902 213Q763 137 701 -7Q697 -16 695 -19T690 -23T680 -25Q658 -25 658 -15Q658 -11 663 1Q673 24 685 46T707 83T725 109T739 126L744 132Q744 133 500 133Q255 133 255 132L260 126Q265 121 273 110T292 84T314 47T336 1Q341 -11 341 -15Q341 -25 319 -25Q312 -24 309 -23T304 -19T298 -7Q233 141 97 213Q83 221 70 227T51 235T41 239T35 243T34 250T35 256T40 261T51 265T70 273T97 287Q235 363 299 509Q305 522 308 524ZM792 319L783 327H216Q183 294 120 256L110 250L120 244Q173 212 207 181L216 173H783L792 181Q826 212 879 244L889 250L879 256Q826 288 792 319',
    0x21D5: '290 755Q298 772 305 772T318 757T343 706T393 633Q431 588 473 558T545 515T579 497V484Q579 464 570 464Q564 464 550 470Q485 497 423 550L422 400V100L423 -50Q485 3 550 30Q565 36 570 36Q579 36 579 16V3Q575 -1 549 -12T480 -53T393 -132Q361 -172 342 -208T318 -258T305 -272T293 -258T268 -208T217 -132Q170 -80 128 -51T61 -12T31 3V16Q31 36 40 36Q46 36 61 30Q86 19 109 6T146 -18T173 -38T188 -50V550Q186 549 173 539T147 519T110 495T61 470Q46 464 40 464Q31 464 31 484V497Q34 500 63 513T135 557T217 633Q267 692 290 755ZM374 598Q363 610 351 625T332 651T316 676T305 695L294 676Q282 657 267 636T236 598L228 589V-89L236 -98Q247 -110 259 -125T278 -151T294 -176T305 -195L316 -176Q328 -157 343 -136T374 -98L382 -89V589L374 598',
    0x21DA: '944 54Q942 44 929 36H372Q372 34 377 26T395 -4T422 -58Q442 -109 442 -110T408 -111H374L370 -100Q282 124 87 243L76 250L87 257Q284 377 370 600L374 611H408Q442 611 442 610Q423 550 381 480Q380 478 379 475T376 471T374 468T372 465V464H929Q942 456 944 446Q944 442 943 439T941 434T938 430T935 428T931 426T928 424H344L336 414Q277 336 200 277L191 270H560Q929 270 933 268Q944 262 944 250Q944 237 933 232Q929 230 560 230H191L200 223Q279 162 336 86L344 76H928Q929 76 931 75T934 73T938 70T941 66T943 61T944 54',
    0x21DB: '56 250Q56 260 68 270H808L799 277Q720 338 663 414L655 424H363Q71 424 68 426Q55 432 55 444T68 462Q71 464 349 464H627Q627 466 622 474T604 504T577 558Q557 609 557 610T591 611H626L629 600Q717 376 912 257L923 250L912 243Q715 123 629 -100L626 -111H591Q557 -111 557 -110Q576 -50 618 20Q619 22 620 25T623 29T625 32T626 35L627 36H349Q71 36 68 38Q55 44 55 56T68 74Q71 76 363 76H655L663 86Q722 164 799 223L808 230H438L68 231Q56 236 56 250',
    0x21DD: '76 230Q68 230 62 237T56 250Q56 257 63 264T91 291Q102 300 108 306L159 351Q168 356 177 351L218 316L303 239L353 195Q376 214 403 239L488 316L529 351Q538 356 546 351Q548 350 594 310L638 270H848L841 278Q813 309 792 344T763 396T755 416Q755 417 778 417H801Q817 367 856 323T943 250Q895 221 856 177T801 83H778Q755 83 755 84Q755 86 762 103T791 156T841 222L848 230H737Q625 230 622 232Q620 233 599 251T558 288L537 306Q537 305 451 228T362 149Q353 146 345 149Q341 150 255 227T169 306Q167 306 129 270Q123 265 115 257T102 245T93 237T84 232T76 230',
    0x21E0: '292 419Q292 400 261 347T211 275H306H364Q400 275 411 271T422 250T411 230T366 225H306H211Q214 222 232 197T271 136T292 82Q292 71 285 68T262 64H250H241Q221 64 216 67T205 83Q186 127 153 167T78 230Q64 238 64 250Q64 258 69 263T82 272T106 288T139 318Q162 342 177 365T198 402T209 425T223 436Q224 437 252 437H258Q292 437 292 419ZM501 237T501 250T515 270H819Q834 262 834 250T819 230H515Q501 237 501 250ZM918 237T918 250T932 270H1236Q1251 262 1251 250T1236 230H932Q918 237 918 250',
    0x21E2: '84 237T84 250T98 270H402Q417 262 417 250T402 230H98Q84 237 84 250ZM501 237T501 250T515 270H819Q834 262 834 250T819 230H515Q501 237 501 250ZM1022 417Q1022 437 1055 437H1067Q1090 437 1097 434T1109 417Q1128 373 1161 333T1236 270Q1251 261 1251 250Q1251 241 1244 236T1216 217T1175 182Q1149 155 1133 128T1109 85T1097 66Q1093 64 1065 64H1053Q1031 64 1025 72T1027 100Q1036 124 1049 147T1073 185T1091 210T1101 223L1103 225H1008H950Q914 225 903 229T892 250T903 270T948 275H1008H1103L1101 277Q1100 280 1091 291T1067 325T1039 374Q1022 408 1022 417',
    0x2200: '0 673Q0 684 7 689T20 694Q32 694 38 680T82 567L126 451H430L473 566Q483 593 494 622T512 668T519 685Q524 694 538 694Q556 692 556 674Q556 670 426 329T293 -15Q288 -22 278 -22T263 -15Q260 -11 131 328T0 673ZM414 410Q414 411 278 411T142 410L278 55L414 410',
    0x2201: '404 269Q412 269 418 267T428 261T435 253T441 245L444 240V172Q444 103 443 96Q440 81 431 65T403 27T344 -7T250 -21T156 -8T97 27T69 65T58 96Q56 103 56 413Q56 722 58 729Q74 822 215 845Q221 846 229 846H243Q282 846 290 845Q422 826 443 729Q444 722 444 653V586L442 583Q441 580 440 578T436 573T430 567T423 562T415 558T404 556Q377 556 367 583Q364 590 364 654V719Q363 721 360 726T355 733Q326 766 250 766H249Q235 766 219 765T174 752T137 719V107Q145 83 178 71T251 58H254Q340 58 364 107V172Q364 176 364 187T363 204Q363 269 404 269',
    0x2202: '202 508Q179 508 169 520T158 547Q158 557 164 577T185 624T230 675T301 710L333 715H345Q378 715 384 714Q447 703 489 661T549 568T566 457Q566 362 519 240T402 53Q321 -22 223 -22Q123 -22 73 56Q42 102 42 148V159Q42 276 129 370T322 465Q383 465 414 434T455 367L458 378Q478 461 478 515Q478 603 437 639T344 676Q266 676 223 612Q264 606 264 572Q264 547 246 528T202 508ZM430 306Q430 372 401 400T333 428Q270 428 222 382Q197 354 183 323T150 221Q132 149 132 116Q132 21 232 21Q244 21 250 22Q327 35 374 112Q389 137 409 196T430 306',
    0x2203: '56 661T56 674T70 694H487Q497 686 500 679V15Q497 10 487 1L279 0H70Q56 7 56 20T70 40H460V327H84Q70 334 70 347T84 367H460V654H70Q56 661 56 674',
    0x2205: '331 696Q335 708 339 722T345 744T350 759T357 769T367 772Q374 772 381 767T388 754Q388 746 377 712L366 673L378 661Q460 575 460 344Q460 281 456 234T432 126T373 27Q319 -22 250 -22Q214 -22 180 -7Q168 -3 168 -4L159 -33Q148 -71 142 -75Q138 -78 132 -78Q124 -78 118 -72T111 -60Q111 -52 122 -18L133 21L125 29Q39 111 39 344Q39 596 137 675Q187 716 251 716Q265 716 278 714T296 710T315 703T331 696ZM276 676Q264 679 246 679Q196 679 159 631Q134 597 128 536T121 356Q121 234 127 174T151 80L234 366Q253 430 275 506T308 618L318 654Q318 656 294 669L276 676ZM181 42Q207 16 250 16Q291 16 324 47Q354 78 366 136T378 356Q378 470 372 528T349 616L348 613Q348 611 264 326L181 42',
    0x2206: '51 0Q46 4 46 7Q46 9 215 357T388 709Q391 716 416 716Q439 716 444 709Q447 705 616 357T786 7Q786 4 781 0H51ZM507 344L384 596L137 92L383 91H630Q630 93 507 344',
    0x2207: '46 676Q46 679 51 683H781Q786 679 786 676Q786 674 617 326T444 -26Q439 -33 416 -33T388 -26Q385 -22 216 326T46 676ZM697 596Q697 597 445 597T193 596Q195 591 319 336T445 80L697 596',
    0x2208: '84 250Q84 372 166 450T360 539Q361 539 377 539T419 540T469 540H568Q583 532 583 520Q583 511 570 501L466 500Q355 499 329 494Q280 482 242 458T183 409T147 354T129 306T124 272V270H568Q583 262 583 250T568 230H124V228Q124 207 134 177T167 112T231 48T328 7Q355 1 466 0H570Q583 -10 583 -20Q583 -32 568 -40H471Q464 -40 446 -40T417 -41Q262 -41 172 45Q84 127 84 250',
    0x2209: '196 25Q84 109 84 250Q84 372 166 450T360 539Q361 539 375 539T413 540T460 540L547 707Q550 716 563 716Q570 716 575 712T581 703T583 696T505 540H568Q583 532 583 520Q583 511 570 501L484 500L366 270H568Q583 262 583 250T568 230H346L247 38Q284 16 328 7Q355 1 466 0H570Q583 -10 583 -20Q583 -32 568 -40H471Q464 -40 447 -40T419 -41Q304 -41 228 3Q117 -211 115 -212Q111 -215 104 -215T92 -212T86 -204T84 -197Q84 -190 89 -183L196 25ZM214 61L301 230H124V228Q124 196 147 147T214 61ZM321 270L440 500Q353 499 329 494Q280 482 242 458T183 409T147 354T129 306T124 272V270H321',
    0x220B: '83 520Q83 532 98 540H195Q202 540 220 540T249 541Q404 541 494 455Q582 374 582 250Q582 165 539 99T434 0T304 -39Q297 -40 195 -40H98Q83 -32 83 -20Q83 -10 96 0H200Q311 1 337 6Q369 14 401 28Q422 39 445 55Q484 85 508 127T537 191T542 228V230H98Q84 237 84 250T98 270H542V272Q542 280 539 295T527 336T497 391T445 445Q422 461 401 472Q386 479 374 483T347 491T325 495T298 498T273 499T239 500T200 500L96 501Q83 511 83 520',
    0x220D: '154 -1Q122 -1 112 3T102 26Q102 63 158 63H178Q192 64 206 65T228 66T240 68Q301 85 324 146L329 157H244Q158 157 153 161Q149 162 145 169T140 183Q140 201 158 215L167 221H256L344 223L349 237Q352 262 352 287Q352 308 351 315Q341 352 315 368T256 385Q231 385 206 376T166 356T149 346Q143 346 138 364T132 388Q132 396 147 406Q198 440 252 440Q291 440 318 435Q421 404 451 301Q456 288 456 248V234Q456 151 391 86Q330 25 240 3Q212 -1 154 -1',
    0x220F: '158 656Q147 684 131 694Q110 707 69 710H55V750H888V710H874Q840 708 820 698T795 678T786 656V-155Q798 -206 874 -210H888V-250H570V-210H584Q618 -208 638 -197T663 -178T673 -155V710H270V277L271 -155Q283 -206 359 -210H373V-250H55V-210H69Q103 -208 123 -197T148 -178T158 -155V656',
    0x2210: '158 656Q147 684 131 694Q110 707 69 710H55V750H373V710H359Q325 708 305 698T280 678T271 656L270 223V-210H673V656Q666 672 663 679T639 697T584 710H570V750H888V710H874Q840 708 820 698T795 678T786 656V-155Q798 -206 874 -210H888V-250H55V-210H69Q103 -208 123 -197T148 -178T158 -155V656',
    0x2211: '61 748Q64 750 489 750H913L954 640Q965 609 976 579T993 533T999 516H979L959 517Q936 579 886 621T777 682Q724 700 655 705T436 710H319Q183 710 183 709Q186 706 348 484T511 259Q517 250 513 244L490 216Q466 188 420 134T330 27L149 -187Q149 -188 362 -188Q388 -188 436 -188T506 -189Q679 -189 778 -162T936 -43Q946 -27 959 6H999L913 -249L489 -250Q65 -250 62 -248Q56 -246 56 -239Q56 -234 118 -161Q186 -81 245 -11L428 206Q428 207 242 462L57 717L56 728Q56 744 61 748',
    0x2212: '84 237T84 250T98 270H679Q694 262 694 250T679 230H98Q84 237 84 250',
    0x2213: '56 467T56 480T70 500H707Q722 492 722 480T707 460H409V187H707Q722 179 722 167Q722 154 707 147H409V0V-93Q409 -144 406 -155T389 -166Q376 -166 372 -155T368 -105Q368 -96 368 -62T369 -2V147H70Q56 154 56 167T70 187H369V460H70Q56 467 56 480',
    0x2214: '339 717Q339 739 354 752T388 766Q410 766 424 751T439 716T424 681T390 666Q369 666 354 681T339 717ZM57 237T57 250T71 270H369V425L370 581Q380 594 389 594Q402 594 409 579V270H707Q722 262 722 250T707 230H409V-79Q401 -93 391 -93H389H387Q375 -93 369 -79V230H71Q57 237 57 250',
    0x2215: '423 750Q432 750 438 744T444 730Q444 725 271 248T92 -240Q85 -250 75 -250Q68 -250 62 -245T56 -231Q56 -221 230 257T407 740Q411 750 423 750',
    0x2216: '56 731Q56 740 62 745T75 750Q85 750 92 740Q96 733 270 255T444 -231Q444 -239 438 -244T424 -250Q414 -250 407 -240Q404 -236 230 242T56 731',
    0x2217: '229 286Q216 420 216 436Q216 454 240 464Q241 464 245 464T251 465Q263 464 273 456T283 436Q283 419 277 356T270 286L328 328Q384 369 389 372T399 375Q412 375 423 365T435 338Q435 325 425 315Q420 312 357 282T289 250L355 219L425 184Q434 175 434 161Q434 146 425 136T401 125Q393 125 383 131T328 171L270 213Q283 79 283 63Q283 53 276 44T250 35Q231 35 224 44T216 63Q216 80 222 143T229 213L171 171Q115 130 110 127Q106 124 100 124Q87 124 76 134T64 161Q64 166 64 169T67 175T72 181T81 188T94 195T113 204T138 215T170 230T210 250L74 315Q65 324 65 338Q65 353 74 363T98 374Q106 374 116 368T171 328L229 286',
    0x2218: '55 251Q55 328 112 386T249 444T386 388T444 249Q444 171 388 113T250 55Q170 55 113 112T55 251ZM245 403Q188 403 142 361T96 250Q96 183 141 140T250 96Q284 96 313 109T354 135T375 160Q403 197 403 250Q403 313 360 358T245 403',
    0x2219: '55 251Q55 328 112 386T249 444T386 388T444 249Q444 171 388 113T250 55Q170 55 113 112T55 251',
    0x221A: '95 178Q89 178 81 186T72 200T103 230T169 280T207 309Q209 311 212 311H213Q219 311 227 294T281 177Q300 134 312 108L397 -77Q398 -77 501 136T707 565T814 786Q820 800 834 800Q841 800 846 794T853 782V776L620 293L385 -193Q381 -200 366 -200Q357 -200 354 -197Q352 -195 256 15L160 225L144 214Q129 202 113 190T95 178',
    0x221D: '56 124T56 216T107 375T238 442Q260 442 280 438T319 425T352 407T382 385T406 361T427 336T442 315T455 297T462 285L469 297Q555 442 679 442Q687 442 722 437V398H718Q710 400 694 400Q657 400 623 383T567 343T527 294T503 253T495 235Q495 231 520 192T554 143Q625 44 696 44Q717 44 719 46H722V-5Q695 -11 678 -11Q552 -11 457 141Q455 145 454 146L447 134Q362 -11 235 -11Q157 -11 107 56ZM93 213Q93 143 126 87T220 31Q258 31 292 48T349 88T389 137T413 178T421 196Q421 200 396 239T362 288Q322 345 288 366T213 387Q163 387 128 337T93 213',
    0x221E: '55 217Q55 305 111 373T254 442Q342 442 419 381Q457 350 493 303L507 284L514 294Q618 442 747 442Q833 442 888 374T944 214Q944 128 889 59T743 -11Q657 -11 580 50Q542 81 506 128L492 147L485 137Q381 -11 252 -11Q166 -11 111 57T55 217ZM907 217Q907 285 869 341T761 397Q740 397 720 392T682 378T648 359T619 335T594 310T574 285T559 263T548 246L543 238L574 198Q605 158 622 138T664 94T714 61T765 51Q827 51 867 100T907 217ZM92 214Q92 145 131 89T239 33Q357 33 456 193L425 233Q364 312 334 337Q285 380 233 380Q171 380 132 331T92 214',
    0x2220: '71 0L68 2Q65 3 63 5T58 11T55 20Q55 22 57 28Q67 43 346 361Q397 420 474 508Q595 648 616 671T647 694T661 688T666 674Q666 668 663 663Q662 662 627 622T524 503T390 350L120 41L386 40H653Q666 30 666 20Q666 8 651 0H71',
    0x2221: '71 0L68 2Q65 3 63 5T58 11T55 20Q55 22 57 28Q64 38 348 373T638 712Q644 714 646 714Q653 714 659 709T666 694V693Q666 687 633 647Q619 631 576 580Q528 524 495 485Q336 296 329 289Q328 288 348 264T395 182T433 54L434 40H651Q666 32 666 20T651 0H436Q431 -20 416 -20Q400 -20 396 -4V0H71ZM394 40Q394 51 389 76T366 149T319 234L302 256L119 41L256 40H394',
    0x2222: '666 -32Q666 -51 646 -51Q639 -51 365 85L75 228Q55 238 55 250Q55 257 59 262T68 268L72 270L611 536Q642 551 647 551T659 547T666 532Q666 521 657 515L525 449Q525 448 535 424T556 352T566 250T556 148T536 77T525 51L657 -15Q666 -21 666 -32ZM526 250Q526 297 517 342T499 409T488 431Q487 431 304 341T121 250T304 159T488 69Q526 143 526 250',
    0x2223: '139 -249H137Q125 -249 119 -235V251L120 737Q130 750 139 750Q152 750 159 735V-235Q151 -249 141 -249H139',
    0x2224: '118 737Q131 750 138 750L151 746L158 739V579L160 421L213 470Q269 519 276 519Q284 519 290 513T296 499V498Q296 493 291 488T244 445Q225 428 213 417L158 368V-239Q143 -252 136 -252L124 -248L120 -241L118 44V328L62 279Q4 231 0 230Q-8 230 -14 236T-20 250Q-20 257 -11 265T62 332L118 384V737',
    0x2225: '133 736Q138 750 153 750Q164 750 170 739Q172 735 172 250T170 -239Q164 -250 152 -250Q144 -250 138 -244L137 -243Q133 -241 133 -179T132 250Q132 731 133 736ZM329 739Q334 750 346 750Q353 750 361 744L362 743Q366 741 366 679T367 250T367 -178T362 -243L361 -244Q355 -250 347 -250Q335 -250 329 -239Q327 -235 327 250T329 739',
    0x2226: '131 737Q134 739 138 743T144 748T151 750T171 737V199L327 357V737Q340 750 347 750Q351 750 353 749T360 743T367 737V397L429 457Q493 518 498 519Q506 519 512 512T518 500Q518 489 442 417L367 339V-237Q352 -250 346 -250L333 -243L327 -237V301L171 143V-237Q156 -250 151 -250T131 -237V101L69 41Q24 -3 15 -12T0 -21Q-8 -21 -14 -14T-20 -2Q-20 5 -7 19T56 81L131 159V737',
    0x2227: '318 591Q325 598 333 598Q344 598 348 591Q349 590 414 445T545 151T611 -4Q609 -22 591 -22Q588 -22 586 -21T581 -20T577 -17T575 -13T572 -9T570 -4L333 528L96 -4Q87 -20 80 -21Q78 -22 75 -22Q57 -22 55 -4Q55 2 120 150T251 444T318 591',
    0x2228: '55 580Q56 587 61 592T75 598Q86 598 96 580L333 48L570 580Q579 596 586 597Q588 598 591 598Q609 598 611 580Q611 574 546 426T415 132T348 -15Q343 -22 333 -22T318 -15Q317 -14 252 131T121 425T55 580',
    0x2229: '88 -21T75 -21T55 -7V200Q55 231 55 280Q56 414 60 428Q61 430 61 431Q77 500 152 549T332 598Q443 598 522 544T610 405Q611 399 611 194V-7Q604 -22 591 -22Q582 -22 572 -9L570 405Q563 433 556 449T529 485Q498 519 445 538T334 558Q251 558 179 518T96 401Q95 396 95 193V-7Q88 -21 75 -21',
    0x222A: '591 598H592Q604 598 611 583V376Q611 345 611 296Q610 162 606 148Q605 146 605 145Q586 68 507 23T333 -22Q268 -22 209 -1T106 66T56 173Q55 180 55 384L56 585Q66 598 75 598Q85 598 95 585V378L96 172L98 162Q112 95 181 57T332 18Q415 18 487 58T570 175Q571 180 571 383V583Q579 598 591 598',
    0x222B: '151 -112Q151 -150 106 -161Q106 -165 114 -172T134 -179Q155 -179 170 -146Q181 -120 188 -64T206 101T232 310Q256 472 277 567Q308 716 392 716Q434 716 453 681T472 613Q472 590 458 577T424 564Q404 564 390 578T376 612Q376 650 421 661Q421 663 418 667T407 675T393 679Q387 679 380 675Q360 665 350 619T326 438Q302 190 253 -57Q235 -147 201 -186Q174 -213 138 -216Q93 -216 74 -181T55 -113Q55 -91 69 -78T103 -64Q123 -64 137 -78T151 -112',
    0x222C: '113 -244Q113 -246 119 -251T139 -263T167 -269Q186 -269 199 -260Q220 -247 232 -218T251 -133T262 -15T276 155T297 367Q300 390 305 438T314 512T325 580T340 647T361 703T390 751T428 784T479 804Q481 804 488 804T501 805Q552 802 581 769T610 695Q610 669 594 657T561 645Q542 645 527 658T512 694Q512 705 516 714T526 729T538 737T548 742L552 743Q552 745 545 751T525 762T498 768Q475 768 460 756T434 716T418 652T407 559T398 444T387 300T369 133Q349 -38 337 -102T303 -207Q256 -306 169 -306Q119 -306 87 -272T55 -196Q55 -170 71 -158T104 -146Q123 -146 138 -159T153 -195Q153 -206 149 -215T139 -230T127 -238T117 -242L113 -244ZM460 -244Q460 -246 466 -251T486 -263T514 -269Q532 -269 546 -260Q567 -247 579 -218T598 -133T609 -15T623 155T644 367Q647 390 652 438T661 512T672 580T687 647T708 703T737 751T775 784T826 804Q828 804 835 804T848 805Q899 802 928 769T957 695Q957 669 941 657T908 645Q889 645 874 658T859 694Q859 705 863 714T873 729T885 737T895 742L899 743Q899 745 892 751T872 762T845 768Q822 768 807 756T781 716T765 652T754 559T745 444T734 300T716 133Q696 -38 684 -102T650 -207Q603 -306 516 -306Q466 -306 434 -272T402 -196Q402 -170 418 -158T451 -146Q470 -146 485 -159T500 -195Q500 -206 496 -215T486 -230T474 -238T464 -242L460 -244',
    0x222D: '113 -244Q113 -246 119 -251T139 -263T167 -269Q186 -269 199 -260Q220 -247 232 -218T251 -133T262 -15T276 155T297 367Q300 390 305 438T314 512T325 580T340 647T361 703T390 751T428 784T479 804Q481 804 488 804T501 805Q552 802 581 769T610 695Q610 669 594 657T561 645Q542 645 527 658T512 694Q512 705 516 714T526 729T538 737T548 742L552 743Q552 745 545 751T525 762T498 768Q475 768 460 756T434 716T418 652T407 559T398 444T387 300T369 133Q349 -38 337 -102T303 -207Q256 -306 169 -306Q119 -306 87 -272T55 -196Q55 -170 71 -158T104 -146Q123 -146 138 -159T153 -195Q153 -206 149 -215T139 -230T127 -238T117 -242L113 -244ZM460 -244Q460 -246 466 -251T486 -263T514 -269Q532 -269 546 -260Q567 -247 579 -218T598 -133T609 -15T623 155T644 367Q647 390 652 438T661 512T672 580T687 647T708 703T737 751T775 784T826 804Q828 804 835 804T848 805Q899 802 928 769T957 695Q957 669 941 657T908 645Q889 645 874 658T859 694Q859 705 863 714T873 729T885 737T895 742L899 743Q899 745 892 751T872 762T845 768Q822 768 807 756T781 716T765 652T754 559T745 444T734 300T716 133Q696 -38 684 -102T650 -207Q603 -306 516 -306Q466 -306 434 -272T402 -196Q402 -170 418 -158T451 -146Q470 -146 485 -159T500 -195Q500 -206 496 -215T486 -230T474 -238T464 -242L460 -244ZM807 -244Q807 -246 813 -251T833 -263T861 -269Q880 -269 893 -260Q914 -247 926 -218T945 -133T956 -15T970 155T991 367Q994 390 999 438T1008 512T1019 580T1034 647T1055 703T1084 751T1122 784T1173 804Q1175 804 1182 804T1195 805Q1246 802 1275 769T1304 695Q1304 669 1288 657T1255 645Q1236 645 1221 658T1206 694Q1206 705 1210 714T1220 729T1232 737T1242 742L1246 743Q1246 745 1239 751T1219 762T1192 768Q1169 768 1154 756T1128 716T1112 652T1101 559T1092 444T1081 300T1063 133Q1043 -38 1031 -102T997 -207Q950 -306 863 -306Q813 -306 781 -272T749 -196Q749 -170 765 -158T798 -146Q817 -146 832 -159T847 -195Q847 -206 843 -215T833 -230T821 -238T811 -242L807 -244',
    0x222E: '269 74L256 80Q244 85 227 97T191 128T161 179T148 250Q148 332 199 379T302 433L306 434L307 444Q309 456 313 495T321 553T331 607T345 664T365 712T393 756T431 785T479 804Q481 804 488 804T501 805Q552 802 581 769T610 695Q610 669 594 657T561 645Q542 645 527 658T512 694Q512 705 516 714T526 729T538 737T548 742L552 743Q552 745 545 751T525 762T498 768Q471 768 454 752T427 693T414 626T406 536Q405 530 405 527L397 425L404 422Q410 419 421 413T445 399T470 376T494 345T511 303T518 250Q518 205 502 169T460 112T410 80T364 66L360 65L359 55Q357 38 353 4T346 -43T340 -81T333 -118T326 -148T316 -179T303 -207Q256 -306 169 -306Q119 -306 87 -272T55 -196Q55 -170 71 -158T104 -146Q123 -146 138 -159T153 -195Q153 -206 149 -215T139 -230T127 -238T117 -242L113 -244Q113 -246 119 -251T139 -263T167 -269Q186 -269 199 -260Q231 -241 242 -183T266 33L269 74ZM272 122Q272 156 300 391Q300 392 299 392Q287 392 263 379T213 331T187 249Q187 211 205 180T239 137T272 116V122ZM366 107Q378 107 402 119T453 167T479 249Q479 340 394 383V377Q394 375 394 374T393 371T393 366T392 357T391 342T389 321T386 291T382 251T377 199T369 133Q366 112 366 107',
    0x2234: '273 411Q273 437 291 454T334 471Q358 471 375 454T393 411T376 368T333 351Q307 351 290 368T273 411ZM84 38Q110 38 126 21T143 -22Q143 -46 127 -64T83 -82Q57 -82 41 -65T24 -22Q24 4 41 21T84 38ZM524 -22Q524 4 541 21T584 38Q608 38 625 21T643 -22Q643 -45 627 -63T583 -82Q557 -82 541 -65T524 -22',
    0x2235: '23 411Q23 437 41 454T84 471Q108 471 125 454T143 411T126 368T83 351Q57 351 40 368T23 411ZM523 411Q523 437 541 454T584 471Q608 471 625 454T643 411T626 368T583 351Q557 351 540 368T523 411ZM274 -22Q274 4 291 21T334 38Q356 38 374 22T392 -22T375 -65T333 -82Q307 -82 291 -65T274 -22',
    0x223C: '55 166Q55 241 101 304T222 367Q260 367 296 349T362 304T421 252T484 208T554 189Q616 189 655 236T694 338Q694 350 698 358T708 367Q722 367 722 334Q722 260 677 197T562 134H554Q517 134 481 152T414 196T355 248T292 293T223 311Q179 311 145 286Q109 257 96 218T80 156T69 133Q55 133 55 166',
    0x223D: '222 133Q147 133 102 197T56 335Q56 362 66 365Q71 369 77 364Q83 356 84 335T90 298Q102 254 137 222T223 189Q258 189 292 206T355 250T413 301T477 346T550 367Q628 367 673 309T722 171Q722 133 708 133Q703 133 699 141T694 162Q694 220 655 265T555 311Q519 311 485 293T421 248T363 196T298 152T222 133',
    0x2240: '55 569Q55 583 83 583Q122 583 151 565T194 519T215 464T222 411Q222 360 194 304T139 193T111 89Q111 38 134 -7T195 -55Q222 -57 222 -69Q222 -83 189 -83Q130 -83 93 -33T55 90Q55 130 72 174T110 252T148 328T166 411Q166 462 144 507T83 555Q55 556 55 569',
    0x2241: '220 366Q258 366 297 347T361 308T391 288Q394 288 464 370Q494 407 510 425T535 454T546 465T552 467H553Q560 467 566 461T573 448Q573 439 499 350Q424 266 424 261Q424 259 442 247T492 222T554 209Q607 209 646 243Q671 268 680 295T690 341T702 366Q719 366 719 314Q716 265 695 226Q682 199 664 179Q614 132 555 132Q517 132 477 151T412 190T383 210T347 172T278 89T233 37Q228 32 220 32Q210 32 206 38T201 48Q201 57 266 137Q272 144 275 148Q351 231 351 237Q351 239 333 251T283 276T221 289Q159 289 123 248T86 166Q86 156 82 145T73 132Q55 132 55 172Q55 220 79 272Q95 301 111 319Q161 366 220 366',
    0x2242: '55 439T55 443T56 449T62 456T68 463H706Q720 449 720 443T706 423H68Q55 439 55 443ZM56 72Q56 112 73 152T130 225T224 257Q259 257 294 240T360 199T419 149T484 107T553 90Q603 90 643 125T691 223Q693 257 704 257Q717 257 717 221Q717 147 671 91T554 34Q517 34 481 51T414 93T355 142T291 184T222 201Q172 201 131 167T84 67Q81 34 71 34Q56 37 56 72',
    0x2243: '55 283Q55 356 103 409T217 463Q262 463 297 447T395 382Q431 355 446 344T493 320T554 307H558Q613 307 652 344T694 433Q694 464 708 464T722 432Q722 356 673 304T564 251H554Q510 251 465 275T387 329T310 382T223 407H219Q164 407 122 367Q91 333 85 295T76 253T69 250Q55 250 55 283ZM56 56Q56 71 72 76H706Q722 70 722 56Q722 44 707 36H70Q56 43 56 56',
    0x2245: '55 388Q55 463 101 526T222 589Q260 589 296 571T362 526T421 474T484 430T554 411Q616 411 655 458T694 560Q694 572 698 580T708 589Q722 589 722 556Q722 482 677 419T562 356H554Q517 356 481 374T414 418T355 471T292 515T223 533Q179 533 145 508Q109 479 96 440T80 378T69 355Q55 355 55 388ZM56 236Q56 249 70 256H707Q722 248 722 236Q722 225 708 217L390 216H72Q56 221 56 236ZM56 42Q56 57 72 62H708Q722 52 722 42Q722 30 707 22H70Q56 29 56 42',
    0x2247: '55 417Q55 479 101 528T222 578Q259 578 294 564T393 507Q413 493 434 480T469 460T484 454L537 549Q587 639 595 647Q600 652 607 652Q615 652 621 647T628 634Q628 625 575 536Q524 446 524 443Q527 440 555 440Q603 440 644 469T691 547Q694 578 706 578T718 556Q718 555 718 551T717 545Q717 488 684 445T595 387Q582 384 558 384Q530 384 508 389L493 394L404 238L557 236H708Q720 224 720 217T706 196H379L291 43L499 41H708Q720 29 720 21T706 1H268L226 -71Q186 -143 179 -148Q173 -155 165 -155T152 -150T146 -137Q146 -133 184 -64L222 1H144L66 3L59 7Q54 14 54 20Q54 29 66 41H246L333 194Q333 196 202 196H68Q55 211 55 218T66 236H213L357 238L457 409L437 421Q432 423 393 450T307 500T222 523Q171 523 129 491T84 414Q82 383 70 383Q55 383 55 417',
    0x2248: '55 319Q55 360 72 393T114 444T163 472T205 482Q207 482 213 482T223 483Q262 483 296 468T393 413L443 381Q502 346 553 346Q609 346 649 375T694 454Q694 465 698 474T708 483Q722 483 722 452Q722 386 675 338T555 289Q514 289 468 310T388 357T308 404T224 426Q164 426 125 393T83 318Q81 289 69 289Q55 289 55 319ZM55 85Q55 126 72 159T114 210T163 238T205 248Q207 248 213 248T223 249Q262 249 296 234T393 179L443 147Q502 112 553 112Q609 112 649 141T694 220Q694 249 708 249T722 217Q722 153 675 104T555 55Q514 55 468 76T388 123T308 170T224 192Q164 192 125 159T83 84Q80 55 69 55Q55 55 55 85',
    0x224A: '220 523Q163 523 124 486T84 412Q81 383 69 383Q56 383 56 413Q56 441 67 470Q78 508 111 537T187 575Q203 579 219 579Q248 579 271 572Q304 565 393 508Q498 439 551 439Q620 439 662 486Q688 512 693 557Q693 565 697 572T707 579Q719 579 719 548Q719 483 673 434T550 384Q512 384 467 405T386 453T305 501T220 523ZM222 288Q164 288 124 251T84 177Q81 148 69 148Q56 148 56 178Q56 206 67 235Q78 274 111 302T187 339Q198 343 220 343Q244 343 259 341T308 322T393 272Q496 203 553 203Q612 203 651 241T691 312Q693 343 705 343Q719 343 719 313Q719 245 673 199Q626 148 552 148Q513 148 467 170T385 218T304 266T222 288ZM51 -19Q51 -6 62 -1H387Q713 -1 715 -3Q725 -10 725 -20Q725 -27 718 -34Q714 -38 672 -38T387 -39H62Q51 -25 51 -19',
    0x224D: '55 464Q55 471 60 477T74 484Q80 484 108 464T172 420T268 376T389 356Q436 356 483 368T566 399T630 436T675 467T695 482Q701 484 703 484Q711 484 716 478T722 464Q722 454 707 442Q550 316 389 316Q338 316 286 329T195 362T124 402T76 437T57 456Q55 462 55 464ZM57 45Q66 58 109 88T230 151T381 183Q438 183 494 168T587 135T658 94T703 61T720 45Q722 39 722 36Q722 28 717 22T703 16Q697 16 669 36T606 80T510 124T389 144Q341 144 294 132T211 101T147 64T102 33T82 18Q76 16 74 16Q66 16 61 22T55 36Q55 39 57 45',
    0x224E: '245 367Q251 415 288 453T392 492Q445 492 485 456T532 367H707Q722 359 722 347Q722 334 711 331T665 327H608H509Q500 332 498 336Q496 338 493 363T472 411Q443 451 389 451H387Q335 451 305 411Q290 392 287 374T282 344T268 327H72Q56 332 56 347Q56 360 70 367H245ZM56 153Q56 168 72 173H268Q277 168 279 164Q281 162 284 137T305 89Q334 49 389 49H391Q442 49 472 89Q487 108 490 126T495 156T509 173H608H666Q701 173 711 170T722 153T707 133H532Q526 81 486 45T389 8Q331 8 291 45T245 133H70Q56 140 56 153',
    0x224F: '245 367Q251 415 288 453T392 492Q445 492 485 456T532 367H707Q722 359 722 347Q722 334 711 331T665 327H608H509Q500 332 498 336Q496 338 493 363T472 411Q443 451 389 451H387Q335 451 305 411Q290 392 287 374T282 344T268 327H72Q56 332 56 347Q56 360 70 367H245ZM56 153Q56 168 72 173H708Q722 163 722 153Q722 140 707 133H70Q56 140 56 153',
    0x2250: '56 347Q56 360 70 367H707Q722 359 722 347Q722 336 708 328L390 327H72Q56 332 56 347ZM56 153Q56 168 72 173H708Q722 163 722 153Q722 140 707 133H70Q56 140 56 153ZM329 610Q329 634 346 652T389 670Q413 670 431 654T450 611Q450 586 433 568T390 550T347 567T329 610',
    0x2251: '421 474T389 474T339 493T321 541Q321 566 337 587T391 609Q456 602 456 541Q456 512 439 493ZM56 347Q56 360 70 367H707Q722 359 722 347Q722 336 708 328L390 327H72Q56 332 56 347ZM56 153Q56 168 72 173H708Q722 163 722 153Q722 140 707 133H70Q56 140 56 153ZM421 -108T389 -108T339 -89T321 -41Q321 -16 337 5T391 27Q456 20 456 -41Q456 -70 439 -89',
    0x2252: '15 541Q15 569 33 585T75 601T117 585T135 541Q135 514 118 498T75 481T32 498T15 541ZM56 347Q56 360 70 367H707Q722 359 722 347Q722 336 708 328L390 327H72Q56 332 56 347ZM56 153Q56 168 72 173H708Q722 163 722 153Q722 140 707 133H70Q56 140 56 153ZM642 -41Q642 -17 658 0T702 18Q726 18 744 3T762 -41Q762 -67 745 -84T702 -101Q676 -101 659 -85T642 -41',
    0x2253: '642 541Q642 569 660 585T702 601T744 585T762 541Q762 515 745 498T702 481Q676 481 659 497T642 541ZM56 347Q56 360 70 367H707Q722 359 722 347Q722 336 708 328L390 327H72Q56 332 56 347ZM56 153Q56 168 72 173H708Q722 163 722 153Q722 140 707 133H70Q56 140 56 153ZM14 -41Q14 -15 31 1T74 18Q101 18 118 0T135 -41Q135 -64 118 -83T75 -102Q51 -102 33 -85T14 -41',
    0x2256: '56 347Q56 360 70 367H707Q722 359 722 347Q722 334 711 331T658 327H586H465L472 318Q496 288 496 250T472 182L465 173H586H663Q700 173 711 170T722 153T707 133H70Q56 140 56 153Q56 168 72 173H312L305 182Q281 212 281 250T305 318L312 327H72Q56 332 56 347ZM473 250Q473 265 472 273T460 297T428 327H349Q328 313 318 298T306 273T304 250Q304 235 305 227T317 203T349 173H428Q449 187 459 202T471 227T473 250',
    0x2257: '279 612Q279 656 310 688T388 721Q433 721 465 689T498 612Q498 573 470 538T389 503Q336 503 308 538T279 612ZM458 614Q458 637 452 651T433 672T411 679T383 680T352 675T333 664T324 647T321 629T320 611Q320 593 321 584T332 562T359 545Q366 543 389 543H391Q406 543 414 544T435 552T452 573T458 614ZM56 347Q56 360 70 367H707Q722 359 722 347Q722 336 708 328L390 327H72Q56 332 56 347ZM56 153Q56 168 72 173H708Q722 163 722 153Q722 140 707 133H70Q56 140 56 153',
    0x225C: '192 482H190Q187 483 185 484T181 488T177 493T175 501Q175 506 178 512Q184 523 278 687T375 853Q379 857 383 857Q385 857 387 858T390 859Q397 859 403 853Q405 851 499 687T600 512Q603 506 603 501Q603 488 587 482H192ZM548 523L389 798Q388 798 309 661T230 523T389 522T548 523ZM56 347Q56 360 70 367H708Q723 359 723 347Q723 336 709 328L390 327H72Q56 332 56 347ZM56 153Q56 168 72 173H709Q723 163 723 153Q723 140 708 133H70Q56 140 56 153',
    0x2260: '166 -215T159 -215T147 -212T141 -204T139 -197Q139 -190 144 -183L306 133H70Q56 140 56 153Q56 168 72 173H327L406 327H72Q56 332 56 347Q56 360 70 367H426Q597 702 602 707Q605 716 618 716Q625 716 630 712T636 703T638 696Q638 692 471 367H707Q722 359 722 347Q722 336 708 328L451 327L371 173H708Q722 163 722 153Q722 140 707 133H351Q175 -210 170 -212Q166 -215 159 -215',
    0x2261: '56 444Q56 457 70 464H707Q722 456 722 444Q722 430 706 424H72Q56 429 56 444ZM56 237T56 250T70 270H707Q722 262 722 250T707 230H70Q56 237 56 250ZM56 56Q56 71 72 76H706Q722 70 722 56Q722 44 707 36H70Q56 43 56 56',
    0x2264: '674 636Q682 636 688 630T694 615T687 601Q686 600 417 472L151 346L399 228Q687 92 691 87Q694 81 694 76Q694 58 676 56H670L382 192Q92 329 90 331Q83 336 83 348Q84 359 96 365Q104 369 382 500T665 634Q669 636 674 636ZM84 -118Q84 -108 99 -98H678Q694 -104 694 -118Q694 -130 679 -138H98Q84 -131 84 -118',
    0x2265: '83 616Q83 624 89 630T99 636Q107 636 253 568T543 431T687 361Q694 356 694 346T687 331Q685 329 395 192L107 56H101Q83 58 83 76Q83 77 83 79Q82 86 98 95Q117 105 248 167Q326 204 378 228L626 346L360 472Q291 505 200 548Q112 589 98 597T83 616ZM84 -118Q84 -108 99 -98H678Q694 -104 694 -118Q694 -130 679 -138H98Q84 -131 84 -118',
    0x2266: '674 753Q682 753 688 747T694 732T687 718Q686 717 417 589L151 463L399 345Q687 209 691 204Q694 198 694 193Q694 175 676 173H670L382 309Q92 446 90 448Q83 453 83 465Q84 476 96 482Q104 486 382 617T665 751Q669 753 674 753ZM84 39Q84 49 99 59H678Q694 53 694 39Q694 26 679 19H98Q84 26 84 39ZM83 -157Q83 -153 84 -150T86 -145T89 -141T92 -139T96 -137T99 -135H678Q694 -146 694 -155Q694 -168 679 -175H98Q84 -168 83 -157',
    0x2267: '83 733Q83 741 89 747T99 753Q107 753 253 685T543 548T687 478Q694 473 694 463T687 448Q685 446 395 309L107 173H101Q83 175 83 193Q83 194 83 196Q82 203 98 212Q117 222 248 284Q326 321 378 345L626 463L360 589Q291 622 200 665Q112 706 98 714T83 733ZM84 39Q84 49 99 59H678Q694 53 694 39Q694 26 679 19H98Q84 26 84 39ZM83 -157Q83 -153 84 -150T86 -145T89 -141T92 -139T96 -137T99 -135H678Q694 -146 694 -155Q694 -168 679 -175H98Q84 -168 83 -157',
    0x2268: '86 472Q93 477 381 614T673 752Q680 752 686 746T693 732T689 721Q686 715 418 590L151 461L418 332Q684 207 689 201Q693 195 693 190Q693 183 687 177T675 170Q668 170 380 307T86 450Q82 454 82 461Q82 467 86 472ZM82 33Q82 37 83 40T89 47T95 54H473L520 105Q569 156 571 156Q573 157 578 157Q586 157 592 151T598 136Q598 130 562 92L526 56L604 54H682Q693 43 693 35Q693 31 692 28T686 21T680 14H489L342 -139L513 -142H682Q693 -148 693 -160Q693 -167 680 -182H304L258 -230Q248 -240 237 -251T221 -268T211 -278T203 -284T197 -286Q189 -286 184 -280T178 -264Q178 -257 213 -219L249 -182H171L93 -179L86 -175Q82 -170 82 -163Q82 -155 95 -142H289L360 -64L433 14H262L93 16Q82 23 82 33',
    0x2269: '89 745Q95 752 100 752Q106 752 394 615T689 472Q693 468 693 461T689 450Q684 445 396 308T100 170Q95 170 89 176T82 190Q82 195 86 201Q91 208 358 332L624 461L358 590Q90 715 86 721Q82 725 82 731Q82 739 89 745ZM82 33Q82 37 83 40T89 47T95 54H473L520 105Q569 156 571 156Q573 157 578 157Q586 157 592 151T598 136Q598 130 562 92L526 56L604 54H682Q693 43 693 35Q693 31 692 28T686 21T680 14H489L342 -139L513 -142H682Q693 -148 693 -160Q693 -167 680 -182H304L258 -230Q248 -240 237 -251T221 -268T211 -278T203 -284T197 -286Q189 -286 184 -280T178 -264Q178 -257 213 -219L249 -182H171L93 -179L86 -175Q82 -170 82 -163Q82 -155 95 -142H289L360 -64L433 14H262L93 16Q82 23 82 33',
    0x226A: '639 -48Q639 -54 634 -60T619 -67H618Q612 -67 536 -26Q430 33 329 88Q61 235 59 239Q56 243 56 250T59 261Q62 266 336 415T615 567L619 568Q622 567 625 567Q639 562 639 548Q639 540 633 534Q632 532 374 391L117 250L374 109Q632 -32 633 -34Q639 -40 639 -48ZM944 -48Q944 -54 939 -60T924 -67H923Q917 -67 841 -26Q735 33 634 88Q366 235 364 239Q361 243 361 250T364 261Q367 266 641 415T920 567L924 568Q927 567 930 567Q944 562 944 548Q944 540 938 534Q937 532 679 391L422 250L679 109Q937 -32 938 -34Q944 -40 944 -48',
    0x226B: '55 539T55 547T60 561T74 567Q81 567 207 498Q297 449 365 412Q633 265 636 261Q639 255 639 250Q639 241 626 232Q614 224 365 88Q83 -65 79 -66Q76 -67 73 -67Q65 -67 60 -61T55 -47Q55 -39 61 -33Q62 -33 95 -15T193 39T320 109L321 110H322L323 111H324L325 112L326 113H327L329 114H330L331 115H332L333 116L334 117H335L336 118H337L338 119H339L340 120L341 121H342L343 122H344L345 123H346L347 124L348 125H349L351 126H352L353 127H354L355 128L356 129H357L358 130H359L360 131H361L362 132L363 133H364L365 134H366L367 135H368L369 136H370L371 137L372 138H373L374 139H375L376 140L378 141L576 251Q63 530 62 533Q55 539 55 547ZM360 539T360 547T365 561T379 567Q386 567 512 498Q602 449 670 412Q938 265 941 261Q944 255 944 250Q944 241 931 232Q919 224 670 88Q388 -65 384 -66Q381 -67 378 -67Q370 -67 365 -61T360 -47Q360 -39 366 -33Q367 -33 400 -15T498 39T625 109L626 110H627L628 111H629L630 112L631 113H632L634 114H635L636 115H637L638 116L639 117H640L641 118H642L643 119H644L645 120L646 121H647L648 122H649L650 123H651L652 124L653 125H654L656 126H657L658 127H659L660 128L661 129H662L663 130H664L665 131H666L667 132L668 133H669L670 134H671L672 135H673L674 136H675L676 137L677 138H678L679 139H680L681 140L683 141L881 251Q368 530 367 533Q360 539 360 547',
    0x226C: '104 730Q104 749 123 749Q130 749 138 745Q186 717 237 671L250 659L261 670Q297 703 332 726T375 750T389 744T395 730Q395 721 390 717T364 699T322 668Q290 641 283 632Q280 628 281 627T293 612Q425 454 425 250Q425 144 388 51T293 -112Q282 -125 281 -126T283 -132Q306 -162 379 -209Q395 -219 395 -230Q395 -238 389 -244T375 -250T335 -228T262 -171L250 -159L238 -170Q202 -203 167 -226T124 -250T110 -244T104 -230Q104 -219 121 -209Q199 -156 216 -132Q219 -128 218 -127T206 -112Q74 46 74 250T206 612Q217 625 218 626T216 632Q199 656 121 709Q104 719 104 730ZM249 -94Q364 61 364 250Q364 430 265 574Q253 590 249 594L242 583Q134 439 134 250Q134 114 192 -1Q212 -44 242 -83L249 -94',
    0x226E: '693 -14T693 -20T687 -33T675 -41Q667 -41 506 37L344 112Q342 112 262 -46Q184 -196 176 -205Q172 -209 168 -209T162 -208Q155 -208 151 -203T146 -190Q146 -178 171 -137Q193 -91 251 21L306 132L198 183Q142 208 118 220T88 238T82 249Q82 253 86 261Q92 267 278 357L464 443L529 572Q561 637 577 667T597 703T607 708Q615 708 622 702T629 688Q629 680 575 579L524 474Q524 473 545 482T598 508Q666 541 673 541T686 535T693 521Q693 512 679 504T589 459L493 414L360 150Q366 148 378 142T431 116T529 70Q686 -8 689 -10Q693 -14 693 -20ZM380 277L433 383Q432 385 292 319T151 250T237 209T324 170L380 277',
    0x226F: '82 514T82 520T89 533T100 541Q106 541 271 463Q434 386 435 386L515 543Q593 699 600 706Q604 708 607 708Q615 708 622 702T629 688T549 526Q509 445 491 407T473 368Q522 343 580 317Q636 291 660 278T688 261T693 250V249Q693 241 681 234T580 184Q533 161 502 146Q317 59 315 59Q312 56 246 -74Q197 -170 186 -189T168 -209Q164 -209 162 -208Q155 -208 151 -203T146 -190Q146 -187 200 -79L253 28L218 11Q182 -6 144 -23T100 -41Q95 -41 89 -35T82 -21Q82 -12 96 -4T186 41L284 88L349 217Q377 273 395 311T413 350Q413 351 253 428Q101 498 86 510Q82 514 82 520ZM624 250Q461 330 455 330Q454 331 453 329T448 321T441 308T430 287T416 259T398 223L342 114L624 250',
    0x2270: '82 -124Q82 -120 83 -117T89 -110T95 -103H220L284 50Q346 204 344 206L218 268Q153 297 123 313T87 333T82 344T86 355Q104 369 291 455Q491 552 491 553L542 673Q581 767 590 784T609 801Q616 801 622 795T629 781Q629 773 586 677Q546 581 546 577L609 606Q669 635 673 635Q680 635 686 629T693 615Q693 610 692 608T670 593T604 561L524 521L400 226L542 157Q617 123 649 107T687 85T694 72Q694 66 690 60T679 54Q664 54 526 121Q513 127 495 136T464 150T438 162T416 173T399 180T388 185L384 186Q383 186 322 41L262 -103H680Q682 -105 684 -108T688 -113T691 -118T693 -124Q693 -134 682 -141L464 -143H246L213 -219Q182 -292 178 -299Q172 -303 166 -303T153 -297T146 -283Q146 -282 174 -213T202 -143H146L93 -141Q82 -134 82 -124ZM418 370L466 495Q464 495 308 420T151 344T204 317T311 267T364 244Q364 247 418 370',
    0x2271: '97 54Q82 54 82 72Q82 79 86 84Q95 91 222 153L351 215L398 324L442 433L258 519Q95 597 87 604Q82 608 82 615T88 628T102 635Q107 635 424 484L458 468L524 630Q593 789 597 795Q601 801 609 801Q616 801 622 795T629 781L562 615L493 450L589 406Q665 371 679 362T694 344Q694 339 693 337T677 326T631 302T538 257Q504 241 465 223T406 195T386 186Q384 185 322 39L262 -103H680Q682 -105 684 -108T688 -113T691 -118T693 -124Q693 -134 682 -141L464 -143H246L213 -219Q182 -292 178 -299Q172 -303 166 -303T153 -297T146 -283Q146 -282 174 -213T202 -143H146L93 -141Q82 -134 82 -124Q82 -120 83 -117T89 -110T95 -103H220L273 26Q326 156 326 157L218 106Q109 54 97 54ZM553 379Q480 412 480 415Q479 415 460 372T423 285T406 241Q408 240 516 291T624 344L553 379',
    0x2272: '674 732Q682 732 688 726T694 711T687 697Q686 696 417 568L151 442L399 324Q687 188 691 183Q694 177 694 172Q694 154 676 152H670L382 288Q92 425 90 427Q83 432 83 444Q84 455 96 461Q104 465 382 596T665 730Q669 732 674 732ZM56 -194Q56 -107 106 -51T222 6Q260 6 296 -12T362 -56T420 -108T483 -153T554 -171Q616 -171 654 -128T694 -29Q696 6 708 6Q722 6 722 -26Q722 -102 676 -164T557 -227Q518 -227 481 -209T415 -165T358 -113T294 -69T223 -51Q163 -51 125 -93T83 -196Q81 -228 69 -228Q56 -228 56 -202V-194',
    0x2273: '90 697Q83 704 83 712T88 726T99 732Q107 732 253 664T543 527T687 457Q694 452 694 442T687 427Q685 425 395 288L107 152H101Q83 154 83 172Q83 173 83 175Q82 182 98 191Q117 201 248 263Q326 300 378 324L626 442L360 568Q91 696 90 697ZM56 -194Q56 -107 106 -51T222 6Q260 6 296 -12T362 -56T420 -108T483 -153T554 -171Q616 -171 654 -128T694 -29Q696 6 708 6Q722 6 722 -26Q722 -102 676 -164T557 -227Q518 -227 481 -209T415 -165T358 -113T294 -69T223 -51Q163 -51 125 -93T83 -196Q81 -228 69 -228Q56 -228 56 -202V-194',
    0x2276: '734 181Q734 173 728 167T714 161Q711 161 386 280T54 404Q44 408 44 421Q44 432 52 437Q66 443 388 562T714 681Q721 681 727 675T734 661Q734 651 722 645Q711 639 462 546Q441 539 420 531L122 421L420 311L723 198Q734 192 734 181ZM44 247Q44 255 50 261T63 267Q66 267 391 148T723 24Q734 18 734 7T723 -10Q716 -14 391 -133T63 -253Q56 -253 50 -247T44 -233Q44 -223 55 -217Q67 -210 317 -118Q337 -110 357 -103L655 7L357 117L54 230Q44 236 44 247',
    0x2277: '83 661Q83 668 88 674T104 681Q111 679 396 560Q686 437 687 436Q694 431 694 421T687 406Q686 405 543 344T253 222T101 161Q83 163 83 180Q83 194 95 199Q96 199 130 213T232 257T361 311L621 421L357 532Q307 553 233 584Q121 631 102 640T83 661ZM673 267Q694 267 694 248Q694 237 687 232Q684 229 420 118L156 7L416 -103L683 -215Q694 -222 694 -233Q694 -251 676 -253Q670 -253 524 -192T235 -70T90 -8Q83 -1 83 7Q83 19 94 24Q97 25 378 144T667 266Q669 267 673 267',
    0x227A: '84 249Q84 262 91 266T117 270Q120 270 126 270T137 269Q388 273 512 333T653 512Q657 539 676 539Q685 538 689 532T694 520V515Q689 469 672 431T626 366T569 320T500 286T435 265T373 249Q379 248 404 242T440 233T477 221T533 199Q681 124 694 -17Q694 -41 674 -41Q658 -41 653 -17Q646 41 613 84T533 154T418 197T284 220T137 229H114Q104 229 98 230T88 235T84 249',
    0x227B: '84 517Q84 539 102 539Q115 539 119 529T125 503T137 459T171 404Q277 275 640 269H661Q694 269 694 249T661 229H640Q526 227 439 214T283 173T173 98T124 -17Q118 -41 103 -41Q83 -41 83 -17Q88 29 105 67T151 132T208 178T277 212T342 233T404 249Q401 250 380 254T345 263T302 276T245 299Q125 358 92 468Q84 502 84 517',
    0x227C: '112 270Q83 270 83 290Q83 301 94 307Q98 310 118 310Q516 310 620 464Q635 486 642 510T651 548T657 571T675 580Q693 577 693 559V552Q684 472 628 410T465 314Q436 303 372 290Q373 290 388 287T425 278T465 266Q674 199 693 28L694 17Q688 5 683 3Q677 0 673 0Q656 0 653 24Q623 270 118 270H112ZM110 116Q83 116 83 136T110 156H113Q134 156 160 155T231 146T318 128T407 95T489 44T550 -30T583 -131Q583 -153 563 -153Q556 -153 553 -152T547 -145T542 -127Q531 -54 478 0Q425 53 333 83T123 116H110',
    0x227D: '668 310Q694 310 694 290Q694 285 691 279Q684 271 664 270Q550 268 464 257T301 220T179 146T124 27Q119 0 103 0T83 16Q83 21 83 31T92 68T113 121T157 177T229 231Q295 268 405 290Q404 290 389 293T352 302T312 314Q138 371 96 500Q83 541 83 562Q83 568 89 574T103 580Q115 580 120 570T126 542T138 497T173 442Q289 310 659 310H668ZM194 -131Q201 -60 241 -6T343 82T477 133T628 155Q632 155 644 155T661 156Q685 155 690 147Q694 143 694 136Q694 132 693 129T689 124T685 120T681 117L656 116Q596 114 543 106T436 79T342 35T272 -33T235 -127Q231 -154 212 -154Q203 -153 199 -147T194 -136V-131',
    0x227E: '84 442Q84 455 91 459T117 463Q120 463 126 463T137 462Q388 466 512 526T653 705Q657 732 676 732Q685 731 689 725T694 714V708Q689 662 672 624T626 559T569 513T500 479T435 458T373 442Q379 441 404 435T440 426T477 414T533 392Q592 362 630 319T681 241T694 174Q694 153 674 153Q662 153 657 163T652 188T640 231T606 287Q500 416 137 422H114Q104 422 98 423T88 428T84 442ZM56 -194Q56 -107 106 -51T222 6Q260 6 296 -12T362 -56T420 -108T483 -153T554 -171Q616 -171 654 -128T694 -29Q696 6 708 6Q722 6 722 -26Q722 -102 676 -164T557 -227Q518 -227 481 -209T415 -165T358 -113T294 -69T223 -51Q163 -51 125 -93T83 -196Q81 -228 69 -228Q56 -228 56 -202V-194',
    0x227F: '84 710Q84 732 102 732Q115 732 119 722T125 696T137 652T171 597Q277 468 640 462H661Q694 462 694 442T661 422H640Q578 421 526 417T415 403T309 376T222 333T156 268T124 179Q122 162 118 158T103 153Q100 153 98 153T95 154T93 155T90 158T85 163Q83 167 83 176Q88 222 105 260T151 325T208 371T277 405T342 426T404 442Q401 443 380 447T345 456T302 469T245 492Q125 551 92 661Q84 695 84 710ZM56 -194Q56 -107 106 -51T222 6Q260 6 296 -12T362 -56T420 -108T483 -153T554 -171Q616 -171 654 -128T694 -29Q696 6 708 6Q722 6 722 -26Q722 -102 676 -164T557 -227Q518 -227 481 -209T415 -165T358 -113T294 -69T223 -51Q163 -51 125 -93T83 -196Q81 -228 69 -228Q56 -228 56 -202V-194',
    0x2280: '386 292Q388 292 439 393T543 598T598 703Q599 703 603 704T609 705Q616 705 622 699T629 685T533 494Q440 308 440 305Q451 310 462 312Q547 342 592 388T651 505Q654 525 658 532T673 539Q680 539 686 533T693 519Q693 495 678 450Q638 341 500 283Q433 259 418 259Q416 259 411 251T406 241T415 239Q482 224 544 190Q674 121 691 -10Q693 -28 691 -32Q684 -43 672 -43Q664 -43 658 -37Q656 -33 650 -6T634 47T589 109T500 168Q473 179 436 190T388 201H386L284 -1Q261 -45 232 -101T191 -181T178 -206Q176 -206 172 -207T166 -208Q160 -208 153 -202T146 -188Q146 -185 246 12Q344 206 344 210Q344 213 305 217T213 225T124 228H95Q82 241 82 248Q82 253 95 268H124Q172 268 236 273T343 283T386 292',
    0x2281: '103 -43Q96 -43 89 -39T82 -26L84 -10Q105 141 275 212Q342 236 355 236Q360 236 364 245L369 256H360Q284 280 275 283Q115 351 86 490Q82 507 82 517Q82 526 88 532T103 538Q110 538 115 534Q119 531 122 517T128 486T143 444T174 397T231 351T320 310Q371 292 389 292L491 496Q595 701 598 703Q599 703 603 704T609 705Q616 705 622 699T629 685Q629 684 531 485Q431 296 431 288Q431 278 520 273T651 268H680Q693 253 693 248Q693 241 680 228H651Q591 228 491 218T386 201L284 -1Q261 -45 232 -101T191 -181T178 -206Q176 -206 172 -207T166 -208Q160 -208 153 -202T146 -188Q146 -182 302 125L335 190L324 185Q313 185 289 172Q241 153 208 128T159 78T135 31T124 -11T118 -37Q112 -43 103 -43',
    0x2282: '84 250Q84 372 166 450T360 539Q361 539 370 539T395 539T430 540T475 540T524 540H679Q694 532 694 520Q694 511 681 501L522 500H470H441Q366 500 338 496T266 472Q244 461 224 446T179 404T139 337T124 250V245Q124 157 185 89Q244 25 328 7Q348 2 366 2T522 0H681Q694 -10 694 -20Q694 -32 679 -40H526Q510 -40 480 -40T434 -41Q350 -41 289 -25T172 45Q84 127 84 250',
    0x2283: '83 520Q83 532 98 540H251Q267 540 297 540T343 541Q427 541 488 525T605 455Q693 374 693 250Q693 165 650 99T545 0T415 -39Q407 -40 251 -40H98Q83 -32 83 -20Q83 -10 96 0H255H308H337Q412 0 439 4T512 28Q533 39 553 54T599 96T639 163T654 250Q654 341 592 411Q557 449 512 472Q468 491 439 495T335 500H306H255L96 501Q83 511 83 520',
    0x2286: '84 346Q84 468 166 546T360 635Q361 635 370 635T395 635T430 636T475 636T524 636H679Q694 628 694 616Q694 607 681 597L522 596H470H441Q366 596 338 592T266 568Q244 557 224 542T179 500T139 433T124 346V341Q124 253 185 185Q244 121 328 103Q348 98 366 98T522 96H681Q694 86 694 76Q694 64 679 56H526Q510 56 480 56T434 55Q350 55 289 71T172 141Q84 223 84 346ZM104 -131T104 -118T118 -98H679Q694 -106 694 -118T679 -138H118Q104 -131 104 -118',
    0x2287: '83 616Q83 628 98 636H251Q267 636 297 636T343 637Q427 637 488 621T605 551Q693 470 693 346Q693 261 650 195T545 96T415 57Q407 56 251 56H98Q83 64 83 76Q83 86 96 96H255H308H337Q412 96 439 100T512 124Q533 135 553 150T599 192T639 259T654 346Q654 437 592 507Q557 545 512 568Q468 587 439 591T335 596H306H255L96 597Q83 607 83 616ZM84 -131T84 -118T98 -98H659Q674 -106 674 -118T659 -138H98Q84 -131 84 -118',
    0x2288: '146 -283Q146 -282 174 -213T202 -143H115Q102 -127 102 -123T115 -103H220L291 68L278 73Q203 101 153 157T86 288Q83 309 83 344Q83 380 86 399Q107 480 160 539Q222 601 298 621Q328 630 345 631T435 635L526 637L560 715Q587 778 593 789T609 801Q616 801 622 795T629 781Q629 780 625 771T614 742T600 706L571 637Q571 635 626 635H680Q693 620 693 613T689 601L682 597L618 595H553L449 346Q425 288 399 223T359 127T346 95H356Q365 95 381 95T417 94T463 93T515 93H682Q693 82 693 74T680 53H511Q420 55 335 55L329 57L262 -103H680Q682 -105 684 -108T688 -113T691 -118T693 -124Q693 -134 682 -141L464 -143H246L213 -219Q182 -292 178 -299Q172 -303 166 -303T153 -297T146 -283ZM509 590Q509 595 438 595Q354 595 318 586Q246 567 195 516T126 395Q123 378 123 344T126 293Q141 229 184 181T291 110L306 104L406 346L509 590',
    0x2289: '82 606T82 613T95 635H251H348Q408 635 435 632T502 615L515 608L520 617Q520 619 558 708Q584 774 591 787T609 801Q616 801 622 795T629 781Q629 775 562 615L551 590L569 577Q646 527 678 437Q691 398 691 344T678 250Q653 182 597 132T469 64Q427 53 366 53H326L295 -25L262 -103H660Q673 -118 673 -124Q673 -129 669 -136L662 -141L453 -143H246L213 -219Q182 -292 178 -299Q172 -303 166 -303T153 -297T146 -283Q146 -282 174 -213T202 -143H95Q82 -128 82 -123T95 -103H220L251 -25L284 53H189L93 55L86 59Q82 64 82 71T95 93H302L400 333Q498 569 498 573L444 590Q431 593 260 595L93 597L86 601Q82 606 82 613ZM652 344V354Q652 451 575 521Q571 526 557 538T537 551Q534 551 533 548Q533 543 438 319L344 95L371 93H386Q487 93 557 150T649 293Q652 309 652 344',
    0x228A: '693 72Q693 68 692 66T686 59T680 52H524Q398 52 367 53T309 63Q236 82 180 132T98 250Q84 288 84 343Q84 397 98 437Q126 515 193 568T346 632Q347 632 373 633T440 634T520 635H680Q693 620 693 615Q693 608 680 595H526Q364 595 353 592Q279 582 221 539T138 430Q124 392 124 343Q124 296 138 257Q163 192 221 149T353 95Q364 92 526 92H680Q693 79 693 72ZM102 -132T102 -125T115 -103H382L420 -68Q429 -60 438 -52T452 -39T463 -28T472 -20T478 -14T483 -10T487 -7T490 -6T493 -5T496 -5Q502 -5 508 -12T515 -28Q515 -34 513 -37Q512 -38 507 -42T492 -55T475 -70L440 -101L562 -103H682Q693 -114 693 -122T680 -143H395L355 -179Q289 -241 280 -241Q273 -241 267 -235T260 -221T265 -208T300 -174L335 -143H224L113 -141L106 -137Q102 -132 102 -125',
    0x228B: '82 615Q82 620 95 635H251Q378 635 409 634T469 623Q540 605 596 555T678 437Q691 397 691 343T678 250Q649 172 581 119T426 55Q415 52 251 52H95Q93 55 89 59T84 65T82 72Q82 79 95 92H249Q411 92 422 95Q496 105 554 148T638 257Q651 296 651 343Q651 391 638 430Q613 495 555 538T422 592Q411 595 249 595H95Q82 608 82 615ZM82 -132T82 -125T95 -103H380L420 -57Q452 -21 460 -14T474 -6Q482 -6 488 -12T495 -25T451 -81L433 -101L549 -103H662Q673 -114 673 -122T660 -143H395L355 -190Q311 -239 309 -239Q305 -241 302 -241Q294 -241 287 -235T280 -221T324 -163L342 -143H218L93 -141L86 -137Q82 -132 82 -125',
    0x228E: '591 598H592Q604 598 611 583V376Q611 345 611 296Q610 162 606 148Q605 146 605 145Q586 68 507 23T333 -22Q268 -22 209 -1T106 66T56 173Q55 180 55 384L56 585Q66 598 75 598Q85 598 95 585V378L96 172L98 162Q112 95 181 57T332 18Q415 18 487 58T570 175Q571 180 571 383V583Q579 598 591 598ZM313 406Q313 417 313 435T312 459Q312 483 316 493T333 503T349 494T353 461V406V325H515Q516 325 519 323T527 316T531 305T527 294T520 287T515 285H353V204V152Q353 127 350 117T333 107T316 117T312 152Q312 158 312 175T313 204V285H151Q150 285 147 287T139 294T135 305T139 316T146 323T151 325H313V406',
    0x228F: '83 523Q87 535 99 539H679Q694 531 694 519Q694 506 679 499H123V-1H678Q694 -7 694 -21Q694 -34 679 -41H98Q93 -38 84 -28L83 247V523',
    0x2290: '64 506T64 519T78 539H699Q706 536 714 526V-28Q706 -38 699 -41H78Q64 -34 64 -21Q64 -6 80 -1H674V499H78Q64 506 64 519',
    0x2291: '94 620Q98 632 110 636H699Q714 628 714 616T699 596H134V96H698Q714 90 714 76Q714 64 699 56H109Q104 59 95 69L94 344V620ZM84 -118Q84 -103 100 -98H698Q714 -104 714 -118Q714 -130 699 -138H98Q84 -131 84 -118',
    0x2292: '64 603T64 616T78 636H668Q675 633 683 623V69Q675 59 668 56H78Q64 63 64 76Q64 91 80 96H643V596H78Q64 603 64 616ZM64 -118Q64 -108 79 -98H678Q694 -104 694 -118Q694 -130 679 -138H78Q64 -131 64 -118',
    0x2293: '83 0Q79 0 76 1T71 3T67 6T65 9T63 13T61 16V301L62 585Q70 595 76 598H592Q602 590 605 583V15Q598 2 587 0Q583 0 580 1T575 3T571 6T569 9T567 13T565 16V558H101V15Q94 2 83 0',
    0x2294: '77 0Q65 4 61 16V301L62 585Q72 598 81 598Q94 598 101 583V40H565V583Q573 598 585 598Q598 598 605 583V15Q602 10 592 1L335 0H77',
    0x2295: '56 250Q56 394 156 488T384 583Q530 583 626 485T722 250Q722 110 625 14T390 -83Q249 -83 153 14T56 250ZM364 542Q308 539 251 509T148 418T96 278V270H369V542H364ZM681 278Q675 338 650 386T592 462T522 509T458 535T412 542H409V270H681V278ZM96 222Q104 150 139 95T219 12T302 -29T366 -42H369V230H96V222ZM681 222V230H409V-42H412Q429 -42 456 -36T521 -10T590 37T649 113T681 222',
    0x2296: '56 250Q56 394 156 488T384 583Q530 583 626 485T722 250Q722 110 625 14T390 -83Q249 -83 153 14T56 250ZM681 278Q669 385 591 463T381 542Q283 542 196 471T96 278V270H681V278ZM275 -42T388 -42T585 32T681 222V230H96V222Q108 107 191 33',
    0x2297: '56 250Q56 394 156 488T384 583Q530 583 626 485T722 250Q722 110 625 14T390 -83Q249 -83 153 14T56 250ZM582 471Q531 510 496 523Q446 542 381 542Q324 542 272 519T196 471L389 278L485 375L582 471ZM167 442Q95 362 95 250Q95 137 167 58L359 250L167 442ZM610 58Q682 138 682 250Q682 363 610 442L418 250L610 58ZM196 29Q209 16 230 2T295 -27T388 -42Q409 -42 429 -40T465 -33T496 -23T522 -11T544 1T561 13T574 22T582 29L388 222L196 29',
    0x2298: '56 250Q56 394 156 488T384 583Q530 583 626 485T722 250Q722 110 625 14T390 -83Q249 -83 153 14T56 250ZM582 471Q581 472 571 480T556 491T539 502T517 514T491 525T460 534T424 539T381 542Q272 542 184 460T95 251Q95 198 113 150T149 80L167 58L582 471ZM388 -42Q513 -42 597 44T682 250Q682 363 610 442L196 29Q209 16 229 2T295 -27T388 -42',
    0x2299: '56 250Q56 394 156 488T384 583Q530 583 626 485T722 250Q722 110 625 14T390 -83Q249 -83 153 14T56 250ZM682 250Q682 322 649 387T546 497T381 542Q272 542 184 459T95 250Q95 132 178 45T389 -42Q515 -42 598 45T682 250ZM311 250Q311 285 332 304T375 328Q376 328 382 328T392 329Q424 326 445 305T466 250Q466 217 445 195T389 172Q354 172 333 195T311 250',
    0x229A: '57 250Q57 327 87 392T166 497T270 560T382 582H394Q512 582 610 500Q721 401 721 250Q721 112 626 15T389 -82Q251 -82 154 13T57 250ZM682 129T682 250T596 457T390 543Q269 543 183 457T96 250Q96 132 180 45T389 -43Q511 -43 596 43ZM250 250Q250 316 295 352T384 388Q451 388 489 347T528 250Q528 192 487 152T389 112Q331 112 291 152T250 250ZM488 250Q488 290 460 319T389 349Q348 349 319 320T290 250Q290 208 320 180T389 151Q431 151 459 181T488 250',
    0x229B: '57 250Q57 327 87 392T166 497T270 560T382 582H394Q512 582 610 500Q721 401 721 250Q721 112 626 15T389 -82Q251 -82 154 13T57 250ZM682 129T682 250T596 457T390 543Q269 543 183 457T96 250Q96 132 180 45T389 -43Q511 -43 596 43ZM204 339Q204 357 215 366T238 375Q247 375 283 348Q300 336 311 328L368 286Q369 286 366 323T359 398T355 437Q357 456 379 465Q380 465 384 465T391 466Q403 465 412 457T423 437Q423 436 420 398T413 323T410 286L467 328Q476 334 486 341T501 353T513 361T523 368T529 372T535 374T541 375Q554 375 564 365T575 339Q575 325 566 318T519 292Q504 285 496 281L430 250L496 219Q552 192 559 188T572 175Q575 168 575 161Q575 148 566 137T541 126H538Q530 126 499 149Q480 163 467 172L410 214Q409 214 412 177T419 102T423 63Q423 59 421 54T411 43T389 36T368 42T357 54T355 63Q355 64 358 102T365 177T368 214L311 172Q302 165 293 159T279 148T268 140T260 134T254 131T250 128T246 127T242 126T238 126Q223 126 214 135T204 161T213 183T282 219L348 250L282 281Q226 308 219 312T206 325Q204 330 204 339',
    0x229D: '57 250Q57 327 87 392T166 497T270 560T382 582H394Q512 582 610 500Q721 401 721 250Q721 112 626 15T389 -82Q251 -82 154 13T57 250ZM682 129T682 250T596 457T390 543Q269 543 183 457T96 250Q96 132 180 45T389 -43Q511 -43 596 43ZM223 250Q223 263 233 267T280 271Q289 271 325 271T389 270H490Q535 270 545 267T555 250Q555 241 549 235Q544 231 527 231T389 230Q239 230 235 232Q223 236 223 250',
    0x229E: '71 0Q59 4 55 16V346L56 676Q64 686 70 689H709Q719 681 722 674V15Q719 10 709 1L390 0H71ZM369 365V649H95V365H369ZM682 365V649H409V365H682ZM369 40V325H95V40H369ZM682 40V325H409V40H682',
    0x229F: '71 0Q59 4 55 16V346L56 676Q64 686 70 689H709Q719 681 722 674V15Q719 10 709 1L390 0H71ZM682 365V649H95V365H682ZM682 40V325H95V40H682',
    0x22A0: '71 0Q59 4 55 16V346L56 676Q64 686 70 689H707Q714 686 722 676V13Q714 3 707 0H71ZM123 649Q147 625 214 555T335 430T389 374L654 649H123ZM95 70Q99 74 229 209T360 345L95 619V70ZM682 70V619L418 346Q417 344 549 207L682 70ZM654 41L400 304L388 315L123 41L256 40H522L654 41',
    0x22A1: '71 0Q59 4 55 16V346L56 676Q64 686 70 689H709Q719 681 722 674V15Q719 10 709 1L390 0H71ZM682 40V649H95V40H682ZM330 345Q330 371 347 388T390 405Q412 405 430 389T448 345Q448 317 430 301T389 285T348 301T330 345',
    0x22A2: '55 678Q55 679 56 681T58 684T61 688T65 691T70 693T77 694Q88 692 95 679V367H540Q555 359 555 347Q555 334 540 327H95V15Q88 2 77 0Q73 0 70 1T65 3T61 6T59 9T57 13T55 16V678',
    0x22A3: '515 678Q515 679 516 681T518 684T521 688T525 691T530 693T537 694Q548 692 555 679V15Q548 2 537 0Q533 0 530 1T525 3T521 6T519 9T517 13T515 16V327H71Q70 327 67 329T59 336T55 347T59 358T66 365T71 367H515V678',
    0x22A4: '55 642T55 648T59 659T66 666T71 668H708Q723 660 723 648T708 628H409V15Q402 2 391 0Q387 0 384 1T379 3T375 6T373 9T371 13T369 16V628H71Q70 628 67 630T59 637',
    0x22A5: '369 652Q369 653 370 655T372 658T375 662T379 665T384 667T391 668Q402 666 409 653V40H708Q723 32 723 20T708 0H71Q70 0 67 2T59 9T55 20T59 31T66 38T71 40H369V652',
    0x22A8: '139 -249H137Q125 -249 119 -235V251L120 737Q130 750 139 750Q152 750 159 735V367H796Q811 359 811 347Q811 336 797 328L479 327H161L159 328V172L161 173H797Q798 172 800 171T803 169T805 167T808 164T809 162T810 158T811 153Q811 140 796 133H159V-235Q151 -249 141 -249H139',
    0x22A9: '55 678Q55 679 56 681T58 684T61 688T65 691T70 693T77 694Q88 692 95 679V15Q88 2 77 0Q73 0 70 1T65 3T61 6T59 9T57 13T55 16V678ZM249 678Q249 679 250 681T252 684T255 688T259 691T264 693T271 694Q282 692 289 679V367H651Q666 359 666 347Q666 334 651 327H289V15Q282 2 271 0Q267 0 264 1T259 3T255 6T253 9T251 13T249 16V678',
    0x22AA: '55 678Q55 679 56 681T58 684T61 688T65 691T70 693T77 694Q88 692 95 679V15Q88 2 77 0Q73 0 70 1T65 3T61 6T59 9T57 13T55 16V678ZM237 678Q237 679 238 681T240 684T243 688T247 691T252 693T259 694Q270 692 277 679V15Q270 2 259 0Q255 0 252 1T247 3T243 6T241 9T239 13T237 16V678ZM419 678Q419 679 420 681T422 684T425 688T429 691T434 693T441 694Q452 692 459 679V367H818Q833 359 833 347Q833 334 818 327H459V15Q452 2 441 0Q437 0 434 1T429 3T425 6T423 9T421 13T419 16V678',
    0x22AC: '56 681Q70 695 76 695T96 681V368H243L381 530Q521 692 525 692Q537 700 547 688Q554 682 554 674Q554 671 553 669T548 661T539 649T522 631T499 604T465 565T421 512Q296 373 296 368H416H476Q525 368 539 365T554 348Q554 334 543 328H261L96 141V12Q81 -1 75 -1Q65 -1 58 10L56 50V92L18 48Q7 37 -1 28T-13 14T-19 6T-23 1T-27 0T-33 -1Q-42 -1 -48 4T-55 19Q-55 24 -47 34T12 103L56 155V681ZM205 326Q205 328 152 328H96V263Q96 203 98 203Q99 203 123 231T174 290T205 326',
    0x22AD: '56 681Q70 695 76 695T96 681V466H327L425 579Q522 692 527 692Q529 693 534 693Q542 693 547 688T553 674Q553 668 549 663Q549 662 538 650T504 611T463 563L381 468L461 466H543Q554 453 554 446T541 426H345L209 272L376 270H543Q554 257 554 251T541 230H174L96 141V12Q81 -1 75 -1Q65 -1 58 10L56 50V92L18 48Q7 37 -1 28T-13 14T-19 6T-23 1T-27 0T-33 -1Q-42 -1 -48 4T-55 19Q-55 24 -47 34T12 103L56 155V681ZM267 399L292 426H96V270H158L201 321Q256 382 267 399ZM118 228L119 229Q119 230 109 230H96V201L107 212Q118 227 118 228',
    0x22AE: '56 681Q70 695 77 695T96 683V428L98 175L252 323V681Q264 695 272 695Q278 695 292 681V526Q292 368 296 368Q298 368 447 510Q638 695 642 695H645Q651 695 658 688T665 673Q665 666 661 661Q659 660 639 641T578 582T505 512L356 370L505 368H654Q665 357 665 349Q665 343 652 328H314L303 317L292 308V12Q289 10 285 6T279 1T272 -1Q265 -1 252 12V139Q252 266 249 266L96 119V12Q80 -1 76 -1T70 0T63 6T56 12V79L29 55Q-26 -1 -35 -1Q-42 -1 -48 5T-55 19Q-55 25 -51 30T-15 66Q5 86 18 99L56 135V681',
    0x22AF: '56 681Q70 695 77 695T96 683V428L98 175L252 323V681Q264 695 272 695Q278 695 292 681V466H401L503 563L621 679Q637 695 645 695Q652 695 658 688T665 673Q665 670 663 666Q663 665 651 652T611 612T561 563L458 468L556 466H654Q665 455 665 447T652 426H416L294 308L292 288V270H652Q665 255 665 250T652 230H292V12Q289 10 285 6T279 1T272 -1Q265 -1 252 12V139Q252 266 249 266L96 119V12Q80 -1 76 -1T70 0T63 6T56 12V79L29 55Q-26 -1 -35 -1Q-42 -1 -48 5T-55 19Q-55 25 -51 30T-15 66Q5 86 18 99L56 135V681ZM358 426H292V361L325 392L358 426',
    0x22B2: '694 -26Q686 -40 676 -41H670L382 95Q92 232 90 234Q83 239 83 249Q83 262 96 267Q101 270 379 401T665 537Q671 539 674 539Q686 539 694 524V-26ZM654 11T654 249T653 487T402 369T151 249L275 190Q399 131 524 72T652 11Q654 11 654 249',
    0x22B3: '83 523Q83 524 85 527T92 535T103 539Q107 539 389 406T680 268Q694 260 694 249Q694 239 687 234Q685 232 395 95L107 -41H101Q90 -40 83 -26V523ZM376 368Q323 393 254 425T155 472L125 487Q123 487 123 249T125 11Q127 12 252 71T502 190L626 249L376 368',
    0x22B4: '694 71Q686 58 676 56H670L382 192Q92 329 90 331Q83 336 83 346Q83 359 96 364Q101 367 379 498T665 634Q671 636 674 636Q686 636 694 621V71ZM654 108T654 346T653 584T402 466T151 346L275 287Q399 228 524 169T652 108Q654 108 654 346ZM83 -120Q83 -116 84 -113T86 -108T89 -104T92 -102T96 -100T99 -98H678Q679 -98 681 -99T684 -101T688 -104T691 -108T693 -113T694 -120Q692 -130 679 -138H98Q84 -130 83 -120',
    0x22B5: '83 620Q83 621 85 624T92 632T103 636Q107 636 389 503T680 365Q694 357 694 346Q694 336 687 331Q685 329 395 192L107 56H101Q90 58 83 71V620ZM376 465Q323 490 254 522T155 570L125 584Q123 584 123 346T125 108Q127 109 252 168T502 287L626 346L376 465ZM83 -120Q83 -116 84 -113T86 -108T89 -104T92 -102T96 -100T99 -98H678Q679 -98 681 -99T684 -101T688 -104T691 -108T693 -113T694 -120Q692 -130 679 -138H98Q84 -130 83 -120',
    0x22B8: '1055 250Q1055 190 1012 141T896 92Q858 92 828 106T781 140T755 180T741 214L738 228V230H405Q71 230 68 232Q55 238 55 250T68 268Q71 270 405 270H738V272L740 280Q742 287 745 297T754 321T771 348T796 374T832 396T881 408H891Q969 408 1012 360T1055 250ZM896 132Q948 132 981 166T1014 250Q1014 301 985 330T920 367Q914 368 891 368Q853 368 816 338T778 250Q778 198 812 165T896 132',
    0x22BA: '318 -182Q302 -212 280 -212H278H275Q249 -212 239 -182L238 84V351H162L87 352Q57 362 57 391T84 429Q89 431 280 431H470L474 429Q477 427 479 426T484 423T490 417T495 410T499 402T500 391Q500 365 470 352L394 351H318V-182',
    0x22BB: '56 697Q56 706 62 711T75 716Q86 716 90 709Q91 708 104 680T147 592T199 483L305 261L411 483Q443 548 481 629Q512 694 518 705T535 716Q543 716 549 710T555 700Q555 693 501 577T388 340T325 210Q316 194 305 194Q292 194 285 210Q282 219 224 339T111 574T56 697ZM55 14T55 20T59 31T66 38T71 40H540Q555 32 555 20T540 0H71Q70 0 67 2T59 9',
    0x22BC: '55 698Q56 708 70 716H540Q554 708 555 698Q555 694 554 691T552 686T549 682T546 680T542 678T539 676H71Q70 676 68 677T65 679T61 682T58 686T56 691T55 698ZM555 18Q554 12 549 6T536 0H535Q525 0 515 17T459 132Q430 194 410 235L305 455L199 233Q176 185 147 125T105 36T90 7Q85 0 75 0Q63 0 58 11Q55 15 55 21Q58 31 170 266T285 507Q295 522 305 522T320 515Q322 513 439 268L555 24V18',
    0x22C0: '119 -249T97 -249T65 -235T55 -207Q55 -201 56 -198Q58 -190 218 268T380 729Q392 750 416 750Q438 750 451 732Q453 728 534 498T695 36L775 -194Q777 -204 777 -208Q777 -222 767 -235T735 -249Q713 -249 700 -231Q696 -225 557 177L416 579L276 177Q136 -226 132 -231Q119 -249 97 -249',
    0x22C1: '55 708Q55 729 68 739T96 750Q119 750 132 731Q136 726 276 323L416 -79L557 323Q696 725 700 731Q713 749 735 749Q756 749 766 736T777 708Q777 700 696 466T533 1T451 -232Q436 -249 416 -249Q402 -249 391 -241Q384 -236 380 -226Q368 -198 219 230Q55 697 55 708',
    0x22C2: '139 -217Q127 -241 114 -246Q106 -249 97 -249Q67 -249 57 -220Q55 -214 55 102Q55 152 55 221T54 312Q54 422 60 464T91 554Q120 612 165 654T257 714T337 741T392 749Q393 750 402 750Q414 750 422 749Q557 749 660 659T776 430Q777 422 777 102Q777 -214 775 -220Q765 -249 735 -249Q716 -249 708 -241T694 -217L692 428L690 441Q674 540 597 603T416 666H409Q388 666 364 662T294 638T212 581Q156 523 142 441L140 428L139 105V-217',
    0x22C3: '96 750Q103 750 109 748T120 744T127 737T133 730T137 723T139 718V395L140 73L142 60Q159 -43 237 -104T416 -166Q521 -166 597 -103T690 60L692 73L694 718Q708 749 735 749Q765 749 775 720Q777 714 777 398Q777 78 776 71Q766 -51 680 -140Q571 -249 416 -249H411Q261 -249 152 -140Q66 -51 56 71Q55 78 55 398Q55 714 57 720Q60 734 70 740Q80 750 96 750',
    0x22C4: '242 486Q245 488 250 488Q256 488 258 486Q262 484 373 373T486 258T488 250T486 242T373 127T258 14Q256 12 250 12Q245 12 242 14Q237 16 127 126T14 242Q12 245 12 250T14 258Q16 263 126 373T242 486ZM439 250L250 439L61 250L250 61L439 250',
    0x22C5: '78 250Q78 274 95 292T138 310Q162 310 180 294T199 251Q199 226 182 208T139 190T96 207T78 250',
    0x22C6: '210 282Q210 284 225 381T241 480Q241 484 245 484Q249 486 251 486Q258 486 260 477T272 406Q275 390 276 380Q290 286 290 282L388 299Q484 314 487 314H488Q497 314 497 302Q497 297 434 266Q416 257 404 251L315 206L361 118Q372 98 383 75T401 40L407 28Q407 16 395 16Q394 16 392 16L390 17L250 159L110 17L108 16Q106 16 105 16Q93 16 93 28L99 40Q105 52 116 75T139 118L185 206L96 251Q6 296 4 300Q3 301 3 302Q3 314 12 314H13Q16 314 112 299L210 282',
    0x22C7: '366 543Q374 545 382 545Q405 545 419 538Q429 534 443 521T462 496Q466 478 466 467Q466 438 444 412Q422 390 388 390Q352 390 331 412Q311 434 311 467Q311 499 331 518Q345 533 366 543ZM146 472Q146 479 153 485T166 492Q171 492 187 476T279 385L386 278L495 385Q600 492 608 492Q615 492 621 486T628 472Q628 467 614 452T531 367L435 270H706Q720 256 720 250Q720 241 706 230H435L531 132Q600 63 614 48T628 27Q628 20 622 14T608 7Q600 7 495 114L386 221L279 114Q204 39 188 23T166 7Q159 7 153 13T146 27Q146 32 160 47T244 132L339 230H68Q55 243 55 250Q55 255 68 270H339L244 367Q175 436 161 451T146 472ZM466 34Q466 4 447 -20T388 -44Q353 -44 331 -22Q311 1 311 34Q311 66 331 85Q347 101 366 110Q374 112 382 112Q405 112 419 105Q429 100 443 87T462 63Q466 45 466 34',
    0x22C8: '833 50T833 250T832 450T659 351T487 250T658 150T832 50Q833 50 833 250ZM873 10Q866 -5 854 -5Q851 -5 845 -3L449 226L260 115Q51 -5 43 -5Q39 -5 35 -1T28 7L26 11V489Q33 505 43 505Q51 505 260 385L449 274L845 503Q851 505 853 505Q866 505 873 490V10ZM412 250L67 450Q66 450 66 250T67 50Q69 51 240 150T412 250',
    0x22C9: '146 479Q159 492 166 492Q171 492 189 475T279 386L386 279L495 386Q598 492 608 492Q615 492 621 486T628 472Q628 464 522 357L415 250L522 144Q628 37 628 28Q628 21 622 15T608 8Q599 8 495 115L386 221L279 115Q204 40 188 24T166 8Q162 8 160 9T153 15T146 21V479ZM186 77L359 250L186 424V77',
    0x22CA: '146 472Q146 479 152 485T166 492Q171 492 189 475T279 386L386 279L495 386Q598 492 608 492Q615 492 628 479V21Q615 8 608 8Q599 8 495 115L386 221L279 115Q204 40 188 24T166 8Q159 8 153 14T146 28Q146 37 253 144L359 250L253 357Q146 464 146 472ZM588 77V424L499 337L415 250L588 77',
    0x22CB: '55 674Q55 682 62 688T76 694H77Q83 694 100 677T208 561Q320 440 410 342Q462 286 541 201Q677 55 699 30T722 -2Q722 -9 716 -15T701 -22T688 -17Q687 -15 542 141T394 301L388 306L240 146Q119 15 101 -3T75 -22T61 -16T55 -2Q55 4 67 19T158 117Q190 151 209 172L361 336L209 500Q62 657 57 667Q55 671 55 674',
    0x22CC: '84 -22T76 -22T62 -16T55 -2Q55 4 78 30T249 215Q321 293 367 342Q672 672 683 682Q695 694 702 694Q710 694 716 688T722 674Q722 668 710 653T619 555Q587 521 568 500L416 336L568 172Q715 15 720 5Q722 1 722 -2Q722 -9 716 -15T702 -22H700Q693 -22 671 1T537 146L389 306Q387 304 340 253T237 143T135 33L89 -17Q84 -22 76 -22',
    0x22CD: '56 433Q56 464 71 464Q74 464 77 461Q82 454 82 438T91 397T123 347Q166 307 222 307Q264 307 308 331T386 385T465 438T556 463Q631 463 676 408T722 283Q722 250 708 250Q704 250 699 257Q695 265 693 286T682 330Q670 350 655 367Q612 407 556 407Q514 407 470 383T393 329T314 276T222 251Q148 251 102 306T56 433ZM57 56Q57 71 73 76H706Q722 70 722 56Q722 44 707 36H71Q57 43 57 56',
    0x22CE: '83 558Q83 566 89 572T104 578Q108 578 116 577T146 570T190 555T239 526T286 480Q308 453 325 420T351 358T367 304T376 265T380 251T381 253Q381 262 395 312Q428 434 492 499T642 576Q654 578 655 578Q664 578 670 572T676 558Q676 543 657 540T599 524T525 476Q406 362 400 29V8Q400 -21 380 -21Q369 -21 362 -11Q360 -7 360 12Q360 115 348 200T308 360T231 480T111 537Q83 540 83 558',
    0x22CF: '104 -22Q95 -22 89 -16T83 -2Q83 11 98 16T135 23T192 46T256 103Q360 233 360 549Q360 554 360 557T361 563T362 567T364 569T367 572T371 576Q377 578 380 578Q401 578 401 547Q401 543 401 537T400 527Q409 53 648 19Q676 16 676 -2Q676 -10 670 -16T655 -22Q654 -22 642 -20Q556 -9 492 56T395 244Q381 294 381 303Q381 305 380 305T374 275T352 201T310 110T234 27T117 -20Q105 -22 104 -22',
    0x22D0: '84 250Q84 372 166 450T360 539Q361 539 370 539T395 539T430 540T475 540T524 540H679Q694 532 694 520Q694 511 681 501L522 500H470H441Q366 500 338 496T266 472Q244 461 224 446T179 404T139 337T124 250V245Q124 157 185 89Q244 25 328 7Q348 2 366 2T522 0H681Q694 -10 694 -20Q694 -32 679 -40H526Q510 -40 480 -40T434 -41Q350 -41 289 -25T172 45Q84 127 84 250ZM694 134Q694 123 679 114H425H384Q350 114 326 121T277 154Q238 193 238 251Q238 322 295 361Q318 378 339 382T412 387Q423 387 459 387T520 386H679Q694 377 694 366Q694 354 679 346H519Q493 346 458 346T411 347Q360 347 341 342T303 315Q278 287 278 250Q278 210 301 187T351 156Q358 154 519 154H679Q694 146 694 134',
    0x22D1: '83 520Q83 532 98 540H251Q267 540 297 540T343 541Q427 541 488 525T605 455Q693 374 693 250Q693 165 650 99T545 0T415 -39Q407 -40 251 -40H98Q83 -32 83 -20Q83 -10 96 0H255H308H337Q412 0 439 4T512 28Q533 39 553 54T599 96T639 163T654 250Q654 341 592 411Q557 449 512 472Q468 491 439 495T335 500H306H255L96 501Q83 511 83 520ZM83 366Q83 376 96 386H244Q280 386 317 386T378 386L402 387Q456 387 498 348T540 250Q540 203 512 168T446 120Q427 114 353 114H99Q84 120 84 134Q84 147 98 154H258Q284 154 319 154T366 153Q416 153 436 158T474 185Q500 214 500 250Q500 290 477 313T426 344Q419 346 258 346H98Q83 354 83 366',
    0x22D2: '88 -21T75 -21T55 -7V200Q55 231 55 280Q56 414 60 428Q61 430 61 431Q77 500 152 549T332 598Q443 598 522 544T610 405Q611 399 611 194V-7Q604 -22 591 -22Q582 -22 572 -9L570 405Q563 433 556 449T529 485Q498 519 445 538T334 558Q251 558 179 518T96 401Q95 396 95 193V-7Q88 -21 75 -21ZM229 -21H227Q215 -21 209 -7V166Q209 304 209 327T215 363Q226 398 259 421T333 444Q380 444 414 416T455 347Q457 339 457 166V-7Q449 -21 439 -21H437H435Q423 -21 417 -7V164Q417 303 417 325T411 358Q387 403 333 403T255 358Q250 347 250 325T249 164V-7Q241 -21 231 -21H229',
    0x22D3: '591 598H592Q604 598 611 583V376Q611 345 611 296Q610 162 606 148Q605 146 605 145Q586 68 507 23T333 -22Q268 -22 209 -1T106 66T56 173Q55 180 55 384L56 585Q66 598 75 598Q85 598 95 585V378L96 172L98 162Q112 95 181 57T332 18Q415 18 487 58T570 175Q571 180 571 383V583Q579 598 591 598ZM437 598Q450 598 457 583V410Q457 237 455 229Q448 189 414 161T333 132Q291 132 255 157T211 230Q209 237 209 412L210 585Q220 598 229 598Q242 598 249 583V412Q249 273 249 251T255 218Q279 173 333 173T411 218Q416 229 416 251T417 412V583Q425 598 437 598',
    0x22D4: '76 -22Q64 -22 56 -7V176L57 360L59 370Q66 401 83 426T123 468T171 495T221 513T265 522T298 527L311 528H314V625L315 723Q325 736 334 736Q346 736 354 721V528H356L368 527Q380 526 399 523T441 515T490 498T537 472T578 433T606 379Q611 359 611 171V-7Q604 -21 591 -21T571 -7V170Q571 313 571 337T565 375Q555 408 526 432T461 467T402 482T365 487H354V-7Q347 -21 334 -21T314 -7V487H303Q251 484 207 467Q121 438 99 367L97 357L96 174V-9Q86 -22 76 -22',
    0x22D6: '86 261Q92 267 381 404T673 541Q680 541 686 535T693 521T689 510Q684 504 418 379L151 250L418 121Q686 -4 689 -10Q693 -14 693 -21T687 -34T675 -41Q668 -41 380 96T86 239Q82 244 82 250Q82 257 86 261ZM610 250Q610 224 592 198T531 172Q498 172 475 195Q453 214 453 250Q453 308 513 328Q515 330 535 330Q569 328 589 304T610 250',
    0x22D7: '82 521Q82 529 89 535T100 541Q107 541 395 404T689 261Q693 257 693 250T689 239Q684 234 396 97T100 -41Q95 -41 89 -35T82 -21Q82 -12 96 -4Q118 9 358 121L624 250L358 379Q91 503 86 510Q82 514 82 521ZM165 250Q165 282 188 306T239 330Q262 330 275 323Q303 312 318 283Q322 272 322 250Q322 213 300 195Q277 172 246 172Q224 172 213 177Q165 200 165 250',
    0x22D8: '639 -48Q639 -54 634 -60T619 -67H618Q612 -67 536 -26Q430 33 329 88Q61 235 59 239Q56 243 56 250T59 261Q62 266 336 415T615 567L619 568Q622 567 625 567Q639 562 639 548Q639 540 633 534Q632 532 374 391L117 250L374 109Q632 -32 633 -34Q639 -40 639 -48ZM958 -48Q958 -54 953 -60T938 -67H937Q931 -67 855 -26Q749 33 648 88Q380 235 378 239Q375 243 375 250T378 261Q381 266 655 415T934 567L938 568Q941 567 944 567Q958 562 958 548Q958 540 952 534Q951 532 693 391L436 250L693 109Q951 -32 952 -34Q958 -40 958 -48ZM1277 -48Q1277 -54 1272 -60T1257 -67H1256Q1250 -67 1174 -26Q1068 33 967 88Q699 235 697 239Q694 243 694 250T697 261Q700 266 974 415T1253 567L1257 568Q1260 567 1263 567Q1277 562 1277 548Q1277 540 1271 534Q1270 532 1012 391L755 250L1012 109Q1270 -32 1271 -34Q1277 -40 1277 -48',
    0x22D9: '75 -67Q65 -67 60 -61T55 -48Q55 -40 61 -34Q62 -32 329 109L595 250L329 391Q62 532 61 534Q55 540 55 548Q55 562 69 567H77Q81 567 222 493T506 342T653 264Q667 250 653 236Q649 234 504 157T220 7T77 -67H75ZM364 547Q364 563 381 567L384 568Q387 568 518 499T795 353T955 269Q967 261 967 250T955 231Q925 216 780 139T513 -3T383 -67Q373 -67 369 -60T364 -47Q364 -40 370 -34Q373 -31 639 109L904 250L639 391Q373 531 370 534Q364 540 364 547ZM674 538T674 548T681 562T693 567Q699 567 816 505Q915 453 993 412Q1050 382 1132 339Q1241 282 1259 271T1277 250Q1277 241 1263 232Q1246 221 985 84Q698 -67 692 -67Q674 -67 674 -47Q674 -38 680 -33Q683 -30 947 109L1213 250L947 391Q683 530 680 533Q674 538 674 548',
    0x22DA: '674 445Q674 438 669 432T655 425T369 531T90 640Q83 645 83 655Q83 668 95 673Q644 886 654 886Q662 886 668 880T674 866Q674 856 663 850Q649 843 411 751L160 655L407 560Q474 534 561 501Q646 469 660 462T674 445ZM84 250Q84 260 99 270H658Q674 264 674 250Q674 238 659 230H98Q84 237 84 250ZM83 55Q83 68 94 73Q98 76 104 76Q108 75 383 -30T664 -138Q674 -144 674 -155Q674 -165 667 -170Q664 -173 385 -279T104 -386Q85 -386 83 -368Q83 -354 92 -349Q93 -349 347 -251L597 -155L346 -59Q296 -40 223 -12Q118 28 101 36T83 55',
    0x22DB: '111 425T102 425T88 431T83 445V446Q83 455 96 461Q111 469 203 504Q287 536 350 560L597 655L346 751Q94 848 92 850Q83 856 83 866Q83 873 88 879T104 886Q109 885 386 779T667 670Q674 665 674 655T667 640Q665 638 388 532ZM84 250Q84 260 99 270H658Q674 264 674 250Q674 238 659 230H98Q84 237 84 250ZM653 76Q656 76 660 75T669 68T674 56Q674 46 665 40Q663 38 411 -59L160 -155L410 -251Q664 -349 665 -349Q674 -354 674 -368Q672 -386 654 -386Q650 -386 371 -279T90 -170Q83 -165 83 -155Q83 -144 93 -138Q645 76 653 76',
    0x22DE: '113 424Q83 424 83 444Q83 453 96 464H121Q181 466 234 474T341 501T435 545T505 613T542 707Q545 734 564 734Q583 731 583 714Q583 658 560 613T500 538T414 486T321 453T229 434T156 426T113 424ZM112 270Q83 270 83 290Q83 301 94 307Q98 310 118 310Q624 310 653 556Q657 580 675 580Q693 577 693 559V552Q684 472 628 410T465 314Q436 303 372 290Q373 290 388 287T425 278T465 266Q674 199 693 28L694 17L692 14Q691 11 689 8T683 3T673 0Q657 0 653 24Q623 270 118 270H112',
    0x22DF: '195 713Q195 725 201 729T214 734Q227 734 231 722T238 691T255 641T299 580Q405 474 656 464H681Q694 451 694 443Q694 424 670 424H664Q535 424 415 465T235 595Q195 657 195 713ZM668 310Q694 310 694 290Q694 285 691 279Q684 271 664 270Q550 268 464 257T301 220T179 146T124 27Q119 0 103 0T83 16Q83 21 83 31T92 68T113 121T157 177T229 231Q295 268 405 290Q404 290 389 293T352 302T312 314Q138 371 96 500Q83 541 83 562Q83 568 89 574T103 580Q115 580 120 570T126 542T138 497T173 442Q289 310 659 310H668',
    0x22E0: '82 344Q82 349 95 364H124Q266 364 398 390L429 397L509 595Q519 619 536 659Q581 766 590 783T609 801Q616 801 622 795T629 781Q629 776 553 595Q533 548 516 506T489 439T480 415Q482 415 505 426T538 444Q632 498 651 601Q654 621 658 628T673 635Q680 635 686 629T693 615Q693 591 678 546Q636 433 484 375L458 364L451 348Q443 332 443 329T455 324Q480 316 503 307T560 277T619 233T664 170T691 86Q693 68 691 64Q684 53 672 53Q664 53 658 59Q657 60 650 97T617 174T538 244Q515 257 476 273T428 289Q425 289 412 256Q381 179 344 90L262 -103H680Q682 -105 684 -108T688 -113T691 -118T693 -124Q693 -134 682 -141L464 -143H246L213 -219Q182 -292 178 -299Q172 -303 166 -303T153 -297T146 -283Q146 -282 174 -213T202 -143H95Q82 -128 82 -123T95 -103H220L302 97Q384 288 384 299Q384 302 341 308T235 319T124 324H95Q82 337 82 344ZM399 338Q403 338 406 346L409 353L375 344Q375 343 384 341T399 338',
    0x22E1: '146 -283Q146 -282 174 -213T202 -143H95Q82 -127 82 -123T95 -103H220L300 93Q343 196 374 270Q385 294 386 299L373 295Q331 287 289 268Q241 249 208 224T159 174T135 127T124 85T118 59Q112 53 103 53Q91 53 84 64Q82 68 84 86Q96 185 174 248T375 337L400 344Q399 344 381 348T351 355T316 364T276 379T235 398T193 424T155 456T122 497T98 546Q82 587 82 615Q82 622 88 628T102 635Q112 635 116 628T124 601Q128 579 134 562T159 515T207 463T290 418T415 384L422 381L506 586Q571 744 584 772T609 801Q616 801 622 795T629 781T544 577Q525 529 504 478T473 402T462 375Q480 373 500 373Q579 364 651 364H680Q682 361 686 357T691 351T693 344Q693 337 680 324H651Q553 324 451 310L433 308L349 104L262 -101L473 -103H682Q694 -115 694 -123Q694 -133 682 -141L464 -143H246L213 -219Q182 -292 178 -299Q172 -303 166 -303T153 -297T146 -283',
    0x22E6: '86 450Q93 455 380 592T673 730Q680 730 686 724T693 710Q693 702 688 699Q686 693 417 568L151 439L417 310Q685 185 688 179Q693 176 693 168Q693 161 687 155T675 148Q668 148 380 285T86 428Q74 438 86 450ZM55 -205Q55 -175 64 -142T92 -76T145 -22T222 -1Q288 -1 362 -66Q369 -72 372 -75T378 -79T382 -81T384 -79Q389 -74 439 21Q483 100 490 111T504 122Q510 122 518 118T526 103Q526 101 510 69T467 -12T419 -99L413 -112L433 -128Q498 -180 553 -180Q605 -180 646 -139Q672 -112 681 -77T693 -21T706 -1Q719 -1 719 -33Q719 -39 717 -57Q708 -141 655 -190Q625 -224 586 -232Q568 -237 551 -237Q487 -237 413 -172L391 -155Q391 -157 335 -255Q297 -325 286 -342T268 -359Q260 -359 254 -353T248 -339T304 -230L359 -126Q359 -124 337 -107T302 -81Q262 -57 221 -57Q170 -57 130 -93T84 -201Q82 -236 70 -236Q55 -236 55 -205',
    0x22E7: '88 723Q95 730 99 730Q106 730 394 593T688 450Q693 447 693 439T688 428Q683 423 395 286T99 148Q94 148 88 155T82 168Q82 175 86 179Q89 184 357 310L624 439L357 568Q88 694 86 699Q81 703 81 711T88 723ZM55 -205Q55 -175 64 -142T92 -76T145 -22T222 -1Q288 -1 362 -66Q369 -72 372 -75T378 -79T382 -81T384 -79Q389 -74 439 21Q483 100 490 111T504 122Q510 122 518 118T526 103Q526 101 510 69T467 -12T419 -99L413 -112L433 -128Q498 -180 553 -180Q605 -180 646 -139Q672 -112 681 -77T693 -21T706 -1Q719 -1 719 -33Q719 -39 717 -57Q708 -141 655 -190Q625 -224 586 -232Q568 -237 551 -237Q487 -237 413 -172L391 -155Q391 -157 335 -255Q297 -325 286 -342T268 -359Q260 -359 254 -353T248 -339T304 -230L359 -126Q359 -124 337 -107T302 -81Q262 -57 221 -57Q170 -57 130 -93T84 -201Q82 -236 70 -236Q55 -236 55 -205',
    0x22E8: '95 419Q81 433 81 439T95 459H124Q318 459 455 501Q515 521 556 550T615 607T641 659T652 702T659 725Q667 730 673 730Q680 730 686 724T693 710Q693 682 677 641Q668 616 654 594T622 554T586 522T545 497T504 477T464 462T428 452T397 444T375 439Q379 437 410 430T476 411T551 379T625 321T677 237Q693 196 693 168Q693 161 687 155T673 148Q662 148 658 154T651 181Q638 253 591 300T455 377Q318 419 124 419H95ZM55 -205Q55 -175 64 -142T92 -76T145 -22T222 -1Q288 -1 362 -66Q369 -72 372 -75T378 -79T382 -81T384 -79Q389 -74 439 21Q483 100 490 111T504 122Q510 122 518 118T526 103Q526 101 510 69T467 -12T419 -99L413 -112L433 -128Q498 -180 553 -180Q605 -180 646 -139Q672 -112 681 -77T693 -21T706 -1Q719 -1 719 -33Q719 -39 717 -57Q708 -141 655 -190Q625 -224 586 -232Q568 -237 551 -237Q487 -237 413 -172L391 -155Q391 -157 335 -255Q297 -325 286 -342T268 -359Q260 -359 254 -353T248 -339T304 -230L359 -126Q359 -124 337 -107T302 -81Q262 -57 221 -57Q170 -57 130 -93T84 -201Q82 -236 70 -236Q55 -236 55 -205',
    0x22E9: '679 459Q693 445 693 439Q693 430 679 419H651Q455 419 319 377Q231 347 184 300T124 181Q120 161 116 155T102 148Q95 148 89 154T82 168Q82 192 97 237Q111 275 137 306T188 355T249 391T307 414T361 429T399 439Q397 440 364 447T298 467T224 499T149 557T97 641Q82 686 82 710Q82 717 88 723T102 730L115 725Q118 722 124 697Q137 625 184 578T319 501Q456 459 651 459H679ZM55 -205Q55 -175 64 -142T92 -76T145 -22T222 -1Q288 -1 362 -66Q369 -72 372 -75T378 -79T382 -81T384 -79Q389 -74 439 21Q483 100 490 111T504 122Q510 122 518 118T526 103Q526 101 510 69T467 -12T419 -99L413 -112L433 -128Q498 -180 553 -180Q605 -180 646 -139Q672 -112 681 -77T693 -21T706 -1Q719 -1 719 -33Q719 -39 717 -57Q708 -141 655 -190Q625 -224 586 -232Q568 -237 551 -237Q487 -237 413 -172L391 -155Q391 -157 335 -255Q297 -325 286 -342T268 -359Q260 -359 254 -353T248 -339T304 -230L359 -126Q359 -124 337 -107T302 -81Q262 -57 221 -57Q170 -57 130 -93T84 -201Q82 -236 70 -236Q55 -236 55 -205',
    0x22EA: '693 -30Q686 -41 673 -41Q661 -41 506 34L346 110L280 -44Q228 -162 216 -185T193 -208Q177 -208 173 -192Q173 -186 242 -30T311 128Q271 145 184 186T86 236Q82 240 82 246Q82 251 86 259Q96 267 271 350L449 434L506 565Q537 635 551 664T571 700T582 706Q587 706 593 701T600 690Q600 679 553 572Q504 463 504 461L586 501Q672 539 673 539Q679 539 693 525V-30ZM653 10V488L566 445L480 405L422 276Q415 260 405 236T388 199T376 171T368 151T366 145Q368 143 510 77T653 10ZM422 374Q422 376 420 376T285 313T151 248Q315 168 326 163Q415 356 422 374',
    0x22EB: '82 525Q96 539 102 539Q103 539 122 530T186 501T266 463L426 388Q428 388 495 541Q564 694 569 699Q573 706 581 706Q587 706 593 702T600 691Q600 676 533 528Q515 486 506 465T485 418T470 381T466 370Q466 369 575 316Q676 269 689 259Q693 253 693 248Q693 242 689 236Q688 235 506 145Q328 63 324 59Q324 50 266 -70Q224 -169 214 -188T193 -208Q177 -208 173 -192Q173 -183 222 -77Q244 -29 257 2T269 34L186 -6Q108 -43 99 -43Q93 -43 82 -30V525ZM271 416Q129 485 126 485H125Q122 485 122 250Q122 10 124 10L211 50L295 92L411 350Q411 351 271 416ZM624 248L449 332L440 319Q434 297 393 214Q353 121 353 119Q355 119 489 182T624 248',
    0x22EC: '82 -123Q82 -114 93 -103H166L238 -101L293 50Q349 200 349 204L220 266Q166 291 140 304T100 325T84 336T82 344Q82 353 94 360Q112 372 282 453L473 541L482 568Q487 578 529 693Q559 785 569 795Q573 802 581 802Q587 802 593 797T599 786Q599 775 564 675L526 570Q526 568 561 584T633 617T673 635Q679 635 693 621V66Q686 54 679 54Q665 54 526 119Q491 137 458 153T405 177T386 184Q385 182 334 42T282 -101T482 -103H680Q682 -105 684 -108T688 -113T691 -118T693 -124Q693 -134 682 -141L473 -143H266L238 -219Q217 -278 210 -290T193 -303Q178 -303 173 -287Q173 -279 198 -214L222 -145Q222 -143 158 -143L93 -141L86 -136Q82 -131 82 -123ZM653 106V584L506 513L453 370Q442 339 428 300T407 243T400 224Q403 222 527 164T653 106ZM453 486Q453 488 451 488T300 417T151 344L224 308Q247 298 285 279T331 257L364 241L453 486',
    0x22ED: '82 621Q96 635 102 635T249 568L420 486L449 473L469 533Q563 789 569 797Q573 801 581 801Q598 801 600 786Q602 781 544 617L484 455Q531 435 584 408Q677 364 689 355Q693 351 693 344Q693 339 692 337T676 325T631 302T538 257Q504 241 465 223T406 195T386 186Q384 184 333 44T282 -101Q282 -103 482 -103H680Q682 -105 684 -108T688 -113T691 -118T693 -124Q693 -134 682 -141L473 -143H266L238 -219Q217 -278 210 -290T193 -303Q178 -303 173 -287Q173 -279 198 -214L222 -145Q222 -143 158 -143L93 -141L86 -136Q82 -131 82 -123Q82 -114 93 -103H166L238 -101L333 159Q326 159 220 106Q110 54 97 54Q89 54 82 66V621ZM298 501Q155 567 142 575L122 584V344Q122 106 124 106Q125 106 180 132T291 185T351 213Q355 217 393 326L433 435Q433 436 298 501ZM549 381Q472 417 471 417L406 241Q408 240 516 291T624 344L549 381',
    0x22EE: '78 30Q78 54 95 72T138 90Q162 90 180 74T199 31Q199 6 182 -12T139 -30T96 -13T78 30ZM78 440Q78 464 95 482T138 500Q162 500 180 484T199 441Q199 416 182 398T139 380T96 397T78 440ZM78 840Q78 864 95 882T138 900Q162 900 180 884T199 841Q199 816 182 798T139 780T96 797T78 840',
    0x22EF: '78 250Q78 274 95 292T138 310Q162 310 180 294T199 251Q199 226 182 208T139 190T96 207T78 250ZM525 250Q525 274 542 292T585 310Q609 310 627 294T646 251Q646 226 629 208T586 190T543 207T525 250ZM972 250Q972 274 989 292T1032 310Q1056 310 1074 294T1093 251Q1093 226 1076 208T1033 190T990 207T972 250',
    0x22F1: '133 760Q133 784 150 802T193 820Q217 820 235 804T254 761Q254 736 237 718T194 700T151 717T133 760ZM580 460Q580 484 597 502T640 520Q664 520 682 504T701 461Q701 436 684 418T641 400T598 417T580 460ZM1027 160Q1027 184 1044 202T1087 220Q1111 220 1129 204T1148 161Q1148 136 1131 118T1088 100T1045 117T1027 160',
    0x2305: '55 698Q56 708 70 716H540Q554 708 555 698Q555 694 554 691T552 686T549 682T546 680T542 678T539 676H71Q70 676 68 677T65 679T61 682T58 686T56 691T55 698ZM555 18Q554 12 549 6T536 0H535Q525 0 515 17T459 132Q430 194 410 235L305 455L199 233Q176 185 147 125T105 36T90 7Q85 0 75 0Q63 0 58 11Q55 15 55 21Q58 31 170 266T285 507Q295 522 305 522T320 515Q322 513 439 268L555 24V18',
    0x2306: '55 795Q56 806 70 813H540Q554 806 555 795Q555 791 554 788T552 783T549 779T546 777T542 775T539 773H71Q70 773 68 774T65 776T61 779T58 783T56 788T55 795ZM55 601Q56 612 70 619H540Q554 612 555 601Q555 597 554 594T552 589T549 585T546 583T542 581T539 579H71Q70 579 68 580T65 582T61 585T58 589T56 594T55 601ZM75 -97Q67 -97 61 -91T55 -81Q55 -71 169 166T285 410Q295 425 305 425Q311 425 316 422T323 414L325 410Q327 404 441 167T555 -81Q555 -85 549 -91T535 -97T520 -90Q519 -89 506 -61T463 27T411 136L305 358L199 136Q167 71 129 -10Q98 -75 92 -86T75 -97',
    0x2308: '174 734Q178 746 190 750H298H369Q400 750 411 747T422 730T411 713T372 709Q365 709 345 709T310 710H214V-235Q206 -248 196 -250Q192 -250 189 -249T184 -247T180 -244T178 -241T176 -237T174 -234V734',
    0x2309: '21 717T21 730T32 746T75 750H147H256Q266 742 269 735V-235Q262 -248 251 -250Q247 -250 244 -249T239 -247T235 -244T233 -241T231 -237T229 -234V710H133Q119 710 99 710T71 709Q43 709 32 713',
    0x230A: '174 734Q174 735 175 737T177 740T180 744T184 747T189 749T196 750Q206 748 214 735V-210H310H373Q401 -210 411 -213T422 -230T411 -247T369 -251Q362 -251 338 -251T298 -250H190Q178 -246 174 -234V734',
    0x230B: '229 734Q229 735 230 737T232 740T235 744T239 747T244 749T251 750Q262 748 269 735V-235Q266 -240 256 -249L147 -250H77Q43 -250 32 -247T21 -230T32 -213T72 -209Q79 -209 99 -209T133 -210H229V734',
    0x231C: '76 306Q62 306 59 319T55 386V500V596Q55 664 57 676T68 692Q71 694 250 694Q428 694 432 692Q444 685 444 674Q444 665 432 656Q428 654 261 654H95V487Q95 355 95 336T90 312Q84 306 76 306',
    0x231D: '424 306Q418 306 413 310T406 318L404 321V654H238Q71 654 68 656Q55 662 55 674T68 692Q71 694 250 694H379Q432 694 438 688Q443 683 443 662T444 500T444 338T438 312Q432 306 424 306',
    0x231E: '55 172V287Q55 341 58 353T76 366Q88 366 95 351V18H261Q428 18 432 16Q444 9 444 -2Q444 -11 432 -20Q428 -22 250 -22H120Q67 -22 61 -16Q56 -11 56 10T55 172',
    0x231F: '404 351Q410 366 424 366Q437 366 440 353T444 288V172V72Q444 8 443 -4T432 -20Q428 -22 250 -22Q71 -22 68 -20Q55 -14 55 -2T68 16Q71 18 238 18H404V351',
    0x2322: '55 141Q55 149 72 174T125 234T209 303T329 360T478 388H526Q649 383 765 319Q814 291 858 250T923 179T944 141Q944 133 938 128T924 122Q914 124 912 125T902 139Q766 328 500 328Q415 328 342 308T225 258T150 199T102 148T84 124Q81 122 75 122Q55 127 55 141',
    0x2323: '923 378Q944 378 944 358Q944 345 912 311T859 259Q710 134 500 134Q288 134 140 259Q55 336 55 358Q55 366 61 372T75 378Q78 378 84 376Q86 376 101 356T147 310T221 257T339 212T500 193Q628 193 734 236Q841 282 903 363Q914 378 923 378',
    0x2329: '333 -232Q332 -239 327 -244T313 -250Q303 -250 296 -240Q293 -233 202 6T110 250T201 494T296 740Q299 745 306 749L309 750Q312 750 313 750Q331 750 333 732Q333 727 243 489Q152 252 152 250T243 11Q333 -227 333 -232',
    0x232A: '55 732Q56 739 61 744T75 750Q85 750 92 740Q95 733 186 494T278 250T187 6T92 -240Q85 -250 75 -250Q67 -250 62 -245T55 -232Q55 -227 145 11Q236 248 236 250T145 489Q55 727 55 732',
    0x23B0: '357 741V726Q357 720 349 715Q261 655 242 539Q240 526 240 454T239 315T239 247Q240 235 240 124V40Q240 -17 233 -53T201 -130Q155 -206 78 -244H69H64Q58 -244 57 -243T56 -234Q56 -232 56 -231V-225Q56 -218 63 -215Q153 -153 170 -39Q172 -25 173 119V219Q173 245 174 249Q173 258 173 376V460Q173 515 178 545T201 611Q244 695 327 741L334 744H354L357 741',
    0x23B1: '78 744Q153 706 196 640T239 492V376Q239 341 239 314T238 271T238 253Q239 251 239 223V119V49Q239 -39 254 -85Q263 -111 275 -134T301 -172T326 -197T346 -213T356 -221T357 -232V-241L354 -244H334Q264 -209 222 -146T174 -12Q173 -6 173 95Q173 134 173 191T174 250Q173 258 173 382V451Q173 542 159 585Q145 626 120 658T75 706T56 723V731Q56 741 57 742T66 744H78',
    0x23D0: '312 0V602H355V0H312',
    0x24C8: '451 -175Q328 -175 226 -115T66 47T8 267Q8 303 15 342T39 431T94 531T186 622Q239 663 307 686T424 709H440Q604 709 716 622Q757 592 788 555T838 482T869 414T886 350T892 301T894 267Q894 147 835 45T674 -116T451 -175ZM854 268Q854 375 802 467T657 614T450 670Q283 670 166 552T49 267Q49 99 167 -18T453 -136Q617 -136 735 -18T854 268ZM273 378Q273 430 309 474T409 527Q411 527 417 527T428 528Q498 528 549 484L567 505Q583 528 590 528H594Q600 528 606 522V350L600 344H586Q577 344 574 344T569 347T566 357Q542 491 432 491Q389 491 365 465T340 407Q340 391 344 378T358 356T377 340T400 328T421 321T443 316T459 313Q499 305 517 300T559 279T601 238Q629 195 629 148Q629 80 583 33T471 -14Q392 -14 330 30Q312 6 293 -13Q292 -14 285 -14Q279 -14 273 -8V77V138Q273 160 275 165T286 170H294H307Q313 164 313 158Q313 108 350 67T471 26Q512 26 537 54T562 119Q562 137 558 151T544 176T527 193T504 205T483 212T459 218T441 222Q391 232 368 241T318 273Q273 316 273 378',
    0x250C: '76 306Q62 306 59 319T55 386V500V596Q55 664 57 676T68 692Q71 694 250 694Q428 694 432 692Q444 685 444 674Q444 665 432 656Q428 654 261 654H95V487Q95 355 95 336T90 312Q84 306 76 306',
    0x2510: '424 306Q418 306 413 310T406 318L404 321V654H238Q71 654 68 656Q55 662 55 674T68 692Q71 694 250 694H379Q432 694 438 688Q443 683 443 662T444 500T444 338T438 312Q432 306 424 306',
    0x2514: '55 172V287Q55 341 58 353T76 366Q88 366 95 351V18H261Q428 18 432 16Q444 9 444 -2Q444 -11 432 -20Q428 -22 250 -22H120Q67 -22 61 -16Q56 -11 56 10T55 172',
    0x2518: '404 351Q410 366 424 366Q437 366 440 353T444 288V172V72Q444 8 443 -4T432 -20Q428 -22 250 -22Q71 -22 68 -20Q55 -14 55 -2T68 16Q71 18 238 18H404V351',
    0x2571: '19 -195Q13 -195 7 -188T0 -176Q0 -169 18 -151L822 683Q835 694 840 694T852 688T860 674Q860 667 810 614T460 252Q57 -167 44 -179Q27 -195 19 -195',
    0x2572: '0 675Q0 681 6 687T19 694Q27 694 44 678L460 247Q759 -62 809 -115T860 -175Q860 -183 852 -189T840 -195Q835 -195 822 -184L18 649Q0 667 0 675',
    0x25A0: '71 0Q59 4 55 16V346L56 676Q64 686 70 689H709Q719 681 722 674V15Q719 10 709 1L390 0H71',
    0x25A1: '71 0Q59 4 55 16V346L56 676Q64 686 70 689H709Q719 681 722 674V15Q719 10 709 1L390 0H71ZM682 40V649H95V40H682',
    0x25AA: '71 0Q59 4 55 16V346L56 676Q64 686 70 689H709Q719 681 722 674V15Q719 10 709 1L390 0H71',
    0x25B2: '99 -20Q84 -11 84 0Q84 5 148 145T278 424L342 563Q347 575 360 575Q368 575 375 570Q376 569 441 430T571 148T637 0Q637 -11 622 -20H99',
    0x25B3: '75 0L72 2Q69 3 67 5T62 11T59 20Q59 24 62 30Q65 37 245 370T428 707Q428 708 430 710T436 714T444 716Q451 716 455 712Q459 710 644 368L828 27V20Q828 7 814 0H75ZM610 347L444 653Q443 653 278 347T113 40H775Q775 42 610 347',
    0x25B4: '99 -20Q84 -11 84 0Q84 5 148 145T278 424L342 563Q347 575 360 575Q368 575 375 570Q376 569 441 430T571 148T637 0Q637 -11 622 -20H99',
    0x25B5: '75 0L72 2Q69 3 67 5T62 11T59 20Q59 24 62 30Q65 37 245 370T428 707Q428 708 430 710T436 714T444 716Q451 716 455 712Q459 710 644 368L828 27V20Q828 7 814 0H75ZM610 347L444 653Q443 653 278 347T113 40H775Q775 42 610 347',
    0x25B6: '83 523Q83 524 85 527T92 535T103 539Q107 539 389 406T680 268Q694 260 694 249Q694 239 687 234Q685 232 395 95L107 -41H101Q90 -40 83 -26V523',
    0x25B8: '83 523Q83 524 85 527T92 535T103 539Q107 539 389 406T680 268Q694 260 694 249Q694 239 687 234Q685 232 395 95L107 -41H101Q90 -40 83 -26V523',
    0x25B9: '26 489Q33 505 43 505Q51 505 260 385Q464 266 471 259Q473 257 473 250Q473 242 469 239Q459 231 260 115Q51 -5 43 -5Q39 -5 35 -1T28 7L26 11V489ZM412 250L67 450Q66 450 66 250T67 50Q69 51 240 150T412 250',
    0x25BC: '84 556Q84 567 99 576H622Q637 567 637 556Q637 551 572 409T441 127T375 -14Q368 -19 360 -19H358Q349 -19 342 -7T296 92Q249 193 211 275Q84 550 84 556',
    0x25BD: '59 480Q59 485 61 489T66 495T72 498L75 500H814Q828 493 828 480V474L644 132Q458 -210 455 -212Q451 -215 444 -215T433 -212Q429 -210 342 -49T164 282T64 466Q59 478 59 480ZM775 460H113Q113 459 278 153T444 -153T610 153T775 460',
    0x25BE: '84 556Q84 567 99 576H622Q637 567 637 556Q637 551 572 409T441 127T375 -14Q368 -19 360 -19H358Q349 -19 342 -7T296 92Q249 193 211 275Q84 550 84 556',
    0x25BF: '59 480Q59 485 61 489T66 495T72 498L75 500H814Q828 493 828 480V474L644 132Q458 -210 455 -212Q451 -215 444 -215T433 -212Q429 -210 342 -49T164 282T64 466Q59 478 59 480ZM775 460H113Q113 459 278 153T444 -153T610 153T775 460',
    0x25C0: '694 -26Q686 -40 676 -41H670L382 95Q92 232 90 234Q83 239 83 249Q83 262 96 267Q101 270 379 401T665 537Q671 539 674 539Q686 539 694 524V-26',
    0x25C2: '694 -26Q686 -40 676 -41H670L382 95Q92 232 90 234Q83 239 83 249Q83 262 96 267Q101 270 379 401T665 537Q671 539 674 539Q686 539 694 524V-26',
    0x25C3: '473 10Q466 -5 454 -5Q451 -5 445 -3Q444 -3 343 56T140 173T35 234Q26 239 26 250T35 266Q40 269 240 384T445 503Q451 505 453 505Q466 505 473 490V10ZM433 50T433 250T432 450T259 351T87 250T258 150T432 50Q433 50 433 250',
    0x25CA: '318 709Q325 716 332 716Q340 716 344 713T474 511Q611 298 611 292Q611 285 526 152Q494 103 474 72Q347 -128 344 -130Q340 -132 333 -132T322 -130Q319 -128 257 -31T131 169T60 278Q56 285 56 292Q56 298 60 305Q73 326 194 516T318 709ZM567 290T567 291T451 475T333 658L100 293Q100 288 215 108L333 -74Q334 -74 450 108',
    0x25EF: '56 250Q56 353 95 442T196 589T335 681T491 715Q573 715 635 693Q694 673 747 635T846 543T917 412T944 250Q944 58 815 -78T500 -215Q457 -215 429 -210Q274 -183 165 -56T56 250ZM500 -176Q664 -176 784 -54T904 250Q904 418 799 536T543 674Q534 675 493 675Q425 675 357 647T229 567T133 432T96 250Q96 160 129 80T217 -56T346 -144T500 -176',
    0x25FB: '71 0Q59 4 55 16V346L56 676Q64 686 70 689H709Q719 681 722 674V15Q719 10 709 1L390 0H71ZM682 40V649H95V40H682',
    0x25FC: '71 0Q59 4 55 16V346L56 676Q64 686 70 689H709Q719 681 722 674V15Q719 10 709 1L390 0H71',
    0x2605: '367 395Q374 416 398 492T442 627T463 688Q463 692 467 692Q471 694 472 694Q478 694 484 680T523 562Q553 469 576 400L577 395H731H819Q872 395 883 394T895 384Q895 380 891 376T832 333Q794 305 767 285Q643 195 643 194L690 47Q737 -96 737 -103Q737 -111 727 -111Q721 -111 594 -18L472 71L350 -18Q223 -111 217 -111Q207 -111 207 -103Q207 -96 254 47L301 194Q301 195 241 239T118 328T51 378Q49 382 49 384Q49 392 58 393T110 395H213H367',
    0x2660: '181 -21Q134 -21 96 27T55 193Q55 224 58 247T82 317T143 410Q172 443 234 498Q282 543 314 598T360 687T380 725Q386 727 389 727Q395 727 398 725T406 716T413 702T423 677T439 641Q481 556 544 498Q633 420 678 353T723 204Q723 142 711 94T669 12T590 -21Q520 -21 490 8T459 66V70H409V62Q409 22 416 -17T430 -82T437 -112Q437 -131 407 -131Q403 -131 397 -131T389 -130T382 -130T372 -131Q341 -131 341 -111Q341 -107 348 -82T362 -18T369 62V70H319V66Q319 57 314 44T297 16T257 -10T191 -21H181',
    0x2661: '55 490Q55 557 71 604T114 674T167 706T222 716Q279 716 322 684T389 605Q391 610 395 617T414 643T447 677T494 704T555 716Q642 716 682 652T723 490Q723 455 718 426T684 342T602 227Q573 196 537 161T485 110T449 63T412 -8Q408 -22 404 -27T389 -33Q382 -33 379 -31T372 -23T366 -8T355 18T335 54Q319 81 298 104T239 163T176 227Q102 310 79 371T55 490ZM198 674Q143 664 119 613T95 491Q95 415 137 346Q174 282 265 194T384 48L389 39Q391 42 397 54T406 71T415 86T427 104T442 122T464 146T491 172Q571 249 613 303Q683 396 683 487Q683 581 649 631Q613 676 556 676Q495 676 457 634T410 538Q407 514 390 514Q386 514 380 517Q372 520 369 536T355 581T319 635Q277 675 223 675H217H208L204 674Q200 674 198 674',
    0x2662: '370 714Q370 717 375 722T388 727Q398 727 403 721T417 697Q420 692 421 689Q536 465 709 304Q723 291 723 282T709 260Q529 93 406 -153Q402 -162 390 -162H389Q379 -162 376 -158T357 -125Q247 89 89 241L64 265Q55 272 55 282Q55 287 57 290T64 300T77 312T98 331T127 361Q197 435 258 523T344 663L370 714ZM655 299Q568 384 508 470T389 662L376 638Q362 613 341 577T289 497T215 399T123 299L105 282L123 265Q210 180 270 94T389 -98L402 -74Q416 -49 437 -13T489 67T563 165T655 265L673 282L655 299',
    0x2663: '213 532Q213 615 265 670T389 726Q461 726 513 671T565 532Q565 511 562 492T553 458T541 432T526 409T512 393T498 379L490 371L511 326Q512 326 516 330T528 341T546 353T572 363T606 368Q664 368 707 315T750 174Q750 87 699 33T579 -22Q567 -22 553 -20T517 -10T479 16T459 63V70H409V62Q409 22 416 -17T430 -82T437 -112Q437 -131 407 -131Q403 -131 397 -131T389 -130T382 -130T372 -131Q341 -131 341 -111Q341 -107 348 -82T362 -18T369 62V70H319V63Q315 25 281 2T197 -22Q132 -22 80 32T28 174Q28 255 69 311T175 368Q192 368 207 364T232 353T250 341T262 331T267 326L288 371L280 378Q272 385 267 391T253 407T238 430T226 457T217 492T213 532',
    0x266D: '200 467Q254 467 293 428T332 321Q332 147 104 -11L88 -22H75Q62 -22 56 -16L55 362V647Q55 743 60 748Q63 750 76 750H83Q87 750 95 744V434L104 440Q144 467 200 467ZM237 322Q237 360 225 388T183 417Q158 417 134 407T101 378Q96 370 96 349T95 197V34Q152 91 194 167T237 322',
    0x266E: '65 721Q78 734 94 734Q100 734 104 727V444L116 449Q129 454 157 465T208 486Q313 527 314 527Q318 527 324 521V-210Q306 -223 294 -223Q289 -223 284 -216V-13L270 -18Q257 -24 231 -34T180 -54Q77 -96 74 -96T65 -90V721ZM104 13Q282 84 283 85Q284 85 284 252Q284 418 283 418L230 396L140 360L104 346V13',
    0x266F: '101 -223Q94 -223 93 -217T91 -188V-151Q91 -88 90 -88Q87 -88 80 -92T68 -96Q62 -96 56 -90L55 -50V-22Q55 -8 58 -4T78 5L91 10V177Q91 343 90 343Q87 343 80 339T68 335Q62 335 56 341L55 381V409Q55 423 58 427T78 436L91 441V543V616Q91 643 93 648T106 656Q119 662 126 659Q130 657 130 645T131 554V456L257 503V607L258 710L260 712Q261 715 272 719T286 723Q293 723 295 715T297 671V617Q297 519 298 519Q301 519 307 522T319 526Q327 526 333 521V437L330 435Q328 432 312 427L297 421V254Q297 88 298 88Q301 88 307 91T319 95Q327 95 333 90V6L330 4Q328 1 312 -4L297 -10V-78V-122Q297 -145 295 -149T282 -156Q274 -160 268 -160Q257 -160 257 -130V-89V-25L131 -72V-210Q123 -215 116 -218T104 -222L101 -223ZM257 72V406L131 359V25L257 72',
    0x2713: '84 231Q84 244 114 264T170 285Q176 285 183 274T224 205Q267 129 268 129Q271 141 279 163T318 250T389 378T502 523T662 673Q702 706 732 706H734Q749 706 749 695Q749 682 730 666T660 607T559 505Q387 299 328 29Q324 0 295 -17T245 -34H241Q234 -34 225 -21T185 46Q166 79 154 101Q84 223 84 231',
    0x2720: '195 702T195 706T201 716H632Q638 710 638 706T636 700T621 690Q436 581 427 374V357H430Q554 357 645 421Q682 447 711 483T755 542T770 567Q775 572 786 563V131Q777 125 774 125T762 139Q709 228 642 274T482 333Q452 337 430 337H427V320Q430 279 437 247T462 170T521 82T621 4Q630 -2 633 -4T637 -7T638 -12Q638 -16 632 -22H201Q195 -16 195 -12T197 -6T212 4Q397 113 406 320V337H403Q279 337 188 273Q151 247 122 211T78 152T63 127Q58 122 48 131V563Q54 569 59 569Q62 569 71 555Q124 466 191 420T351 361Q381 357 403 357H406V374Q403 415 396 447T371 525T312 613T212 690Q199 697 197 699',
    0x2758: '139 -249H137Q125 -249 119 -235V251L120 737Q130 750 139 750Q152 750 159 735V-235Q151 -249 141 -249H139',
    0x27E8: '333 -232Q332 -239 327 -244T313 -250Q303 -250 296 -240Q293 -233 202 6T110 250T201 494T296 740Q299 745 306 749L309 750Q312 750 313 750Q331 750 333 732Q333 727 243 489Q152 252 152 250T243 11Q333 -227 333 -232',
    0x27E9: '55 732Q56 739 61 744T75 750Q85 750 92 740Q95 733 186 494T278 250T187 6T92 -240Q85 -250 75 -250Q67 -250 62 -245T55 -232Q55 -227 145 11Q236 248 236 250T145 489Q55 727 55 732',
    0x27EE: '357 741V726Q357 720 349 715Q261 655 242 539Q240 526 240 394V331Q240 259 239 250Q240 242 240 119V49Q240 -42 254 -85Q263 -111 275 -134T301 -172T326 -197T346 -213T356 -221T357 -232V-241L354 -244H334Q264 -209 222 -146T174 -12Q173 -6 173 95Q173 134 173 191T174 250Q173 260 173 376V460Q173 515 178 545T201 611Q244 695 327 741L334 744H354L357 741',
    0x27EF: '78 744Q153 706 196 640T239 492V376Q239 339 239 311T238 269T238 252Q240 236 240 124V40Q240 -18 233 -53T202 -130Q156 -206 79 -244H70H65Q58 -244 57 -242T56 -231T57 -220T64 -215Q153 -154 170 -39Q173 -18 174 119V247Q173 249 173 382V451Q173 542 159 585Q145 626 120 658T75 706T56 723V731Q56 741 57 742T66 744H78',
    0x27F5: '165 270H1510Q1525 262 1525 250T1510 230H165Q167 228 182 216T211 189T244 152T277 96T303 25Q308 7 308 0Q308 -11 288 -11Q281 -11 278 -11T272 -7T267 2T263 21Q245 94 195 151T73 236Q58 242 55 247Q55 254 59 257T73 264Q121 283 158 314T215 375T247 434T264 480L267 497Q269 503 270 505T275 509T288 511Q308 511 308 500Q308 493 303 475Q293 438 278 406T246 352T215 315T185 287T165 270',
    0x27F6: '84 237T84 250T98 270H1444Q1328 357 1301 493Q1301 494 1301 496T1300 499Q1300 511 1317 511H1320Q1329 511 1332 510T1338 506T1341 497T1344 481T1352 456Q1374 389 1425 336T1544 261Q1553 258 1553 250Q1553 244 1548 241T1524 231T1486 212Q1445 186 1415 152T1370 85T1349 35T1341 4Q1339 -6 1336 -8T1320 -11Q1300 -11 1300 0Q1300 7 1305 25Q1337 151 1444 230H98Q84 237 84 250',
    0x27F7: '165 270H1694Q1578 357 1551 493Q1551 494 1551 496T1550 499Q1550 511 1567 511H1570Q1579 511 1582 510T1588 506T1591 497T1594 481T1602 456Q1624 389 1675 336T1794 261Q1803 258 1803 250Q1803 244 1798 241T1774 231T1736 212Q1695 186 1665 152T1620 85T1599 35T1591 4Q1589 -6 1586 -8T1570 -11Q1550 -11 1550 0Q1550 7 1555 25Q1587 151 1694 230H165Q167 228 182 216T211 189T244 152T277 96T303 25Q308 7 308 0Q308 -11 288 -11Q281 -11 278 -11T272 -7T267 2T263 21Q245 94 195 151T73 236Q58 242 55 247Q55 254 59 257T73 264Q121 283 158 314T215 375T247 434T264 480L267 497Q269 503 270 505T275 509T288 511Q308 511 308 500Q308 493 303 475Q293 438 278 406T246 352T215 315T185 287T165 270',
    0x27F8: '274 173H1539Q1540 172 1542 171T1545 169T1547 167T1550 164T1551 162T1552 158T1553 153Q1553 140 1538 133H318L328 123Q379 69 414 0Q419 -13 419 -17Q419 -24 399 -24Q388 -24 385 -23T377 -12Q332 77 253 144T72 237Q62 240 59 242T56 250T59 257T70 262T89 268T119 278T160 296Q303 366 377 512Q382 522 385 523T401 525Q419 524 419 515Q419 510 414 500Q379 431 328 377L318 367H1538Q1553 359 1553 347Q1553 336 1539 328L1221 327H903L900 328L602 327H274L264 319Q225 289 147 250Q148 249 165 241T210 217T264 181L274 173',
    0x27F9: '1218 514Q1218 525 1234 525Q1239 525 1242 525T1247 525T1251 524T1253 523T1255 520T1257 517T1260 512Q1297 438 1358 381T1469 300T1565 263Q1582 258 1582 250T1573 239T1536 228T1478 204Q1334 134 1260 -12Q1256 -21 1253 -22T1238 -24Q1218 -24 1218 -17Q1218 -13 1223 0Q1258 69 1309 123L1319 133H70Q56 140 56 153Q56 168 72 173H1363L1373 181Q1412 211 1490 250Q1489 251 1472 259T1427 283T1373 319L1363 327H710L707 328L390 327H72Q56 332 56 347Q56 360 70 367H1319L1309 377Q1276 412 1247 458T1218 514',
    0x27FA: '1438 514Q1438 525 1454 525Q1459 525 1462 525T1467 525T1471 524T1473 523T1475 520T1477 517T1480 512Q1517 438 1578 381T1689 300T1785 263Q1802 258 1802 250T1793 239T1756 228T1698 204Q1554 134 1480 -12Q1476 -21 1473 -22T1458 -24Q1438 -24 1438 -17Q1438 -13 1443 0Q1478 69 1529 123L1539 133H318L328 123Q379 69 414 0Q419 -13 419 -17Q419 -24 399 -24Q388 -24 385 -23T377 -12Q332 77 253 144T72 237Q62 240 59 242T56 250T59 257T70 262T89 268T119 278T160 296Q303 366 377 512Q382 522 385 523T401 525Q419 524 419 515Q419 510 414 500Q379 431 328 377L318 367H1539L1529 377Q1496 412 1467 458T1438 514ZM274 173H1583L1593 181Q1632 211 1710 250Q1709 251 1692 259T1647 283T1593 319L1583 327H930L927 328L602 327H274L264 319Q225 289 147 250Q148 249 165 241T210 217T264 181L274 173',
    0x27FC: '95 155V109Q95 83 92 73T75 63Q61 63 58 74T54 130Q54 140 54 180T55 250Q55 421 57 425Q61 437 75 437Q88 437 91 428T95 393V345V270H1444Q1328 357 1301 493Q1301 494 1301 496T1300 499Q1300 511 1317 511H1320Q1329 511 1332 510T1338 506T1341 497T1344 481T1352 456Q1374 389 1425 336T1544 261Q1553 258 1553 250Q1553 244 1548 241T1524 231T1486 212Q1445 186 1415 152T1370 85T1349 35T1341 4Q1339 -6 1336 -8T1320 -11Q1300 -11 1300 0Q1300 7 1305 25Q1337 151 1444 230H95V155',
    0x29EB: '318 709Q325 716 332 716Q340 716 344 713T474 511Q611 298 611 292Q611 285 526 152Q494 103 474 72Q347 -128 344 -130Q340 -132 333 -132T322 -130Q319 -128 257 -31T131 169T60 278Q56 285 56 292Q56 298 60 305Q73 326 194 516T318 709',
    0x29F8: '166 -215T159 -215T147 -212T141 -204T139 -197Q139 -190 144 -183Q157 -157 378 274T602 707Q605 716 618 716Q625 716 630 712T636 703T638 696Q638 691 406 241T170 -212Q166 -215 159 -215',
    0x2A00: '555 -250Q420 -250 306 -185T124 -4T56 250Q56 453 193 595T526 749Q528 750 539 750Q554 750 562 749Q688 749 800 687T983 508T1054 250Q1054 112 987 -3T806 -184T555 -250ZM555 -165Q672 -165 767 -108T916 44T970 250Q970 418 861 532T600 664Q591 665 548 665Q446 665 353 614T200 466T140 250V243Q140 88 248 -30Q262 -46 280 -62T338 -105T434 -148T555 -165ZM478 250Q478 288 503 307T551 326Q586 326 609 305T632 250Q632 217 610 196T555 174T500 196T478 250',
    0x2A01: '555 -250Q420 -250 306 -185T124 -4T56 250Q56 453 193 595T526 749Q528 750 539 750Q554 750 562 749Q688 749 800 687T983 508T1054 250Q1054 112 987 -3T806 -184T555 -250ZM513 478Q513 664 512 664Q504 664 481 660T406 637T313 588Q281 564 255 537T211 483T181 431T161 382T150 342T144 310T141 292H513V478ZM798 588Q758 616 711 634T639 658T602 663L597 664V292H969Q969 293 967 309T960 341T949 381T930 430T900 482T856 537T798 588ZM513 -164V208H141Q142 205 144 189T149 160T158 125T173 83T196 39T229 -9Q249 -34 273 -55T318 -92T363 -119T405 -138T444 -150T475 -158T499 -162T513 -164ZM775 -103Q801 -87 823 -68T863 -30T894 10T919 49T937 88T950 123T959 154T964 180T968 198L969 208H597V-164Q599 -163 616 -161T647 -155T683 -145T728 -128T775 -103',
    0x2A02: '555 -250Q420 -250 306 -185T124 -4T56 250Q56 453 193 595T526 749Q528 750 539 750Q554 750 562 749Q688 749 800 687T983 508T1054 250Q1054 112 987 -3T806 -184T555 -250ZM600 664Q591 665 548 665Q414 665 306 583L292 573L423 441L555 310L687 441L818 573L804 583Q714 650 600 664ZM364 118L495 250L364 382L232 513L223 500Q140 391 140 250Q140 107 223 0L232 -13L364 118ZM970 250Q970 389 887 501L878 512Q878 513 861 496T812 447T746 381L615 250L746 118L878 -13L887 0Q970 109 970 250ZM687 59L555 190L423 59L292 -73L306 -83Q416 -166 555 -166T804 -83L818 -73L687 59',
    0x2A04: '96 750Q103 750 109 748T120 744T127 737T133 730T137 723T139 718V395L140 73L142 60Q159 -43 237 -104T416 -166Q521 -166 597 -103T690 60L692 73L694 718Q708 749 735 749Q765 749 775 720Q777 714 777 398Q777 78 776 71Q766 -51 680 -140Q571 -249 416 -249H411Q261 -249 152 -140Q66 -51 56 71Q55 78 55 398Q55 714 57 720Q60 734 70 740Q80 750 96 750ZM223 276Q223 282 224 287T227 296T232 302T238 308T243 313T250 316L254 319H374V376V406Q374 438 382 454T418 470Q443 467 450 453T458 410V376V319H579Q580 319 583 317T589 313T594 308T600 302T604 295T608 287T609 276Q609 253 587 241Q577 235 513 235H458V178Q458 176 458 166T459 148Q459 84 415 84Q401 84 390 93T375 117Q374 120 374 178V235H319Q317 235 307 235T290 234Q223 234 223 276',
    0x2A06: '777 -217Q766 -244 745 -249H88Q64 -242 57 -220Q55 -214 55 250T57 720Q60 734 70 740Q80 750 96 750Q127 750 137 720Q139 714 139 274V-166H693V274Q693 714 695 720Q705 749 735 749Q766 749 775 719Q777 713 777 248V-217',
    0x2A2F: '630 29Q630 9 609 9Q604 9 587 25T493 118L389 222L284 117Q178 13 175 11Q171 9 168 9Q160 9 154 15T147 29Q147 36 161 51T255 146L359 250L255 354Q174 435 161 449T147 471Q147 480 153 485T168 490Q173 490 175 489Q178 487 284 383L389 278L493 382Q570 459 587 475T609 491Q630 491 630 471Q630 464 620 453T522 355L418 250L522 145Q606 61 618 48T630 29',
    0x2A3F: '28 660Q28 676 31 679T46 683H50Q87 681 182 681Q217 681 247 681T294 682T315 682Q321 682 323 682T328 679T331 673T332 660Q332 643 328 640T304 637Q239 637 231 626Q229 620 229 334V46H520V334Q520 620 518 626Q510 637 445 637Q426 637 422 640T417 660Q417 675 420 678T432 682H435Q437 682 467 682T569 681T671 681T703 682Q714 682 717 679T721 660Q721 643 717 640T693 637Q628 637 620 626Q619 623 619 342Q619 60 620 57Q628 46 693 46Q714 46 717 43T721 23Q721 5 715 1Q713 0 374 0Q36 0 34 1Q28 5 28 23Q28 40 31 43T56 46Q121 46 129 57Q131 63 131 342Q131 620 129 626Q121 637 56 637Q35 637 32 640T28 660',
    0x2A5E: '55 795Q56 806 70 813H540Q554 806 555 795Q555 791 554 788T552 783T549 779T546 777T542 775T539 773H71Q70 773 68 774T65 776T61 779T58 783T56 788T55 795ZM55 601Q56 612 70 619H540Q554 612 555 601Q555 597 554 594T552 589T549 585T546 583T542 581T539 579H71Q70 579 68 580T65 582T61 585T58 589T56 594T55 601ZM75 -97Q67 -97 61 -91T55 -81Q55 -71 169 166T285 410Q295 425 305 425Q311 425 316 422T323 414L325 410Q327 404 441 167T555 -81Q555 -85 549 -91T535 -97T520 -90Q519 -89 506 -61T463 27T411 136L305 358L199 136Q167 71 129 -10Q98 -75 92 -86T75 -97',
    0x2A7D: '674 636Q682 636 688 630T694 615T687 601Q686 600 417 472L151 346L399 228Q687 92 691 87Q694 81 694 76Q694 58 676 56H670L382 192Q92 329 90 331Q83 336 83 348Q84 359 96 365Q104 369 382 500T665 634Q669 636 674 636ZM94 170Q102 172 104 172Q110 171 254 103T535 -30T678 -98Q694 -106 694 -118Q694 -136 676 -138H670L382 -2Q92 135 90 137Q83 142 83 154Q84 164 94 170',
    0x2A7E: '83 616Q83 624 89 630T99 636Q107 636 253 568T543 431T687 361Q694 356 694 346T687 331Q685 329 395 192L107 56H101Q83 58 83 76Q83 77 83 79Q82 86 98 95Q117 105 248 167Q326 204 378 228L626 346L360 472Q291 505 200 548Q112 589 98 597T83 616ZM674 172Q692 172 694 154Q694 142 687 137Q685 135 395 -2L107 -138H101Q83 -136 83 -118Q83 -106 96 -100Q100 -98 380 35T665 170T674 172',
    0x2A85: '667 761Q669 762 673 762Q682 762 688 756T694 741Q694 731 687 727Q684 724 420 613L156 502L416 392Q476 367 544 338T647 295T682 280Q694 274 694 262Q694 244 676 242Q670 242 524 303T235 425T90 487Q83 493 83 501Q83 514 94 519Q97 520 378 639T667 761ZM55 -23Q55 43 103 90T223 138Q265 138 316 114Q342 100 393 68L443 36Q502 0 554 0Q609 0 650 32T694 109Q694 138 708 138Q710 138 713 136T719 127T722 108Q722 37 673 -9T557 -56Q514 -56 468 -35T387 13T308 60T223 82Q167 82 127 50T83 -27Q81 -56 69 -56Q55 -56 55 -23ZM55 -257Q55 -191 103 -144T223 -96Q265 -96 316 -120Q342 -134 393 -166L443 -198Q502 -234 554 -234Q609 -234 650 -202T694 -125Q694 -96 708 -96Q710 -96 713 -98T719 -107T722 -126Q722 -197 673 -243T557 -290Q514 -290 468 -269T387 -221T308 -174T223 -152Q167 -152 127 -184T83 -261Q80 -290 69 -290Q55 -290 55 -257',
    0x2A86: '90 727Q83 734 83 743Q83 751 89 756T104 762Q111 760 396 641Q686 518 687 517Q694 512 694 502T687 487Q686 486 543 425T253 303T101 242Q83 244 83 262Q83 274 95 280Q96 280 130 294T232 338T361 392L621 502L357 613Q93 724 90 727ZM55 -23Q55 43 103 90T223 138Q265 138 316 114Q342 100 393 68L443 36Q502 0 554 0Q609 0 650 32T694 109Q694 138 708 138Q710 138 713 136T719 127T722 108Q722 37 673 -9T557 -56Q514 -56 468 -35T387 13T308 60T223 82Q167 82 127 50T83 -27Q81 -56 69 -56Q55 -56 55 -23ZM55 -257Q55 -191 103 -144T223 -96Q265 -96 316 -120Q342 -134 393 -166L443 -198Q502 -234 554 -234Q609 -234 650 -202T694 -125Q694 -96 708 -96Q710 -96 713 -98T719 -107T722 -126Q722 -197 673 -243T557 -290Q514 -290 468 -269T387 -221T308 -174T223 -152Q167 -152 127 -184T83 -261Q80 -290 69 -290Q55 -290 55 -257',
    0x2A87: '380 497Q445 528 522 564T636 618T673 635Q680 635 686 628T693 615T689 603Q686 599 418 472L151 343L418 215Q686 88 689 83Q693 79 693 72T687 59T675 52Q669 52 381 189T86 332Q82 337 82 344Q82 350 86 355Q91 359 380 497ZM82 -130T82 -124T95 -103H380L431 -54Q476 -6 486 -6Q491 -6 498 -12T505 -27Q505 -28 505 -29T504 -32Q503 -33 498 -38T485 -53T469 -70L438 -103H680Q682 -106 686 -110T691 -116T693 -123Q693 -130 680 -143H398L346 -192Q300 -241 291 -241Q271 -241 271 -221Q271 -209 306 -179L340 -143H95Q82 -130 82 -124',
    0x2A88: '82 614Q82 620 86 625T94 632T100 635Q106 635 394 498T689 355Q693 349 693 343Q693 338 689 332Q683 327 395 190T100 52Q95 52 89 58T82 72Q82 80 95 88Q114 99 358 215L624 343L358 472Q89 599 86 603Q82 607 82 614ZM82 -130T82 -124T95 -103H380L431 -54Q476 -6 486 -6Q491 -6 498 -12T505 -27Q505 -28 505 -29T504 -32Q503 -33 498 -38T485 -53T469 -70L438 -103H680Q682 -106 686 -110T691 -116T693 -123Q693 -130 680 -143H398L346 -192Q300 -241 291 -241Q271 -241 271 -221Q271 -209 306 -179L340 -143H95Q82 -130 82 -124',
    0x2A89: '86 512Q93 518 381 639T673 761Q680 761 686 755T693 741Q693 733 688 730Q685 723 419 612L155 501L419 390Q685 277 688 272Q693 269 693 261Q693 254 687 248T675 241Q669 241 381 362T86 490Q74 500 86 512ZM70 -59Q57 -59 57 -24Q57 40 104 87Q116 102 146 118Q186 136 231 136Q232 136 242 135T258 133T276 128T302 118T334 101T377 74Q386 69 396 63T411 53T417 50Q435 87 453 134Q491 223 495 227Q498 230 505 230Q513 230 519 225T526 212Q526 203 491 118T453 30Q453 22 489 10T553 -3Q589 -3 622 14Q653 28 669 50T688 90T694 122T706 136Q718 136 718 114Q718 113 718 109T717 103Q717 31 668 -14T554 -60Q529 -60 499 -50T451 -32T433 -24Q431 -24 404 -90T375 -157Q375 -159 402 -178T473 -218T553 -239Q599 -239 641 -211T691 -130Q694 -99 706 -99T718 -122Q718 -123 718 -127T717 -133Q717 -204 668 -249T559 -295Q512 -295 470 -275T355 -206L322 -290Q313 -310 304 -332T289 -367T282 -382Q277 -387 270 -387Q262 -387 255 -382T248 -368Q248 -361 322 -186Q311 -177 280 -166T222 -155Q189 -155 153 -173Q122 -186 106 -208T87 -248T82 -280T71 -294Q57 -294 57 -259Q57 -195 104 -148Q122 -126 155 -113T220 -99Q245 -99 276 -109T324 -127T342 -135Q397 -2 397 1Q386 10 367 23T302 58T222 80Q175 80 132 52T84 -28Q82 -59 70 -59',
    0x2A8A: '86 730Q81 734 81 740Q81 747 88 754T99 761Q103 761 392 640T688 512Q693 509 693 501T688 490Q682 484 394 363T99 241Q94 241 88 248T82 261Q82 268 86 272Q89 277 355 390L619 501L355 612Q89 723 86 730ZM70 -59Q57 -59 57 -24Q57 40 104 87Q116 102 146 118Q186 136 231 136Q232 136 242 135T258 133T276 128T302 118T334 101T377 74Q386 69 396 63T411 53T417 50Q435 87 453 134Q491 223 495 227Q498 230 505 230Q513 230 519 225T526 212Q526 203 491 118T453 30Q453 22 489 10T553 -3Q589 -3 622 14Q653 28 669 50T688 90T694 122T706 136Q718 136 718 114Q718 113 718 109T717 103Q717 31 668 -14T554 -60Q529 -60 499 -50T451 -32T433 -24Q431 -24 404 -90T375 -157Q375 -159 402 -178T473 -218T553 -239Q599 -239 641 -211T691 -130Q694 -99 706 -99T718 -122Q718 -123 718 -127T717 -133Q717 -204 668 -249T559 -295Q512 -295 470 -275T355 -206L322 -290Q313 -310 304 -332T289 -367T282 -382Q277 -387 270 -387Q262 -387 255 -382T248 -368Q248 -361 322 -186Q311 -177 280 -166T222 -155Q189 -155 153 -173Q122 -186 106 -208T87 -248T82 -280T71 -294Q57 -294 57 -259Q57 -195 104 -148Q122 -126 155 -113T220 -99Q245 -99 276 -109T324 -127T342 -135Q397 -2 397 1Q386 10 367 23T302 58T222 80Q175 80 132 52T84 -28Q82 -59 70 -59',
    0x2A8B: '674 1003Q681 1003 687 999T694 983Q694 973 683 967Q669 959 420 868L162 772L422 676Q683 579 685 577Q694 571 694 560Q694 550 687 546T673 541Q669 542 384 647T93 755Q83 760 83 772Q83 783 91 788Q98 791 383 897T674 1003ZM84 354T84 367T98 387H679Q694 379 694 367Q694 354 679 347H98Q84 354 84 367ZM84 160T84 173T98 193H679Q694 185 694 173Q694 160 679 153H98Q84 160 84 173ZM94 -3Q102 -1 104 -1Q107 -2 392 -107T684 -215Q694 -219 694 -232Q694 -241 687 -247Q686 -248 395 -357Q106 -463 101 -463Q83 -461 83 -443Q83 -431 94 -426Q97 -423 357 -328L615 -232L355 -136Q94 -39 92 -37Q83 -31 83 -21Q83 -9 94 -3',
    0x2A8C: '104 541Q98 541 91 545T83 560Q83 571 92 577Q94 579 355 676L615 772L357 868Q108 959 94 967Q83 973 83 983Q83 989 87 996T104 1003Q109 1002 396 896T687 787Q694 781 694 772Q694 759 684 755Q678 752 393 647T104 541ZM84 367Q84 380 98 387H679Q694 379 694 367Q694 356 680 348L390 347H100Q84 352 84 367ZM84 173Q84 188 100 193H680Q694 183 694 173Q694 160 679 153H98Q84 160 84 173ZM674 -1Q682 -1 688 -6T694 -20Q694 -31 685 -37Q683 -39 422 -136L162 -232L420 -328Q680 -423 683 -426Q694 -431 694 -443Q694 -461 676 -463Q671 -463 382 -357Q91 -248 90 -247Q83 -242 83 -232Q83 -220 93 -215Q667 -1 674 -1',
    0x2A95: '674 636Q682 636 688 631T694 616Q694 605 687 601Q685 599 395 462L107 326H101Q83 328 83 345Q83 358 96 365Q102 367 382 500T665 634Q671 636 674 636ZM674 442Q692 442 694 424Q694 412 687 407Q686 406 417 278L151 152L399 34Q687 -102 691 -107Q694 -113 694 -118Q694 -136 676 -138H670L382 -2Q92 135 90 137Q83 142 83 154Q84 165 96 171Q104 175 382 306T665 440Q669 442 674 442',
    0x2A96: '83 616Q83 624 89 630T99 636Q107 636 253 568T543 431T687 361Q694 354 694 346Q694 328 676 326H670L382 462Q317 493 226 535Q119 585 101 595T83 616ZM94 440Q102 442 104 442Q110 441 254 373T535 240T678 172Q679 172 680 171Q694 164 694 153T687 137Q685 135 395 -2L107 -138H101Q83 -136 83 -118Q83 -106 93 -101L128 -84Q163 -68 230 -36T361 26L626 152L360 278Q91 406 90 407Q83 412 83 424Q84 434 94 440',
    0x2AAF: '84 346Q84 359 91 363T117 367Q120 367 126 367T137 366Q388 370 512 430T653 609Q657 636 676 636Q685 635 689 629T694 618V612Q689 566 672 528T626 463T569 417T500 383T435 362T373 346Q379 345 404 339T440 330T477 318T533 296Q592 266 630 223T681 145T694 78Q694 57 674 57Q662 57 657 67T652 92T640 135T606 191Q500 320 137 326H114Q104 326 98 327T88 332T84 346ZM84 -131T84 -118T98 -98H679Q694 -106 694 -118T679 -138H98Q84 -131 84 -118',
    0x2AB0: '84 614Q84 636 102 636Q115 636 119 626T125 600T137 556T171 501Q277 372 640 366H661Q694 366 694 346T661 326H640Q578 325 526 321T415 307T309 280T222 237T156 172T124 83Q122 66 118 62T103 57Q100 57 98 57T95 58T93 59T90 62T85 67Q83 71 83 80Q88 126 105 164T151 229T208 275T277 309T342 330T404 346Q401 347 380 351T345 360T302 373T245 396Q125 455 92 565Q84 599 84 614ZM84 -131T84 -118T98 -98H679Q694 -106 694 -118T679 -138H98Q84 -131 84 -118',
    0x2AB5: '653 734Q653 738 660 745T673 752T686 745T693 723Q672 555 466 485Q390 463 378 463Q373 463 373 461Q373 458 378 458Q390 458 466 436Q562 404 620 350Q682 283 693 198Q693 183 686 176Q681 170 674 170T660 176T653 187Q653 192 652 200T646 228T631 265T602 307T555 350Q435 431 151 441H95Q82 454 82 460T95 481H151Q165 482 197 483T238 485Q427 500 528 554T649 707Q653 729 653 734ZM82 33Q82 37 83 40T89 47T95 54H473L520 105Q569 156 571 156Q573 157 578 157Q586 157 592 151T598 136Q598 130 562 92L526 56L604 54H682Q693 43 693 35Q693 31 692 28T686 21T680 14H489L342 -139L513 -142H682Q693 -148 693 -160Q693 -167 680 -182H304L258 -230Q248 -240 237 -251T221 -268T211 -278T203 -284T197 -286Q189 -286 184 -280T178 -264Q178 -257 213 -219L249 -182H171L93 -179L86 -175Q82 -170 82 -163Q82 -155 95 -142H289L360 -64L433 14H262L93 16Q82 23 82 33',
    0x2AB6: '693 466T693 460T680 441H624Q608 439 577 438T538 436Q349 421 248 367T126 214Q122 192 122 187Q122 183 116 177T102 170Q95 170 89 176Q82 183 82 198Q93 283 155 350Q213 404 309 436Q385 458 398 458Q402 458 402 461Q402 463 398 463Q385 463 309 485Q103 555 82 723Q82 738 89 745T102 752T115 745T122 734Q122 721 126 701T155 640T220 572Q340 490 624 481H680Q693 466 693 460ZM82 33Q82 37 83 40T89 47T95 54H473L520 105Q569 156 571 156Q573 157 578 157Q586 157 592 151T598 136Q598 130 562 92L526 56L604 54H682Q693 43 693 35Q693 31 692 28T686 21T680 14H489L342 -139L513 -142H682Q693 -148 693 -160Q693 -167 680 -182H304L258 -230Q248 -240 237 -251T221 -268T211 -278T203 -284T197 -286Q189 -286 184 -280T178 -264Q178 -257 213 -219L249 -182H171L93 -179L86 -175Q82 -170 82 -163Q82 -155 95 -142H289L360 -64L433 14H262L93 16Q82 23 82 33',
    0x2AB7: '82 494T82 501T95 521H171Q405 527 511 569Q630 618 651 732Q652 734 653 740T655 748T658 754T663 759T672 761L686 754Q693 747 693 734Q684 668 648 623Q627 591 573 557T442 507L417 501Q428 496 442 494Q520 478 573 444T648 378Q684 333 693 267Q693 254 686 247Q673 234 659 245Q657 247 651 269Q630 383 511 432Q406 474 171 481H95Q82 494 82 501ZM70 -59Q57 -59 57 -26Q57 30 90 73T177 132Q191 136 226 136Q228 136 239 136T253 135T267 132T287 125T311 113T346 95T391 67Q462 20 502 5Q519 1 553 1Q586 1 602 5Q641 18 664 45T691 107Q694 136 704 136Q717 136 717 115V105Q717 39 671 -9T554 -58Q518 -58 481 -43T382 14Q302 63 273 74Q255 78 222 78Q188 78 173 74Q90 46 84 -28Q82 -59 70 -59ZM71 -294Q57 -294 57 -262Q57 -205 90 -162T177 -104Q191 -99 226 -99Q266 -103 277 -106Q310 -119 391 -168Q455 -212 502 -231Q519 -235 553 -235Q586 -235 602 -231Q640 -218 661 -195T686 -151T693 -115T704 -99Q717 -99 717 -121V-131Q717 -198 671 -246T556 -294Q519 -294 482 -279T382 -222Q307 -175 273 -162Q255 -157 222 -157Q188 -157 173 -162Q133 -175 110 -201T84 -264Q82 -294 71 -294',
    0x2AB8: '693 501Q693 493 679 481H604Q369 474 264 432Q143 382 124 269Q116 246 115 245Q101 234 88 247Q82 254 82 267Q89 329 126 378Q147 410 201 444T333 494L357 501Q354 502 340 505T318 510T295 516T269 525T243 535T215 548T188 565Q142 599 126 623Q89 672 82 734Q82 761 102 761L115 756Q116 755 124 732Q143 619 264 569Q371 527 604 521H679Q693 507 693 501ZM70 -59Q57 -59 57 -26Q57 30 90 73T177 132Q191 136 226 136Q228 136 239 136T253 135T267 132T287 125T311 113T346 95T391 67Q462 20 502 5Q519 1 553 1Q586 1 602 5Q641 18 664 45T691 107Q694 136 704 136Q717 136 717 115V105Q717 39 671 -9T554 -58Q518 -58 481 -43T382 14Q302 63 273 74Q255 78 222 78Q188 78 173 74Q90 46 84 -28Q82 -59 70 -59ZM71 -294Q57 -294 57 -262Q57 -205 90 -162T177 -104Q191 -99 226 -99Q266 -103 277 -106Q310 -119 391 -168Q455 -212 502 -231Q519 -235 553 -235Q586 -235 602 -231Q640 -218 661 -195T686 -151T693 -115T704 -99Q717 -99 717 -121V-131Q717 -198 671 -246T556 -294Q519 -294 482 -279T382 -222Q307 -175 273 -162Q255 -157 222 -157Q188 -157 173 -162Q133 -175 110 -201T84 -264Q82 -294 71 -294',
    0x2AB9: '82 494T82 501T95 521H171Q256 523 317 528T441 548T543 584T613 644T651 732Q652 734 653 740T655 748T658 754T663 759T672 761L686 754Q693 747 693 734Q686 686 664 647T615 586T548 545T482 518T417 501Q419 500 451 493T517 471T590 434T657 367T693 267Q693 241 673 241Q664 241 659 245Q656 249 650 273T635 323T593 380T511 432Q406 474 171 481H95Q82 494 82 501ZM57 -26Q57 39 101 87T219 136Q254 136 277 130Q320 114 382 72Q419 50 424 45Q426 45 459 110Q496 178 497 179Q500 180 504 180Q509 180 517 175T526 161Q526 158 495 90L462 25Q462 21 502 5Q519 1 553 1Q586 1 602 5Q641 18 664 45T691 107Q694 136 706 136T718 115Q718 114 718 111T717 105Q717 39 671 -9T554 -58L459 -33Q450 -29 444 -27T437 -26L371 -155L391 -168Q485 -235 538 -235H553Q586 -235 602 -230Q683 -204 691 -128Q694 -99 706 -99T718 -120Q718 -121 718 -124T717 -130Q717 -199 670 -246T557 -294T393 -228Q353 -205 351 -201Q348 -201 315 -266Q294 -310 285 -323T268 -337Q259 -337 254 -331T248 -317Q248 -305 282 -246L313 -181Q313 -177 273 -161Q255 -157 222 -157Q188 -157 173 -161Q134 -174 113 -198T88 -242T82 -278T71 -294Q57 -294 57 -261Q57 -204 91 -161T179 -104Q195 -99 228 -99Q274 -102 315 -124Q337 -132 337 -130L404 -1L384 12Q319 58 273 74Q255 79 222 79Q188 79 173 74Q133 61 112 37T88 -7T82 -43T70 -59Q57 -59 57 -26',
    0x2ABA: '693 501Q693 493 679 481H604Q548 479 509 477T418 469T331 454T257 429T194 392T150 340T124 270Q117 247 115 245Q101 236 88 247Q82 254 82 267Q89 330 126 379Q147 411 202 444T333 494L357 501Q239 531 188 565Q142 599 126 623Q89 672 82 734Q82 761 102 761L115 756Q116 755 124 732Q133 678 166 640T241 579T349 544T470 527T604 521H679Q693 507 693 501ZM57 -26Q57 39 101 87T219 136Q254 136 277 130Q320 114 382 72Q419 50 424 45Q426 45 459 110Q496 178 497 179Q500 180 504 180Q509 180 517 175T526 161Q526 158 495 90L462 25Q462 21 502 5Q519 1 553 1Q586 1 602 5Q641 18 664 45T691 107Q694 136 706 136T718 115Q718 114 718 111T717 105Q717 39 671 -9T554 -58L459 -33Q450 -29 444 -27T437 -26L371 -155L391 -168Q485 -235 538 -235H553Q586 -235 602 -230Q683 -204 691 -128Q694 -99 706 -99T718 -120Q718 -121 718 -124T717 -130Q717 -199 670 -246T557 -294T393 -228Q353 -205 351 -201Q348 -201 315 -266Q294 -310 285 -323T268 -337Q259 -337 254 -331T248 -317Q248 -305 282 -246L313 -181Q313 -177 273 -161Q255 -157 222 -157Q188 -157 173 -161Q134 -174 113 -198T88 -242T82 -278T71 -294Q57 -294 57 -261Q57 -204 91 -161T179 -104Q195 -99 228 -99Q274 -102 315 -124Q337 -132 337 -130L404 -1L384 12Q319 58 273 74Q255 79 222 79Q188 79 173 74Q133 61 112 37T88 -7T82 -43T70 -59Q57 -59 57 -26',
    0x2AC5: '84 463Q84 585 166 663T360 752Q361 752 370 752T395 752T430 752T475 753T524 753H679Q694 746 694 733Q694 724 681 714L522 713H470H441Q366 713 338 709T266 685Q244 674 224 659T179 617T139 550T124 463V458Q124 370 185 302Q244 238 328 220Q348 215 366 215T522 213H681Q694 203 694 193Q694 180 679 173H526Q510 173 480 173T434 172Q350 172 289 188T172 258Q84 340 84 463ZM84 -14T84 -1T98 19H679Q694 11 694 -1Q694 -14 679 -21H98Q84 -14 84 -1ZM84 -208T84 -195T98 -175H679Q694 -183 694 -195Q694 -208 679 -215H98Q84 -208 84 -195',
    0x2AC6: '83 733Q83 746 98 753H251Q267 753 297 753T343 754Q427 754 488 738T605 668Q693 587 693 463Q693 378 650 312T545 213T415 174Q407 173 251 173H98Q83 180 83 193Q83 203 96 213H255H308H337Q412 213 439 217T512 241Q533 252 553 267T599 309T639 376T654 463Q654 554 592 624Q557 662 512 685Q468 704 439 708T335 713H306H255L96 714Q83 724 83 733ZM84 -14T84 -1T98 19H679Q694 11 694 -1Q694 -14 679 -21H98Q84 -14 84 -1ZM84 -208T84 -195T98 -175H679Q694 -183 694 -195Q694 -208 679 -215H98Q84 -208 84 -195',
    0x2ACB: '693 221Q693 214 680 201H524Q398 201 367 202T309 212Q236 230 180 280T98 398Q84 438 84 492T98 585Q126 663 193 716T346 781Q347 781 373 781T440 782T520 783H680Q682 780 686 776T691 770T693 763T680 743H526Q364 743 353 741Q279 730 221 687T138 578Q124 540 124 492T138 405Q163 340 221 297T353 243Q364 241 526 241H680Q682 238 686 234T691 228T693 221ZM82 -48T82 -41T95 -19H462L513 41L569 105Q574 110 582 110T596 104T602 90Q602 87 600 83Q600 77 555 30L515 -17L600 -19H682Q693 -30 693 -38T680 -59H480L415 -137L349 -213L515 -215H682Q693 -226 693 -233T680 -255H313L260 -317Q224 -360 212 -372T192 -385Q184 -385 179 -377T173 -362Q174 -361 218 -306L260 -255H178L93 -253L86 -248Q82 -243 82 -235Q82 -226 93 -215H195L295 -213L362 -137L426 -59H260L93 -57L86 -53Q82 -48 82 -41',
    0x2ACC: '82 759T82 763T83 769T89 776T95 783H251Q378 783 409 782T469 772Q540 753 596 703T678 585Q691 546 691 492T678 398Q649 320 581 267T426 203Q415 201 251 201H95Q82 214 82 221Q82 225 83 227T89 234T95 241H249Q411 241 422 243Q496 253 554 296T638 405Q651 444 651 492Q651 539 638 578Q613 643 555 686T422 741Q411 743 249 743H95Q82 759 82 763ZM82 -48T82 -41T95 -19H462L513 41L569 105Q574 110 582 110T596 104T602 90Q602 87 600 83Q600 77 555 30L515 -17L600 -19H682Q693 -30 693 -38T680 -59H480L415 -137L349 -213L515 -215H682Q693 -226 693 -233T680 -255H313L260 -317Q224 -360 212 -372T192 -385Q184 -385 179 -377T173 -362Q174 -361 218 -306L260 -255H178L93 -253L86 -248Q82 -243 82 -235Q82 -226 93 -215H195L295 -213L362 -137L426 -59H260L93 -57L86 -53Q82 -48 82 -41',
    0x3008: '333 -232Q332 -239 327 -244T313 -250Q303 -250 296 -240Q293 -233 202 6T110 250T201 494T296 740Q299 745 306 749L309 750Q312 750 313 750Q331 750 333 732Q333 727 243 489Q152 252 152 250T243 11Q333 -227 333 -232',
    0x3009: '55 732Q56 739 61 744T75 750Q85 750 92 740Q95 733 186 494T278 250T187 6T92 -240Q85 -250 75 -250Q67 -250 62 -245T55 -232Q55 -227 145 11Q236 248 236 250T145 489Q55 727 55 732',
    0xE006: '91 417Q104 430 111 430T131 417V301L171 341Q201 373 207 378T220 384Q227 384 233 377T240 366Q240 357 187 299L131 244V-10Q116 -23 111 -23T91 -10V201L49 157Q20 127 14 121T0 115Q-8 115 -14 121T-20 132Q-20 139 17 178Q29 191 36 199L91 257V417',
    0xE007: '56 417Q68 431 76 431L89 426L96 419V317L98 215L193 273L291 330V375L293 419Q301 431 311 431Q331 431 331 388L333 355L356 370Q381 384 388 384Q394 384 400 377T407 363Q407 354 367 328L331 308V-10Q316 -23 310 -23Q300 -23 293 -12L291 135V284L98 168L96 77V-12Q84 -24 76 -24L62 -19L58 -12L56 66V144L31 128Q5 114 -2 114Q-8 114 -14 121T-20 136Q-20 142 -14 147T20 170L56 190V417',
    0xE008: '477 261Q477 257 473 256T455 253T417 251T348 250H235L155 -77L146 -82Q137 -85 109 -85Q55 -85 55 -77L139 261Q224 596 226 598Q229 603 239 603Q240 603 254 603T290 603T341 604T405 605T477 605Q656 603 687 602T719 596Q719 589 692 588T513 585H319L282 427L242 272Q242 270 351 270Q388 270 410 270T444 269T460 267T469 265T477 261',
    0xE009: '228 325Q170 322 156 316T127 309Q108 309 104 314Q99 319 99 322T108 341Q125 376 171 400T268 425H271Q302 425 319 396Q328 377 328 358Q328 332 324 314Q311 270 286 221Q274 194 274 192H275Q339 234 484 325T639 421Q669 434 691 434T723 425T734 406Q734 394 719 381Q715 376 644 330L575 287L566 267Q543 233 526 176Q520 160 515 143T508 115T506 105Q506 103 533 103Q585 103 607 110T641 118Q670 118 670 107Q670 100 661 85Q643 50 598 27T504 3Q465 3 450 36Q441 51 441 73Q441 84 444 96Q452 146 484 205L497 236L324 125Q143 12 135 10Q103 -6 77 -6Q61 -6 49 2T37 21Q37 36 49 46T124 96L195 141L204 156Q219 179 243 248T264 323Q264 325 228 325',
    0xE00C: '86 472Q93 477 381 614T673 752Q680 752 686 746T693 732T689 721Q686 715 418 590L151 461L418 332Q684 207 689 201Q693 195 693 190Q693 183 687 177T675 170Q668 170 380 307T86 450Q82 454 82 461Q82 467 86 472ZM369 101V126Q369 156 382 156H384Q385 157 386 157Q409 157 409 115V98V54H680Q693 39 693 34T680 14H409V-142H680Q693 -155 693 -162Q693 -167 680 -182H409V-273Q396 -284 388 -284Q382 -284 369 -275V-182H95Q82 -167 82 -162Q82 -155 95 -142H369V14H95Q93 17 89 21T84 27T82 34T83 40T89 47T95 54H369V101',
    0xE00D: '89 745Q95 752 100 752Q106 752 394 615T689 472Q693 468 693 461T689 450Q684 445 396 308T100 170Q95 170 89 176T82 190Q82 195 86 201Q91 208 358 332L624 461L358 590Q90 715 86 721Q82 725 82 731Q82 739 89 745ZM369 101V126Q369 156 382 156H384Q385 157 386 157Q409 157 409 115V98V54H680Q693 39 693 34T680 14H409V-142H680Q693 -155 693 -162Q693 -167 680 -182H409V-273Q396 -284 388 -284Q382 -284 369 -275V-182H95Q82 -167 82 -162Q82 -155 95 -142H369V14H95Q93 17 89 21T84 27T82 34T83 40T89 47T95 54H369V101',
    0xE00E: '97 172Q82 172 82 190Q82 197 86 201Q94 209 173 246T327 319T402 357Q405 360 434 448T462 539L278 628Q96 713 86 721Q82 725 82 732T88 745T102 752Q103 752 125 742T198 709T293 666Q342 642 385 622T453 590T478 579Q479 579 506 659T562 824T598 915Q602 919 609 919T622 913T629 901Q629 898 571 728Q546 656 531 608T518 559Q555 539 602 519Q664 488 679 479T694 461Q694 457 689 450Q680 443 616 413T494 356T435 326L389 190L342 57L513 55H682Q694 43 694 34Q694 28 689 21L682 17L506 15H329L322 -8Q320 -13 310 -41T295 -85L275 -141H680Q682 -143 684 -146T688 -151T691 -156T693 -162Q693 -172 682 -179L473 -181H262L220 -303Q192 -388 185 -404T166 -421Q160 -421 153 -415T146 -403Q146 -400 179 -302T220 -185Q220 -181 158 -181L93 -179L86 -174Q82 -169 82 -161Q82 -152 93 -141H164L233 -139L260 -63L286 15H189L93 17L86 21Q82 26 82 34Q82 44 93 55H198L300 57L342 179Q350 204 361 238T378 286T382 301L246 237Q111 172 97 172ZM624 461Q621 464 560 492Q512 518 503 518Q500 518 500 517Q499 513 488 479T465 413T453 379L624 461',
    0xE00F: '97 54Q82 54 82 72Q82 79 86 84Q95 91 222 153L351 215L398 324L442 433L258 519Q95 597 87 604Q82 608 82 615T88 628T102 635Q107 635 424 484L458 468L524 630Q593 789 597 795Q601 801 609 801Q616 801 622 795T629 781L562 615L493 450L589 406Q665 371 679 362T694 344Q694 339 693 337T677 326T631 302T538 257Q504 241 465 223T406 195T386 186Q383 185 344 92T306 -3L486 81Q662 168 673 168Q680 168 686 162T693 148T689 137Q688 136 482 35L280 -59L233 -176Q184 -291 178 -299Q172 -303 166 -303T153 -297T146 -283Q146 -279 185 -186T224 -90Q225 -88 223 -88Q219 -88 193 -101Q109 -143 98 -143Q82 -138 82 -122Q82 -116 85 -113T108 -98T171 -67L249 -30L289 61Q297 81 307 107T321 144T326 157L218 106Q109 54 97 54ZM553 379Q480 412 480 415Q479 415 460 372T423 285T406 241Q408 240 516 291T624 344L553 379',
    0xE010: '102 168Q103 168 151 146T247 102T295 81Q299 85 322 144T344 206L218 268Q153 297 123 313T87 333T82 344T86 355Q104 369 291 455Q491 552 491 553L542 673Q581 767 590 784T609 801Q616 801 622 795T629 781Q629 773 586 677Q546 581 546 577L609 606Q669 635 673 635Q680 635 686 629T693 615Q693 610 692 608T670 593T604 561L524 521L400 226L542 157Q617 123 649 107T687 85T694 72Q694 66 690 60T679 54Q665 54 526 119Q394 186 386 186Q385 186 342 88L331 61L509 -23Q680 -105 688 -111Q693 -115 693 -122T688 -135T675 -141H673Q664 -141 491 -59Q320 21 316 21H315L249 -136Q183 -293 178 -299Q172 -303 166 -303T153 -297T146 -283Q146 -282 154 -261T181 -197T213 -119L280 41Q280 46 186 86Q157 101 121 119Q92 133 87 136T82 148Q82 155 88 161T102 168ZM418 370L466 495Q464 495 308 420T151 344T204 317T311 267T364 244Q364 247 418 370',
    0xE011: '82 34Q82 44 93 55H198L300 57L342 179Q351 207 362 238T378 286T384 303T238 377Q109 435 86 450Q82 454 82 460T86 472Q90 476 302 579L511 679Q512 679 553 795Q569 842 577 866T592 903T600 917T608 919Q615 919 622 912T629 901Q629 899 595 799Q589 777 581 753T569 717T564 703L618 728Q666 752 673 752T686 746T693 732Q693 723 683 717T615 683L546 650L491 488Q464 410 450 368T438 326Q493 297 562 266Q660 219 677 209T694 190Q694 183 690 177T678 171Q664 171 546 228L424 286Q422 286 382 172L342 57L513 55H682Q694 43 694 34Q694 28 689 21L682 17L506 15H329L322 -8Q320 -13 310 -41T295 -85L275 -141H680Q682 -143 684 -146T688 -151T691 -156T693 -162Q693 -172 682 -179L473 -181H262L220 -303Q192 -388 185 -404T166 -421Q160 -421 153 -415T146 -403Q146 -400 179 -302T220 -185Q220 -181 158 -181L93 -179L86 -174Q82 -169 82 -161Q82 -152 93 -141H164L233 -139L260 -63L286 15H189L93 17L86 21Q82 26 82 34ZM495 623Q495 626 493 626T321 544T151 461L398 343Q399 343 405 360T423 415T446 483Q457 513 469 551T488 606T495 623',
    0xE016: '82 -6Q82 1 95 14H262L295 94Q331 171 331 174Q324 175 312 178T267 194T206 227T146 283T98 368Q84 406 84 461T98 554Q126 632 194 685T349 750Q360 752 480 752H591L604 783Q620 819 624 821Q631 828 640 828Q653 825 658 810Q658 808 646 781L635 754Q635 752 658 752Q680 752 686 746Q693 739 693 732Q693 728 692 726T686 719T680 712H615L506 466Q479 407 451 344T408 248T393 214Q393 210 535 210H680Q693 194 693 190T680 170H373L340 92L304 14H680Q693 1 693 -6Q693 -11 680 -26H286L253 -103L218 -179L451 -181H682Q694 -193 694 -201Q694 -212 682 -219L440 -221H200L178 -270Q160 -309 154 -319T139 -330Q122 -330 118 -312L155 -223Q155 -221 126 -221H95Q82 -206 82 -201T95 -181H175L206 -108Q237 -35 242 -30Q242 -26 169 -26H95Q82 -11 82 -6ZM571 710Q571 712 469 712Q443 712 416 712T371 711T351 710Q279 700 221 656T138 548Q124 508 124 461T138 374Q186 245 351 212L460 459Q571 709 571 710',
    0xE017: '82 -14T82 -7T95 15H431L529 170H435Q341 170 333 175Q149 218 98 368Q84 406 84 461Q84 515 98 555Q126 633 193 686T346 750Q347 750 373 750T440 751T520 752H680Q693 739 693 732Q693 727 680 712H526Q364 712 353 710Q268 700 207 646T126 512Q123 496 123 461T126 410Q141 350 180 304T280 232Q312 217 344 214T464 210H555L589 261Q613 301 620 311T635 321Q644 321 650 315T657 301Q657 296 651 286T630 252T604 212Q604 210 642 210H680Q693 197 693 190Q693 186 692 184T686 177T680 170H578L526 92L478 17L580 15H682Q693 4 693 -4T680 -25H451L353 -179L518 -181H682Q694 -193 694 -201Q694 -211 682 -219L504 -221H326L293 -272Q257 -332 246 -332Q238 -332 232 -326T225 -313Q225 -310 226 -308Q226 -305 251 -265T278 -223Q278 -221 186 -221H95Q93 -218 89 -214T84 -208T82 -201T95 -181H306L404 -25H249L93 -23L86 -19Q82 -14 82 -7',
    0xE018: '82 732Q82 739 95 752H251H348Q420 752 460 744T551 708Q566 697 566 701Q618 815 624 821Q631 828 640 828Q653 825 658 810L600 677Q600 671 615 656T653 605T689 517Q692 496 692 461T689 406Q668 325 615 266Q572 221 513 196T391 170H373L340 92L304 14H680Q693 1 693 -6Q693 -11 680 -26H286L253 -103L218 -179L451 -181H682Q694 -193 694 -201Q694 -212 682 -219L440 -221H200L178 -270Q160 -309 154 -319T139 -330Q122 -330 118 -312L155 -223Q155 -221 126 -221H95Q82 -206 82 -201T95 -181H175L206 -108Q237 -35 242 -30Q242 -26 169 -26H95Q82 -11 82 -6Q82 1 95 14H262L295 92L331 170H95Q93 172 91 175T87 180T84 185T82 191Q82 199 93 210H220L349 212L549 659Q507 692 462 702T338 712H249H95Q82 727 82 732ZM652 473Q652 513 636 552T603 611T582 632Q581 632 487 422T393 210Q424 210 460 220T535 253T605 316T649 410Q652 427 652 461V473',
    0xE019: '82 732Q82 739 95 752H251Q415 752 426 750Q539 736 615 657Q667 599 689 517Q692 496 692 461T689 406Q668 325 615 266Q522 170 382 170H355L326 95Q319 80 311 59T298 28T293 17Q293 15 486 15H680Q693 0 693 -6T680 -25H275L213 -179L449 -181H682Q693 -192 693 -199T680 -221H198L178 -270Q153 -333 139 -333Q132 -333 126 -327T119 -314T135 -266T153 -223Q153 -221 124 -221H95Q82 -207 82 -201T95 -181H171L233 -25H162L93 -23L86 -19Q82 -14 82 -7T95 15H251L313 170H202L93 172L86 177Q82 182 82 190Q82 199 93 210H211L329 212L349 261Q366 301 372 311T386 321Q392 321 399 315T407 302Q407 295 390 254T373 210Q374 209 377 209Q412 209 444 217Q512 231 564 273T638 377Q651 414 651 461Q651 509 638 548Q613 613 555 656T422 710Q411 712 249 712H95Q82 727 82 732',
    0xE01A: '693 -115T693 -122T680 -144H315L269 -199Q221 -255 213 -255H212Q203 -255 197 -248T193 -231Q195 -225 229 -184L262 -144H186L113 -142L106 -137Q102 -130 102 -125Q102 -119 115 -104H298L426 52H386Q342 54 309 63Q236 79 180 129T98 249Q84 289 84 343Q84 398 98 436Q126 514 193 567T346 632Q347 632 373 632T440 633T520 634H680Q682 631 686 627T691 621T693 614T680 594H526Q364 594 353 592Q268 581 207 528T126 394Q123 378 123 343T126 292Q141 231 181 185T280 114Q329 92 415 92H462L506 147Q554 203 562 203H563Q572 203 578 196T582 178Q579 173 546 132L513 94L598 92H682Q693 81 693 73T680 52H480L349 -102L515 -104H682Q693 -115 693 -122',
    0xE01B: '82 610T82 614T83 620T89 627T95 634H251Q378 634 409 633T469 623Q540 604 596 554T678 436Q691 397 691 343T678 249Q653 181 597 131T469 63Q427 52 362 52H315L213 -102L438 -104H662Q673 -115 673 -123Q673 -129 660 -144H186L151 -197Q114 -250 109 -253Q106 -254 104 -254Q100 -254 98 -253Q91 -253 87 -248T82 -235Q82 -230 109 -186L138 -144H115Q82 -144 82 -125Q82 -119 95 -104H166L266 49Q266 52 182 52H95Q82 65 82 72Q82 76 83 78T89 85T95 92H295L329 143Q365 195 369 198Q372 203 380 203Q385 203 391 197T398 185Q398 184 398 184L399 182Q399 175 369 129L344 94Q344 92 376 92Q402 92 422 94Q496 104 554 147T638 256Q651 295 651 343Q651 390 638 429Q613 494 555 537T422 592Q411 594 249 594H95Q82 610 82 614',
    0x1D400: '296 0Q278 3 164 3Q58 3 49 0H40V62H92Q144 62 144 64Q388 682 397 689Q403 698 434 698Q463 698 471 689Q475 686 538 530T663 218L724 64Q724 62 776 62H828V0H817Q796 3 658 3Q509 3 485 0H472V62H517Q561 62 561 63L517 175H262L240 120Q218 65 217 64Q217 62 261 62H306V0H296ZM390 237L492 238L440 365Q390 491 388 491Q287 239 287 237H390',
    0x1D401: '720 510Q720 476 704 448T665 404T619 377T580 362L564 359L583 356Q602 353 632 342T690 312Q712 292 725 276Q752 235 752 189V183Q752 160 741 125Q698 18 547 2Q543 1 288 0H39V62H147V624H39V686H264H409Q502 686 542 681T624 655Q720 607 720 510ZM563 513Q563 553 548 578T518 611T486 622Q479 624 385 624H293V382H375Q458 383 467 385Q563 405 563 513ZM590 192Q590 307 505 329Q504 330 503 330L398 331H293V62H391H400H444Q496 62 528 75T580 131Q590 155 590 192',
    0x1D402: '64 343Q64 502 174 599T468 697Q502 697 533 691T586 674T623 655T647 639T657 632L694 663Q703 670 711 677T723 687T730 692T735 695T740 696T746 697Q759 697 762 692T766 668V627V489V449Q766 428 762 424T742 419H732H720Q699 419 697 436Q690 498 657 545Q611 618 532 632Q522 634 496 634Q356 634 286 553Q232 488 232 343T286 133Q355 52 497 52Q597 52 650 112T704 237Q704 248 709 251T729 254H735Q750 254 755 253T763 248T766 234Q766 136 680 63T469 -11Q285 -11 175 86T64 343',
    0x1D403: '39 624V686H270H310H408Q500 686 545 680T638 649Q768 584 805 438Q817 388 817 338Q817 171 702 75Q628 17 515 2Q504 1 270 0H39V62H147V624H39ZM655 337Q655 370 655 390T650 442T639 494T616 540T580 580T526 607T451 623Q443 624 368 624H298V62H377H387H407Q445 62 472 65T540 83T606 129Q629 156 640 195T653 262T655 337',
    0x1D404: '723 286Q721 284 700 145T677 3V0H39V62H147V618H39V680H660V676Q662 670 675 552T691 428V424H629V428Q629 429 627 448T618 494T601 541Q574 593 527 605T382 618H374H304V384H336Q338 384 347 384T361 384T376 386T392 390T407 397T421 407T432 423Q442 444 443 482V501H505V205H443V224Q442 258 435 278T411 307T380 318T336 322H304V62H375H394Q429 62 449 62T497 66T541 76T577 95T609 126T632 170T651 232Q661 287 661 289H723V286',
    0x1D405: '425 0L228 3Q63 3 51 0H39V62H147V618H39V680H644V676Q647 670 659 552T675 428V424H613Q613 433 605 477Q599 511 589 535T562 574T530 599T488 612T441 617T387 618H368H304V371H333Q389 373 411 390T437 468V488H499V192H437V212Q436 244 430 263T408 292T378 305T333 309H304V62H439V0H425',
    0x1D406: '465 -10Q281 -10 173 88T64 343Q64 413 85 471T143 568T217 631T298 670Q371 697 449 697Q452 697 459 697T470 696Q502 696 531 690T582 675T618 658T644 641T656 632L732 695Q734 697 745 697Q758 697 761 692T765 668V627V489V449Q765 428 761 424T741 419H731H724Q705 419 702 422T695 444Q683 520 631 577T495 635Q364 635 295 563Q261 528 247 477T232 343Q232 296 236 260T256 185T296 120T366 76T472 52Q481 51 498 51Q544 51 573 67T607 108Q608 111 608 164V214H464V276H479Q506 273 680 273Q816 273 834 276H845V214H765V113V51Q765 16 763 8T750 0Q742 2 709 16T658 40L648 46Q592 -10 465 -10',
    0x1D407: '400 0Q376 3 226 3Q75 3 51 0H39V62H147V624H39V686H51Q75 683 226 683Q376 683 400 686H412V624H304V388H595V624H487V686H499Q523 683 673 683Q824 683 848 686H860V624H752V62H860V0H848Q824 3 674 3Q523 3 499 0H487V62H595V326H304V62H412V0H400',
    0x1D408: '397 0Q370 3 218 3Q65 3 38 0H25V62H139V624H25V686H38Q65 683 218 683Q370 683 397 686H410V624H296V62H410V0H397',
    0x1D409: '174 114Q174 96 169 82T159 63T144 47L155 45Q183 40 203 40Q271 40 290 104Q294 118 294 150T295 380V624H154V686H169Q196 683 365 683Q499 683 517 686H527V624H446V379Q446 183 446 153T441 108Q413 32 315 2Q266 -11 208 -11Q160 -11 118 -2T42 37T8 114V122Q8 150 30 174T91 198T152 174T174 122V114',
    0x1D40A: '400 0Q376 3 226 3Q75 3 51 0H39V62H147V624H39V686H51Q75 683 226 683Q376 683 400 686H412V624H304V338L472 483L634 624H565V686H576Q597 683 728 683Q814 683 829 686H836V624H730L614 524Q507 432 497 422Q496 422 498 418T514 395T553 342T627 241L759 63L805 62H852V0H842Q830 3 701 3Q550 3 526 0H513V62H549Q584 62 584 63Q583 65 486 196T388 328L304 256V62H412V0H400',
    0x1D40B: '643 285Q641 280 629 148T612 4V0H39V62H147V624H39V686H51Q75 683 228 683Q415 685 425 686H439V624H304V62H352H378Q492 62 539 138Q551 156 558 178T569 214T576 255T581 289H643V285',
    0x1D40C: '314 0Q296 3 181 3T48 0H39V62H147V624H39V686H305Q316 679 323 667Q330 653 434 414L546 157L658 414Q766 662 773 674Q778 681 788 686H1052V624H944V62H1052V0H1040Q1016 3 874 3T708 0H696V62H804V341L803 618L786 580Q770 543 735 462T671 315Q540 13 536 9Q528 1 507 1Q485 1 477 9Q472 14 408 162T281 457T217 603Q215 603 215 334V62H323V0H314',
    0x1D40D: '314 0Q296 3 181 3T48 0H39V62H147V624H39V686H171H265Q288 686 297 686T309 684T315 679Q317 676 500 455T684 233V624H576V686H585Q603 683 718 683T851 686H860V624H752V319Q752 15 750 11Q747 4 742 2T718 0H712Q708 0 706 0T700 0T696 1T693 2T690 4T687 7T684 11T679 16T674 23Q671 27 437 311L215 579V62H323V0H314',
    0x1D40E: '64 339Q64 431 96 502T182 614T295 675T420 696Q469 696 481 695Q620 680 709 589T798 339Q798 173 697 82T432 -10Q262 -10 163 85T64 339ZM625 454Q618 502 600 538T562 593T515 624T469 639T431 642Q331 642 276 563Q232 493 232 353Q232 315 234 285T244 216T267 148T308 94T372 56Q405 46 432 46Q517 46 567 106T627 267Q631 299 631 353Q631 418 625 454',
    0x1D40F: '400 0Q376 3 226 3Q75 3 51 0H39V62H147V624H39V686H253Q435 686 470 685T536 678Q585 668 621 648T675 605T705 557T718 514T721 483T718 451T704 409T673 362T616 322T530 293Q500 288 399 287H304V62H412V0H400ZM553 475Q553 554 537 582T459 622Q451 623 373 624H298V343H372Q457 344 480 350Q527 362 540 390T553 475',
    0x1D410: '64 339Q64 431 96 502T182 614T295 675T420 696Q469 696 481 695Q620 680 709 589T798 339Q798 255 768 184Q720 77 611 26L600 21Q635 -26 682 -26H696Q769 -26 769 0Q769 7 774 12T787 18Q805 18 805 -7V-13Q803 -64 785 -106T737 -171Q720 -183 697 -191Q687 -193 668 -193Q636 -193 613 -182T575 -144T552 -94T532 -27Q531 -23 530 -16T528 -6T526 -3L512 -5Q499 -7 477 -8T431 -10Q393 -10 382 -9Q238 8 151 97T64 339ZM326 80Q326 113 356 138T430 163Q492 163 542 100L553 86Q554 85 561 91T578 108Q637 179 637 330Q637 430 619 498T548 604Q500 641 425 641Q408 641 390 637T347 623T299 590T259 535Q226 469 226 338Q226 244 246 180T318 79L325 74Q326 74 326 80ZM506 58Q480 112 433 112Q412 112 395 104T378 77Q378 44 431 44Q480 44 506 58',
    0x1D411: '394 0Q370 3 222 3Q75 3 51 0H39V62H147V624H39V686H234Q256 686 299 686T362 687Q479 687 554 669T681 593Q716 550 716 497Q716 390 568 338Q569 337 572 336T577 332Q605 317 623 300T650 258T662 218T668 172Q678 98 689 76Q707 40 748 40Q770 40 780 54T795 88T801 111Q805 117 827 117H831Q846 117 852 113T858 92Q857 78 852 63T834 30T797 1T739 -11Q630 -11 580 12T511 87Q506 104 506 168Q506 170 506 178T507 194Q507 289 438 313Q424 318 356 318H298V62H406V0H394ZM366 369Q459 370 490 381Q548 402 548 476V498V517Q548 578 513 600Q479 624 392 624H358H298V369H366',
    0x1D412: '64 493Q64 582 120 636T264 696H272Q280 697 285 697Q380 697 454 645L480 669Q484 672 488 676T495 683T500 688T504 691T508 693T511 695T514 696T517 697T522 697Q536 697 539 691T542 652V577Q542 557 542 532T543 500Q543 472 540 465T524 458H511H505Q489 458 485 461T479 478Q472 529 449 564T393 614T336 634T287 639Q228 639 203 610T177 544Q177 517 195 493T247 457Q253 454 343 436T475 391Q574 326 574 207V200Q574 163 559 120Q517 12 389 -9Q380 -10 346 -10Q308 -10 275 -5T221 7T184 22T160 35T151 40L126 17Q122 14 118 10T111 3T106 -2T102 -5T98 -7T95 -9T92 -10T89 -11T84 -11Q70 -11 67 -4T64 35V108Q64 128 64 153T63 185Q63 203 63 211T69 223T77 227T94 228H100Q118 228 122 225T126 205Q130 125 193 88T345 51Q408 51 434 82T460 157Q460 196 439 221T388 257Q384 259 305 276T221 295Q155 313 110 366T64 493',
    0x1D413: '41 425Q41 426 51 545T62 669V675H737V669Q738 665 748 546T758 425V419H696V425Q687 517 669 555T595 607Q578 612 522 613H478V62H631V0H615Q585 3 399 3Q214 3 184 0H168V62H321V613H277H263Q164 613 134 561Q113 527 103 425V419H41V425',
    0x1D414: '570 686Q588 683 703 683T836 686H845V624H737V420Q737 390 737 345T738 284Q738 205 729 164T689 83Q614 -11 465 -11Q321 -11 240 51T148 207Q147 214 147 421V624H39V686H51Q75 683 226 683Q376 683 400 686H412V624H304V405V370V268Q304 181 311 146T346 87Q387 52 466 52Q642 52 667 195Q668 204 669 415V624H561V686H570',
    0x1D415: '592 686H604Q615 685 631 685T666 684T700 684T724 683Q829 683 835 686H843V624H744L611 315Q584 254 546 165Q492 40 482 19T461 -6L460 -7H409Q398 -4 391 9Q385 20 257 315L124 624H25V686H36Q57 683 190 683Q340 683 364 686H377V624H289L384 403L480 185L492 212Q504 240 529 298T575 405L670 624H582V686H592',
    0x1D416: '915 686L1052 683Q1142 683 1157 686H1164V624H1073L957 320Q930 249 900 170T855 52T839 10Q834 0 826 -5Q821 -7 799 -7H792Q777 -7 772 -5T759 10Q759 11 748 39T716 122T676 228L594 442L512 228Q486 159 455 78Q433 19 428 9T416 -5Q411 -7 389 -7H379Q356 -7 349 10Q349 12 334 51T288 170T231 320L116 624H24V686H35Q44 683 183 683Q331 683 355 686H368V624H323Q278 624 278 623L437 207L499 369L561 531L526 624H434V686H445Q454 683 593 683Q741 683 765 686H778V624H733Q688 624 688 623L847 207Q848 207 927 415T1006 624H905V686H915',
    0x1D417: '327 0Q306 3 174 3Q52 3 43 0H33V62H98L162 63L360 333L157 624H48V686H59Q80 683 217 683Q368 683 395 686H408V624H335L393 540L452 458L573 623Q573 624 528 624H483V686H494Q515 683 646 683Q769 683 778 686H787V624H658L575 511Q493 398 493 397L508 376Q522 356 553 312T611 229L727 62H835V0H824Q803 3 667 3Q516 3 489 0H476V62H513L549 63L401 274L247 63Q247 62 292 62H338V0H327',
    0x1D418: '605 0Q581 3 434 3Q286 3 262 0H250V62H358V275L126 624H19V686H30Q54 683 189 683Q361 685 370 686H383V624H308L319 608Q330 591 353 556T396 491L484 359L660 623Q660 624 623 624H585V686H595Q613 683 728 683Q832 683 841 686H849V624H742L509 274V62H618V0H605',
    0x1D419: '80 430L92 686H358Q624 686 628 684Q638 679 638 656Q638 640 637 639Q637 638 445 353Q401 288 351 214T277 103L253 67L256 66Q258 66 265 66T279 66T298 66H343Q380 66 406 68T464 81T518 110T557 164T579 250Q583 278 583 298Q583 299 614 299H645V291Q643 281 636 150T627 8V0H353Q79 0 75 2Q64 7 64 31Q64 48 66 52L259 340L451 623Q451 624 384 624Q294 623 259 612Q155 581 143 446Q142 440 142 432V430H80',
    0x1D41A: '64 349Q64 399 107 426T255 453Q346 453 402 423T473 341Q478 327 478 310T479 196V77Q493 63 529 62Q549 62 553 57T558 31Q558 9 552 5T514 0H497H481Q375 0 367 56L356 46Q300 -6 210 -6Q130 -6 81 30T32 121Q32 188 111 226T332 272H350V292Q350 313 348 327T337 361T306 391T248 402T194 399H189Q204 376 204 354Q204 327 187 306T134 284Q97 284 81 305T64 349ZM164 121Q164 89 186 67T238 45Q274 45 307 63T346 108L350 117V226H347Q248 218 206 189T164 121',
    0x1D41B: '32 686L123 690Q214 694 215 694H221V409Q289 450 378 450Q479 450 539 387T600 221Q600 122 535 58T358 -6H355Q272 -6 203 53L160 1L129 0H98V301Q98 362 98 435T99 525Q99 591 97 604T83 620Q69 624 42 624H29V686H32ZM227 105L232 99Q237 93 242 87T258 73T280 59T306 49T339 45Q380 45 411 66T451 131Q457 160 457 230Q457 264 456 284T448 329T430 367T396 389T343 398Q282 398 235 355L227 348V105',
    0x1D41C: '447 131H458Q478 131 478 117Q478 112 471 95T439 51T377 9Q330 -6 286 -6Q196 -6 135 35Q39 96 39 222Q39 324 101 384Q169 453 286 453Q359 453 411 431T464 353Q464 319 445 302T395 284Q360 284 343 305T325 353Q325 380 338 396H333Q317 398 295 398H292Q280 398 271 397T245 390T218 373T197 338T183 283Q182 275 182 231Q182 199 184 180T193 132T220 85T270 57Q289 50 317 50H326Q385 50 414 115Q419 127 423 129T447 131',
    0x1D41D: '351 686L442 690Q533 694 534 694H540V389Q540 327 540 253T539 163Q539 97 541 83T555 66Q569 62 596 62H609V31Q609 0 608 0Q588 0 510 -3T412 -6Q411 -6 411 16V38L401 31Q337 -6 265 -6Q159 -6 99 58T38 224Q38 265 51 303T92 375T165 429T272 449Q359 449 417 412V507V555Q417 597 415 607T402 620Q388 624 361 624H348V686H351ZM411 350Q362 399 291 399Q278 399 256 392T218 371Q195 351 189 320T182 238V221Q182 179 183 159T191 115T212 74Q241 46 288 46Q358 46 404 100L411 109V350',
    0x1D41E: '32 225Q32 332 102 392T272 452H283Q382 452 436 401Q494 343 494 243Q494 226 486 222T440 217Q431 217 394 217T327 218H175V209Q175 177 179 154T196 107T236 69T306 50Q312 49 323 49Q376 49 410 85Q421 99 427 111T434 127T442 133T463 135H468Q494 135 494 117Q494 110 489 97T468 66T431 32T373 5T292 -6Q181 -6 107 55T32 225ZM383 276Q377 346 348 374T280 402Q253 402 230 390T195 357Q179 331 176 279V266H383V276',
    0x1D41F: '308 0Q290 3 172 3Q58 3 49 0H40V62H109V382H42V444H109V503L110 562L112 572Q127 625 178 658T316 699Q318 699 330 699T348 700Q381 698 404 687T436 658T449 629T452 606Q452 576 432 557T383 537Q355 537 335 555T314 605Q314 635 328 649H325Q311 649 293 644T253 618T227 560Q226 555 226 498V444H340V382H232V62H318V0H308',
    0x1D420: '50 300Q50 368 105 409T255 450Q328 450 376 426L388 420Q435 455 489 455Q517 455 533 441T554 414T558 389Q558 367 544 353T508 339Q484 339 471 354T458 387Q458 397 462 400Q464 401 461 400Q459 400 454 399Q429 392 427 390Q454 353 459 328Q461 315 461 300Q461 240 419 202Q364 149 248 149Q185 149 136 172Q129 158 129 148Q129 105 170 93Q176 91 263 91Q273 91 298 91T334 91T366 89T400 85T432 77T466 64Q544 22 544 -69Q544 -114 506 -145Q438 -201 287 -201Q149 -201 90 -161T30 -70Q30 -58 33 -47T42 -27T54 -13T69 -1T82 6T94 12T101 15Q66 57 66 106Q66 151 90 187L97 197L89 204Q50 243 50 300ZM485 403H492Q491 404 488 404L485 403V403ZM255 200Q279 200 295 206T319 219T331 242T335 268T336 300Q336 337 333 352T317 380Q298 399 255 399Q228 399 211 392T187 371T178 345T176 312V300V289Q176 235 194 219Q215 200 255 200ZM287 -150Q357 -150 400 -128T443 -71Q443 -65 442 -61T436 -50T420 -37T389 -27T339 -21L308 -20Q276 -20 253 -20Q190 -20 180 -20T156 -26Q130 -38 130 -69Q130 -105 173 -127T287 -150',
    0x1D421: '40 686L131 690Q222 694 223 694H229V533L230 372L238 381Q248 394 264 407T317 435T398 450Q428 450 448 447T491 434T529 402T551 346Q553 335 554 198V62H623V0H614Q596 3 489 3Q374 3 365 0H356V62H425V194V275Q425 348 416 373T371 399Q326 399 288 370T238 290Q236 281 235 171V62H304V0H295Q277 3 171 3Q64 3 46 0H37V62H106V332Q106 387 106 453T107 534Q107 593 105 605T91 620Q77 624 50 624H37V686H40',
    0x1D422: '72 610Q72 649 98 672T159 695Q193 693 217 670T241 610Q241 572 217 549T157 525Q120 525 96 548T72 610ZM46 442L136 446L226 450H232V62H294V0H286Q271 3 171 3Q67 3 49 0H40V62H109V209Q109 358 108 362Q103 380 55 380H43V442H46',
    0x1D423: '104 610Q104 649 130 672T191 695Q225 693 249 670T273 610Q273 572 249 549T189 525Q152 525 128 548T104 610ZM78 442L173 446L268 450H274V196Q274 -5 274 -37T269 -83Q256 -132 201 -166T71 -200Q10 -200 -30 -173T-71 -102Q-71 -70 -51 -51T-1 -31Q27 -31 48 -49T69 -100Q69 -121 53 -147H56Q66 -149 77 -149H80Q90 -149 100 -146T127 -125T149 -73Q151 -55 151 149V362Q150 364 148 366T145 370T142 373T138 375T133 377T124 378T113 379T97 380H75V442H78',
    0x1D424: '32 686L123 690Q214 694 215 694H221V255L377 382H346V444H355Q370 441 476 441Q544 441 556 444H562V382H476L347 277L515 62H587V0H579Q564 3 476 3Q370 3 352 0H343V62H358L373 63L260 206L237 189L216 172V62H285V0H277Q259 3 157 3Q46 3 37 0H29V62H98V332Q98 387 98 453T99 534Q99 593 97 605T83 620Q69 624 42 624H29V686H32',
    0x1D425: '43 686L134 690Q225 694 226 694H232V62H301V0H292Q274 3 170 3Q67 3 49 0H40V62H109V332Q109 387 109 453T110 534Q110 593 108 605T94 620Q80 624 53 624H40V686H43',
    0x1D426: '40 442Q217 450 218 450H224V365Q226 367 235 378T254 397T278 416T314 435T362 448Q376 450 400 450H406Q503 450 534 393Q545 376 545 370Q545 368 555 379Q611 450 716 450Q774 450 809 434Q850 414 861 379T873 276V213V198V62H942V0H933Q915 3 809 3Q702 3 684 0H675V62H744V194V275Q744 348 735 373T690 399Q645 399 607 370T557 290Q555 281 554 171V62H623V0H614Q596 3 489 3Q374 3 365 0H356V62H425V194V275Q425 348 416 373T371 399Q326 399 288 370T238 290Q236 281 235 171V62H304V0H295Q277 3 171 3Q64 3 46 0H37V62H106V210V303Q106 353 104 363T91 376Q77 380 50 380H37V442H40',
    0x1D427: '40 442Q217 450 218 450H224V407L225 365Q233 378 245 391T289 422T362 448Q374 450 398 450Q428 450 448 447T491 434T529 402T551 346Q553 335 554 198V62H623V0H614Q596 3 489 3Q374 3 365 0H356V62H425V194V275Q425 348 416 373T371 399Q326 399 288 370T238 290Q236 281 235 171V62H304V0H295Q277 3 171 3Q64 3 46 0H37V62H106V210V303Q106 353 104 363T91 376Q77 380 50 380H37V442H40',
    0x1D428: '287 -5Q228 -5 182 10T109 48T63 102T39 161T32 219Q32 272 50 314T94 382T154 423T214 446T265 452H279Q319 452 326 451Q428 439 485 376T542 221Q542 156 514 108T442 33Q384 -5 287 -5ZM399 230V250Q399 280 398 298T391 338T372 372T338 392T282 401Q241 401 212 380Q190 363 183 334T175 230Q175 202 175 189T177 153T183 118T195 91T215 68T245 56T287 50Q348 50 374 84Q388 101 393 132T399 230',
    0x1D429: '32 442L123 446Q214 450 215 450H221V409Q222 409 229 413T251 423T284 436T328 446T382 450Q480 450 540 388T600 223Q600 128 539 61T361 -6H354Q292 -6 236 28L227 34V-132H296V-194H287Q269 -191 163 -191Q56 -191 38 -194H29V-132H98V113V284Q98 330 97 348T93 370T83 376Q69 380 42 380H29V442H32ZM457 224Q457 303 427 349T350 395Q282 395 235 352L227 345V104L233 97Q274 45 337 45Q383 45 420 86T457 224',
    0x1D42A: '38 220Q38 273 54 314T95 380T152 421T211 443T264 449Q368 449 429 386L438 377L484 450H540V-132H609V-194H600Q582 -191 475 -191Q360 -191 351 -194H342V-132H411V42Q409 41 399 34T383 25T367 16T347 7T324 1T296 -4T264 -6Q162 -6 100 56T38 220ZM287 46Q368 46 417 127V301L412 312Q398 347 369 371T302 395Q282 395 263 388T225 362T194 308T182 221Q182 126 214 86T287 46',
    0x1D42B: '405 293T374 293T324 312T305 361Q305 378 312 394Q315 397 315 399Q305 399 294 394T266 375T238 329T222 249Q221 241 221 149V62H308V0H298Q280 3 161 3Q47 3 38 0H29V62H98V210V303Q98 353 96 363T83 376Q69 380 42 380H29V442H32L118 446Q204 450 205 450H210V414L211 378Q247 449 315 449H321Q384 449 413 422T442 360Q442 332 424 313',
    0x1D42C: '38 315Q38 339 45 360T70 404T127 440T223 453Q273 453 320 436L338 445L357 453H366Q380 453 383 447T386 403V387V355Q386 331 383 326T365 321H355H349Q333 321 329 324T324 341Q317 406 224 406H216Q123 406 123 353Q123 334 143 321T188 304T244 294T285 286Q305 281 325 273T373 237T412 172Q414 162 414 142Q414 -6 230 -6Q154 -6 117 22L68 -6H58Q44 -6 41 0T38 42V73Q38 85 38 101T37 122Q37 144 42 148T68 153H75Q87 153 91 151T97 147T103 132Q131 46 220 46H230Q257 46 265 47Q330 58 330 108Q330 127 316 142Q300 156 284 162Q271 168 212 178T122 202Q38 243 38 315',
    0x1D42D: '272 49Q320 49 320 136V145V177H382V143Q382 106 380 99Q374 62 349 36T285 -2L272 -5H247Q173 -5 134 27Q109 46 102 74T94 160Q94 171 94 199T95 245V382H21V433H25Q58 433 90 456Q121 479 140 523T162 621V635H224V444H363V382H224V239V207V149Q224 98 228 81T249 55Q261 49 272 49',
    0x1D42E: '40 442L134 446Q228 450 229 450H235V273V165Q235 90 238 74T254 52Q268 46 304 46H319Q352 46 380 67T419 121L420 123Q424 135 425 199Q425 201 425 207Q425 233 425 249V316Q425 354 423 363T410 376Q396 380 369 380H356V442L554 450V267Q554 84 556 79Q561 62 610 62H623V31Q623 0 622 0Q603 0 527 -3T432 -6Q431 -6 431 25V56L420 45Q373 6 332 -1Q313 -6 281 -6Q208 -6 165 14T109 87L107 98L106 230Q106 358 104 366Q96 380 50 380H37V442H40',
    0x1D42F: '401 444Q413 441 495 441Q568 441 574 444H580V382H510L409 156Q348 18 339 6Q331 -4 320 -4Q318 -4 313 -4T303 -3H288Q273 -3 264 12T221 102Q206 135 197 156L96 382H26V444H34Q49 441 145 441Q252 441 270 444H279V382H231L284 264Q335 149 338 149Q338 150 389 264T442 381Q442 382 418 382H394V444H401',
    0x1D430: '624 444Q636 441 722 441Q797 441 800 444H805V382H741L593 11Q592 10 590 8T586 4T584 2T581 0T579 -2T575 -3T571 -3T567 -4T561 -4T553 -4H542Q525 -4 518 6T490 70Q474 110 463 137L415 257L367 137Q357 111 341 72Q320 17 313 7T289 -4H277Q259 -4 253 -2T238 11L90 382H25V444H32Q47 441 140 441Q243 441 261 444H270V382H222L310 164L382 342L366 382H303V444H310Q322 441 407 441Q508 441 523 444H531V382H506Q481 382 481 380Q482 376 529 259T577 142L674 382H617V444H624',
    0x1D431: '227 0Q212 3 121 3Q40 3 28 0H21V62H117L245 213L109 382H26V444H34Q49 441 143 441Q247 441 265 444H274V382H246L281 339Q315 297 316 297Q320 297 354 341L389 382H352V444H360Q375 441 466 441Q547 441 559 444H566V382H471L355 246L504 63L545 62H586V0H578Q563 3 469 3Q365 3 347 0H338V62H366Q366 63 326 112T285 163L198 63L217 62H235V0H227',
    0x1D432: '84 -102Q84 -110 87 -119T102 -138T133 -149Q148 -148 162 -143T186 -131T206 -114T222 -95T234 -76T243 -59T249 -45T252 -37L269 0L96 382H26V444H34Q49 441 146 441Q252 441 270 444H279V382H255Q232 382 232 380L337 151L442 382H394V444H401Q413 441 495 441Q568 441 574 444H580V382H510L406 152Q298 -84 297 -87Q269 -139 225 -169T131 -200Q85 -200 54 -172T23 -100Q23 -64 44 -50T87 -35Q111 -35 130 -50T152 -92V-100H84V-102',
    0x1D433: '48 262Q48 264 54 349T60 436V444H252Q289 444 336 444T394 445Q441 445 450 441T459 418Q459 406 458 404Q456 399 327 229T194 55H237Q260 56 268 56T297 58T325 65T348 77T370 98T384 128T395 170Q400 197 400 216Q400 217 431 217H462V211Q461 208 453 108T444 6V0H245Q46 0 43 2Q32 7 32 28V33Q32 41 40 52T84 112Q129 170 164 217L298 393H256Q189 392 165 380Q124 360 115 303Q110 280 110 256Q110 254 79 254H48V262',
    0x1D434: '208 74Q208 50 254 46Q272 46 272 35Q272 34 270 22Q267 8 264 4T251 0Q249 0 239 0T205 1T141 2Q70 2 50 0H42Q35 7 35 11Q37 38 48 46H62Q132 49 164 96Q170 102 345 401T523 704Q530 716 547 716H555H572Q578 707 578 706L606 383Q634 60 636 57Q641 46 701 46Q726 46 726 36Q726 34 723 22Q720 7 718 4T704 0Q701 0 690 0T651 1T578 2Q484 2 455 0H443Q437 6 437 9T439 27Q443 40 445 43L449 46H469Q523 49 533 63L521 213H283L249 155Q208 86 208 74ZM516 260Q516 271 504 416T490 562L463 519Q447 492 400 412L310 260L413 259Q516 259 516 260',
    0x1D435: '231 637Q204 637 199 638T194 649Q194 676 205 682Q206 683 335 683Q594 683 608 681Q671 671 713 636T756 544Q756 480 698 429T565 360L555 357Q619 348 660 311T702 219Q702 146 630 78T453 1Q446 0 242 0Q42 0 39 2Q35 5 35 10Q35 17 37 24Q42 43 47 45Q51 46 62 46H68Q95 46 128 49Q142 52 147 61Q150 65 219 339T288 628Q288 635 231 637ZM649 544Q649 574 634 600T585 634Q578 636 493 637Q473 637 451 637T416 636H403Q388 635 384 626Q382 622 352 506Q352 503 351 500L320 374H401Q482 374 494 376Q554 386 601 434T649 544ZM595 229Q595 273 572 302T512 336Q506 337 429 337Q311 337 310 336Q310 334 293 263T258 122L240 52Q240 48 252 48T333 46Q422 46 429 47Q491 54 543 105T595 229',
    0x1D436: '50 252Q50 367 117 473T286 641T490 704Q580 704 633 653Q642 643 648 636T656 626L657 623Q660 623 684 649Q691 655 699 663T715 679T725 690L740 705H746Q760 705 760 698Q760 694 728 561Q692 422 692 421Q690 416 687 415T669 413H653Q647 419 647 422Q647 423 648 429T650 449T651 481Q651 552 619 605T510 659Q484 659 454 652T382 628T299 572T226 479Q194 422 175 346T156 222Q156 108 232 58Q280 24 350 24Q441 24 512 92T606 240Q610 253 612 255T628 257Q648 257 648 248Q648 243 647 239Q618 132 523 55T319 -22Q206 -22 128 53T50 252',
    0x1D437: '287 628Q287 635 230 637Q207 637 200 638T193 647Q193 655 197 667T204 682Q206 683 403 683Q570 682 590 682T630 676Q702 659 752 597T803 431Q803 275 696 151T444 3L430 1L236 0H125H72Q48 0 41 2T33 11Q33 13 36 25Q40 41 44 43T67 46Q94 46 127 49Q141 52 146 61Q149 65 218 339T287 628ZM703 469Q703 507 692 537T666 584T629 613T590 629T555 636Q553 636 541 636T512 636T479 637H436Q392 637 386 627Q384 623 313 339T242 52Q242 48 253 48T330 47Q335 47 349 47T373 46Q499 46 581 128Q617 164 640 212T683 339T703 469',
    0x1D438: '492 213Q472 213 472 226Q472 230 477 250T482 285Q482 316 461 323T364 330H312Q311 328 277 192T243 52Q243 48 254 48T334 46Q428 46 458 48T518 61Q567 77 599 117T670 248Q680 270 683 272Q690 274 698 274Q718 274 718 261Q613 7 608 2Q605 0 322 0H133Q31 0 31 11Q31 13 34 25Q38 41 42 43T65 46Q92 46 125 49Q139 52 144 61Q146 66 215 342T285 622Q285 629 281 629Q273 632 228 634H197Q191 640 191 642T193 659Q197 676 203 680H757Q764 676 764 669Q764 664 751 557T737 447Q735 440 717 440H705Q698 445 698 453L701 476Q704 500 704 528Q704 558 697 578T678 609T643 625T596 632T532 634H485Q397 633 392 631Q388 629 386 622Q385 619 355 499T324 377Q347 376 372 376H398Q464 376 489 391T534 472Q538 488 540 490T557 493Q562 493 565 493T570 492T572 491T574 487T577 483L544 351Q511 218 508 216Q505 213 492 213',
    0x1D439: '48 1Q31 1 31 11Q31 13 34 25Q38 41 42 43T65 46Q92 46 125 49Q139 52 144 61Q146 66 215 342T285 622Q285 629 281 629Q273 632 228 634H197Q191 640 191 642T193 659Q197 676 203 680H742Q749 676 749 669Q749 664 736 557T722 447Q720 440 702 440H690Q683 445 683 453Q683 454 686 477T689 530Q689 560 682 579T663 610T626 626T575 633T503 634H480Q398 633 393 631Q388 629 386 623Q385 622 352 492L320 363H375Q378 363 398 363T426 364T448 367T472 374T489 386Q502 398 511 419T524 457T529 475Q532 480 548 480H560Q567 475 567 470Q567 467 536 339T502 207Q500 200 482 200H470Q463 206 463 212Q463 215 468 234T473 274Q473 303 453 310T364 317H309L277 190Q245 66 245 60Q245 46 334 46H359Q365 40 365 39T363 19Q359 6 353 0H336Q295 2 185 2Q120 2 86 2T48 1',
    0x1D43A: '50 252Q50 367 117 473T286 641T490 704Q580 704 633 653Q642 643 648 636T656 626L657 623Q660 623 684 649Q691 655 699 663T715 679T725 690L740 705H746Q760 705 760 698Q760 694 728 561Q692 422 692 421Q690 416 687 415T669 413H653Q647 419 647 422Q647 423 648 429T650 449T651 481Q651 552 619 605T510 659Q492 659 471 656T418 643T357 615T294 567T236 496T189 394T158 260Q156 242 156 221Q156 173 170 136T206 79T256 45T308 28T353 24Q407 24 452 47T514 106Q517 114 529 161T541 214Q541 222 528 224T468 227H431Q425 233 425 235T427 254Q431 267 437 273H454Q494 271 594 271Q634 271 659 271T695 272T707 272Q721 272 721 263Q721 261 719 249Q714 230 709 228Q706 227 694 227Q674 227 653 224Q646 221 643 215T629 164Q620 131 614 108Q589 6 586 3Q584 1 581 1Q571 1 553 21T530 52Q530 53 528 52T522 47Q448 -22 322 -22Q201 -22 126 55T50 252',
    0x1D43B: '228 637Q194 637 192 641Q191 643 191 649Q191 673 202 682Q204 683 219 683Q260 681 355 681Q389 681 418 681T463 682T483 682Q499 682 499 672Q499 670 497 658Q492 641 487 638H485Q483 638 480 638T473 638T464 637T455 637Q416 636 405 634T387 623Q384 619 355 500Q348 474 340 442T328 395L324 380Q324 378 469 378H614L615 381Q615 384 646 504Q674 619 674 627T617 637Q594 637 587 639T580 648Q580 650 582 660Q586 677 588 679T604 682Q609 682 646 681T740 680Q802 680 835 681T871 682Q888 682 888 672Q888 645 876 638H874Q872 638 869 638T862 638T853 637T844 637Q805 636 794 634T776 623Q773 618 704 340T634 58Q634 51 638 51Q646 48 692 46H723Q729 38 729 37T726 19Q722 6 716 0H701Q664 2 567 2Q533 2 504 2T458 2T437 1Q420 1 420 10Q420 15 423 24Q428 43 433 45Q437 46 448 46H454Q481 46 514 49Q520 50 522 50T528 55T534 64T540 82T547 110T558 153Q565 181 569 198Q602 330 602 331T457 332H312L279 197Q245 63 245 58Q245 51 253 49T303 46H334Q340 38 340 37T337 19Q333 6 327 0H312Q275 2 178 2Q144 2 115 2T69 2T48 1Q31 1 31 10Q31 12 34 24Q39 43 44 45Q48 46 59 46H65Q92 46 125 49Q139 52 144 61Q147 65 216 339T285 628Q285 635 228 637',
    0x1D43C: '43 1Q26 1 26 10Q26 12 29 24Q34 43 39 45Q42 46 54 46H60Q120 46 136 53Q137 53 138 54Q143 56 149 77T198 273Q210 318 216 344Q286 624 286 626Q284 630 284 631Q274 637 213 637H193Q184 643 189 662Q193 677 195 680T209 683H213Q285 681 359 681Q481 681 487 683H497Q504 676 504 672T501 655T494 639Q491 637 471 637Q440 637 407 634Q393 631 388 623Q381 609 337 432Q326 385 315 341Q245 65 245 59Q245 52 255 50T307 46H339Q345 38 345 37T342 19Q338 6 332 0H316Q279 2 179 2Q143 2 113 2T65 2T43 1',
    0x1D43D: '447 625Q447 637 354 637H329Q323 642 323 645T325 664Q329 677 335 683H352Q393 681 498 681Q541 681 568 681T605 682T619 682Q633 682 633 672Q633 670 630 658Q626 642 623 640T604 637Q552 637 545 623Q541 610 483 376Q420 128 419 127Q397 64 333 21T195 -22Q137 -22 97 8T57 88Q57 130 80 152T132 174Q177 174 182 130Q182 98 164 80T123 56Q115 54 115 53T122 44Q148 15 197 15Q235 15 271 47T324 130Q328 142 387 380T447 625',
    0x1D43E: '285 628Q285 635 228 637Q205 637 198 638T191 647Q191 649 193 661Q199 681 203 682Q205 683 214 683H219Q260 681 355 681Q389 681 418 681T463 682T483 682Q500 682 500 674Q500 669 497 660Q496 658 496 654T495 648T493 644T490 641T486 639T479 638T470 637T456 637Q416 636 405 634T387 623L306 305Q307 305 490 449T678 597Q692 611 692 620Q692 635 667 637Q651 637 651 648Q651 650 654 662T659 677Q662 682 676 682Q680 682 711 681T791 680Q814 680 839 681T869 682Q889 682 889 672Q889 650 881 642Q878 637 862 637Q787 632 726 586Q710 576 656 534T556 455L509 418L518 396Q527 374 546 329T581 244Q656 67 661 61Q663 59 666 57Q680 47 717 46H738Q744 38 744 37T741 19Q737 6 731 0H720Q680 3 625 3Q503 3 488 0H478Q472 6 472 9T474 27Q478 40 480 43T491 46H494Q544 46 544 71Q544 75 517 141T485 216L427 354L359 301L291 248L268 155Q245 63 245 58Q245 51 253 49T303 46H334Q340 37 340 35Q340 19 333 5Q328 0 317 0Q314 0 280 1T180 2Q118 2 85 2T49 1Q31 1 31 11Q31 13 34 25Q38 41 42 43T65 46Q92 46 125 49Q139 52 144 61Q147 65 216 339T285 628',
    0x1D43F: '228 637Q194 637 192 641Q191 643 191 649Q191 673 202 682Q204 683 217 683Q271 680 344 680Q485 680 506 683H518Q524 677 524 674T522 656Q517 641 513 637H475Q406 636 394 628Q387 624 380 600T313 336Q297 271 279 198T252 88L243 52Q243 48 252 48T311 46H328Q360 46 379 47T428 54T478 72T522 106T564 161Q580 191 594 228T611 270Q616 273 628 273H641Q647 264 647 262T627 203T583 83T557 9Q555 4 553 3T537 0T494 -1Q483 -1 418 -1T294 0H116Q32 0 32 10Q32 17 34 24Q39 43 44 45Q48 46 59 46H65Q92 46 125 49Q139 52 144 61Q147 65 216 339T285 628Q285 635 228 637',
    0x1D440: '289 629Q289 635 232 637Q208 637 201 638T194 648Q194 649 196 659Q197 662 198 666T199 671T201 676T203 679T207 681T212 683T220 683T232 684Q238 684 262 684T307 683Q386 683 398 683T414 678Q415 674 451 396L487 117L510 154Q534 190 574 254T662 394Q837 673 839 675Q840 676 842 678T846 681L852 683H948Q965 683 988 683T1017 684Q1051 684 1051 673Q1051 668 1048 656T1045 643Q1041 637 1008 637Q968 636 957 634T939 623Q936 618 867 340T797 59Q797 55 798 54T805 50T822 48T855 46H886Q892 37 892 35Q892 19 885 5Q880 0 869 0Q864 0 828 1T736 2Q675 2 644 2T609 1Q592 1 592 11Q592 13 594 25Q598 41 602 43T625 46Q652 46 685 49Q699 52 704 61Q706 65 742 207T813 490T848 631L654 322Q458 10 453 5Q451 4 449 3Q444 0 433 0Q418 0 415 7Q413 11 374 317L335 624L267 354Q200 88 200 79Q206 46 272 46H282Q288 41 289 37T286 19Q282 3 278 1Q274 0 267 0Q265 0 255 0T221 1T157 2Q127 2 95 1T58 0Q43 0 39 2T35 11Q35 13 38 25T43 40Q45 46 65 46Q135 46 154 86Q158 92 223 354T289 629',
    0x1D441: '234 637Q231 637 226 637Q201 637 196 638T191 649Q191 676 202 682Q204 683 299 683Q376 683 387 683T401 677Q612 181 616 168L670 381Q723 592 723 606Q723 633 659 637Q635 637 635 648Q635 650 637 660Q641 676 643 679T653 683Q656 683 684 682T767 680Q817 680 843 681T873 682Q888 682 888 672Q888 650 880 642Q878 637 858 637Q787 633 769 597L620 7Q618 0 599 0Q585 0 582 2Q579 5 453 305L326 604L261 344Q196 88 196 79Q201 46 268 46H278Q284 41 284 38T282 19Q278 6 272 0H259Q228 2 151 2Q123 2 100 2T63 2T46 1Q31 1 31 10Q31 14 34 26T39 40Q41 46 62 46Q130 49 150 85Q154 91 221 362L289 634Q287 635 234 637',
    0x1D442: '740 435Q740 320 676 213T511 42T304 -22Q207 -22 138 35T51 201Q50 209 50 244Q50 346 98 438T227 601Q351 704 476 704Q514 704 524 703Q621 689 680 617T740 435ZM637 476Q637 565 591 615T476 665Q396 665 322 605Q242 542 200 428T157 216Q157 126 200 73T314 19Q404 19 485 98T608 313Q637 408 637 476',
    0x1D443: '287 628Q287 635 230 637Q206 637 199 638T192 648Q192 649 194 659Q200 679 203 681T397 683Q587 682 600 680Q664 669 707 631T751 530Q751 453 685 389Q616 321 507 303Q500 302 402 301H307L277 182Q247 66 247 59Q247 55 248 54T255 50T272 48T305 46H336Q342 37 342 35Q342 19 335 5Q330 0 319 0Q316 0 282 1T182 2Q120 2 87 2T51 1Q33 1 33 11Q33 13 36 25Q40 41 44 43T67 46Q94 46 127 49Q141 52 146 61Q149 65 218 339T287 628ZM645 554Q645 567 643 575T634 597T609 619T560 635Q553 636 480 637Q463 637 445 637T416 636T404 636Q391 635 386 627Q384 621 367 550T332 412T314 344Q314 342 395 342H407H430Q542 342 590 392Q617 419 631 471T645 554',
    0x1D444: '399 -80Q399 -47 400 -30T402 -11V-7L387 -11Q341 -22 303 -22Q208 -22 138 35T51 201Q50 209 50 244Q50 346 98 438T227 601Q351 704 476 704Q514 704 524 703Q621 689 680 617T740 435Q740 255 592 107Q529 47 461 16L444 8V3Q444 2 449 -24T470 -66T516 -82Q551 -82 583 -60T625 -3Q631 11 638 11Q647 11 649 2Q649 -6 639 -34T611 -100T557 -165T481 -194Q399 -194 399 -87V-80ZM636 468Q636 523 621 564T580 625T530 655T477 665Q429 665 379 640Q277 591 215 464T153 216Q153 110 207 59Q231 38 236 38V46Q236 86 269 120T347 155Q372 155 390 144T417 114T429 82T435 55L448 64Q512 108 557 185T619 334T636 468ZM314 18Q362 18 404 39L403 49Q399 104 366 115Q354 117 347 117Q344 117 341 117T337 118Q317 118 296 98T274 52Q274 18 314 18',
    0x1D445: '230 637Q203 637 198 638T193 649Q193 676 204 682Q206 683 378 683Q550 682 564 680Q620 672 658 652T712 606T733 563T739 529Q739 484 710 445T643 385T576 351T538 338L545 333Q612 295 612 223Q612 212 607 162T602 80V71Q602 53 603 43T614 25T640 16Q668 16 686 38T712 85Q717 99 720 102T735 105Q755 105 755 93Q755 75 731 36Q693 -21 641 -21H632Q571 -21 531 4T487 82Q487 109 502 166T517 239Q517 290 474 313Q459 320 449 321T378 323H309L277 193Q244 61 244 59Q244 55 245 54T252 50T269 48T302 46H333Q339 38 339 37T336 19Q332 6 326 0H311Q275 2 180 2Q146 2 117 2T71 2T50 1Q33 1 33 10Q33 12 36 24Q41 43 46 45Q50 46 61 46H67Q94 46 127 49Q141 52 146 61Q149 65 218 339T287 628Q287 635 230 637ZM630 554Q630 586 609 608T523 636Q521 636 500 636T462 637H440Q393 637 386 627Q385 624 352 494T319 361Q319 360 388 360Q466 361 492 367Q556 377 592 426Q608 449 619 486T630 554',
    0x1D446: '308 24Q367 24 416 76T466 197Q466 260 414 284Q308 311 278 321T236 341Q176 383 176 462Q176 523 208 573T273 648Q302 673 343 688T407 704H418H425Q521 704 564 640Q565 640 577 653T603 682T623 704Q624 704 627 704T632 705Q645 705 645 698T617 577T585 459T569 456Q549 456 549 465Q549 471 550 475Q550 478 551 494T553 520Q553 554 544 579T526 616T501 641Q465 662 419 662Q362 662 313 616T263 510Q263 480 278 458T319 427Q323 425 389 408T456 390Q490 379 522 342T554 242Q554 216 546 186Q541 164 528 137T492 78T426 18T332 -20Q320 -22 298 -22Q199 -22 144 33L134 44L106 13Q83 -14 78 -18T65 -22Q52 -22 52 -14Q52 -11 110 221Q112 227 130 227H143Q149 221 149 216Q149 214 148 207T144 186T142 153Q144 114 160 87T203 47T255 29T308 24',
    0x1D447: '40 437Q21 437 21 445Q21 450 37 501T71 602L88 651Q93 669 101 677H569H659Q691 677 697 676T704 667Q704 661 687 553T668 444Q668 437 649 437Q640 437 637 437T631 442L629 445Q629 451 635 490T641 551Q641 586 628 604T573 629Q568 630 515 631Q469 631 457 630T439 622Q438 621 368 343T298 60Q298 48 386 46Q418 46 427 45T436 36Q436 31 433 22Q429 4 424 1L422 0Q419 0 415 0Q410 0 363 1T228 2Q99 2 64 0H49Q43 6 43 9T45 27Q49 40 55 46H83H94Q174 46 189 55Q190 56 191 56Q196 59 201 76T241 233Q258 301 269 344Q339 619 339 625Q339 630 310 630H279Q212 630 191 624Q146 614 121 583T67 467Q60 445 57 441T43 437H40',
    0x1D448: '107 637Q73 637 71 641Q70 643 70 649Q70 673 81 682Q83 683 98 683Q139 681 234 681Q268 681 297 681T342 682T362 682Q378 682 378 672Q378 670 376 658Q371 641 366 638H364Q362 638 359 638T352 638T343 637T334 637Q295 636 284 634T266 623Q265 621 238 518T184 302T154 169Q152 155 152 140Q152 86 183 55T269 24Q336 24 403 69T501 205L552 406Q599 598 599 606Q599 633 535 637Q511 637 511 648Q511 650 513 660Q517 676 519 679T529 683Q532 683 561 682T645 680Q696 680 723 681T752 682Q767 682 767 672Q767 650 759 642Q756 637 737 637Q666 633 648 597Q646 592 598 404Q557 235 548 205Q515 105 433 42T263 -22Q171 -22 116 34T60 167V183Q60 201 115 421Q164 622 164 628Q164 635 107 637',
    0x1D449: '52 648Q52 670 65 683H76Q118 680 181 680Q299 680 320 683H330Q336 677 336 674T334 656Q329 641 325 637H304Q282 635 274 635Q245 630 242 620Q242 618 271 369T301 118L374 235Q447 352 520 471T595 594Q599 601 599 609Q599 633 555 637Q537 637 537 648Q537 649 539 661Q542 675 545 679T558 683Q560 683 570 683T604 682T668 681Q737 681 755 683H762Q769 676 769 672Q769 655 760 640Q757 637 743 637Q730 636 719 635T698 630T682 623T670 615T660 608T652 599T645 592L452 282Q272 -9 266 -16Q263 -18 259 -21L241 -22H234Q216 -22 216 -15Q213 -9 177 305Q139 623 138 626Q133 637 76 637H59Q52 642 52 648',
    0x1D44A: '436 683Q450 683 486 682T553 680Q604 680 638 681T677 682Q695 682 695 674Q695 670 692 659Q687 641 683 639T661 637Q636 636 621 632T600 624T597 615Q597 603 613 377T629 138L631 141Q633 144 637 151T649 170T666 200T690 241T720 295T759 362Q863 546 877 572T892 604Q892 619 873 628T831 637Q817 637 817 647Q817 650 819 660Q823 676 825 679T839 682Q842 682 856 682T895 682T949 681Q1015 681 1034 683Q1048 683 1048 672Q1048 666 1045 655T1038 640T1028 637Q1006 637 988 631T958 617T939 600T927 584L923 578L754 282Q586 -14 585 -15Q579 -22 561 -22Q546 -22 542 -17Q539 -14 523 229T506 480L494 462Q472 425 366 239Q222 -13 220 -15T215 -19Q210 -22 197 -22Q178 -22 176 -15Q176 -12 154 304T131 622Q129 631 121 633T82 637H58Q51 644 51 648Q52 671 64 683H76Q118 680 176 680Q301 680 313 683H323Q329 677 329 674T327 656Q322 641 318 637H297Q236 634 232 620Q262 160 266 136L501 550L499 587Q496 629 489 632Q483 636 447 637Q428 637 422 639T416 648Q416 650 418 660Q419 664 420 669T421 676T424 680T428 682T436 683',
    0x1D44B: '42 0H40Q26 0 26 11Q26 15 29 27Q33 41 36 43T55 46Q141 49 190 98Q200 108 306 224T411 342Q302 620 297 625Q288 636 234 637H206Q200 643 200 645T202 664Q206 677 212 683H226Q260 681 347 681Q380 681 408 681T453 682T473 682Q490 682 490 671Q490 670 488 658Q484 643 481 640T465 637Q434 634 411 620L488 426L541 485Q646 598 646 610Q646 628 622 635Q617 635 609 637Q594 637 594 648Q594 650 596 664Q600 677 606 683H618Q619 683 643 683T697 681T738 680Q828 680 837 683H845Q852 676 852 672Q850 647 840 637H824Q790 636 763 628T722 611T698 593L687 584Q687 585 592 480L505 384Q505 383 536 304T601 142T638 56Q648 47 699 46Q734 46 734 37Q734 35 732 23Q728 7 725 4T711 1Q708 1 678 1T589 2Q528 2 496 2T461 1Q444 1 444 10Q444 11 446 25Q448 35 450 39T455 44T464 46T480 47T506 54Q523 62 523 64Q522 64 476 181L429 299Q241 95 236 84Q232 76 232 72Q232 53 261 47Q262 47 267 47T273 46Q276 46 277 46T280 45T283 42T284 35Q284 26 282 19Q279 6 276 4T261 1Q258 1 243 1T201 2T142 2Q64 2 42 0',
    0x1D44C: '66 637Q54 637 49 637T39 638T32 641T30 647T33 664T42 682Q44 683 56 683Q104 680 165 680Q288 680 306 683H316Q322 677 322 674T320 656Q316 643 310 637H298Q242 637 242 624Q242 619 292 477T343 333L346 336Q350 340 358 349T379 373T411 410T454 461Q546 568 561 587T577 618Q577 634 545 637Q528 637 528 647Q528 649 530 661Q533 676 535 679T549 683Q551 683 578 682T657 680Q684 680 713 681T746 682Q763 682 763 673Q763 669 760 657T755 643Q753 637 734 637Q662 632 617 587Q608 578 477 424L348 273L322 169Q295 62 295 57Q295 46 363 46Q379 46 384 45T390 35Q390 33 388 23Q384 6 382 4T366 1Q361 1 324 1T232 2Q170 2 138 2T102 1Q84 1 84 9Q84 14 87 24Q88 27 89 30T90 35T91 39T93 42T96 44T101 45T107 45T116 46T129 46Q168 47 180 50T198 63Q201 68 227 171L252 274L129 623Q128 624 127 625T125 627T122 629T118 631T113 633T105 634T96 635T83 636T66 637',
    0x1D44D: '58 8Q58 23 64 35Q64 36 329 334T596 635L586 637Q575 637 512 637H500H476Q442 637 420 635T365 624T311 598T266 548T228 469Q227 466 226 463T224 458T223 453T222 450L221 448Q218 443 202 443Q185 443 182 453L214 561Q228 606 241 651Q249 679 253 681Q256 683 487 683H718Q723 678 723 675Q723 673 717 649Q189 54 188 52L185 49H274Q369 50 377 51Q452 60 500 100T579 247Q587 272 590 277T603 282H607Q628 282 628 271Q547 5 541 2Q538 0 300 0H124Q58 0 58 8',
    0x1D44E: '33 157Q33 258 109 349T280 441Q331 441 370 392Q386 422 416 422Q429 422 439 414T449 394Q449 381 412 234T374 68Q374 43 381 35T402 26Q411 27 422 35Q443 55 463 131Q469 151 473 152Q475 153 483 153H487Q506 153 506 144Q506 138 501 117T481 63T449 13Q436 0 417 -8Q409 -10 393 -10Q359 -10 336 5T306 36L300 51Q299 52 296 50Q294 48 292 46Q233 -10 172 -10Q117 -10 75 30T33 157ZM351 328Q351 334 346 350T323 385T277 405Q242 405 210 374T160 293Q131 214 119 129Q119 126 119 118T118 106Q118 61 136 44T179 26Q217 26 254 59T298 110Q300 114 325 217T351 328',
    0x1D44F: '73 647Q73 657 77 670T89 683Q90 683 161 688T234 694Q246 694 246 685T212 542Q204 508 195 472T180 418L176 399Q176 396 182 402Q231 442 283 442Q345 442 383 396T422 280Q422 169 343 79T173 -11Q123 -11 82 27T40 150V159Q40 180 48 217T97 414Q147 611 147 623T109 637Q104 637 101 637H96Q86 637 83 637T76 640T73 647ZM336 325V331Q336 405 275 405Q258 405 240 397T207 376T181 352T163 330L157 322L136 236Q114 150 114 114Q114 66 138 42Q154 26 178 26Q211 26 245 58Q270 81 285 114T318 219Q336 291 336 325',
    0x1D450: '34 159Q34 268 120 355T306 442Q362 442 394 418T427 355Q427 326 408 306T360 285Q341 285 330 295T319 325T330 359T352 380T366 386H367Q367 388 361 392T340 400T306 404Q276 404 249 390Q228 381 206 359Q162 315 142 235T121 119Q121 73 147 50Q169 26 205 26H209Q321 26 394 111Q403 121 406 121Q410 121 419 112T429 98T420 83T391 55T346 25T282 0T202 -11Q127 -11 81 37T34 159',
    0x1D451: '366 683Q367 683 438 688T511 694Q523 694 523 686Q523 679 450 384T375 83T374 68Q374 26 402 26Q411 27 422 35Q443 55 463 131Q469 151 473 152Q475 153 483 153H487H491Q506 153 506 145Q506 140 503 129Q490 79 473 48T445 8T417 -8Q409 -10 393 -10Q359 -10 336 5T306 36L300 51Q299 52 296 50Q294 48 292 46Q233 -10 172 -10Q117 -10 75 30T33 157Q33 205 53 255T101 341Q148 398 195 420T280 442Q336 442 364 400Q369 394 369 396Q370 400 396 505T424 616Q424 629 417 632T378 637H357Q351 643 351 645T353 664Q358 683 366 683ZM352 326Q329 405 277 405Q242 405 210 374T160 293Q131 214 119 129Q119 126 119 118T118 106Q118 61 136 44T179 26Q233 26 290 98L298 109L352 326',
    0x1D452: '39 168Q39 225 58 272T107 350T174 402T244 433T307 442H310Q355 442 388 420T421 355Q421 265 310 237Q261 224 176 223Q139 223 138 221Q138 219 132 186T125 128Q125 81 146 54T209 26T302 45T394 111Q403 121 406 121Q410 121 419 112T429 98T420 82T390 55T344 24T281 -1T205 -11Q126 -11 83 42T39 168ZM373 353Q367 405 305 405Q272 405 244 391T199 357T170 316T154 280T149 261Q149 260 169 260Q282 260 327 284T373 353',
    0x1D453: '118 -162Q120 -162 124 -164T135 -167T147 -168Q160 -168 171 -155T187 -126Q197 -99 221 27T267 267T289 382V385H242Q195 385 192 387Q188 390 188 397L195 425Q197 430 203 430T250 431Q298 431 298 432Q298 434 307 482T319 540Q356 705 465 705Q502 703 526 683T550 630Q550 594 529 578T487 561Q443 561 443 603Q443 622 454 636T478 657L487 662Q471 668 457 668Q445 668 434 658T419 630Q412 601 403 552T387 469T380 433Q380 431 435 431Q480 431 487 430T498 424Q499 420 496 407T491 391Q489 386 482 386T428 385H372L349 263Q301 15 282 -47Q255 -132 212 -173Q175 -205 139 -205Q107 -205 81 -186T55 -132Q55 -95 76 -78T118 -61Q162 -61 162 -103Q162 -122 151 -136T127 -157L118 -162',
    0x1D454: '311 43Q296 30 267 15T206 0Q143 0 105 45T66 160Q66 265 143 353T314 442Q361 442 401 394L404 398Q406 401 409 404T418 412T431 419T447 422Q461 422 470 413T480 394Q480 379 423 152T363 -80Q345 -134 286 -169T151 -205Q10 -205 10 -137Q10 -111 28 -91T74 -71Q89 -71 102 -80T116 -111Q116 -121 114 -130T107 -144T99 -154T92 -162L90 -164H91Q101 -167 151 -167Q189 -167 211 -155Q234 -144 254 -122T282 -75Q288 -56 298 -13Q311 35 311 43ZM384 328L380 339Q377 350 375 354T369 368T359 382T346 393T328 402T306 405Q262 405 221 352Q191 313 171 233T151 117Q151 38 213 38Q269 38 323 108L331 118L384 328',
    0x1D456: '184 600Q184 624 203 642T247 661Q265 661 277 649T290 619Q290 596 270 577T226 557Q211 557 198 567T184 600ZM21 287Q21 295 30 318T54 369T98 420T158 442Q197 442 223 419T250 357Q250 340 236 301T196 196T154 83Q149 61 149 51Q149 26 166 26Q175 26 185 29T208 43T235 78T260 137Q263 149 265 151T282 153Q302 153 302 143Q302 135 293 112T268 61T223 11T161 -11Q129 -11 102 10T74 74Q74 91 79 106T122 220Q160 321 166 341T173 380Q173 404 156 404H154Q124 404 99 371T61 287Q60 286 59 284T58 281T56 279T53 278T49 278T41 278H27Q21 284 21 287',
    0x1D457: '297 596Q297 627 318 644T361 661Q378 661 389 651T403 623Q403 595 384 576T340 557Q322 557 310 567T297 596ZM288 376Q288 405 262 405Q240 405 220 393T185 362T161 325T144 293L137 279Q135 278 121 278H107Q101 284 101 286T105 299Q126 348 164 391T252 441Q253 441 260 441T272 442Q296 441 316 432Q341 418 354 401T367 348V332L318 133Q267 -67 264 -75Q246 -125 194 -164T75 -204Q25 -204 7 -183T-12 -137Q-12 -110 7 -91T53 -71Q70 -71 82 -81T95 -112Q95 -148 63 -167Q69 -168 77 -168Q111 -168 139 -140T182 -74L193 -32Q204 11 219 72T251 197T278 308T289 365Q289 372 288 376',
    0x1D458: '121 647Q121 657 125 670T137 683Q138 683 209 688T282 694Q294 694 294 686Q294 679 244 477Q194 279 194 272Q213 282 223 291Q247 309 292 354T362 415Q402 442 438 442Q468 442 485 423T503 369Q503 344 496 327T477 302T456 291T438 288Q418 288 406 299T394 328Q394 353 410 369T442 390L458 393Q446 405 434 405H430Q398 402 367 380T294 316T228 255Q230 254 243 252T267 246T293 238T320 224T342 206T359 180T365 147Q365 130 360 106T354 66Q354 26 381 26Q429 26 459 145Q461 153 479 153H483Q499 153 499 144Q499 139 496 130Q455 -11 378 -11Q333 -11 305 15T277 90Q277 108 280 121T283 145Q283 167 269 183T234 206T200 217T182 220H180Q168 178 159 139T145 81T136 44T129 20T122 7T111 -2Q98 -11 83 -11Q66 -11 57 -1T48 16Q48 26 85 176T158 471L195 616Q196 629 188 632T149 637H144Q134 637 131 637T124 640T121 647',
    0x1D459: '117 59Q117 26 142 26Q179 26 205 131Q211 151 215 152Q217 153 225 153H229Q238 153 241 153T246 151T248 144Q247 138 245 128T234 90T214 43T183 6T137 -11Q101 -11 70 11T38 85Q38 97 39 102L104 360Q167 615 167 623Q167 626 166 628T162 632T157 634T149 635T141 636T132 637T122 637Q112 637 109 637T101 638T95 641T94 647Q94 649 96 661Q101 680 107 682T179 688Q194 689 213 690T243 693T254 694Q266 694 266 686Q266 675 193 386T118 83Q118 81 118 75T117 65V59',
    0x1D45A: '21 287Q22 293 24 303T36 341T56 388T88 425T132 442T175 435T205 417T221 395T229 376L231 369Q231 367 232 367L243 378Q303 442 384 442Q401 442 415 440T441 433T460 423T475 411T485 398T493 385T497 373T500 364T502 357L510 367Q573 442 659 442Q713 442 746 415T780 336Q780 285 742 178T704 50Q705 36 709 31T724 26Q752 26 776 56T815 138Q818 149 821 151T837 153Q857 153 857 145Q857 144 853 130Q845 101 831 73T785 17T716 -10Q669 -10 648 17T627 73Q627 92 663 193T700 345Q700 404 656 404H651Q565 404 506 303L499 291L466 157Q433 26 428 16Q415 -11 385 -11Q372 -11 364 -4T353 8T350 18Q350 29 384 161L420 307Q423 322 423 345Q423 404 379 404H374Q288 404 229 303L222 291L189 157Q156 26 151 16Q138 -11 108 -11Q95 -11 87 -5T76 7T74 17Q74 30 112 181Q151 335 151 342Q154 357 154 369Q154 405 129 405Q107 405 92 377T69 316T57 280Q55 278 41 278H27Q21 284 21 287',
    0x1D45B: '21 287Q22 293 24 303T36 341T56 388T89 425T135 442Q171 442 195 424T225 390T231 369Q231 367 232 367L243 378Q304 442 382 442Q436 442 469 415T503 336T465 179T427 52Q427 26 444 26Q450 26 453 27Q482 32 505 65T540 145Q542 153 560 153Q580 153 580 145Q580 144 576 130Q568 101 554 73T508 17T439 -10Q392 -10 371 17T350 73Q350 92 386 193T423 345Q423 404 379 404H374Q288 404 229 303L222 291L189 157Q156 26 151 16Q138 -11 108 -11Q95 -11 87 -5T76 7T74 17Q74 30 112 180T152 343Q153 348 153 366Q153 405 129 405Q91 405 66 305Q60 285 60 284Q58 278 41 278H27Q21 284 21 287',
    0x1D45C: '201 -11Q126 -11 80 38T34 156Q34 221 64 279T146 380Q222 441 301 441Q333 441 341 440Q354 437 367 433T402 417T438 387T464 338T476 268Q476 161 390 75T201 -11ZM121 120Q121 70 147 48T206 26Q250 26 289 58T351 142Q360 163 374 216T388 308Q388 352 370 375Q346 405 306 405Q243 405 195 347Q158 303 140 230T121 120',
    0x1D45D: '23 287Q24 290 25 295T30 317T40 348T55 381T75 411T101 433T134 442Q209 442 230 378L240 387Q302 442 358 442Q423 442 460 395T497 281Q497 173 421 82T249 -10Q227 -10 210 -4Q199 1 187 11T168 28L161 36Q160 35 139 -51T118 -138Q118 -144 126 -145T163 -148H188Q194 -155 194 -157T191 -175Q188 -187 185 -190T172 -194Q170 -194 161 -194T127 -193T65 -192Q-5 -192 -24 -194H-32Q-39 -187 -39 -183Q-37 -156 -26 -148H-6Q28 -147 33 -136Q36 -130 94 103T155 350Q156 355 156 364Q156 405 131 405Q109 405 94 377T71 316T59 280Q57 278 43 278H29Q23 284 23 287ZM178 102Q200 26 252 26Q282 26 310 49T356 107Q374 141 392 215T411 325V331Q411 405 350 405Q339 405 328 402T306 393T286 380T269 365T254 350T243 336T235 326L232 322Q232 321 229 308T218 264T204 212Q178 106 178 102',
    0x1D45E: '33 157Q33 258 109 349T280 441Q340 441 372 389Q373 390 377 395T388 406T404 418Q438 442 450 442Q454 442 457 439T460 434Q460 425 391 149Q320 -135 320 -139Q320 -147 365 -148H390Q396 -156 396 -157T393 -175Q389 -188 383 -194H370Q339 -192 262 -192Q234 -192 211 -192T174 -192T157 -193Q143 -193 143 -185Q143 -182 145 -170Q149 -154 152 -151T172 -148Q220 -148 230 -141Q238 -136 258 -53T279 32Q279 33 272 29Q224 -10 172 -10Q117 -10 75 30T33 157ZM352 326Q329 405 277 405Q242 405 210 374T160 293Q131 214 119 129Q119 126 119 118T118 106Q118 61 136 44T179 26Q233 26 290 98L298 109L352 326',
    0x1D45F: '21 287Q22 290 23 295T28 317T38 348T53 381T73 411T99 433T132 442Q161 442 183 430T214 408T225 388Q227 382 228 382T236 389Q284 441 347 441H350Q398 441 422 400Q430 381 430 363Q430 333 417 315T391 292T366 288Q346 288 334 299T322 328Q322 376 378 392Q356 405 342 405Q286 405 239 331Q229 315 224 298T190 165Q156 25 151 16Q138 -11 108 -11Q95 -11 87 -5T76 7T74 17Q74 30 114 189T154 366Q154 405 128 405Q107 405 92 377T68 316T57 280Q55 278 41 278H27Q21 284 21 287',
    0x1D460: '131 289Q131 321 147 354T203 415T300 442Q362 442 390 415T419 355Q419 323 402 308T364 292Q351 292 340 300T328 326Q328 342 337 354T354 372T367 378Q368 378 368 379Q368 382 361 388T336 399T297 405Q249 405 227 379T204 326Q204 301 223 291T278 274T330 259Q396 230 396 163Q396 135 385 107T352 51T289 7T195 -10Q118 -10 86 19T53 87Q53 126 74 143T118 160Q133 160 146 151T160 120Q160 94 142 76T111 58Q109 57 108 57T107 55Q108 52 115 47T146 34T201 27Q237 27 263 38T301 66T318 97T323 122Q323 150 302 164T254 181T195 196T148 231Q131 256 131 289',
    0x1D461: '26 385Q19 392 19 395Q19 399 22 411T27 425Q29 430 36 430T87 431H140L159 511Q162 522 166 540T173 566T179 586T187 603T197 615T211 624T229 626Q247 625 254 615T261 596Q261 589 252 549T232 470L222 433Q222 431 272 431H323Q330 424 330 420Q330 398 317 385H210L174 240Q135 80 135 68Q135 26 162 26Q197 26 230 60T283 144Q285 150 288 151T303 153H307Q322 153 322 145Q322 142 319 133Q314 117 301 95T267 48T216 6T155 -11Q125 -11 98 4T59 56Q57 64 57 83V101L92 241Q127 382 128 383Q128 385 77 385H26',
    0x1D462: '21 287Q21 295 30 318T55 370T99 420T158 442Q204 442 227 417T250 358Q250 340 216 246T182 105Q182 62 196 45T238 27T291 44T328 78L339 95Q341 99 377 247Q407 367 413 387T427 416Q444 431 463 431Q480 431 488 421T496 402L420 84Q419 79 419 68Q419 43 426 35T447 26Q469 29 482 57T512 145Q514 153 532 153Q551 153 551 144Q550 139 549 130T540 98T523 55T498 17T462 -8Q454 -10 438 -10Q372 -10 347 46Q345 45 336 36T318 21T296 6T267 -6T233 -11Q189 -11 155 7Q103 38 103 113Q103 170 138 262T173 379Q173 380 173 381Q173 390 173 393T169 400T158 404H154Q131 404 112 385T82 344T65 302T57 280Q55 278 41 278H27Q21 284 21 287',
    0x1D463: '173 380Q173 405 154 405Q130 405 104 376T61 287Q60 286 59 284T58 281T56 279T53 278T49 278T41 278H27Q21 284 21 287Q21 294 29 316T53 368T97 419T160 441Q202 441 225 417T249 361Q249 344 246 335Q246 329 231 291T200 202T182 113Q182 86 187 69Q200 26 250 26Q287 26 319 60T369 139T398 222T409 277Q409 300 401 317T383 343T365 361T357 383Q357 405 376 424T417 443Q436 443 451 425T467 367Q467 340 455 284T418 159T347 40T241 -11Q177 -11 139 22Q102 54 102 117Q102 148 110 181T151 298Q173 362 173 380',
    0x1D464: '580 385Q580 406 599 424T641 443Q659 443 674 425T690 368Q690 339 671 253Q656 197 644 161T609 80T554 12T482 -11Q438 -11 404 5T355 48Q354 47 352 44Q311 -11 252 -11Q226 -11 202 -5T155 14T118 53T104 116Q104 170 138 262T173 379Q173 380 173 381Q173 390 173 393T169 400T158 404H154Q131 404 112 385T82 344T65 302T57 280Q55 278 41 278H27Q21 284 21 287Q21 293 29 315T52 366T96 418T161 441Q204 441 227 416T250 358Q250 340 217 250T184 111Q184 65 205 46T258 26Q301 26 334 87L339 96V119Q339 122 339 128T340 136T341 143T342 152T345 165T348 182T354 206T362 238T373 281Q402 395 406 404Q419 431 449 431Q468 431 475 421T483 402Q483 389 454 274T422 142Q420 131 420 107V100Q420 85 423 71T442 42T487 26Q558 26 600 148Q609 171 620 213T632 273Q632 306 619 325T593 357T580 385',
    0x1D465: '52 289Q59 331 106 386T222 442Q257 442 286 424T329 379Q371 442 430 442Q467 442 494 420T522 361Q522 332 508 314T481 292T458 288Q439 288 427 299T415 328Q415 374 465 391Q454 404 425 404Q412 404 406 402Q368 386 350 336Q290 115 290 78Q290 50 306 38T341 26Q378 26 414 59T463 140Q466 150 469 151T485 153H489Q504 153 504 145Q504 144 502 134Q486 77 440 33T333 -11Q263 -11 227 52Q186 -10 133 -10H127Q78 -10 57 16T35 71Q35 103 54 123T99 143Q142 143 142 101Q142 81 130 66T107 46T94 41L91 40Q91 39 97 36T113 29T132 26Q168 26 194 71Q203 87 217 139T245 247T261 313Q266 340 266 352Q266 380 251 392T217 404Q177 404 142 372T93 290Q91 281 88 280T72 278H58Q52 284 52 289',
    0x1D466: '21 287Q21 301 36 335T84 406T158 442Q199 442 224 419T250 355Q248 336 247 334Q247 331 231 288T198 191T182 105Q182 62 196 45T238 27Q261 27 281 38T312 61T339 94Q339 95 344 114T358 173T377 247Q415 397 419 404Q432 431 462 431Q475 431 483 424T494 412T496 403Q496 390 447 193T391 -23Q363 -106 294 -155T156 -205Q111 -205 77 -183T43 -117Q43 -95 50 -80T69 -58T89 -48T106 -45Q150 -45 150 -87Q150 -107 138 -122T115 -142T102 -147L99 -148Q101 -153 118 -160T152 -167H160Q177 -167 186 -165Q219 -156 247 -127T290 -65T313 -9T321 21L315 17Q309 13 296 6T270 -6Q250 -11 231 -11Q185 -11 150 11T104 82Q103 89 103 113Q103 170 138 262T173 379Q173 380 173 381Q173 390 173 393T169 400T158 404H154Q131 404 112 385T82 344T65 302T57 280Q55 278 41 278H27Q21 284 21 287',
    0x1D467: '347 338Q337 338 294 349T231 360Q211 360 197 356T174 346T162 335T155 324L153 320Q150 317 138 317Q117 317 117 325Q117 330 120 339Q133 378 163 406T229 440Q241 442 246 442Q271 442 291 425T329 392T367 375Q389 375 411 408T434 441Q435 442 449 442H462Q468 436 468 434Q468 430 463 420T449 399T432 377T418 358L411 349Q368 298 275 214T160 106L148 94L163 93Q185 93 227 82T290 71Q328 71 360 90T402 140Q406 149 409 151T424 153Q443 153 443 143Q443 138 442 134Q425 72 376 31T278 -11Q252 -11 232 6T193 40T155 57Q111 57 76 -3Q70 -11 59 -11H54H41Q35 -5 35 -2Q35 13 93 84Q132 129 225 214T340 322Q352 338 347 338',
    0x1D468: '65 0Q45 0 45 18Q48 52 61 60Q65 62 81 62Q155 62 165 74Q166 74 265 228T465 539T569 699Q576 707 583 709T611 711T637 710T649 700Q650 697 695 380L741 63L784 62H827Q839 50 839 45L835 29Q831 9 827 5T806 0Q803 0 790 0T743 1T657 2Q585 2 547 1T504 0Q481 0 481 17Q484 54 497 60Q501 62 541 62Q580 62 580 63Q580 68 573 121T564 179V181H308L271 124Q236 69 236 67T283 62H287Q316 62 316 46Q316 26 307 8Q302 3 295 0L262 1Q242 2 168 2Q119 2 93 1T65 0ZM537 372Q533 402 528 435T521 486T518 504V505Q517 505 433 375L348 244L451 243Q555 243 555 244L537 372',
    0x1D469: '258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 449 686H477H586Q684 686 733 677T817 634Q853 598 853 547Q853 499 826 460T761 401T695 371T654 360H653L662 358Q670 357 683 354T712 344T744 327T774 303T795 269T804 224Q804 148 732 79T533 1Q524 0 288 0H58Q47 5 43 15Q47 54 60 60Q64 62 113 62H162L302 623Q302 624 258 624ZM703 550Q703 571 695 586T675 609T656 619T643 623L545 624H447L417 504Q386 384 386 383T470 382Q554 383 565 385Q632 397 667 447T703 550ZM651 240Q651 265 645 282T626 309T608 322T592 329Q587 330 479 331H373L340 198Q307 65 306 64Q306 62 406 62L507 63L519 65Q565 76 596 107T639 171T651 240',
    0x1D46A: '380 -17Q335 -17 293 -10T207 16T130 65T76 144T55 256Q55 306 70 361T122 476T211 582T345 663T525 702H545Q673 702 731 634L777 668Q783 672 789 677T800 685T808 691T814 695T818 698T822 700T825 702T828 703T830 703T833 703Q855 703 855 690Q855 686 823 558T789 426Q786 421 782 420T756 419Q734 420 729 421T724 432Q724 434 725 447T726 472Q726 552 678 604Q640 640 586 640H574Q533 640 494 632T409 604T324 541T260 437Q243 397 227 333T210 219Q210 152 237 117Q255 90 299 68T420 46H429Q506 46 580 100T678 234Q683 249 687 251T712 254H723Q743 254 743 240Q743 232 736 213T710 162T663 100T586 40T477 -5Q433 -17 380 -17',
    0x1D46B: '258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 437 686Q659 686 668 685Q727 680 772 662T842 621T883 568T905 517T913 475Q914 466 914 434Q914 373 892 307T828 179T712 69T548 7Q517 2 494 2T279 0H58Q47 5 43 15Q47 54 60 60Q64 62 113 62H162L302 623Q302 624 258 624ZM768 475Q768 515 753 544T718 588T666 611T613 622T563 624H538H532H452L382 344Q311 64 311 63T363 62H405Q490 62 545 76T656 142Q696 185 724 265T760 399T768 475',
    0x1D46C: '257 618H231Q198 618 198 636Q202 672 214 678L219 680H811Q817 677 820 673T824 666L825 664Q825 659 814 549T799 433Q793 424 771 424Q752 424 746 427T740 441Q740 445 742 466T744 505Q744 561 722 585T646 616Q639 617 545 618H456Q456 617 427 502T398 385Q398 384 435 384Q461 385 471 385T499 391T526 405T545 433T562 478Q566 494 571 497T595 501H604Q622 501 626 486Q626 482 593 349T557 213Q552 205 530 205Q499 205 499 219Q499 222 503 242T508 281Q508 308 491 314T429 322Q425 322 423 322H382L317 64Q317 62 390 62Q460 62 493 64T569 80T640 124Q665 149 686 187T719 253T733 283Q739 289 760 289Q791 289 791 274Q791 267 763 201T706 71L678 8Q676 4 667 0H58Q47 5 43 15Q47 54 60 60Q64 62 113 62H162L163 66Q163 67 231 341T301 616Q301 618 257 618',
    0x1D46D: '257 618H231Q198 618 198 636Q202 672 214 678L219 680H795Q801 677 804 673T808 666L809 664Q809 659 798 549T783 433Q777 424 755 424Q736 424 730 427T724 444Q724 448 725 468T727 507V524Q727 541 724 554T713 577T698 594T676 605T653 612T625 616T597 617T566 618T538 618H456L455 614Q455 611 424 491L394 371H429Q454 372 463 372T491 378T517 392T536 419T552 464Q556 481 561 484T586 488Q603 488 607 486Q616 482 616 473Q616 467 584 337T549 201Q542 192 521 192Q503 192 497 195T490 209Q490 212 492 224Q499 251 499 269Q499 288 489 296T465 306T417 308L379 309L348 188Q341 161 334 129T322 80L318 65L317 62H375H409Q430 62 438 59T447 45Q444 8 431 2L426 0L377 1Q347 2 231 2Q152 2 111 1T65 0Q48 0 43 15Q47 54 60 60Q64 62 113 62H162L163 66Q163 67 231 341T301 616Q301 618 257 618',
    0x1D46E: '379 -16Q233 -16 145 52T56 255Q56 310 73 368T127 483T216 586T347 663T518 702H540Q562 702 582 700T616 696T644 689T667 681T686 670T702 659T717 647T731 635L776 668Q782 672 788 677T799 685T807 691T813 695T817 698T821 700T824 702T827 703T829 703T832 703Q854 703 854 690Q854 686 822 558T788 426Q785 421 781 420T755 419Q734 420 729 422T723 432Q723 434 724 446T725 469Q725 531 702 571T642 628Q616 640 575 640Q468 640 390 593T272 464Q247 415 229 340T210 214Q210 166 228 132T277 79T343 54T419 46Q445 46 465 50T500 59T526 76T544 96T557 123T566 150T574 182T581 214H519Q511 214 498 214T479 213Q443 213 443 230Q443 250 452 268Q457 273 464 276L514 275Q546 274 657 274Q735 274 768 275T803 276Q826 276 826 258Q823 224 810 216Q806 214 771 214H736Q736 211 710 109T683 5Q678 0 671 0Q666 0 637 14T597 36Q593 38 590 40T585 44T582 44T576 40Q511 -16 379 -16',
    0x1D46F: '258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 239 686Q290 684 403 684Q475 684 512 685T553 686Q576 686 576 668Q572 632 560 626Q555 624 506 624H457L399 389Q399 388 547 388H695L753 623Q753 624 709 624H686Q665 624 660 626T650 639Q653 678 668 684Q672 686 681 686Q685 686 726 685T847 684Q902 684 937 684T986 685T1004 686Q1027 686 1027 668Q1023 632 1011 626Q1006 624 957 624H908L839 344Q768 64 768 63T812 62H839Q871 62 871 44Q867 6 854 2L850 0L808 1Q782 2 675 2Q600 2 560 1T516 0Q499 0 494 15Q498 54 511 60Q515 62 564 62H613L614 66L679 324Q679 326 531 326H383L382 322L317 64Q317 62 361 62H388Q420 62 420 44Q416 6 403 2L399 0L357 1Q331 2 224 2Q149 2 109 1T65 0Q48 0 43 15Q47 54 60 60Q64 62 113 62H162L302 623Q302 624 258 624',
    0x1D470: '247 624Q242 624 233 624T220 623Q186 623 186 640Q186 647 190 664T202 684Q206 686 226 686Q277 684 393 684Q435 684 471 684T528 685T553 686Q573 686 573 670Q573 650 564 632Q556 624 537 624H501H449L380 344Q309 64 309 63T356 62Q361 62 370 62T384 63Q417 63 417 46Q417 26 408 8Q403 3 396 0L352 1Q325 2 216 2T82 1L45 0Q30 7 30 16Q33 51 46 60Q51 62 102 62H154L294 623Q294 624 247 624',
    0x1D471: '205 131Q205 105 192 84T165 54L152 45Q152 44 160 42T182 37T213 35H216Q255 35 289 65Q314 90 329 129Q331 136 392 378T453 623Q453 624 393 624H332Q318 631 318 640Q318 647 322 664T334 684Q338 686 359 686Q413 684 533 684Q566 684 605 685T652 686Q677 686 685 683T694 669Q694 664 691 652Q686 631 681 628T647 624H602L542 380Q531 336 518 285T500 212T487 161T475 122T463 97T448 74T429 55Q351 -17 213 -17Q142 -17 99 7T43 70Q42 75 42 93Q42 143 73 168T139 194Q168 194 186 177T205 131',
    0x1D472: '536 0Q522 6 522 18Q522 35 533 57Q539 62 557 62Q595 62 601 65L472 330L365 255L342 160Q318 65 317 64Q317 62 361 62H388Q420 62 420 44Q416 6 403 2L399 0L357 1Q331 2 224 2Q149 2 109 1T65 0Q48 0 43 15Q47 54 60 60Q64 62 113 62H162L302 623Q302 624 258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 239 686Q290 684 403 684Q475 684 512 685T553 686Q576 686 576 668Q572 632 560 626Q555 624 506 624H457L422 481Q386 339 386 337L785 621Q779 624 749 624Q726 624 726 641Q726 645 730 659Q734 675 736 679T747 686L786 685Q812 684 888 684Q908 684 934 685T968 686Q1003 686 1003 669Q1003 646 991 629Q985 624 967 624Q918 624 888 617Q884 617 874 613L865 609Q864 608 732 515T599 420Q599 418 686 242T775 65Q784 62 829 62Q847 62 850 61T860 54Q862 52 862 43Q862 10 845 1Q844 1 842 1T836 0T797 1T694 2Q599 2 573 1L536 0',
    0x1D473: '258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 239 686Q290 684 409 684Q454 684 492 684T552 685T579 686Q603 686 603 668Q599 632 587 626Q583 624 520 624H457L388 344Q317 64 317 63T353 62H390Q418 62 440 64T493 78T548 110T598 169T643 261Q651 282 655 285T680 289Q696 289 700 288T709 279Q711 274 711 269Q710 265 663 138T613 8Q611 4 602 0H58Q47 5 43 15Q47 54 60 60Q64 62 113 62H162L302 623Q302 624 258 624',
    0x1D474: '258 624H231Q214 624 208 626T199 639Q203 678 216 684Q220 686 347 686H473Q474 685 478 682T484 677Q487 673 535 413L584 153L608 187Q631 221 672 281T761 410Q935 663 943 671Q949 678 962 686H1082H1166Q1201 686 1210 683T1219 668Q1215 632 1203 626Q1199 624 1149 624H1100L1031 344Q960 64 960 63T1004 62H1031Q1063 62 1063 44Q1060 7 1046 2Q1042 0 1034 0Q1030 0 990 1T875 2Q804 2 767 1T725 0H723Q707 0 703 15Q707 54 720 60Q724 62 773 62H822Q961 618 961 619L754 318Q546 15 543 12Q531 0 510 0Q500 0 495 0T484 5T477 19Q477 20 421 315L367 604L299 335Q234 72 234 68Q234 62 302 62Q334 62 334 46Q332 8 317 2Q313 0 306 0Q301 0 267 1T181 2Q125 2 96 1T63 0Q48 0 43 15Q43 19 47 35Q52 55 57 58T94 62Q147 64 164 69L233 345Q302 619 302 622Q302 624 258 624',
    0x1D475: '258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 344 686H434Q464 686 477 680Q480 677 607 454Q738 227 739 227Q742 227 789 418T836 618Q836 620 835 620L821 622Q811 622 779 624Q755 624 749 625T740 632Q737 635 737 644Q737 656 742 669T754 685Q755 685 757 685T763 686Q768 686 803 685T890 684Q925 684 951 684T990 685T1006 686Q1014 686 1016 684Q1027 679 1027 668Q1023 632 1011 626Q1007 624 978 624Q912 622 907 617Q907 616 831 314T753 8Q749 0 723 0H712Q699 0 692 7Q692 8 671 44T607 155T526 296L361 580L296 323Q234 74 234 68T302 62H307Q334 62 334 44Q330 6 317 2L313 0L280 1Q260 2 181 2Q125 2 96 1T63 0Q48 0 43 15Q43 19 47 35Q52 55 57 58T94 62Q147 64 164 69L233 345Q302 619 302 622Q302 624 258 624',
    0x1D476: '53 245Q53 297 70 356T125 478T216 590T349 671T523 703Q656 703 735 637T815 445Q815 378 791 307Q727 104 527 17Q437 -17 344 -17Q289 -17 242 -5T150 35T79 116T53 245ZM664 489Q664 575 618 611T511 648Q463 648 416 627T334 570Q297 531 270 472T230 355T213 261T208 206Q208 177 215 151T237 98T284 56T358 40Q440 40 510 98T618 270Q664 400 664 489',
    0x1D477: '162 62L302 623Q302 624 258 624H234Q214 624 209 626T200 638Q200 677 217 684Q220 686 439 686Q667 685 684 682Q686 681 693 680Q713 677 733 671T782 649T829 602T847 528Q847 450 784 382T604 293Q571 288 469 287H373L346 176Q340 151 333 122T321 78L317 64Q317 62 361 62H387Q420 62 420 44Q417 10 404 2L399 0L357 1Q331 2 224 2Q149 2 109 1T65 0Q43 0 43 17Q43 21 47 33Q52 54 57 58T89 62H113H162ZM692 558Q692 611 617 622Q610 623 529 624H452L381 343H458H492Q604 343 641 389Q662 414 677 471T692 558',
    0x1D478: '53 245Q53 297 70 356T125 478T216 590T349 671T523 703Q656 703 735 637T815 445Q815 410 808 370T781 277T729 178T643 87T519 14L525 4Q540 -19 553 -25T592 -32Q632 -32 654 -24T680 -7T689 10T704 18Q713 18 717 12T722 0Q722 -8 711 -36T681 -101T624 -166T541 -194Q513 -194 494 -183T465 -157T450 -118T444 -79T443 -41V-7L433 -9Q391 -17 344 -17Q301 -17 263 -10T185 15T118 62T71 138T53 245ZM666 482Q666 529 652 563T614 615T565 640T512 648Q412 648 335 573Q268 506 235 389T201 202Q201 164 210 136T230 95T259 66L262 76Q269 109 302 135T382 162Q401 162 415 159T449 140T484 92L491 78L496 82Q502 86 505 88T515 97T528 107T541 120T555 137T570 156T585 179T599 205T612 235Q629 278 647 351T666 482ZM439 56Q439 58 439 62T435 75T426 92T410 106T383 112Q353 112 332 96T311 63Q311 38 355 38H366Q391 39 415 45T439 56',
    0x1D479: '258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 422 686H446H525Q634 686 698 674T806 620Q843 583 843 535Q843 505 833 478T805 432T768 396T728 370T690 352T662 342L651 338L654 336Q658 334 667 327T688 310Q719 278 719 237Q719 222 710 165T701 94Q701 35 748 35Q775 35 793 57T819 101Q822 112 826 114T843 117H849Q881 117 881 99Q881 78 852 39T781 -11Q765 -17 728 -17Q537 -13 537 94Q537 110 552 169T567 243Q567 292 529 309Q517 316 508 316T441 318H375L374 314Q374 312 343 189T311 64Q311 62 355 62H382Q414 62 414 44Q410 6 397 2L393 0L351 1Q325 2 221 2Q147 2 108 1T65 0Q48 0 43 15Q47 54 60 60Q64 62 113 62H162L302 623Q302 624 258 624ZM687 555Q687 617 589 623Q581 624 513 624H451L420 498Q413 468 405 436T392 388L388 371Q388 369 458 369Q464 369 485 369T515 369T541 372T570 377T596 386T624 400Q649 417 664 457T683 522T687 555',
    0x1D47A: '354 45Q429 45 467 91T506 184Q506 225 477 250Q461 262 384 279T294 300Q238 318 210 362T182 452Q182 493 202 540T257 623Q338 702 449 702Q491 702 501 701Q571 690 610 654Q614 649 617 650Q618 650 650 675T683 702Q685 703 692 703Q714 703 714 690Q714 686 687 578T658 466Q653 458 629 458Q606 458 602 463Q600 465 599 466Q599 467 599 469T598 473Q598 475 599 487T600 511Q600 584 557 614T454 645Q386 645 347 606T308 520Q308 506 311 496T323 477T338 464T360 454T384 446T413 439T441 433Q523 416 549 401Q581 384 602 352Q631 309 631 254Q631 214 615 170T567 83T478 12T347 -16Q322 -16 300 -14T261 -8T230 0T205 10T187 20T172 30L162 37L130 11Q124 7 119 3T110 -4T104 -9T100 -13T96 -15T93 -16T91 -17T88 -17H82Q76 -17 73 -16T69 -14T66 -10T63 -5L90 107Q97 133 106 170Q116 211 120 219T136 228H148Q167 228 173 227T179 218Q179 216 176 200T173 168Q173 102 227 74T354 45',
    0x1D47B: '498 62Q511 58 511 43Q511 10 494 1L490 0Q487 0 482 0T424 1T271 2Q201 2 157 2T94 1T72 0H70Q46 0 46 17Q49 54 62 60L66 62H137Q208 62 209 63L218 98Q227 134 244 203T278 339L347 613H300Q262 612 246 611T198 599T146 564Q128 545 114 512T91 454T79 425Q73 419 52 419Q22 419 22 434Q22 440 41 498T80 611L100 666Q105 673 111 675H434Q758 675 762 673Q772 668 772 657Q772 655 756 549T738 434Q735 419 711 419H707Q690 419 686 421Q677 425 677 434Q676 436 678 449T683 485T686 529Q686 553 679 569T662 594T631 607T593 612T544 613H502L433 340Q418 279 400 207T374 100L365 65L364 62H498',
    0x1D47C: '856 686Q877 686 877 668Q877 663 873 649T867 631Q861 624 828 624Q762 622 757 617Q757 613 705 409T651 200Q620 112 540 48T328 -16Q251 -16 196 2T113 51T75 112T63 176Q63 202 70 232T117 422Q129 469 141 520T160 598L167 623Q167 624 123 624H96Q79 624 73 626T64 639Q68 678 81 684Q85 686 104 686Q155 684 268 684Q340 684 377 685T418 686Q441 686 441 668Q437 632 425 626Q421 624 371 624H322L270 415Q224 232 217 198T209 141Q209 45 336 45Q372 45 406 52T475 77T540 128T585 211L590 229Q594 247 601 274T617 336T636 409T654 482T670 547T681 595T686 618Q686 620 685 620H683Q681 621 678 621T671 622Q660 622 630 624Q616 624 610 624T598 626T589 630T587 640Q587 647 590 659Q594 677 598 681T613 686Q618 686 653 685T740 684Q775 684 801 684T840 685T856 686',
    0x1D47D: '401 686Q415 680 415 668Q415 651 404 629Q398 624 356 624Q318 624 318 623Q318 620 337 508T377 284L397 174L472 285Q548 396 623 507T699 620Q698 621 652 624Q634 624 627 627T619 641Q619 648 622 658Q627 677 631 681T650 686Q654 686 686 685T766 684Q794 684 823 684T858 685Q874 685 878 683T886 671Q886 667 882 651Q877 632 873 628T850 624Q800 624 779 617Q774 617 770 613Q767 610 560 304T350 -5Q346 -9 332 -16H306H291Q270 -16 267 -2Q267 -1 260 37T238 161T210 313L156 624H116H94Q62 624 62 642Q66 678 78 684Q82 686 99 686Q144 684 246 684Q330 684 368 685L401 686',
    0x1D47E: '111 624Q109 624 102 624T91 623Q61 623 61 640Q61 660 70 678Q78 686 98 686Q140 684 239 684Q277 684 309 684T360 685T383 686H385Q407 686 407 668Q404 634 391 626Q387 624 348 624Q307 624 307 622Q307 618 332 409Q359 198 359 195L570 532L564 576L558 622V624H522H504Q472 624 472 641Q475 678 488 684L493 686L529 685Q551 684 645 684Q716 684 753 685T795 686Q818 686 818 669Q815 632 802 626Q798 624 759 624Q718 624 718 622Q718 615 743 410Q770 199 770 196Q770 195 806 253T903 406Q1035 618 1035 619Q1025 624 968 624Q943 624 943 641Q943 648 946 659Q950 675 952 679T963 686L998 685Q1020 684 1093 684Q1113 684 1139 685T1173 686Q1207 686 1207 669Q1207 664 1204 652Q1199 631 1194 628T1164 624Q1113 622 1101 615Q1098 612 905 305Q715 -1 709 -7Q699 -17 673 -17Q645 -17 639 -8L581 441Q581 444 442 221Q331 44 314 18T288 -14Q279 -17 263 -17H254Q229 -17 227 -5Q225 2 186 311L147 620V624H111',
    0x1D47F: '931 686Q953 686 953 670Q953 650 944 632Q936 624 924 624H914Q823 624 803 611Q800 609 696 503T591 396Q591 394 667 229L743 62H787H814Q846 62 846 44Q843 7 829 2Q825 0 817 0Q813 0 775 1T664 2Q590 2 551 1T508 0H507Q484 0 484 18Q484 19 488 37Q492 56 497 58T534 62L566 63Q567 64 520 169T471 274Q469 274 369 172T268 67L315 62Q320 62 328 62L335 61Q347 58 347 44Q344 10 331 2L326 0L287 1Q263 2 177 2Q95 2 78 1L53 0Q38 6 38 17Q38 40 50 57Q56 62 78 62Q169 62 188 75Q194 77 435 324L444 334L439 347Q437 351 373 492L313 624H268H246Q220 624 212 632Q210 636 210 642Q210 655 215 669T227 684Q230 686 247 686Q295 684 398 684Q438 684 472 684T527 685T551 686Q567 686 572 671Q572 667 568 651Q563 631 558 628T523 624T492 623H488L526 540Q563 457 564 457Q564 456 574 466T604 496T645 537L724 619Q716 622 677 624H673Q645 624 645 640Q645 660 654 678Q659 683 666 686L704 685Q728 684 813 684Q847 684 873 684T913 685T931 686',
    0x1D480: '97 624H73Q40 624 40 640Q40 660 49 678Q57 686 77 686Q122 684 228 684Q269 684 304 684T360 685T385 686Q406 686 406 668Q406 662 403 653Q398 631 393 628T361 624H353Q321 624 321 623T376 491T432 360L448 377Q465 394 493 424T553 490L673 620Q662 624 630 624Q606 624 606 640Q608 678 623 684Q627 686 634 686Q638 686 671 685T755 684Q777 684 805 685T841 686Q861 686 868 683T876 669Q876 664 873 652Q868 631 863 628T829 624Q764 622 747 611Q727 590 590 441L437 275L411 170Q385 65 384 64Q384 62 429 62H453Q473 62 478 60T487 48Q488 44 484 29Q479 6 473 3Q468 0 454 0Q450 0 436 0T386 1T294 2Q220 2 181 1T138 0Q121 0 116 15Q120 54 133 60Q137 62 187 62H236L289 275L142 624H97',
    0x1D481: '223 430Q192 430 192 448Q192 450 225 561T261 677Q265 683 270 684Q273 686 534 686Q796 686 797 685Q805 682 805 673Q805 668 804 661T800 648T798 641Q796 637 531 352L266 67L329 66H364Q412 66 446 70T523 96T596 157Q617 186 630 220T649 273T663 297Q667 299 684 299H688Q715 299 715 281Q715 278 673 145T628 8Q626 4 617 0H348Q289 0 221 0T139 -1Q112 -1 99 -1T78 1T69 5T68 12Q68 16 71 31T77 49L84 57Q91 65 104 79T133 110T170 151T213 196L610 624H540Q533 624 514 624T488 624T467 623T443 620T422 616T398 609T373 600Q292 560 255 449Q251 436 246 433T223 430',
    0x1D482: '222 -8Q140 -8 89 34T38 158Q38 191 48 227Q72 329 151 390T327 452Q361 452 385 443T421 425T433 416H434L441 421Q448 426 460 430T486 435Q509 435 523 422T538 386Q538 380 522 315T488 179T467 93Q466 87 466 72Q466 42 483 42Q505 42 521 75Q531 94 541 134Q546 155 550 158T571 162H576H587Q607 162 607 148Q606 142 604 132T590 94T566 47T528 9T474 -8Q396 -8 358 40Q295 -8 222 -8ZM404 351Q383 401 324 401Q300 401 270 385T221 330Q206 296 186 220Q166 136 166 106Q166 72 184 58T228 43Q256 43 284 57T328 84T343 103Q343 106 374 228L404 351',
    0x1D483: '220 -8Q142 -8 94 35T45 155V167Q45 187 52 218T104 426L153 622H149Q148 622 144 622T134 623T122 624T111 624T101 624T96 625Q84 628 84 642Q84 647 88 661T94 679Q98 684 109 685T185 690Q258 694 272 694Q289 694 293 679Q293 676 263 553L232 429L244 434Q256 440 281 446T331 452Q417 452 465 407T513 285Q513 235 494 184T439 90T346 20T220 -8ZM385 337Q385 400 318 400Q269 400 226 360Q214 349 211 341T191 268Q162 149 162 113Q162 44 226 44Q269 44 299 76T339 135T362 215Q364 222 365 226Q385 303 385 337',
    0x1D484: '362 325Q362 344 371 361T390 386L399 394Q390 401 355 401Q276 401 231 338Q207 301 189 230T170 122Q170 43 264 43Q392 43 457 105Q472 120 480 117Q486 114 497 102T509 83Q509 79 502 70T477 47T432 21T360 1T259 -8Q194 -8 148 9T80 54T49 109T40 167Q40 280 129 365T352 451Q390 451 396 450Q448 442 473 416T499 358T477 302T421 274H417Q393 274 378 288T362 325',
    0x1D485: '222 -8Q140 -8 89 34T38 158Q38 220 68 285T151 391Q230 452 329 452Q382 452 416 428L422 424Q423 424 447 523L472 622H468Q467 622 463 622T453 623T441 624T430 624T420 624T415 625Q403 628 403 642Q403 647 407 661T413 679Q417 684 428 685T504 690Q577 694 591 694Q608 694 612 679L467 91Q466 87 466 72Q466 43 483 43Q518 43 541 134Q546 155 550 158T571 162H576H587Q607 162 607 148Q606 142 604 132T590 94T566 47T528 9T474 -8Q396 -8 358 40Q295 -8 222 -8ZM404 351Q383 401 324 401Q300 401 270 385T221 330Q206 296 186 220Q166 136 166 106Q166 72 184 58T228 43Q256 43 284 57T328 84T343 103Q343 106 374 228L404 351',
    0x1D486: '260 -8Q196 -8 151 9T83 54T52 111T42 169Q42 188 44 210Q50 240 58 266Q127 434 335 451L338 452Q342 452 345 452Q347 452 353 452T363 451Q426 451 464 424T502 352Q502 289 442 250Q381 211 222 211H184Q184 210 181 196T175 162T171 126Q171 43 264 43Q391 43 457 105Q472 120 480 117Q486 114 497 102T509 83Q509 79 502 70T477 47T432 21T360 1T260 -8ZM237 262Q427 266 427 349Q427 368 409 384T354 401Q316 401 287 388T242 354T216 314T202 278L197 263Q197 262 237 262',
    0x1D487: '584 444Q597 439 597 426Q597 409 586 387Q580 382 505 382H434V380Q432 378 421 314T395 162T368 30Q324 -164 203 -199Q194 -201 175 -201Q123 -201 94 -177T64 -117T88 -58T145 -33Q169 -33 184 -47T200 -84Q200 -122 166 -150L174 -151H185Q202 -148 217 -112Q222 -94 240 9Q246 40 262 132T293 303T307 382H247H210Q190 382 182 385T173 400Q177 436 189 442Q193 444 256 444H318L319 446Q337 565 355 602Q373 640 404 664T458 694T503 701Q569 701 596 676T624 617Q624 581 599 557T544 533Q520 533 504 547T488 585Q488 596 491 606T499 624T508 637T516 646L520 650Q515 650 509 651Q459 651 459 561V554L458 518L452 484Q446 448 445 447V444H584',
    0x1D488: '227 0Q142 0 93 43T43 166Q43 207 58 252T104 341T188 414T310 451L313 452Q316 452 319 452Q321 452 326 452T335 451Q367 451 390 443T425 425L436 416L443 421Q450 426 462 430T488 435Q511 435 525 422T540 386Q540 378 483 151T424 -82Q401 -139 335 -170T167 -202Q72 -202 36 -183T0 -125Q0 -90 24 -67T81 -43Q103 -43 119 -56T136 -99Q133 -124 114 -149L123 -150H183Q222 -150 254 -127T299 -74Q300 -71 306 -47T318 -1T324 23Q323 23 310 18T274 6T227 0ZM407 353Q406 354 404 358T400 365T395 371T388 379T381 385T371 391T360 396T346 400T329 401Q300 401 277 389T240 355T219 317T205 280Q171 148 171 109Q171 77 190 64T235 51T296 67Q322 82 337 98L345 106L407 353',
    0x1D489: '477 56Q477 48 479 46T490 43Q522 45 544 75T577 140Q582 156 585 159T605 162H611H622Q642 162 642 148Q642 138 632 114T602 62T550 13T478 -8Q429 -8 394 17T358 83Q358 95 395 199T433 350Q433 400 394 400H388H383Q335 400 291 363Q256 332 236 298Q233 293 202 170T169 40Q160 18 141 5T99 -8Q70 -8 58 9T45 39Q45 51 116 336L188 622H184Q183 622 179 622T169 623T157 624T146 624T136 624T131 625Q119 628 119 642Q119 647 123 661T129 679Q133 684 144 685T220 690Q293 694 307 694Q324 694 328 679Q328 672 294 540Q286 507 278 473T264 420L260 403Q260 400 269 408Q327 451 393 451H401H410Q425 451 439 450T476 442T515 424T544 391T556 337Q556 286 517 179T477 56',
    0x1D48A: '205 615Q205 646 229 670T283 694Q310 694 324 679T339 641Q339 610 315 586T258 562Q235 562 220 577T205 615ZM24 296Q24 305 34 328T63 380T115 430T187 452Q205 452 223 448T262 435T295 406T308 360Q308 345 287 290T240 170T207 87Q202 67 202 57Q202 42 215 42Q235 42 257 64Q288 92 302 140Q307 156 310 159T330 162H336H347Q367 162 367 148Q367 140 357 117T329 65T276 14T201 -8Q158 -8 121 15T83 84Q83 104 133 229T184 358Q189 376 189 388Q189 402 177 402Q156 402 134 380Q103 352 89 304Q84 288 81 285T61 282H55H44Q24 282 24 296',
    0x1D48B: '321 616Q321 648 346 671T400 694Q425 694 440 680T456 640Q456 609 431 586T376 562Q353 562 337 577T321 616ZM297 360T297 373T294 392T288 400T278 401H276Q241 399 210 372T158 303Q151 287 148 285T129 282H123H116Q102 282 97 284T92 298Q96 331 153 391T279 451Q357 451 388 422T420 354V339L371 142Q363 111 353 71T339 13T329 -30T318 -64T308 -88T296 -109T283 -125T264 -142Q190 -202 88 -202Q44 -202 16 -181T-12 -125Q-12 -93 11 -68T68 -43Q92 -43 108 -58T124 -93Q124 -121 106 -144Q101 -150 103 -150Q120 -150 147 -128T191 -64Q194 -57 242 136T294 345Q297 360 297 373',
    0x1D48C: '99 -8Q71 -8 58 9T45 39Q45 51 116 336L188 622H184Q183 622 179 622T169 623T157 624T146 624T136 624T131 625Q119 628 119 642Q119 647 123 661T129 679Q133 684 144 685T220 690Q293 694 307 694Q324 694 328 679Q328 674 280 482Q231 290 231 287Q231 285 234 286Q259 302 294 334T356 390T420 433T493 452Q528 452 546 427T564 364Q564 308 538 282T480 256Q456 256 441 269T425 308Q425 339 444 359T483 384L502 389Q502 395 496 398Q493 400 483 400Q465 400 449 395T409 374T373 347T323 305T268 257Q274 256 282 256Q312 251 329 247T371 232T411 202Q431 181 431 146Q431 132 427 110T422 73Q422 44 440 44H442Q462 44 478 64T502 102T514 141Q518 157 522 159T547 162H558Q578 162 578 148Q578 118 537 56T440 -7H432Q374 -7 337 21T299 94Q299 103 301 116T304 139Q304 164 281 181T235 202L212 206H211Q176 47 160 24Q137 -8 99 -8',
    0x1D48D: '157 -8Q138 -8 118 -4T77 11T41 42T27 91V106L156 622H152Q151 622 147 622T137 623T125 624T114 624T104 624T99 625Q87 628 87 642Q87 647 91 661T97 679Q101 684 112 685T188 690Q261 694 275 694Q292 694 296 679L151 91Q150 85 150 71Q150 42 167 42Q186 42 205 75Q216 96 225 134Q230 155 234 158T255 162H260H271Q291 162 291 148Q290 145 289 140T283 118T271 87T254 54T229 23T197 1T157 -8',
    0x1D48E: '24 296Q25 302 27 312T41 350T65 397T104 435T159 452Q203 452 234 435Q268 419 285 384L295 392Q305 401 317 410T349 429T389 445Q411 451 446 451Q560 451 592 383Q593 380 594 379L595 375L604 384Q675 452 762 452Q893 452 916 367Q918 356 918 334Q918 285 881 183T841 66Q838 43 849 43Q876 43 901 69T940 138Q945 156 949 159T969 162H975H986Q1006 162 1006 148Q1006 138 996 115T966 63T914 13T841 -8Q794 -8 758 16T721 82Q721 96 758 199T796 351Q796 401 753 401Q702 401 662 369T599 298Q597 294 567 172T533 40Q525 22 506 7T462 -8Q435 -8 422 8T409 39Q409 48 425 114T458 248T476 320Q478 330 478 348T474 377T462 393T449 399T433 400H428Q380 400 336 363Q301 332 281 298Q278 293 247 170T214 40Q206 22 187 7T143 -8T104 7T90 39Q90 47 108 124T146 274L164 347Q166 355 166 372Q166 401 149 401Q129 401 115 379T89 306Q84 288 80 285T55 282H44Q24 282 24 296',
    0x1D48F: '24 296Q25 302 27 312T41 350T65 397T104 435T159 452Q203 452 234 435Q268 419 285 384L293 391Q363 452 454 452Q575 446 597 367Q599 356 599 334Q599 285 562 183T522 66Q519 43 530 43Q557 43 582 69T621 138Q626 156 630 159T650 162H656H667Q687 162 687 148Q687 138 677 115T647 63T595 13T522 -8Q475 -8 439 16T402 82Q402 96 439 199T477 351Q477 401 434 401Q421 401 409 398Q341 388 285 305L278 295L247 170Q216 46 214 40Q206 22 187 7T143 -8T104 7T90 39Q90 47 108 124T146 274L164 347Q166 355 166 372Q166 401 149 401Q129 401 115 379T89 306Q84 288 80 285T55 282H44Q24 282 24 296',
    0x1D490: '254 -8Q191 -8 146 9T80 54T49 111T39 169Q39 206 53 247T96 329T176 402T292 446Q317 451 336 451L344 452Q353 452 359 452Q457 452 516 408T576 279Q576 169 488 81T254 -8ZM349 400Q321 400 287 385T231 338Q206 301 188 228T170 126Q170 99 178 83Q198 44 260 44Q367 44 409 157Q419 185 432 238T445 317Q445 336 443 348Q435 372 416 384T384 398T349 400',
    0x1D491: '24 296Q25 302 27 312T41 350T65 397T103 435T157 452Q235 452 273 404Q336 452 409 452Q434 452 458 448T507 432T550 402T581 354T593 285Q593 221 564 159T480 53Q401 -8 302 -8Q290 -8 279 -7T259 -3T242 3T228 9T218 14T212 18L209 20Q208 19 190 -55T171 -131T198 -132H213Q240 -132 240 -150Q237 -187 223 -192Q219 -194 212 -194Q208 -194 176 -193T95 -192Q48 -192 24 -193T-3 -194Q-11 -194 -16 -190T-22 -182T-23 -176Q-20 -142 -7 -134Q-3 -132 20 -132H44L164 354Q165 357 165 372Q165 401 148 401Q113 401 90 310Q85 289 82 286T60 282H55H44Q24 282 24 296ZM465 339Q465 373 447 387T403 401Q375 401 347 387T303 360T288 341Q288 338 257 216L227 93Q248 43 306 43Q332 43 361 59T410 115Q425 147 445 224Q465 309 465 339',
    0x1D492: '38 159Q38 209 58 260T113 355T205 425T327 452Q338 452 348 451T366 449T382 444T394 440T405 434T414 429T422 423T429 418Q440 429 481 440T533 452Q540 452 545 447T550 437Q550 432 481 152Q410 -130 410 -131T437 -132H452Q479 -132 479 -150Q476 -187 462 -192Q458 -194 451 -194Q447 -194 414 -193T330 -192Q277 -192 249 -193T217 -194Q202 -194 197 -179Q197 -175 201 -159Q206 -139 211 -136T243 -132H283L319 15L307 10Q295 4 270 -2T220 -8Q134 -8 86 37T38 159ZM402 353Q402 358 395 368T369 390T324 401Q301 401 282 394T249 369T226 338T208 297T196 258T186 218Q166 141 166 107Q166 44 229 44Q265 44 294 61T337 95Q341 100 371 222T402 353',
    0x1D493: '24 296Q24 298 28 313T42 352T67 398T104 436T154 452Q198 452 230 437T273 404L282 411Q334 452 393 452Q441 452 470 423T500 350Q500 301 473 279T418 256Q395 256 379 270T363 308Q363 366 424 386Q424 388 420 391T405 398T385 401Q363 401 343 390Q321 380 289 341L252 192Q214 42 212 39Q190 -8 142 -8Q117 -8 103 7T89 39Q89 52 127 202T165 369Q165 402 148 402Q139 402 128 393T110 369Q100 348 90 310Q85 289 82 286T60 282H55H44Q24 282 24 296',
    0x1D494: '140 290Q140 311 148 336T176 388T235 433T326 451H336Q355 451 373 449T418 439T460 412T476 363Q476 333 456 311T406 289Q384 289 371 302T357 335Q357 351 364 364T379 384L386 391Q386 392 381 394T362 398T330 400Q299 400 278 393T247 373T235 352T232 335Q232 322 242 312Q258 299 315 290T403 260Q457 224 457 167Q457 152 453 134T435 90T397 43T330 7T229 -8Q133 -8 95 22T57 91Q57 127 81 152T139 177Q161 177 177 164T194 121Q189 80 153 56Q179 43 236 43Q275 43 303 52T343 75T361 101T366 124Q366 148 338 161T272 180T232 186Q187 198 164 227T140 290',
    0x1D495: '82 382H55Q21 382 21 399Q21 422 33 439Q39 444 93 444H144L162 517Q163 522 167 541T174 567T181 589T192 611T206 627T226 639T253 643Q276 643 291 630T306 594Q306 586 288 514Q284 499 280 481T273 454L271 445Q271 444 317 444Q322 444 331 444T345 445Q377 445 377 428Q377 408 368 390Q360 382 341 382H306H255Q182 86 182 75Q182 43 201 43H204Q242 46 279 81Q298 101 309 119T323 145T330 157T350 162H356H363Q377 162 382 160T387 146Q385 136 372 114T337 64T275 14T192 -7Q131 -7 95 19T59 90V105L128 381Q128 382 82 382',
    0x1D496: '189 389Q189 397 187 399T176 401Q150 399 126 372T89 304Q84 288 81 285T61 282H55H44Q24 282 24 296Q24 307 35 331T65 383T117 431T187 452Q237 452 272 427T308 362Q308 347 273 254T238 111Q238 43 291 43Q319 43 344 58T380 86T391 103T426 247T464 396Q472 414 491 429T535 444T574 429T588 397Q588 390 570 315T534 168L516 97Q514 89 514 72Q514 42 531 42Q544 42 556 56Q574 76 589 134Q594 155 598 158T619 162H624H635Q655 162 655 148Q654 142 652 132T638 94T614 47T575 9T520 -8Q509 -8 498 -7T478 -3T461 2T446 8T434 16T424 23T416 29T410 35T406 39L405 41L397 34Q347 -7 288 -7H281Q148 -7 122 78Q116 95 116 125V136Q116 174 152 273T189 389',
    0x1D497: '380 367Q380 397 406 425T465 453Q493 453 516 430T540 357Q540 314 524 250T467 115T373 13Q338 -8 292 -8Q218 -8 167 23T116 129Q116 178 152 275T189 388Q189 396 187 398T176 401Q148 398 125 372T89 304Q84 288 81 285T61 282H55H44Q24 282 24 296Q24 306 34 330T64 382T116 431T189 452Q231 452 269 429T308 362Q308 346 273 255T238 114Q238 43 306 43Q336 43 363 65T407 118T437 182T456 239T462 268Q462 290 417 315Q380 335 380 367',
    0x1D498: '636 367Q636 400 664 426T719 453Q748 453 772 431T796 357Q796 321 782 256T727 112T633 6Q604 -8 567 -8Q466 -8 415 43Q414 42 410 38T403 31T396 25T388 18T378 11T367 5T355 0T340 -4T324 -7T306 -8Q249 -8 209 5T151 40T125 84T117 129Q117 176 153 274T190 388Q190 408 158 396Q112 376 90 306Q85 288 81 285T61 282H55H44Q24 282 24 296Q24 305 34 328T63 380T114 430T187 452Q240 452 274 427T309 362Q309 346 275 255T240 117Q240 43 317 43Q325 43 333 45T347 50T359 57T369 66T377 75T383 83T388 90L390 95Q390 99 389 110T387 129Q387 139 391 167Q393 177 419 282T448 396Q456 414 475 429T519 444Q546 444 559 428T572 397Q572 384 542 265T511 114Q511 43 579 43Q608 43 633 66T673 122T699 188T714 244L718 267Q718 291 673 315Q636 335 636 367',
    0x1D499: '74 282H63Q43 282 43 296Q43 298 45 307T56 332T76 365T110 401T159 433Q200 451 233 451H236Q273 451 282 450Q358 437 382 400L392 410Q434 452 483 452Q538 452 568 421T599 346Q599 303 573 280T517 256Q494 256 478 270T462 308Q462 343 488 367Q501 377 520 385Q520 386 516 389T502 396T480 400T462 398Q429 383 415 341Q354 116 354 80T405 44Q449 44 485 74T535 142Q539 156 542 159T562 162H568H579Q599 162 599 148Q599 135 586 111T550 60T485 12T397 -8Q313 -8 266 35L258 44Q215 -7 161 -7H156Q99 -7 71 25T43 95Q43 143 70 165T125 188Q148 188 164 174T180 136Q180 101 154 77Q141 67 122 59Q124 54 136 49T161 43Q183 43 200 61T226 103Q287 328 287 364T236 400Q200 400 164 377T107 302Q103 288 100 285T80 282H74',
    0x1D49A: '206 -150Q240 -150 268 -134T314 -95T344 -48T362 -7T367 14Q339 -7 280 -7Q230 -7 195 5T144 39T122 79T115 122Q115 175 152 274T189 388Q189 396 187 398T176 401Q148 398 125 372T89 304Q84 288 81 285T61 282H55H44Q24 282 24 296Q24 306 34 329T64 381T116 431T188 452Q239 452 273 427T308 361Q308 347 273 253T237 109Q237 43 291 43T388 98Q388 99 425 246T463 396Q471 414 490 429T534 444T573 430T587 399Q587 386 537 186T483 -25Q461 -84 410 -126T296 -188Q248 -202 204 -202Q127 -202 96 -175T64 -114Q64 -82 86 -57T144 -31Q169 -31 184 -45T199 -83Q199 -89 198 -94T196 -104T193 -113T189 -120T184 -128T179 -134T173 -141T168 -147Q189 -150 206 -150',
    0x1D49B: '66 -8H60Q34 -8 34 5Q34 9 42 22T70 59T115 110Q162 156 255 229T381 332L389 339H381Q348 332 298 329T212 325T172 321Q168 318 151 318H146Q116 318 116 332Q116 334 118 342Q128 374 154 402Q205 452 265 452Q304 452 352 422T426 392Q441 392 462 421T485 451T508 452H518Q539 452 539 438Q539 431 516 401T458 334Q412 289 312 210Q229 146 191 111L183 103H195Q203 103 213 104T240 107T268 110Q301 114 337 116T391 119T428 123T455 134T469 157Q473 166 495 166Q521 166 525 161Q532 156 528 141Q510 81 456 37T337 -7Q297 -7 251 22T177 52Q154 52 134 38T100 8L88 -7Q86 -8 66 -8',
    0x1D49C: '76 60Q83 29 135 29Q190 29 264 81Q280 93 280 95T277 99T265 106T248 118Q189 166 189 237Q189 307 245 388Q267 421 299 436Q336 455 386 455Q488 455 550 403L559 395L571 405Q727 544 944 679L957 687L966 701Q968 704 970 707T973 712T975 714T978 716T982 717T989 717H995Q1004 717 1007 717T1013 714T1016 708Q1016 705 829 403L636 92L630 83Q659 93 685 110T728 143L745 158Q758 158 752 138L748 127L740 119Q676 58 605 42L593 22Q590 17 587 12T583 6T579 2T573 0T564 0H530H484Q480 3 480 8Q480 15 489 26T498 39T497 40Q477 40 423 49T327 74L316 78L302 68Q200 -8 121 -8Q85 -8 60 7T35 53T60 113T123 144Q144 144 153 132T162 106Q162 89 143 75T99 61Q90 61 76 65V60ZM904 614L905 615Q901 615 840 573T700 469T581 369L587 359Q600 340 608 315T618 273T622 238T624 216L764 414Q904 612 904 614ZM525 363Q493 405 379 418H375Q342 418 309 378Q251 300 251 234Q251 174 306 137Q318 128 322 131Q323 132 329 139Q351 161 362 180Q430 273 509 348L525 363ZM579 205Q579 245 571 278T556 323T546 337L521 311Q461 248 422 196T362 121L353 111Q427 85 499 79Q517 77 520 77L525 76L549 111Q551 114 556 121T563 131T568 138T573 147T575 157T577 169T578 185T579 205',
    0x1D49E: '367 89Q367 84 353 77T334 70Q325 70 312 83T298 120Q298 169 364 233T496 298Q538 298 563 275T588 220V213Q588 132 501 53T306 -26Q251 -26 211 6T170 114Q171 148 181 184T205 248T232 298T255 334T265 349T246 350Q127 350 77 390T26 480Q26 533 71 581T178 656T295 683Q312 683 312 676Q312 674 311 672L302 660Q294 648 292 647L286 646Q280 646 276 646Q197 641 145 583T93 476Q93 387 265 387Q271 387 277 387T287 388T292 388T313 414T373 483T451 562Q618 712 732 727Q733 727 740 727T753 728Q790 727 804 708T819 665Q819 643 810 617T773 553T699 481T572 414T385 361Q381 361 367 341Q247 172 247 86Q247 11 325 11Q404 11 465 95Q518 165 528 208Q529 212 529 220Q529 237 518 249T480 261Q431 261 387 209T343 126Q343 111 355 101T367 89ZM777 658Q777 691 738 691Q704 691 658 662T570 590T491 504T432 432T410 400H411Q416 400 440 405T505 423T589 455T675 506T743 576Q777 627 777 658',
    0x1D49F: '251 272Q199 272 168 298T136 374Q136 432 175 496T267 603Q321 645 395 676T552 708Q610 708 655 694T724 659T763 618T784 584L790 569Q792 569 800 572T819 576T840 578Q855 578 855 571Q855 566 846 554T829 541Q824 541 817 539T804 533T799 529Q802 517 802 483Q802 376 742 256T585 53T392 -31Q353 -31 300 -7L289 -2L277 -6Q242 -17 192 -17Q141 -17 113 -13T77 -3T68 14Q68 20 73 28T93 45T131 58Q152 62 197 62Q241 62 272 48L282 44Q308 65 334 93Q380 145 467 266T622 454Q644 476 664 493T694 517T720 534T740 547Q699 671 540 671Q461 671 385 625T276 534Q265 520 251 499T219 438T202 368Q202 309 267 309Q325 309 396 383T491 545Q492 548 493 552Q494 558 496 559T511 564Q513 565 514 565Q523 568 527 568Q534 568 534 560Q534 554 529 537Q507 442 420 357T251 272ZM332 20Q332 19 341 15T368 6T400 2Q425 2 457 13T531 49T614 125T690 248Q750 376 750 476V490L738 479Q698 436 646 366T554 239T455 121T332 20ZM226 20Q226 25 186 25Q181 25 174 24H166Q161 24 158 23H152Q170 21 197 21Q205 21 213 21T223 20H226',
    0x1D4A2: '206 318L249 320Q249 327 259 352T282 399Q312 447 366 510T465 610Q588 717 661 717Q695 717 716 701T738 653T704 577Q663 522 610 474T512 397T424 346T359 315T333 306Q331 304 327 290T323 267Q323 229 368 229Q402 229 439 254T505 312T571 382T638 435Q642 437 644 437Q658 437 658 409Q655 403 647 399T624 379T595 326Q583 296 564 267T523 179Q504 126 483 91T423 27Q335 -37 231 -37Q191 -37 157 -30T95 -2T67 53Q67 89 94 123T159 158Q180 158 190 146T200 119Q200 102 178 89T136 75Q128 75 120 78T110 81Q105 81 105 62Q105 38 129 24T180 5T238 1H244Q282 1 319 32Q349 55 371 85T436 190L465 239Q413 192 354 192Q300 192 274 217T245 276Q245 284 242 284Q220 280 187 280Q106 280 59 315T12 409Q12 457 43 508T118 597T210 659T295 683Q308 683 308 675Q308 670 299 658T282 646Q266 646 240 633Q182 606 118 511Q76 448 76 400Q76 368 105 343T206 318ZM677 660Q677 680 646 680Q616 680 565 629Q537 601 514 571Q493 544 455 492T389 398T360 355Q366 357 386 367Q463 406 534 473T641 591T677 660',
    0x1D4A5: '829 148Q845 148 845 143T841 130T823 109T788 83T730 54T644 22Q637 20 634 19T627 16T622 13T618 10T612 3T604 -6Q510 -112 396 -203T201 -312Q190 -314 171 -314H166Q156 -314 147 -312T123 -304T101 -283T92 -245Q92 -203 117 -160T175 -93Q214 -65 280 -41T390 -6T509 22L532 27L547 47Q673 219 673 225L665 228Q657 231 648 235T627 249T606 270T591 300T584 341Q584 389 614 447T686 544Q735 588 799 615T959 667T1108 713Q1118 717 1119 717Q1125 717 1129 705T1133 689Q1133 686 1115 673Q1051 627 1001 579T918 481T867 403T828 328T805 277Q802 271 801 267T798 261T798 259Q849 270 900 317Q956 371 956 421Q956 438 953 457T950 480Q950 481 950 482L951 484Q953 487 966 492T985 498Q989 498 994 473Q1000 441 1000 423Q1000 362 934 302T790 223L775 220L767 207Q757 191 731 158T685 98T662 63Q662 61 690 70T755 99T811 142L816 148H829ZM646 332Q646 308 659 291T685 268L698 263L735 320Q828 465 865 511Q923 582 1003 643L1005 645Q1004 645 924 620Q775 572 716 492Q646 401 646 332ZM184 -278Q233 -278 311 -212T444 -80L498 -15H496Q468 -20 424 -30T293 -70T174 -133Q167 -140 158 -153T138 -190T127 -232Q127 -278 184 -278',
    0x1D4A6: '521 506Q521 493 549 493Q608 493 660 527Q667 531 690 555L736 604Q750 619 777 646T825 694T848 716T852 717Q857 717 860 711T865 697L866 690Q866 687 812 615Q654 404 654 401Q655 401 656 401T659 402T665 403T680 404Q718 404 734 374Q735 370 743 376Q745 377 752 382Q806 416 852 466T933 563T982 623Q1028 666 1075 686T1155 706Q1165 706 1173 705T1186 703T1194 699T1199 695T1201 692T1202 688V687L1204 677Q1204 667 1191 670Q1189 670 1183 670T1174 671Q1137 671 1086 643T1001 564Q970 517 899 449T749 339L739 333L736 322Q730 295 703 253T652 165T628 77Q628 21 681 21Q692 21 698 23Q751 46 817 134L827 148H844Q861 148 864 146Q869 140 859 127Q818 75 761 29T665 -17Q649 -17 633 -12T598 4T569 40T558 100Q558 128 563 152T585 205T609 245T643 294Q647 300 645 300Q633 297 615 297Q607 297 600 298T589 300T584 301Q581 301 569 284T536 236T488 171T418 97T331 28Q233 -37 155 -37Q104 -37 68 -17T29 44Q29 82 55 120T123 158Q144 158 154 146T164 119Q164 102 143 89T100 75Q92 75 86 76T77 80T72 82Q68 82 68 59Q68 37 85 23T123 5T167 0Q203 0 238 21T311 85T382 174T468 288T563 410Q576 426 588 440T607 462T615 472L605 468Q568 456 533 456H527Q490 456 463 483Q451 496 451 508T468 545T486 581Q486 593 465 593Q448 593 425 568T384 518T364 493Q364 492 347 492Q326 492 326 499T351 537T414 599T477 630Q509 630 528 615T547 576Q547 556 534 533T521 506ZM675 357Q675 368 665 368Q656 368 644 363Q631 355 616 333H628Q640 334 672 341Q675 354 675 357',
    0x1D4A9: '764 513Q764 482 772 423T780 330Q780 304 778 285T775 256T773 245Q778 252 826 328T932 484T1042 617Q1077 652 1114 678T1173 715T1200 726Q1208 726 1208 717Q1208 711 1206 695L1203 679L1199 675Q1197 675 1187 670T1161 657T1133 639Q1050 583 959 456Q906 381 858 307T779 179T725 83T691 18T679 -6Q677 -8 660 -8H656Q639 -8 639 -1Q639 4 646 17Q685 93 685 173V196Q685 233 681 288T676 380Q676 438 687 487L664 454Q505 230 454 170Q366 64 290 14T163 -36H152Q87 -36 58 -11T29 46Q29 82 55 120T123 158Q144 158 154 146T164 119Q164 102 143 89T100 75Q92 75 86 76T77 80T72 82Q67 82 67 59Q67 37 89 19T167 1Q187 1 197 3Q221 9 246 22T292 52T336 91T375 132T411 174T440 212T463 245T478 266Q779 695 784 698Q786 700 802 700H818Q824 694 824 692T809 668T779 604T764 513',
    0x1D4AA: '259 -8Q230 -7 205 0T153 24T112 74T96 153Q96 254 174 379T374 599T607 707H621Q732 707 778 661Q805 634 805 598Q805 558 775 517T696 452L684 447V441Q684 378 626 273T484 97Q379 7 288 -7Q279 -8 259 -8ZM760 594Q760 670 608 670Q562 670 493 622T347 472Q174 240 174 131Q174 76 205 53T279 29Q294 29 306 32Q405 60 507 205Q592 325 616 401Q625 426 625 435Q625 436 621 436T603 440T569 449Q524 466 515 475Q513 477 513 481T525 496T541 508L548 504Q555 501 565 497T587 488T609 480T625 476Q627 476 626 481Q626 486 623 494T613 513T589 533T548 541Q495 541 413 454T286 281Q265 241 254 201T240 141T235 120Q234 118 217 111T197 104Q195 104 192 107T189 112Q190 125 193 147T220 231T280 348Q335 428 407 493T539 576Q548 578 563 578Q594 578 617 568T653 546T672 518T681 494T683 482Q683 481 684 481Q690 481 707 495T742 538T760 594',
    0x1D4AB: '571 345Q571 384 612 418T687 452Q698 452 698 445Q698 436 679 417Q677 415 670 415Q650 412 633 389T615 350Q615 340 621 331T634 319T643 315L663 342Q751 462 817 536Q873 595 896 614L907 625Q843 680 701 680Q594 680 499 632T344 516Q317 486 296 449T267 384Q262 366 262 354Q262 332 276 316T326 299H327Q374 299 426 338Q481 376 537 456T597 598Q597 616 599 617Q601 619 614 624T630 630Q639 630 639 604V587V581Q639 519 597 456Q544 377 462 320T318 262Q278 262 250 282T222 350Q222 418 285 504Q360 597 480 656T702 716Q773 716 825 707T898 688T951 660Q962 670 985 685T1012 700Q1018 700 1022 690T1026 673Q1026 670 1019 664Q988 633 988 631Q988 630 999 618T1020 580T1031 522Q1031 471 1003 419T928 330Q854 275 765 264Q757 262 733 262H714L701 245Q615 121 473 42T218 -37Q159 -37 125 -15T90 46Q90 82 116 120T185 158Q203 158 213 147T224 121Q224 110 219 102Q198 75 159 75Q154 75 149 76T143 77T140 77Q137 72 137 53Q138 37 149 26T177 9T205 2T228 0Q313 0 419 74T602 257L620 281L614 285Q607 289 601 294T587 306T576 323T571 345ZM950 529Q950 576 943 576Q940 576 840 439T741 299H751Q804 300 845 334T924 438Q949 490 950 529',
    0x1D4AC: '330 387Q330 331 402 331Q463 331 514 371T589 459T613 542Q613 559 608 570T598 588T593 596Q593 601 617 610Q632 617 636 616Q675 585 675 527Q675 464 629 409T516 324T387 294Q271 294 271 394V402Q271 438 292 478Q344 582 457 649T672 717Q765 717 825 675T885 548Q885 433 771 298T498 76Q493 73 491 72T486 69T484 67T485 66Q539 41 607 41Q655 41 703 71T780 139L787 148H804Q806 148 809 148Q826 149 826 140Q826 128 786 91T687 19T589 -16H576Q503 -16 414 20L396 27Q279 -17 192 -17Q130 -17 92 2T54 53Q54 92 107 123T222 155Q303 155 401 106L431 91L441 97Q476 118 527 157Q622 236 711 361T801 573Q801 591 795 607T775 641T732 668T660 679Q592 679 528 644T422 560T355 464T330 387ZM201 20Q232 20 267 27T322 40T342 49Q342 52 315 60T243 77T160 86Q150 88 144 88Q130 88 122 79T112 62L111 53Q111 20 201 20',
    0x1D4AE: '346 463Q346 419 406 386T576 352H588L613 384L681 476Q767 594 842 651T973 708Q1016 708 1016 661Q1016 621 987 562T894 449Q802 375 696 338L682 334L665 312Q638 279 605 233T547 158T482 97Q418 46 332 5T158 -36Q87 -36 58 -11T29 48Q29 82 55 120T123 158Q144 158 154 146T164 119Q164 102 143 89T100 75Q92 75 86 76T77 80T72 82Q67 82 67 59Q67 28 98 14T166 0Q232 0 320 55T491 226Q530 279 551 305L558 314Q558 315 543 315Q417 321 353 365T289 460Q289 566 488 632Q578 662 660 665H679Q685 660 685 657T676 642Q670 633 666 629L664 628Q663 628 661 628T655 628Q606 628 546 615T426 568T350 486Q346 475 346 463ZM976 653Q976 671 959 671Q938 671 919 661T883 629T858 593T835 554Q832 548 830 545Q802 495 775 455T734 400T721 382L736 388Q876 449 946 568Q948 572 949 573Q976 622 976 653',
    0x1D4AF: '354 350Q264 350 264 426Q264 442 265 448Q279 514 347 582T503 692T662 735Q719 735 774 714T882 672T983 651Q996 651 996 644Q996 639 977 612T954 581Q953 580 938 580Q909 582 884 587L869 591L870 587Q870 583 849 557T796 491T748 422Q729 391 692 313T620 188Q555 105 454 34T253 -37Q214 -37 181 -30T120 -2T92 53Q92 89 119 123T184 158Q205 158 215 146T225 119Q225 102 203 89T161 75Q153 75 145 78T135 81Q130 81 130 62Q130 39 153 24T204 5T267 0Q311 0 358 29T454 117T539 226T629 358T710 476Q726 496 744 516T778 551T807 577T828 595L836 601L785 623Q743 642 713 651T668 661T626 663Q564 663 509 644T418 596T356 535T317 475T305 431Q305 416 312 408Q323 388 369 388Q429 388 465 411T530 480Q557 526 557 565Q557 573 556 579T555 587T555 590Q555 591 568 600T584 611Q588 612 600 603Q622 581 622 549Q622 516 600 475T536 405Q454 350 354 350',
    0x1D4B0: '55 377Q55 443 122 523T290 660T478 717Q533 717 575 689T618 594Q618 565 611 538T585 477T552 422T506 355T458 288L357 146Q307 68 307 31Q307 20 318 20Q326 20 331 21Q367 27 411 57T490 128L767 500L861 617H908H939Q953 617 956 616T960 609Q960 605 928 566T816 423T648 198Q587 113 571 86Q540 34 540 21Q540 20 545 20Q580 25 623 55T696 124Q702 132 704 133T722 134H739Q744 130 744 127T735 113Q713 80 655 38T548 -14Q527 -17 524 -17Q475 -17 473 47V63L462 55Q364 -17 302 -17Q235 -17 235 69Q235 88 239 105T248 135T268 171T292 205T328 251T369 304Q376 313 395 338T423 374T450 408T476 445T499 479T519 514T534 546T545 579T548 608Q548 647 522 663T460 680Q355 680 243 591T99 406Q97 399 97 383V377Q97 339 153 339Q169 339 175 340Q215 350 241 373T298 444Q333 496 349 535T367 588T374 603Q402 616 408 616Q416 616 416 608Q416 563 393 492T320 378Q233 302 140 302H132Q75 302 57 353Q55 361 55 377',
    0x1D4B1: '540 717Q542 717 545 717Q562 717 562 710Q562 708 557 702T541 678T517 632T485 544T448 407Q447 405 443 388T438 366T433 345T427 321T420 299T411 274T400 250T387 223T372 197Q363 181 364 181L388 203Q476 284 527 354T620 490T718 612Q754 647 790 673T835 700Q839 700 842 691T848 672L850 662Q850 657 842 653Q803 630 768 600T699 527T653 467T610 405Q554 322 518 282T368 138Q307 84 273 51T231 9T218 -5L207 -17H175Q169 -11 169 -9Q169 -4 183 10Q227 56 258 120T302 234T330 350T356 445Q357 450 360 458L362 464Q317 434 276 434Q221 437 221 479Q221 498 240 521T259 552Q259 565 235 565Q209 565 174 546T105 482Q103 480 102 478T99 474T97 472T95 470T93 468T90 467T86 467T81 467H75Q56 467 56 475Q56 482 82 511T156 570T253 601Q289 601 311 590T334 557Q334 543 312 517T289 478Q289 471 297 471Q327 471 378 514Q384 519 390 531T412 571T451 632Q482 675 524 717H540',
    0x1D4B2: '232 504Q232 492 263 492Q304 492 356 515L367 520L379 537Q443 632 515 705L527 717H543H552Q564 717 564 709Q564 705 562 703T554 694T540 677T518 643T488 589T448 504T398 385Q368 309 353 281L349 270L523 472L669 634Q726 695 737 706T757 717H765Q786 717 786 710Q786 704 776 691T738 627T675 497Q630 394 609 354T541 244Q456 120 449 111L447 107L448 108Q630 240 700 327Q734 368 788 463Q821 520 841 550T898 619T980 683Q1010 700 1018 700Q1020 700 1023 697T1026 692Q1026 688 1024 671T1020 652Q1018 650 1007 645T988 635Q940 609 902 565T842 477T781 374T699 272Q653 226 535 133Q423 47 373 -2L358 -17H342Q320 -17 320 -11Q320 -7 328 5T354 46T390 112Q416 161 439 217T488 326T564 453L589 490Q587 490 523 414T352 217T166 14Q138 -16 137 -16Q136 -17 120 -17Q106 -17 103 -16T99 -9Q99 -5 100 -3T106 3T116 14T132 35T154 72T184 129T222 212T270 327Q310 426 337 471L326 467Q278 455 243 455Q209 455 188 462T161 478T155 496Q155 508 176 533T198 576Q198 579 198 581T192 588T178 593Q151 593 100 519Q89 504 86 502T75 499H68Q46 499 46 506Q46 510 63 534T112 587T170 627Q178 629 195 629Q235 629 252 613T270 577Q270 556 251 532T232 504',
    0x1D4B3: '351 351Q308 351 290 373T272 426Q272 487 329 566T478 688Q538 717 584 717Q635 717 681 696T745 620Q752 598 752 564T745 498L741 485Q742 486 769 516T825 573T889 634T962 689T1027 716Q1035 717 1060 717Q1083 716 1096 714T1120 705T1131 686Q1131 668 1109 647T1055 626Q1035 626 1026 638T1016 663Q1016 667 1020 679H1015Q971 671 886 589T728 413L688 360Q688 359 725 359H750Q762 359 766 357T770 348Q769 345 761 335T750 322Q748 321 704 321H660Q651 311 632 282T589 199T565 107Q565 25 653 20Q684 20 720 44T779 95T826 152T850 183L856 184Q861 184 865 184Q888 184 888 173Q883 163 845 117Q770 37 723 10T638 -17Q584 -14 554 17T523 101Q523 147 534 167L532 166Q530 164 526 160T518 153Q378 15 256 -15Q250 -16 226 -16Q161 -16 132 7T103 59Q103 93 129 125T194 158Q232 158 232 121Q233 118 233 113T221 96T188 77Q182 75 168 75T150 78V70Q150 43 178 32T241 20Q340 20 527 286L552 321H524Q489 321 489 330Q489 333 497 344T509 358Q511 359 545 359H579Q580 362 597 389T631 445T661 514T675 586Q675 637 645 658T572 680Q506 680 444 632T348 527T314 434Q314 388 361 388H364H366Q380 388 389 390T416 408T457 454Q487 497 505 536T526 594L529 613Q531 615 545 622T560 630Q568 630 573 613T578 577Q578 518 532 455Q504 413 453 382T351 351',
    0x1D4B4: '155 280Q116 280 87 300T57 368Q57 409 87 466T192 589Q269 653 345 684T472 716Q572 716 613 675Q644 644 644 599Q644 585 643 574T637 550T629 529T616 505T600 481T578 450T554 416Q494 330 493 328L480 306Q466 278 466 256Q466 227 492 227H496Q514 227 534 234Q541 237 544 241T571 279L762 559Q777 579 792 595Q818 620 856 646T919 686T946 700Q951 700 955 692T959 677Q959 673 947 665T911 639T866 595Q816 538 749 408T640 225Q574 138 464 61T248 -17Q190 -17 144 1T98 62Q98 81 109 102T131 135Q156 156 183 158Q226 158 226 121Q226 111 224 107Q215 93 196 84T162 74Q154 74 147 77H144V70Q146 41 185 31T263 20Q363 20 493 175L507 192H504Q500 191 498 191Q484 189 476 189Q430 189 405 219T379 287Q379 294 379 299T382 311T385 322T391 335T398 347T408 363T420 379T434 399T450 422Q455 429 469 449T488 475T504 499T520 523T533 543T544 565T552 583T557 603T559 620Q559 680 467 680Q402 680 333 646T213 563T131 462T98 373Q98 343 119 330T169 317Q187 317 212 333Q242 354 291 423T352 555Q354 562 355 588Q355 612 356 612Q357 614 371 622T387 630Q391 630 397 623T409 597T415 556Q415 507 380 448T294 344Q216 280 155 280',
    0x1D4B5: '221 428Q221 487 280 555T425 670T583 717H587Q641 717 665 695T689 646Q689 625 674 600T658 564Q658 550 671 541T695 530T710 528L718 539Q779 613 821 646Q894 707 964 707H970Q1010 707 1025 675Q1032 661 1032 645Q1032 626 1022 607Q1008 579 980 560T897 522Q867 512 836 505T788 496L771 493Q768 493 760 477T736 429T702 370Q700 367 698 363Q696 360 696 359H805Q809 355 809 350Q809 340 791 322Q789 321 728 321H668Q562 179 433 88L419 78L434 73Q505 54 554 54Q609 54 654 82T720 140H752Q758 134 758 132Q758 128 747 113Q711 67 657 32T552 -14Q540 -16 517 -16T480 -15T439 -3T375 27L354 38L338 30Q257 -8 191 -8H184Q154 -8 133 -5T103 1T88 10T83 19T83 29Q83 35 86 44T100 65T127 88T173 105T241 112Q286 112 342 99L360 95L372 105Q434 157 523 270L560 320Q560 321 533 321L507 322Q502 325 502 330Q502 339 521 358Q523 359 556 359H588L669 474L682 491Q676 492 665 494T647 498T632 503T614 510T596 521Q556 547 556 570Q556 585 579 618T603 663Q603 679 568 679Q510 679 452 650T354 581T288 500T262 431Q262 407 280 397T321 387Q331 387 341 390T360 398T376 409T390 423T400 435T409 447L414 454Q457 514 460 562Q460 575 461 576Q461 577 475 586T492 595Q496 595 503 588T514 572Q520 559 520 539Q520 473 452 412T308 351Q269 351 245 370T221 428ZM989 642Q989 667 953 671Q905 671 871 644Q853 632 832 604T799 554T787 531H788Q801 531 842 539T916 561Q989 592 989 642ZM198 29Q230 29 257 36T295 52L306 59Q306 63 259 73Q251 74 209 74Q177 74 158 66T134 48L130 40Q130 29 198 29',
    0x1D504: '22 505Q22 563 94 624T271 685H280Q416 685 443 560Q447 535 447 504Q444 414 405 330L399 319L229 155Q233 154 241 153T253 150T265 145T281 135T301 119T328 93L357 64L402 92Q438 116 473 137L500 154V339Q500 528 495 593V601L559 649Q621 696 624 696L638 686L629 677Q599 650 593 638Q582 614 581 504Q580 490 580 443Q580 314 584 238Q584 235 584 224T584 210T585 199T586 187T588 176T591 164T595 152T601 137T609 121Q630 77 640 77Q661 77 703 101Q704 95 706 90L707 86V84L636 29Q618 15 601 2T574 -19T564 -25L500 121Q499 121 399 48L299 -26Q298 -26 291 -15T272 11T245 42T209 69T165 80Q120 80 58 43L48 37L40 42L32 48L122 117Q196 173 241 211Q319 280 343 327T368 447Q368 535 317 582Q264 633 199 633Q155 633 122 605T86 542Q86 518 133 467T181 387Q181 348 140 309Q113 281 73 260L64 255L50 265L59 273Q112 307 112 345Q112 363 90 387T45 441T22 505',
    0x1D505: '48 506Q48 568 120 629T268 691Q362 691 425 594L431 585L441 594Q478 628 528 657T629 686Q665 686 687 670Q703 658 718 584T753 506Q756 505 763 505Q778 505 804 512L815 516L820 496Q820 494 808 490T774 476T732 454Q720 445 708 437L675 415L640 394L625 383Q626 382 635 382Q652 382 670 379T712 364T754 336T784 289T797 220Q797 172 776 122Q769 106 766 102T745 84Q654 11 619 -8T538 -27Q483 -27 387 10T249 47Q218 47 186 34T133 8T112 -5T104 7T97 21L196 82Q259 120 284 140Q333 181 351 214Q368 251 368 353Q368 588 228 620Q222 621 205 621Q160 621 139 596Q117 569 117 548Q117 526 162 470T208 387Q208 352 179 320T104 264Q88 256 86 256Q83 256 70 266L82 274Q134 309 134 343Q134 352 130 359Q118 377 100 401T72 439T56 470T48 506ZM453 528Q457 496 457 419L458 357L488 367Q554 390 622 425Q673 449 673 453L671 454Q669 456 665 460T657 473T648 498T639 541Q629 597 616 613Q599 633 567 633Q534 633 493 599Q471 577 457 540L453 528ZM713 176Q713 252 661 295T528 339Q512 339 494 336T466 330T455 325Q454 325 452 311T444 270T425 217L420 207L304 118L319 116Q381 111 475 74T602 37Q655 37 684 79T713 176',
    0x1D507: '346 611Q300 611 261 604T194 584T144 555T107 519T82 481T65 443T55 410T50 383T48 367L37 372L27 378V393Q33 524 115 603Q160 648 230 666T365 685Q423 685 434 684Q745 653 745 405Q745 281 694 151L687 133L657 103Q524 -27 470 -27Q456 -27 331 18T157 64Q122 64 84 28Q67 9 57 -6L50 -16L43 -8L35 0Q35 3 46 22T83 74T141 133H154Q208 136 253 161T299 230Q299 259 277 290T232 348T209 392Q209 434 254 479T358 556L368 561L382 550Q382 549 381 548T377 545T371 541Q353 527 326 496T298 444Q298 430 343 365T389 277Q389 240 353 202T267 136L252 127Q304 118 400 83T513 47Q548 47 582 79T635 145Q671 208 671 323Q671 390 651 441T599 524T523 576T436 603T346 611',
    0x1D508: '301 564Q240 564 203 507T165 361Q165 280 190 218T260 119T358 64T474 46Q491 46 499 47L511 48L628 106V80L499 -5Q498 -6 497 -7T494 -10T490 -12T485 -15T478 -17T468 -19T456 -21T439 -22T418 -23T392 -24Q252 -20 169 67T86 315Q86 409 124 491Q128 500 139 508Q211 560 272 591L293 601L325 585L338 594Q374 617 422 639T502 674L532 685Q533 685 540 663T557 614T574 581Q580 575 591 575Q600 575 616 580T634 585V564L623 559Q572 535 532 529L522 527Q491 547 442 627Q442 628 440 627T435 624T428 620Q410 610 405 597T399 559V553Q399 534 409 487Q419 431 427 369L476 400Q526 431 526 432Q531 426 537 420Q565 382 602 359Q611 353 610 352Q609 351 575 332T537 312Q536 312 505 335T463 358Q451 358 439 352T425 337Q394 243 263 223L251 221L243 229L235 236L245 239Q274 247 303 266Q320 277 325 290T330 336Q330 394 315 451T300 531Q300 549 303 561Q303 564 301 564',
    0x1D509: '198 617Q177 617 159 609T128 587T106 557T91 524T82 494T76 472L75 463Q74 463 67 468T59 474Q59 488 63 510T80 564T114 622T170 667T253 686Q272 686 295 680Q330 672 384 645T468 598Q498 580 524 580Q549 580 595 626L612 643V616L599 599Q522 500 482 500Q466 500 435 514L420 521Q388 516 349 486T309 415Q309 396 323 366T352 315T367 294Q367 293 375 301T398 322T429 352L490 410Q537 355 542 332Q542 330 531 315T510 286L499 273Q479 313 449 313Q437 313 419 303T390 282L378 271L394 250Q444 183 470 129Q484 96 484 71Q484 -19 402 -86T223 -153H220Q175 -153 137 -120Q103 -89 103 -8Q103 12 106 40T109 96Q109 132 103 146T72 161Q46 161 16 143L14 154L11 165Q88 219 143 219Q163 219 177 197T192 127Q192 102 186 65T179 4Q179 -60 210 -88T272 -117Q319 -117 362 -75T405 33Q405 72 363 139T278 271T235 368Q235 385 243 404T264 439T292 472T324 500T353 522T374 538L382 543Q382 544 351 562T274 598T198 617',
    0x1D50A: '373 560Q373 543 378 509T388 448T394 421Q396 421 416 433T474 474T548 537L538 543Q481 570 452 628L487 659Q521 690 522 690L526 684Q529 677 535 667T551 646T575 625T607 608Q617 606 621 606Q644 606 660 622L664 600L497 455Q495 453 527 453H546Q628 453 665 407L666 406Q710 357 710 297Q710 196 631 109L621 98L577 69Q471 -7 452 -15Q413 -26 370 -26Q239 -26 155 59Q66 148 66 319Q66 442 113 505L191 552Q269 600 270 600H271Q273 600 280 596T293 589T299 586Q300 586 357 623Q413 659 415 659L426 649Q373 604 373 560ZM219 228Q305 262 305 329V343Q305 384 294 434T282 519Q282 562 287 567Q287 569 286 569Q222 556 191 520Q144 464 144 362Q144 213 228 128T420 42Q514 42 568 103T622 255V260Q622 320 578 357Q526 400 453 400Q434 400 410 396L399 394L400 378V372Q400 330 388 301T348 254T305 232T252 217Q245 215 242 214L234 213L226 220L219 228',
    0x1D50D: '194 601Q175 601 155 590T121 564T95 533T76 506L69 495Q55 503 55 507Q55 509 60 521Q87 586 132 636T222 686Q251 686 289 660T363 607T422 580Q442 580 466 588T506 604L522 612V591L487 574Q371 517 359 517H357Q332 522 280 561T194 601ZM383 39Q383 96 356 203T329 361Q329 364 329 370T330 378L331 388L339 399Q362 429 394 462T446 513T469 531Q470 531 477 527T484 521L474 509Q411 434 411 379Q411 341 434 223T458 82Q458 1 390 -69T239 -139Q191 -139 162 -123T123 -83T108 -30T101 23T88 64T52 80Q27 80 -4 64L-10 80Q-5 85 13 98T63 128T118 144Q149 144 163 129T179 93T182 38T187 -19Q199 -96 278 -96Q294 -96 309 -91T343 -73T372 -31T383 39',
    0x1D50E: '154 37Q219 41 219 142Q219 203 190 302T160 434Q160 445 172 472T209 534T269 602T354 657T460 680H472Q576 680 618 617V599Q618 578 615 555T608 517T602 501Q596 495 587 495Q586 495 586 499Q586 500 586 505T585 513Q567 628 415 628Q384 628 356 621T302 596T260 546T244 466Q244 416 258 377L261 367L276 382Q294 401 317 422T386 471T468 500Q500 500 518 477T544 422T554 386Q555 382 560 376T576 364T601 357Q612 357 623 361Q623 360 621 351T617 340L541 305L465 272Q469 271 476 270T488 266T502 260L512 255L519 242Q533 211 546 157T564 93Q584 53 617 53Q649 53 682 74V54L675 46Q646 17 612 -5T554 -27Q546 -27 538 -23T517 -9T494 28T478 91Q465 172 448 200T378 228Q337 228 289 207L288 196Q288 160 284 138Q283 125 262 98Q185 1 112 -24L102 -27L91 -25Q47 -13 17 31Q17 33 49 69L80 106Q111 37 154 37ZM421 434Q411 436 405 436Q370 436 336 407T275 337L267 325L271 313Q288 257 288 234Q289 234 395 276T502 319Q501 320 499 323T493 330T485 345T475 371Q473 378 468 388T449 416T421 434',
    0x1D50F: '251 231Q251 254 206 341T160 468Q160 529 238 605Q313 677 425 685L432 686Q440 686 445 686Q520 686 545 657T571 576Q571 548 568 517T564 480Q564 466 572 460T608 452H623V431L619 430Q618 430 611 428T591 423T566 417L516 404Q497 415 491 419T480 433T475 461Q475 470 477 498T480 546Q480 561 480 569T474 592T459 615T429 631T379 638Q322 638 283 606T239 526Q237 480 280 394Q319 310 320 268V250Q308 235 244 169L203 128H210Q215 128 256 112T357 80T466 64Q526 64 564 88T625 186L629 196Q643 187 644 186Q618 70 604 41Q577 -15 513 -24Q496 -26 476 -26Q418 -26 344 -3T211 45T133 69Q115 69 97 50T65 7T49 -20L45 -16Q41 -11 37 -6T33 0Q33 8 66 69T111 134Q251 156 251 231',
    0x1D510: '687 578Q690 586 719 612T794 665T877 692Q909 692 926 670T953 614T973 570Q983 561 1000 560Q1023 560 1048 569V553L1034 546Q1003 528 972 504T933 466Q905 403 905 264Q905 205 909 172T920 126T940 91Q951 74 959 74T997 87L1027 100V79L956 27Q940 15 922 2T894 -19L885 -26Q883 -26 854 27L825 80V192Q826 314 830 341Q831 345 833 356Q840 398 844 404Q871 444 920 489L917 491Q914 493 911 495T903 501T898 508Q893 516 877 562T852 616Q840 628 818 628Q751 628 702 541L697 532L700 515Q708 467 708 419Q706 348 690 276T662 169T643 126L634 116Q626 105 611 87T581 51L522 -22L514 -15Q470 21 452 21Q431 21 394 -16L384 5L386 9L426 60Q449 87 460 95T486 104Q487 104 491 104T497 103Q514 99 541 81L559 69Q595 96 606 169T618 350Q618 486 598 543T517 601Q484 601 458 570T432 523Q432 516 434 492T436 443Q436 250 369 133L363 122L280 50Q207 -16 192 -27L182 -18Q136 26 106 26Q83 26 62 9T37 -16L27 0L33 10Q41 22 54 39T94 81T143 106Q178 106 225 62L235 53Q283 82 307 117Q352 190 352 359Q352 408 345 451T320 534T267 597T183 621Q159 621 147 617T120 598Q97 574 96 556Q96 528 143 469T191 377T161 311T102 262T70 245Q69 245 59 252T49 260L56 264Q63 268 73 275T94 291T111 312T118 338Q118 355 95 384T50 446T27 506Q31 567 101 626T255 686Q297 686 330 671T382 632T409 595T421 572V571L430 580Q454 610 496 634T588 659H590Q655 659 683 585L687 578',
    0x1D511: '112 334Q112 356 70 410T27 497Q27 553 94 619T229 685Q230 685 236 685T246 686Q303 686 349 654Q373 636 392 607T419 558L426 538L454 576Q474 604 486 618T520 651T569 678T633 686Q668 684 687 673T713 651T730 609Q730 608 732 600T736 588T741 578T747 568T754 561T765 555T779 553Q789 553 817 562Q819 557 819 555V547L790 526Q743 492 730 479T712 447Q697 369 697 281Q697 166 726 108Q741 76 755 68Q759 66 767 66Q789 66 825 93V82Q825 71 822 70Q821 69 763 27T701 -18L692 -25L668 15Q662 25 650 45T635 70L627 85V107Q627 122 626 162T624 285Q624 381 632 398Q638 409 651 425T675 454T696 477T707 489H696Q683 490 679 492T669 507T653 551Q642 588 627 608T584 628Q572 628 560 625T538 616T519 602T502 586T486 568T473 549T463 532T454 517T448 504L445 497Q437 480 437 474Q437 472 439 461T444 421T446 348Q446 205 405 124Q396 105 392 100T368 78Q312 32 278 9T235 -18T214 -22Q191 -22 170 -10T139 12T129 25T160 66T192 105Q193 102 194 98T200 83T213 64T233 49T261 42Q303 42 339 90Q373 134 373 268Q373 397 339 493T235 618Q215 628 191 628Q155 628 126 604T97 548Q97 524 120 493T168 431T192 381Q192 346 164 318T86 260L70 250L54 266L63 272Q112 300 112 334',
    0x1D512: '428 596Q412 596 386 595T350 593Q289 593 270 625Q267 632 267 640Q267 656 280 672T312 705T336 729H343Q351 729 351 728Q342 710 342 703Q342 683 382 676T493 662T604 643Q648 627 677 599T720 535T739 466T744 392Q744 317 732 260T696 166T659 116T621 83Q617 80 615 78Q485 -27 377 -27Q320 -25 272 -9T187 21T122 36H116Q65 36 29 -13L27 -15L12 0Q12 1 32 26T76 78T109 109Q145 123 166 150T187 207Q187 244 134 318T80 412Q80 454 112 498T176 566T213 590Q216 590 224 585L234 580L225 573Q216 566 207 557T188 536T172 511T165 484Q165 448 213 368T261 259Q261 241 252 219T228 179T200 146T176 122L167 112Q170 111 174 111Q188 110 233 91T339 55T453 37Q508 37 556 68T626 152Q655 219 655 328Q655 543 532 582Q484 596 428 596',
    0x1D513: '112 339Q112 354 91 380T49 438T28 497Q28 565 95 628T242 692Q261 692 277 689T307 682T331 670T351 655T367 637T379 619T388 600T395 582T401 565T405 550Q409 554 422 570T453 603T500 641Q573 692 637 692Q656 692 670 686T692 672T705 647T713 618T718 584Q720 568 721 562T728 546T742 534T768 530Q776 531 782 532T791 535T796 536Q799 536 804 521Q801 519 789 513T764 499T738 480Q697 447 680 414Q677 407 677 396Q677 370 713 312T750 210Q750 125 686 57T560 -11Q540 -11 475 13L410 37V31Q410 -9 412 -50T417 -118T420 -150Q419 -150 373 -184T326 -218L305 -208Q305 -207 307 -196T314 -165T322 -116T328 -46T331 43V63L318 66Q270 80 250 80Q233 80 213 70Q183 57 138 -3L128 -16L118 5L125 20Q193 154 282 154Q309 154 331 146V287Q331 444 327 469Q321 522 301 560Q284 590 251 611T184 633Q146 633 119 607T92 550Q92 539 94 534Q100 516 143 460T186 386Q186 366 170 336T119 281Q102 264 70 250L49 260L56 266Q64 271 72 278T90 296T106 317T112 339ZM602 345Q602 357 608 371T622 397T642 421T661 441T678 456L686 462Q663 473 652 486T639 512T634 553Q631 594 624 608T593 631Q587 632 567 632Q539 632 497 600T416 497L410 484V122L467 103Q481 99 502 92T533 82T557 75T578 69T594 66T610 64Q647 64 672 87T697 144Q697 180 650 250T602 345',
    0x1D514: '428 596Q412 596 386 595T350 593Q313 593 291 605T268 638Q268 644 269 648T274 658T284 669T301 689T326 718L336 729H343Q351 729 351 728Q342 710 342 703Q342 683 382 676T493 662T604 643Q744 592 744 398Q744 299 708 213T646 104L603 68L614 55Q670 -5 710 -5Q726 -5 744 1T772 14L781 20Q782 20 782 7V-6L771 -13Q673 -69 665 -69L647 -63Q552 -30 514 8H512Q509 8 500 3T471 -9T428 -23Q405 -27 377 -27Q305 -24 228 6T124 36Q69 36 27 -16Q23 -13 19 -8L11 0L27 20Q93 102 109 109Q145 123 166 150T187 207Q187 244 134 318T80 412Q80 454 112 498T176 566T213 590Q216 590 224 585L234 580L225 573Q216 566 207 557T188 536T172 511T165 484Q165 448 213 368T261 259Q261 241 252 219T228 179T200 146T176 122L167 112Q170 111 174 111Q188 110 233 91T339 55T453 37Q508 37 556 68T626 152Q655 219 655 328Q655 543 532 582Q484 596 428 596',
    0x1D516: '750 276Q750 141 645 57T378 -27Q224 -27 146 67Q66 163 66 307Q66 361 80 412T127 515T217 609T356 676L388 684L420 692L442 681Q545 625 586 608T654 591Q688 591 710 609T737 646L742 665Q742 665 756 654L752 642Q736 594 706 566T621 515L607 510Q580 513 528 542T421 599T335 627Q272 627 214 569Q139 500 139 373Q139 308 159 247T217 136T316 56T455 26Q520 26 566 43T634 90T666 148T676 207Q676 264 649 297T580 338Q574 339 552 339Q516 339 442 325T327 310H324Q303 310 290 312T259 328T232 369Q230 375 230 390Q230 426 259 461Q286 488 338 507Q351 498 351 495L346 492Q342 490 339 488T330 482T320 473T312 462T305 447T303 428Q303 368 366 368Q393 368 487 388T613 409Q656 409 696 385T748 306Q750 294 750 276',
    0x1D517: '289 686Q338 686 439 668T565 649Q606 649 641 672Q656 683 664 690T674 700L675 701Q676 698 676 692V681L641 636Q608 591 602 588Q577 575 528 575Q490 575 438 583L426 585L416 578Q335 520 335 465Q335 437 393 366T452 259Q452 226 406 171Q387 146 351 115L341 106L358 97Q438 58 475 58Q525 58 598 124L608 133Q617 119 617 118Q617 114 606 103Q513 0 448 -27L438 -25Q401 -19 337 14T234 48Q186 48 122 -3L112 -11L106 -3L101 5L110 15Q160 63 209 105L224 117H310Q384 163 384 210Q384 238 329 303T263 398Q262 400 262 404Q262 427 290 476T368 580Q383 596 381 596Q315 622 228 622Q159 622 118 593T76 516Q76 482 96 461T139 431L107 399L75 367Q34 403 34 472Q34 525 64 572T148 652Q207 686 289 686',
    0x1D518: '160 247Q160 291 110 382T59 505Q59 542 106 597T207 690L218 697L229 682Q229 680 216 669T187 644T158 604T144 552Q144 526 165 482T207 387T228 291Q228 277 228 267T224 247T219 232T210 217T199 202T184 184T166 163L118 107Q124 105 132 105T160 98T220 77Q298 47 317 47Q354 47 409 105Q444 142 451 161T458 244V277Q458 339 455 457T447 596L575 686L591 676L583 668Q550 632 541 587T532 449V372V298Q532 198 550 147Q572 79 605 79Q617 79 631 84T656 95L665 100V79L595 27Q578 15 560 2T532 -19L523 -26Q522 -26 490 42L458 111L397 50Q389 42 374 27T355 8T340 -5T325 -16T311 -22T294 -26T274 -27Q232 -25 152 6T35 37Q28 36 19 30T4 18T-8 4T-16 -5Q-25 10 -25 11T-20 18Q-5 34 15 57L54 101Q70 103 86 116Q94 121 113 140T137 166Q160 201 160 247',
    0x1D519: '54 266Q118 307 118 339Q118 360 74 413T27 493Q26 530 59 578T133 651Q187 686 256 686Q299 686 332 673T382 640T409 600T424 567T429 553Q431 553 463 586T542 653T624 686Q663 686 715 652T798 617Q812 617 825 622V606L815 599Q785 576 762 546Q724 495 724 432Q724 397 737 317T750 217Q750 148 711 96Q697 75 639 34T578 -10Q563 -21 555 -23T522 -26Q458 -26 363 29Q253 89 211 89Q192 89 173 83T139 67T114 49T96 34L90 27L80 43L95 59Q121 87 148 110T191 143T213 154Q261 154 300 195Q328 222 340 267T352 406Q352 562 255 611Q222 627 187 627H182Q143 627 115 598Q96 579 96 559Q96 528 144 470T192 381Q192 348 156 314T80 256L70 250L54 266ZM727 548Q701 550 650 583T567 617Q560 617 554 615Q532 610 501 580T445 501L437 485V465Q432 326 378 223L294 150Q321 143 421 87T560 31Q608 31 634 64Q660 102 660 198Q660 226 655 292T650 387Q650 418 658 452L660 462L735 548H727ZM264 150Q265 151 263 151H261Q261 150 264 150ZM280 150H276V149Q280 149 280 150',
    0x1D51A: '32 501Q35 564 103 625T264 686Q326 686 365 652Q402 618 420 564L441 585Q486 630 539 670L559 686L572 672Q637 606 665 559L682 576Q787 686 845 686Q877 686 929 656T992 623Q999 621 1016 621Q1041 621 1054 628L1051 617L1047 606Q1042 602 1037 597Q983 557 969 522T954 434Q954 394 961 336T968 221Q968 201 966 184T963 155T956 131T948 113T936 96T923 82T906 66T887 50L816 -2Q815 -3 810 -6T801 -11T791 -16T777 -20T759 -23T735 -25Q691 -25 619 4T505 37H496L463 21Q420 0 389 -20L378 -27H362Q316 -27 275 -9T202 28T150 46H146Q114 46 59 -5L48 -16L41 -6Q40 -4 38 -2T34 2L33 4Q33 8 77 54Q111 87 142 112L154 122H166Q239 127 288 182Q346 244 346 406Q346 489 326 537T259 610Q227 628 195 628Q155 628 128 606T101 549Q101 517 146 465T192 382Q192 348 156 310T85 254L75 250L64 258L55 266Q56 267 74 276T105 301T118 339Q118 362 75 413T32 501ZM952 547Q927 555 877 586T796 617Q744 617 682 525L676 516L677 498Q678 486 678 425Q678 301 652 206Q649 195 613 151T577 102Q577 100 582 100Q618 100 720 51Q761 32 790 32H794Q843 32 869 83Q884 110 884 189Q884 233 879 294T873 400Q874 433 879 451T889 478T915 507T952 547ZM241 105Q249 98 263 88T317 60T393 42Q478 42 547 109Q607 171 607 358Q607 371 607 380T604 417T595 467T577 517T546 566T500 601L479 580Q449 550 433 526L426 516V503Q426 311 357 200L352 191L296 149Q241 107 241 105',
    0x1D51B: '96 511L78 527L149 603Q207 666 227 686L237 685Q278 680 306 667T351 631T376 588T395 533L400 516L406 527Q439 600 523 653Q538 664 587 688Q589 688 596 672T613 635T629 606Q643 588 665 588Q677 588 693 596L709 603V585L607 526Q583 536 554 564T512 614Q461 571 435 507T405 381V367H581L563 347Q561 344 558 341T553 335T549 330T546 326L545 325H541Q537 325 488 329T411 334H405V325Q405 198 454 123Q497 54 568 54Q594 54 619 64T660 84L676 95V74L565 -16L553 -20Q528 -27 512 -27Q492 -27 475 -21T444 -5T418 19T398 47T382 77T371 105T363 128T358 145L357 151Q354 151 339 136T294 91T232 37Q152 -22 113 -22Q90 -22 69 -9T38 17T28 32Q28 33 58 68L86 101Q94 81 115 64T165 47Q206 47 249 84Q302 128 325 222Q334 263 336 312V334H312Q198 334 163 324H161Q162 326 182 348L198 367H336V398Q333 508 308 550Q295 570 274 585T227 601Q204 601 181 589T145 565T115 533T96 511',
    0x1D51C: '123 345Q123 358 75 415T27 496Q27 533 63 578T121 639Q181 685 256 685Q305 685 332 676T384 635Q419 588 425 542Q468 585 526 628T618 686Q632 667 658 645T704 609T724 594Q740 512 740 385V375V361Q740 128 682 -3Q656 -64 554 -141T361 -218Q319 -218 287 -203T243 -173T230 -150Q230 -148 267 -109L304 -69Q311 -115 341 -142T411 -170Q474 -170 534 -119T624 11Q660 111 660 312Q660 447 633 528L612 549Q578 583 552 596L543 601L528 592Q501 574 483 558Q454 528 436 494L431 484V473Q431 330 376 230Q366 213 359 206T306 159L250 112Q278 105 327 82T399 58Q421 58 440 69T471 92T484 104H485L493 90L480 73Q453 39 429 13Q393 -22 372 -22Q342 -22 260 15T153 53Q97 53 48 -16L38 5Q46 22 74 54T128 108L143 122H155Q226 127 275 168Q352 236 352 399Q352 501 309 564T197 628Q156 628 129 607T102 553Q102 517 147 463T192 383Q192 323 75 250L59 266Q123 314 123 345',
    0x1D51E: '86 363Q86 365 128 391T212 444L255 470Q256 470 263 465T283 453T308 442Q338 431 346 431Q350 431 358 436L414 465L421 459L418 434Q404 339 404 262Q404 147 408 119L425 97Q443 74 444 74L467 86L491 97Q492 97 497 83L436 28Q377 -26 374 -26L331 46L329 91L155 -35Q152 -35 114 10T68 65L67 86Q66 100 66 156Q66 177 66 198T68 236T71 268T74 296T77 320T80 338T83 351T86 360V363ZM314 365Q285 367 255 374T206 386L187 392Q181 392 172 376T154 311T145 197Q145 124 151 118Q156 110 187 78L208 56H214Q221 57 253 76L308 109L329 123V136Q328 143 328 257V365H314',
    0x1D51F: '177 427Q177 364 181 364Q184 364 192 370T223 395T271 433Q317 469 323 469Q325 469 338 462T377 440T432 413L440 409L441 396Q441 394 441 372T442 334Q442 203 425 108L423 97L400 83Q347 49 296 21T222 -19T196 -31Q192 -29 149 12T87 71L89 89Q100 155 100 319Q100 500 94 627Q94 632 94 638T94 648T94 656T93 662V664Q93 668 97 669T106 670H110Q114 653 118 633L127 596Q127 595 132 597Q136 599 195 642L255 685L272 673Q269 670 256 659T233 637T211 609T190 565T179 508Q177 494 177 427ZM295 377L274 385Q273 385 264 381T242 370T223 361Q213 355 188 340L178 333V123L198 103Q259 42 285 42Q299 42 314 55T339 85Q363 132 363 232Q363 310 358 343V349L345 355Q330 363 295 377',
    0x1D520: '72 208Q72 323 84 361Q84 363 167 414Q248 466 250 466Q255 465 260 465Q283 460 307 450T344 430L357 422L306 343L301 342L296 340Q267 362 247 372T216 384T177 390Q154 349 154 238Q154 220 156 158V145L168 132Q179 121 208 93T244 59Q245 58 246 58Q255 58 302 83T350 108L352 104Q355 100 356 96L358 92Q358 91 274 32T187 -29L177 -20Q160 -6 120 36T77 84Q77 87 75 118T72 208',
    0x1D521: '13 542Q13 544 77 576T147 609Q154 609 185 598T283 551T414 464L427 454V440Q427 436 427 412T428 372Q428 315 426 270T418 197T409 152T400 121T394 103L304 35Q285 21 261 2T225 -25L214 -33Q149 7 97 59L76 80L75 91V171Q75 221 76 254T79 299T80 313Q80 315 78 321Q78 323 78 326L77 330Q79 336 132 384T211 447L219 445Q221 445 224 444L228 443Q229 443 228 441T221 432T206 415L191 395Q175 378 162 339Q152 306 152 250Q152 217 159 140V134L171 121Q194 99 235 74T284 48Q296 48 310 75T337 156T349 267Q349 346 336 400L315 420Q246 489 176 525Q127 545 94 545H73L27 527L13 542',
    0x1D522: '353 103Q353 97 358 87L337 71Q293 38 247 6Q191 -30 189 -30Q188 -30 173 -16T130 26T76 85L74 99Q70 139 70 190Q70 228 73 271T79 338T84 365L94 372Q105 378 126 392T166 417L246 467Q283 417 349 369L364 358L355 349Q249 249 169 180L151 166L152 152V139L165 126Q186 105 215 84T260 62Q267 62 309 82L353 103ZM267 323Q246 337 230 350T204 371T189 385T179 394T174 397Q149 381 149 219V211Q151 211 171 223T220 258T268 299L282 313L267 323',
    0x1D523: '128 387Q128 391 112 456T92 546V555L101 568Q133 617 183 670L193 681L204 673Q238 648 262 648Q276 648 292 656L302 661L308 653Q308 652 278 618L249 585H234Q194 587 159 595Q158 595 156 596H153L150 597Q151 593 152 581T154 564T157 547T164 524T176 494Q199 436 203 400V392H207L323 386Q323 384 309 368L296 351H203V176Q201 -9 198 -32Q194 -61 166 -114Q158 -129 153 -138Q114 -214 110 -221Q105 -221 98 -220L87 -219V-216Q88 -215 106 -150T124 -82Q128 -73 129 155V351H30L64 386H96Q128 386 128 387',
    0x1D524: '27 -144L17 -131L82 -75L146 -20L126 6Q99 37 92 48L78 65L77 79Q75 103 75 158Q75 217 78 268T86 343T91 368Q92 370 109 382T157 413T222 452Q240 465 249 469L251 470Q296 443 350 428L365 424L437 465L442 459Q442 456 441 453T437 444T432 424T427 385T423 317T421 215V133L423 119Q423 111 455 -17Q455 -21 380 -108T290 -201Q290 -202 284 -204T269 -207T252 -209Q172 -209 75 -135L62 -126Q60 -126 44 -135L27 -144ZM349 351Q267 359 178 389Q177 387 173 376T166 358T161 337T157 303T155 259Q155 195 159 135L160 124L170 112Q180 101 204 77T230 53Q231 53 286 88L340 123V141Q340 235 348 329Q348 334 348 339T348 348L349 351ZM364 -59Q364 -37 358 -1T347 64T341 93Q336 93 252 28T149 -57Q226 -134 293 -134Q325 -134 344 -118T364 -69V-59',
    0x1D525: '88 427Q88 486 87 545T84 634T81 667Q81 673 82 673T89 676H94L117 600L200 661Q208 667 218 674T233 684L238 688L254 678Q222 646 216 637Q164 572 164 483V442V371Q187 390 244 431T312 478Q344 446 411 423L428 417L429 396Q434 297 434 218Q430 8 406 -34Q346 -111 262 -195L251 -205L249 -203Q248 -203 244 -199T236 -193Q226 -183 227 -182Q228 -182 244 -169T278 -140T301 -121Q330 -89 340 -22Q355 60 355 171Q355 200 353 244T348 321T344 354Q333 363 307 376T260 390Q241 390 179 350L164 341V121Q180 96 201 66L213 50Q210 46 172 11T131 -24Q130 -24 123 -11T102 25T77 65V78Q80 104 84 227T88 427',
    0x1D526: '72 617Q72 618 102 645T133 673H134Q134 668 175 627L187 615L130 555L116 568Q72 615 72 617ZM27 369Q21 376 14 382L26 392Q83 440 119 474Q158 435 169 427L179 420L176 395Q166 302 166 227Q166 171 174 139Q178 119 186 103T202 81L208 75Q255 100 261 100Q262 100 264 92T267 83Q267 80 208 30T145 -20Q143 -20 118 17L91 55Q98 117 98 193Q98 339 88 369Q71 390 68 390Q63 390 39 376L27 369',
    0x1D527: '101 644L132 672L145 657Q155 642 169 628L182 614L169 600Q148 579 140 570L125 555L112 568Q109 570 91 592T72 615Q72 617 101 644ZM19 -208Q-9 -185 -9 -183Q57 -134 80 -106Q103 -81 110 -19T118 179Q118 294 113 329T86 383Q78 389 76 389Q73 389 57 379L39 367Q26 380 26 381L33 387Q40 393 53 405T79 428L130 474Q147 457 186 428L195 422L196 376Q196 130 192 58T174 -35Q172 -39 104 -114T19 -208',
    0x1D528: '93 348Q93 379 90 503T87 658V670Q87 671 94 671L100 672L112 636Q124 603 124 600L144 616Q196 659 234 682L246 689Q252 686 256 685T268 680L254 667Q225 641 211 626T184 579T168 505V497L178 504Q203 523 237 544L300 584L310 573Q359 522 359 481Q359 476 359 472T358 464T355 456T351 448T346 440T340 433T332 424T323 414T312 402T299 389L255 343L300 342H346L320 309H177V128L193 113Q214 92 244 68L254 60L303 84Q351 108 352 108L362 96Q355 85 277 30T194 -25L170 -4Q146 18 120 41T89 68Q94 85 94 168Q94 184 94 218T93 268V309H58L24 310L51 342H93V348ZM172 342Q217 342 252 374T287 445Q287 472 255 504Q242 517 241 517Q202 498 187 468T168 365V342H172',
    0x1D529: '221 76L267 99Q268 98 269 96T271 88T270 82L262 76Q255 69 241 57T214 34L148 -20L98 56L99 89Q107 247 107 373Q107 494 101 621Q101 653 99 659V665L106 668L113 672L118 655Q122 642 131 617L138 597Q140 597 157 611L240 670L264 685Q265 685 270 680T276 674T269 667T247 647T219 617Q197 587 186 551T173 493T171 438Q171 376 175 282T179 147V117Q184 109 201 93T221 76',
    0x1D52A: '20 367L8 379Q9 380 63 425T118 471Q130 460 143 446L168 421V398L169 376L295 475Q362 433 415 418V399Q415 380 416 380T437 394T484 428T529 462L544 474L556 467Q590 449 614 438T646 424L653 421L665 417L664 412Q664 411 664 407T664 397T663 384Q660 342 660 335Q658 303 658 245Q658 186 660 152L661 126L669 115Q680 96 697 79L707 83Q716 87 723 90T735 96T741 100T746 102L747 103V102L750 95Q753 88 753 87L631 -18Q630 -17 622 -3T589 43L576 60L579 72Q592 146 592 218Q592 265 584 321Q581 345 578 350T560 363Q535 376 496 386L481 390Q475 387 425 358L415 351V238V157Q415 142 415 135T417 120T421 110T430 98T441 81L465 47Q462 44 458 41T443 28T420 8L380 -26L333 47L336 62Q339 77 342 109T345 184Q345 223 341 285T333 348Q322 364 258 382L240 388L169 347L168 240Q168 118 171 110L174 106Q178 101 183 93T195 78L217 48Q217 47 196 30T154 -5T133 -21L130 -16Q127 -10 122 0T111 19Q89 56 89 60Q95 76 95 153Q95 239 88 337V365L62 391L20 367',
    0x1D52B: '31 368Q20 379 20 380T72 427L121 470L129 463Q137 455 155 441T176 425V367L320 475L329 469Q351 454 385 437T434 417L432 402Q429 362 429 231V128L439 111Q442 107 445 102T449 95T453 90T456 86T459 84T463 82T467 82Q468 82 489 93T511 105T514 95V88L395 -18L340 65L341 77Q347 111 348 178Q348 343 343 353V358L332 363Q298 377 264 382Q260 382 254 382L249 383Q247 383 211 362L176 341V229V147Q176 134 176 127T177 115T179 106T183 99T189 91T197 81Q201 76 206 69T214 57L217 53Q217 52 175 15T132 -22Q132 -20 114 5T88 41Q84 45 84 50Q94 116 94 203Q94 227 93 263T92 324L90 364L67 388L50 378Q32 368 31 368',
    0x1D52C: '67 121Q70 265 84 359V364L97 371Q137 394 177 421T238 464T260 480L278 468Q295 457 327 442T394 418L406 414Q412 388 412 300Q412 263 410 228T405 170T399 130T393 103T391 92L381 86Q274 19 165 -28L161 -24Q157 -21 151 -15T136 -2T118 14T101 30T86 46T74 60L67 68V121ZM335 278Q335 286 335 303T334 335L333 349V356H326Q288 360 208 388L183 397Q178 393 172 383T154 333T142 239Q142 192 151 134Q152 124 155 120T175 99Q197 77 219 64T251 47T267 44T281 52T302 80T320 124Q335 182 335 278',
    0x1D52D: '23 25Q15 33 12 38L35 59Q82 102 87 105V351L68 378Q42 414 42 422Q42 437 67 472T114 530L125 541Q126 541 132 537T138 532Q121 507 121 488Q121 453 160 413L167 405L166 390L165 372Q169 372 233 421T305 478Q307 479 315 472Q395 421 423 415Q424 415 424 415T425 414L426 410Q426 407 426 401T426 388Q430 335 430 272Q430 139 408 80Q407 78 340 22L273 -33Q200 23 165 23Q164 23 164 -32Q165 -89 168 -155V-170L93 -212L78 -203Q85 -48 87 16V47H79Q53 44 23 25ZM319 41Q346 94 349 212Q349 278 343 343V351L330 357Q318 363 270 381L255 387L245 383Q212 370 178 345L166 336V287Q165 260 165 166V94H175Q219 90 299 51L319 41ZM132 57L129 60Q130 58 132 57ZM158 29Q159 29 155 34T145 45T138 50Q155 29 158 29',
    0x1D52E: '399 19Q399 -123 407 -174V-179L332 -219L322 -210L312 -202L314 -185Q320 -83 323 54V95L311 85Q255 40 162 -19Q146 -29 145 -27Q140 -22 103 20L63 65V73Q61 83 61 115Q61 122 61 135T60 154Q60 263 79 353L83 368L94 375Q123 391 147 405T186 429T212 446T229 458T238 466T243 470T247 471L260 464Q274 457 295 448T330 434L341 432L410 479L412 478Q414 476 416 475T419 473L417 461Q399 358 399 19ZM316 367Q287 370 252 377T196 391L176 397H175Q173 397 166 382T149 314T139 187V158Q139 138 141 132T155 114Q158 111 160 109Q210 58 211 58L218 62Q226 67 240 75T266 91L319 124V196Q319 334 325 361V367H316',
    0x1D52F: '357 398Q364 398 375 403L386 408Q386 404 387 400V393L369 377Q361 370 350 360T336 347T327 340T316 336T303 335Q270 335 234 371L224 379Q220 375 214 370T198 355L182 340V243Q182 164 183 152T189 129Q195 117 211 100T239 72T254 60T298 81Q342 101 343 101Q344 100 345 92T346 82L200 -21Q174 -5 117 55L97 75L99 92Q106 147 106 196Q106 216 104 282T97 355Q95 359 95 361Q95 364 77 378L65 387L48 380Q30 372 29 372Q28 371 23 375T17 380Q17 384 50 415T107 467L115 474L128 461Q171 421 182 414V382L235 425Q247 435 261 446T283 462L290 468L295 460Q335 398 357 398',
    0x1D530: '272 33Q284 33 290 70T296 138Q296 181 290 198T262 230Q257 232 246 232Q231 232 218 227T156 194L108 167L101 176Q90 192 75 219L69 230V284Q69 346 70 357V377L146 427Q181 450 202 464T223 477L225 476Q227 475 229 474Q231 472 242 466T270 450T296 431Q309 418 326 418Q342 418 361 429T392 450T403 459Q406 445 406 442Q406 440 384 421T338 382L316 363Q315 362 310 362Q292 362 251 371T173 396Q156 403 155 403Q143 386 143 342Q143 305 150 278T168 244Q171 242 181 242L190 243L315 300Q330 295 357 274Q358 273 362 270T366 266T369 261T372 253T373 239T374 217T374 185Q374 157 372 133T368 98T363 71T358 50L336 40Q288 20 255 2T211 -22T198 -29L190 -22Q181 -14 168 -3T138 19T104 38T70 46Q50 46 32 37T3 20T-9 11L-18 23L-1 41Q46 89 62 99T112 110Q141 110 157 103T225 62Q268 33 272 33',
    0x1D531: '228 640L242 627L227 613Q213 599 211 593T203 553Q197 500 196 435V389H301L272 353H195V274Q195 178 196 159L197 123Q249 71 273 68H280L312 83Q344 99 345 99Q348 99 348 78Q348 76 314 52T246 4L212 -20Q211 -20 164 19T112 62Q112 122 113 196Q113 228 113 262T114 318T114 341V353H27L62 389H116L117 431V558L228 640',
    0x1D532: '171 315Q171 252 165 199T153 124L147 103Q147 102 196 80L244 57L335 107V116Q339 161 339 268Q339 315 338 351T337 412V424L420 468Q424 465 427 461L424 435Q412 331 412 226Q412 170 415 145T434 96Q442 84 452 83Q461 83 492 96L506 102Q507 102 510 96T513 88L493 71Q445 32 401 -9Q392 -18 388 -17L384 -16Q358 39 355 44Q336 77 333 77Q261 45 203 -9Q186 -23 181 -23Q177 -23 162 -11T121 18T69 44L57 48L61 54Q65 60 71 73T82 102Q100 156 100 262Q100 305 93 335T80 373T62 396L23 376Q18 377 9 386L119 474Q149 437 171 421V339V315',
    0x1D533: '55 418Q55 443 100 503Q121 530 123 530Q125 528 127 528T131 528T134 528T135 524T131 516Q123 499 123 486Q123 457 159 416L169 405L170 388L171 372Q171 371 244 424L317 477L334 466Q394 428 432 416L433 402Q433 400 433 377T434 336V305Q434 195 420 131Q413 94 406 87Q403 84 298 29L192 -28L172 -8Q139 25 106 52L92 64V70Q95 97 95 198Q95 293 94 318L92 355L84 367Q55 406 55 418ZM174 306Q174 297 173 255T171 184Q171 153 175 136T198 100Q207 94 224 80T255 57T282 49Q304 49 328 80Q359 129 359 243Q359 284 352 345Q351 358 348 360Q333 373 304 384T265 396L251 390Q215 372 186 351L175 344V337Q175 319 174 306',
    0x1D534: '45 425Q45 439 82 485T126 532Q127 532 128 532T131 531T137 529L132 519Q121 499 121 483Q121 473 131 454T156 419L167 408L169 376L185 388Q237 425 291 473L301 466Q337 443 394 416L419 405L418 387V369Q419 369 487 418T560 471L581 459Q597 450 621 437T664 415T684 406Q688 406 688 323Q688 254 679 193T662 103T648 71Q647 70 554 20L464 -28L443 -15Q416 1 386 17T343 40T331 49Q331 52 333 73T337 133T339 216Q339 309 334 339Q333 341 316 353T277 377T246 389Q235 389 201 369T166 346Q166 345 164 247Q164 232 164 210T163 179Q163 139 170 116T205 57L212 48L136 -27Q115 16 87 44L78 53L80 67Q88 124 88 211Q88 282 87 315T83 356T74 371Q45 410 45 425ZM591 352Q580 359 565 365T540 374T517 381T504 385L418 342L417 318V220Q417 212 417 194T417 170T418 152T420 134T424 120T431 106T440 95T454 83Q508 44 544 44Q566 44 583 77Q603 120 605 235Q605 289 600 322Q598 343 597 345T591 352',
    0x1D535: '14 377Q63 428 117 472Q130 462 144 449L193 408V392V376L247 420Q300 464 301 464L310 451Q331 417 363 390L333 365Q303 340 301 340Q293 343 277 364T250 386Q241 386 215 361L195 341Q194 333 193 327T191 318T190 304T188 269Q188 220 192 148Q193 122 195 118T210 101Q255 60 271 60Q276 60 278 61L318 82Q327 86 336 91T351 98L356 101Q359 82 356 79Q354 77 283 27T210 -24L192 -7Q160 23 137 40L126 49L116 40Q66 -13 66 -54Q66 -117 167 -140L179 -142V-147L180 -152L123 -188L112 -185Q58 -170 31 -145Q10 -122 10 -96Q10 -80 22 -53Q44 -10 95 49Q97 51 100 54T104 59T107 64T109 71T111 81T112 97T113 119T114 149T114 188Q114 284 108 347V354L96 365Q76 385 67 385Q62 385 45 377L27 368L14 377',
    0x1D536: '74 58Q89 124 89 241Q89 265 89 278T89 305T88 324T87 336T84 346T81 352T77 359T71 368Q57 388 52 398L45 409Q62 454 98 507L113 528Q128 528 128 524Q128 523 125 518T120 503T117 483V471Q123 456 130 445Q135 438 140 432T148 422T153 415T157 409T160 405T162 402T163 399T163 395V384V365Q165 365 237 419L311 473Q383 430 420 416Q426 414 426 411V404Q426 398 426 388T427 367Q431 233 431 201Q431 -3 391 -52Q387 -58 305 -138T221 -218L218 -217Q216 -216 211 -214T202 -210L184 -202L199 -194Q259 -162 288 -127T334 -19T350 184Q350 257 342 328L340 356Q282 390 262 390Q248 390 178 346L163 336V111L216 45L131 -26L124 -15Q114 3 85 44L74 58',
    0x1D537: '47 335L36 347L52 362Q112 421 167 461L181 471L192 465Q236 444 260 417T284 369Q284 355 276 343T233 291Q195 246 195 245T205 242T241 228T300 198L302 187Q314 138 314 74Q314 -24 291 -95Q290 -100 222 -157L154 -214H145Q102 -214 53 -189T-7 -117Q41 -21 183 122L207 147Q205 149 186 157T140 172T100 180H97V192L140 231Q192 280 199 293Q205 303 205 315Q205 339 185 363T137 388Q119 388 63 347L47 335ZM179 -153Q212 -153 226 -113T240 14Q240 67 233 98T223 132T211 143L222 130Q216 125 206 116T170 82T124 33T88 -20T72 -70Q72 -99 105 -126T179 -153',
    0x1D538: '130 -1H63Q34 -1 26 2T17 17Q17 24 22 29T35 35Q49 35 64 44T88 66Q101 93 210 383Q331 693 335 697T346 701T357 697Q358 696 493 399Q621 104 633 83Q656 35 686 35Q693 35 698 30T703 17Q703 5 693 2T643 -1H541Q388 -1 386 1Q378 6 378 16Q378 24 383 29T397 35Q412 35 434 45T456 65Q456 93 428 170L419 197H197L195 179Q184 134 184 97Q184 82 186 71T190 55T198 45T205 39T214 36L219 35Q241 31 241 17Q241 5 233 2T196 -1H130ZM493 68Q493 51 481 35H619Q604 56 515 256Q486 321 468 361L348 637Q347 637 330 592T313 543Q313 538 358 436T448 219T493 68ZM404 235Q404 239 355 355T295 488L275 430Q241 348 208 232H306Q404 232 404 235ZM155 48Q151 55 148 88V117L135 86Q118 47 117 46L110 37L135 35H159Q157 41 155 48',
    0x1D539: '11 665Q11 672 22 683H213Q407 681 431 677Q582 649 582 515Q582 488 573 468Q554 413 484 372L474 366H475Q620 317 620 178Q620 115 568 69T420 6Q393 1 207 -1H22Q11 10 11 18Q11 35 51 35Q79 37 88 39T102 52Q107 70 107 341T102 630Q97 640 88 643T51 648H46Q11 648 11 665ZM142 341Q142 129 141 88T134 37Q133 36 133 35H240L233 48L229 61V623L233 635L240 648H133L138 639Q142 621 142 341ZM284 370Q365 378 391 411T417 508Q417 551 406 581T378 624T347 643T320 648Q298 648 278 635Q267 628 266 611T264 492V370H284ZM546 515Q546 551 531 577T494 617T454 635T422 641L411 643L420 630Q439 604 445 579T452 510V504Q452 481 451 467T441 430T415 383Q420 383 439 391T483 413T527 455T546 515ZM585 185Q585 221 570 249T534 294T490 320T453 334T436 337L435 336L440 330Q445 325 452 315T467 288T479 246T484 188Q484 145 474 110T454 62T442 48Q442 47 444 47Q450 47 470 54T517 75T564 119T585 185ZM449 184Q449 316 358 332Q355 332 335 333T302 335H264V199Q266 68 270 57Q275 50 289 43Q300 37 324 37Q449 37 449 184',
    0x1D53B: '16 666Q16 675 28 683H193Q329 683 364 682T430 672Q534 650 600 585T686 423Q688 406 688 352Q688 274 673 226Q641 130 565 72T381 1Q368 -1 195 -1H28Q16 5 16 16Q16 35 53 35Q68 36 75 37T87 42T95 52Q98 61 98 341T95 630Q91 640 83 643T53 648Q16 648 16 666ZM237 646Q237 648 184 648H128Q128 647 133 632Q136 620 136 341Q136 64 133 50L128 35H237L230 48L226 61V343Q228 620 231 633Q232 636 237 646ZM264 61Q278 40 310 35Q363 35 401 55T461 112T496 193T513 295Q515 333 515 349Q515 411 504 459Q481 598 373 641Q351 648 321 648Q304 648 292 643T277 635T264 621V61ZM461 628Q462 627 471 616T489 594T509 559T529 509T544 441T550 352Q550 165 479 75L468 59Q474 61 484 65T522 87T573 128T618 195T650 290Q654 322 654 354Q654 418 638 464T581 552Q559 576 529 595T480 621L461 628',
    0x1D53C: '12 666Q12 675 24 683H582Q590 680 593 672V588Q593 514 591 502T575 490Q567 490 563 495T555 517Q552 556 517 590Q486 623 445 634T340 648H282Q266 636 264 620T260 492V370H277Q329 375 358 391T404 439Q420 480 420 506Q420 529 436 529Q445 529 451 521Q455 517 455 361Q455 333 455 298T456 253Q456 217 453 207T437 197Q420 196 420 217Q420 240 406 270Q377 328 284 335H260V201Q261 174 261 134Q262 73 264 61T278 38Q281 36 282 35H331Q400 35 449 50Q571 93 602 179Q605 203 622 203Q629 203 634 197T640 183Q638 181 624 95T604 3L600 -1H24Q12 5 12 16Q12 35 51 35Q92 38 97 52Q102 60 102 341T97 632Q91 645 51 648Q12 648 12 666ZM137 341Q137 131 136 89T130 37Q129 36 129 35H235Q233 41 231 48L226 61V623L231 635L235 648H129Q132 641 133 638T135 603T137 517T137 341ZM557 603V648H504Q504 646 515 639Q527 634 542 619L557 603ZM420 317V397L406 383Q394 370 380 363L366 355Q373 350 382 346Q400 333 409 328L420 317ZM582 61L586 88Q585 88 582 83Q557 61 526 46L511 37L542 35H577Q577 36 578 39T580 49T582 61',
    0x1D53D: '584 499Q569 490 566 490Q558 490 552 497T546 515Q546 535 533 559Q526 574 506 593T469 621Q415 648 326 648Q293 648 287 647T275 641Q264 630 263 617Q262 609 260 492V370L275 372Q323 376 350 392T393 441Q409 473 409 506Q409 529 427 529Q437 529 442 519Q444 511 444 362Q444 212 442 206Q436 197 426 197Q409 197 409 217Q409 265 375 299Q346 328 280 335H260V206Q260 70 262 63Q265 46 276 41T326 35Q362 35 366 28Q377 17 366 3L360 -1H24Q12 5 12 16Q12 35 51 35Q92 38 97 52Q102 60 102 341T97 632Q91 645 51 648Q12 648 12 666Q12 675 24 683H573Q576 678 584 670V499ZM137 341Q137 131 136 89T130 37Q129 36 129 35H182Q233 35 233 39Q226 54 225 92T224 346L226 623L231 635L235 648H129Q132 641 133 638T135 603T137 517T137 341ZM549 603V648H495L506 641Q531 621 533 619L549 603ZM409 317V395L400 386Q390 376 375 366L357 355L373 346Q394 331 397 328L409 317',
    0x1D53E: '737 285Q749 277 749 268Q749 260 744 255T730 250Q695 250 677 217Q666 195 666 119Q666 52 664 50Q656 36 555 3Q483 -16 415 -19Q364 -19 348 -17Q226 -3 146 70T44 261Q39 283 39 341T44 421Q66 538 143 611T341 699Q344 699 364 700T395 701Q449 698 503 677T585 655Q603 655 611 662T620 678T625 694T639 702Q650 702 657 690V481L653 474Q640 467 628 472Q624 476 618 496T595 541Q562 587 507 625T390 663H381Q337 663 299 625Q213 547 213 337Q213 75 341 23Q357 19 397 19Q440 19 462 22T492 30T513 45V119Q513 184 506 203Q491 237 435 250Q421 250 415 257Q404 267 415 281L421 285H737ZM250 43Q250 45 243 55T225 87T203 139T185 224T177 343V361Q184 533 250 625Q264 643 261 643Q238 635 214 620T161 579T110 510T79 414Q74 384 74 341T79 268Q106 117 230 52L250 43ZM621 565V625Q617 623 613 623Q603 619 590 619H575L588 605Q608 583 610 579L621 565ZM655 250H517L524 241Q548 213 548 149V114V39Q549 39 562 44T592 55T615 63L630 70V134Q632 190 634 204T648 237Q655 245 655 250',
    0x1D540: '20 666Q20 676 31 683H358Q369 676 369 666Q369 648 331 648Q288 645 282 632Q278 626 278 341Q278 57 282 50Q286 42 295 40T331 35Q369 35 369 16Q369 6 358 -1H31Q20 4 20 16Q20 35 58 35Q84 37 93 39T107 50Q113 60 113 341Q113 623 107 632Q101 645 58 648Q20 648 20 666ZM249 35Q246 40 246 41T244 54T242 83T242 139V341Q242 632 244 639L249 648H140Q146 634 147 596T149 341Q149 124 148 86T140 35H249',
    0x1D541: '79 103Q108 103 129 83T151 38Q151 9 130 -15Q116 -34 130 -37Q133 -39 157 -39Q208 -39 219 -8L226 3V305Q226 612 224 621Q220 636 211 641T166 647Q137 647 128 654Q119 665 128 679L135 683H466Q478 677 478 666Q478 647 439 647Q399 644 393 632Q388 620 388 347Q386 69 384 59Q364 -6 316 -39T184 -77H172Q102 -77 56 -48T6 30Q6 62 26 82T79 103ZM353 354Q353 556 354 596T361 645Q362 646 362 647H253Q257 639 258 628T261 547T262 312V-4L255 -17Q248 -29 250 -29Q253 -29 258 -28T277 -20T302 -5T327 22T348 65Q350 74 353 354ZM115 36Q115 47 105 57T79 67Q73 67 67 66T52 56T44 34Q44 9 62 -8Q66 -11 71 -15T81 -22T86 -24L90 -13Q100 3 102 5Q115 22 115 36',
    0x1D542: '22 666Q22 676 33 683H351L358 679Q368 665 358 655Q351 648 324 648Q288 645 280 637Q275 631 274 605T273 477L275 343L382 446Q473 530 492 553T512 599Q512 617 502 631T475 648Q455 651 455 666Q455 677 465 680T510 683H593H720Q732 676 732 666Q732 659 727 654T713 648Q670 648 589 581Q567 562 490 489T413 415Q413 413 554 245T711 61Q737 35 751 35Q758 35 763 29T768 15Q768 6 758 -1H624Q491 -1 486 3Q480 10 480 17Q480 25 487 30T506 35Q518 36 520 38T520 48L400 195L302 310L286 297L273 283V170Q275 65 277 57Q280 41 300 38Q302 37 324 35Q349 35 358 28Q367 17 358 3L351 -1H33Q22 4 22 16Q22 35 60 35Q101 38 106 52Q111 60 111 341T106 632Q100 645 60 648Q22 648 22 666ZM240 341V553Q240 635 246 648H138Q141 641 142 638T144 603T146 517T146 341Q146 131 145 89T139 37Q138 36 138 35H246Q240 47 240 129V341ZM595 632L615 648H535L542 637Q542 636 544 625T549 610V595L562 606Q565 608 577 618T595 632ZM524 226L386 388Q386 389 378 382T358 361Q330 338 330 333Q330 332 330 332L331 330L533 90Q558 55 558 41V35H684L671 50Q667 54 524 226',
    0x1D543: '12 666Q12 675 24 683H333L340 679Q350 665 340 655Q333 648 309 648Q287 646 279 643T266 630Q264 623 264 346Q264 68 266 57Q274 40 284 35H340Q413 37 460 55Q514 78 553 117T602 197Q605 221 622 221Q629 221 634 215T640 201Q638 194 625 105T611 12Q611 6 600 -1H24Q12 5 12 16Q12 35 51 35Q92 38 97 52Q102 60 102 341T97 632Q91 645 51 648Q12 648 12 666ZM137 341Q137 131 136 89T130 37Q129 36 129 35H237Q235 41 233 48L229 61L226 339Q226 621 229 628Q230 630 231 636T233 643V648H129Q132 641 133 638T135 603T137 517T137 341ZM580 48Q580 59 583 74T586 97Q586 98 585 97T579 92T571 86Q549 64 513 43L500 35H577L580 48',
    0x1D544: '18 666Q18 677 27 680T73 683H146Q261 683 266 679L465 215Q469 215 566 443Q663 676 668 681Q673 683 790 683H908L915 679Q924 664 915 655Q912 648 897 648Q851 639 835 606L833 346Q833 86 835 79Q838 69 849 58T873 41Q877 40 887 38T901 35Q926 35 926 16Q926 6 915 -1H604L597 3Q588 19 597 28Q600 35 615 35Q660 42 673 68L679 79V339Q679 409 679 443T679 520T679 580T677 597Q646 521 584 375T473 117T424 3Q416 -1 410 -1T401 1Q399 3 273 301L148 599L146 343Q146 86 148 79Q152 69 163 58T186 41Q190 40 200 38T215 35Q226 35 235 28Q244 17 235 3L228 -1H28Q17 4 17 17Q17 35 39 35Q84 42 97 68L104 79V639L88 641Q72 644 53 648Q34 648 26 651T18 666ZM457 166Q451 169 449 171T435 198T404 268T344 412L244 648H157L166 637Q169 633 293 346L413 66Q424 88 435 117L457 166ZM817 646Q817 648 766 648H715V72L708 57Q701 45 697 41L695 37Q695 35 757 35H819L813 46Q802 61 800 76Q797 105 797 346L799 612L804 626Q812 638 815 641L817 646ZM124 42Q119 42 119 38Q119 35 128 35Q132 35 132 36Q125 42 124 42',
    0x1D546: '131 601Q180 652 249 676T387 701Q485 701 562 661Q628 629 671 575T731 448Q742 410 742 341T731 234Q707 140 646 81Q549 -19 389 -19Q228 -19 131 81Q57 155 37 274Q34 292 34 341Q34 392 37 410Q58 528 131 601ZM568 341Q568 613 437 659Q406 664 395 665Q329 665 286 625Q232 571 213 439Q210 408 210 341Q210 275 213 245Q232 111 286 57Q309 37 342 23Q357 19 389 19Q420 19 437 23Q469 38 491 57Q568 132 568 341ZM174 341Q174 403 177 441T197 535T249 639Q246 639 224 627T193 608Q189 606 183 601T169 589T155 577Q69 488 69 344Q69 133 231 52Q244 45 246 45Q248 45 246 48Q231 69 222 85T200 141T177 239Q174 269 174 341ZM708 341Q708 415 684 475T635 563T582 610Q578 612 565 619T546 630Q533 637 531 637Q530 637 530 636V635L531 634Q562 591 577 543Q602 471 602 341V316Q602 264 599 230T580 144T531 48L530 47V46Q530 45 531 45Q533 45 547 52T583 75T622 105Q708 195 708 341',
    0x1D54A: '54 238Q72 238 72 212Q72 174 106 121Q113 110 132 90T166 59Q221 23 264 23Q315 23 348 41Q368 50 384 79Q393 102 393 129Q393 181 356 219T221 299Q120 343 74 390T28 501Q28 561 55 610Q98 682 212 699Q214 699 231 700T261 701Q309 698 340 687T408 675Q431 678 445 690T465 702Q474 702 481 690V497L477 490Q464 481 450 490Q446 500 446 501Q446 546 386 606T260 666Q215 666 182 639T148 565Q148 528 186 496T319 428Q352 414 370 405T418 379T468 338T506 284Q528 239 528 191Q528 102 456 46T266 -10Q211 -10 176 2T110 15Q86 9 73 -1T53 -12Q44 -12 37 -1V112V182Q37 214 40 226T54 238ZM446 619Q446 648 444 648Q439 646 435 644Q425 644 415 639H404L417 624Q435 606 439 601L446 592V619ZM124 619L128 635Q126 635 108 617Q64 576 64 502Q64 489 65 479T76 449T102 414T150 376T228 335Q335 291 381 245T427 128Q427 94 419 75L415 61Q421 61 448 88Q490 127 490 190Q490 233 475 264Q456 299 430 321Q402 349 369 367T287 404T204 441Q138 481 119 526Q113 544 113 565Q113 596 124 619ZM75 43Q76 43 90 46T110 50H119L106 64L74 101Q72 101 72 72T75 43',
    0x1D54B: '33 672Q36 680 44 683H624Q632 680 635 672V490L631 483Q621 479 617 479Q611 479 606 485T600 499Q600 525 584 552Q577 567 558 588T524 617Q479 642 426 646L415 648V355Q415 62 422 52Q425 42 434 40T473 35Q500 35 509 28Q518 17 509 3L502 -1H166L160 3Q149 17 160 28Q167 35 195 35Q224 37 234 39T249 52Q253 66 253 355V648L242 646Q192 642 144 617Q129 609 110 588T84 552Q69 527 69 499Q69 490 64 484T50 478Q39 478 33 490V672ZM113 639L126 648H69V597L84 612Q93 623 113 639ZM389 35Q382 46 381 86Q380 134 380 350V648H289V350Q289 199 288 131T286 53T280 35H389ZM600 597V648H542L555 639Q575 623 584 612L600 597',
    0x1D54C: '16 666Q16 677 28 683H341L348 679Q359 665 348 654Q342 648 315 648Q270 644 266 632Q262 627 262 598T261 399Q261 372 261 325T260 260Q260 149 274 99T339 30Q355 25 393 25Q430 25 457 33T494 49T519 72Q562 115 575 205Q576 219 576 379Q576 538 575 550Q568 597 550 622T506 648Q498 648 493 654T487 667T499 683H697Q709 675 709 667T704 654T690 648Q653 648 633 597Q624 573 622 546T619 377Q617 193 613 174Q596 95 544 41Q477 -19 355 -19H344Q275 -16 226 5T153 57T120 110T106 154Q101 172 99 399Q99 618 95 632Q88 644 53 648Q16 648 16 666ZM228 639L233 648H128Q128 647 133 632Q135 621 135 412Q135 197 137 185Q148 115 181 79Q209 51 235 41Q242 36 258 31T277 25Q276 27 268 38T254 59T241 92T228 145Q226 161 226 399Q226 632 228 639ZM604 621Q606 626 619 648H577L586 634Q587 632 591 625T595 614L597 608L604 621',
    0x1D54D: '316 683Q327 676 327 666Q327 648 302 648Q272 642 258 628Q249 621 249 608Q252 589 263 556T289 485T322 406T357 325T388 256T411 205L420 185Q423 185 473 317Q547 497 547 590Q547 621 541 632T516 648Q501 648 498 654Q488 664 498 679L504 683H607H660Q695 683 707 680T719 667Q719 660 714 654T700 648Q678 648 658 628L642 614L513 301Q484 231 449 148T397 25T380 -15Q373 -20 368 -20Q361 -20 358 -15Q354 -13 287 135T149 438T67 610Q45 648 18 648Q11 648 6 653T0 666Q0 677 9 680T59 683H164H316ZM216 614Q216 620 216 622T216 628T216 633T217 635T218 638T219 640T221 644T224 648H84L96 632Q118 592 236 330L367 43L387 88L404 132L380 185Q250 468 222 568Q216 590 216 614ZM576 645Q584 628 584 597L587 568L598 597Q609 624 618 637L624 648H600Q576 648 576 645',
    0x1D54E: '785 664Q785 670 795 683H982Q994 675 994 665Q994 650 975 648Q953 643 939 619Q931 593 823 292T710 -15Q706 -19 699 -19T688 -15Q682 -6 639 107T555 328T513 437Q513 438 500 409T462 325T413 212Q315 -14 310 -17Q308 -19 302 -19T288 -15L57 619Q45 643 24 648Q5 650 5 665Q5 677 17 683H146H200Q256 683 270 681T285 666Q285 659 280 654T268 648Q253 648 239 634Q230 630 230 619Q230 598 264 481L362 192Q363 193 428 341T493 492Q493 496 473 546T446 608Q426 648 399 648Q392 648 387 653T382 667Q382 678 393 683H679Q690 670 690 665Q690 662 685 655T673 648Q653 648 633 632L622 625V610Q626 576 657 479T719 300T751 218Q754 218 779 294Q847 492 847 581Q847 648 802 648Q796 648 791 652T785 664ZM194 623Q194 630 199 648H82L90 632Q99 616 199 332L302 50Q303 50 322 94T342 141Q342 142 305 245T231 467T194 623ZM585 620Q585 634 593 648H530Q466 648 466 645Q479 632 595 323L699 54Q701 56 718 103T735 154L702 245Q585 562 585 620ZM884 572L890 587Q896 602 903 620T915 645Q915 648 893 648H868L875 634Q883 598 883 576Q883 572 884 572',
    0x1D54F: '22 666Q22 677 31 680T80 683H184H335Q346 675 346 667Q346 660 341 655Q335 648 315 648Q280 644 273 637Q273 630 300 583T356 492T386 448Q430 504 450 535T474 577T478 601Q478 620 469 634T444 648Q428 648 428 666Q428 678 436 680T488 683H559H630Q673 683 681 681T690 666Q690 648 673 648Q652 648 619 637Q571 615 517 550Q490 517 450 464T410 408Q415 399 501 273T617 106Q648 61 661 48T688 35Q705 35 705 16Q705 5 695 -1H539Q384 -1 379 3Q373 10 373 17Q373 27 380 31T408 35Q459 40 459 49Q459 59 418 129T335 259Q334 260 332 260Q328 260 273 197Q210 127 208 117Q199 104 199 82Q199 57 213 46T239 35Q247 35 252 29T257 15Q257 10 256 7T253 3T248 0L246 -1H28Q16 7 16 15T21 29T35 35Q61 35 117 88Q289 279 304 297Q307 303 255 377Q117 586 79 626Q60 648 39 648Q32 648 27 653T22 666ZM237 639V648H173Q113 647 113 646Q113 642 137 612Q186 546 302 373T453 139Q497 63 497 43Q497 39 495 35H559Q622 35 622 37Q622 38 583 94T486 233T373 399T277 552T237 639ZM553 637L566 648H504L508 637Q510 630 515 615V603L528 615Q529 616 539 625T553 637ZM170 46Q169 49 167 58T164 70V83L137 59L113 35H175Q175 38 170 46',
    0x1D550: '16 659T16 667T28 683H295Q306 676 306 666Q306 648 284 648Q258 648 255 641Q255 634 265 615T339 479Q418 339 421 339L455 394Q489 448 523 502L557 557Q560 566 560 582Q560 637 504 648Q489 648 486 655Q475 664 486 679L493 683H693Q704 675 704 667Q704 650 684 648Q672 645 653 623Q633 604 614 576T517 426L439 301V183Q442 62 444 59Q449 35 504 35Q521 35 528 30Q538 16 528 3L521 -1H195L188 3Q178 16 188 30Q195 35 213 35Q266 35 273 59Q274 61 277 163V261L75 621Q64 638 58 643T37 648Q28 648 22 653ZM219 637V648H101Q110 634 215 446L313 270V166Q310 59 306 48L301 35H415L410 48Q404 65 404 175V290L317 443Q230 601 226 612Q219 625 219 637ZM608 630L624 648H575Q584 632 588 623L595 610L608 630',
    0x1D56C: '821 97Q822 97 824 88T827 77L793 53Q676 -25 670 -28Q669 -29 656 -27L583 123Q583 124 467 46L352 -31L341 -20Q305 18 264 47T192 77Q161 77 60 32L49 40Q37 47 38 49Q39 49 93 83T212 160T297 219Q411 312 411 452Q411 519 360 571T233 624Q180 624 157 601T133 548Q133 524 160 496T214 441T241 393Q241 356 199 321T100 256L86 249L77 256Q68 263 67 263L84 274Q101 286 118 304T135 339T109 384T56 446T29 504Q29 566 118 624Q207 686 309 686Q349 686 360 685Q405 678 439 661T491 625T520 583T534 543T537 511Q537 436 491 344L478 318L455 299Q420 272 308 179L284 160L294 158Q348 154 426 89L437 79Q513 110 579 153V175Q579 183 579 227T580 330T581 446T582 542L583 582L664 630Q681 640 703 653T734 673L744 679Q750 678 756 676L767 674L716 623V585Q716 568 712 463T708 289V250Q708 237 709 218T710 195L711 180L739 130Q768 79 771 79Q775 79 796 88T821 97',
    0x1D56D: '160 345Q160 357 144 376T109 413T73 458T57 509Q57 544 95 584Q142 631 205 657T331 684Q382 684 427 658T500 585L505 577L521 588Q537 599 562 614T616 646T679 673T738 684Q790 684 807 666T840 587Q850 552 863 532T888 508Q894 505 906 505Q917 505 930 507T953 512T963 514L964 504Q965 495 965 494T914 467T808 413T745 384H751Q782 380 802 377T854 362T904 334T937 287T951 217Q951 178 937 143T908 91Q903 86 820 34L734 -21L718 -24Q679 -31 639 -31Q561 -31 451 4T271 40Q190 40 119 -2L99 -13L91 1L84 15L86 16Q88 18 132 42T233 100T315 152Q377 199 386 233Q388 240 393 297T399 363Q399 487 353 551Q337 573 306 597T238 622Q201 622 179 602T157 557T214 476T272 396Q272 371 229 334T143 272T96 246Q95 246 85 252T74 259T95 273T138 306T160 345ZM529 443Q529 409 528 385T526 353L525 346Q526 346 649 390T773 435Q749 451 742 464T727 518Q727 519 725 532T721 548T717 562T712 577T706 589T698 601T688 608T675 614T658 616Q626 616 576 582T525 528Q525 527 526 518T528 489T529 443ZM772 57Q774 57 778 58T792 64T808 77T821 103T827 144Q827 222 784 266T660 322Q652 323 611 323H596Q577 323 535 316L523 314Q520 291 505 255L500 241L356 138L366 137Q443 131 518 110T650 72T748 54Q763 54 772 57',
    0x1D56E: '460 -32Q373 -32 305 -11T193 45T122 124T83 214T72 303Q72 395 114 476L119 486L313 592L338 568L359 580Q418 615 479 638T568 668T606 675Q607 675 608 676H610Q612 676 615 661T630 621T660 578Q673 568 694 568Q717 568 721 570H726Q724 565 722 559L717 549L706 545Q608 513 583 513Q568 517 559 522T533 546T493 603L490 609Q452 599 452 558Q452 537 469 481T486 393Q486 353 474 331T422 285T296 231L272 223L262 230L253 237Q279 246 314 274T351 338Q351 376 334 442T316 532Q316 546 319 552Q319 554 316 554Q304 554 288 547T250 523T214 466T199 371Q199 218 299 133T541 47Q571 47 585 51T652 81L712 108Q716 104 716 81L706 74Q695 68 673 54T633 29L550 -22L540 -24Q492 -32 460 -32',
    0x1D56F: '380 596Q307 596 250 582T158 546T100 493T67 433T56 373V361Q55 361 43 366L31 372V384Q31 455 69 523T173 627Q213 650 284 666T444 683H452Q629 683 735 629Q896 548 896 369Q896 263 839 163Q835 155 818 140Q746 82 662 27T563 -29Q525 -29 386 16T183 62Q147 62 127 52T63 1L48 -14L40 -4L31 5Q83 73 172 149L186 161H199Q291 161 329 181Q357 199 357 231Q357 258 301 316T245 396Q245 423 282 458T349 512T403 543L413 548L425 545L438 541Q373 491 373 462Q373 446 399 415T453 349T480 288Q480 251 433 212Q394 180 348 156L334 148L353 145Q408 134 513 105T654 76Q711 76 745 132T780 277Q780 434 676 517Q637 549 562 572T380 596',
    0x1D570: '527 55Q574 55 619 69T691 97L717 111V85L562 -18Q520 -29 443 -29Q379 -29 325 -15T235 21T180 61T146 98Q74 186 74 307Q74 395 109 472Q113 482 123 489T190 533Q251 568 295 591L308 598L350 580L361 586Q403 612 464 636T564 673T609 686Q610 686 610 685Q612 683 616 670T627 636T646 601Q666 572 686 572H692Q713 572 726 576H728L725 565L723 554L692 544Q660 535 629 526T595 516Q585 514 574 519Q563 527 543 552T507 597T490 617Q467 604 456 579V564Q456 535 473 471T492 393L494 381L613 460L622 446Q630 433 650 411T696 371L703 365L614 312H596L580 322Q568 329 553 340T528 355T510 360Q496 358 491 354T484 345T471 326T435 297Q408 278 370 261T307 235T277 227Q273 227 266 234L256 240L267 245Q280 251 294 258T330 288T353 336Q353 373 335 444T316 530V537Q316 549 322 567Q270 554 233 499T196 370Q196 253 287 157Q392 55 527 55',
    0x1D571: '424 522Q265 596 208 596Q193 596 180 593T150 579T116 542T89 474Q86 465 86 463L59 481L63 494Q87 578 137 627Q191 684 285 684Q334 684 406 658T538 607T621 581Q644 581 706 629L721 640Q722 640 725 630L727 620Q701 592 654 548T582 486L569 487Q533 490 485 504L468 508Q449 503 429 495T387 466T365 422Q365 373 439 299L453 310Q473 325 528 370L588 418Q614 398 642 368T668 331Q667 331 628 296L590 262L582 274Q557 311 526 311Q511 311 487 297T462 278Q462 277 492 244T551 166T581 88Q581 54 570 25T536 -27T505 -56T478 -76Q376 -146 274 -146H270Q199 -146 162 -118T124 -15Q124 12 128 30T132 96V107Q132 144 117 157Q102 169 85 169Q74 169 59 165T32 156T20 151Q20 152 19 158T17 167Q17 168 17 168T17 169T19 170T22 172T27 175T35 179Q131 230 195 230Q231 230 259 202Q270 190 270 171Q269 150 253 87T236 -16Q236 -67 261 -87T322 -107Q380 -107 428 -68Q467 -35 467 30Q467 60 447 91T383 171T316 251Q290 286 278 308T263 339T261 359Q261 384 284 418Q322 469 424 522',
    0x1D572: '742 611Q784 611 812 631V611Q807 607 783 591T718 544T629 476L606 458Q608 458 628 457T667 453T713 443T762 423T804 388T836 335Q844 313 844 289Q844 231 814 182T746 103Q720 82 655 48T546 -18L520 -21Q456 -29 432 -29Q313 -29 223 33Q204 45 183 65T135 119T91 207T74 320Q74 428 109 480Q116 491 127 497T215 546L308 595L343 583L355 591Q387 613 433 636T488 660H489L491 659Q493 658 495 657T500 655L509 650L500 645Q479 635 460 612T441 552Q441 535 447 498T459 433T466 405L625 513L643 526Q620 530 585 546T535 586Q535 587 532 592T527 602T525 610Q525 613 577 649L630 687Q632 687 638 675T653 649T686 623T742 611ZM349 313Q349 328 327 413T305 510V516Q305 531 308 542T314 559T317 566T315 567Q297 567 270 548Q233 524 212 490T191 392Q191 337 206 288T244 207T284 156T316 128Q410 51 535 51Q632 51 675 102T718 217Q718 269 690 314T599 375Q574 381 535 381Q501 381 477 377L466 376Q469 364 469 349Q469 314 457 295T408 258Q366 236 308 219L288 213L279 220L270 227Q284 232 294 236T309 243T320 252T326 260T331 270T336 281Q349 310 349 313',
    0x1D573: '288 139Q288 172 255 224T189 335T156 442Q156 495 242 579Q289 625 361 668Q364 671 368 673T376 678T380 681L384 683L392 676Q401 670 414 661T443 642T477 626T509 619Q543 619 618 668Q625 672 628 674T631 675Q632 673 633 663T633 651L564 595Q556 589 545 580T528 566T516 556T505 548T497 543T488 539T481 537T472 535T463 534T451 534H442Q385 534 304 581L291 589Q290 588 285 583T277 575T269 566T262 555T257 543T255 529V522Q255 507 260 487T276 446T293 409T311 376L321 359Q321 358 322 358T324 359T327 361T333 366Q386 409 481 460L503 472L543 471Q586 471 599 470Q692 459 714 430Q725 416 738 360T752 245Q752 184 742 127T725 51T703 -8Q700 -13 619 -64T518 -123Q508 -126 493 -126Q438 -126 398 -86L427 -52Q456 -17 457 -17Q460 -17 465 -16H473Q474 -21 481 -32T504 -56T539 -69Q572 -69 599 -34Q625 4 625 158Q625 264 609 311T532 378Q508 386 484 386Q455 386 419 372T360 345T337 330L346 313Q375 263 386 227Q389 215 389 202Q389 192 388 184T384 168T376 152T365 138T350 121T331 103T307 81T278 54L194 -24Q130 30 99 30Q85 30 64 20T31 1T16 -10Q15 -11 13 -7Q12 -6 11 -3Q8 4 6 8L32 35Q88 88 117 107T169 126Q177 126 182 125Q218 118 252 84L263 73Q288 113 288 139',
    0x1D574: '500 615Q523 615 550 628T595 655T614 668L623 654L607 642Q512 569 440 534L427 527L413 529Q384 535 340 547T265 565T209 572Q173 572 145 556T101 522T60 465Q58 460 54 460T41 468L32 477L37 487Q96 599 139 640Q187 681 247 681Q275 681 283 680Q313 674 398 645T500 615ZM418 170Q418 186 410 260T401 382Q403 418 403 424L405 433L415 444Q482 515 571 571L582 578Q591 573 607 568L597 560Q522 504 522 450Q522 427 533 357T545 241V228Q545 190 536 159T508 106T478 73T446 48Q343 -25 238 -25Q179 -25 118 15L107 22L79 5Q51 -12 51 -12L38 2L55 18Q106 67 175 122L192 136Q202 130 206 123Q223 91 252 61Q263 50 266 48T278 39T297 32T320 30Q357 30 389 68Q415 102 418 170',
    0x1D575: '65 510Q68 517 74 528T101 569T144 620T202 661T274 680Q308 680 389 628T503 576Q530 576 596 600Q615 607 616 607Q616 602 615 596V585Q605 581 576 568T531 548T485 531T418 509L400 503L358 522Q347 527 327 537T299 550T277 560T257 568T239 573T220 577T201 578H196Q181 578 169 575T135 554T88 502L83 496Q82 496 74 502T65 510ZM424 4Q424 50 395 151T365 313V320Q365 352 369 361T405 403Q431 432 465 462T521 508T547 525L549 524Q551 524 554 523T560 521L571 517L552 498Q515 461 499 430Q485 399 485 366Q485 326 512 231T539 84Q539 -14 460 -77T273 -141Q248 -141 234 -140T198 -131T160 -106T134 -59Q128 -40 124 -16T117 22T108 49T91 69T59 75T15 65L1 59Q-8 76 -7 77Q4 85 22 97T88 129T170 149Q218 149 234 125Q242 112 242 43V21Q242 -17 248 -41T274 -85T322 -105H325H330Q363 -105 396 -75Q424 -47 424 4',
    0x1D576: '234 109Q234 144 194 245T153 404Q153 445 180 490Q232 572 325 626T517 681H524Q612 681 661 658Q683 647 699 632T717 604Q717 600 708 545L699 490L690 489Q681 488 679 488Q675 488 669 504T640 546T577 592Q520 620 446 620Q415 620 386 614T327 594T280 553T262 487Q262 468 265 447T271 413T279 384T285 362L295 371Q320 396 352 421T439 474T538 502Q577 502 596 484T627 428Q642 386 651 373T677 360H682Q698 360 727 369L724 357Q724 354 724 351T722 346V344Q559 289 539 283Q582 272 589 271L615 265L637 189Q662 109 663 108Q668 97 682 84Q698 68 722 68H730H738Q762 68 799 91L803 80L806 70Q795 59 770 40T703 -3T631 -26Q598 -26 578 -8Q548 24 536 92Q524 154 509 183T477 218T428 224Q409 224 385 220T346 212L331 207Q330 205 330 201T331 189T332 178Q332 158 325 116L305 96Q269 60 240 38Q171 -21 123 -21Q72 -21 33 18L20 32L62 74Q96 107 102 112T116 118Q120 118 122 113T131 95T150 69Q171 48 190 48Q198 48 206 51T224 69T234 109ZM519 367Q497 432 450 432Q379 432 313 333L300 314L304 299Q306 294 309 280T315 260L321 235L542 313Q530 325 519 367',
    0x1D577: '277 226Q277 248 253 286T203 369T178 449Q178 490 212 533T284 607Q380 683 532 683Q610 683 639 660T668 583Q668 568 666 546T663 509Q663 478 683 460Q691 452 719 452L738 450Q732 437 729 437Q728 437 652 416T573 394Q554 394 541 409T527 444Q527 449 532 487T538 542Q536 584 501 606T418 628Q389 628 364 620T317 587T295 523Q295 478 333 401T372 276Q372 269 371 267Q371 264 318 206L264 149Q284 141 317 130T433 101T577 82Q619 82 652 95T701 127T728 164T742 196L744 209Q744 210 749 208T759 203T764 199T760 185T751 154T744 129Q714 42 680 13Q628 -28 566 -28Q490 -28 403 -5T249 42T153 66T106 53T70 15T47 -16Q46 -17 30 -5L39 13Q85 100 138 148L147 156L161 157Q218 165 246 179T277 226',
    0x1D578: '134 338Q134 357 81 417T27 504Q27 516 34 530Q55 568 110 615Q190 683 305 683H314Q445 683 495 580L501 569L512 577Q608 646 681 646Q759 646 801 585L808 576L816 583Q860 619 921 650T1041 682Q1063 682 1077 675T1096 660T1112 631T1132 596Q1160 555 1188 555Q1204 555 1228 564Q1230 565 1231 562Q1231 560 1232 554V547L1215 538Q1179 521 1114 475Q1112 474 1106 470T1099 464T1093 459T1088 452T1085 441T1082 425T1081 404T1079 376T1079 339Q1079 282 1084 236T1098 160T1117 112T1138 85T1159 77Q1166 77 1180 81T1207 90L1219 94Q1220 94 1221 86T1222 76L1045 -32Q1044 -32 1004 15L964 64V167Q965 334 970 372V378L994 402Q1032 440 1057 460Q1061 463 1066 467Q1070 469 1070 470T1068 471T1060 474T1050 481Q1040 488 1021 531T996 583Q979 609 947 609Q922 609 887 592T820 537L821 524Q825 484 825 448Q825 268 768 155L759 137L589 -28L579 -20Q533 17 507 17Q475 17 449 -7L436 -18L424 2L441 20Q446 25 456 36T471 52T484 65T497 79T509 90T522 99T534 106T548 112T561 115T576 117Q602 117 639 86Q648 81 648 81Q650 82 657 94T668 112Q711 202 711 373Q711 484 677 533T600 583Q592 583 583 581T569 577T554 568T542 560T528 549T516 539L519 523Q527 485 527 461Q527 444 522 407Q506 266 447 150L437 130L217 -25L208 -15Q165 28 126 28Q89 28 62 1Q47 -14 43 -14Q42 -14 36 -8L28 0L44 17Q96 73 120 92T166 117Q182 123 204 123Q239 123 284 78L295 67Q307 72 337 102Q400 178 400 346Q400 508 325 571Q270 618 208 618Q180 618 168 614T140 594Q124 578 124 564Q124 540 182 480T240 396Q240 359 197 321Q154 285 94 252L80 245L76 248L67 257L61 262L71 268Q82 275 94 284T120 309T134 338',
    0x1D579: '522 492Q521 492 517 502T512 513Q542 444 542 333Q542 226 503 137L498 125L396 53Q308 -8 292 -17T260 -27Q226 -27 191 -9T136 29L145 39Q162 56 192 89L230 129L235 128H241Q276 57 332 57Q358 57 391 80Q403 89 409 100T422 143T428 227Q428 329 406 408T347 530T272 594T196 615Q152 615 135 596T118 558Q118 535 146 502T203 438T232 385Q232 357 195 322T122 265T83 243Q82 242 72 249T61 258L66 262Q72 265 82 273T103 292Q125 314 125 333Q125 351 101 376T51 432T26 492Q26 549 108 614T290 679Q326 679 335 678Q353 675 370 670T400 658T425 642T445 625T463 606T477 588T487 571T495 556T500 543L504 535L523 553Q553 581 569 595T619 632T686 667T757 678Q778 678 793 675T819 664T833 651T844 633T852 617Q884 548 910 548H916Q938 548 962 556L967 542Q967 540 947 531Q909 509 883 492T847 467T838 458Q825 419 825 328Q825 234 833 191T858 121Q875 94 892 77Q898 71 907 71Q912 71 928 76T957 87T971 91L972 88Q972 84 972 81L973 73L957 63Q891 21 806 -23L794 -30L783 -14Q766 13 728 60L713 79V372L724 384Q743 406 765 427T800 460L813 471Q809 472 806 472Q783 479 766 503T741 551T715 594T672 614Q644 614 622 595Q597 576 572 550T534 508L522 492',
    0x1D57A: '254 595Q269 583 269 581L262 577Q256 573 247 566T228 549T212 527T205 502Q205 480 266 386T328 277Q328 234 239 150L221 134L231 133Q264 131 376 99T516 62Q567 50 604 50Q614 50 626 52Q643 57 662 71T703 115T739 198T753 323Q753 454 692 517Q652 555 584 565T382 577Q365 577 357 577H308L300 591L292 606Q292 608 342 665L392 724L403 725Q406 725 411 726H416L417 725L412 715Q408 705 408 698Q408 684 423 679Q431 677 516 672T663 655Q757 634 806 593T873 463Q881 421 881 380Q881 340 874 306Q859 223 809 147Q801 134 789 124Q595 -30 456 -30Q395 -30 289 3T147 36Q134 36 121 33T98 26T76 15T59 4T44 -8T32 -17L22 -7L12 4L56 59L100 114L116 118Q217 142 217 199Q217 230 185 276T120 365T87 430Q87 435 109 464T172 534T254 595',
    0x1D57B: '247 398Q247 372 206 334T126 272T83 247Q82 247 72 253T61 261Q60 261 61 262T66 265Q127 306 127 343Q127 364 63 430Q42 451 38 458T33 480V490V497Q33 526 63 567Q112 632 170 660T282 688Q341 688 384 667Q454 633 482 566Q483 565 484 566T496 574Q562 623 630 653Q699 681 751 681Q778 681 797 673Q818 662 830 609Q835 580 843 564Q863 524 895 524H901Q917 524 932 528Q936 522 938 518T942 513T942 511Q873 480 836 454Q789 423 789 395Q789 362 834 298T880 200Q880 170 867 145T820 81Q733 -20 647 -20Q581 -20 499 21V9Q499 -16 502 -53T509 -116L512 -141L370 -223L357 -216Q344 -209 344 -208L348 -196Q370 -113 370 33V52L355 58Q307 76 284 76Q258 76 228 60T183 29T141 -11Q137 -7 133 -2L126 7L134 18Q181 89 210 121T278 170Q304 179 328 179Q336 179 358 177L370 175Q368 268 367 359Q367 416 363 434Q362 438 362 441Q348 527 302 574T203 621Q169 621 148 599T127 557Q127 535 187 476T247 398ZM673 315Q673 357 786 442Q786 443 776 444T750 449T727 462Q719 471 716 484V496Q715 507 715 515Q715 571 698 588Q680 611 643 611Q592 611 547 571Q534 558 511 522L499 505V139L543 123Q702 64 744 64Q770 64 781 79T793 112Q793 143 733 217T673 315',
    0x1D57C: '254 595Q269 583 269 581L262 577Q256 573 247 566T228 549T212 527T205 502Q205 480 266 386T328 277Q328 234 239 150L221 134L231 133Q264 131 376 99T516 62Q567 50 604 50Q614 50 626 52Q643 57 662 71T703 115T739 198T753 323Q753 454 692 517Q652 555 584 565T382 577Q365 577 357 577H308L300 591L292 606Q292 608 342 665L392 724L403 725Q406 725 411 726H416L417 725L412 715Q408 705 408 698Q408 684 423 679Q431 677 516 672T663 655Q757 634 806 593T873 463Q881 421 881 380Q881 340 874 306Q864 250 838 196T791 126Q748 93 733 82L715 69Q714 68 723 60T748 40T774 23Q806 2 832 2Q849 2 870 6T904 14L917 17Q917 12 918 6V-3L882 -22Q806 -60 778 -73L755 -83Q640 -36 596 -7L586 0L576 -4Q513 -30 457 -30Q394 -30 289 2T149 35Q119 35 93 22T52 -4T36 -17T24 -7T12 4L56 59L100 114L116 118Q217 142 217 199Q217 230 185 276T120 365T87 430Q87 435 109 464T172 534T254 595',
    0x1D57D: '31 498Q34 541 76 586T176 659T279 688H290Q377 688 429 653T506 569L511 558L526 572Q620 663 707 682Q722 685 737 685Q781 685 804 665T830 619T838 565T854 525Q866 511 897 511Q917 511 925 513L937 515Q938 515 941 509T944 501T925 493T870 470T803 438Q735 406 735 401Q735 400 741 399T767 390T814 374L828 367L829 307Q829 233 833 202T852 144Q873 109 896 90Q906 82 928 82T976 95V92Q976 88 978 72L807 -28Q768 39 733 87L718 108V149Q718 230 714 257T693 298Q654 333 580 333Q524 333 520 329Q520 300 489 224T443 133Q441 131 333 53T223 -27Q221 -26 204 -11T169 16T136 28Q110 28 66 -8L56 -16Q52 -13 40 -1L48 7Q165 124 211 124Q232 124 287 77L298 67Q309 73 337 97Q397 150 397 347Q397 419 379 474T330 560T269 604T207 619Q177 619 152 601T126 563Q126 540 185 479T244 387Q240 336 160 289Q144 278 98 255L80 246L62 261L79 272Q96 283 113 301T130 337Q130 353 115 373T81 410T47 451T31 498ZM524 358Q537 358 657 405T777 457Q777 459 768 459Q749 462 738 474T723 499T714 539Q706 585 697 599Q681 618 657 618Q632 618 597 595T532 515L525 502L524 441Q524 375 523 369Q523 358 524 358',
    0x1D57E: '457 -31Q356 -31 272 6T135 120T82 304Q82 372 106 430T170 527T241 588T305 626Q341 643 386 657T460 678T495 685T554 660T674 609T778 584Q800 584 818 591T848 610T866 633T878 651T883 659L893 649L901 639Q879 574 803 532T666 490Q661 490 657 490T650 491T641 492T633 495T622 500T610 505T595 513T577 522T554 533T527 547Q436 594 415 602Q393 608 374 608Q303 608 253 545T202 386Q202 229 307 135T568 41Q674 41 748 85T822 198Q822 244 779 283T639 322Q595 322 499 303T383 283Q358 283 335 290T291 318T270 374Q270 418 313 460T424 510H431L435 505L440 500Q425 496 403 475T380 427Q380 382 431 373Q437 372 475 372Q543 372 626 388T742 404Q831 404 868 362T905 260Q905 182 831 108Q692 -31 457 -31',
    0x1D57F: '666 641Q737 641 794 686L802 662Q790 648 734 596L677 541L664 538Q630 528 583 528Q540 528 482 537L461 541Q402 512 402 456Q402 427 439 387T512 311T549 253Q549 220 455 139L440 126Q541 75 586 75Q600 75 619 80T654 94T685 110T709 124T719 130Q722 125 725 119L730 108Q700 72 568 -18Q551 -30 542 -30Q495 -30 404 6T270 42H263Q213 42 142 -11L131 -19L129 -8Q126 1 126 4Q218 84 301 126L316 134H406L413 142Q436 165 436 189Q436 202 421 221T364 281Q336 307 318 328T296 356T283 381L290 394Q338 478 410 540Q419 549 417 549Q415 550 369 558T268 575T195 584Q153 584 127 567T100 523Q100 499 116 479T151 447T170 433Q170 429 171 428Q171 427 131 394T88 359Q82 363 73 370T47 403T31 457Q31 513 79 565T197 648T332 679Q369 679 490 660T666 641',
    0x1D580: '273 244Q273 281 244 331T186 428T155 502Q155 524 165 536Q239 634 333 688Q338 684 345 680L356 672L344 664Q310 642 295 624T280 582Q280 550 303 505T348 407T371 300Q371 270 362 248L247 123L358 92Q452 64 484 64Q507 64 523 72Q553 87 573 109Q583 121 586 146T593 283Q594 303 594 344Q594 401 591 461T584 558L581 595Q598 600 623 611T672 634T719 659T754 678L768 686Q770 686 784 673L782 670Q781 668 777 664T768 655Q747 635 738 616T721 535T714 359Q714 205 723 176Q727 164 744 133T771 89Q780 75 804 75Q814 75 853 87L867 92L871 73L671 -39L654 -10Q636 20 619 50T600 83Q600 84 589 75T539 34Q478 -16 475 -19Q469 -22 449 -28T414 -34Q410 -34 394 -32Q356 -28 282 -2L237 15Q169 38 126 38Q106 38 85 27T51 4T37 -8T27 -1T18 8Q18 10 70 63T124 116Q154 123 176 131T223 154T260 191T273 244',
    0x1D581: '133 343Q133 360 79 416T25 496Q25 523 58 563T118 624Q197 685 293 685Q331 685 339 684Q453 665 489 558L493 546Q521 570 553 596T640 653T725 684Q753 684 783 672T844 641T889 618Q895 616 912 616Q924 616 936 617T956 620T965 622T966 612V604L952 595Q924 576 895 549Q864 517 856 496T847 448V434Q847 395 848 388L859 323Q874 241 874 212Q874 142 830 96Q796 62 724 14Q661 -29 603 -29Q555 -29 421 28T242 86Q182 86 110 31Q105 28 102 26T99 25Q88 36 88 42Q95 54 222 142Q252 163 262 165Q319 183 344 218Q378 266 378 377Q378 444 362 494T319 571T266 610T212 623Q181 623 156 603T131 562Q131 539 154 512T206 458T243 416Q246 409 246 399Q246 387 242 377T225 351T178 311T94 259L79 251Q72 256 68 261T62 268L61 270L70 277Q131 318 133 343ZM822 526Q778 531 719 564T628 597Q611 597 579 574Q543 543 513 506L505 495L506 473Q506 469 506 461T507 449Q507 348 467 271L462 261L404 218L348 174Q349 173 356 173Q384 169 450 144L546 105Q665 56 708 56Q737 56 746 72T756 118Q756 129 755 135L741 219Q725 314 725 334V344Q725 416 736 431Q748 450 815 510L832 526H822',
    0x1D582: '133 317T133 338T80 413T26 496Q26 532 83 591Q100 608 111 616T151 644T219 672T304 682Q381 682 434 646T506 564L510 557Q513 557 534 573L677 665L707 683L790 561L803 572Q933 682 1001 682Q1037 682 1098 650T1193 616Q1208 616 1222 619L1235 622Q1239 622 1239 616Q1239 611 1240 609Q1240 608 1206 577T1138 503T1104 430Q1104 409 1123 330T1142 208Q1142 183 1136 147Q1127 118 1117 106Q1114 103 1031 48T935 -14Q930 -18 908 -22T862 -27Q826 -27 759 -6T647 26Q597 38 578 38Q573 38 561 33T533 20T505 4T480 -10L469 -16L452 -26L439 -28Q423 -30 411 -30Q358 -30 279 7T169 45Q125 45 58 -5L47 -14L41 -4L35 8Q35 11 56 29T113 75T181 125L200 139H217Q279 143 320 180T377 270T394 393Q394 453 378 498T334 568T277 605T213 617Q177 617 155 607Q140 600 130 587T119 560Q119 545 137 522T177 479T217 434T236 393Q236 324 98 251L89 246L76 253L63 261Q91 275 112 296ZM1088 526Q1066 526 1004 556T909 586Q863 586 816 539L802 526L804 514Q814 461 814 411Q814 319 781 238Q772 214 760 198T730 165T702 136L715 133Q759 122 848 90T973 57Q1003 57 1017 80Q1022 93 1022 116Q1022 152 1003 241T983 377V391Q983 405 985 409T1002 429Q1019 450 1045 475T1090 514L1107 528Q1104 527 1102 527T1096 527T1088 526ZM699 358Q699 391 696 419T688 467T675 503T660 530T642 550T626 563T608 574T593 582Q581 575 559 554T524 512Q523 510 523 477Q523 315 444 218L435 207L368 169Q301 132 301 131Q307 128 315 125L377 99Q476 57 515 57Q534 57 608 94L627 102L636 111Q699 187 699 358',
    0x1D583: '273 679Q354 674 408 633T477 525L484 533Q496 548 524 574T571 615Q594 633 625 649T675 673T699 681Q724 632 747 607Q754 601 756 599T765 594T777 591T794 590Q818 590 834 594V585L835 577L704 513L693 518Q657 534 631 560T597 599Q596 601 581 584Q495 490 489 379V366H562L681 369Q682 369 679 366T668 355T651 341L620 314H485V295Q490 190 543 125T686 60Q720 60 789 88L801 93V89Q798 83 798 66Q781 59 685 -10L665 -25L634 -30Q596 -35 594 -35Q570 -35 536 -23T477 19Q461 37 445 67T418 118L409 138Q401 131 388 120T340 79T273 28T206 -12T151 -31Q129 -31 90 -12T32 22L113 101Q114 101 120 96T136 84T160 69T189 56T221 51Q256 51 305 90Q376 149 376 301V315H293Q276 315 251 315T210 314T190 313L168 312Q168 313 200 340L231 368L238 367Q275 367 311 366H378V387Q376 470 355 512T291 572Q274 579 252 579Q223 579 197 568T156 544T131 519T117 508Q112 512 108 518L99 527L117 545Q177 604 255 665L273 679',
    0x1D584: '34 496Q34 518 53 549T107 610T195 661T310 682Q357 682 398 663T460 611Q467 600 475 583T489 554T495 542Q495 544 531 570T617 629T700 676L724 688Q742 670 756 657T784 635T806 621T830 606T856 592Q878 416 878 340Q878 154 805 -3L798 -20L779 -40Q706 -113 613 -163T421 -214Q359 -214 317 -196T256 -160L306 -63L313 -64L320 -66L326 -79Q337 -104 349 -120T392 -151T470 -166Q576 -166 644 -101Q750 7 750 292Q750 426 721 495T617 565H611Q563 565 513 509L506 501L508 493Q508 490 509 475T510 445Q510 319 458 236L451 225L436 216Q406 198 365 169T318 134L332 127Q336 126 397 103T489 80H493Q527 80 593 129L604 137L607 127Q610 119 610 116Q610 114 592 95T543 46T484 -4Q450 -27 446 -27Q441 -27 402 -18Q365 -9 290 20T188 50Q135 50 64 -7L52 -17L43 -7L34 2L51 19Q118 87 177 132L192 143H215Q259 145 289 155T335 184T355 214T366 245Q382 306 382 388Q382 426 381 436Q368 520 318 570T214 621Q184 621 165 608T142 583T137 562Q137 541 163 508L201 469Q245 425 251 408Q253 403 253 398Q253 383 240 366T212 335T161 295Q128 271 99 253L89 247L77 256L65 266L76 273Q125 301 134 329Q136 334 136 342Q136 357 124 372T88 410T49 455Q34 479 34 496',
    0x1D585: '278 601Q242 601 212 591T167 570T121 533Q114 528 111 525L93 550Q223 661 244 667Q299 677 356 677Q415 677 456 666T515 634T541 596T549 555Q549 513 529 478T480 421T424 388T377 372Q365 370 365 367Q365 365 389 365T450 358T523 337T588 282T623 183Q624 177 624 161Q624 20 524 -60Q415 -148 285 -148Q242 -148 213 -139Q181 -131 159 -109Q136 -87 127 -56T114 6T104 49Q94 69 57 69Q38 69 13 58L1 53Q1 55 0 59T-3 68T-4 76Q78 130 138 142Q150 144 162 144Q213 144 227 120T242 31Q242 -30 263 -66T345 -102Q397 -102 444 -52T491 107Q491 172 471 211T428 265Q392 288 306 288Q269 288 233 284L218 282Q208 289 208 291L229 324L251 359Q250 360 248 360Q239 360 248 371L256 381H273Q344 385 378 409T413 495Q413 537 384 569T278 601',
    0x1D586: '80 129V151Q80 241 99 363Q99 367 111 372T172 401T285 465L297 472Q340 455 405 443L423 440L455 453Q486 467 489 467L497 461L494 451Q480 390 480 292V283Q480 207 483 155L484 143L535 80L558 90L582 99Q586 95 586 83Q586 81 513 25L443 -29Q410 16 386 40L371 55V61Q371 63 371 67T370 74V80L278 25Q186 -29 184 -31Q182 -32 160 -12T112 35T80 75V129ZM359 366Q334 366 300 371T243 382L221 388Q218 388 212 375T200 323T194 228Q194 191 197 152L198 139L217 120Q245 92 269 74L279 66L304 78Q338 95 349 100L369 110V152Q368 164 368 210T367 275Q367 358 366 361V366H359',
    0x1D587: '99 398Q99 610 86 662Q86 665 95 669T106 674L108 669Q109 664 112 654T119 635Q122 626 125 616T130 601L131 596Q214 649 273 678Q295 690 298 690Q299 690 304 688T313 682L317 679Q275 653 240 612Q210 569 210 469V459Q210 450 210 432T211 406L212 378L285 425Q301 435 321 447T350 466L360 472Q360 473 361 473T368 471T401 456T465 429L501 414V408Q504 386 504 309Q504 255 500 203T491 125T485 97Q485 95 445 74T343 23T237 -24L214 -32Q197 -22 165 3T109 49T87 73Q99 169 99 398ZM386 251Q386 320 380 347V350L305 374L282 382L214 348L213 274Q213 184 214 165V131L230 119Q288 76 349 54Q386 137 386 251',
    0x1D588: '227 393Q215 393 210 351T205 269Q205 161 213 153Q220 145 244 125T290 88L312 72L365 92Q414 113 418 113V93L365 60Q255 -9 221 -26L211 -18Q158 21 91 88L90 107Q87 167 87 225Q87 267 90 302T96 351T100 366L295 473L311 470Q340 464 368 454T410 437T424 429L347 334L342 333H337L325 342Q299 363 271 378T228 393H227',
    0x1D589: '88 117Q88 177 91 231T97 310T102 341Q102 343 118 357T168 397T239 447L257 459L268 454L278 449Q242 416 238 412L219 394Q219 391 216 378T211 349T206 307T203 249Q203 211 206 166L208 148Q224 132 261 108T333 70Q341 66 342 67T350 79Q393 157 393 302Q393 368 388 406V411L371 424Q199 558 101 558Q69 558 28 545L18 542L8 549L-1 557L24 569Q61 587 147 621L177 632Q179 631 194 627T216 621T240 613T269 602T302 589T340 571T382 549T431 522T484 488Q504 475 504 472Q511 449 511 365Q511 248 474 129L468 108L451 96Q427 77 347 28T254 -28Q235 -20 174 21T89 86L88 117',
    0x1D58A: '309 69Q391 98 416 108Q418 106 422 100T425 92Q419 86 326 30T229 -27Q228 -27 207 -13T154 27T97 76L85 87L84 106Q81 152 81 194Q81 295 93 359L95 369L286 471L313 449Q376 397 414 372L428 362Q428 360 375 318L188 181V170Q188 156 189 153V148L203 138Q228 119 266 94T309 69ZM209 389Q208 388 204 366T194 307T187 244Q187 225 188 225T201 233L245 261Q283 284 291 291Q324 313 324 316L296 334Q280 343 259 357T224 380L210 390Q209 390 209 389',
    0x1D58B: '128 400Q127 401 121 422T108 478T99 540V555L111 569Q135 597 165 626T214 671T235 687L249 678Q263 668 282 659T315 650Q335 650 362 666L372 654L286 569H271Q205 576 173 586V583Q173 558 208 492T252 401Q253 399 310 399T367 398L332 355H254V311Q251 160 235 16Q230 -28 226 -36Q225 -38 221 -45Q171 -140 121 -211L113 -222H104Q94 -222 94 -220Q94 -215 105 -187L121 -145Q139 -80 139 35V93Q139 222 135 314L134 354Q134 355 84 355H35L84 399H106Q128 399 128 400',
    0x1D58C: '92 71Q92 74 91 88T88 128T86 183Q86 230 91 275T102 342T109 366Q115 372 207 422T305 472Q407 426 431 426Q435 426 476 445L519 465L525 463L532 461Q497 392 497 268Q496 255 496 233Q496 179 516 92T539 -10L541 -22L526 -38Q441 -126 355 -194L339 -206L327 -207Q324 -207 319 -207T310 -208Q242 -208 171 -179T73 -131L56 -141Q40 -150 38 -150Q17 -140 17 -137Q17 -136 18 -136T98 -79L176 -23Q174 -21 134 24T92 71ZM226 393Q224 393 221 372T214 312T210 235Q210 182 214 144L215 132L230 118Q281 70 301 66Q304 66 331 80T373 105L384 112L383 165Q383 224 387 309Q387 314 387 319T387 329T388 336T388 341V343Q388 344 381 344T339 354T249 384Q246 385 243 386T236 389T231 391T228 392L226 393ZM414 -80Q414 -64 411 -43T403 -1T394 37T386 66T382 79Q381 79 286 15T189 -52Q312 -125 365 -125Q397 -125 405 -115T414 -80',
    0x1D58D: '95 661Q95 662 103 667T113 672L126 634L137 596L147 602Q235 656 275 677L292 687L303 680Q305 679 307 677T312 674L313 672L310 670Q307 669 301 667T289 660T274 649T259 634Q250 622 244 611T233 585T226 560T222 528T221 497T220 456T219 413V377L232 384Q244 391 271 409T339 455L362 471L383 461Q425 440 491 415L504 410V406Q507 399 507 269Q507 76 486 -21Q485 -30 483 -33T461 -57Q382 -139 299 -207L281 -197L263 -186L266 -185Q268 -184 280 -177T312 -155Q344 -130 353 -116Q394 -59 394 117Q394 162 391 216T386 301T382 335Q382 338 365 346T323 364T281 376L250 362Q220 347 219 347Q213 336 213 232Q213 177 217 144L218 128L224 119Q244 92 263 71L272 60Q206 21 157 -24Q156 -24 151 -16T132 11T98 52L89 62L91 103Q104 289 104 436Q104 471 103 506T101 568T99 616T96 649L95 661',
    0x1D58E: '73 613L164 686L184 666Q200 650 214 637T235 620T242 614T203 577T162 540Q158 540 122 570T73 613ZM92 58Q92 63 94 83T98 142T101 234Q101 318 97 358V366L59 387L40 379L21 371Q20 371 12 376T3 382L38 406Q78 431 125 466L138 477Q149 468 186 444L219 422V389Q215 324 215 247Q215 136 222 123Q226 113 238 98T258 83Q263 83 292 94L322 104Q322 103 324 97T327 89Q327 88 317 82T272 52T190 -7Q166 -25 164 -25L112 35Q92 55 92 58',
    0x1D58F: '74 611L155 682Q172 666 186 655T208 636L235 614Q227 606 191 574L154 540L135 556Q101 582 84 601L74 611ZM10 377L144 477Q145 476 184 453T229 428L233 425V416Q238 346 238 252Q238 93 215 -16L213 -30L185 -57Q29 -203 19 -203Q17 -203 -19 -189L-9 -183Q52 -146 78 -116T114 -37Q120 31 120 192V237Q120 327 113 351T72 380L53 372Q34 362 32 364L10 377',
    0x1D590: '106 72Q110 105 111 193T114 294V308H74L34 309L83 346H115V430Q114 591 106 652Q105 662 107 665T114 668T123 672Q125 672 139 635L152 597L154 598Q156 600 160 602T167 607Q193 625 226 644T279 672T302 682L312 676L321 670L312 665Q281 649 263 626T241 587T233 547Q232 541 231 530T230 510T230 501Q231 501 265 522T334 564T369 583L380 570Q428 509 428 481Q428 475 427 470T423 459T416 448T404 434T389 418T369 397T344 371L321 347L365 346H409L372 308H227V294Q227 272 230 208T234 138Q234 136 256 119T302 84L324 68L372 88Q421 108 422 108T432 90L421 83Q373 53 270 -5L234 -25L204 -1Q172 25 124 60L106 72ZM336 434Q336 452 327 472T308 503T297 514Q296 514 290 510T275 499T264 490Q230 458 230 358V346H247Q268 346 276 350T302 372Q328 398 335 423Q335 424 335 428T336 434',
    0x1D591: '111 275Q111 406 108 518T104 650V657Q105 657 109 660T117 665T122 666L133 629L144 594L161 606Q218 642 272 670L294 681Q295 681 300 677T306 672L302 669Q298 666 292 662T278 651T263 637T251 621Q232 587 227 530T222 343Q222 226 230 125L231 112L244 98L258 83Q271 87 285 92L312 102V84Q297 72 231 24T163 -23L100 55Q110 141 111 275',
    0x1D592: '115 203Q115 257 114 291T112 338T111 355Q111 357 93 370L75 384L54 375Q32 366 31 365Q27 365 16 378Q25 383 89 430L152 476Q175 453 228 420Q229 420 229 418T229 410T227 394L225 369Q279 400 315 425T363 461T376 471Q480 424 514 416V412Q514 411 514 404T513 392L511 376L520 382Q529 387 548 399T584 422Q599 432 618 444T648 463L657 469H658Q661 469 681 461T735 440T796 420Q803 418 803 416Q801 414 798 390T791 325T788 247Q788 220 790 172T794 123Q799 115 814 97T835 78H838Q841 78 867 89L895 101Q896 101 896 100T897 92T900 78L873 62Q810 23 761 -12L736 -30Q735 -30 729 -22T707 7T671 48L661 59Q674 93 674 207V219Q674 341 670 344Q655 353 591 372L576 376L544 364Q511 351 510 351Q507 349 507 224V132L535 95Q541 87 548 78T560 63L563 58Q563 57 504 15T444 -28L385 53L387 67Q396 114 396 206Q396 289 393 334Q393 346 390 348Q369 358 306 373Q301 373 265 361L228 349V335Q227 322 227 284Q227 206 231 157Q231 151 231 144T232 133V129Q232 125 259 90Q286 56 286 53Q287 53 284 51T273 43T258 31L173 -31L166 -20Q160 -11 145 7T119 38T108 59Q108 62 110 81T113 133T115 203',
    0x1D593: '608 88Q572 65 535 37T477 -8T455 -25Q432 7 389 53L375 68L378 82Q386 160 386 195V221Q386 284 385 307L384 344Q352 359 306 373L286 379L213 353V273Q214 229 214 161V129L275 62L163 -28L150 -14Q136 0 121 16T91 44Q86 48 86 50Q95 83 96 148Q96 224 89 340L88 366L79 374Q69 384 67 385L64 388L55 383Q52 382 44 378T33 373L21 367L13 374Q5 379 5 381Q5 384 69 428L133 473Q135 473 147 464T179 443T215 424L214 400V376Q271 404 342 457L363 472Q363 473 364 473Q366 473 375 469T418 449T502 414L512 411V407Q502 330 502 217V197V132L523 109Q527 104 533 97T543 87T547 83L550 80L578 92Q603 103 604 103Q606 103 608 88',
    0x1D594: '107 102Q107 178 112 242T123 334T129 362Q129 363 140 368T199 400T315 469L336 482L346 476Q409 439 498 414L514 410L515 389Q515 208 502 141Q494 101 491 94Q490 89 478 81Q430 51 375 23T288 -20T254 -34Q250 -34 200 -1T119 56L108 65L107 76V102ZM389 355Q367 358 346 363T309 372T282 381T264 388L257 390H256Q254 390 249 381T238 348T227 293Q226 280 226 237Q226 183 231 146L232 131L244 122Q285 91 323 74T374 57H377L380 68Q405 154 405 267Q405 315 401 349V354L389 355',
    0x1D595: '66 435Q66 445 117 501T173 557Q174 557 183 555T193 551Q174 526 174 509Q174 496 190 472T233 428V386L377 482L399 471Q450 445 509 425Q519 421 519 420L518 419Q518 418 518 416T517 410Q517 405 518 381T519 335Q519 222 501 137Q492 84 489 84L473 75Q457 66 423 44T354 -6L338 -19L329 -13Q320 -8 313 -4T297 4T284 10T270 14T258 17T245 20T233 22V12L241 -161L214 -172Q187 -184 160 -195T131 -207Q127 -207 112 -202L113 -188Q113 -182 115 -77T118 31Q118 32 109 32Q63 27 23 0L10 -9Q5 -4 -1 8Q1 13 52 57T114 101H115L117 123Q117 141 117 230V359L110 367Q85 394 71 421Q66 433 66 435ZM384 83Q386 83 389 110T396 180T400 254Q400 294 395 339L394 349L379 355Q308 383 294 383Q290 383 263 372L234 360L233 245V130Q270 125 305 113T361 92T384 83',
    0x1D596: '362 -196Q375 -92 375 47V78L282 24Q189 -29 188 -30Q187 -30 139 21T90 75Q87 84 87 158Q88 206 94 259T107 342L113 372L308 478L322 473Q374 452 421 444L433 442L503 485Q515 479 515 477Q485 378 485 56Q485 -100 494 -164V-171L381 -211L371 -207L362 -202V-196ZM280 72Q301 77 323 86T358 101T372 110Q372 268 377 346L378 358H374Q368 360 358 360T323 365T257 380L234 386Q231 386 229 379Q215 353 211 310T207 180Q207 152 208 150Q210 142 235 114T280 72',
    0x1D597: '23 367Q21 370 18 374T14 380L13 382L151 472L236 411L238 381L290 426Q298 432 307 439T322 452T333 461T342 467L344 469Q382 410 404 399Q410 397 416 397Q423 397 432 399T446 403L451 405Q453 405 453 399V393Q430 374 404 356T364 328T350 318L349 317Q321 320 276 356Q257 371 256 371Q253 374 249 366T242 351Q232 321 232 236Q232 214 232 205T232 182T233 162T235 148T238 137T242 129T249 120T257 114T268 105T281 95Q313 70 314 70L358 85Q377 92 389 96T402 100V90L403 80L229 -26L221 -18Q195 6 166 29T121 63T105 76T106 82T110 97T114 121T117 158T119 208Q119 269 114 329L113 341L103 350Q90 362 67 380L45 374L23 367',
    0x1D598: '189 331Q190 304 196 282T207 252T214 244Q239 244 348 292L371 302L382 297Q398 290 415 279T433 265Q442 238 442 166Q442 103 423 45Q416 42 380 29T310 3T244 -26L227 -34Q139 40 73 40Q61 40 48 37T24 30T6 22T-8 14L-13 11Q-14 11 -18 18T-23 26T38 75T102 125Q107 128 146 131H153Q192 131 296 56Q318 40 318 43Q323 48 323 114Q323 157 321 177L319 194Q308 208 291 216T261 225Q239 225 160 185L123 167Q85 205 79 227Q78 230 78 304V377L171 428Q264 479 265 478Q268 478 287 465T334 440T384 427Q423 427 475 463L478 453Q481 446 481 442Q481 439 410 391L339 342H331Q309 345 277 361T222 391T198 406T195 399T191 372T189 331',
    0x1D599: '328 69Q401 102 403 102Q404 102 405 94T406 84Q406 83 318 28L230 -27Q223 -21 206 -5T171 25T132 54L124 60V71Q129 154 129 297V359H43L44 363Q44 365 44 367L45 369L48 372Q51 374 57 378T68 387L90 405H129V553L285 648Q304 641 306 640L260 598V592Q259 589 255 505T249 413V405H353V402Q353 399 328 379L303 360H245V319Q245 150 253 125Q257 115 276 101T311 78T328 69',
    0x1D59A: '444 -31Q444 -29 384 66Q382 66 364 58T309 30T231 -17Q214 -29 212 -29L197 -20Q172 -4 140 11T88 34L68 42Q68 43 73 49T85 67T100 98T113 149T118 221Q118 272 105 332L100 356L58 383L23 365L9 379L76 425Q141 472 144 472Q144 471 183 443L221 414V404Q224 365 224 275V253Q224 159 196 113Q191 104 193 104Q203 104 285 72L308 62L374 89L375 106Q375 266 373 340Q373 364 371 396V424L430 445L491 467Q493 467 499 463T505 457Q505 456 503 442Q488 335 488 187V158L529 81L534 80Q541 80 568 90L598 101Q605 94 602 87L524 27Q445 -32 444 -31',
    0x1D59B: '95 67Q104 80 104 193Q104 261 100 321L98 355L91 363Q56 402 56 421Q56 441 82 472T132 524T159 546Q174 542 175 542Q159 520 159 501Q159 481 205 432L221 415L220 401Q219 394 219 387L288 429Q309 441 325 451T347 465T358 472T365 476L504 415V409Q504 408 505 374T507 318Q507 155 474 91L469 80L343 26Q314 14 281 0T232 -20L216 -27L202 -15Q192 -5 152 28Q141 35 126 45T103 60T95 67ZM386 349Q302 389 287 389Q271 383 253 375L220 361V136Q226 120 256 100T312 68T342 56Q355 56 360 68Q389 134 389 258Q389 310 386 341V349',
    0x1D59C: '90 58T90 59T92 64T97 78T102 105T107 150T109 218Q109 290 103 350V356L83 377Q55 407 55 425Q55 445 138 528Q158 549 162 549L164 548Q165 548 167 548T170 547L175 546L172 540Q168 533 165 523T161 502Q161 479 216 430L229 419V382Q232 382 366 471Q407 445 500 408L511 404V387L512 370L595 420Q678 469 679 469L693 462Q756 431 795 417L815 409L814 380Q812 187 782 96Q774 71 766 62T744 48T684 25T577 -23L557 -32L546 -26Q536 -19 519 -10T481 10T436 31T393 47Q384 50 380 50Q380 52 381 58T384 77T387 104Q391 174 391 256V292L390 333L377 340Q350 357 304 373L294 376L227 355V348Q224 322 224 243Q228 117 232 112L235 108Q238 103 245 95T257 80L281 50Q281 49 227 10T172 -29L159 -13Q133 19 116 36T94 56ZM652 64Q658 64 667 84T685 162T697 303V336L686 341Q653 356 619 367L591 376Q590 376 553 361T514 344T512 324T510 275T508 221Q508 167 510 152T521 126Q537 112 590 88T652 64',
    0x1D59D: '8 -90Q8 -68 13 -63Q13 -56 53 -8T120 63L128 71L129 85Q133 120 134 182Q134 308 131 331T106 365Q100 367 97 369L75 381L35 365L20 377Q20 378 47 397T110 440T161 471L253 413V396Q253 378 254 378L309 422Q364 466 365 466Q365 467 366 466T370 461T376 454Q403 419 426 396L441 380L438 377Q438 376 433 372T420 359T404 344L372 314Q351 320 338 327T310 344T277 364Q261 364 252 316Q251 306 251 235Q251 136 255 129Q257 127 258 124T268 113T298 92Q334 68 335 68Q340 70 349 73T377 84T408 95T421 99Q422 99 422 90L423 82L334 26Q246 -28 243 -28L200 8Q156 43 148 43Q144 43 130 36T99 9T83 -36Q83 -67 121 -89T198 -118L237 -124V-129L238 -133L193 -160Q183 -166 171 -173T152 -184L146 -188Q140 -187 131 -185T98 -173T56 -154T23 -127T8 -90',
    0x1D59E: '280 53Q272 47 246 27T199 -10T176 -27L167 -18Q137 17 107 44L90 60L93 71Q108 130 109 290V331Q109 339 109 344T108 353T107 359T105 364T102 369T97 374T91 381Q60 412 60 432Q60 448 86 479T138 534L164 557Q168 553 180 553Q163 532 163 511Q165 491 186 468Q206 443 231 423V404L232 385L371 477L389 468Q439 441 498 418L512 412V386Q512 360 507 190T500 14Q488 -26 445 -67Q401 -111 355 -148T282 -203T249 -221Q247 -220 230 -210T213 -199T229 -191T269 -172T306 -151Q361 -120 379 14Q391 92 391 182Q391 218 386 305Q384 339 380 341Q363 353 330 366T288 379Q282 379 258 368L230 356V181V141Q230 127 232 120T236 108T251 89T275 59L280 53',
    0x1D59F: '153 371Q141 371 126 365T100 354T78 340L65 331L57 338L50 346L62 356Q133 419 222 471Q274 453 306 422T338 366Q338 356 329 346T283 301L243 264L262 257Q298 246 361 214Q378 154 378 73Q378 33 371 -9T356 -74T345 -104Q340 -106 267 -160L191 -214H177Q60 -214 13 -150Q-7 -122 -7 -115Q-7 -112 19 -77T106 25T241 149Q241 152 227 158T181 173T109 185V190L108 194L158 229Q212 267 223 278T234 306Q234 329 208 350T153 371ZM258 1Q258 42 257 68T254 105T252 118Q235 105 210 85T144 22T102 -45Q102 -79 146 -106T234 -133H238Q248 -128 254 -80Q258 -58 258 1',
    0x1D5A0: '183 181Q183 179 152 91T118 0H28L154 346L280 693Q281 694 333 694H385L511 349Q636 4 638 2Q638 0 584 0H530L464 183H184L183 181ZM324 606Q319 578 292 492T238 332T210 256Q210 254 324 254T438 255L429 281L419 308Q409 336 395 378T365 465T339 551T324 611V606',
    0x1D5A1: '425 363Q438 363 465 353T526 324T585 270T610 192Q610 132 561 78T426 7Q404 2 387 2T240 0H90V694H227Q373 693 396 689Q484 673 533 623T583 517Q583 494 574 473T551 437T520 409T487 388T456 374T433 366L425 363ZM490 516Q490 527 485 539T467 568T423 599T347 621Q340 622 262 623H188V399H261H286Q432 399 478 475Q490 496 490 516ZM514 190Q514 245 462 280T343 322Q336 323 259 323H188V71H274Q365 72 388 77Q445 88 479 121T514 190',
    0x1D5A2: '59 347Q59 440 100 521T218 654T392 705Q473 705 550 680Q577 670 577 667Q576 666 572 642T564 595T559 571Q515 601 479 613T392 626Q300 626 232 549T164 347Q164 231 229 150T397 68Q453 68 489 80T568 120L581 129L582 110Q584 91 585 71T587 46Q580 40 566 31T502 5T396 -11Q296 -11 218 41T99 174T59 347',
    0x1D5A3: '88 0V694H237H258H316Q383 694 425 686T511 648Q578 604 622 525T666 343Q666 190 564 86Q494 18 400 3Q387 1 237 0H88ZM565 341Q565 409 546 463T495 550T429 600T359 621Q348 623 267 623H189V71H267Q272 71 286 71T310 70Q461 70 527 184Q565 251 565 341',
    0x1D5A4: '86 0V691H541V611H366L190 612V397H513V321H190V85H372L554 86V0H86',
    0x1D5A5: '86 0V691H526V611H358L190 612V384H485V308H190V0H86',
    0x1D5A6: '59 346Q59 499 157 601T384 704Q436 704 466 700T541 679Q551 674 560 670T575 664T583 660T588 658T590 656Q590 652 582 605T573 557L564 564Q489 626 392 626Q301 626 233 549T164 347T233 145T392 68Q441 68 506 84V223H388V299H599V38L588 33Q494 -11 393 -11Q296 -11 219 40T100 172T59 346',
    0x1D5A7: '86 0V694H190V399H517V694H621V0H517V323H190V0H86',
    0x1D5A8: '87 0V694H191V0H87',
    0x1D5A9: '181 53Q200 53 215 56T241 66T259 79T272 95T280 109T285 122L287 129V694H388V415V229Q388 135 385 112T369 63Q364 51 355 39T328 12T280 -12T212 -22Q172 -22 130 -12T66 8T43 20L46 42Q50 65 54 88L58 110Q58 111 65 104Q107 53 181 53',
    0x1D5AA: '88 0V694H188V519L189 343L525 694H638L375 419L651 0H541L309 351L188 225V0H88',
    0x1D5AB: '87 0V694H191V79L297 80H451L499 81V0H87',
    0x1D5AC: '92 0V694H228L233 680Q236 675 284 547T382 275T436 106Q446 149 497 292T594 558L640 680L645 694H782V0H689V305L688 606Q688 577 500 78L479 23H392L364 96Q364 97 342 156T296 280T246 418T203 544T186 609V588Q185 568 185 517T185 427T185 305V0H92',
    0x1D5AD: '88 0V694H235L252 659Q261 639 364 428T526 84V694H619V0H472L455 35Q453 39 330 294T185 601L181 611V0H88',
    0x1D5AE: '55 345Q55 504 149 609T361 715Q386 715 406 713Q521 696 600 592T680 344Q680 193 590 86T368 -22Q239 -22 147 84T55 345ZM276 59T368 59T518 146T576 360Q576 473 525 545T401 634Q371 637 362 637Q284 637 222 562T159 360T217 147',
    0x1D5AF: '88 0V694H230Q347 693 370 692T410 686Q487 667 535 611T583 485Q583 409 527 348T379 276Q369 274 279 274H192V0H88ZM486 485Q486 523 471 551T432 593T391 612T357 621Q350 622 268 623H189V347H268Q350 348 357 349Q370 351 383 354T416 368T450 391T475 429T486 485',
    0x1D5B0: '55 345Q55 504 149 609T361 715Q386 715 406 713Q521 696 600 592T680 344Q680 284 665 231T629 143T587 85T551 48L536 35L648 -120L652 -125H531L452 -8L440 -12Q407 -22 369 -22Q239 -22 147 85T55 345ZM579 345Q579 473 517 555T369 637Q279 637 218 554T156 345Q156 223 215 141T368 58Q376 58 382 58T392 58T397 59T401 60T403 61H404Q404 63 360 128T315 194H421L453 150Q485 105 486 105Q490 108 496 113T517 138T545 182T567 247T579 334V345',
    0x1D5B1: '88 0V694H227H259H302Q365 694 399 689T474 663Q528 637 558 595T589 504Q589 482 584 462T569 426T547 396T522 372T495 353T470 338T449 328T434 322L429 320L440 300Q452 280 477 238T523 160L617 1L565 0Q513 0 512 1Q512 2 424 156L337 309H189V0H88ZM492 504Q492 600 367 620Q354 622 271 623H189V385H271Q363 386 388 392Q432 402 462 430T492 504',
    0x1D5B2: '55 514Q55 589 115 652T283 716Q315 716 345 711T396 699T432 685T457 672T467 667Q467 666 459 618T449 568Q383 634 282 634Q214 634 182 600T150 525Q150 507 155 492T172 465T194 446T222 432T247 423T272 416T289 412Q353 396 378 384Q432 358 466 307T500 194Q500 110 438 44T272 -22Q215 -22 159 -5T73 28T44 50Q45 51 49 75T57 122T62 146L65 143Q68 140 74 136T88 125T107 111T131 98T160 85T194 74T232 66T274 63H286Q327 63 366 96T406 182Q406 245 352 280Q329 296 265 310T173 339Q124 363 90 409T55 514',
    0x1D5B3: '36 608V688H644V608H518L392 609V0H288V609L162 608H36',
    0x1D5B4: '87 450V694H191V449Q192 203 193 194Q200 148 220 117T266 72T311 54T347 49Q404 49 446 84T501 178Q505 195 505 218T507 449V694H600V450Q600 414 600 356Q599 198 595 181Q594 178 594 177Q575 89 505 34T345 -22Q258 -22 184 34T89 196Q88 205 87 450',
    0x1D5B5: '14 692Q14 694 68 694H122L146 633Q325 165 339 90Q340 87 341 87Q341 124 530 619L558 694H605Q652 694 652 692Q650 690 523 354T390 10L387 0H279L276 10Q271 18 144 354T14 692',
    0x1D5B6: '115 694Q115 693 156 550T233 266T270 90L271 85Q272 86 272 92Q272 153 405 616L427 694H524L553 590Q672 174 681 95L682 84L684 95Q689 138 728 287T803 563T841 692Q841 694 885 694T929 693Q929 691 829 346L730 0H679L628 1L606 75Q478 524 470 600L469 611L467 600Q458 518 338 101L310 0H213L114 346Q14 691 14 693Q14 694 64 694H115',
    0x1D5B7: '14 0Q16 5 144 184T275 367L153 528Q121 571 88 615T42 674T28 694H150L228 584Q315 463 316 461L326 448L497 694H610L609 692Q606 689 492 528Q440 454 409 410T378 366Q378 365 515 182L652 0H531L326 292Q326 293 299 254T226 146L128 0H14',
    0x1D5B8: '4 693L64 694H125L174 621Q335 378 340 364L341 362Q361 398 395 450L558 694H663L383 277V0H282V278L143 485Q112 531 75 586T21 668L4 693',
    0x1D5B9: '69 617V694H555V643L373 362Q190 81 190 79H234Q244 79 272 79T344 80T419 81H560V0H55V53L237 334Q420 615 420 617Q413 618 387 618Q380 618 334 618T245 617H69',
    0x1D5BA: '236 387Q209 387 184 382T141 370T111 355T91 342T83 337L82 355Q80 373 79 393T77 417Q77 419 81 421Q86 423 91 426Q155 460 227 460H238Q319 460 368 400Q393 371 400 341T408 252Q408 240 408 207T407 152V0H317V39L306 32Q244 -10 159 -10H152Q109 -10 77 22Q38 61 38 126Q38 142 39 146Q55 199 130 223T295 252H314V277Q314 305 313 310Q308 342 287 364T236 387ZM303 186Q124 180 124 126Q124 105 144 86T208 66Q284 66 309 124Q314 137 314 166V186H303',
    0x1D5BB: '303 -11Q280 -11 259 -6T222 6T194 21T176 33T168 38V0H75V694H165V550L166 405Q247 455 336 455Q397 455 439 389T482 226Q482 115 428 52T303 -11ZM390 221Q390 283 361 331T265 379Q214 379 177 342L168 334V118Q203 66 258 66Q316 66 353 106T390 221',
    0x1D5BC: '34 223Q34 327 99 393T245 460Q290 460 301 459Q328 455 354 445T395 427T410 415L396 338L386 344Q377 349 362 357T335 370Q305 381 258 381H252Q182 381 146 315Q126 275 126 224Q126 158 159 113T255 68Q329 68 394 106L408 114L410 93Q411 72 412 52L414 32Q407 27 394 20T338 2T252 -10Q156 -10 95 58T34 223',
    0x1D5BD: '33 224Q33 321 81 388T197 455Q277 455 342 414L351 408V694H441V0H348V44L338 37Q278 -10 198 -10Q177 -10 168 -8Q99 11 62 90Q33 148 33 224ZM348 337Q307 378 263 378Q260 378 256 378T251 379Q239 379 223 374T182 355T142 305T126 220Q126 90 225 67Q231 66 250 66H255Q306 66 342 115L348 124V337',
    0x1D5BE: '28 226Q28 329 91 395T235 461Q258 461 279 456T325 436T368 397T399 332T415 238V219H113V215Q113 163 151 114T248 65Q273 65 298 70T341 82T373 96T396 108L403 113Q403 106 406 76T409 38Q409 34 408 33T393 24Q325 -10 252 -10Q155 -10 92 59T28 226ZM340 289L338 297Q335 305 333 310T327 326T317 343T304 358T286 372T263 381T233 385Q212 385 193 376T162 353T140 325T127 301T123 289H340',
    0x1D5BF: '262 705H267Q300 705 347 694V612L336 616Q303 628 274 628H266Q224 628 199 605Q187 590 184 579T181 541V507V444H287V371H184V0H94V371H27V444H94V492Q94 544 95 550Q102 617 151 661T262 705',
    0x1D5C0: '55 286Q55 357 105 406T224 455Q280 455 323 421L322 423L318 427Q318 428 339 434T396 448T465 455H471L478 416L485 377Q484 377 474 379T445 383T401 385Q397 385 391 385T381 384L362 383L357 387Q358 386 364 375T375 354T384 325T389 287Q389 217 340 168T221 119Q178 119 138 142Q133 145 131 143Q125 131 125 117Q125 82 155 72L227 71Q230 71 251 71T280 71T310 69T343 65T373 57T403 46T428 30T449 7Q471 -26 471 -62V-71Q471 -136 384 -178Q326 -206 250 -206Q159 -206 102 -172T30 -92Q28 -84 28 -68T31 -37T40 -12T52 7T64 21T75 31T82 38Q60 68 60 106Q60 145 80 180L86 189L80 199Q55 240 55 286ZM304 233T304 287T279 362T220 383Q189 383 165 361T140 287Q140 243 161 217T220 191Q253 191 278 212ZM250 -134Q298 -134 331 -122T375 -96T387 -69Q387 -21 306 -7Q288 -5 216 -5Q161 -5 153 -7Q146 -9 139 -13T122 -31T113 -66Q113 -75 113 -80T127 -97T166 -121Q203 -134 250 -134',
    0x1D5C1: '163 395Q223 455 307 455Q417 455 438 354Q442 331 443 164V0H350V157Q349 315 348 320Q334 378 259 378H253Q224 378 204 358Q180 334 173 301T165 209Q165 198 165 172T166 129V0H73V694H163V395',
    0x1D5C2: '67 576V680H171V576H67ZM74 0V444H164V0H74',
    0x1D5C3: '88 576V680H192V576H88ZM31 -126Q40 -126 48 -125T62 -122T73 -117T82 -111T89 -105T94 -99T98 -92L102 -86V444H192V180Q191 -45 191 -70T184 -113Q171 -152 140 -178T63 -205Q34 -205 4 -197T-43 -181T-59 -171T-51 -133T-41 -96L-38 -99Q-34 -102 -28 -106T-13 -115T7 -123T31 -126',
    0x1D5C4: '76 0V694H163V257L340 444H449L286 272L292 263Q296 259 378 138T463 12L471 0H372L309 92Q294 114 277 139T250 179T237 198L228 211L160 139V0H76',
    0x1D5C5: '74 0V694H164V0H74',
    0x1D5C6: '160 392Q223 455 304 455Q359 455 386 436T430 383L437 391Q495 455 584 455Q694 455 715 354Q719 331 720 164V0H627V157Q626 315 625 320Q611 378 536 378H530Q501 378 481 358Q457 334 450 301T442 209Q442 198 442 172T443 129V0H350V157Q349 315 348 320Q334 378 259 378H253Q224 378 204 358Q180 334 173 301T165 209Q165 198 165 172T166 129V0H73V450H160V392',
    0x1D5C7: '160 392Q214 446 283 454Q285 454 292 454T303 455H306Q417 455 438 354Q442 331 443 164V0H350V157Q349 315 348 320Q334 378 259 378H253Q224 378 204 358Q180 334 173 301T165 209Q165 198 165 172T166 129V0H73V450H160V392',
    0x1D5C8: '28 222Q28 323 95 391T244 460Q275 460 281 459Q364 445 417 377T471 219Q471 124 408 57T250 -10Q158 -10 93 57T28 222ZM377 230Q377 277 364 310T328 358T287 379T248 385Q233 385 219 382T186 369T155 342T132 297T122 230Q122 146 159 108T250 69H253Q263 69 274 70T305 81T339 106T365 154T377 230',
    0x1D5C9: '166 404Q194 424 241 439T337 455H341Q410 455 451 370Q483 307 483 222Q483 128 433 59T306 -10Q282 -10 260 -5T222 7T194 21T176 33T168 38V-194H75V444H165V424L166 404ZM390 222Q390 287 354 331T266 376T177 340L168 332V118Q200 66 257 66Q313 66 351 112T390 222',
    0x1D5CA: '33 220Q33 325 87 389T206 454Q286 454 341 406L351 398V455H441V-194H348V41L338 35Q276 -8 198 -11Q171 -11 154 -5Q102 12 68 74T33 220ZM126 220Q126 160 161 113T251 65Q305 65 351 123V298L350 301Q349 304 347 308T342 319T336 331T327 343T315 355T300 365T283 373Q273 375 256 375Q208 375 167 332T126 220',
    0x1D5CB: '171 389Q237 455 320 455H327V373H317Q262 369 220 336T167 248Q165 239 164 119V0H74V450H159V377L171 389',
    0x1D5CC: '33 326Q33 376 60 408T117 450T175 460H190Q245 460 272 454T345 429Q345 428 338 388L331 349Q278 386 188 386H183Q119 386 119 336Q119 307 142 295T210 276T278 256Q360 213 360 130Q360 108 354 88T332 43T281 5T195 -10Q152 -10 111 1T49 22T28 35Q29 36 32 56T39 95T43 115T51 110T72 99T102 85T143 73T193 68Q274 68 274 123Q274 152 248 167Q234 178 187 186T115 207Q66 229 46 273Q33 298 33 326',
    0x1D5CD: '333 27Q333 24 314 16T257 -1T184 -10H172Q146 -10 128 14T105 58T99 91Q95 113 95 251V371H18V444H98V571H182V444H316V371H182V253Q183 128 189 104Q199 68 234 68Q277 72 314 98Q315 93 323 61T333 27',
    0x1D5CE: '353 39Q352 38 344 34T331 27T315 19T295 11T270 3T241 -3T207 -8T168 -10H162Q148 -10 137 -8T111 2T87 30T74 81Q73 89 73 268V444H166V268L167 92Q171 79 174 74T192 64T238 59Q317 59 344 116Q349 128 349 148T350 291V444H443V0H353V39',
    0x1D5CF: '178 0Q15 441 14 442Q14 444 60 444Q107 444 107 442Q108 441 136 364T196 194T232 67Q233 98 280 234T356 442Q356 444 401 444T446 442L282 0H178',
    0x1D5D0: '14 444H105L108 434Q192 160 200 74V65L201 75Q206 144 282 399L296 444H381L399 381Q480 112 480 69L481 70Q481 113 562 386L580 444H668L534 1L483 0H432L429 10Q343 294 338 367L337 377Q336 375 336 370Q336 340 313 250T269 88T245 11L242 0H195L148 1L14 444',
    0x1D5D1: '187 229L6 444H107L227 294L344 444H393L442 443Q439 437 299 268L267 229L460 0H359L294 88Q280 107 262 131T236 166L227 177L100 0H0Q1 1 47 58T140 171T187 229',
    0x1D5D2: '113 -204Q83 -204 63 -200L43 -197Q43 -196 40 -157T36 -117L48 -121Q79 -133 114 -133Q124 -133 130 -132T145 -121T163 -94Q169 -82 184 -42T200 1L188 29Q176 57 152 115T107 223T62 330T26 416L14 443Q14 444 61 444H109L122 411Q230 155 236 75L237 65V74Q245 161 348 424L356 444H401Q446 444 446 443L396 313Q345 183 293 49T236 -93Q191 -204 123 -204H113',
    0x1D5D3: '42 370V444H400V395L156 76L279 77H402V0H28V51L273 371L157 370H42',
    0x1D5D4: '110 0H86Q42 0 42 27Q42 37 148 350T258 667Q269 687 291 692Q295 694 366 694H399Q432 694 448 689T474 667Q477 663 583 350T690 27Q690 0 642 0H617H592Q582 0 575 1T561 2T549 6T541 11T533 18T527 26T522 37T517 49T512 64T506 81L490 130H225Q225 128 208 79T189 27Q185 19 180 14T170 7T156 3T143 1T127 0T110 0ZM439 279Q359 524 359 547L357 555L355 543Q347 503 270 263L259 231H357Q455 231 455 232L439 279',
    0x1D5D5: '119 1Q98 5 92 28V667Q98 686 118 693Q121 694 272 694H289H346Q439 694 500 681T600 625Q640 580 640 513Q640 451 601 414T504 364L518 361Q568 351 602 329T649 280T666 235T671 197Q671 172 665 147T642 91T586 37T488 5Q456 1 282 1H119ZM489 509Q489 532 479 548T450 573T421 585T394 591Q387 592 315 593H247V404H298H325Q432 404 466 444Q489 470 489 509ZM517 194Q517 235 502 261T458 299T407 313T353 317H329H322H247V101H319H357Q387 101 407 103T452 111T492 133T514 171Q516 176 517 194',
    0x1D5D6: '423 -11Q339 -11 275 9T171 62T106 143T71 240T61 347Q61 450 93 527Q157 664 313 694Q357 704 416 704Q479 704 517 699T608 676Q634 667 635 660Q635 653 624 592L612 528L609 524Q604 521 601 521Q595 521 583 531T555 555T505 578T428 589H424Q298 589 250 494Q224 438 224 347Q224 292 233 251T265 175T329 122T432 104Q488 104 524 115T604 158Q607 160 610 162T615 165T619 168L621 170Q625 172 630 170T637 163Q638 160 642 109T647 54Q646 49 625 37T568 11T499 -7Q463 -11 423 -11',
    0x1D5D7: '119 1Q98 5 92 28V667Q98 686 118 693H124Q131 693 142 693T168 694T200 694T237 694H296Q416 694 450 692T525 677Q732 617 732 342Q732 169 644 81Q593 32 528 16T372 0Q356 0 324 0T276 1H119ZM573 349Q573 387 571 413T559 473T532 527T482 567T403 591Q395 592 320 593H250V101H321Q418 102 456 114Q553 144 569 263Q573 303 573 349',
    0x1D5D8: '277 122Q280 122 380 123T544 125Q552 125 557 125T565 124T569 124Q595 115 595 75V62V47Q595 9 569 2Q564 0 341 0L119 1Q99 7 92 28V664Q98 683 118 690Q121 691 335 691T554 689Q580 682 580 644V632V618Q580 582 554 573Q553 573 551 573T542 572T527 572Q464 572 364 573T260 575H253V412H385H459Q524 412 536 404T549 357Q549 341 549 334T542 318T523 305Q518 303 385 303H253V122H277',
    0x1D5D9: '512 572Q451 572 356 573T258 575H253V400H370H431Q494 400 506 392T518 345Q518 307 507 299T437 291H370H253V161Q253 141 253 113T254 75Q254 23 245 12T195 0H170L119 1Q99 7 92 28V664Q98 683 118 690Q121 691 327 691T538 689Q564 682 564 644V632V618Q564 582 538 573Q537 573 535 573T526 572T512 572',
    0x1D5DA: '61 347Q61 405 70 454T105 550T171 631T276 685T426 705Q483 705 537 693T620 668T650 646Q650 645 649 637T645 612T639 578L627 514L624 510Q620 507 615 507T597 520T566 548T512 577T430 590Q223 590 223 347T431 104Q478 104 506 112Q508 112 508 164V215H471L434 216L428 222L427 268Q427 315 429 318Q432 323 444 323T544 324H652Q655 320 659 317V45L656 43Q654 39 624 27T536 2T424 -11Q366 -11 317 -2T219 33T137 97T82 200T61 347',
    0x1D5DB: '92 667Q101 694 143 694H172H198Q244 694 251 669Q253 663 253 539V415H540V539Q540 558 540 585T539 621Q539 673 550 683T611 694H621H646Q671 694 683 690T700 669Q702 663 702 347T700 25Q696 9 684 5T646 0H621H606Q560 0 550 11T539 76Q539 85 539 116T540 169V306H253V169Q253 147 253 116T254 75Q254 23 245 12T194 0H170L119 1Q99 7 92 28V667',
    0x1D5DC: '85 667Q94 694 136 694H165H191Q237 694 244 669Q246 663 246 347T244 25Q235 0 192 0H163L112 1Q92 7 85 28V667',
    0x1D5DD: '236 -22Q190 -22 144 -11T72 12T46 29Q63 147 69 153Q80 164 92 146Q124 91 191 91Q222 91 242 102T267 134Q268 139 268 402Q268 663 270 669Q275 687 294 692Q298 694 347 694H367Q393 694 406 690T425 669Q427 663 427 399Q427 132 426 125Q421 87 404 58T366 15T318 -9T273 -20T236 -22',
    0x1D5DE: '92 667Q101 694 139 694H163H186Q225 694 234 671Q236 663 236 529L237 392L533 682Q550 694 590 694H623H681Q695 680 695 672Q695 670 693 664Q688 657 561 533L431 405L698 33Q701 28 701 23Q701 7 683 0H626H604Q571 0 564 2T545 13Q544 14 530 33T489 90T437 162L332 307Q331 307 284 260L236 214V122V65Q236 32 231 19T210 2Q205 0 161 0L119 1Q99 7 92 28V667',
    0x1D5DF: '92 667Q98 684 109 689T142 694H172H198Q244 694 251 669Q253 663 253 389V116L278 117Q410 119 490 119H495Q511 119 517 115T534 93V63V48Q534 9 508 2Q503 0 310 0L119 1Q99 7 92 28V667',
    0x1D5E0: '92 667Q98 684 109 689T146 695Q152 695 167 695T192 694Q200 694 214 694T234 695Q291 695 305 664Q313 651 400 419T487 165Q487 162 488 162T489 165Q489 187 574 413T671 664Q679 680 695 688Q708 694 785 694H828Q855 694 867 689T884 669Q886 663 886 347T884 25Q876 0 832 0H817H802Q758 0 750 25Q748 31 748 293V555L746 544Q737 509 692 386T606 160T564 52Q548 22 502 22H487H472Q423 22 410 52Q407 59 367 160T283 385T231 546L230 548Q229 548 229 293Q229 31 227 25Q222 9 211 5T176 0H158L119 1Q99 7 92 28V667',
    0x1D5E1: '92 667Q98 684 109 689T146 694H185Q273 694 279 692Q301 689 315 669Q322 660 419 453L554 163L562 143Q564 143 564 401Q564 663 566 669Q574 694 618 694H633H648Q692 694 700 669Q702 663 702 347T700 25Q696 10 683 5T642 0H596H551Q520 0 505 4T478 25Q471 34 374 241L239 532Q231 550 231 552L229 479Q229 440 229 293Q229 31 227 25Q222 9 211 5T176 0H158L119 1Q99 7 92 28V667',
    0x1D5E2: '362 715Q364 715 376 715T394 716H400Q542 716 626 643T727 426Q731 395 731 342Q731 271 722 225Q674 -22 396 -22Q320 -22 259 -3T148 68T77 201Q62 257 62 342Q62 447 86 522T173 649Q245 707 362 715ZM568 433Q551 623 396 623Q383 623 370 622T333 612T292 591T257 550T233 485Q223 442 223 350Q223 276 232 227T267 137Q309 74 397 74Q433 74 461 85T507 113T537 156T556 205T566 260T569 310T570 357Q570 409 568 433',
    0x1D5E3: '641 470Q641 426 630 391T603 334T561 295T513 271T459 259T408 254T361 253H350H337H253V142Q253 125 253 100T254 67Q254 32 249 19T227 2Q222 0 170 0L119 1Q99 7 92 28V667Q98 686 118 693Q121 694 271 694Q428 693 462 688Q641 656 641 470ZM487 467Q487 495 485 510T474 546T442 578T382 592Q375 593 310 593H250V347H309H339Q364 347 380 348T418 354T451 368T474 395T486 438Q487 444 487 467',
    0x1D5E4: '450 -20Q444 -20 429 -21T396 -22Q320 -22 259 -3T148 68T77 201Q62 257 62 342Q62 447 86 522T173 649Q245 707 362 715Q364 715 376 715T394 716Q732 716 732 340Q732 268 719 210T686 120T647 68T615 39T601 29T638 -22T676 -73Q679 -78 679 -83Q679 -98 661 -106H593Q526 -106 521 -104Q514 -103 507 -97T496 -84T477 -55L454 -19L450 -20ZM554 509Q516 622 391 622Q294 622 250 535Q220 475 220 345Q220 299 222 266T234 198T258 140T299 99T363 74Q378 71 393 71H395L381 92Q367 114 353 136T338 161Q336 165 336 170Q336 186 352 193L361 194Q370 194 384 194T412 194H452Q457 194 460 194T466 194T471 192T476 191T480 188T483 185T487 180T492 174T497 167T504 158L526 129Q532 127 552 175Q573 231 573 348Q573 455 554 509',
    0x1D5E5: '654 24Q654 9 644 5T612 0H577L521 1Q509 5 503 13Q498 20 421 160L343 304H250V168Q250 147 250 118T251 78Q251 24 242 12T192 0H168L119 1Q99 7 92 28V667Q98 686 118 693H124Q131 693 141 693T165 694T195 694T229 694T280 694T332 695Q389 695 428 691T510 675T582 637T627 569Q641 532 641 493Q641 377 537 331L497 317L493 316L571 177Q653 28 654 24ZM487 472T487 492T485 525T476 553T450 577T404 591Q398 592 322 593H250V391H321Q327 391 353 391T385 392T412 395T438 401T457 412T474 430T483 456',
    0x1D5E6: '61 503Q61 547 72 583T110 650T186 698T305 716Q405 716 496 671Q513 664 514 657Q514 656 513 648T509 623T503 589L491 525L488 521Q484 518 479 518H475L461 532Q430 565 395 581T305 598Q201 598 201 523Q201 480 240 462T345 431T443 394Q549 324 549 204Q549 160 538 123T502 51T427 -2T308 -22Q180 -22 69 41Q50 52 49 57Q49 58 50 66T54 91T60 125L72 189L75 193Q80 196 84 196Q87 196 104 182T145 149T212 117T304 102Q408 102 408 188Q408 215 396 234T362 263T319 278T267 290T219 302Q149 324 105 380T61 503',
    0x1D5E7: '67 687Q70 688 366 688Q661 688 666 686Q692 680 692 641V629V615Q692 579 666 570H660Q655 569 648 569Q645 569 624 569T581 570Q505 570 475 572H447V302Q447 31 445 25Q436 0 393 0H364L313 1Q293 7 286 28L285 300V572H257Q227 570 151 570Q130 570 109 570T84 569Q77 569 72 570H66Q48 577 44 588T40 631L41 661Q47 680 67 687',
    0x1D5E8: '92 667Q101 694 143 694H172H200Q242 694 251 671Q253 663 253 430Q254 189 255 185Q262 134 288 107T384 79Q498 79 516 168Q520 191 521 431Q521 663 523 671Q532 694 572 694H596H618Q639 694 648 692T665 679Q671 672 671 653Q672 632 672 555V432Q671 200 670 190Q652 79 581 29T383 -22Q137 -22 98 166Q92 195 92 303V667',
    0x1D5E9: '27 667Q27 683 39 688T75 694H101Q155 694 159 692Q182 687 194 665Q202 652 283 419T374 142Q376 165 473 445Q552 664 553 666Q568 694 618 694H639H658Q681 694 693 689T705 667Q705 660 592 347Q481 32 477 28Q466 7 441 1H292Q266 7 255 28Q251 32 140 347Q27 660 27 667',
    0x1D5EA: '994 694Q1012 683 1014 668Q1014 661 977 519T896 217T845 26Q831 0 783 0H747H711Q685 0 672 5T649 26Q644 36 583 272T517 548Q516 552 516 551Q503 479 437 227Q389 37 383 26Q367 0 323 0H288H254Q207 0 193 26Q191 32 108 346T24 665Q24 685 44 693Q47 694 98 694H115Q152 694 168 668Q174 657 235 417T297 144Q297 134 300 153Q307 204 362 421T427 668Q441 694 488 694H523Q586 694 597 688Q612 683 620 661T651 549Q664 496 673 462Q744 194 750 146V140Q767 223 800 354T857 576T883 668Q897 694 938 694H958H994',
    0x1D5EB: '52 1Q37 11 37 23Q37 26 39 32Q39 34 158 202L275 369Q275 370 221 441T112 586T55 663Q53 669 53 672Q53 687 68 693H72Q77 693 84 693T99 694T118 694T139 694H176Q203 694 212 692T230 682Q231 681 239 669T265 634T296 591L358 504L418 591Q481 682 486 686Q491 691 499 692Q505 694 569 694H632Q650 685 650 672Q650 667 646 660Q643 654 592 582T491 440T441 369T566 201T693 29Q694 27 694 23Q694 11 677 0H607L537 1Q523 6 519 10T437 131Q422 153 411 170T390 200T375 222T365 237T359 245L357 247L348 232Q339 218 319 188T283 131Q222 37 211 22T186 1H52',
    0x1D5EC: '635 694H668Q688 694 698 690T708 670Q708 664 704 658L446 278L445 152V27Q442 20 440 17T433 9T419 1L368 0H339Q316 0 305 5T288 26Q286 31 286 154V278L157 468Q135 500 101 550Q43 635 34 650T24 671Q24 686 39 693Q42 694 105 694H122H132Q163 694 180 689T214 666Q225 654 336 485Q373 425 373 420L374 418Q375 419 375 421Q378 432 418 493T496 609T536 667Q543 676 551 681T572 689T591 693T615 694T635 694',
    0x1D5ED: '411 584Q243 581 131 581Q122 581 116 581T106 582T102 582Q84 589 80 600T76 640L77 667Q83 686 103 693Q106 694 343 694Q579 694 584 692Q592 691 599 684T609 668Q610 665 610 646Q610 614 608 608Q605 603 434 361L261 116Q340 117 402 118T490 119T533 120T560 120H572Q605 120 614 95Q616 89 616 60V46Q616 9 590 2Q585 0 339 0Q92 0 87 2Q79 3 72 10T62 26Q61 29 61 49Q61 84 63 90Q65 94 152 217T325 461T411 584',
    0x1D5EE: '255 394Q218 394 186 383T138 358T109 333T94 321H91Q88 321 86 322T83 325T80 331T79 339T78 349T77 362T75 377Q72 410 72 420Q72 423 72 425T73 429T74 431T77 433T80 435T85 437Q166 475 262 475Q360 475 413 440Q462 406 471 341Q472 332 472 181Q472 155 472 119T473 73Q473 20 462 10T398 0H380Q349 0 337 8T324 48V65Q298 30 257 10T172 -11Q109 -11 70 37T31 145Q31 276 307 289H321V309Q321 337 318 352T300 381T255 394ZM176 146Q176 116 190 97T231 77Q251 77 266 85Q322 110 322 185Q322 189 322 192T322 198L321 201V234L308 232Q176 220 176 146',
    0x1D5EF: '54 667Q63 694 102 694H127H151Q190 694 199 671Q201 663 201 544L202 422L211 428Q270 468 355 468Q523 468 523 231Q523 -10 321 -10Q286 -10 261 -2T204 33Q197 11 187 6T142 0H126L81 1Q61 7 54 28V667ZM372 230Q372 317 355 349T280 382Q251 382 204 356V107Q235 76 274 76Q301 76 320 84T349 105T364 139T371 180T372 230',
    0x1D5F0: '188 233Q188 199 190 177T200 131T225 95T271 83H281Q356 83 421 130Q433 138 434 139Q446 141 448 131Q449 128 453 84T457 36Q455 30 432 20T364 -1T273 -11Q37 -11 37 232Q37 456 244 474Q246 474 257 474T276 475Q349 475 400 455Q443 436 448 431L451 425Q451 419 443 377Q442 372 441 366T439 356T438 348T436 340T435 334T433 330T431 327T429 325T426 324Q420 324 406 336Q376 362 350 372T281 382Q254 382 236 373T208 352T194 317T189 278T188 233',
    0x1D5F1: '225 -11Q192 -11 164 -3T104 29T55 102T37 227Q37 321 63 376Q111 469 223 469Q292 469 349 433L359 426V546Q359 564 359 589T358 623Q358 675 369 684T433 694H451Q497 694 505 669Q507 663 507 347T505 25Q500 9 487 5T450 0H432H413Q356 0 356 36V40Q298 -11 225 -11ZM356 359Q326 382 286 382Q239 382 215 358Q189 330 189 256V229V203V195Q189 102 231 86Q251 76 274 76Q318 76 356 114V359',
    0x1D5F2: '30 231Q30 301 51 351T107 426T181 463T262 474H268Q293 474 312 472T366 459T422 427T462 363T480 260Q480 230 466 222T405 213Q395 213 364 213T311 214H173V211Q173 183 183 148T216 96Q244 76 287 76Q319 76 350 85T399 104T433 125T451 136Q463 138 465 128Q466 125 469 84T473 39Q471 29 423 13T330 -9Q321 -10 286 -10Q213 -10 161 11T81 68T42 144T30 231ZM353 292Q350 324 342 344T320 373T294 384T264 387Q191 387 175 286V282H353V292',
    0x1D5F3: '67 458H78V502V509Q78 538 79 556T90 604T117 651T169 685T253 704Q254 704 258 704T265 705T275 705T287 704Q364 704 381 687V638V607Q381 591 378 585T366 579Q362 581 351 591T323 610T282 618Q238 618 226 587Q224 581 223 519V458H253Q288 458 298 453Q315 441 315 415Q315 399 312 390T299 377T282 372T259 371H255H226V201Q226 31 224 25Q215 0 174 0H150L106 1Q86 7 79 28L78 199V371H69Q29 371 29 412Q29 425 30 431T40 447T67 458',
    0x1D5F4: '63 108Q63 142 83 176L76 184Q35 227 35 300Q35 469 243 469Q330 469 385 437L397 443Q451 469 508 469Q515 468 517 466T522 456T528 425Q534 392 534 386Q532 379 523 377Q520 377 509 381T477 390T431 398L425 399Q453 362 453 297Q453 268 445 242T417 189T351 146T242 130Q169 130 119 153Q117 141 117 136Q117 95 155 83Q161 81 252 81Q354 80 362 79Q437 71 475 48T526 -24Q532 -42 532 -65Q532 -116 489 -156T341 -204Q323 -206 274 -206H256Q39 -206 18 -84Q17 -79 17 -68Q17 15 82 42L76 53Q63 79 63 108ZM310 300Q310 359 298 377T238 396Q217 396 204 390T186 368T179 339T178 300Q178 245 189 224T244 203T299 224T310 300ZM414 -66Q414 -55 411 -47T401 -32T387 -21T368 -14T346 -10T322 -7T297 -6T271 -6T246 -6H193Q163 -6 154 -10T140 -30Q135 -45 135 -62Q135 -134 274 -134Q414 -134 414 -66',
    0x1D5F5: '53 667Q62 694 101 694H126H148Q191 694 198 669Q200 663 200 526V390Q263 469 361 469Q390 469 412 465T456 449T491 413T507 351Q508 342 508 185Q508 31 506 25Q498 0 450 0H432H413Q368 0 359 23Q357 31 357 186Q356 345 355 350Q349 369 336 376Q324 381 301 381H298Q269 381 242 362Q217 342 210 316T202 239Q202 229 202 202T203 157V82Q203 24 195 12T146 0H125L80 1Q60 7 53 28V667',
    0x1D5F6: '72 574Q55 583 51 591T46 619V636L47 670L48 673Q50 676 52 678T56 684T63 690T73 694H81Q89 694 102 694T129 695H181Q193 687 196 685T203 676T207 661T208 634Q208 603 204 593T181 574H72ZM54 431Q63 458 102 458H127H149Q192 458 199 433Q201 427 201 229T199 25Q190 0 149 0H125L81 1Q61 7 54 28V431',
    0x1D5F7: '70 634V648Q70 686 96 693Q100 695 151 695H176Q201 695 213 691T230 670Q232 665 232 634V620Q232 582 206 575Q202 573 151 573H126Q101 573 89 577T72 598Q70 603 70 634ZM-41 -84Q-1 -105 28 -105Q67 -105 78 -85Q83 -77 83 -48T84 180Q84 427 86 433Q93 458 136 458H158H180Q201 458 209 456T225 443Q230 436 231 418Q232 397 232 313V183V124V40Q232 -55 228 -87T203 -147Q166 -205 78 -205Q31 -205 -20 -189T-71 -159Q-71 -156 -62 -124T-52 -89Q-49 -84 -41 -84',
    0x1D5F8: '496 23Q496 9 487 5T457 0H427H398Q367 0 354 11Q352 12 288 99L226 183L191 150V90V54Q191 30 186 18T165 2Q160 0 124 0L90 1Q70 7 63 28V667Q72 694 108 694H128H146Q183 694 192 671Q194 663 194 496L195 325L254 383Q266 394 281 409T301 429T316 441T329 450T341 455T357 458T376 458H409H436Q461 458 470 454T480 437Q480 430 477 427T445 395Q417 368 396 347L319 271Q319 270 358 217T442 103T494 32Q496 30 496 23',
    0x1D5F9: '54 667Q63 694 102 694H127H149Q192 694 199 669Q201 663 201 347T199 25Q190 0 149 0H125L81 1Q61 7 54 28V667',
    0x1D5FA: '197 386Q256 468 366 468Q404 468 430 461T471 438T491 413T503 385Q563 469 666 469Q731 469 769 446T814 350Q815 343 815 185Q815 31 813 25Q808 9 796 5T758 0H737L692 1Q672 7 665 28L664 186V206V290Q664 349 655 365T610 381Q581 381 560 370T529 341T515 311T510 291Q509 286 509 157V82Q509 24 501 12T452 0H431L386 1Q366 7 359 28L358 186V206V290Q358 349 349 365T304 381Q275 381 254 370T223 341T209 311T204 291Q203 286 203 157V82Q203 24 195 12T146 0H125L80 1Q60 7 53 28V437Q58 453 80 464H122H142Q167 464 178 460T195 439Q197 434 197 409V386',
    0x1D5FB: '197 386Q264 468 350 468Q375 468 390 467T429 460T466 443T492 408T507 351Q508 342 508 185Q508 31 506 25Q498 0 450 0H432H413Q368 0 359 23Q357 31 357 186Q356 345 355 350Q349 369 336 376Q324 381 301 381H298Q269 381 242 362Q217 342 210 316T202 239Q202 229 202 202T203 157V82Q203 24 195 12T146 0H125L80 1Q60 7 53 28V437Q58 453 80 464H122H142Q167 464 178 460T195 439Q197 434 197 409V386',
    0x1D5FC: '274 -11Q32 -11 32 225Q32 346 85 406T249 474H266H271Q302 474 325 471T385 458T451 419T498 346Q518 300 518 225Q518 -11 274 -11ZM367 233Q367 322 350 354T270 387Q240 387 222 377T195 344T184 298T182 233Q182 151 198 117T275 83H282Q318 83 339 104Q355 119 361 146T367 233',
    0x1D5FD: '125 458H139Q174 458 185 452T202 420L211 426Q245 448 288 458T354 469Q356 469 361 469T369 468Q443 468 481 412Q523 355 523 223Q523 164 509 120T473 51T423 12T371 -7T323 -11Q260 -11 204 33V-65Q204 -80 204 -102T205 -131Q205 -162 200 -175T178 -192Q173 -194 126 -194L81 -193Q61 -187 54 -166V431Q58 447 81 458H125ZM372 230Q372 376 282 376Q247 376 204 352V107L208 103Q213 99 218 95T232 87T251 79T274 76Q323 76 349 116Q372 153 372 230',
    0x1D5FE: '226 -11Q37 -11 37 236Q37 294 51 338T86 407T135 445T186 464T233 469H235Q300 469 349 422L359 413V425Q359 452 376 464Q384 469 433 469H455Q498 469 505 444Q507 438 507 137Q507 -163 505 -169Q500 -185 487 -189T450 -194H432H413Q367 -194 358 -171Q356 -163 356 -63V40L348 33Q296 -11 231 -11H226ZM281 375Q188 375 188 228Q188 77 275 77Q322 77 359 120V328Q338 357 324 366T281 375',
    0x1D5FF: '54 437Q58 453 81 464H122H147Q186 464 194 439Q196 434 196 405V377L203 387Q245 456 324 468Q325 468 331 468T340 469Q347 469 356 462V360Q350 355 346 354T339 353T326 353T300 347Q260 337 234 311T202 252Q201 247 201 138Q201 122 201 98T202 66Q202 33 197 20T175 2Q170 0 125 0L81 1Q61 7 54 28V437',
    0x1D600: '37 328Q37 392 75 433T203 474Q254 474 265 473Q319 465 370 442Q378 439 380 432Q380 426 372 384Q364 336 359 333Q358 331 355 331Q348 331 337 341Q282 388 216 388H208Q190 388 180 387T161 377T151 351Q151 333 164 323T224 306L267 297Q314 285 355 246T396 144Q396 17 282 -5Q260 -10 218 -10Q170 -10 124 2T55 26T30 44Q30 48 39 99T49 153Q52 159 60 159Q66 159 70 153Q100 120 133 101T218 82Q231 82 238 83T258 87T277 101T283 126Q283 149 260 160T200 176T153 186Q109 201 73 236T37 328',
    0x1D601: '225 267Q225 202 226 169T232 115T244 88T265 82Q295 84 318 100T345 116Q352 116 354 110T364 77Q373 46 373 43Q373 28 312 9T190 -10Q160 -10 139 1T107 29T89 77T82 136T80 210V258V371H66H59Q39 371 27 386Q20 394 20 417Q21 432 23 437Q35 458 60 458H65H83V510L84 562Q93 589 131 589H154H174Q216 589 223 564Q225 558 225 508V458H274Q330 458 338 453Q355 441 355 415Q355 388 338 376Q330 371 274 371H225V267',
    0x1D602: '53 431Q62 459 100 459Q105 459 114 459T127 458H152Q192 458 201 435Q203 427 203 262Q204 86 208 77Q209 74 216 71Q227 66 258 66H264Q334 66 354 140L356 150L357 290Q357 427 359 435Q365 449 377 453T412 458H432H450Q498 458 506 433Q508 427 508 229T506 25Q498 0 451 0H434H418Q386 0 374 7T360 43V58L352 49Q298 -11 199 -11Q135 -9 101 11T56 80Q52 100 52 273L53 431',
    0x1D603: '26 429T26 435T32 448T44 456Q48 458 85 458H99Q145 458 161 431Q162 429 207 285L251 145L294 284Q333 410 341 430Q351 451 374 456Q379 458 420 458H430Q450 458 457 456T471 443Q473 437 473 435Q473 426 443 325T381 126L350 28Q339 7 316 2Q312 0 250 0Q187 0 183 2Q160 7 149 28L136 68Q124 109 106 166T70 283T39 385',
    0x1D604: '699 458Q717 447 719 432Q719 426 666 230T610 27Q602 10 588 5T548 0H512H482Q431 0 420 17T384 135Q356 241 352 298V308L351 295Q348 251 322 145T290 28Q279 0 233 0H212H191Q146 0 133 27Q130 33 77 229T24 430Q24 449 44 457Q47 458 79 458Q122 458 126 456Q154 450 163 419L233 153Q241 187 272 304T307 431Q318 458 368 458Q394 458 398 456Q421 451 430 431Q434 423 509 147L547 286Q582 416 588 429Q600 454 624 457Q632 458 647 458H663H699',
    0x1D605: '92 0Q87 0 77 0T62 -1Q24 -1 24 22Q24 29 33 41T106 136Q185 237 184 238Q184 239 147 284T73 376T33 427Q31 430 31 436Q31 451 45 457Q48 458 96 458H122Q152 458 163 450T208 394L247 345L282 394Q288 403 297 416T309 434T319 444T328 452T338 455T352 458T372 458H393H440Q457 449 457 435Q457 428 450 419T379 328Q308 239 308 237L389 137Q409 112 436 79Q475 31 475 23Q475 -1 436 -1Q432 -1 422 -1T407 0Q360 0 352 3Q343 6 336 16T291 83L247 151L245 148Q243 145 239 139T229 124T218 106T204 84Q167 24 160 15T141 1L92 0',
    0x1D606: '454 458Q473 446 473 430Q473 426 394 184L311 -68Q291 -119 245 -162T123 -205Q51 -205 46 -190Q44 -187 40 -142T36 -92Q36 -90 36 -88L37 -87Q41 -80 46 -80Q48 -80 73 -92T126 -105Q146 -105 161 -98T185 -76T197 -53T206 -28L215 0L122 212Q29 427 29 435Q29 448 46 457Q49 458 91 458Q93 458 106 458T125 457T140 454T157 446T170 431Q183 410 224 305T266 158Q266 152 266 151Q267 151 268 163Q271 206 302 310T342 432Q354 458 398 458H418H454',
    0x1D607: '268 376Q250 376 180 375T92 374Q69 374 63 380Q46 390 46 419Q46 428 49 437Q57 451 73 457Q76 458 242 458T413 456Q420 455 427 448Q439 438 439 413Q439 392 433 385Q432 383 318 236T204 88Q235 88 306 89T395 90H399Q408 90 414 89T427 84T438 70T442 45Q442 9 416 2Q411 0 236 0H136Q73 0 62 1T41 12Q31 23 31 47Q31 68 36 77Q37 78 51 97T96 155T153 228L268 376',
    0x1D608: '28 0L429 694H533L585 350Q596 275 610 182T632 46L638 3V0H530L528 18Q527 25 515 103T503 183H223L135 29L118 1L73 0H28ZM492 254Q492 256 473 398T454 589V610Q433 552 290 301L264 255L378 254H492',
    0x1D609: '501 363Q557 355 605 316T653 222Q653 148 586 85T403 2Q394 1 240 0Q90 0 90 1L100 46Q109 90 128 177T164 348L238 694H375Q518 693 546 688Q614 674 655 635T696 544Q696 490 648 441T516 368L501 363ZM601 530Q601 568 566 590T479 621Q472 622 394 623H320L297 513Q292 489 286 459T276 415L273 401V399H339H372Q504 399 571 466Q601 498 601 530ZM257 322Q256 320 230 197T203 73Q203 71 289 71Q379 72 387 73Q459 84 507 122T556 210Q556 255 519 283T428 320Q415 322 336 323Q257 323 257 322',
    0x1D60A: '124 266Q124 372 179 473T333 639T544 705Q592 705 635 697T698 679L718 670Q719 669 701 621T681 572L676 576Q670 580 661 586T641 598T614 611T583 620Q558 625 526 625Q406 625 318 516T230 276Q230 238 236 212Q251 148 294 108T412 68Q469 68 508 80T598 123Q608 129 608 128Q606 109 603 87L598 45L573 33Q521 7 486 -1T394 -10Q358 -10 346 -8Q260 5 202 62Q124 145 124 266',
    0x1D60B: '162 348L236 694H385Q535 693 543 692Q600 682 641 654T705 586T737 506T747 425Q747 296 672 187Q625 114 548 62T384 1Q376 0 262 0Q88 0 88 1L98 46Q107 90 126 177T162 348ZM622 533Q575 624 443 624Q434 624 419 624T399 623H321L263 348Q249 283 234 213T212 107L204 72Q204 71 289 71Q374 72 386 74Q501 94 573 193T646 422Q646 487 622 533',
    0x1D60C: '86 2Q88 4 160 346T233 689Q233 691 461 691Q688 691 688 689Q685 686 671 611H495L320 612L319 609Q319 607 297 501L274 397H436Q597 397 597 396L596 391Q595 386 593 376T589 358L581 322L420 321Q258 321 258 320Q209 89 208 87Q208 85 390 85Q417 85 460 85T518 86L572 85Q556 8 554 2V0H86V2',
    0x1D60D: '86 2Q88 4 160 346T233 689Q233 691 453 691T673 689Q670 686 656 611H488L320 612Q314 579 302 523T281 427T272 385Q272 384 419 384H567L551 308H255L223 156Q216 124 207 82T194 20L190 2Q190 0 138 0H86V2',
    0x1D60E: '125 267Q125 375 182 476T337 641T544 705Q598 705 644 693T710 669T730 655L712 609L693 560L692 557L681 567Q618 626 526 626Q447 626 378 573T269 440T229 277Q229 185 276 127T406 68Q422 68 451 71T502 78T524 84L526 93Q528 102 532 119T539 153L553 222Q553 223 495 223Q436 223 436 224Q436 230 444 262L452 299H662V296Q661 290 635 166T607 40Q606 37 576 25T492 1T391 -11Q272 -11 199 66T125 267',
    0x1D60F: '517 2Q518 3 551 161T585 322Q586 323 557 323T422 323H259L190 0H138Q86 0 86 1L96 46Q105 90 124 177T160 348L234 694H337V691Q336 690 306 545T275 399H602L603 403Q603 407 634 551L665 694H768V691Q768 690 695 348T621 2V0H517V2',
    0x1D610: '161 348L235 694H338V691Q338 690 265 348T191 2V0H139Q87 0 87 1L96 46Q106 90 125 177T161 348',
    0x1D611: '377 424L435 694H535V691Q534 685 476 412T416 135Q401 74 350 26T210 -22Q165 -22 124 -11T65 9T46 21L54 41Q62 61 70 83T81 109Q82 111 85 106Q86 105 87 103Q93 94 103 84T135 64T185 53Q238 53 272 76T317 142Q317 145 325 182T348 289T377 424',
    0x1D612: '236 223Q235 222 213 113T188 2V0H138Q88 0 88 1L98 46Q107 90 126 177T162 348L236 694H285Q335 694 335 693L330 671Q326 649 316 603T298 518Q289 477 280 433T266 366L261 343L672 694H729L784 693L465 420L651 0H596L541 1L384 350Q383 351 310 288T236 223',
    0x1D613: '161 348L235 694H338V691Q338 690 273 385T208 79Q278 80 362 80H516Q502 11 499 2V0H293Q87 0 87 1L96 46Q106 90 125 177T161 348',
    0x1D614: '375 691Q456 215 459 124V106Q488 177 762 641L793 694H929V691Q929 690 856 348T782 2V0H689V2Q691 4 753 304Q817 604 818 606Q819 611 817 608Q817 607 815 603Q798 559 540 117L484 22H440L397 23L393 42Q393 47 373 169T334 422T315 594V609L250 306Q186 3 185 2Q185 0 138 0Q92 0 92 1L102 46Q111 90 130 177T166 348L240 694H375V691',
    0x1D615: '311 609Q310 608 246 306T181 2V0H134Q88 0 88 1L98 46Q107 90 126 177T162 348L236 694H382L383 691Q383 688 418 561T493 286T541 97L544 84L545 89Q545 90 553 128T578 246T610 394L674 694H766V691Q766 690 693 348T619 2V0H472L469 13Q468 17 393 293T312 605L311 609',
    0x1D616: '118 254Q118 366 174 473T324 648T517 716Q627 716 695 638T763 435Q763 321 706 215T555 43T362 -22Q256 -22 187 56T118 254ZM380 58Q452 58 518 116T622 263T661 442Q661 496 646 535T608 594T567 622T534 634Q516 636 496 636Q400 636 313 528T225 264Q225 172 267 115T380 58',
    0x1D617: '162 348L236 694H378Q522 693 530 692Q604 680 647 635T690 524Q690 474 665 430T612 359Q550 299 465 280Q443 275 343 274H250V271Q250 269 235 201T206 68T192 2V0H140Q88 0 88 1L98 46Q107 90 126 177T162 348ZM594 513Q594 560 562 588T477 622Q470 623 394 623H321L293 487L263 349V347H342H347H375Q530 347 578 449Q594 483 594 513',
    0x1D618: '118 254Q118 366 174 473T324 648T517 716Q627 716 695 638T763 435Q763 305 693 194T543 36Q547 29 586 -47T625 -125H504L450 -8Q406 -22 363 -22Q256 -22 187 56T118 254ZM661 437Q661 532 616 584T506 636Q428 636 361 578T257 433T220 258Q220 167 264 113T380 58Q390 58 397 58T408 59T413 60T417 61Q417 63 387 127T356 193Q356 194 409 194H462L485 150L508 105Q509 103 532 125T567 161Q661 278 661 437',
    0x1D619: '162 348L236 694H375H414H445Q507 694 538 690T606 668Q698 623 698 534V528Q698 447 608 377Q582 358 555 345T512 326L497 321L617 0H565L513 1L402 309H255L189 0H138Q88 0 88 1L98 46Q107 90 126 177T162 348ZM603 525Q603 603 499 620Q486 622 403 623H321L297 506Q292 482 285 449T274 402L271 387V385H346Q350 385 363 385T386 384Q548 384 592 479Q603 503 603 525',
    0x1D61A: '161 478Q161 568 242 642T435 716Q527 716 599 673L609 667Q595 633 589 615L571 568Q570 568 564 575T546 592T518 611T475 628T417 635Q351 635 305 596T259 507Q259 465 290 444T372 411T432 396Q473 385 509 343T545 236Q545 140 464 59T270 -22Q155 -22 54 48L92 146Q93 146 101 138T124 117T161 92T216 72T288 63Q360 63 403 109T447 204Q447 220 444 233T435 256T421 273T404 285T385 295T366 301T347 306T331 310T315 314T292 321T265 331T235 346T207 367T183 395T168 431T161 478',
    0x1D61B: '165 608L182 687Q182 688 486 688H790L789 685L781 645L773 609H521L457 306Q393 3 392 2Q392 0 340 0H288V2Q289 5 353 304T417 605V609L291 608H165',
    0x1D61C: '340 -22Q251 -22 191 33T131 177V187Q131 192 131 195T132 205T133 215T136 231T141 253T147 285T156 328T168 384T184 457L235 694H338V691Q338 690 288 451T236 210Q234 194 234 177Q234 138 247 111T280 72T319 54T357 49Q408 49 449 74T510 128Q516 136 521 143T530 158T538 175T545 194T553 220T560 250T569 289T579 336T591 395T606 464L655 694H747V691Q651 243 645 213Q623 149 587 102Q482 -22 340 -22',
    0x1D61D: '220 348L161 694H216Q270 694 270 693L283 613Q334 313 346 215Q359 102 359 96Q359 87 358 84Q388 162 684 657L706 694H753Q799 694 799 693L387 0H333Q279 0 279 1L272 45Q264 89 249 177T220 348',
    0x1D61E: '596 540Q596 562 597 585T599 609Q599 588 436 255Q402 185 362 104L310 0H213V3Q213 6 188 347T161 694H263L265 664Q290 327 293 184Q293 112 289 85Q290 85 290 87Q290 95 301 123T332 194T373 282T419 380T463 469T498 541T517 579L574 694H671V689L674 646Q678 603 682 538T691 401T699 263T703 160Q703 102 700 87Q719 154 930 576L989 694H1076Q1076 693 903 347L730 0H628V4L626 26Q624 48 622 85T616 168T609 267T603 369T598 464T596 540',
    0x1D61F: '14 0Q17 3 184 184T352 367L265 529Q244 567 222 609T188 672L176 692Q176 694 236 694H297L338 612Q387 515 400 489L421 448L645 694H758L708 640Q481 393 456 368Q455 366 500 281T596 104T652 0H531L388 293L128 0H14',
    0x1D620: '151 692Q151 694 212 694H272L418 362L696 683L705 694H758L809 693Q809 692 630 490T444 280Q442 275 413 139L383 1L333 0Q282 0 282 2Q283 3 312 141L341 278L246 484L151 692',
    0x1D621: '67 54Q551 615 551 617Q543 618 517 618Q510 618 463 618T376 617Q200 617 200 618T209 657L216 694H459Q702 694 702 692Q702 689 697 667L692 643L207 80H392Q493 81 577 81Q577 70 560 2V0H55V2L67 54',
    0x1D622: '313 386Q286 386 260 381T217 369T186 355T164 342T155 337Q154 338 159 377T165 418Q251 461 320 461Q322 461 328 461T337 460Q397 460 435 424T473 329Q473 325 473 318T472 308Q432 110 407 2V0H317V2L325 38Q295 21 269 10Q215 -10 156 -10H149Q76 -10 62 69Q61 75 61 90Q61 127 73 150T116 194Q146 215 207 231T348 252H368L373 277Q378 302 378 318Q378 367 339 384Q332 386 313 386ZM150 116Q150 93 171 79T223 65Q259 65 293 85T341 135Q343 140 348 160T353 184Q353 186 342 186Q298 186 231 174T153 134Q150 127 150 116',
    0x1D623: '302 -11Q266 -11 235 1T190 26L176 38Q170 8 168 2V0H121Q75 0 75 1L84 46Q94 90 113 177T149 348L223 694H267Q312 694 312 693T282 551T251 407Q251 406 256 408T271 415Q347 454 430 454H438Q501 454 528 374Q539 339 539 299Q539 179 466 84T302 -11ZM443 275Q443 317 421 348T346 379Q318 379 296 369Q269 359 238 332L193 118L198 109Q220 65 269 65Q350 65 396 130T443 275',
    0x1D624: '75 164Q75 226 100 282T165 377T252 437T342 460H347Q447 460 499 417L483 378Q468 339 468 338Q466 338 455 347T424 366T385 378Q355 382 334 382Q262 382 215 318T168 177Q168 120 196 95T259 69H269Q345 69 420 108Q432 114 432 113T427 72L422 32L402 22Q382 12 344 2T259 -11Q214 -11 180 2T126 36T95 81T79 126T75 164',
    0x1D625: '73 156Q73 224 102 293T184 408T294 455Q375 455 432 413Q438 407 438 410T469 553L499 694H588V691Q588 690 515 348T441 2V0H348V2Q357 29 357 43L352 41Q332 24 288 7T196 -10H190Q178 -10 166 -7T134 8T98 46T75 113Q73 129 73 156ZM419 335Q419 339 412 348T386 368T342 379Q284 379 243 343T184 261T167 168Q167 122 191 94T263 66Q321 66 367 116L374 124L397 229Q419 333 419 335',
    0x1D626: '248 -11Q170 -11 121 41T71 173Q71 265 133 349T285 454Q305 460 318 460H328Q368 460 399 448Q472 414 472 309Q472 274 464 234L462 219H159Q156 198 156 185Q156 137 179 107T237 68Q246 66 268 66Q345 66 427 113V109Q426 108 422 73T417 37Q417 34 409 29Q329 -11 248 -11ZM401 299Q399 337 376 361T316 385Q291 385 266 371Q220 350 184 289H401V299',
    0x1D627: '381 443Q381 440 374 407T366 371H315Q263 371 263 369Q262 368 224 186Q215 145 205 97T189 25L184 2V0H94V2L99 25Q104 48 114 96T134 186Q172 368 173 369Q173 371 139 371H106V373L114 410L121 444H155L188 445L191 455L212 551Q232 612 288 658T415 705Q438 705 464 701T494 694Q478 614 477 614L467 618Q457 621 440 624T406 629H400Q333 629 306 579Q301 568 289 507L275 444H328Q381 444 381 443',
    0x1D628: '113 252Q113 334 177 394T311 454Q332 454 350 451T379 442T398 432T410 424L413 421Q412 423 411 424L409 426Q409 429 434 436T496 449T560 455H568V451Q568 447 567 429T566 394L565 377L553 379Q522 385 479 385Q463 385 456 384L443 383L436 392Q454 357 454 324Q454 243 390 182T249 120Q233 120 219 122T195 128T178 136T167 142L163 145Q149 131 149 105Q149 78 171 72L242 71Q246 71 269 71T303 71T336 68T372 62T403 51T432 32Q461 8 461 -40Q461 -112 383 -159T211 -206Q123 -206 68 -172T12 -86Q12 -55 31 -23T82 32Q90 38 89 39Q89 40 87 44T82 59T80 82Q80 134 126 189Q113 228 113 252ZM369 319Q369 354 350 368T304 383Q274 383 252 369T218 333T202 291T197 255Q197 221 217 206T263 191Q317 191 343 233T369 319ZM373 -59Q373 -41 362 -30T330 -13T291 -7T247 -5H216Q167 -5 158 -6T139 -12Q123 -20 110 -38T97 -76Q97 -102 133 -118T221 -134Q242 -134 267 -130T316 -118T357 -94T373 -59',
    0x1D629: '416 321Q416 379 336 379Q276 379 237 302Q226 276 209 202T180 66T166 2V0H119Q73 0 73 1L82 46Q92 90 111 177T147 348L221 694H265Q310 694 310 693T279 544L247 395Q325 455 403 455Q513 455 513 358Q513 334 508 309Q507 304 476 156T443 2V0H350V2L357 34Q364 65 373 110T392 200T409 281T416 321',
    0x1D62A: '189 578Q190 579 199 627T211 678V680H315V678Q313 675 304 627T293 578V576H189V578ZM168 442T168 443T213 444T258 443T212 225T164 2V0H74V2Q75 7 121 224',
    0x1D62B: '211 577L233 680H284Q336 680 336 679L315 576H263Q211 576 211 577ZM19 -204Q-12 -204 -40 -196T-82 -179T-96 -170Q-96 -168 -78 -132L-61 -95L-54 -103Q-32 -126 3 -126Q26 -126 50 -116Q76 -101 83 -85Q84 -79 140 180T196 443Q196 444 241 444T286 443Q286 441 232 186T175 -75Q163 -120 122 -162T19 -204',
    0x1D62C: '150 348L224 694H310V691Q218 259 218 258L232 270Q245 281 274 306T327 351L435 444H489L542 443Q542 442 443 357L344 272L471 1L422 0H372L366 14Q359 27 347 54T323 105L273 210Q271 210 231 174L190 139L160 0H118Q76 0 76 1L86 46Q95 90 114 177T150 348',
    0x1D62D: '148 348L222 694H311V691Q311 690 238 348T164 2V0H119Q74 0 74 1L84 46Q93 90 112 177T148 348',
    0x1D62E: '416 321Q416 379 336 379Q276 379 237 302Q226 276 209 202T180 66T166 2V0H119Q73 0 73 2L121 226L169 449Q169 450 213 450H256L249 421Q248 417 247 412T246 404T245 398T244 394T244 392Q250 398 261 407T307 433T379 454H392H400Q451 454 472 439Q482 434 489 427T500 412T506 399T510 388L511 384Q511 384 517 388Q563 431 620 446Q648 455 680 455Q790 455 790 358Q790 334 785 309Q784 304 753 156T720 2V0H627V2L634 34Q641 65 650 110T669 200T686 281T693 321Q693 379 613 379Q553 379 514 302Q503 276 486 202T457 66T443 2V0H350V2L357 34Q364 65 373 110T392 200T409 281T416 321',
    0x1D62F: '416 321Q416 379 336 379Q276 379 237 302Q226 276 209 202T180 66T166 2V0H119Q73 0 73 2L121 226L169 449Q169 450 213 450H256L249 421Q248 417 247 412T246 404T245 398T244 394T244 392Q250 398 261 407T307 433T379 454H392Q416 454 433 452T470 440T502 411T513 358Q513 334 508 309Q507 304 476 156T443 2V0H350V2L357 34Q364 65 373 110T392 200T409 281T416 321',
    0x1D630: '69 169Q69 238 107 306T211 417T348 461Q419 461 471 412T523 271Q523 161 438 75T247 -11Q170 -11 120 39T69 169ZM432 279Q432 338 401 361T333 385Q280 385 240 352T182 273T164 178Q164 119 195 94T265 68Q306 68 344 94Q380 115 406 169T432 279',
    0x1D631: '259 443Q251 405 251 404L260 409Q269 414 286 421T324 436T375 449T434 455Q482 455 510 417T538 303Q538 169 463 79T302 -11Q226 -11 176 39V36Q175 35 151 -80L127 -193Q127 -194 80 -194H34V-191L102 127L169 443Q169 444 214 444T259 443ZM269 65Q332 65 386 124T441 262Q441 304 422 334T370 373Q356 375 339 375Q293 375 238 331L193 118Q200 103 206 94T229 75T269 65',
    0x1D632: '72 149Q72 272 146 363T304 455Q340 455 371 442T409 423T436 398Q438 411 442 427L448 455H538L400 -193Q400 -194 354 -194Q307 -194 307 -193L356 37V41Q355 41 350 38T332 27T302 13Q247 -10 191 -10H179Q138 -10 105 32T72 149ZM414 298Q402 376 341 376Q277 376 223 317T169 182Q169 121 198 93T265 65Q319 65 365 111L377 123L414 298',
    0x1D633: '240 377L244 380Q248 384 255 390T272 404T296 419T325 434T361 446T401 454Q403 454 408 454T416 455H424L421 442Q419 435 413 405T406 373Q351 373 294 336T216 237Q213 231 201 173T178 60T164 2V0H119Q74 0 74 2L122 226L170 449Q170 450 213 450H255L247 414Q246 409 245 403T243 393T241 385T240 379T240 377',
    0x1D634: '99 299Q99 318 106 341T133 393T195 441T298 461Q336 461 370 453T420 437L436 429Q436 428 421 389T405 350Q356 386 273 386H265Q248 386 237 384T211 371T191 337Q189 329 189 326Q189 320 190 315T194 306T200 299T209 293T218 289T228 285T239 283T251 281T263 278L270 276Q278 275 283 274T298 270T316 264T333 255T351 243T367 228T380 209T388 186T391 157Q391 96 341 43T193 -11Q171 -11 150 -8T114 -1T84 9T61 19T45 28T35 33Q35 36 67 116L76 109Q132 67 211 67Q258 67 279 88T301 135Q301 159 280 170T224 187T180 197Q141 212 120 239T99 299',
    0x1D635: '245 68Q267 68 289 75T322 90L334 98Q338 94 338 28V24L324 19Q268 -4 218 -8Q198 -11 177 -11Q118 -11 118 75Q118 98 123 127Q125 137 149 251T174 369Q174 371 135 371H97V373L105 410L112 444H152L192 445L200 478Q208 512 213 541L219 571H261Q303 571 303 570T290 506L276 444H343Q410 444 410 443Q410 440 403 407T395 371H328Q261 371 261 369Q211 152 211 118Q211 68 245 68',
    0x1D636: '166 -10H160Q146 -10 137 -8T115 0T97 22T90 63Q90 79 130 268L167 443Q167 444 214 444Q260 444 260 443L224 273Q187 97 187 86Q187 70 202 65T250 59Q303 59 336 83T379 139Q380 143 412 292T444 443Q444 444 491 444Q537 444 537 443T491 225T443 2V0H353V2L361 38L352 34Q344 29 326 22T286 7T232 -5T166 -10',
    0x1D637: '177 6L108 442V444H201V442Q202 441 213 371T235 213T246 90V65Q259 117 429 406L450 444H495Q540 444 540 443Q539 442 411 221L282 1L230 0H178L177 6',
    0x1D638: '148 5Q147 8 128 222T109 440L108 444H199V442Q200 441 204 385T214 253T219 140Q219 108 215 76Q215 72 214 67V65L215 66Q219 95 278 221L390 444H475V437Q497 203 497 121Q497 90 494 70Q494 67 494 67L496 73Q520 143 654 405L674 444H718Q762 444 762 443L534 1L483 0H432V5Q429 28 422 126T413 283Q413 343 416 370L417 378Q416 377 416 376Q401 303 248 12L242 0H148V5',
    0x1D639: '317 229Q453 9 460 0H409L359 1L312 88Q266 176 265 176Q265 177 254 165T223 132T182 88L100 0H1L15 14Q29 28 61 59T118 115L236 229L226 244Q108 433 100 444H201L290 294L438 444H537L528 435Q526 432 512 418T468 376T418 327L317 229',
    0x1D63A: '11 -117L20 -120Q28 -124 46 -128T84 -132H100Q124 -122 149 -85Q200 -6 200 1Q200 17 155 204T109 442Q109 444 156 444H203Q203 443 208 419T221 357T235 277T248 190T254 114Q254 81 250 67V65Q251 65 251 67Q256 94 297 177Q339 259 422 397L450 444H540Q540 443 386 186T219 -90Q179 -153 145 -179T73 -205Q52 -205 34 -202Q29 -202 21 -201T7 -198L1 -197Q1 -196 6 -157T11 -117',
    0x1D63B: '129 408L136 444H315Q494 444 494 443Q494 441 489 419L484 396L164 76L291 77Q418 77 418 76T411 41T402 2V0H215Q28 0 28 2L34 27L38 50L360 371L240 370Q121 370 121 371Q124 388 129 408',
    0x1D670: '191 76Q212 75 220 68T229 38Q229 10 208 1H129H80Q48 1 38 7T28 38Q28 51 29 57T40 69T70 76Q89 76 89 78Q90 79 117 205T173 461T205 599Q212 623 250 623H262H273Q312 623 319 599Q322 591 350 461T406 205T435 78Q435 76 454 76H458Q484 76 493 59Q496 53 496 38Q496 11 478 3Q474 1 395 1H317Q295 8 295 38Q295 65 311 73Q316 75 333 76L348 77V78Q348 80 341 112L334 143H190L183 112Q176 80 176 78Q175 76 178 76Q180 76 191 76ZM318 221Q313 238 288 366T263 519Q263 526 262 527Q261 527 261 520Q261 493 236 365T206 221Q206 219 262 219T318 221',
    0x1D671: '39 1Q17 10 17 32V38V46Q17 65 34 73Q40 76 61 76H84V535H61H54Q27 535 19 553Q17 557 17 573Q17 583 17 587T23 599T39 610Q40 611 179 611Q320 610 332 607Q332 607 339 605Q394 591 427 547T461 454Q461 413 436 378T369 325L358 320Q405 311 443 270T482 169Q482 112 445 64T345 3L334 1H39ZM309 533Q302 535 234 535H168V356H230Q284 357 296 358T323 368Q346 380 361 402T377 452Q377 482 358 505T309 533ZM398 176Q396 218 371 246T315 279Q310 280 237 280H168V76H239Q316 77 327 81Q329 82 334 84Q398 107 398 176',
    0x1D672: '40 305Q40 437 110 529T281 622Q315 622 343 611T387 589T404 578Q409 585 415 596T425 611T435 618T452 622Q472 622 478 609T485 566Q485 559 485 540T484 508V460Q484 413 478 403T442 393Q417 393 409 402Q400 409 400 420Q400 428 395 445T380 487T347 528T295 546Q235 546 180 483T124 306Q124 245 141 197T186 121T241 80T296 66Q346 66 373 103T400 178Q400 209 435 209H442H450Q484 209 484 172Q480 96 421 43T281 -11Q177 -11 109 84T40 305',
    0x1D673: '38 1Q16 8 16 38Q16 62 32 73Q39 76 58 76H78V535H58Q40 535 32 538Q16 548 16 573Q16 587 17 591Q23 604 34 607T83 611H166H176Q188 611 209 611T239 612Q299 612 337 597T415 530Q485 438 485 300Q485 180 431 100T301 3L291 1H38ZM400 301Q400 363 385 410T346 482T303 519T267 534Q261 535 210 535H162V76H214L267 77Q323 89 361 148T400 301',
    0x1D674: '374 271Q374 241 367 232T332 223Q307 223 299 231Q290 240 290 263V279H173V76H418V118V144Q418 167 426 176T460 186Q491 186 500 166Q502 161 502 93V52Q502 25 499 17T480 1H41Q19 9 19 32V38Q19 63 36 73Q42 76 65 76H89V535H65H55Q44 535 38 537T25 548T19 573Q19 602 41 610H47Q53 610 63 610T88 610T121 610T160 611T204 611T251 611H458Q460 609 465 606T471 602T475 598T478 593T479 586T480 576T480 562V526V488Q480 452 462 444Q458 442 438 442Q413 442 405 450Q398 457 397 463T396 501V535H173V355H290V371Q290 394 299 403T332 412Q363 412 372 392Q374 387 374 317V271',
    0x1D675: '384 260Q384 230 377 221T342 212Q317 212 309 220Q300 229 300 252V268H179V76H249Q264 67 267 61T271 38Q271 10 249 1H44Q22 9 22 32V38Q22 63 39 73Q45 76 69 76H95V535H69H59Q42 535 32 542T22 573Q22 602 44 610H50Q56 610 66 610T91 610T125 610T164 611T208 611T257 611H468Q470 609 475 606T481 602T485 598T488 593T489 586T490 576T490 562V526V488Q490 452 472 444Q468 442 448 442Q423 442 415 450Q408 457 407 463T406 501V535H179V344H300V360Q300 383 309 392T342 401Q373 401 382 381Q384 376 384 306V260',
    0x1D676: '38 306Q38 447 105 534T261 622Q280 622 298 618T329 608T350 596T366 585L371 581Q373 581 377 591T390 612T417 622Q437 622 443 609T450 566Q450 559 450 540T449 508V460Q449 413 443 403T407 393Q392 393 386 394T373 402T364 426Q360 472 335 509T271 546Q214 546 168 477T121 308Q121 210 164 138T271 65Q293 65 310 78T337 109T352 147T360 180T362 195Q362 196 333 196L304 197Q282 204 282 227V234Q282 247 282 251T288 261T304 272H474Q488 263 492 256T496 234Q496 211 479 199Q475 197 461 196H449V21Q441 6 434 3T412 -1H407H402Q385 -1 379 3T364 28Q350 14 322 2T260 -11Q173 -11 106 76T38 306',
    0x1D677: '16 571Q16 597 27 604T74 611H125H208Q223 602 226 596T230 573Q230 559 227 551T217 540T204 536T186 535H165V356H359V535H338H333Q306 535 297 552Q295 556 295 573Q295 586 295 590T301 600T317 611H486Q501 602 504 596T508 573Q508 559 505 551T495 540T482 536T464 535H443V76H464H470Q482 76 489 75T502 64T508 38Q508 10 486 1H317Q306 5 301 11T296 21T295 38V44Q295 66 311 73Q318 76 338 76H359V280H165V76H186H192Q204 76 211 75T224 64T230 38Q230 10 208 1H39Q28 5 23 11T18 21T17 38V44Q17 66 33 73Q40 76 60 76H81V535H60Q45 535 38 536T24 545T16 571',
    0x1D678: '400 76Q431 76 441 69T452 38Q452 29 452 26T450 18T443 9T430 1H95Q84 6 79 12T73 23T72 38Q72 65 90 73Q96 76 157 76H220V535H157H124Q93 535 83 542T72 573Q72 603 93 610Q97 611 264 611H430Q432 609 436 607T444 602T449 594Q452 588 452 573Q452 546 434 538Q428 535 367 535H304V76H367H400',
    0x1D679: '202 543T202 573T224 610H228Q231 610 237 610T251 610T269 610T291 611T315 611T342 611H457Q471 602 475 595T479 573Q479 549 462 538Q454 535 432 535H408V328Q408 159 408 133T402 93Q386 48 340 19T229 -11Q158 -11 108 16T57 100Q57 129 73 141T108 154Q128 154 143 140T159 102Q159 93 155 79Q188 65 228 65H230Q290 65 318 106Q323 115 323 139T324 329V535H274L224 536Q202 543 202 573',
    0x1D67A: '18 549T18 573T29 604T70 611H118H193Q207 603 210 596T214 573Q214 549 198 538Q191 535 172 535H152V421Q152 344 152 326T153 309L242 422L329 534Q327 535 322 536T314 538T308 542T303 548T300 558T298 573Q298 600 316 608Q322 611 392 611H463Q477 602 481 595T485 573Q485 535 446 535H441H420L281 357L436 77L454 76Q473 75 478 73Q495 62 495 38Q495 10 473 1H345Q334 5 329 11T324 21T323 38Q323 51 324 56T332 68T355 77L233 296L152 192V76H172Q191 76 198 73Q214 63 214 38Q214 9 193 1H41Q18 8 18 38Q18 61 35 73Q42 76 61 76H81V535H61Q42 535 35 538Q18 549 18 573',
    0x1D67B: '27 594Q34 605 43 608T84 611H154H213Q258 611 269 605T281 573Q281 546 263 538Q257 535 222 535H185V76H404V118V145Q404 168 411 177T446 186H453Q478 186 486 167Q488 161 488 93V50Q488 24 485 17T466 1L258 0H147H99Q47 0 36 6T25 38Q25 59 35 69Q44 76 76 76H101V535H76H64Q36 535 27 552Q25 557 25 573T27 594',
    0x1D67C: '50 535Q37 536 31 537T18 547T12 573Q12 598 22 604T62 611H91H121Q147 611 158 607T178 587Q183 579 222 446T261 293Q261 289 262 288Q263 288 263 292Q263 311 298 434T346 588Q353 603 365 607T402 611H435H450Q488 611 500 605T512 573Q512 556 506 547T493 537T474 535H459V76H474Q487 75 493 74T505 64T512 38Q512 11 494 3Q490 1 424 1H386Q355 1 345 7T335 38Q335 55 341 64T354 74T373 76H388V302Q388 512 387 519Q382 482 346 359T304 228Q292 204 262 204T220 228Q215 237 179 359T137 519Q136 512 136 302V76H151Q164 75 170 74T182 64T189 38Q189 11 171 3Q167 1 101 1H63Q32 1 22 7T12 38Q12 55 18 64T31 74T50 76H65V535H50',
    0x1D67D: '20 571Q20 598 30 604T73 611H105H136Q152 611 160 611T177 607T189 601T198 587T206 568T217 537T231 497Q354 142 365 95L368 84V535H347H342Q314 535 306 552Q304 556 304 573Q304 586 304 590T310 600T326 611H482Q497 602 500 596T504 573Q504 559 501 551T491 540T478 536T460 535H439V25Q432 7 424 4T389 0H374Q334 0 322 31L293 115Q171 468 159 517L156 528V76H177H183Q195 76 202 75T215 64T221 38Q221 10 199 1H43Q32 5 27 11T22 21T21 38V44Q21 66 37 73Q44 76 64 76H85V535H64Q49 535 42 536T28 545T20 571',
    0x1D67E: '102 588Q140 621 240 621Q323 621 335 620Q393 613 422 588Q450 560 459 493T468 306Q468 185 460 118T422 23Q382 -10 289 -10H262H235Q142 -10 102 23Q74 50 65 118T56 306Q56 427 64 494T102 588ZM363 513Q357 523 347 530T324 540T302 544T280 546H268Q192 546 167 521Q150 501 145 452T140 300Q140 235 142 197T151 130T172 89T207 71T262 65Q317 65 341 81T374 144T384 300Q384 474 363 513',
    0x1D67F: '41 1Q19 9 19 32V38Q19 63 36 73Q42 76 65 76H89V535H65H55Q38 535 29 543T19 576Q19 603 41 610H49Q57 610 70 610T100 610T136 611T175 611Q190 611 216 611T255 612Q321 612 363 598T441 537Q480 486 480 427V421Q480 354 447 311T378 251Q339 230 275 230H239H173V76H197Q220 76 227 73Q244 62 244 38Q244 10 222 1H41ZM396 421Q396 461 369 491T300 533Q294 534 233 535H173V306H233Q294 307 300 308Q345 319 370 352T396 421',
    0x1D680: '56 306Q56 380 58 426T68 510T87 568T120 600T170 617T240 621Q323 621 335 620Q393 613 422 588Q450 560 459 493T468 306Q468 124 447 66Q433 23 394 6L424 -53Q454 -112 454 -118Q454 -128 441 -138H377Q367 -135 363 -129T333 -69L304 -11H254Q205 -10 180 -8T128 6T91 36T70 92T58 178T56 306ZM227 151Q227 171 262 171H276H281Q292 171 296 171T305 170T313 165T317 158T323 145T332 127L353 88Q356 88 361 95T372 131T382 202Q384 228 384 306Q384 452 371 492T304 544Q296 545 251 545Q230 545 215 543T188 534T169 520T155 497T147 466T143 423T141 371T140 306Q140 248 141 217T146 154T157 109T178 83T212 68T262 65H266L264 70Q261 75 256 85T247 105Q227 145 227 151',
    0x1D681: '16 571Q16 598 27 605T76 612Q84 612 108 612T148 611Q268 611 294 605Q346 592 389 550T432 440Q432 394 410 359Q393 329 366 310L358 303Q387 273 399 239Q405 219 405 178T408 106T421 68Q426 65 428 65Q433 65 435 74T438 96T441 112Q450 130 480 130H485Q519 130 522 100Q522 79 516 56T488 11T434 -11Q421 -11 408 -8T377 5T344 37T324 93Q322 101 322 154L321 209Q304 257 257 267Q252 268 207 268H165V76H186H192Q204 76 211 75T224 64T230 38Q230 10 208 1H39Q28 5 23 11T18 21T17 38V44Q17 66 33 73Q40 76 60 76H81V535H60Q45 535 38 536T24 545T16 571ZM348 440Q348 478 321 502T260 532Q252 534 208 535H165V344H208Q212 344 223 344T239 345T252 346T266 348T278 351T293 358Q348 387 348 440',
    0x1D682: '52 454Q52 524 107 572T229 621Q266 621 274 620Q326 610 360 588L371 581Q377 594 379 598T386 610T397 619T412 622Q433 622 439 610T446 570Q446 563 446 545T445 515V479Q445 441 444 432T436 417Q428 408 403 408T370 417Q361 424 361 434Q361 439 360 448T351 476T331 509T295 535T238 546Q194 546 163 522T132 458Q132 435 148 412Q155 401 166 393T192 380T218 371T247 364T270 359Q341 342 349 339Q389 325 418 296T461 229Q472 201 472 164Q469 92 417 41T287 -11Q240 -11 200 -1T143 19L126 29Q117 6 109 -2Q100 -11 84 -11Q64 -11 58 1T51 42Q51 49 51 66T52 95V135Q52 173 53 180T61 194Q70 203 95 203Q119 203 127 194Q136 186 136 168Q143 66 284 66H290Q325 66 350 85Q391 115 391 165Q391 204 369 228T322 260Q320 260 255 275T185 293Q123 309 88 355T52 454',
    0x1D683: '129 38Q129 51 129 55T135 65T151 76H220V535H110V501Q110 470 109 464T101 450Q93 442 68 442H60Q37 442 28 461Q26 466 26 527L27 589Q36 607 49 610H55Q61 610 72 610T97 610T131 610T170 611T215 611T264 611H476Q478 609 483 606T489 602T493 598T496 593T497 586T498 576T498 562V526V488Q498 452 480 444Q476 442 456 442Q431 442 423 450Q416 457 415 463T414 501V535H304V76H374Q389 67 392 61T396 38Q396 10 374 1H151Q140 5 135 11T130 21T129 38',
    0x1D684: '-3 573Q-3 597 8 604T50 612Q57 612 77 612T111 611H200Q214 602 218 595T222 573Q222 549 205 538Q198 535 175 535H151V359Q151 333 151 291Q152 177 156 162Q157 160 157 159Q165 123 193 95T262 66Q303 66 330 94T367 159Q371 175 371 191T373 359V535H349H339Q328 535 322 537T309 548T303 573T306 595T325 611H506Q520 602 524 595T528 573Q528 549 511 538Q504 535 481 535H457V364Q457 189 456 182Q448 101 394 45T262 -11Q189 -11 132 43T68 182Q67 189 67 364V535H43H33Q22 535 16 537T3 548T-3 573',
    0x1D685: '19 578Q19 585 20 590T23 598T29 604T38 608T48 610T62 611T78 612T97 611T119 611H195Q210 602 213 596T217 573Q217 561 216 555T206 542T179 535H164Q166 529 188 435T235 231T261 94L262 84V88Q263 91 263 94Q265 121 289 231T336 438L360 535H345Q308 535 308 566V573Q308 586 308 590T314 600T330 611H484Q499 602 502 595T505 573Q505 560 504 554T493 541T465 535H447L384 278Q321 19 319 14Q309 -7 278 -7H262H246Q215 -7 205 14Q203 19 140 278L78 535H59Q45 535 38 536T25 547T19 573V578',
    0x1D686: '459 611Q491 611 501 605T512 573Q512 538 482 535H474L439 276Q406 26 402 11Q398 2 389 -3Q387 -3 386 -4L380 -7H359H349Q324 -7 313 13Q307 29 285 139T263 275Q263 283 262 283Q261 282 261 274Q261 248 239 137T211 13Q200 -7 175 -7H165H144Q136 -3 127 3Q121 10 117 36T85 276L50 535H42Q26 536 19 545T12 564V573Q12 603 33 610Q37 611 101 611H134Q165 611 175 604T186 573Q186 563 186 559T182 547T169 538T143 535H122V531Q124 517 133 446T155 266T172 96V84L173 102Q176 157 192 243T215 346Q227 367 259 367H262H265Q297 367 309 346Q316 329 332 243T351 102L352 84V96Q356 161 368 266T390 444T402 531V535H381Q366 535 359 536T345 547T338 573Q338 600 356 608Q362 611 425 611H459',
    0x1D687: '39 571Q39 597 49 604T93 611H141H218Q233 602 236 595T239 573Q239 538 210 535Q202 535 202 534T215 507T243 454L257 428L307 535H298Q266 538 266 573Q266 584 267 588T273 598T289 611H366H401Q442 611 454 605T466 573Q466 546 448 538Q442 535 421 535H398L299 327Q299 323 362 201L426 77L449 76Q467 76 475 75T489 65T495 38Q495 11 477 3Q473 1 395 1H317Q295 8 295 38Q295 73 325 76L334 77Q333 78 314 117T276 196L257 235L239 196Q221 157 204 118T186 77Q190 76 196 76Q211 74 218 67T227 55T228 38Q228 28 227 24T221 13T206 1H50Q28 9 28 32V38Q28 63 45 73Q51 76 73 76H96L214 324Q215 327 162 431L108 535H85H79Q67 535 60 536T46 546T39 571',
    0x1D688: '20 573Q20 597 30 604T72 611H121H198Q212 602 216 595T220 573Q220 568 219 563T217 555T214 549T211 544T207 541T203 538T198 537T194 536T190 536L188 535Q179 535 179 534L188 516Q196 497 208 470T232 415T252 363T261 332Q261 329 262 329T263 332Q263 354 333 508L345 534Q345 535 336 535Q305 538 305 567V573Q305 589 308 595T327 611H483Q505 598 505 573Q505 549 488 538Q481 535 460 535H438L304 245V76H325H331Q343 76 350 75T363 64T369 38Q369 10 347 1H178Q167 5 162 11T157 21T156 38V44Q156 66 172 73Q180 76 199 76H220V245L86 535H64Q44 535 36 538Q20 548 20 573',
    0x1D689: '71 1Q60 5 55 11T49 23T48 39V46Q48 56 58 73T131 183Q171 242 197 282L366 535H144V501Q144 470 143 464T135 450Q127 442 102 442H94Q71 442 62 461Q60 466 60 527L61 589Q70 607 83 610H88Q93 610 102 610T124 610T154 610T188 611T227 611T270 611H454Q456 609 461 606T467 601T471 597T474 591T475 584T476 572V565Q476 555 466 538T393 428Q353 369 327 329L158 76H397V120V146Q397 169 405 179T439 189Q470 189 479 169Q481 164 481 95V48Q481 24 478 16T459 1H71',
    0x1D68A: '126 306Q105 306 90 321T74 359Q74 439 211 439Q268 439 276 438Q343 426 383 390T430 306Q431 301 431 190V81Q446 79 465 78T492 76T509 72T521 60T524 38Q524 11 506 3Q502 1 466 1Q426 1 406 5T379 14T355 36L345 30Q284 -6 205 -6Q135 -6 92 39T48 141Q48 182 79 212T158 256T252 278T342 285H347V290Q347 315 325 335T267 362Q258 363 224 363Q189 363 185 362H179L178 358Q178 353 178 352T176 345T174 337T170 330T165 322T158 316T150 311T139 308T126 306ZM132 140Q132 115 157 93T224 70Q269 70 302 87T344 133Q346 139 347 175V211H339Q256 209 194 190T132 140',
    0x1D68B: '4 573Q4 596 15 603T52 611H90H124Q146 611 155 608T171 591Q173 586 173 491V396L182 402Q217 424 256 431Q280 437 309 437Q376 437 434 379T492 217Q492 162 473 118T422 47T358 8T293 -6Q229 -6 174 38Q171 13 163 7T135 1H131H122Q99 1 90 23L89 279V535H58L27 536Q4 543 4 573ZM409 215Q409 269 377 315T283 361Q255 361 224 344T177 297L173 290V167Q189 124 213 97T278 70Q330 70 369 111T409 215',
    0x1D68C: '291 -6Q196 -6 131 60T66 216Q66 296 119 361Q154 403 200 421T273 439Q275 440 293 440H313Q400 440 433 409Q454 388 454 359Q454 335 439 321T402 306Q380 306 365 321T350 357V362L340 363Q339 363 326 363T303 364Q280 364 266 362Q217 352 184 313T151 215Q151 153 199 112T313 70Q341 70 357 85T381 118T394 140Q402 146 424 146Q443 146 447 144Q466 137 466 117Q466 106 457 88T429 47T374 10T291 -6',
    0x1D68D: '266 573Q266 596 277 603T314 611H352H385Q411 611 419 607T435 586V76H498Q512 67 516 60T520 38Q520 9 498 1H436Q429 1 417 1T398 0Q375 0 363 7T351 34V43L342 36Q288 -6 223 -6Q143 -6 87 58T31 216Q31 307 88 372T230 437Q292 437 342 405L351 399V535H320L289 536Q266 543 266 573ZM351 290Q347 302 337 316T302 346T244 361Q193 361 154 319T115 215Q115 152 152 111T235 70Q314 70 351 170V290',
    0x1D68E: '48 217Q48 295 100 361T248 439L258 440Q268 440 274 440Q329 438 369 416T428 359T456 292T464 228Q464 215 461 208T454 198T442 190L288 189H135L138 179Q153 132 199 102T303 71Q336 71 353 86T380 120T398 143Q404 146 422 146Q453 146 462 126Q464 120 464 116Q464 84 416 39T285 -6Q187 -6 118 59T48 217ZM377 264Q371 291 365 306T341 338T294 362Q288 363 264 363Q225 363 190 336T139 264H377',
    0x1D68F: '43 395Q44 405 44 408T47 416T53 423T66 431H176V461Q176 500 182 518Q201 570 252 593T353 617Q399 614 418 593T437 548Q437 528 424 514T387 499Q365 499 353 511T338 537V541H328Q275 536 261 494Q260 490 260 460V431H327Q334 431 346 431T364 432Q392 432 404 425T416 393T405 362T365 355H327H260V76H319Q375 76 388 71T401 38Q401 27 400 23T395 12T379 1H58Q47 6 42 12T36 23T35 38Q35 65 53 73Q59 76 117 76H176V355H121H93Q64 355 54 362T43 395',
    0x1D690: '60 274Q60 337 107 386T233 436Q278 436 316 417L329 410L338 416Q384 442 427 442T489 423T509 381T494 345T460 332Q449 332 440 338Q432 341 427 348T419 360T415 365Q414 364 410 364L383 355Q406 320 406 274Q406 211 358 162T233 112Q189 112 155 128L146 133Q142 125 142 115Q142 99 150 85T175 71Q182 72 187 70Q188 70 195 70T218 70T254 69Q259 69 275 69T297 69T318 68T340 66T361 62T384 57T405 49T428 38Q495 -1 495 -76Q495 -143 427 -186T262 -229Q161 -229 94 -185T29 -73Q30 -60 33 -48T39 -26T47 -8T57 8T67 20T77 30T86 38L91 43Q91 44 86 53T75 80T70 117Q70 142 89 183L83 194Q60 232 60 274ZM321 274Q321 312 296 337T230 362Q197 362 171 338T145 274Q145 235 170 211T233 187Q273 187 297 212T321 274ZM422 -78Q422 -54 408 -38T366 -15T315 -6T255 -4H200Q198 -4 193 -4T183 -3Q148 -3 125 -26T102 -78Q102 -110 151 -132T261 -154Q321 -154 371 -132T422 -78',
    0x1D691: '4 573Q4 596 15 603T52 611H90H124Q146 611 155 608T171 591Q173 586 173 489Q173 394 175 394L186 402Q197 410 219 420T269 434Q278 436 306 436Q343 436 371 423Q411 402 423 365T436 265Q436 257 436 239T435 211V198V76H498Q512 67 516 60T520 38Q520 9 498 1H308Q286 10 286 32V38V46Q286 65 303 73Q309 76 329 76H351V188Q351 204 351 230T352 266Q352 321 341 341T288 361Q253 361 222 341T176 274L174 264L173 170V76H236Q250 67 254 60T258 38Q258 9 236 1H27Q4 8 4 38Q4 53 8 60T27 76H89V535H58L27 536Q4 543 4 573',
    0x1D692: '202 538T202 559T218 596T260 612Q283 612 300 597T317 560Q317 538 300 523T260 507Q235 507 219 522ZM411 76Q441 76 451 69T462 38Q462 29 462 26T460 18T453 9T440 1H94Q72 8 72 33V38Q72 46 72 49T74 58T81 68T94 76H233V355H167L102 356Q80 363 80 393Q80 418 91 425T138 432Q145 432 165 432T200 431H295Q297 429 303 425T310 420T314 415T317 404T317 389T318 363Q318 354 318 314T317 241V76H378H411',
    0x1D693: '261 559Q261 580 277 596T319 612Q342 612 359 597T376 560T360 523T320 507Q296 507 279 523T261 559ZM75 -91T100 -91T138 -107T152 -144V-150L160 -151H193H203Q241 -151 267 -121Q284 -97 288 -73T292 23V151V355H218L145 356Q123 365 123 387V393Q123 422 145 430H148Q151 430 156 430T169 430T185 430T205 431T227 431T251 431H354Q356 430 360 427T365 424T369 420T372 416T373 410T375 402T376 391T377 376T377 356Q377 345 377 286T376 176Q376 -67 371 -88Q362 -123 342 -151T299 -194Q254 -228 180 -228Q84 -226 56 -177Q49 -162 48 -148Q48 -122 61 -107',
    0x1D694: '13 42Q13 63 23 69T69 76H102V535H69H54Q34 535 24 542T13 573Q13 588 15 593Q22 605 29 608T56 611H95Q113 611 122 611T140 610T152 609T159 607T163 603T167 597T173 589V413L174 237L295 355H275Q260 355 253 356T239 367T232 393Q232 419 243 425T304 431H359H464Q479 422 482 415T485 393Q485 364 464 356L431 355H398L293 254L427 76H486Q501 67 504 60T507 38Q507 28 507 24T501 12T486 1H314Q292 8 292 38Q292 62 308 73Q312 75 326 76L338 77L290 140Q279 154 267 171T248 196L242 204L207 171L173 139V76H206H221Q241 76 251 69T262 38Q262 11 244 3Q240 1 138 1Q123 1 100 1T70 0Q32 0 23 7T13 42',
    0x1D695: '51 573Q51 602 73 610H76Q79 610 84 610T97 610T113 610T133 611T155 611T179 611H282Q301 598 304 586V76H452Q466 67 470 60T474 38Q474 10 452 1H73Q51 9 51 32V38Q51 54 54 60T73 76H220V535H146L73 536Q51 545 51 567V573',
    0x1D696: '133 76Q156 74 164 67T172 38Q172 9 151 1H11Q-12 8 -12 38Q-12 61 5 73Q10 75 28 76H45V355H28Q10 356 5 358Q-12 370 -12 393Q-12 419 11 431H52H70Q91 431 100 427T116 405Q163 436 200 436Q255 436 281 390L285 394Q289 398 292 400T301 407T314 415T329 423T346 429T366 434T389 436H392Q425 436 448 411Q469 390 474 360T480 268V232V203V76H497Q520 74 528 67T536 38Q536 9 515 1H396Q374 9 374 32V38Q374 73 402 76H409V191V242Q409 317 404 339T375 361Q343 361 323 332T299 264Q298 258 298 165V76H315Q338 74 346 67T354 38Q354 9 333 1H214Q192 9 192 32V38Q192 73 220 76H227V191V242Q227 317 222 339T193 361Q161 361 141 332T117 264Q116 258 116 165V76H133',
    0x1D697: '89 431Q94 431 105 431T122 432Q173 432 173 399Q173 394 175 394Q176 394 190 404T233 425T298 436Q343 436 371 423Q411 402 423 365T436 265Q436 257 436 239T435 211V198V76H498Q512 67 516 60T520 38Q520 9 498 1H308Q286 9 286 32V38V45Q286 65 303 73Q309 76 329 76H351V188Q351 204 351 230T352 266Q352 321 341 341T288 361Q253 361 222 341T176 274L174 264L173 170V76H236Q250 67 254 60T258 38Q258 9 236 1H27Q4 8 4 38Q4 53 8 60T27 76H89V355H58L27 356Q4 363 4 393Q4 408 8 415T27 431H89',
    0x1D698: '52 216Q52 318 118 379T261 440Q343 440 407 378T472 216Q472 121 410 58T262 -6Q176 -6 114 58T52 216ZM388 225Q388 281 351 322T261 364Q213 364 175 325T136 225Q136 158 174 114T262 70T350 114T388 225',
    0x1D699: '89 431Q93 431 104 431T121 432Q173 432 173 401V396L182 402Q237 437 305 437Q376 437 434 378T492 217Q492 146 459 93T382 17T291 -6Q261 -6 232 5T188 26L174 37Q173 37 173 -54V-146H236Q250 -155 254 -162T258 -184Q258 -213 236 -221H27Q4 -214 4 -184Q4 -169 8 -162T27 -146H89V355H58L27 356Q4 363 4 393Q4 408 8 415T27 431H89ZM409 215Q409 269 377 315T283 361Q255 361 224 344T177 297L173 290V167Q189 124 213 97T278 70Q330 70 369 111T409 215',
    0x1D69A: '34 215Q34 309 91 368T222 436Q224 436 231 436T242 437Q309 437 372 390V401Q372 419 381 428T414 437Q426 437 432 436T444 430T456 412V-146H489H504Q524 -146 534 -153T545 -184Q545 -211 527 -219Q523 -221 414 -221Q398 -221 374 -221T342 -222Q304 -222 294 -216T283 -184Q283 -157 301 -149Q307 -146 339 -146H372V-51Q372 43 371 43L364 38Q357 33 345 26T318 12T280 -1T236 -6Q155 -6 95 55T34 215ZM117 215Q117 152 157 111T250 70Q289 70 318 92T363 146Q372 163 372 192V215L371 263Q339 360 254 360Q206 360 162 321T117 215',
    0x1D69B: '327 76Q359 76 369 70T380 38Q380 10 359 1H47Q24 8 24 38Q24 54 28 61T47 76H145V355H96L47 356Q24 363 24 393Q24 409 28 416T47 431H207Q223 419 226 414T229 393V387V369Q297 437 394 437Q436 437 461 417T487 368Q487 347 473 332T438 317Q428 317 420 320T407 327T398 337T393 347T390 356L388 361Q348 356 324 345Q228 299 228 170Q228 161 228 151T229 138V76H293H327',
    0x1D69C: '72 317Q72 361 108 396T229 439Q231 439 245 439T268 440Q303 439 324 435T353 427T363 423L372 432Q380 440 397 440Q430 440 430 395Q430 390 430 380T429 366V335Q429 311 422 302T387 293Q364 293 355 300T346 316T343 336T325 353Q306 364 257 364Q209 364 178 351T147 317Q147 284 231 272Q327 256 357 247Q458 210 458 129V121Q458 74 413 34T271 -6Q246 -6 224 -3T189 5T165 14T150 22T144 26Q142 23 139 18T135 11T132 6T128 1T124 -2T119 -4T113 -5T104 -6Q84 -6 78 6T71 43Q71 48 71 60T72 79Q72 132 73 141T81 157Q90 166 115 166Q135 166 142 162T157 140Q168 108 191 90T260 70Q297 70 323 76T361 91T379 110T384 129Q384 157 346 171T247 195T165 212Q119 228 96 256T72 317',
    0x1D69D: '25 395Q26 405 26 408T29 416T35 423T48 431H145V481L146 532Q154 547 161 550T184 554H189Q218 554 227 534Q229 529 229 480V431H405Q406 430 411 427T418 422T422 416T426 407T427 393Q427 387 427 382T424 374T421 368T417 363T413 360T408 358L405 356L317 355H229V249Q229 237 229 214T228 179Q228 126 241 98T295 70Q354 70 365 149Q366 167 375 174Q383 182 407 182H415Q438 182 446 166Q448 161 448 148Q448 84 398 39T282 -6Q226 -6 189 29T146 128Q145 134 145 247V355H96H72Q45 355 35 362T25 395',
    0x1D69E: '4 393Q4 416 15 423T52 431H90Q141 431 151 429T168 417Q171 412 173 409V254L174 100Q182 70 244 70Q320 70 344 119Q349 130 350 144T351 248V355H320L289 356Q266 363 266 393Q266 408 270 415T289 431H351H386Q409 431 418 428T433 411Q435 406 435 241V76H498Q512 67 516 60T520 38Q520 9 498 1H436H394Q372 1 364 5T351 26L342 21Q293 -5 227 -5Q118 -5 96 67Q91 82 90 101T89 227V355H58L27 356Q4 363 4 393',
    0x1D69F: '24 392Q24 417 36 424T79 432Q85 432 103 432T132 431H215Q229 422 233 415T237 393Q237 355 198 355H193H172L262 77L352 355H331H323Q288 355 288 393Q288 409 291 415T310 431H478Q491 423 495 416T500 393Q500 364 478 356L452 355H426L374 190Q320 24 318 20Q307 -4 273 -4H262H251Q217 -4 206 20Q204 24 150 190L98 355H72L47 356Q24 363 24 392',
    0x1D6A0: '54 355Q16 355 16 388V393Q16 423 37 430Q41 431 125 431H162Q206 431 218 425T230 393Q230 366 212 358Q206 355 174 355Q141 355 141 354L150 296Q181 110 181 89V84Q182 85 183 96Q185 118 199 173T218 237Q223 247 245 259H264H268Q294 259 309 240Q315 229 329 174T343 92Q343 84 344 84V86Q344 88 344 91T345 97Q347 125 356 187T374 301T383 354Q383 355 350 355H333Q314 355 304 362T294 393Q294 420 312 428Q318 431 401 431H440Q485 431 496 425T508 393Q508 382 508 377T498 363T470 355L455 354Q455 353 441 271T413 104T396 16Q384 -4 355 -4H351Q315 -4 305 9T280 79Q278 90 276 96Q265 149 265 169Q265 176 264 169Q263 166 263 162Q261 130 248 79T230 18Q220 -4 183 -4H175L151 -3Q134 5 127 17L112 102Q97 188 83 270T69 354Q62 355 54 355',
    0x1D6A1: '35 393Q35 417 46 424T89 432Q95 432 112 432T141 431H223Q238 422 241 415T244 393Q244 389 244 383T237 367T216 355Q209 355 209 354L234 319Q259 286 260 286L308 354Q308 355 301 355Q285 356 278 365T270 384L271 393Q271 420 289 428Q295 431 376 431H459Q460 430 465 427T472 422T476 416T480 407T481 393Q481 368 470 362T434 355H425H392L344 290Q295 225 295 223Q294 223 309 203T350 149L405 77L439 76H453Q474 76 484 69T495 38Q495 10 473 1H303Q281 9 281 32V38Q281 49 282 54T290 67T313 76Q324 76 324 77L259 173L197 77Q202 76 209 76Q225 75 233 68T241 55T242 38Q242 28 242 24T236 12T221 1H51Q29 9 29 32V38Q29 48 29 51T31 59T38 67T51 76H117L171 149Q224 222 224 223L124 355H90H78Q54 355 45 361T35 393',
    0x1D6A2: '26 393Q26 417 37 424T80 431H134H217Q232 422 235 416T239 393Q239 379 236 371T226 360T214 356T197 355L179 354V353L188 330Q197 306 209 272T235 201T259 133T271 89V84L274 95Q279 122 298 185T335 300T352 354Q352 355 331 355Q312 355 304 358Q288 368 288 393Q288 408 291 415T310 431H478Q479 430 484 427T491 422T495 416T499 407T500 393Q500 376 493 367T479 357T458 355H452Q426 355 425 353Q420 337 351 124T280 -94Q240 -195 168 -220Q147 -228 125 -228Q89 -228 66 -201T42 -139Q42 -116 56 -102T93 -87Q117 -87 130 -102T144 -135V-138H126Q121 -148 121 -150T130 -152Q182 -147 207 -87Q211 -78 223 -40T236 1Q230 10 102 355H75L49 356Q26 363 26 393',
    0x1D6A3: '56 1Q40 7 37 14T34 41Q34 59 36 64Q39 67 43 73Q65 95 191 213T341 355H133V334Q133 306 124 297Q116 289 91 289H83Q60 289 51 308Q49 313 49 361L50 409Q59 427 72 430H78Q83 430 92 430T115 430T144 430T179 431T219 431T262 431H450Q452 430 455 428T459 424T463 422T466 419T468 416T469 413T470 409T471 404T472 398T472 391Q472 374 469 368L462 358Q453 349 315 218Q210 122 164 76H391V103Q391 136 400 146Q409 155 433 155Q464 155 473 135Q475 130 475 78V46Q475 24 472 16T453 1H56',
    0x1D6A8: '296 0Q278 3 164 3Q58 3 49 0H40V62H92Q144 62 144 64Q388 682 397 689Q403 698 434 698Q463 698 471 689Q475 686 538 530T663 218L724 64Q724 62 776 62H828V0H817Q796 3 658 3Q509 3 485 0H472V62H517Q561 62 561 63L517 175H262L240 120Q218 65 217 64Q217 62 261 62H306V0H296ZM390 237L492 238L440 365Q390 491 388 491Q287 239 287 237H390',
    0x1D6A9: '720 510Q720 476 704 448T665 404T619 377T580 362L564 359L583 356Q602 353 632 342T690 312Q712 292 725 276Q752 235 752 189V183Q752 160 741 125Q698 18 547 2Q543 1 288 0H39V62H147V624H39V686H264H409Q502 686 542 681T624 655Q720 607 720 510ZM563 513Q563 553 548 578T518 611T486 622Q479 624 385 624H293V382H375Q458 383 467 385Q563 405 563 513ZM590 192Q590 307 505 329Q504 330 503 330L398 331H293V62H391H400H444Q496 62 528 75T580 131Q590 155 590 192',
    0x1D6AA: '425 0L228 3Q63 3 51 0H39V62H147V618H39V680H612V676Q614 670 627 552T643 428V424H581V428Q580 430 576 461T562 524T532 576Q512 596 481 605T426 616T357 618H304V62H439V0H425',
    0x1D6AB: '901 12Q901 7 892 0H479Q65 0 62 2Q56 6 56 11Q56 14 242 347T433 685Q438 694 450 696Q454 698 480 698H506L523 687Q526 683 711 354T899 17Q901 13 901 12ZM653 137L427 538L202 137L315 136H540L653 137',
    0x1D6AC: '723 286Q721 284 700 145T677 3V0H39V62H147V618H39V680H660V676Q662 670 675 552T691 428V424H629V428Q629 429 627 448T618 494T601 541Q574 593 527 605T382 618H374H304V384H336Q338 384 347 384T361 384T376 386T392 390T407 397T421 407T432 423Q442 444 443 482V501H505V205H443V224Q442 258 435 278T411 307T380 318T336 322H304V62H375H394Q429 62 449 62T497 66T541 76T577 95T609 126T632 170T651 232Q661 287 661 289H723V286',
    0x1D6AD: '80 430L92 686H358Q624 686 628 684Q638 679 638 656Q638 640 637 639Q637 638 445 353Q401 288 351 214T277 103L253 67L256 66Q258 66 265 66T279 66T298 66H343Q380 66 406 68T464 81T518 110T557 164T579 250Q583 278 583 298Q583 299 614 299H645V291Q643 281 636 150T627 8V0H353Q79 0 75 2Q64 7 64 31Q64 48 66 52L259 340L451 623Q451 624 384 624Q294 623 259 612Q155 581 143 446Q142 440 142 432V430H80',
    0x1D6AE: '400 0Q376 3 226 3Q75 3 51 0H39V62H147V624H39V686H51Q75 683 226 683Q376 683 400 686H412V624H304V388H595V624H487V686H499Q523 683 673 683Q824 683 848 686H860V624H752V62H860V0H848Q824 3 674 3Q523 3 499 0H487V62H595V326H304V62H412V0H400',
    0x1D6AF: '629 -10T446 -10T164 89T64 340Q64 380 71 420T102 510T163 596T266 662T418 696H438Q488 696 499 695Q582 686 644 655T741 584T796 495T823 409T829 338Q829 188 729 89ZM439 645Q416 645 390 638T333 615T275 564T236 480Q221 423 221 341Q221 272 230 228Q247 144 301 94T447 43T592 93T663 228Q672 272 672 341Q672 645 439 645ZM286 242V446H348V412H545V446H607V242H545V276H348V242H286',
    0x1D6B0: '397 0Q370 3 218 3Q65 3 38 0H25V62H139V624H25V686H38Q65 683 218 683Q370 683 397 686H410V624H296V62H410V0H397',
    0x1D6B1: '400 0Q376 3 226 3Q75 3 51 0H39V62H147V624H39V686H51Q75 683 226 683Q376 683 400 686H412V624H304V338L472 483L634 624H565V686H576Q597 683 728 683Q814 683 829 686H836V624H730L614 524Q507 432 497 422Q496 422 498 418T514 395T553 342T627 241L759 63L805 62H852V0H842Q830 3 701 3Q550 3 526 0H513V62H549Q584 62 584 63Q583 65 486 196T388 328L304 256V62H412V0H400',
    0x1D6B2: '285 0Q267 3 154 3Q56 3 47 0H40V62H131Q131 63 167 160T244 369T321 578T359 678Q366 698 393 698H404H413Q437 698 446 678Q448 672 560 369T674 62H765V0H754Q733 3 604 3Q453 3 429 0H416V62H461L507 63L355 470Q353 468 279 265L203 63L249 62H294V0H285',
    0x1D6B3: '314 0Q296 3 181 3T48 0H39V62H147V624H39V686H305Q316 679 323 667Q330 653 434 414L546 157L658 414Q766 662 773 674Q778 681 788 686H1052V624H944V62H1052V0H1040Q1016 3 874 3T708 0H696V62H804V341L803 618L786 580Q770 543 735 462T671 315Q540 13 536 9Q528 1 507 1Q485 1 477 9Q472 14 408 162T281 457T217 603Q215 603 215 334V62H323V0H314',
    0x1D6B4: '314 0Q296 3 181 3T48 0H39V62H147V624H39V686H171H265Q288 686 297 686T309 684T315 679Q317 676 500 455T684 233V624H576V686H585Q603 683 718 683T851 686H860V624H752V319Q752 15 750 11Q747 4 742 2T718 0H712Q708 0 706 0T700 0T696 1T693 2T690 4T687 7T684 11T679 16T674 23Q671 27 437 311L215 579V62H323V0H314',
    0x1D6B5: '54 465L63 674Q63 675 383 675T703 674L712 465Q712 464 681 464H650V467Q650 490 646 516T632 545Q612 550 383 550H283Q169 550 149 548T124 531Q123 530 123 529Q116 506 116 467V464H85Q54 464 54 465ZM160 256V447H222V414H544V447H606V256H544V289H222V256H160ZM57 0L48 222H110V219Q110 147 125 133Q127 130 130 129T160 127T235 126T383 126Q482 126 530 126T604 127T635 129T641 133Q656 146 656 219V222H718L709 0H57',
    0x1D6B6: '64 339Q64 431 96 502T182 614T295 675T420 696Q469 696 481 695Q620 680 709 589T798 339Q798 173 697 82T432 -10Q262 -10 163 85T64 339ZM625 454Q618 502 600 538T562 593T515 624T469 639T431 642Q331 642 276 563Q232 493 232 353Q232 315 234 285T244 216T267 148T308 94T372 56Q405 46 432 46Q517 46 567 106T627 267Q631 299 631 353Q631 418 625 454',
    0x1D6B7: '400 0Q376 3 226 3Q75 3 51 0H39V62H147V618H39V680H860V618H752V62H860V0H848Q824 3 674 3Q523 3 499 0H487V62H595V618H304V62H412V0H400',
    0x1D6B8: '400 0Q376 3 226 3Q75 3 51 0H39V62H147V624H39V686H253Q435 686 470 685T536 678Q585 668 621 648T675 605T705 557T718 514T721 483T718 451T704 409T673 362T616 322T530 293Q500 288 399 287H304V62H412V0H400ZM553 475Q553 554 537 582T459 622Q451 623 373 624H298V343H372Q457 344 480 350Q527 362 540 390T553 475',
    0x1D6BA: '766 271Q764 266 750 137T735 4V0H407Q74 0 71 4L70 5Q64 9 64 18Q64 24 82 41T213 158L359 288Q360 288 320 336T214 460Q67 633 66 635Q64 638 64 655Q64 679 75 684Q78 686 407 686H735V682Q738 676 751 558T766 434V430H735Q704 430 704 431Q704 434 703 444T696 477T681 520T654 563T613 598Q578 615 527 619T371 624H281L396 489Q506 358 513 351Q517 342 512 334Q503 325 371 208Q338 179 303 147T249 99L231 83L243 81Q258 81 364 81Q382 81 418 81T470 82T513 83T554 88T587 96T619 109T645 129Q689 173 702 260L704 274Q704 275 735 275H766V271',
    0x1D6BB: '41 425Q41 426 51 545T62 669V675H737V669Q738 665 748 546T758 425V419H696V425Q687 517 669 555T595 607Q578 612 522 613H478V62H631V0H615Q585 3 399 3Q214 3 184 0H168V62H321V613H277H263Q164 613 134 561Q113 527 103 425V419H41V425',
    0x1D6BC: '64 556Q69 619 114 658T224 697Q271 697 310 677T375 622T417 554T444 484L447 477V479Q456 516 473 551T516 620T582 676T670 697Q735 697 780 656T829 556Q829 539 818 532H772Q761 539 761 548Q761 571 681 571Q664 571 653 570T623 562T587 537T555 490Q536 448 531 410T525 300V210V62H660V0H646L447 3Q257 1 247 0H233V62H368V210V301Q368 373 363 410T338 490Q324 518 307 536T270 561T240 569T212 571Q132 571 132 548Q132 539 121 532H75Q64 538 64 556',
    0x1D6BD: '609 0Q582 3 415 3T221 0H207V62H342V168L328 169Q193 180 117 241Q64 286 64 343T117 445Q193 506 328 517L342 518V624H207V686H221Q248 683 415 683T609 686H623V624H488V518L502 517Q637 506 713 445Q766 400 766 343T713 241Q637 180 502 169L488 168V62H623V0H609ZM342 219T342 343T340 467Q328 467 304 459Q277 451 261 439T237 409T228 378T226 343Q226 314 229 296T250 259T301 228Q331 219 341 219Q342 219 342 343ZM604 343Q604 365 602 379T591 413T560 446T503 464L489 467Q488 467 488 343T489 219Q499 219 529 228Q554 236 570 248T593 277T602 308T604 343',
    0x1D6BE: '327 0Q306 3 174 3Q52 3 43 0H33V62H98L162 63L360 333L157 624H48V686H59Q80 683 217 683Q368 683 395 686H408V624H335L393 540L452 458L573 623Q573 624 528 624H483V686H494Q515 683 646 683Q769 683 778 686H787V624H658L575 511Q493 398 493 397L508 376Q522 356 553 312T611 229L727 62H835V0H824Q803 3 667 3Q516 3 489 0H476V62H513L549 63L401 274L247 63Q247 62 292 62H338V0H327',
    0x1D6BF: '64 515Q64 532 71 536T104 540H139Q200 540 207 538Q225 533 236 521T253 489T260 454T264 414Q264 340 287 296T347 237Q369 226 373 226Q374 226 374 425V624H239V686H253Q280 683 447 683T641 686H655V624H520V226L522 227Q525 228 531 229T552 240T580 261T606 298T624 354Q627 368 628 394T631 440T637 482T654 518T686 538Q693 540 754 540H794Q817 540 823 536T829 515Q829 500 824 495T811 489T796 483T782 461T775 408Q767 212 568 175Q526 168 521 168Q520 168 520 115V62H655V0H641Q614 3 447 3T253 0H239V62H374V168L364 169Q290 178 243 203Q126 261 118 409Q117 443 111 461T98 484T83 489T70 495T64 515',
    0x1D6C0: '598 645T415 645T232 458Q232 385 275 239T318 26Q318 8 311 4T272 -1Q265 -1 240 -1T196 0H88V3Q86 5 70 108Q52 211 51 212V215H113V212Q123 149 132 133Q136 124 149 122T202 118Q241 118 241 119Q241 132 132 277Q64 378 64 457Q64 564 158 630T403 696Q487 696 543 685T661 638Q722 599 744 549T766 458Q766 434 761 410T749 368T729 327T709 293T684 258T663 229Q632 187 614 160T592 126L589 119Q589 118 628 118Q667 119 680 121T698 133Q702 140 706 160T714 196L717 212V215H779V212Q778 211 760 108Q744 5 742 3V0H634H562Q528 0 520 4T512 26Q512 92 555 238T598 458Q598 645 415 645',
    0x1D6C1: '56 673Q56 679 65 686H892Q901 679 901 673Q901 668 714 331T521 -15Q518 -18 506 -24H452Q440 -19 436 -15Q431 -8 337 162T150 501L57 669Q57 670 56 672V673ZM528 136L758 553H297Q298 551 414 341L528 136',
    0x1D6E2: '208 74Q208 50 254 46Q272 46 272 35Q272 34 270 22Q267 8 264 4T251 0Q249 0 239 0T205 1T141 2Q70 2 50 0H42Q35 7 35 11Q37 38 48 46H62Q132 49 164 96Q170 102 345 401T523 704Q530 716 547 716H555H572Q578 707 578 706L606 383Q634 60 636 57Q641 46 701 46Q726 46 726 36Q726 34 723 22Q720 7 718 4T704 0Q701 0 690 0T651 1T578 2Q484 2 455 0H443Q437 6 437 9T439 27Q443 40 445 43L449 46H469Q523 49 533 63L521 213H283L249 155Q208 86 208 74ZM516 260Q516 271 504 416T490 562L463 519Q447 492 400 412L310 260L413 259Q516 259 516 260',
    0x1D6E3: '231 637Q204 637 199 638T194 649Q194 676 205 682Q206 683 335 683Q594 683 608 681Q671 671 713 636T756 544Q756 480 698 429T565 360L555 357Q619 348 660 311T702 219Q702 146 630 78T453 1Q446 0 242 0Q42 0 39 2Q35 5 35 10Q35 17 37 24Q42 43 47 45Q51 46 62 46H68Q95 46 128 49Q142 52 147 61Q150 65 219 339T288 628Q288 635 231 637ZM649 544Q649 574 634 600T585 634Q578 636 493 637Q473 637 451 637T416 636H403Q388 635 384 626Q382 622 352 506Q352 503 351 500L320 374H401Q482 374 494 376Q554 386 601 434T649 544ZM595 229Q595 273 572 302T512 336Q506 337 429 337Q311 337 310 336Q310 334 293 263T258 122L240 52Q240 48 252 48T333 46Q422 46 429 47Q491 54 543 105T595 229',
    0x1D6E4: '49 1Q31 1 31 10Q31 12 34 24Q39 43 44 45Q48 46 59 46H65Q92 46 125 49Q139 52 144 61Q146 66 215 342T285 622Q285 629 281 629Q273 632 228 634H197Q191 640 191 642T193 661Q197 674 203 680H714Q721 676 721 669Q721 664 708 557T694 447Q692 440 674 440H662Q655 445 655 454Q655 455 658 480T661 534Q661 572 652 592Q638 619 603 626T501 634H471Q398 633 393 630Q389 628 386 622Q385 619 315 341T245 60Q245 46 333 46H345Q366 46 366 35Q366 33 363 21T358 6Q356 1 339 1Q334 1 292 1T187 2Q122 2 88 2T49 1',
    0x1D6E5: '574 715L582 716Q589 716 595 716Q612 716 616 714Q621 712 621 709Q622 707 705 359T788 8Q786 5 785 3L781 0H416Q52 0 50 2T48 6Q48 9 305 358T567 711Q572 712 574 715ZM599 346L538 602L442 474Q347 345 252 217T157 87T409 86T661 88L654 120Q646 151 629 220T599 346',
    0x1D6E6: '492 213Q472 213 472 226Q472 230 477 250T482 285Q482 316 461 323T364 330H312Q311 328 277 192T243 52Q243 48 254 48T334 46Q428 46 458 48T518 61Q567 77 599 117T670 248Q680 270 683 272Q690 274 698 274Q718 274 718 261Q613 7 608 2Q605 0 322 0H133Q31 0 31 11Q31 13 34 25Q38 41 42 43T65 46Q92 46 125 49Q139 52 144 61Q146 66 215 342T285 622Q285 629 281 629Q273 632 228 634H197Q191 640 191 642T193 659Q197 676 203 680H757Q764 676 764 669Q764 664 751 557T737 447Q735 440 717 440H705Q698 445 698 453L701 476Q704 500 704 528Q704 558 697 578T678 609T643 625T596 632T532 634H485Q397 633 392 631Q388 629 386 622Q385 619 355 499T324 377Q347 376 372 376H398Q464 376 489 391T534 472Q538 488 540 490T557 493Q562 493 565 493T570 492T572 491T574 487T577 483L544 351Q511 218 508 216Q505 213 492 213',
    0x1D6E7: '58 8Q58 23 64 35Q64 36 329 334T596 635L586 637Q575 637 512 637H500H476Q442 637 420 635T365 624T311 598T266 548T228 469Q227 466 226 463T224 458T223 453T222 450L221 448Q218 443 202 443Q185 443 182 453L214 561Q228 606 241 651Q249 679 253 681Q256 683 487 683H718Q723 678 723 675Q723 673 717 649Q189 54 188 52L185 49H274Q369 50 377 51Q452 60 500 100T579 247Q587 272 590 277T603 282H607Q628 282 628 271Q547 5 541 2Q538 0 300 0H124Q58 0 58 8',
    0x1D6E8: '228 637Q194 637 192 641Q191 643 191 649Q191 673 202 682Q204 683 219 683Q260 681 355 681Q389 681 418 681T463 682T483 682Q499 682 499 672Q499 670 497 658Q492 641 487 638H485Q483 638 480 638T473 638T464 637T455 637Q416 636 405 634T387 623Q384 619 355 500Q348 474 340 442T328 395L324 380Q324 378 469 378H614L615 381Q615 384 646 504Q674 619 674 627T617 637Q594 637 587 639T580 648Q580 650 582 660Q586 677 588 679T604 682Q609 682 646 681T740 680Q802 680 835 681T871 682Q888 682 888 672Q888 645 876 638H874Q872 638 869 638T862 638T853 637T844 637Q805 636 794 634T776 623Q773 618 704 340T634 58Q634 51 638 51Q646 48 692 46H723Q729 38 729 37T726 19Q722 6 716 0H701Q664 2 567 2Q533 2 504 2T458 2T437 1Q420 1 420 10Q420 15 423 24Q428 43 433 45Q437 46 448 46H454Q481 46 514 49Q520 50 522 50T528 55T534 64T540 82T547 110T558 153Q565 181 569 198Q602 330 602 331T457 332H312L279 197Q245 63 245 58Q245 51 253 49T303 46H334Q340 38 340 37T337 19Q333 6 327 0H312Q275 2 178 2Q144 2 115 2T69 2T48 1Q31 1 31 10Q31 12 34 24Q39 43 44 45Q48 46 59 46H65Q92 46 125 49Q139 52 144 61Q147 65 216 339T285 628Q285 635 228 637',
    0x1D6E9: '740 435Q740 320 676 213T511 42T304 -22Q207 -22 138 35T51 201Q50 209 50 244Q50 346 98 438T227 601Q351 704 476 704Q514 704 524 703Q621 689 680 617T740 435ZM640 466Q640 523 625 565T583 628T532 658T479 668Q370 668 273 559T151 255Q150 245 150 213Q150 156 165 116T207 55T259 26T313 17Q385 17 451 63T561 184Q590 234 615 312T640 466ZM510 276Q510 278 512 288L515 298Q515 299 384 299H253L250 285Q246 271 244 268T231 265H227Q216 265 214 266T207 274Q207 278 223 345T244 416Q247 419 260 419H263Q280 419 280 408Q280 406 278 396L275 386Q275 385 406 385H537L540 399Q544 413 546 416T559 419H563Q574 419 576 418T583 410Q583 403 566 339Q549 271 544 267Q542 265 538 265H530H527Q510 265 510 276',
    0x1D6EA: '43 1Q26 1 26 10Q26 12 29 24Q34 43 39 45Q42 46 54 46H60Q120 46 136 53Q137 53 138 54Q143 56 149 77T198 273Q210 318 216 344Q286 624 286 626Q284 630 284 631Q274 637 213 637H193Q184 643 189 662Q193 677 195 680T209 683H213Q285 681 359 681Q481 681 487 683H497Q504 676 504 672T501 655T494 639Q491 637 471 637Q440 637 407 634Q393 631 388 623Q381 609 337 432Q326 385 315 341Q245 65 245 59Q245 52 255 50T307 46H339Q345 38 345 37T342 19Q338 6 332 0H316Q279 2 179 2Q143 2 113 2T65 2T43 1',
    0x1D6EB: '285 628Q285 635 228 637Q205 637 198 638T191 647Q191 649 193 661Q199 681 203 682Q205 683 214 683H219Q260 681 355 681Q389 681 418 681T463 682T483 682Q500 682 500 674Q500 669 497 660Q496 658 496 654T495 648T493 644T490 641T486 639T479 638T470 637T456 637Q416 636 405 634T387 623L306 305Q307 305 490 449T678 597Q692 611 692 620Q692 635 667 637Q651 637 651 648Q651 650 654 662T659 677Q662 682 676 682Q680 682 711 681T791 680Q814 680 839 681T869 682Q889 682 889 672Q889 650 881 642Q878 637 862 637Q787 632 726 586Q710 576 656 534T556 455L509 418L518 396Q527 374 546 329T581 244Q656 67 661 61Q663 59 666 57Q680 47 717 46H738Q744 38 744 37T741 19Q737 6 731 0H720Q680 3 625 3Q503 3 488 0H478Q472 6 472 9T474 27Q478 40 480 43T491 46H494Q544 46 544 71Q544 75 517 141T485 216L427 354L359 301L291 248L268 155Q245 63 245 58Q245 51 253 49T303 46H334Q340 37 340 35Q340 19 333 5Q328 0 317 0Q314 0 280 1T180 2Q118 2 85 2T49 1Q31 1 31 11Q31 13 34 25Q38 41 42 43T65 46Q92 46 125 49Q139 52 144 61Q147 65 216 339T285 628',
    0x1D6EC: '135 2Q114 2 90 2T60 1Q35 1 35 11Q35 28 42 40Q45 46 55 46Q119 46 151 94Q153 97 325 402T498 709Q505 716 526 716Q543 716 549 710Q550 709 560 548T580 224T591 57Q594 52 595 52Q603 47 638 46H663Q670 39 670 35Q669 12 657 0H644Q613 2 530 2Q497 2 469 2T424 2T405 1Q388 1 388 10Q388 15 391 24Q392 27 393 32T395 38T397 41T401 44T406 45T415 46Q473 46 487 64L472 306Q468 365 465 426T459 518L457 550Q456 550 328 322T198 88Q196 80 196 77Q196 49 243 46Q261 46 261 35Q261 34 259 22Q256 7 254 4T240 0Q237 0 211 1T135 2',
    0x1D6ED: '289 629Q289 635 232 637Q208 637 201 638T194 648Q194 649 196 659Q197 662 198 666T199 671T201 676T203 679T207 681T212 683T220 683T232 684Q238 684 262 684T307 683Q386 683 398 683T414 678Q415 674 451 396L487 117L510 154Q534 190 574 254T662 394Q837 673 839 675Q840 676 842 678T846 681L852 683H948Q965 683 988 683T1017 684Q1051 684 1051 673Q1051 668 1048 656T1045 643Q1041 637 1008 637Q968 636 957 634T939 623Q936 618 867 340T797 59Q797 55 798 54T805 50T822 48T855 46H886Q892 37 892 35Q892 19 885 5Q880 0 869 0Q864 0 828 1T736 2Q675 2 644 2T609 1Q592 1 592 11Q592 13 594 25Q598 41 602 43T625 46Q652 46 685 49Q699 52 704 61Q706 65 742 207T813 490T848 631L654 322Q458 10 453 5Q451 4 449 3Q444 0 433 0Q418 0 415 7Q413 11 374 317L335 624L267 354Q200 88 200 79Q206 46 272 46H282Q288 41 289 37T286 19Q282 3 278 1Q274 0 267 0Q265 0 255 0T221 1T157 2Q127 2 95 1T58 0Q43 0 39 2T35 11Q35 13 38 25T43 40Q45 46 65 46Q135 46 154 86Q158 92 223 354T289 629',
    0x1D6EE: '234 637Q231 637 226 637Q201 637 196 638T191 649Q191 676 202 682Q204 683 299 683Q376 683 387 683T401 677Q612 181 616 168L670 381Q723 592 723 606Q723 633 659 637Q635 637 635 648Q635 650 637 660Q641 676 643 679T653 683Q656 683 684 682T767 680Q817 680 843 681T873 682Q888 682 888 672Q888 650 880 642Q878 637 858 637Q787 633 769 597L620 7Q618 0 599 0Q585 0 582 2Q579 5 453 305L326 604L261 344Q196 88 196 79Q201 46 268 46H278Q284 41 284 38T282 19Q278 6 272 0H259Q228 2 151 2Q123 2 100 2T63 2T46 1Q31 1 31 10Q31 14 34 26T39 40Q41 46 62 46Q130 49 150 85Q154 91 221 362L289 634Q287 635 234 637',
    0x1D6EF: '222 668Q222 670 229 677H654Q677 677 705 677T740 678Q764 678 770 676T777 667Q777 662 764 594Q761 579 757 559T751 528L749 519Q747 512 729 512H717Q710 519 710 525Q712 532 715 559T719 591Q718 595 711 595Q682 598 486 598Q252 598 246 592Q239 587 228 552L216 517Q214 512 197 512H185Q178 517 178 522Q178 524 198 591T222 668ZM227 262Q218 262 215 262T209 266L207 270L227 356Q247 435 250 439Q253 443 260 443H267H280Q287 438 287 433Q287 430 285 420T280 402L278 393Q278 392 431 392H585L590 415Q595 436 598 439T612 443H628Q635 438 635 433Q635 431 615 351T594 268Q592 262 575 262H572Q556 262 556 272Q556 280 560 293L565 313H258L252 292Q248 271 245 267T230 262H227ZM60 0Q53 4 53 11Q53 14 68 89T84 169Q88 176 98 176H104H116Q123 169 123 163Q122 160 117 127T112 88Q112 80 243 80H351H454Q554 80 574 81T597 88V89Q603 100 610 121T622 157T630 174Q633 176 646 176H658Q665 171 665 166Q665 164 643 89T618 7Q616 2 607 1T548 0H335H60',
    0x1D6F0: '740 435Q740 320 676 213T511 42T304 -22Q207 -22 138 35T51 201Q50 209 50 244Q50 346 98 438T227 601Q351 704 476 704Q514 704 524 703Q621 689 680 617T740 435ZM637 476Q637 565 591 615T476 665Q396 665 322 605Q242 542 200 428T157 216Q157 126 200 73T314 19Q404 19 485 98T608 313Q637 408 637 476',
    0x1D6F1: '48 1Q31 1 31 10Q31 12 34 24Q39 43 44 45Q48 46 59 46H65Q92 46 125 49Q139 52 144 61Q146 66 215 342T285 622Q285 629 281 629Q273 632 228 634H197Q191 640 191 642T193 661Q197 674 203 680H541Q621 680 709 680T812 681Q841 681 855 681T877 679T886 676T887 670Q887 663 885 656Q880 637 875 635Q871 634 860 634H854Q827 634 794 631Q780 628 775 619Q773 614 704 338T634 58Q634 51 638 51Q646 48 692 46H723Q729 38 729 37T726 19Q722 6 716 0H701Q664 2 567 2Q533 2 504 2T458 2T437 1Q420 1 420 10Q420 15 423 24Q428 43 433 45Q437 46 448 46H454Q481 46 514 49Q528 52 533 61Q536 67 572 209T642 491T678 632Q678 634 533 634H388Q387 631 316 347T245 59Q245 55 246 54T253 50T270 48T303 46H334Q340 38 340 37T337 19Q333 6 327 0H312Q275 2 178 2Q144 2 115 2T69 2T48 1',
    0x1D6F2: '287 628Q287 635 230 637Q206 637 199 638T192 648Q192 649 194 659Q200 679 203 681T397 683Q587 682 600 680Q664 669 707 631T751 530Q751 453 685 389Q616 321 507 303Q500 302 402 301H307L277 182Q247 66 247 59Q247 55 248 54T255 50T272 48T305 46H336Q342 37 342 35Q342 19 335 5Q330 0 319 0Q316 0 282 1T182 2Q120 2 87 2T51 1Q33 1 33 11Q33 13 36 25Q40 41 44 43T67 46Q94 46 127 49Q141 52 146 61Q149 65 218 339T287 628ZM645 554Q645 567 643 575T634 597T609 619T560 635Q553 636 480 637Q463 637 445 637T416 636T404 636Q391 635 386 627Q384 621 367 550T332 412T314 344Q314 342 395 342H407H430Q542 342 590 392Q617 419 631 471T645 554',
    0x1D6F4: '65 0Q58 4 58 11Q58 16 114 67Q173 119 222 164L377 304Q378 305 340 386T261 552T218 644Q217 648 219 660Q224 678 228 681Q231 683 515 683H799Q804 678 806 674Q806 667 793 559T778 448Q774 443 759 443Q747 443 743 445T739 456Q739 458 741 477T743 516Q743 552 734 574T710 609T663 627T596 635T502 637Q480 637 469 637H339Q344 627 411 486T478 341V339Q477 337 477 336L457 318Q437 300 398 265T322 196L168 57Q167 56 188 56T258 56H359Q426 56 463 58T537 69T596 97T639 146T680 225Q686 243 689 246T702 250H705Q726 250 726 239Q726 238 683 123T639 5Q637 1 610 1Q577 0 348 0H65',
    0x1D6F5: '40 437Q21 437 21 445Q21 450 37 501T71 602L88 651Q93 669 101 677H569H659Q691 677 697 676T704 667Q704 661 687 553T668 444Q668 437 649 437Q640 437 637 437T631 442L629 445Q629 451 635 490T641 551Q641 586 628 604T573 629Q568 630 515 631Q469 631 457 630T439 622Q438 621 368 343T298 60Q298 48 386 46Q418 46 427 45T436 36Q436 31 433 22Q429 4 424 1L422 0Q419 0 415 0Q410 0 363 1T228 2Q99 2 64 0H49Q43 6 43 9T45 27Q49 40 55 46H83H94Q174 46 189 55Q190 56 191 56Q196 59 201 76T241 233Q258 301 269 344Q339 619 339 625Q339 630 310 630H279Q212 630 191 624Q146 614 121 583T67 467Q60 445 57 441T43 437H40',
    0x1D6F6: '45 535Q34 535 31 536T28 544Q28 554 39 578T70 631T126 683T206 705Q230 705 251 698T295 671T330 612T344 514Q344 477 342 473V472Q343 472 347 480T361 509T380 547Q471 704 596 704Q615 704 625 702Q659 692 679 663T700 595Q700 565 696 552T687 537T670 535Q656 535 653 536T649 543Q649 544 649 550T650 562Q650 589 629 605T575 621Q502 621 448 547T365 361Q290 70 290 60Q290 46 379 46H404Q410 40 410 39T408 19Q404 6 398 0H381Q340 2 225 2Q184 2 149 2T94 2T69 1Q61 1 58 1T53 4T51 10Q51 11 53 23Q54 25 55 30T56 36T58 40T60 43T62 44T67 46T73 46T82 46H89Q144 46 163 49T190 62L198 93Q206 124 217 169T241 262T262 350T274 404Q281 445 281 486V494Q281 621 185 621Q147 621 116 601T74 550Q71 539 66 537T45 535',
    0x1D6F7: '356 624Q356 637 267 637H243Q237 642 237 645T239 664Q243 677 249 683H264Q342 681 429 681Q565 681 571 683H583Q589 677 589 674T587 656Q582 641 578 637H540Q516 637 504 637T479 633T463 630T454 623T448 613T443 597T438 576Q436 566 434 556T430 539L428 533Q442 533 472 526T543 502T613 451T642 373Q642 301 567 241T386 158L336 150Q332 150 331 146Q310 66 310 60Q310 46 399 46H424Q430 40 430 39T428 19Q424 6 418 0H401Q360 2 247 2Q207 2 173 2T119 2T95 1Q87 1 84 1T79 4T77 10Q77 11 79 23Q80 25 81 30T82 36T84 40T86 43T88 44T93 46T99 46T108 46H115Q170 46 189 49T216 62Q220 74 228 107L239 150L223 152Q139 164 82 205T24 311Q24 396 125 462Q207 517 335 533L346 578Q356 619 356 624ZM130 291Q130 203 241 188H249Q249 190 287 342L325 495H324Q313 495 291 491T229 466T168 414Q130 357 130 291ZM536 393Q536 440 507 463T418 496L341 187L351 189Q443 201 487 255Q536 314 536 393',
    0x1D6F8: '42 0H40Q26 0 26 11Q26 15 29 27Q33 41 36 43T55 46Q141 49 190 98Q200 108 306 224T411 342Q302 620 297 625Q288 636 234 637H206Q200 643 200 645T202 664Q206 677 212 683H226Q260 681 347 681Q380 681 408 681T453 682T473 682Q490 682 490 671Q490 670 488 658Q484 643 481 640T465 637Q434 634 411 620L488 426L541 485Q646 598 646 610Q646 628 622 635Q617 635 609 637Q594 637 594 648Q594 650 596 664Q600 677 606 683H618Q619 683 643 683T697 681T738 680Q828 680 837 683H845Q852 676 852 672Q850 647 840 637H824Q790 636 763 628T722 611T698 593L687 584Q687 585 592 480L505 384Q505 383 536 304T601 142T638 56Q648 47 699 46Q734 46 734 37Q734 35 732 23Q728 7 725 4T711 1Q708 1 678 1T589 2Q528 2 496 2T461 1Q444 1 444 10Q444 11 446 25Q448 35 450 39T455 44T464 46T480 47T506 54Q523 62 523 64Q522 64 476 181L429 299Q241 95 236 84Q232 76 232 72Q232 53 261 47Q262 47 267 47T273 46Q276 46 277 46T280 45T283 42T284 35Q284 26 282 19Q279 6 276 4T261 1Q258 1 243 1T201 2T142 2Q64 2 42 0',
    0x1D6F9: '216 151Q48 174 48 329Q48 361 56 403T65 458Q65 482 58 494T43 507T28 510T21 520Q21 528 23 534T29 544L32 546H72H94Q110 546 119 544T139 536T154 514T159 476V465Q159 445 149 399T138 314Q142 229 197 201Q223 187 226 190L233 218Q240 246 253 300T280 407Q333 619 333 625Q333 637 244 637H220Q214 642 214 645T216 664Q220 677 226 683H241Q321 681 405 681Q543 681 549 683H560Q566 677 566 674T564 656Q559 641 555 637H517Q448 636 436 628Q429 623 423 600T373 404L320 192Q370 201 419 248Q451 281 469 317T500 400T518 457Q529 486 542 505T569 532T594 543T621 546H644H669Q692 546 692 536Q691 509 676 509Q623 509 593 399Q587 377 579 355T552 301T509 244T446 195T359 159Q324 151 314 151Q311 151 310 150T298 106T287 60Q287 46 376 46H401Q407 40 407 39T405 19Q401 6 395 0H378Q337 2 224 2Q184 2 150 2T96 2T72 1Q64 1 61 1T56 4T54 10Q54 11 56 23Q57 25 58 30T59 36T61 40T63 43T65 44T70 46T76 46T85 46H92Q147 46 166 49T193 62L204 106Q216 149 216 151',
    0x1D6FA: '125 84Q127 78 194 76H243V78Q243 122 208 215T165 350Q164 359 162 389Q162 522 272 610Q328 656 396 680T525 704Q628 704 698 661Q734 637 755 601T781 544T786 504Q786 439 747 374T635 226T537 109Q518 81 518 77Q537 76 557 76Q608 76 620 78T640 92Q646 100 656 119T673 155T683 172Q690 173 698 173Q718 173 718 162Q718 161 681 82T642 2Q639 0 550 0H461Q455 5 455 9T458 28Q472 78 510 149T584 276T648 402T677 525Q677 594 636 631T530 668Q476 668 423 641T335 568Q284 499 271 400Q270 388 270 348Q270 298 277 228T285 115Q285 82 280 49T271 6Q269 1 258 1T175 0H87Q83 3 80 7V18Q80 22 82 98Q84 156 85 163T91 172Q94 173 104 173T119 172Q124 169 124 126Q125 104 125 84',
    0x1D6FC: '34 156Q34 270 120 356T309 442Q379 442 421 402T478 304Q484 275 485 237V208Q534 282 560 374Q564 388 566 390T582 393Q603 393 603 385Q603 376 594 346T558 261T497 161L486 147L487 123Q489 67 495 47T514 26Q528 28 540 37T557 60Q559 67 562 68T577 70Q597 70 597 62Q597 56 591 43Q579 19 556 5T512 -10H505Q438 -10 414 62L411 69L400 61Q390 53 370 41T325 18T267 -2T203 -11Q124 -11 79 39T34 156ZM208 26Q257 26 306 47T379 90L403 112Q401 255 396 290Q382 405 304 405Q235 405 183 332Q156 292 139 224T121 120Q121 71 146 49T208 26',
    0x1D6FD: '29 -194Q23 -188 23 -186Q23 -183 102 134T186 465Q208 533 243 584T309 658Q365 705 429 705H431Q493 705 533 667T573 570Q573 465 469 396L482 383Q533 332 533 252Q533 139 448 65T257 -10Q227 -10 203 -2T165 17T143 40T131 59T126 65L62 -188Q60 -194 42 -194H29ZM353 431Q392 431 427 419L432 422Q436 426 439 429T449 439T461 453T472 471T484 495T493 524T501 560Q503 569 503 593Q503 611 502 616Q487 667 426 667Q384 667 347 643T286 582T247 514T224 455Q219 439 186 308T152 168Q151 163 151 147Q151 99 173 68Q204 26 260 26Q302 26 349 51T425 137Q441 171 449 214T457 279Q457 337 422 372Q380 358 347 358H337Q258 358 258 389Q258 396 261 403Q275 431 353 431',
    0x1D6FE: '31 249Q11 249 11 258Q11 275 26 304T66 365T129 418T206 441Q233 441 239 440Q287 429 318 386T371 255Q385 195 385 170Q385 166 386 166L398 193Q418 244 443 300T486 391T508 430Q510 431 524 431H537Q543 425 543 422Q543 418 522 378T463 251T391 71Q385 55 378 6T357 -100Q341 -165 330 -190T303 -216Q286 -216 286 -188Q286 -138 340 32L346 51L347 69Q348 79 348 100Q348 257 291 317Q251 355 196 355Q148 355 108 329T51 260Q49 251 47 251Q45 249 31 249',
    0x1D6FF: '195 609Q195 656 227 686T302 717Q319 716 351 709T407 697T433 690Q451 682 451 662Q451 644 438 628T403 612Q382 612 348 641T288 671T249 657T235 628Q235 584 334 463Q401 379 401 292Q401 169 340 80T205 -10H198Q127 -10 83 36T36 153Q36 286 151 382Q191 413 252 434Q252 435 245 449T230 481T214 521T201 566T195 609ZM112 130Q112 83 136 55T204 27Q233 27 256 51T291 111T309 178T316 232Q316 267 309 298T295 344T269 400L259 396Q215 381 183 342T137 256T118 179T112 130',
    0x1D700: '190 -22Q124 -22 76 11T27 107Q27 174 97 232L107 239L99 248Q76 273 76 304Q76 364 144 408T290 452H302Q360 452 405 421Q428 405 428 392Q428 381 417 369T391 356Q382 356 371 365T338 383T283 392Q217 392 167 368T116 308Q116 289 133 272Q142 263 145 262T157 264Q188 278 238 278H243Q308 278 308 247Q308 206 223 206Q177 206 142 219L132 212Q68 169 68 112Q68 39 201 39Q253 39 286 49T328 72T345 94T362 105Q376 103 376 88Q376 79 365 62T334 26T275 -8T190 -22',
    0x1D701: '296 643Q298 704 324 704Q342 704 342 687Q342 682 339 664T336 633Q336 623 337 618T338 611Q339 612 341 612Q343 614 354 616T374 618L384 619H394Q471 619 471 586Q467 548 386 546H372Q338 546 320 564L311 558Q235 506 175 398T114 190Q114 171 116 155T125 127T137 104T153 86T171 72T192 61T213 53T235 46T256 39L322 16Q389 -10 389 -80Q389 -119 364 -154T300 -202Q292 -204 274 -204Q247 -204 225 -196Q210 -192 193 -182T172 -167Q167 -159 173 -148Q180 -139 191 -139Q195 -139 221 -153T283 -168Q298 -166 310 -152T322 -117Q322 -91 302 -75T250 -51T183 -29T116 4T65 62T44 160Q44 287 121 410T293 590L302 595Q296 613 296 643',
    0x1D702: '21 287Q22 290 23 295T28 317T38 348T53 381T73 411T99 433T132 442Q156 442 175 435T205 417T221 395T229 376L231 369Q231 367 232 367L243 378Q304 442 382 442Q436 442 469 415T503 336V326Q503 302 439 53Q381 -182 377 -189Q364 -216 332 -216Q319 -216 310 -208T299 -186Q299 -177 358 57L420 307Q423 322 423 345Q423 404 379 404H374Q288 404 229 303L222 291L189 157Q156 26 151 16Q138 -11 108 -11Q95 -11 87 -5T76 7T74 17Q74 30 114 189T154 366Q154 405 128 405Q107 405 92 377T68 316T57 280Q55 278 41 278H27Q21 284 21 287',
    0x1D703: '35 200Q35 302 74 415T180 610T319 704Q320 704 327 704T339 705Q393 701 423 656Q462 596 462 495Q462 380 417 261T302 66T168 -10H161Q125 -10 99 10T60 63T41 130T35 200ZM383 566Q383 668 330 668Q294 668 260 623T204 521T170 421T157 371Q206 370 254 370L351 371Q352 372 359 404T375 484T383 566ZM113 132Q113 26 166 26Q181 26 198 36T239 74T287 161T335 307L340 324H145Q145 321 136 286T120 208T113 132',
    0x1D704: '139 -10Q111 -10 92 0T64 25T52 52T48 74Q48 89 55 109T85 199T135 375L137 384Q139 394 140 397T145 409T151 422T160 431T173 439T190 442Q202 442 213 435T225 410Q225 404 214 358T181 238T137 107Q126 74 126 54Q126 43 126 39T130 31T142 27H147Q206 27 255 78Q272 98 281 114T290 138T295 149T313 153Q321 153 324 153T329 152T332 149T332 143Q332 106 276 48T145 -10H139',
    0x1D705: '83 -11Q70 -11 62 -4T51 8T49 17Q49 30 96 217T147 414Q160 442 193 442Q205 441 213 435T223 422T225 412Q225 401 208 337L192 270Q193 269 208 277T235 292Q252 304 306 349T396 412T467 431Q489 431 500 420T512 391Q512 366 494 347T449 327Q430 327 418 338T405 368Q405 370 407 380L397 375Q368 360 315 315L253 266L240 257H245Q262 257 300 251T366 230Q422 203 422 150Q422 140 417 114T411 67Q411 26 437 26Q484 26 513 137Q516 149 519 151T535 153Q554 153 554 144Q554 121 527 64T457 -7Q447 -10 431 -10Q386 -10 360 17T333 90Q333 108 336 122T339 146Q339 170 320 186T271 209T222 218T185 221H180L155 122Q129 22 126 16Q113 -11 83 -11',
    0x1D706: '166 673Q166 685 183 694H202Q292 691 316 644Q322 629 373 486T474 207T524 67Q531 47 537 34T546 15T551 6T555 2T556 -2T550 -11H482Q457 3 450 18T399 152L354 277L340 262Q327 246 293 207T236 141Q211 112 174 69Q123 9 111 -1T83 -12Q47 -12 47 20Q47 37 61 52T199 187Q229 216 266 252T321 306L338 322Q338 323 288 462T234 612Q214 657 183 657Q166 657 166 673',
    0x1D707: '58 -216Q44 -216 34 -208T23 -186Q23 -176 96 116T173 414Q186 442 219 442Q231 441 239 435T249 423T251 413Q251 401 220 279T187 142Q185 131 185 107V99Q185 26 252 26Q261 26 270 27T287 31T302 38T315 45T327 55T338 65T348 77T356 88T365 100L372 110L408 253Q444 395 448 404Q461 431 491 431Q504 431 512 424T523 412T525 402L449 84Q448 79 448 68Q448 43 455 35T476 26Q485 27 496 35Q517 55 537 131Q543 151 547 152Q549 153 557 153H561Q580 153 580 144Q580 138 575 117T555 63T523 13Q510 0 491 -8Q483 -10 467 -10Q446 -10 429 -4T402 11T385 29T376 44T374 51L368 45Q362 39 350 30T324 12T288 -4T246 -11Q199 -11 153 12L129 -85Q108 -167 104 -180T92 -202Q76 -216 58 -216',
    0x1D708: '74 431Q75 431 146 436T219 442Q231 442 231 434Q231 428 185 241L137 51H140L150 55Q161 59 177 67T214 86T261 119T312 165Q410 264 445 394Q458 442 496 442Q509 442 519 434T530 411Q530 390 516 352T469 262T388 162T267 70T106 5Q81 -2 71 -2Q66 -2 59 -1T51 1Q45 5 45 11Q45 13 88 188L132 364Q133 377 125 380T86 385H65Q59 391 59 393T61 412Q65 431 74 431',
    0x1D709: '268 632Q268 704 296 704Q314 704 314 687Q314 682 311 664T308 635T309 620V616H315Q342 619 360 619Q443 619 443 586Q439 548 358 546H344Q326 546 317 549T290 566Q257 550 226 505T195 405Q195 381 201 364T211 342T218 337Q266 347 298 347Q375 347 375 314Q374 297 359 288T327 277T280 275Q234 275 208 283L195 286Q149 260 119 214T88 130Q88 116 90 108Q101 79 129 63T229 20Q238 17 243 15Q337 -21 354 -33Q383 -53 383 -94Q383 -137 351 -171T273 -205Q240 -205 202 -190T158 -167Q156 -163 156 -159Q156 -151 161 -146T176 -140Q182 -140 189 -143Q232 -168 274 -168Q286 -168 292 -165Q313 -151 313 -129Q313 -112 301 -104T232 -75Q214 -68 204 -64Q198 -62 171 -52T136 -38T107 -24T78 -8T56 12T36 37T26 66T21 103Q21 149 55 206T145 301L154 307L148 313Q141 319 136 323T124 338T111 358T103 382T99 413Q99 471 143 524T259 602L271 607Q268 618 268 632',
    0x1D70A: '201 -11Q126 -11 80 38T34 156Q34 221 64 279T146 380Q222 441 301 441Q333 441 341 440Q354 437 367 433T402 417T438 387T464 338T476 268Q476 161 390 75T201 -11ZM121 120Q121 70 147 48T206 26Q250 26 289 58T351 142Q360 163 374 216T388 308Q388 352 370 375Q346 405 306 405Q243 405 195 347Q158 303 140 230T121 120',
    0x1D70B: '132 -11Q98 -11 98 22V33L111 61Q186 219 220 334L228 358H196Q158 358 142 355T103 336Q92 329 81 318T62 297T53 285Q51 284 38 284Q19 284 19 294Q19 300 38 329T93 391T164 429Q171 431 389 431Q549 431 553 430Q573 423 573 402Q573 371 541 360Q535 358 472 358H408L405 341Q393 269 393 222Q393 170 402 129T421 65T431 37Q431 20 417 5T381 -10Q370 -10 363 -7T347 17T331 77Q330 86 330 121Q330 170 339 226T357 318T367 358H269L268 354Q268 351 249 275T206 114T175 17Q164 -11 132 -11',
    0x1D70C: '58 -216Q25 -216 23 -186Q23 -176 73 26T127 234Q143 289 182 341Q252 427 341 441Q343 441 349 441T359 442Q432 442 471 394T510 276Q510 219 486 165T425 74T345 13T266 -10H255H248Q197 -10 165 35L160 41L133 -71Q108 -168 104 -181T92 -202Q76 -216 58 -216ZM424 322Q424 359 407 382T357 405Q322 405 287 376T231 300Q217 269 193 170L176 102Q193 26 260 26Q298 26 334 62Q367 92 389 158T418 266T424 322',
    0x1D70D: '31 207Q31 306 115 374T302 442Q341 442 373 430T405 400Q405 392 399 383T379 374Q373 375 348 390T296 405Q222 405 160 357T98 249Q98 232 103 218T112 195T132 175T154 159T186 141T219 122Q234 114 255 102T286 85T299 78L302 74Q306 71 308 69T315 61T322 51T328 40T332 25T334 8Q334 -31 305 -69T224 -107Q194 -107 163 -92Q156 -88 156 -80Q156 -73 162 -67T178 -61Q186 -61 190 -63Q209 -71 224 -71Q244 -71 253 -59T263 -30Q263 -25 263 -21T260 -12T255 -4T248 3T239 9T227 17T213 25T195 34T174 46Q170 48 150 58T122 74T97 90T70 112T51 137T36 169T31 207',
    0x1D70E: '184 -11Q116 -11 74 34T31 147Q31 247 104 333T274 430Q275 431 414 431H552Q553 430 555 429T559 427T562 425T565 422T567 420T569 416T570 412T571 407T572 401Q572 357 507 357Q500 357 490 357T476 358H416L421 348Q439 310 439 263Q439 153 359 71T184 -11ZM361 278Q361 358 276 358Q152 358 115 184Q114 180 114 178Q106 141 106 117Q106 67 131 47T188 26Q242 26 287 73Q316 103 334 153T356 233T361 278',
    0x1D70F: '39 284Q18 284 18 294Q18 301 45 338T99 398Q134 425 164 429Q170 431 332 431Q492 431 497 429Q517 424 517 402Q517 388 508 376T485 360Q479 358 389 358T299 356Q298 355 283 274T251 109T233 20Q228 5 215 -4T186 -13Q153 -13 153 20V30L203 192Q214 228 227 272T248 336L254 357Q254 358 208 358Q206 358 197 358T183 359Q105 359 61 295Q56 287 53 286T39 284',
    0x1D710: '413 384Q413 406 432 424T473 443Q492 443 507 425T523 367Q523 334 508 270T468 153Q424 63 373 27T282 -10H268Q220 -10 186 2T135 36T111 78T104 121Q104 170 138 262T173 379Q173 380 173 381Q173 390 173 393T169 400T158 404H154Q131 404 112 385T82 344T65 302T57 280Q55 278 41 278H27Q21 284 21 287Q21 299 34 333T82 404T161 441Q200 441 225 419T250 355Q248 336 247 334Q247 331 232 291T201 199T185 118Q185 68 211 47T275 26Q317 26 355 57T416 132T452 216T465 277Q465 301 457 318T439 343T421 361T413 384',
    0x1D711: '92 210Q92 176 106 149T142 108T185 85T220 72L235 70L237 71L250 112Q268 170 283 211T322 299T370 375T429 423T502 442Q547 442 582 410T618 302Q618 224 575 152T457 35T299 -10Q273 -10 273 -12L266 -48Q260 -83 252 -125T241 -179Q236 -203 215 -212Q204 -218 190 -218Q159 -215 159 -185Q159 -175 214 -2L209 0Q204 2 195 5T173 14T147 28T120 46T94 71T71 103T56 142T50 190Q50 238 76 311T149 431H162Q183 431 183 423Q183 417 175 409Q134 361 114 300T92 210ZM574 278Q574 320 550 344T486 369Q437 369 394 329T323 218Q309 184 295 109L286 64Q304 62 306 62Q423 62 498 131T574 278',
    0x1D712: '576 -125Q576 -147 547 -175T487 -204H476Q394 -204 363 -157Q334 -114 293 26L284 59Q283 58 248 19T170 -66T92 -151T53 -191Q49 -194 43 -194Q36 -194 31 -189T25 -177T38 -154T151 -30L272 102L265 131Q189 405 135 405Q104 405 87 358Q86 351 68 351Q48 351 48 361Q48 369 56 386T89 423T148 442Q224 442 258 400Q276 375 297 320T330 222L341 180Q344 180 455 303T573 429Q579 431 582 431Q600 431 600 414Q600 407 587 392T477 270Q356 138 353 134L362 102Q392 -10 428 -89T490 -168Q504 -168 517 -156T536 -126Q539 -116 543 -115T557 -114T571 -115Q576 -118 576 -125',
    0x1D713: '161 441Q202 441 226 417T250 358Q250 338 218 252T187 127Q190 85 214 61Q235 43 257 37Q275 29 288 29H289L371 360Q455 691 456 692Q459 694 472 694Q492 694 492 687Q492 678 411 356Q329 28 329 27T335 26Q421 26 498 114T576 278Q576 302 568 319T550 343T532 361T524 384Q524 405 541 424T583 443Q602 443 618 425T634 366Q634 337 623 288T605 220Q573 125 492 57T329 -11H319L296 -104Q272 -198 272 -199Q270 -205 252 -205H239Q233 -199 233 -197Q233 -192 256 -102T279 -9Q272 -8 265 -8Q106 14 106 139Q106 174 139 264T173 379Q173 380 173 381Q173 390 173 393T169 400T158 404H154Q131 404 112 385T82 344T65 302T57 280Q55 278 41 278H27Q21 284 21 287Q21 299 34 333T82 404T161 441',
    0x1D714: '495 384Q495 406 514 424T555 443Q574 443 589 425T604 364Q604 334 592 278T555 155T483 38T377 -11Q297 -11 267 66Q266 68 260 61Q201 -11 125 -11Q15 -11 15 139Q15 230 56 325T123 434Q135 441 147 436Q160 429 160 418Q160 406 140 379T94 306T62 208Q61 202 61 187Q61 124 85 100T143 76Q201 76 245 129L253 137V156Q258 297 317 297Q348 297 348 261Q348 243 338 213T318 158L308 135Q309 133 310 129T318 115T334 97T358 83T393 76Q456 76 501 148T546 274Q546 305 533 325T508 357T495 384',
    0x1D715: '202 508Q179 508 169 520T158 547Q158 557 164 577T185 624T230 675T301 710L333 715H345Q378 715 384 714Q447 703 489 661T549 568T566 457Q566 362 519 240T402 53Q321 -22 223 -22Q123 -22 73 56Q42 102 42 148V159Q42 276 129 370T322 465Q383 465 414 434T455 367L458 378Q478 461 478 515Q478 603 437 639T344 676Q266 676 223 612Q264 606 264 572Q264 547 246 528T202 508ZM430 306Q430 372 401 400T333 428Q270 428 222 382Q197 354 183 323T150 221Q132 149 132 116Q132 21 232 21Q244 21 250 22Q327 35 374 112Q389 137 409 196T430 306',
    0x1D716: '227 -11Q149 -11 95 41T40 174Q40 262 87 322Q121 367 173 396T287 430Q289 431 329 431H367Q382 426 382 411Q382 385 341 385H325H312Q191 385 154 277L150 265H327Q340 256 340 246Q340 228 320 219H138V217Q128 187 128 143Q128 77 160 52T231 26Q258 26 284 36T326 57T343 68Q350 68 354 58T358 39Q358 36 357 35Q354 31 337 21T289 0T227 -11',
    0x1D717: '537 500Q537 474 533 439T524 383L521 362Q558 355 561 351Q563 349 563 345Q563 321 552 318Q542 318 521 323L510 326Q496 261 459 187T362 51T241 -11Q100 -11 100 105Q100 139 127 242T154 366Q154 405 128 405Q107 405 92 377T68 316T57 280Q55 278 41 278H27Q21 284 21 287Q21 291 27 313T47 368T79 418Q103 442 134 442Q169 442 201 419T233 344Q232 330 206 228T180 98Q180 26 247 26Q292 26 332 90T404 260L427 349Q422 349 398 359T339 392T289 440Q265 476 265 520Q265 590 312 647T417 705Q463 705 491 670T528 592T537 500ZM464 564Q464 668 413 668Q373 668 339 622T304 522Q304 494 317 470T349 431T388 406T421 391T435 387H436L443 415Q450 443 457 485T464 564',
    0x1D718: '228 325Q170 322 156 316T127 309Q108 309 104 314Q99 319 99 322T108 341Q125 376 171 400T268 425H271Q302 425 319 396Q328 377 328 358Q328 332 324 314Q311 270 286 221Q274 194 274 192H275Q339 234 484 325T639 421Q669 434 691 434T723 425T734 406Q734 394 719 381Q715 376 644 330L575 287L566 267Q543 233 526 176Q520 160 515 143T508 115T506 105Q506 103 533 103Q585 103 607 110T641 118Q670 118 670 107Q670 100 661 85Q643 50 598 27T504 3Q465 3 450 36Q441 51 441 73Q441 84 444 96Q452 146 484 205L497 236L324 125Q143 12 135 10Q103 -6 77 -6Q61 -6 49 2T37 21Q37 36 49 46T124 96L195 141L204 156Q219 179 243 248T264 323Q264 325 228 325',
    0x1D719: '409 688Q413 694 421 694H429H442Q448 688 448 686Q448 679 418 563Q411 535 404 504T392 458L388 442Q388 441 397 441T429 435T477 418Q521 397 550 357T579 260T548 151T471 65T374 11T279 -10H275L251 -105Q245 -128 238 -160Q230 -192 227 -198T215 -205H209Q189 -205 189 -198Q189 -193 211 -103L234 -11Q234 -10 226 -10Q221 -10 206 -8T161 6T107 36T62 89T43 171Q43 231 76 284T157 370T254 422T342 441Q347 441 348 445L378 567Q409 686 409 688ZM122 150Q122 116 134 91T167 53T203 35T237 27H244L337 404Q333 404 326 403T297 395T255 379T211 350T170 304Q152 276 137 237Q122 191 122 150ZM500 282Q500 320 484 347T444 385T405 400T381 404H378L332 217L284 29Q284 27 285 27Q293 27 317 33T357 47Q400 66 431 100T475 170T494 234T500 282',
    0x1D71A: '205 -174Q136 -174 102 -153T67 -76Q67 -25 91 85T127 234Q143 289 182 341Q252 427 341 441Q343 441 349 441T359 442Q432 442 471 394T510 276Q510 169 431 80T253 -10Q226 -10 204 -2T169 19T146 44T132 64L128 73Q128 72 124 53T116 5T112 -44Q112 -68 117 -78T150 -95T236 -102Q327 -102 356 -111T386 -154Q386 -166 384 -178Q381 -190 378 -192T361 -194H348Q342 -188 342 -179Q342 -169 315 -169Q294 -169 264 -171T205 -174ZM424 322Q424 359 407 382T357 405Q322 405 287 376T231 300Q221 276 204 217Q188 152 188 116Q188 68 210 47T259 26Q297 26 334 62Q367 92 389 158T418 266T424 322',
    0x1D71B: '206 -10Q158 -10 136 24T114 110Q114 233 199 349L205 358H184Q144 358 121 347Q108 340 95 330T75 312T61 295T53 285Q51 284 38 284Q19 284 19 294Q19 300 38 329T93 391T164 429Q171 431 532 431Q799 431 803 430Q823 423 823 402Q823 377 801 364Q790 358 766 358Q748 358 748 357Q748 355 749 348T752 327T754 297Q754 258 738 207T693 107T618 24T520 -10Q488 -10 466 2T432 36T416 77T411 120Q411 128 410 128T404 122Q373 71 323 31T206 -10ZM714 296Q714 316 707 358H251Q250 357 244 348T230 328T212 301T193 267T176 229T164 187T159 144Q159 62 222 62Q290 62 349 127T432 285Q433 286 434 288T435 291T437 293T440 294T444 294T452 294H466Q472 288 472 286Q472 285 464 244T456 170Q456 62 534 62Q604 62 659 139T714 296',
    0x1D71C: '65 0Q45 0 45 18Q48 52 61 60Q65 62 81 62Q155 62 165 74Q166 74 265 228T465 539T569 699Q576 707 583 709T611 711T637 710T649 700Q650 697 695 380L741 63L784 62H827Q839 50 839 45L835 29Q831 9 827 5T806 0Q803 0 790 0T743 1T657 2Q585 2 547 1T504 0Q481 0 481 17Q484 54 497 60Q501 62 541 62Q580 62 580 63Q580 68 573 121T564 179V181H308L271 124Q236 69 236 67T283 62H287Q316 62 316 46Q316 26 307 8Q302 3 295 0L262 1Q242 2 168 2Q119 2 93 1T65 0ZM537 372Q533 402 528 435T521 486T518 504V505Q517 505 433 375L348 244L451 243Q555 243 555 244L537 372',
    0x1D71D: '258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 449 686H477H586Q684 686 733 677T817 634Q853 598 853 547Q853 499 826 460T761 401T695 371T654 360H653L662 358Q670 357 683 354T712 344T744 327T774 303T795 269T804 224Q804 148 732 79T533 1Q524 0 288 0H58Q47 5 43 15Q47 54 60 60Q64 62 113 62H162L302 623Q302 624 258 624ZM703 550Q703 571 695 586T675 609T656 619T643 623L545 624H447L417 504Q386 384 386 383T470 382Q554 383 565 385Q632 397 667 447T703 550ZM651 240Q651 265 645 282T626 309T608 322T592 329Q587 330 479 331H373L340 198Q307 65 306 64Q306 62 406 62L507 63L519 65Q565 76 596 107T639 171T651 240',
    0x1D71E: '257 618H231Q198 618 198 636Q202 672 214 678L219 680H763Q769 677 772 673T776 666L777 664Q777 659 766 549T751 433Q745 424 723 424Q704 424 699 427T693 441Q693 444 695 467T697 513Q697 543 689 563T670 594T636 610T592 617T534 618H516H456L455 614Q455 613 387 339T317 64Q317 62 375 62H411Q430 62 438 59T447 44Q444 7 430 2Q426 0 416 0Q409 0 359 1T231 2Q152 2 111 1T65 0Q48 0 43 15Q47 54 60 60Q64 62 113 62H162L163 66Q163 67 231 341T301 616Q301 618 257 618',
    0x1D71F: '65 0Q59 6 59 9T61 16Q64 20 334 357T608 698Q616 706 629 710Q630 710 634 710T644 710T656 711Q686 711 694 703Q698 699 700 693Q706 674 805 345T904 14Q904 7 894 1L479 0H65ZM630 342L567 551L232 134L462 133H693Q693 137 630 342',
    0x1D720: '257 618H231Q198 618 198 636Q202 672 214 678L219 680H811Q817 677 820 673T824 666L825 664Q825 659 814 549T799 433Q793 424 771 424Q752 424 746 427T740 441Q740 445 742 466T744 505Q744 561 722 585T646 616Q639 617 545 618H456Q456 617 427 502T398 385Q398 384 435 384Q461 385 471 385T499 391T526 405T545 433T562 478Q566 494 571 497T595 501H604Q622 501 626 486Q626 482 593 349T557 213Q552 205 530 205Q499 205 499 219Q499 222 503 242T508 281Q508 308 491 314T429 322Q425 322 423 322H382L317 64Q317 62 390 62Q460 62 493 64T569 80T640 124Q665 149 686 187T719 253T733 283Q739 289 760 289Q791 289 791 274Q791 267 763 201T706 71L678 8Q676 4 667 0H58Q47 5 43 15Q47 54 60 60Q64 62 113 62H162L163 66Q163 67 231 341T301 616Q301 618 257 618',
    0x1D721: '223 430Q192 430 192 448Q192 450 225 561T261 677Q265 683 270 684Q273 686 534 686Q796 686 797 685Q805 682 805 673Q805 668 804 661T800 648T798 641Q796 637 531 352L266 67L329 66H364Q412 66 446 70T523 96T596 157Q617 186 630 220T649 273T663 297Q667 299 684 299H688Q715 299 715 281Q715 278 673 145T628 8Q626 4 617 0H348Q289 0 221 0T139 -1Q112 -1 99 -1T78 1T69 5T68 12Q68 16 71 31T77 49L84 57Q91 65 104 79T133 110T170 151T213 196L610 624H540Q533 624 514 624T488 624T467 623T443 620T422 616T398 609T373 600Q292 560 255 449Q251 436 246 433T223 430',
    0x1D722: '258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 239 686Q290 684 403 684Q475 684 512 685T553 686Q576 686 576 668Q572 632 560 626Q555 624 506 624H457L399 389Q399 388 547 388H695L753 623Q753 624 709 624H686Q665 624 660 626T650 639Q653 678 668 684Q672 686 681 686Q685 686 726 685T847 684Q902 684 937 684T986 685T1004 686Q1027 686 1027 668Q1023 632 1011 626Q1006 624 957 624H908L839 344Q768 64 768 63T812 62H839Q871 62 871 44Q867 6 854 2L850 0L808 1Q782 2 675 2Q600 2 560 1T516 0Q499 0 494 15Q498 54 511 60Q515 62 564 62H613L614 66L679 324Q679 326 531 326H383L382 322L317 64Q317 62 361 62H388Q420 62 420 44Q416 6 403 2L399 0L357 1Q331 2 224 2Q149 2 109 1T65 0Q48 0 43 15Q47 54 60 60Q64 62 113 62H162L302 623Q302 624 258 624',
    0x1D723: '358 -17Q218 -17 136 49T54 243Q54 298 70 356T123 474T211 582T338 663T504 702H527Q578 702 590 701Q709 688 776 622T844 441Q844 379 825 315T765 192T668 86T532 11T358 -17ZM700 474Q700 525 685 561T642 616T587 643T528 652Q390 652 301 534Q252 472 225 373T198 210Q198 160 214 125T256 71T311 44T372 36Q484 36 571 119Q639 189 669 299T700 474ZM366 428Q366 425 364 419T362 411H466L570 412L573 422Q576 437 581 441T604 445Q620 445 623 444Q636 440 636 429Q636 423 616 340T593 253Q586 243 572 243H566Q545 243 539 249Q536 251 535 253T534 258T534 263T535 270T537 277H329L326 266Q323 251 318 247T295 243Q279 243 276 244Q263 248 263 259Q263 265 283 346Q288 366 295 394Q304 431 308 438T326 445H334H338Q366 445 366 428',
    0x1D724: '247 624Q242 624 233 624T220 623Q186 623 186 640Q186 647 190 664T202 684Q206 686 226 686Q277 684 393 684Q435 684 471 684T528 685T553 686Q573 686 573 670Q573 650 564 632Q556 624 537 624H501H449L380 344Q309 64 309 63T356 62Q361 62 370 62T384 63Q417 63 417 46Q417 26 408 8Q403 3 396 0L352 1Q325 2 216 2T82 1L45 0Q30 7 30 16Q33 51 46 60Q51 62 102 62H154L294 623Q294 624 247 624',
    0x1D725: '536 0Q522 6 522 18Q522 35 533 57Q539 62 557 62Q595 62 601 65L472 330L365 255L342 160Q318 65 317 64Q317 62 361 62H388Q420 62 420 44Q416 6 403 2L399 0L357 1Q331 2 224 2Q149 2 109 1T65 0Q48 0 43 15Q47 54 60 60Q64 62 113 62H162L302 623Q302 624 258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 239 686Q290 684 403 684Q475 684 512 685T553 686Q576 686 576 668Q572 632 560 626Q555 624 506 624H457L422 481Q386 339 386 337L785 621Q779 624 749 624Q726 624 726 641Q726 645 730 659Q734 675 736 679T747 686L786 685Q812 684 888 684Q908 684 934 685T968 686Q1003 686 1003 669Q1003 646 991 629Q985 624 967 624Q918 624 888 617Q884 617 874 613L865 609Q864 608 732 515T599 420Q599 418 686 242T775 65Q784 62 829 62Q847 62 850 61T860 54Q862 52 862 43Q862 10 845 1Q844 1 842 1T836 0T797 1T694 2Q599 2 573 1L536 0',
    0x1D726: '439 0Q425 6 425 18Q425 35 436 57Q442 62 485 62Q525 62 525 64L478 483Q478 484 465 463T422 394T350 277Q222 69 222 68Q223 67 224 67Q229 64 271 62Q290 62 297 59T305 45Q305 38 302 28Q297 9 293 5T274 0Q270 0 238 1T159 2Q133 2 105 2T72 1Q56 1 52 3T44 15Q44 19 48 35Q53 55 58 58T89 62Q142 64 151 73Q154 76 345 387T538 699Q550 711 570 711H580H592Q613 711 618 695Q619 692 654 379T690 63Q690 62 726 62H746Q776 62 776 44Q773 7 759 2Q755 0 747 0Q743 0 707 1T600 2Q502 2 476 1L439 0',
    0x1D727: '258 624H231Q214 624 208 626T199 639Q203 678 216 684Q220 686 347 686H473Q474 685 478 682T484 677Q487 673 535 413L584 153L608 187Q631 221 672 281T761 410Q935 663 943 671Q949 678 962 686H1082H1166Q1201 686 1210 683T1219 668Q1215 632 1203 626Q1199 624 1149 624H1100L1031 344Q960 64 960 63T1004 62H1031Q1063 62 1063 44Q1060 7 1046 2Q1042 0 1034 0Q1030 0 990 1T875 2Q804 2 767 1T725 0H723Q707 0 703 15Q707 54 720 60Q724 62 773 62H822Q961 618 961 619L754 318Q546 15 543 12Q531 0 510 0Q500 0 495 0T484 5T477 19Q477 20 421 315L367 604L299 335Q234 72 234 68Q234 62 302 62Q334 62 334 46Q332 8 317 2Q313 0 306 0Q301 0 267 1T181 2Q125 2 96 1T63 0Q48 0 43 15Q43 19 47 35Q52 55 57 58T94 62Q147 64 164 69L233 345Q302 619 302 622Q302 624 258 624',
    0x1D728: '258 624H235Q214 624 209 626T199 639Q203 678 216 684Q220 686 344 686H434Q464 686 477 680Q480 677 607 454Q738 227 739 227Q742 227 789 418T836 618Q836 620 835 620L821 622Q811 622 779 624Q755 624 749 625T740 632Q737 635 737 644Q737 656 742 669T754 685Q755 685 757 685T763 686Q768 686 803 685T890 684Q925 684 951 684T990 685T1006 686Q1014 686 1016 684Q1027 679 1027 668Q1023 632 1011 626Q1007 624 978 624Q912 622 907 617Q907 616 831 314T753 8Q749 0 723 0H712Q699 0 692 7Q692 8 671 44T607 155T526 296L361 580L296 323Q234 74 234 68T302 62H307Q334 62 334 44Q330 6 317 2L313 0L280 1Q260 2 181 2Q125 2 96 1T63 0Q48 0 43 15Q43 19 47 35Q52 55 57 58T94 62Q147 64 164 69L233 345Q302 619 302 622Q302 624 258 624',
    0x1D729: '206 466Q175 466 175 484Q175 487 201 574T230 666Q235 673 241 675H547Q853 675 857 673Q867 668 867 657Q867 655 850 569T832 478Q827 466 808 466H802H795Q773 466 771 481Q771 486 775 508T779 541V549H774Q755 552 505 552Q281 551 267 548Q262 548 255 533T242 496T233 472Q228 466 206 466ZM259 255H252Q231 255 228 270Q228 275 248 355T270 439Q277 448 288 448H298Q321 448 326 440Q331 434 326 414Q326 413 486 413H647L650 424Q654 441 658 444T678 448H683H693Q702 448 705 446T714 432L694 348Q674 267 669 261Q664 255 652 255H643Q622 255 617 261Q613 265 613 272T613 283T616 289Q616 290 456 290H295L294 285Q293 280 292 275T288 265T281 257Q278 255 259 255ZM150 131Q150 124 281 123Q346 123 390 123Q640 123 664 126Q668 127 675 127Q686 131 704 198Q708 213 713 216T733 220H738Q755 220 759 218Q768 213 768 203Q768 198 741 105T710 8Q708 4 699 0H388Q77 0 73 2Q62 7 62 18Q62 27 81 115Q99 206 102 212Q106 220 120 220H129Q140 220 145 220T155 215T160 202Q160 196 159 192Q150 145 150 131',
    0x1D72A: '53 245Q53 297 70 356T125 478T216 590T349 671T523 703Q656 703 735 637T815 445Q815 378 791 307Q727 104 527 17Q437 -17 344 -17Q289 -17 242 -5T150 35T79 116T53 245ZM664 489Q664 575 618 611T511 648Q463 648 416 627T334 570Q297 531 270 472T230 355T213 261T208 206Q208 177 215 151T237 98T284 56T358 40Q440 40 510 98T618 270Q664 400 664 489',
    0x1D72B: '257 618H231Q198 618 198 636Q202 672 214 678L219 680H1011Q1022 675 1026 665Q1022 626 1009 620Q1005 618 956 618H907L906 614Q906 613 838 339T768 64Q768 62 812 62H839Q871 62 871 44Q867 6 854 2L850 0L808 1Q782 2 675 2Q600 2 560 1T516 0Q499 0 494 15Q498 54 511 60Q515 62 564 62H613L614 66Q614 67 682 341T752 616Q752 618 604 618H456L455 614Q455 613 387 339T317 64Q317 62 361 62H388Q420 62 420 44Q416 6 403 2L399 0L357 1Q331 2 224 2Q149 2 109 1T65 0Q48 0 43 15Q47 54 60 60Q64 62 113 62H162L163 66Q163 67 231 341T301 616Q301 618 257 618',
    0x1D72C: '162 62L302 623Q302 624 258 624H234Q214 624 209 626T200 638Q200 677 217 684Q220 686 439 686Q667 685 684 682Q686 681 693 680Q713 677 733 671T782 649T829 602T847 528Q847 450 784 382T604 293Q571 288 469 287H373L346 176Q340 151 333 122T321 78L317 64Q317 62 361 62H387Q420 62 420 44Q417 10 404 2L399 0L357 1Q331 2 224 2Q149 2 109 1T65 0Q43 0 43 17Q43 21 47 33Q52 54 57 58T89 62H113H162ZM692 558Q692 611 617 622Q610 623 529 624H452L381 343H458H492Q604 343 641 389Q662 414 677 471T692 558',
    0x1D72E: '847 430Q828 430 823 434T817 450Q817 454 817 466T818 487Q818 526 809 551T784 591T737 613T675 622T590 624H528H430L513 487Q594 351 596 345Q596 335 590 330Q583 323 418 204L250 81L363 80Q533 80 591 89T694 142Q739 185 765 252Q772 268 776 271T799 274Q816 274 820 272Q832 266 830 254Q829 250 784 130T736 7Q732 3 725 0H405Q84 0 80 2Q69 7 69 18Q69 26 75 32Q76 32 98 48T168 100T255 164L432 293Q429 300 329 465T225 637Q223 675 245 686H888Q900 680 902 671Q902 667 890 556T876 441Q871 430 847 430',
    0x1D72F: '498 62Q511 58 511 43Q511 10 494 1L490 0Q487 0 482 0T424 1T271 2Q201 2 157 2T94 1T72 0H70Q46 0 46 17Q49 54 62 60L66 62H137Q208 62 209 63L218 98Q227 134 244 203T278 339L347 613H300Q262 612 246 611T198 599T146 564Q128 545 114 512T91 454T79 425Q73 419 52 419Q22 419 22 434Q22 440 41 498T80 611L100 666Q105 673 111 675H434Q758 675 762 673Q772 668 772 657Q772 655 756 549T738 434Q735 419 711 419H707Q690 419 686 421Q677 425 677 434Q676 436 678 449T683 485T686 529Q686 553 679 569T662 594T631 607T593 612T544 613H502L433 340Q418 279 400 207T374 100L365 65L364 62H498',
    0x1D730: '32 544Q32 586 91 644T229 703Q277 703 311 683T363 628T389 560T397 491V478L404 491Q455 589 526 646T677 703Q730 703 766 671T802 584Q802 551 793 541T766 531H757L736 532L732 535L729 539V549Q731 569 715 575T658 581H650Q545 581 477 443Q453 392 443 351Q441 345 424 273T389 133T371 64Q371 62 428 62H461Q483 62 492 59T501 44Q498 10 485 2L480 0L431 1Q401 2 278 2T127 1L85 0Q71 5 71 17Q71 24 74 33Q77 46 78 49T84 57T95 61T118 62H154H216Q232 126 249 193T273 287T287 345T296 388T299 416T300 452Q294 581 198 581Q154 581 132 575T106 562T99 546T86 533Q82 531 60 531L39 532Q32 537 32 544',
    0x1D731: '323 624H293Q267 624 261 626T251 639Q255 678 268 684Q272 686 293 686Q348 684 475 684Q557 684 600 685T647 686H648Q671 686 671 668Q667 632 655 626Q650 624 588 624H525L500 520Q500 519 520 518T579 507T656 480Q737 440 737 372Q737 294 648 237Q562 180 426 169L412 168L399 118Q386 66 386 65L385 62H443H479Q498 62 506 59T515 44Q511 8 499 2L494 0L447 1Q417 2 298 2Q212 2 167 1T118 0Q100 0 95 15Q99 54 112 60Q116 62 179 62H241Q242 64 254 114T266 167Q266 168 262 168Q256 168 237 170T180 181T110 205T54 249T29 316Q29 391 112 446T327 516Q345 518 349 518Q351 518 353 518L355 519Q356 520 368 570T381 623Q381 624 323 624ZM342 466Q341 467 339 467Q320 467 283 455T225 420Q181 361 181 296Q181 273 193 257T222 233T254 222T277 219L280 220Q281 220 311 342T342 466ZM583 389Q583 409 576 423T557 444T533 456T509 463T492 467H486L455 343Q444 300 437 271T428 231T426 219Q430 219 445 222T483 232T521 250Q551 269 567 310T583 389',
    0x1D732: '931 686Q953 686 953 670Q953 650 944 632Q936 624 924 624H914Q823 624 803 611Q800 609 696 503T591 396Q591 394 667 229L743 62H787H814Q846 62 846 44Q843 7 829 2Q825 0 817 0Q813 0 775 1T664 2Q590 2 551 1T508 0H507Q484 0 484 18Q484 19 488 37Q492 56 497 58T534 62L566 63Q567 64 520 169T471 274Q469 274 369 172T268 67L315 62Q320 62 328 62L335 61Q347 58 347 44Q344 10 331 2L326 0L287 1Q263 2 177 2Q95 2 78 1L53 0Q38 6 38 17Q38 40 50 57Q56 62 78 62Q169 62 188 75Q194 77 435 324L444 334L439 347Q437 351 373 492L313 624H268H246Q220 624 212 632Q210 636 210 642Q210 655 215 669T227 684Q230 686 247 686Q295 684 398 684Q438 684 472 684T527 685T551 686Q567 686 572 671Q572 667 568 651Q563 631 558 628T523 624T492 623H488L526 540Q563 457 564 457Q564 456 574 466T604 496T645 537L724 619Q716 622 677 624H673Q645 624 645 640Q645 660 654 678Q659 683 666 686L704 685Q728 684 813 684Q847 684 873 684T913 685T931 686',
    0x1D733: '205 471Q205 445 196 403T186 333Q186 303 194 281T218 248T240 233T262 224L361 623Q361 624 303 624Q296 624 284 624T266 623Q232 623 232 641Q232 648 235 657Q240 678 244 682T268 686H273Q329 684 457 684Q502 684 540 684T599 685T626 686H628Q651 686 651 668Q649 633 631 624H505L407 227Q410 228 416 229T439 239T472 259T507 294T539 345Q549 365 563 416T597 498T649 538Q657 540 717 540Q725 540 737 540T755 541Q790 541 790 524Q790 512 784 497Q780 491 767 490T742 477Q736 471 731 463T722 449T715 433T710 419T705 403T701 389Q686 340 658 302T599 240T530 201T463 179T404 169L391 168L379 116Q365 67 365 63Q365 62 422 62H455Q477 62 486 59T495 44Q492 10 479 2L474 0L427 1Q397 2 278 2T131 1L90 0Q76 5 76 17Q76 24 79 33Q82 46 83 49T89 57T100 61T123 62H159H221Q247 162 247 168H244Q241 169 239 169Q202 176 178 182T123 207T74 252Q46 291 46 351Q46 375 52 404T59 454Q59 489 40 489Q32 489 27 494T22 507Q22 535 37 538Q40 540 99 540H128Q168 540 186 528T205 471',
    0x1D734: '162 119Q181 115 235 115L273 116Q273 133 231 222T180 345Q173 368 173 391V406V414Q173 477 214 540Q255 600 315 635Q353 661 423 682T585 703Q656 703 711 690T799 656T851 608T879 555T886 503Q886 449 860 401Q840 359 810 322T725 230T643 146Q619 117 619 116T650 115Q707 115 722 120Q730 123 750 165T775 210Q779 212 796 212Q828 212 828 196Q828 191 807 144T764 52L743 7Q740 4 740 4T733 2T717 0T686 0H632H573Q535 0 526 3T517 17Q517 44 544 103T617 243T671 341Q729 454 729 535Q729 599 686 625T583 652Q549 652 517 645T450 616T388 561T344 470T327 340Q327 304 331 237T336 135Q336 93 330 50T313 2Q308 0 208 0H142Q107 0 100 4T93 25Q93 28 93 41T95 77T96 118L100 199Q105 208 109 210T131 212Q147 212 151 210T161 199V160Q161 131 162 125V119',
    0x1D736: '39 166Q39 213 59 261T117 353T219 424T362 452Q420 452 466 433T536 384T573 325T586 269V265Q593 272 609 308T636 381Q640 397 644 399T669 402H680Q700 402 700 388Q700 379 691 351T659 276T604 188L593 173L595 153Q600 79 612 43H618Q634 45 642 51T653 64T658 71Q661 73 684 73Q712 73 712 59Q712 39 685 16T603 -7Q588 -7 575 -5T551 2T532 12T516 24T503 37T494 49T487 60T481 69L469 61Q362 -8 251 -8Q159 -8 99 36T39 166ZM260 43Q310 43 361 63T438 101T465 124Q458 240 453 277Q435 401 354 401Q291 401 245 355Q230 337 217 313Q201 279 186 216T170 126Q170 72 208 54Q230 43 260 43',
    0x1D737: '59 -194H49Q31 -194 28 -182Q28 -178 107 139T192 473Q212 533 248 580T324 652T395 689T450 701H461Q514 701 551 688T605 652T630 607T637 561Q637 546 634 526T611 465T556 393Q572 382 590 347T608 262Q608 146 522 69T299 -8Q279 -8 261 -6T228 2T204 13T183 26T169 37T157 48L150 56L120 -64Q113 -90 104 -128Q93 -175 89 -184T73 -194H59ZM531 592Q531 651 463 651Q399 651 341 600T253 466Q250 458 217 327T182 185Q180 176 180 159Q180 108 212 76T301 44Q330 44 354 51T393 65T423 91T444 118T459 151T468 179T475 206Q490 264 491 296Q491 313 489 326T484 345L482 350Q481 350 477 348T464 344T444 340T413 335T372 333T334 334T301 340T274 355T265 380Q265 444 397 444Q425 444 445 441T476 436L485 433Q489 433 499 458Q509 482 520 527T531 592ZM424 390Q424 393 389 393Q383 393 374 393T362 392Q348 392 333 388Q345 384 379 384Q424 384 424 390',
    0x1D738: '5 269Q5 285 19 312T57 368T124 421T215 451H241Q274 451 303 439T353 406T389 361T416 311T432 266T442 232L444 220L446 216L450 226Q473 278 513 357T561 441Q566 444 584 444H594Q617 444 617 430Q617 426 596 389T536 273T462 110L452 84L451 70Q447 12 427 -76T388 -192Q375 -211 355 -211Q339 -211 332 -198T325 -171Q325 -114 386 64L393 84V98Q393 181 371 241Q360 280 319 303T210 327Q158 327 126 317T84 296T68 272T59 258Q55 256 36 256Q23 256 18 256T9 260T5 269',
    0x1D739: '216 610Q216 640 229 664T262 700T299 719T327 725Q330 725 406 709T487 690Q513 681 513 651Q513 627 494 607T450 587Q417 587 378 631Q346 663 314 663Q286 663 272 639Q271 637 271 634Q271 609 344 536L397 484Q438 448 458 410T478 313Q478 234 443 147T338 18Q298 -8 249 -8Q214 -8 180 0T113 26T60 81T39 168Q39 200 50 237T87 316T160 391T272 442L260 465Q216 553 216 610ZM348 235Q348 274 336 313T310 372L298 392Q295 391 290 390T269 380T241 359T212 323T185 267Q157 168 157 130Q157 83 186 63T255 43Q280 43 300 67Q317 89 332 138T348 235',
    0x1D73A: '224 -17Q126 -17 81 22T36 112Q36 178 84 226L93 236L88 246Q79 264 79 289Q79 341 124 388Q201 461 333 461Q402 461 455 425Q480 409 481 390Q481 365 464 350T428 334Q415 334 387 352T313 370Q141 370 141 293Q141 275 146 270Q147 270 148 270T155 272Q202 291 263 291H270Q349 291 349 244Q349 195 281 183Q274 182 239 182Q201 182 184 185T137 200Q123 188 112 168T100 129T112 98T148 81T189 75T237 74H243H251Q288 74 310 75T359 86T398 112Q405 124 417 124Q426 124 432 117T439 100Q439 88 427 70T390 32T322 -3T224 -17ZM286 238Q286 240 253 240Q245 240 234 239T216 237T209 235Q209 232 250 232Q286 232 286 238',
    0x1D73B: '361 711Q373 711 381 703T389 683Q389 681 388 676T383 656T376 618V612H379Q385 618 429 618Q521 618 521 572Q521 551 506 534Q483 510 415 507Q385 507 371 511T336 536L326 528Q254 472 204 381T154 209Q154 190 157 177Q159 165 162 154T170 135T182 119T195 106T212 95T229 86T249 78T269 72T290 66T311 60Q385 37 388 36Q437 14 454 -36Q456 -46 456 -64Q456 -83 455 -90Q445 -132 413 -167T333 -202Q300 -202 257 -191T206 -169Q203 -164 203 -158Q203 -148 210 -140T231 -130Q239 -130 263 -139T326 -151H329Q337 -151 342 -150T352 -143T357 -123Q356 -117 355 -113T350 -104T344 -96T335 -90T324 -85T310 -80T294 -74T275 -68T254 -62Q253 -62 231 -56T205 -48T179 -39T150 -26T125 -10T100 11T80 37T62 70T53 109T48 157Q48 281 123 396T317 586V612Q319 638 320 649T325 678T338 703T361 711ZM454 564Q445 567 424 567Q407 567 398 565T387 563Q387 558 411 558Q434 558 450 562L454 564',
    0x1D73C: '24 296Q25 302 27 312T41 350T65 397T103 435T157 452Q202 452 233 435Q267 419 284 384L294 392Q304 401 316 410T348 429T388 445Q410 451 445 451H453Q468 451 482 450T519 443T558 425T587 391T600 337V327Q600 311 538 64Q484 -158 478 -168Q457 -211 409 -211Q386 -211 372 -197T357 -161Q357 -158 415 80Q476 330 477 348Q477 366 473 377T461 393T448 399T432 400H427Q379 400 335 363Q300 332 280 298Q277 293 246 170T213 40Q205 22 186 7T142 -8T103 7T89 39Q89 49 106 117T142 260T164 351Q166 363 166 372Q166 402 148 402Q126 402 110 369Q100 350 90 310Q85 289 82 286T60 282H55H44Q24 282 24 296',
    0x1D73D: '213 -8Q130 -8 85 50T40 200V207Q40 303 83 428Q122 535 189 608Q279 702 381 702Q410 702 437 693T492 661T537 593T554 486Q554 428 539 362T495 230T425 111T330 25T213 -8ZM433 562Q433 600 419 625T377 651Q363 651 348 644T311 619T268 557T229 453Q225 441 217 411T208 378H401Q433 500 433 562ZM161 140Q161 43 217 43Q249 43 280 74Q310 103 332 150T378 287Q385 313 385 315Q385 316 289 316H192Q191 308 183 275T169 205T161 140',
    0x1D73E: '161 -8Q111 -8 75 16T38 85Q38 95 48 121T80 214T123 368L124 374Q126 381 127 386T132 399T139 414T149 428T162 440T180 448T203 452Q226 452 241 439T257 404Q257 386 230 290T171 111Q157 73 157 57Q157 43 166 43Q209 43 258 69T322 144Q326 157 330 159T348 162H355H366Q386 162 386 148Q386 143 383 132T367 100T335 61Q301 27 253 10T161 -8',
    0x1D73F: '258 405Q258 394 251 364T237 308T230 281T238 284T270 306T330 349Q365 377 389 394T450 427T519 444Q545 444 559 430T574 391Q574 360 551 336T491 312Q469 312 454 326T439 364Q439 372 438 372Q433 372 395 344T320 289T283 260H285Q287 260 290 260T297 259Q495 248 495 146Q495 132 491 110T486 74Q486 43 505 43Q520 43 531 53Q559 72 578 141Q582 157 586 159T611 162H622Q642 162 642 148T627 100T578 29T504 -7H495Q435 -7 399 22T363 96Q363 111 366 122T369 142Q369 155 364 165T347 182T326 193T298 200T269 204T238 207T212 210L211 206L190 123L169 40Q161 22 142 7T98 -8T59 7T45 39Q45 48 67 139T112 317L134 404Q142 424 161 438T204 452Q226 452 242 440T258 405',
    0x1D740: '95 -13Q70 -13 55 4T40 41Q40 65 61 88Q65 92 210 207T357 322L235 602Q217 640 185 643Q182 643 178 644T173 645Q161 651 161 666Q161 677 167 684T181 692Q189 694 212 694Q335 694 358 660Q362 653 500 340T647 18Q652 10 652 6Q652 -8 622 -8H589H538L526 -5Q506 1 500 8Q494 16 444 128Q442 133 440 138L387 259L265 134Q156 20 137 4T95 -13',
    0x1D741: '294 -8Q265 -8 244 -5T213 1T201 4Q200 4 192 -32T172 -111T155 -168Q134 -211 86 -211Q62 -211 48 -196T34 -158Q37 -144 103 123T174 404Q182 424 201 438T244 452Q271 452 284 436T298 404Q298 392 267 269T235 114Q235 43 305 43Q342 43 375 68T418 110Q420 112 455 253T492 397Q514 444 562 444Q587 444 601 429T615 397Q615 387 599 320T563 178T542 93Q540 81 540 72Q540 42 558 42Q580 42 596 75Q606 94 616 134Q621 155 624 158T646 162H651H662Q682 162 682 148Q681 142 679 132T665 94T641 47T602 9T548 -8Q523 -8 502 -3T468 11T446 27T432 40L429 46Q367 -8 294 -8',
    0x1D742: '88 382Q70 382 65 385T59 402T64 427T78 443Q79 444 157 448T247 452Q256 452 261 448T266 440L267 437Q267 432 223 252L177 71L192 77Q293 117 371 199T480 388Q489 424 511 438T556 452Q579 452 593 438T608 402Q608 378 593 340T540 251T446 152T299 62T96 -1Q91 -2 78 -2Q38 -2 38 15Q38 22 82 198L127 379Q124 382 88 382',
    0x1D743: '287 648Q291 671 293 680T305 700T329 711Q339 711 347 705T356 687Q356 680 351 653T345 619Q345 615 346 615Q358 618 398 618Q490 618 490 572Q490 553 476 536T434 512Q411 508 378 508H366Q332 508 306 534L298 525Q271 496 254 456T237 375Q237 336 244 336Q272 346 319 346H325Q354 346 372 344T406 331T422 300Q422 252 350 238Q332 236 303 236Q286 236 269 238T240 242T218 247T202 252L196 254Q191 254 174 237T141 191T124 139Q124 108 151 92T267 46Q285 40 295 37Q308 33 332 25T366 13T392 3T415 -8T432 -20T445 -36T451 -55T454 -80Q454 -118 427 -153T358 -199Q349 -201 327 -201Q313 -201 298 -200T271 -196T246 -191T226 -185T210 -180T200 -176L196 -174Q187 -170 187 -158T196 -138T216 -130Q217 -130 254 -140T329 -151Q349 -151 349 -135Q349 -127 340 -122T293 -104Q260 -93 238 -85Q130 -48 115 -41Q71 -19 47 15T23 88Q23 126 48 179T130 277L144 288L136 297Q99 336 99 390Q99 456 148 514T285 602V619Q285 624 286 635T287 648ZM355 563Q362 560 376 558Q424 558 423 564Q405 567 390 567Q369 567 355 563ZM279 292Q297 287 315 287Q355 287 355 293Q355 296 321 296Q316 296 308 296L301 295Q295 295 289 294L279 292',
    0x1D744: '254 -8Q191 -8 146 9T80 54T49 111T39 169Q39 206 53 247T96 329T176 402T292 446Q317 451 336 451L344 452Q353 452 359 452Q457 452 516 408T576 279Q576 169 488 81T254 -8ZM349 400Q321 400 287 385T231 338Q206 301 188 228T170 126Q170 99 178 83Q198 44 260 44Q367 44 409 157Q419 185 432 238T445 317Q445 336 443 348Q435 372 416 384T384 398T349 400',
    0x1D745: '55 289H43Q23 289 23 303Q23 308 33 322Q116 434 199 443Q200 444 418 444Q591 444 617 444T652 439Q674 426 674 400Q674 378 661 360T625 335Q621 334 549 333H479L477 321Q476 312 476 279Q476 219 491 174T521 104T536 65Q536 38 511 15T457 -8Q403 -8 386 94Q384 110 384 139Q384 181 391 229T406 304L413 331Q413 333 365 333H316L315 329Q315 328 312 314T301 272T288 220Q274 167 258 103Q244 49 240 38T228 18Q225 16 224 14Q200 -8 172 -8Q146 -8 132 7T118 44Q118 54 121 61Q122 65 142 102T190 195T235 293Q250 329 250 333Q177 333 166 332Q115 328 88 301L77 290L55 289',
    0x1D746: '307 -8Q277 -8 251 0T215 14L205 20Q203 18 193 -25T171 -114T155 -168Q134 -211 87 -211Q64 -211 49 -198T34 -162Q34 -158 137 254Q153 299 179 334T232 390T277 419T311 434Q357 451 403 451Q435 451 455 449T506 435T560 400Q603 357 603 285Q603 172 520 82T307 -8ZM474 343Q474 364 458 382T409 400H406Q339 400 299 341Q281 313 264 257Q261 248 242 170T222 89Q222 84 230 74T260 54T308 43Q334 43 365 57T417 110Q437 145 456 228Q474 298 474 332V343',
    0x1D747: '33 209Q33 277 80 334T195 421T330 451H344Q410 451 439 429Q457 417 457 402Q457 386 445 375T420 363Q415 363 406 368T383 383T354 398Q347 400 330 400Q256 400 196 361T135 265V257Q135 242 147 225T174 199L358 107Q400 77 400 28T362 -63T271 -105Q254 -105 229 -99T195 -86Q188 -82 188 -71Q188 -56 197 -50T216 -44Q225 -44 237 -48T270 -53H282Q293 -44 293 -31Q293 -19 283 -10Q278 -4 200 33T115 76Q77 98 55 133T33 209',
    0x1D748: '35 151Q35 190 51 236T99 327T184 404T306 443Q307 443 316 443T342 443T378 444T425 444T476 444Q606 444 626 444T655 439Q677 426 677 400Q677 358 639 340Q625 333 563 333Q510 333 510 331Q518 319 518 272Q518 155 437 74T226 -8Q123 -8 79 41T35 151ZM396 278Q396 314 375 323T305 332Q249 332 222 310T180 243Q171 219 162 178T153 116V110Q153 43 234 43Q347 43 382 199Q383 203 383 204Q396 255 396 278',
    0x1D749: '55 289H43Q23 289 23 303Q23 308 33 322Q116 434 199 443Q200 444 386 444Q571 444 577 442Q588 441 599 432T610 402Q610 359 572 340Q561 335 547 334T452 333H353V331Q352 330 342 261T320 119T306 40Q300 18 281 3Q257 -13 233 -13Q210 -13 196 0T181 35Q181 44 182 48Q183 53 229 187T279 331Q279 333 228 333H209Q163 333 136 328T88 301L77 290L55 289',
    0x1D74A: '189 388Q189 396 187 398T176 401Q144 399 122 369T89 304Q84 288 81 285T61 282H55H44Q24 282 24 296Q24 306 34 330T64 382T116 431T188 452Q232 452 270 430T308 361Q308 345 275 258T241 123Q241 44 336 44H344Q380 44 415 73T474 140T511 214T526 267Q526 280 513 292Q505 301 486 311T456 333T444 367Q444 400 471 426T529 453Q555 453 579 431T604 358Q604 327 592 271T557 172Q550 157 541 142T510 97T464 47T404 9T328 -8Q264 -8 219 5T154 41T125 85T117 131Q117 182 153 277T189 388',
    0x1D74B: '238 4Q230 5 218 8T174 26T118 58T73 112T53 190Q53 219 60 246Q77 313 103 362T143 426T163 443Q165 444 186 444Q217 444 217 432Q217 425 188 392Q157 351 137 291T116 206Q116 127 263 109L276 107L288 139Q347 304 414 378T566 452Q621 449 662 412T703 286Q698 167 598 82T357 -8H332V-11Q332 -12 327 -46T317 -116T310 -157Q306 -180 286 -198T234 -216Q211 -216 197 -203T183 -168Q183 -160 184 -155Q184 -152 198 -112T225 -34T238 4ZM637 260Q637 303 607 322T541 341Q502 341 466 319T405 264Q368 218 356 159Q348 111 348 104Q348 103 361 103Q502 103 569 152T637 260',
    0x1D74C: '58 -194Q32 -194 32 -164Q32 -153 46 -139L175 -13Q296 104 296 106Q271 232 226 308Q165 401 141 401Q128 401 117 390T100 365Q94 350 91 349T69 346H45Q35 348 35 359Q35 380 62 411T133 450Q143 452 165 452Q286 452 329 402Q347 379 366 333T394 254T404 215Q404 213 405 213Q405 212 420 227T463 268T520 324Q637 437 640 438Q647 444 661 444Q667 444 676 438T685 419Q685 405 670 389T549 271L420 145Q433 85 444 53Q466 -25 509 -88T575 -151Q590 -151 600 -140T617 -114T626 -98Q629 -96 650 -96H655Q681 -96 681 -108Q681 -114 679 -119Q670 -148 646 -169T591 -199Q581 -201 550 -201Q422 -201 381 -143Q345 -88 316 20L311 39L230 -40Q144 -126 114 -153Q85 -182 77 -188T58 -194',
    0x1D74D: '244 141Q244 117 254 98T277 70T305 55T329 48T342 47L344 48L424 366Q501 678 505 686Q508 691 512 692T533 694T555 693T562 688T565 683Q565 678 486 362T406 45Q406 43 415 43Q467 49 514 78T590 143T636 213T653 266Q653 282 641 293T613 311T585 332T572 367Q572 397 598 425T657 453Q684 453 708 430T732 358Q732 330 723 287T706 225Q671 124 578 58T401 -8H393L370 -101Q346 -196 345 -197Q340 -202 316 -202H306Q286 -202 286 -188Q286 -187 296 -144T318 -57T331 -8Q331 -6 328 -6Q290 -6 233 11T148 62Q119 97 119 146Q119 181 154 275T190 388Q190 401 181 401Q154 401 129 375T90 306Q85 288 81 285T61 282H55H44Q24 282 24 296Q24 306 34 329T64 381T116 431T189 452Q237 452 273 428T309 362Q309 343 277 260T244 141',
    0x1D74E: '532 367Q532 399 559 426T618 453Q645 453 668 430T691 357Q691 319 677 261T649 171Q640 149 626 125T586 68T521 14T438 -7Q401 -7 372 7T333 30T311 57Q246 -8 165 -8Q119 -8 82 19T30 102Q24 126 24 163V178Q24 210 37 255Q61 346 118 424Q141 451 161 451Q174 451 184 443T194 419Q194 402 179 387Q91 273 91 206Q91 159 122 138T189 117T281 145V173Q283 223 294 253Q304 276 323 289T364 303Q386 303 400 287T415 250Q415 219 385 157L378 144Q378 142 388 136T419 124T462 117Q522 117 566 165T610 255Q610 288 561 320Q532 337 532 367',
    0x1D74F: '230 475Q202 475 189 492T175 526T186 570T221 631T288 687T389 710Q430 710 438 709Q495 701 537 679T601 629T637 568T653 509T657 459Q657 409 640 341Q617 248 581 180T507 75T424 16T348 -11T282 -17Q171 -17 113 37Q60 88 60 159Q60 192 71 231Q96 336 184 402Q264 462 366 462Q407 462 439 448T497 389L502 380Q503 381 508 403T519 463T525 531Q523 580 499 610T447 648T387 657Q324 657 283 616Q271 604 276 604Q279 604 286 600T302 583T311 555Q311 523 287 499T230 475ZM196 110Q196 41 287 41Q351 41 398 88Q422 111 437 151Q473 243 473 298Q473 386 409 409Q408 409 383 411Q316 411 278 373Q265 360 259 351T241 311T217 226Q196 143 196 110',
    0x1D750: '415 89Q423 89 429 74T436 46Q436 43 434 39Q432 36 420 29T380 11T322 -5Q311 -7 281 -7Q216 -7 168 10T94 54T56 110T44 167V181Q44 262 94 329Q104 343 119 357T162 391T234 425T327 443Q328 443 348 443T383 444Q434 444 442 438Q450 430 450 416Q446 392 424 383L376 382Q306 381 278 369Q230 349 208 294Q199 274 199 268Q199 267 291 267Q305 267 325 267T353 268Q383 268 394 263T406 241Q406 214 380 206Q375 205 279 205T183 203Q174 176 174 140Q174 87 208 65T292 43Q295 43 300 43T307 44Q337 49 372 69T415 89',
    0x1D751: '114 132Q114 153 140 253T166 372Q166 402 148 402Q126 402 110 369Q100 350 90 310Q85 289 82 286T60 282H55H44Q24 282 24 296Q25 302 27 312T41 350T65 397T104 435T158 452Q184 452 211 445T263 414T288 354V339L265 245Q237 134 237 118V107V102Q237 87 239 77T257 56T300 43Q395 43 455 254Q479 346 479 347L460 354Q294 408 294 528Q294 606 350 653T464 701Q536 701 579 659Q634 601 634 491Q634 468 630 438T623 388L620 370Q624 370 631 369T647 364T656 352Q656 347 653 335Q647 317 642 316Q640 315 637 315Q635 315 619 317Q606 319 605 316Q605 315 603 308Q587 248 550 177T457 57Q379 -8 293 -8Q192 -8 153 23T114 116V132ZM519 566Q519 600 507 625T464 651Q425 651 391 617T356 529Q356 501 370 478T404 441T443 417T477 404L491 400Q493 400 499 428T512 497T519 566',
    0x1D752: '228 325Q170 322 156 316T127 309Q108 309 104 314Q99 319 99 322T108 341Q125 376 171 400T268 425H271Q302 425 319 396Q328 377 328 358Q328 332 324 314Q311 270 286 221Q274 194 274 192H275Q339 234 484 325T639 421Q669 434 691 434T723 425T734 406Q734 394 719 381Q715 376 644 330L575 287L566 267Q543 233 526 176Q520 160 515 143T508 115T506 105Q506 103 533 103Q585 103 607 110T641 118Q670 118 670 107Q670 100 661 85Q643 50 598 27T504 3Q465 3 450 36Q441 51 441 73Q441 84 444 96Q452 146 484 205L497 236L324 125Q143 12 135 10Q103 -6 77 -6Q61 -6 49 2T37 21Q37 36 49 46T124 96L195 141L204 156Q219 179 243 248T264 323Q264 325 228 325',
    0x1D753: '274 -7Q232 -4 195 7T125 38T71 94T51 176V190Q51 213 60 242T95 307T156 373T255 425T393 451L397 452L427 568Q434 597 443 636Q452 677 456 685T472 694H486H495Q517 694 517 680L514 665Q510 650 503 621T489 564L460 451H469Q527 447 574 430T657 370T693 266Q693 163 599 82T350 -7H346L322 -100Q301 -190 295 -197Q291 -202 283 -202H269H258Q238 -202 238 -188Q238 -186 260 -96L283 -7H274ZM449 400Q448 400 404 225T359 47T366 45Q464 55 516 119Q542 149 558 199T575 295Q575 387 462 398L449 400ZM384 398Q384 399 381 399Q350 399 298 378T214 308Q168 236 168 149Q168 68 259 49Q282 44 294 44H295L384 398',
    0x1D754: '371 -168Q357 -168 323 -171T245 -175Q143 -175 109 -150T75 -66Q75 -5 100 108T137 254Q153 299 179 334T232 390T277 419T311 434Q357 451 403 451Q435 451 455 449T506 435T560 400Q603 357 603 282Q603 213 569 148T465 38T304 -8Q273 -8 247 -2T204 14T176 31T159 46T152 53Q152 52 148 27T144 -16Q144 -36 150 -44T189 -58T293 -64Q405 -65 432 -75Q466 -88 466 -127Q466 -140 459 -172Q455 -188 451 -191T426 -194H420Q405 -194 400 -191T395 -176Q396 -170 394 -169T378 -168Q373 -168 371 -168ZM236 116Q236 77 258 60T311 43Q369 43 407 94Q429 123 451 206T474 331Q474 400 409 400H406Q339 400 299 341Q276 305 256 227T236 116',
    0x1D755: '55 289H43Q23 289 23 303Q23 308 33 322Q116 434 199 443Q200 444 562 444Q922 444 928 442Q961 434 961 400Q961 376 944 355T886 333H870Q872 322 872 295V279Q872 230 842 165T751 46T618 -8Q581 -8 554 6T513 45T494 84T484 119Q484 121 478 114Q477 113 476 111Q384 -7 268 -7H265Q251 -7 237 -4T199 11T162 54T147 132Q147 149 149 166T155 198T165 229T176 256T189 281T200 301T211 319T220 333H199Q120 333 88 301L77 290L55 289ZM639 103Q674 103 712 122T780 188T811 295Q811 318 808 330V333H289Q274 318 244 263T214 169Q214 133 236 118T288 103Q351 103 412 153T494 278Q497 290 502 292T529 295Q546 295 551 293T556 283Q556 281 553 260T550 218Q550 153 576 128T639 103',
    0x1D756: '110 0H86Q42 0 42 27Q42 37 148 350T258 667Q269 687 291 692Q295 694 366 694H399Q432 694 448 689T474 667Q477 663 583 350T690 27Q690 0 642 0H617H592Q582 0 575 1T561 2T549 6T541 11T533 18T527 26T522 37T517 49T512 64T506 81L490 130H225Q225 128 208 79T189 27Q185 19 180 14T170 7T156 3T143 1T127 0T110 0ZM439 279Q359 524 359 547L357 555L355 543Q347 503 270 263L259 231H357Q455 231 455 232L439 279',
    0x1D757: '119 1Q98 5 92 28V667Q98 686 118 693Q121 694 272 694H289H346Q439 694 500 681T600 625Q640 580 640 513Q640 451 601 414T504 364L518 361Q568 351 602 329T649 280T666 235T671 197Q671 172 665 147T642 91T586 37T488 5Q456 1 282 1H119ZM489 509Q489 532 479 548T450 573T421 585T394 591Q387 592 315 593H247V404H298H325Q432 404 466 444Q489 470 489 509ZM517 194Q517 235 502 261T458 299T407 313T353 317H329H322H247V101H319H357Q387 101 407 103T452 111T492 133T514 171Q516 176 517 194',
    0x1D758: '92 664Q98 683 118 690Q121 691 312 691T508 689Q534 682 534 644V632V618Q534 582 508 573L502 572Q496 572 489 572Q486 572 463 572T416 573Q333 573 291 575H253V303Q253 31 251 25Q242 0 199 0H170L119 1Q99 7 92 28V664',
    0x1D759: '381 692Q386 694 458 694Q516 694 527 693T549 687Q564 680 575 663Q576 658 715 349T856 27Q856 6 838 1H826Q815 1 795 1T747 1T686 1T616 0T539 0T458 0T378 0T300 0T230 0T169 1T122 1T90 1H78Q60 6 60 27Q62 38 201 349T341 663Q356 687 381 692ZM627 148Q626 149 581 250T492 453L447 554Q447 553 446 552Q444 546 326 278L268 148Q268 147 448 147Q627 147 627 148',
    0x1D75A: '277 122Q280 122 380 123T544 125Q552 125 557 125T565 124T569 124Q595 115 595 75V62V47Q595 9 569 2Q564 0 341 0L119 1Q99 7 92 28V664Q98 683 118 690Q121 691 335 691T554 689Q580 682 580 644V632V618Q580 582 554 573Q553 573 551 573T542 572T527 572Q464 572 364 573T260 575H253V412H385H459Q524 412 536 404T549 357Q549 341 549 334T542 318T523 305Q518 303 385 303H253V122H277',
    0x1D75B: '411 584Q243 581 131 581Q122 581 116 581T106 582T102 582Q84 589 80 600T76 640L77 667Q83 686 103 693Q106 694 343 694Q579 694 584 692Q592 691 599 684T609 668Q610 665 610 646Q610 614 608 608Q605 603 434 361L261 116Q340 117 402 118T490 119T533 120T560 120H572Q605 120 614 95Q616 89 616 60V46Q616 9 590 2Q585 0 339 0Q92 0 87 2Q79 3 72 10T62 26Q61 29 61 49Q61 84 63 90Q65 94 152 217T325 461T411 584',
    0x1D75C: '92 667Q101 694 143 694H172H198Q244 694 251 669Q253 663 253 539V415H540V539Q540 558 540 585T539 621Q539 673 550 683T611 694H621H646Q671 694 683 690T700 669Q702 663 702 347T700 25Q696 9 684 5T646 0H621H606Q560 0 550 11T539 76Q539 85 539 116T540 169V306H253V169Q253 147 253 116T254 75Q254 23 245 12T194 0H170L119 1Q99 7 92 28V667',
    0x1D75D: '62 340Q62 716 425 716Q511 716 576 696T681 642T747 559T783 458T793 341Q793 264 777 203T721 89T608 7T428 -22Q62 -22 62 340ZM638 333Q638 365 637 387T632 441T621 495T600 542T567 583T518 611T451 628Q443 629 427 629Q402 629 378 624T327 608T276 571T240 511Q217 453 217 345Q217 254 231 204T279 120Q333 69 428 69Q522 69 576 120Q638 183 638 333ZM279 349V373Q279 413 305 420Q309 422 427 422H487Q550 422 563 414T576 369V349Q576 345 576 337T577 324Q577 284 550 277Q545 275 428 275H369Q306 275 293 283T279 329V349',
    0x1D75E: '85 667Q94 694 136 694H165H191Q237 694 244 669Q246 663 246 347T244 25Q235 0 192 0H163L112 1Q92 7 85 28V667',
    0x1D75F: '92 667Q101 694 139 694H163H186Q225 694 234 671Q236 663 236 529L237 392L533 682Q550 694 590 694H623H681Q695 680 695 672Q695 670 693 664Q688 657 561 533L431 405L698 33Q701 28 701 23Q701 7 683 0H626H604Q571 0 564 2T545 13Q544 14 530 33T489 90T437 162L332 307Q331 307 284 260L236 214V122V65Q236 32 231 19T210 2Q205 0 161 0L119 1Q99 7 92 28V667',
    0x1D760: '106 0H83Q41 0 41 28Q41 39 133 349T229 667Q242 694 296 694H335H375Q403 694 418 689T442 667Q445 660 537 350T630 28Q630 11 619 6T584 0H555H526Q478 0 465 27Q462 32 431 136T366 372T325 555V546Q320 503 287 376T222 141T186 27Q184 22 177 15T165 6Q154 0 106 0',
    0x1D761: '92 667Q98 684 109 689T146 695Q152 695 167 695T192 694Q200 694 214 694T234 695Q291 695 305 664Q313 651 400 419T487 165Q487 162 488 162T489 165Q489 187 574 413T671 664Q679 680 695 688Q708 694 785 694H828Q855 694 867 689T884 669Q886 663 886 347T884 25Q876 0 832 0H817H802Q758 0 750 25Q748 31 748 293V555L746 544Q737 509 692 386T606 160T564 52Q548 22 502 22H487H472Q423 22 410 52Q407 59 367 160T283 385T231 546L230 548Q229 548 229 293Q229 31 227 25Q222 9 211 5T176 0H158L119 1Q99 7 92 28V667',
    0x1D762: '92 667Q98 684 109 689T146 694H185Q273 694 279 692Q301 689 315 669Q322 660 419 453L554 163L562 143Q564 143 564 401Q564 663 566 669Q574 694 618 694H633H648Q692 694 700 669Q702 663 702 347T700 25Q696 10 683 5T642 0H596H551Q520 0 505 4T478 25Q471 34 374 241L239 532Q231 550 231 552L229 479Q229 440 229 293Q229 31 227 25Q222 9 211 5T176 0H158L119 1Q99 7 92 28V667',
    0x1D763: '627 553Q609 553 512 554T366 555Q316 555 220 554T105 553Q96 553 90 553T82 554T78 554Q61 560 57 571T52 605V623L53 661Q59 680 79 687Q82 688 366 688Q649 688 654 686Q680 679 680 639V621V603Q680 563 654 554Q653 554 651 554T642 554T627 553ZM149 423Q152 424 366 424Q579 424 584 422Q610 415 610 376V358V340Q610 300 584 293Q579 291 366 291H232Q162 291 150 293T129 306Q122 315 122 360L123 397Q129 416 149 423ZM108 135Q143 135 226 134T363 133Q407 133 507 134T632 135H645Q675 135 684 110Q686 104 686 68V49Q686 9 660 2Q655 0 364 0L74 1Q57 7 49 21L47 28L46 65V83Q46 126 72 133Q80 135 108 135',
    0x1D764: '362 715Q364 715 376 715T394 716H400Q542 716 626 643T727 426Q731 395 731 342Q731 271 722 225Q674 -22 396 -22Q320 -22 259 -3T148 68T77 201Q62 257 62 342Q62 447 86 522T173 649Q245 707 362 715ZM568 433Q551 623 396 623Q383 623 370 622T333 612T292 591T257 550T233 485Q223 442 223 350Q223 276 232 227T267 137Q309 74 397 74Q433 74 461 85T507 113T537 156T556 205T566 260T569 310T570 357Q570 409 568 433',
    0x1D765: '92 664Q98 683 118 690Q121 691 396 691T676 689Q695 684 700 666Q702 660 702 345Q702 31 700 25Q696 9 684 5T646 0H621H596Q571 0 559 4T542 25Q540 31 540 307V582H253V307Q253 31 251 25Q242 0 199 0H170L119 1Q99 7 92 28V664',
    0x1D766: '641 470Q641 426 630 391T603 334T561 295T513 271T459 259T408 254T361 253H350H337H253V142Q253 125 253 100T254 67Q254 32 249 19T227 2Q222 0 170 0L119 1Q99 7 92 28V667Q98 686 118 693Q121 694 271 694Q428 693 462 688Q641 656 641 470ZM487 467Q487 495 485 510T474 546T442 578T382 592Q375 593 310 593H250V347H309H339Q364 347 380 348T418 354T451 368T474 395T486 438Q487 444 487 467',
    0x1D768: '322 124Q326 124 457 125T672 127H689Q721 127 730 102Q732 96 732 64V48Q732 9 706 2Q701 0 394 0L89 1Q76 5 69 13T62 29V36Q62 37 62 38Q62 47 70 58T126 126Q161 167 185 196Q302 335 302 336L187 463Q74 584 68 594Q61 603 61 639L62 667Q68 686 88 693Q91 694 396 694T706 692Q732 686 732 647V635V621Q732 585 706 576Q705 576 702 576T691 576T670 575L302 578Q302 577 394 475T490 371Q498 362 498 347Q498 336 488 323T408 226L322 124',
    0x1D769: '67 687Q70 688 366 688Q661 688 666 686Q692 680 692 641V629V615Q692 579 666 570H660Q655 569 648 569Q645 569 624 569T581 570Q505 570 475 572H447V302Q447 31 445 25Q436 0 393 0H364L313 1Q293 7 286 28L285 300V572H257Q227 570 151 570Q130 570 109 570T84 569Q77 569 72 570H66Q48 577 44 588T40 631L41 661Q47 680 67 687',
    0x1D76A: '62 560Q62 607 94 644T169 698T253 715Q273 715 286 713T322 704T363 677T398 625Q413 597 423 556L428 540Q429 541 436 566T454 620T494 677T561 713Q570 715 593 715Q682 715 737 668T793 560Q793 549 793 545T786 533T767 520H670Q646 532 644 551T632 579Q618 594 591 594Q539 594 524 530T509 321V216Q509 31 507 25Q498 0 455 0H426L375 1Q355 7 348 28L347 232Q346 344 346 441Q346 442 343 468T335 521T312 571T266 594Q252 594 247 593Q228 586 220 576T212 557T209 539T191 523L185 520H88Q75 527 69 534T63 545T62 560',
    0x1D76B: '62 292T62 347T80 445T124 511T183 552T243 574T292 584L315 587H319V627L320 667Q329 694 370 694H397H422Q466 694 473 669Q475 663 475 625V587H478Q479 587 500 584T548 575T608 553T668 513T713 446T732 347Q732 253 674 187Q655 167 628 152T576 128T530 116T493 109L478 107H475V69V50Q475 9 449 2Q444 0 395 0L347 1Q327 7 320 28L319 67V107H315L292 110Q269 114 243 119T184 142T124 182T80 249ZM319 197T319 347T318 497Q316 497 307 494T284 485T262 471Q220 438 220 347Q220 285 239 249Q248 234 261 223T286 208T308 200L317 197Q319 197 319 347ZM572 347V357Q572 387 569 407T548 452T496 491Q495 491 494 491T487 493T475 497V197Q518 210 541 232T571 303Q572 312 572 347',
    0x1D76C: '52 1Q37 11 37 23Q37 26 39 32Q39 34 158 202L275 369Q275 370 221 441T112 586T55 663Q53 669 53 672Q53 687 68 693H72Q77 693 84 693T99 694T118 694T139 694H176Q203 694 212 692T230 682Q231 681 239 669T265 634T296 591L358 504L418 591Q481 682 486 686Q491 691 499 692Q505 694 569 694H632Q650 685 650 672Q650 667 646 660Q643 654 592 582T491 440T441 369T566 201T693 29Q694 27 694 23Q694 11 677 0H607L537 1Q523 6 519 10T437 131Q422 153 411 170T390 200T375 222T365 237T359 245L357 247L348 232Q339 218 319 188T283 131Q222 37 211 22T186 1H52',
    0x1D76D: '61 585Q62 594 62 597T64 606T73 616T89 626H138Q196 626 208 620Q243 602 253 546T261 431T271 309T325 219Q342 205 349 205Q350 205 350 436L351 667Q360 694 401 694H428H454Q495 694 504 671Q506 663 506 436L507 205Q542 222 561 251T586 318T593 392T595 472T602 546Q614 614 661 625Q665 626 708 626H730Q766 626 780 618T794 582Q794 548 768 540Q755 538 754 501T750 410T736 298T680 191T560 120Q550 116 512 109H506V70V50Q506 9 480 2Q475 0 426 0L378 1Q358 7 351 28L350 68V109L335 111Q298 117 267 129T214 156T175 191T146 229T127 272T115 314T109 357T106 395T105 429Q104 537 87 540Q66 548 63 565Q61 570 61 585',
    0x1D76E: '241 122Q225 154 191 199T131 278T83 363T61 464Q61 497 68 527T94 591T145 650T228 693T349 715Q354 715 370 715T396 716Q539 716 622 668Q658 647 682 617T715 556T728 505T732 465Q732 415 711 365T663 280T602 200T552 122H632Q649 122 669 122T693 123H697Q736 123 742 98Q744 92 744 62V47Q744 9 718 2Q713 0 591 0L471 1Q454 7 446 21Q444 27 444 45Q444 96 463 154T506 257T549 360T569 469Q569 504 563 530T538 580T485 616T396 629Q313 629 268 594T223 468Q223 419 243 361T286 258T330 152T350 41Q350 14 335 7T276 -1Q267 -1 241 -1T197 0L77 1Q57 7 50 28L49 59V74Q49 114 75 121Q81 123 100 123Q104 123 124 123T161 122H241',
    0x1D7CE: '266 654H280H282Q500 654 524 418Q529 370 529 320Q529 125 456 52Q397 -10 287 -10Q110 -10 63 154Q45 212 45 316Q45 504 113 585Q140 618 185 636T266 654ZM374 548Q347 604 286 604Q247 604 218 575Q197 552 193 511T188 311Q188 159 196 116Q202 87 225 64T287 41Q339 41 367 87Q379 107 382 152T386 329Q386 518 374 548',
    0x1D7CF: '481 0L294 3Q136 3 109 0H96V62H227V304Q227 546 225 546Q169 529 97 529H80V591H97Q231 591 308 647L319 655H333Q355 655 359 644Q361 640 361 351V62H494V0H481',
    0x1D7D0: '175 580Q175 578 185 572T205 551T215 510Q215 467 191 449T137 430Q107 430 83 448T58 511Q58 558 91 592T168 640T259 654Q328 654 383 637Q451 610 484 563T517 459Q517 401 482 360T368 262Q340 243 265 184L210 140H274Q416 140 429 145Q439 148 447 186T455 237H517V233Q516 230 501 119Q489 9 486 4V0H57V25Q57 51 58 54Q60 57 109 106T215 214T288 291Q364 377 364 458Q364 515 328 553T231 592Q214 592 201 589T181 584T175 580',
    0x1D7D1: '80 503Q80 565 133 610T274 655Q366 655 421 623T491 538Q493 528 493 510Q493 446 453 407T361 348L376 344Q452 324 489 281T526 184Q526 152 514 121T474 58T392 8T265 -11Q175 -11 111 34T48 152Q50 187 72 209T132 232Q171 232 193 208T216 147Q216 136 214 126T207 108T197 94T187 84T178 77T170 72L168 71Q168 70 179 65T215 54T266 48H270Q331 48 350 105Q358 128 358 185Q358 239 348 268T309 313Q292 321 242 322Q205 322 198 324T191 341V348Q191 366 196 369T232 375Q239 375 247 376T260 377T268 378Q284 383 297 393T326 436T341 517Q341 536 339 547T331 573T308 593T266 600Q248 600 241 599Q214 593 183 576Q234 556 234 503Q234 462 210 444T157 426Q126 426 103 446T80 503',
    0x1D7D2: '531 0Q510 3 381 3Q238 3 214 0H201V62H313V155H32V217L205 434Q342 606 362 630T387 655L391 656Q395 656 401 656T414 656H427Q447 656 451 645Q453 641 453 429V217H542V155H453V62H542V0H531ZM324 217V494L103 218L213 217H324',
    0x1D7D3: '100 565V605Q100 637 102 646T113 655Q116 655 139 647T202 631T286 623Q332 623 372 631T434 647T459 655Q466 655 469 651T472 643T472 629Q472 613 463 601Q370 487 219 487Q195 487 183 488T169 490T168 433V376Q169 376 174 379T188 387T211 397T244 405T288 409Q390 409 453 352T517 201Q517 106 445 48T253 -11Q169 -11 113 37T57 154Q57 187 79 208T131 229T183 209T206 154Q206 99 155 83Q152 82 157 78Q196 47 253 47Q347 47 358 135Q358 137 358 138Q360 158 360 209Q360 277 355 301T337 338Q315 358 282 358Q202 358 160 303Q153 294 149 292T130 290Q107 290 102 301Q100 304 100 474V565',
    0x1D7D4: '48 318Q48 395 68 456T120 553T193 613T273 646T350 655Q425 655 461 616T497 524Q497 485 475 468T428 451Q399 451 378 470T357 521Q357 565 403 588Q375 601 351 601Q313 601 282 584Q242 565 222 526Q199 473 199 367Q201 369 210 380T227 396T246 410T275 422T312 426Q438 426 494 332Q526 285 526 208V199Q526 112 465 53Q428 17 388 3T285 -11Q236 -11 195 7T135 43T104 80Q48 165 48 318ZM375 231V244V268Q375 295 373 310T364 342T341 366T299 374H297Q231 374 208 287Q200 257 200 196Q201 120 209 100Q231 47 288 47Q351 47 368 90Q375 112 375 231',
    0x1D7D5: '256 -11Q231 -11 208 5T185 65Q185 105 193 146T212 220T241 289T275 349T312 402T346 445T377 479T397 502L400 504H301Q156 503 150 497Q142 491 134 456T126 407H64V411Q65 414 82 544T99 675T130 676H161V673Q161 669 162 666T167 661T173 657T181 654T190 652T200 651T210 650T220 649T229 648Q237 648 254 647T276 646Q277 646 426 644H558V620V607Q558 596 551 586T509 537Q489 515 476 500Q390 401 384 393Q349 339 337 259T324 113T322 38Q307 -11 256 -11',
    0x1D7D6: '80 474Q80 561 139 607T278 654Q357 654 411 632Q490 593 494 509Q494 424 416 376L407 371L418 364Q432 356 447 345T481 312T513 260T526 192Q526 100 461 45T285 -11Q184 -11 116 32T48 164Q48 181 50 196T58 225T69 249T84 270T100 286T117 300T134 311T149 321T162 329L152 336Q120 360 100 397T80 474ZM347 404Q404 446 404 503Q404 579 317 599Q309 600 276 600Q178 600 170 538Q170 532 171 527T173 518T178 509T184 501T194 492T205 484T219 476T235 467T254 456T275 445L347 404ZM289 47Q323 47 351 54T402 82T425 137Q425 147 421 161Q411 183 391 197T303 249Q224 293 223 293Q220 291 215 288T197 273T175 248T157 213T149 167Q149 109 188 78T289 47',
    0x1D7D7: '178 59Q206 48 238 48Q311 48 345 102Q370 138 375 259V278Q374 278 369 271T350 252T322 232Q297 220 258 220Q172 220 110 275T48 438V446Q54 561 146 618Q199 654 278 654Q321 654 329 653Q526 621 526 330Q526 252 507 190T457 92T388 31T312 -2T240 -11Q165 -11 121 25T77 120Q77 159 99 176T147 193T194 177T217 122Q217 113 216 106T211 92T205 82T198 73T191 67T184 62T178 59ZM374 446V465Q374 523 364 552T315 598Q309 600 293 601Q227 601 210 562Q199 539 199 433Q199 343 204 319T235 279Q250 272 274 271H282Q293 271 303 274T327 288T353 323T371 385Q374 403 374 446',
    0x1D7E2: '117 621Q174 678 247 678Q305 678 351 647Q396 617 424 557Q460 472 460 328Q460 271 455 224Q448 154 427 104T376 27T314 -10T249 -22Q201 -22 160 1T91 67Q39 154 39 316Q39 541 117 621ZM250 55Q274 55 293 66T324 93T344 136T357 185T364 240T366 291T367 340Q367 373 367 393T363 449T352 507T332 553T299 589T250 601Q217 601 194 584T159 542T141 479T133 411T132 340V331Q132 299 133 277T137 219T147 157T167 107T201 68T250 55',
    0x1D7E3: '94 612Q172 616 211 632T284 678H307V73H430V0H88V73H213V317Q213 560 212 560Q210 558 197 554T155 546T96 540L83 539V612H94',
    0x1D7E4: '222 599Q190 599 166 585T128 550T108 509T97 474T93 459L67 492L42 526L47 539Q72 608 120 642T225 677Q304 677 355 644Q449 579 449 454Q449 373 361 290Q351 280 315 250T199 144Q156 103 137 85L293 86H449V0H50V79L216 242Q284 302 317 349T351 456Q351 517 315 558T222 599',
    0x1D7E5: '333 521Q333 554 313 579T243 604Q154 604 99 514L78 546Q56 577 56 579Q56 580 62 589T82 611T114 637T162 662T222 677Q224 677 231 677T242 678H245Q318 678 374 634T430 520Q430 483 417 452T382 398T351 369T329 354L328 353Q369 333 373 330Q408 306 432 268T457 184Q457 103 397 41T242 -22Q131 -22 51 58L42 68L49 105L55 142L58 138Q62 134 66 130T77 120T91 108T108 96T129 83T152 72T179 63T209 57T242 54Q285 54 319 86T353 184Q353 231 331 267T260 315L213 316H166V354Q166 392 167 392Q233 395 257 405Q290 418 311 450T333 521',
    0x1D7E6: '271 654L272 656H380V235H471V159H380V0H286V159H28V235L149 443Q269 652 271 654ZM292 235V607Q292 604 290 591T286 571T280 548T269 517T252 476T226 422T189 354T140 267Q136 260 132 253T126 240L123 236Q123 235 207 235H292',
    0x1D7E7: '257 350Q236 350 218 342T189 323T171 301T160 281L157 273Q157 272 116 272H75V656H416V577H162V486Q162 396 163 396T174 403T207 418T258 426Q339 426 394 360T449 203Q449 113 386 46T226 -21H223Q188 -21 156 -11T102 13T64 42T41 66T33 77Q34 78 44 95T64 128L73 144Q93 112 117 93Q165 54 223 54Q270 54 306 86T345 197Q345 350 257 350',
    0x1D7E8: '42 318Q42 396 61 460T109 564T173 629T237 666T289 677H301H317Q359 677 408 658V621Q408 585 407 585H406Q359 605 308 605Q246 605 203 556T146 421Q143 403 144 403Q145 402 152 409Q216 469 299 469Q333 469 357 457T407 405Q457 330 457 226Q457 126 402 57Q340 -22 251 -22Q216 -22 183 -7T116 43T63 149T42 318ZM260 393Q216 393 188 365T150 306T141 243Q141 153 172 104Q192 68 230 56Q238 54 251 54Q311 54 342 116Q360 152 360 226Q360 297 344 332Q320 382 277 392Q275 392 270 392T260 393',
    0x1D7E9: '42 570V656H457V577L447 564Q345 439 295 289T244 0V-11H145V12Q160 330 356 550Q360 556 365 561T374 571L208 570H42',
    0x1D7EA: '55 500Q55 568 109 623T250 678Q327 678 385 627T444 501Q444 445 410 410T336 357L329 353H330Q378 335 417 293T456 184Q456 93 393 36T249 -22T106 35T43 184Q43 251 82 293T169 353Q171 354 166 356Q165 356 163 357Q113 378 84 416T55 500ZM358 496Q358 557 325 581T250 605Q206 605 174 580T141 496Q141 446 171 420T250 393Q298 393 328 419T358 496ZM245 316Q205 316 172 285T139 185V179Q139 79 222 57Q231 55 250 55H254Q295 55 327 84Q360 120 360 188Q360 254 326 285Q295 316 245 316',
    0x1D7EB: '44 476Q57 561 116 619T245 677H255Q308 677 349 647Q392 619 424 545T457 334Q457 178 382 78T205 -22Q168 -22 135 -10T86 14L72 25Q73 25 91 58L110 91Q127 78 136 72T163 60T203 54Q238 54 265 71T308 110T335 164T350 214T357 253V257L347 248Q284 187 200 187Q165 187 140 201T87 258Q42 332 42 430Q42 458 44 476ZM269 604Q265 605 254 605Q222 605 199 591T164 554T148 517T141 487Q140 478 140 430T141 373Q146 335 164 307T207 269Q223 263 247 263Q299 266 328 308T358 417Q358 435 356 456T346 511T318 570T269 604',
    0x1D7EC: '274 -22Q227 -22 190 -9T128 24T87 76T62 135T49 204T44 271T43 336V343V362Q43 407 45 440T56 524T86 613T141 677Q197 715 264 715Q314 715 353 702T418 669T460 616T487 555T500 483T505 413T506 343Q506 310 506 293T503 241T498 185T486 133T467 83T437 42T397 8T343 -13T274 -22ZM355 355V401Q355 448 354 476T349 537T336 587T311 617T272 629Q270 629 266 629T261 628Q219 618 207 568T194 419V355Q194 203 200 156T231 85Q250 66 275 66Q299 66 318 85Q342 109 348 156T355 355',
    0x1D7ED: '118 560H116Q107 560 100 561T85 572T76 600Q76 612 77 618T83 632T99 644Q102 645 135 646T207 660T275 700Q292 716 310 716Q353 716 360 691Q362 685 362 386V87H446Q464 76 468 69T473 44Q473 12 446 1H118Q105 7 100 13T94 25T93 44V62Q100 79 119 87H210V329Q210 571 208 571Q182 560 118 560',
    0x1D7EE: '339 477Q339 505 332 531T301 579T242 601Q165 601 136 503Q133 493 130 490T121 486Q116 486 94 514Q86 523 82 528Q46 572 46 577Q46 591 77 632T147 691Q192 716 257 716Q305 716 351 700Q416 674 455 615T494 481Q494 421 463 376T356 266Q326 240 287 205T224 146T199 122H331Q359 122 392 122T431 123H445Q485 123 492 98Q494 92 494 62V47Q494 9 468 2Q463 0 272 0L83 1Q63 7 56 28L55 57V89Q59 97 215 261Q255 303 275 327T317 394T339 477',
    0x1D7EF: '61 624Q62 630 83 650T153 693T262 716Q328 716 373 698T438 650T465 593T473 536Q473 438 375 376L387 371Q450 350 476 305T503 208Q503 164 492 126T456 53T380 -2T261 -22Q224 -22 189 -15T130 2T86 24T57 43T46 53Q46 56 53 99T62 145Q65 152 71 152Q76 152 90 143T123 121T175 99T248 87Q302 87 321 113T341 202Q341 264 329 292T279 329L232 331L190 332L184 338V411Q190 417 192 417Q193 418 205 419T232 421T252 424Q280 430 299 461T318 539V551Q318 599 283 609Q276 611 257 611Q225 611 199 601T159 577T136 554T120 543T102 560T76 597T62 618T61 624',
    0x1D7F0: '278 693H282Q285 693 291 693T305 694T322 694T342 694H377Q402 694 411 691T430 677Q434 670 434 646T435 456V249H461H472Q509 249 516 224Q518 219 518 194Q518 178 518 171T511 155T492 142Q488 140 461 140H435V86V53Q435 9 409 2Q405 0 366 0H351Q306 0 298 25Q296 31 296 86V140H179H123Q99 140 80 129T50 106T37 95Q31 95 31 163V208Q31 246 33 251Q251 673 262 684Q268 691 278 693ZM302 249V416L301 576Q301 536 165 276L151 250L226 249H302',
    0x1D7F1: '109 282Q87 290 83 310V666Q83 667 84 670T87 676T91 682T98 688T108 693Q112 694 269 694T431 692Q457 686 457 648V637V624Q457 588 431 581Q426 579 326 579H227V510Q227 442 229 442Q243 450 288 450Q377 450 435 399T494 222Q494 -22 241 -22Q202 -22 167 -11T109 16T69 49T45 79T37 94T69 151Q91 185 97 185Q105 185 112 170Q127 135 160 111T240 87Q266 87 284 94T311 111T325 142T331 179T332 226Q332 307 324 335T281 363Q228 363 197 306Q189 289 172 282H109',
    0x1D7F2: '414 589Q410 589 389 600T334 612Q275 612 243 575Q209 538 202 433V422L209 431Q243 487 317 487Q392 487 440 442Q478 402 490 357T503 236Q503 113 454 54Q421 13 381 -4T279 -22Q263 -22 250 -21T214 -15T173 1T133 30T96 77T68 146T50 242Q46 278 46 336Q46 406 52 447Q84 698 312 715L315 716Q318 716 321 716Q323 716 328 716T337 715Q398 715 425 688V596Q419 591 414 589ZM282 87Q324 89 336 117T348 231Q348 310 343 343T324 388T277 399Q249 399 231 373T208 317T202 253Q202 201 207 168T224 117T249 93T282 87',
    0x1D7F3: '135 38Q135 190 198 335T353 572H215Q185 572 151 572T110 571H96Q55 571 48 596Q46 602 46 633V648Q46 686 72 693Q76 695 124 695Q134 695 183 695T274 694Q472 694 477 692Q503 686 503 648V637Q503 612 502 605T491 588Q300 349 292 46V36Q292 -4 266 -9Q262 -11 214 -11H192Q160 -11 148 -3T135 38',
    0x1D7F4: '61 518Q61 574 79 614T128 676T192 706T263 715H270Q298 715 318 714T373 701T430 671T470 612T488 517Q488 459 458 423T390 376Q388 375 393 373Q395 372 398 371Q503 330 503 204Q503 -22 275 -22Q209 -22 163 -3T92 49T57 120T46 204Q46 230 50 252T61 289T77 318T96 339T116 353T134 363T148 369T158 373T160 376Q118 389 90 424T61 518ZM344 538Q344 563 340 578T326 600T307 609T279 612Q232 612 218 594T204 518Q204 459 216 439T275 418Q328 418 338 450Q344 464 344 515V538ZM248 88T274 88T315 94T338 117T346 149T349 197Q349 269 342 290Q338 309 320 320T274 331Q246 331 229 320T207 290Q200 269 200 197Q201 163 202 149T210 118T232 94',
    0x1D7F5: '347 272Q346 272 342 266T330 250T309 230T276 214T230 207Q185 207 150 223Q116 240 90 276T54 357Q46 393 46 468Q46 469 46 484T47 502T48 520T51 540T55 559T61 579T69 599T81 620T96 640T115 661Q174 716 276 716Q299 716 317 714T369 698T426 658T471 580T499 456Q503 402 503 342Q503 115 392 29Q322 -22 231 -22Q163 -22 115 7L82 31Q76 38 81 46Q116 112 127 123Q130 126 134 126T148 116T179 97T226 87Q287 87 318 132Q323 139 326 146T332 165T337 182T340 204T342 225T345 249T347 272ZM201 547T201 454T211 329T262 294Q276 294 285 296T310 312T335 355Q347 391 347 447Q347 520 340 550T317 595Q300 612 277 612Q226 612 214 580',
    0x1D7F6: '42 305Q42 450 111 535T257 621Q335 621 390 562Q482 468 482 306Q482 174 418 82T262 -10T106 82T42 305ZM257 545Q209 545 168 481T126 320Q126 220 162 147Q204 65 262 65Q318 65 358 139T398 320V328Q395 411 364 470T284 543Q270 545 257 545',
    0x1D7F7: '99 461Q99 470 99 474T104 487T120 498T151 502Q213 517 251 596Q264 622 283 622Q308 622 319 597V76H373H401Q428 76 439 69T450 38Q450 11 428 1H127Q104 10 104 38Q104 62 115 69T153 76H181H235V269Q235 461 234 461Q184 426 137 424H133Q124 424 119 425T109 431T99 447V461',
    0x1D7F8: '52 462Q52 528 110 575T247 622H250Q343 622 407 565T472 421Q472 371 446 324T390 248T308 178Q307 177 275 151T214 101L185 77Q185 76 286 76H388V87Q388 105 397 114T430 123T463 114Q470 107 471 100T472 61V42Q472 24 468 16T450 1H75Q53 10 53 32V38V48Q53 57 63 67T127 122Q153 144 169 157L289 256Q388 345 388 419Q388 473 346 509T231 545H224Q176 545 146 499L144 494Q155 476 155 459Q154 459 155 455T154 444T148 430T136 417T114 408Q113 408 110 408T104 407Q80 407 66 422T52 462',
    0x1D7F9: '260 546Q233 546 211 541T180 531T171 524L174 514Q177 505 177 497Q177 476 162 461T125 446Q106 446 90 459T73 504Q76 540 98 565T150 601T203 616T239 621Q241 622 265 622Q322 620 362 602T420 558T444 513T451 478Q451 386 369 329L375 326Q381 323 386 320T401 311T419 298T436 283T452 263T466 240T475 212T479 180Q479 99 416 44T259 -11T105 28T44 130Q44 154 59 168T95 183Q117 183 132 169T148 131Q148 119 139 101Q175 65 260 65Q316 65 355 97T395 179Q395 211 375 240Q336 292 253 292H234H215Q194 292 185 299T175 330Q175 350 184 359Q192 368 238 370T309 384Q336 398 351 423T367 474Q367 496 350 513Q321 546 260 546',
    0x1D7FA: '235 1Q213 10 213 32V38V46Q213 65 230 73Q236 76 274 76H314V168H183L52 169Q37 175 33 182T29 205V218L30 244Q53 283 155 443T264 613Q276 623 298 623H323H363Q378 616 385 601V244H429H450Q474 244 484 237T495 206Q495 179 477 171Q471 168 429 168H385V76H425H442Q466 76 476 69T487 38Q487 10 465 1H235ZM314 244V554L117 245L215 244H314',
    0x1D7FB: '387 189Q387 244 354 278T273 313Q230 313 205 301T163 271T138 249H120Q102 249 97 251Q85 258 83 266T80 311Q80 320 80 359T81 430Q81 587 82 591Q88 605 103 610H108Q112 610 120 610T138 610T163 610T192 611T225 611T260 611H415Q416 610 421 607T428 602T432 596T436 587T437 573Q437 567 437 562T434 554T431 548T427 543T423 540T418 538L415 536L289 535H164V363L170 366Q175 368 184 372T207 380T238 386T276 389Q357 389 414 331T472 187Q472 116 412 53T245 -10Q218 -10 209 -9Q126 5 89 48T52 137Q52 164 68 177T104 191Q130 191 143 175T156 141Q156 132 154 125T149 113T146 107Q146 104 155 95T188 76T245 65Q298 65 342 98T387 189',
    0x1D7FC: '357 536Q357 546 318 546Q258 546 205 497T133 357V353L144 361Q210 402 285 402Q362 402 414 350Q479 285 479 193Q479 111 418 50T263 -11Q234 -11 207 -3T149 26T97 81T60 171T45 301Q45 444 129 533T319 622Q388 622 421 589T454 510Q454 491 442 475T402 458Q373 458 362 475T350 510Q350 520 354 528L357 536ZM319 326T269 326T179 298T136 223Q136 202 143 174T176 112T237 68Q246 66 265 66Q319 66 360 107Q395 146 395 197Q395 250 356 289Q319 326 269 326',
    0x1D7FD: '204 -10Q162 -10 162 40Q162 146 198 261T310 477Q311 478 321 491T342 517T358 535H128V524Q128 506 119 497Q111 489 86 489H78Q55 489 46 508Q44 513 44 557V580Q44 605 52 616T88 627H93Q114 627 125 611H458Q474 598 477 593T480 573Q480 559 478 553T469 543T446 521T408 477Q252 290 246 49Q246 43 246 37T246 27T245 22Q243 11 233 1T204 -10',
    0x1D7FE: '58 460Q58 523 117 572T254 621Q290 621 298 620Q376 607 421 560T466 460Q466 441 460 424T443 393T421 370T397 352T374 340T357 332L350 330L356 328Q363 325 371 321T392 310T415 295T439 274T459 249T473 217T479 179Q479 102 418 46T262 -10T106 46T45 179Q45 202 52 222T70 257T96 284T123 305T148 319T167 328L174 330L170 332Q166 333 159 336T145 343Q104 362 81 393T58 460ZM382 458Q382 491 349 518T263 546Q215 546 179 521T142 458Q142 421 178 395T262 368Q315 368 348 396T382 458ZM396 178Q396 223 358 257T263 292Q206 292 167 258T128 178Q128 137 163 102T262 66Q324 66 360 101T396 178',
    0x1D7FF: '392 259Q333 210 236 210H233Q163 210 109 262Q46 325 46 411T99 550Q164 622 264 622Q293 622 319 615T376 587T428 532T464 440T479 304Q479 167 400 78T217 -11Q140 -11 105 22T70 101Q70 124 84 138T122 153Q150 153 162 137T174 101Q174 91 168 76Q179 65 216 65Q267 65 300 93Q322 109 339 130T366 173T380 210T388 242T392 259ZM388 389Q388 438 357 492T268 546T185 520Q129 479 129 415Q129 384 138 363Q145 349 156 334T195 302T255 285Q305 285 345 313T388 389',
}, {
    0x2033: "\u2032\u2032",
    0x2034: "\u2032\u2032\u2032",
    0x2036: "\u2035\u2035",
    0x2037: "\u2035\u2035\u2035",
    0x2057: "\u2032\u2032\u2032\u2032",
    0x2204: "\u2203\u0338",
    0x220C: "\u220B\u0338",
    0x2244: "\u2243\u0338",
    0x2249: "\u2248\u0338",
    0x2262: "\u2261\u0338",
    0x226D: "\u224D\u0338",
    0x2274: "\u2272\u0338",
    0x2275: "\u2273\u0338",
    0x2278: "\u2276\u0338",
    0x2279: "\u2277\u0338",
    0x2284: "\u2282\u0338",
    0x2285: "\u2283\u0338",
    0x22E2: "\u2291\u0338",
    0x22E3: "\u2292\u0338",
    0x2A0C: "\u222C\u222C",
});
