"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Pcy: '\u041F',
    Poincareplane: '\u210C',
    Pr: '\u2ABB',
    Prime: '\u2033',
    Proportion: '\u2237',
    par: '\u2225',
    para: '\u00B6',
    parallel: '\u2225',
    parsim: '\u2AF3',
    parsl: '\u2AFD',
    part: '\u2202',
    pcy: '\u043F',
    percnt: '\u0025',
    permil: '\u2030',
    perp: '\u22A5',
    pertenk: '\u2031',
    phmmat: '\u2133',
    phone: '\u260E',
    pitchfork: '\u22D4',
    planck: '\u210F',
    planckh: '\u210E',
    plankv: '\u210F',
    plus: '\u002B',
    plusacir: '\u2A23',
    plusb: '\u229E',
    pluscir: '\u2A22',
    plusdo: '\u2214',
    plusdu: '\u2A25',
    pluse: '\u2A72',
    plusmn: '\u00B1',
    plussim: '\u2A26',
    plustwo: '\u2A27',
    pm: '\u00B1',
    pointint: '\u2A15',
    pound: '\u00A3',
    pr: '\u227A',
    prE: '\u2AB3',
    prcue: '\u227C',
    pre: '\u2AAF',
    prec: '\u227A',
    precapprox: '\u2AB7',
    preccurlyeq: '\u227C',
    preceq: '\u2AAF',
    precsim: '\u227E',
    primes: '\u2119',
    prnE: '\u2AB5',
    prnap: '\u2AB9',
    prnsim: '\u22E8',
    prod: '\u220F',
    profalar: '\u232E',
    profline: '\u2312',
    profsurf: '\u2313',
    prop: '\u221D',
    propto: '\u221D',
    prsim: '\u227E',
    prurel: '\u22B0',
    puncsp: '\u2008'
}, 'p');
