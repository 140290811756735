"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Map: '\u2905',
    Mcy: '\u041C',
    MediumSpace: '\u205F',
    Mellintrf: '\u2133',
    Mu: '\u039C',
    mDDot: '\u223A',
    male: '\u2642',
    maltese: '\u2720',
    map: '\u21A6',
    mapsto: '\u21A6',
    mapstodown: '\u21A7',
    mapstoleft: '\u21A4',
    mapstoup: '\u21A5',
    marker: '\u25AE',
    mcomma: '\u2A29',
    mcy: '\u043C',
    mdash: '\u2014',
    measuredangle: '\u2221',
    micro: '\u00B5',
    mid: '\u2223',
    midast: '\u002A',
    midcir: '\u2AF0',
    middot: '\u00B7',
    minus: '\u2212',
    minusb: '\u229F',
    minusd: '\u2238',
    minusdu: '\u2A2A',
    mlcp: '\u2ADB',
    mldr: '\u2026',
    mnplus: '\u2213',
    models: '\u22A7',
    mp: '\u2213',
    mstpos: '\u223E',
    mumap: '\u22B8'
}, 'm');
