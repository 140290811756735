"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sansSerif = void 0;
var FontData_js_1 = require("../../FontData.js");
var sans_serif_js_1 = require("../../../common/fonts/tex/sans-serif.js");
exports.sansSerif = FontData_js_1.AddPaths(sans_serif_js_1.sansSerif, {
    0x21: '120 187Q120 225 115 440T110 693Q110 694 159 694T208 693Q208 655 203 440T198 187Q198 186 159 186T120 187ZM110 0V98H208V0H110',
    0x22: '33 596V694H131V597L82 471H32L47 532Q62 593 63 594Q63 596 48 596H33ZM227 596V694H325V597L276 471H226L241 532Q256 593 257 594Q257 596 242 596H227',
    0x23: '56 350Q56 363 70 370H192Q314 370 314 371L356 526Q396 676 401 685Q406 694 416 694Q423 694 429 689T436 677Q436 673 396 523T355 371Q355 370 449 370Q544 370 544 371L586 526Q628 682 630 685Q636 694 646 694Q653 694 659 689T665 678Q665 670 645 593T605 444L585 371Q585 370 673 370H762Q777 362 777 350Q777 337 767 334T723 330H668H573L567 305Q560 282 545 226L530 171L646 170H721Q756 170 766 167T777 150Q777 138 762 130H640Q518 130 518 129L476 -26Q434 -182 432 -185Q426 -194 416 -194Q409 -194 403 -189T397 -178Q397 -170 417 -93T457 56L477 129Q477 130 383 130Q288 130 288 129L246 -26Q204 -182 202 -185Q196 -194 186 -194Q179 -194 173 -189T167 -178Q167 -170 187 -94T227 56L247 129Q247 130 159 130H70Q56 137 56 150Q56 165 72 170H259L265 195Q273 222 287 275L302 330H186L70 331Q63 334 58 339Q56 343 56 350ZM489 170L532 330H343L337 305Q330 282 315 226L300 171L394 170H489',
    0x24: '55 509Q55 585 103 638T213 701V750H286V703H289Q312 703 354 689Q372 682 399 666T427 646L413 569Q413 568 403 575Q352 615 291 624H286V405Q357 389 400 331T444 199Q444 128 402 69T286 -7V-56H213V-9Q167 -3 125 14T63 44T44 60Q44 61 52 101L59 140L69 132Q78 125 87 119T107 107T124 97T141 90T157 84T173 80T187 76T201 73T213 70V317L202 319Q141 335 98 386T55 509ZM213 424V620L203 615Q143 587 143 522Q143 455 213 424ZM356 187Q356 208 350 227T334 258T315 280T298 293T287 297Q286 297 286 186V75Q356 110 356 187',
    0x25: '56 549Q56 639 98 694T195 750Q248 750 290 694T332 548Q332 455 290 401T195 347Q141 347 99 403T56 549ZM248 549Q248 602 234 638T195 674Q145 674 145 549Q145 423 195 423Q219 423 233 459T248 549ZM197 -56Q187 -56 182 -49T176 -35Q176 -33 178 -27Q179 -25 399 356T623 741Q626 750 639 750Q648 750 654 744T659 729Q657 721 435 336T207 -52Q203 -56 197 -56ZM500 146Q500 235 542 291T639 347Q692 347 734 293T776 146Q776 53 733 -1T639 -56Q587 -56 544 -2T500 146ZM692 146Q692 199 678 235T639 271Q589 271 589 146Q589 20 639 20Q663 20 677 56T692 146',
    0x26: '156 502Q156 600 198 658T302 716Q367 716 405 665T444 549Q444 531 442 523Q426 446 304 348L287 334Q305 297 340 249T402 170T430 139T443 149T472 181T509 231T549 303T583 394Q584 398 586 404Q587 408 587 409Q588 409 626 399T665 388Q663 381 660 369T644 322T614 253T567 176T502 98L491 87Q534 57 584 57Q653 57 700 75Q702 75 702 34T700 -7Q652 -22 586 -22H580Q505 -22 434 26L421 34Q419 33 405 25T374 11T336 -4T285 -17T226 -22Q143 -22 93 31T42 152Q42 184 51 211T81 260T111 291T144 317L184 348L178 365Q156 430 156 502ZM359 552Q359 588 345 614T302 640Q292 640 282 636T260 622T241 590T233 535Q236 474 253 417L257 407L271 419Q312 454 330 479Q359 514 359 552ZM345 102Q262 190 216 277Q215 277 204 267T180 247T165 236Q135 208 135 159Q135 123 152 97T198 61Q207 58 227 58Q286 58 357 89L345 102',
    0x27: '90 596V694H188V597L139 471H89L104 532Q119 593 120 594Q120 596 105 596H90',
    0x28: '74 250Q74 564 240 733L257 750H333L323 739Q167 573 167 250T323 -239L333 -250H257L240 -233Q74 -63 74 250',
    0x29: '221 -73T221 250T65 739L55 750H131L148 733Q314 567 314 250T148 -233L131 -250H55L65 -239Q221 -73 221 250',
    0x2A: '208 717Q211 731 222 740T250 750Q265 750 277 741T291 717Q291 706 290 675T286 617L285 591L329 622Q369 651 376 655T393 659Q411 659 423 647T436 616Q436 609 434 603T429 594T419 585T407 577T389 567T368 556L316 528L368 500Q421 472 429 464Q436 455 436 440Q436 422 423 409T393 396Q390 396 388 396T384 397T380 398T375 401T367 406T358 413T346 422T329 434L285 465Q284 465 285 438T289 381T291 347Q291 327 278 317T250 306Q234 306 222 315T208 339Q208 350 209 381T212 439L214 465L170 434Q130 405 123 401T106 397Q88 397 76 409T63 440Q63 447 65 453T70 462T80 471T92 479T110 489T131 500L183 528L131 556Q78 584 70 592Q63 601 63 616Q63 634 76 647T106 660Q109 660 111 660T115 659T119 658T124 655T132 650T141 643T153 634T170 622L214 591L212 617Q211 643 210 674T208 717',
    0x2B: '56 237T56 250T70 270H369V420L370 570Q380 583 389 583Q402 583 409 568V270H707Q722 262 722 250T707 230H409V-68Q401 -82 391 -82H389H387Q375 -82 369 -68V230H70Q56 237 56 250',
    0x2C: '90 0V98H188V1L139 -125H89L104 -64Q119 -3 120 -2Q120 0 105 0H90',
    0x2D: '11 186V259H277V186H11',
    0x2E: '90 0V98H188V0H90',
    0x2F: '423 750Q432 750 438 744T444 730Q444 725 271 248T92 -240Q85 -250 75 -250Q68 -250 62 -245T56 -231Q56 -221 230 257T407 740Q411 750 423 750',
    0x3A: '90 346V444H188V346H90ZM90 0V98H188V0H90',
    0x3B: '90 346V444H188V346H90ZM90 0V98H188V1L139 -125H89L104 -64Q119 -3 120 -2Q120 0 105 0H90',
    0x3D: '56 350Q56 363 70 370H707Q722 362 722 350Q722 339 708 331L390 330H72Q56 335 56 350ZM56 150Q56 165 72 170H708Q722 160 722 150Q722 138 707 130H70Q56 137 56 150',
    0x3F: '55 652Q63 658 77 666T132 689T214 704T265 703Q303 698 332 684T376 653T401 615T413 578T416 546Q416 475 360 426Q325 396 303 365T273 303T263 256T261 216V186H183V225Q184 281 194 322Q206 368 232 409T276 469T306 495Q323 517 323 550Q323 596 297 612T223 629Q187 629 157 618Q122 607 91 583L83 576L55 652ZM173 0V98H271V0H173',
    0x40: '422 576Q440 576 456 573T480 566L488 563Q488 565 484 571T472 588T452 607T424 622T387 629Q294 629 220 550T146 347Q146 233 210 155T365 66Q376 65 400 65Q465 68 517 86L532 91H612L598 76Q511 -11 388 -11Q250 -11 153 93T56 347Q56 454 107 538T231 663T378 704Q446 704 492 680T564 608T599 506T611 376Q611 320 607 299Q595 227 546 173T422 118Q343 118 288 185T232 347Q232 444 287 510T422 576ZM520 347Q520 429 487 465T421 501Q385 501 354 461T323 347Q323 270 355 232T422 193Q454 193 487 229T520 347',
    0x5B: '94 -250V750H266V674H181V-174H266V-250H94',
    0x5D: '22 674V750H194V-250H22V-174H107V674H22',
    0x5E: '146 612L212 694H287L353 612Q417 532 420 529Q421 528 418 527Q414 527 383 527H345L250 639Q244 633 199 580L154 527H116Q79 528 79 529Q82 532 146 612',
    0x5F: '0 -114V-38H499V-114H0',
    0x7E: '83 204Q86 254 113 290T186 327Q211 327 251 299T312 270Q337 270 340 315V327H416V316Q413 258 382 226T315 193Q289 193 249 221T187 250Q162 250 159 205V193H83V204',
    0x131: '74 0V444H164V0H74',
    0x237: '-35 -95Q-4 -126 34 -126Q58 -126 76 -116T100 -88Q102 -82 102 181V444H192V180Q191 -45 191 -70T184 -113Q171 -152 140 -178T63 -205Q34 -205 4 -197T-43 -181T-59 -171L-47 -133L-35 -95',
    0x300: '-415 692L-417 694H-324L-262 612Q-249 594 -233 572T-208 539L-200 528L-237 527H-275L-344 608Q-359 625 -378 647T-406 680T-415 692',
    0x301: '-239 612L-177 694H-84L-86 692Q-86 691 -95 681T-123 648T-157 608L-226 527H-264L-301 528L-293 539Q-285 550 -269 572T-239 612',
    0x302: '-354 612L-288 694H-213L-147 612Q-83 532 -80 529Q-79 528 -82 527Q-86 527 -117 527H-155L-250 639Q-256 633 -301 580L-346 527H-384Q-421 528 -421 529Q-418 532 -354 612',
    0x303: '-417 554Q-414 604 -387 640T-314 677Q-289 677 -249 649T-188 620Q-163 620 -160 665V677H-84V666Q-87 608 -118 576T-185 543Q-211 543 -251 571T-313 600Q-338 600 -341 555V543H-417V554',
    0x304: '-431 552V631H-70V552H-431',
    0x306: '-250 508Q-331 508 -379 567T-427 689V694H-351V685Q-348 649 -321 620T-250 591Q-206 591 -180 619T-150 685V694H-74V689Q-74 624 -122 566T-250 508',
    0x307: '-302 576V680H-198V576H-302',
    0x308: '-397 582V680H-299V582H-397ZM-202 582V680H-104V582H-202',
    0x30A: '-319 611Q-319 649 -285 671T-211 694Q-164 694 -132 671T-99 611Q-99 572 -133 550T-209 527T-285 549T-319 611ZM-155 610Q-155 635 -171 643T-215 651Q-263 651 -263 610Q-263 570 -211 570H-209H-207Q-155 570 -155 610',
    0x30B: '-250 693Q-317 544 -323 527H-399L-343 694H-296Q-250 694 -250 693ZM-84 693Q-151 544 -157 527H-233L-177 694H-130Q-84 694 -84 693',
    0x30C: '-421 652Q-422 653 -419 654Q-415 654 -384 654H-346L-301 601Q-287 585 -275 571T-258 551T-250 542L-155 654H-117Q-80 653 -80 652Q-83 649 -147 569L-213 487H-288L-354 569Q-418 649 -421 652',
    0x391: '183 181Q183 179 152 91T118 0H28L154 346L280 693Q281 694 333 694H385L511 349Q636 4 638 2Q638 0 584 0H530L464 183H184L183 181ZM324 606Q319 578 292 492T238 332T210 256Q210 254 324 254T438 255L429 281L419 308Q409 336 395 378T365 465T339 551T324 611V606',
    0x392: '425 363Q438 363 465 353T526 324T585 270T610 192Q610 132 561 78T426 7Q404 2 387 2T240 0H90V694H227Q373 693 396 689Q484 673 533 623T583 517Q583 494 574 473T551 437T520 409T487 388T456 374T433 366L425 363ZM490 516Q490 527 485 539T467 568T423 599T347 621Q340 622 262 623H188V399H261H286Q432 399 478 475Q490 496 490 516ZM514 190Q514 245 462 280T343 322Q336 323 259 323H188V71H274Q365 72 388 77Q445 88 479 121T514 190',
    0x393: '87 0V691H499V611H345L191 612V0H87',
    0x394: '203 348L362 694H470L629 348Q789 2 790 1Q790 0 416 0T42 1Q43 2 203 348ZM630 98Q630 100 584 198T481 422T407 603L405 610L403 600Q388 544 191 122L180 99L405 98H630',
    0x395: '86 0V691H541V611H366L190 612V397H513V321H190V85H372L554 86V0H86',
    0x396: '69 617V694H555V643L373 362Q190 81 190 79H234Q244 79 272 79T344 80T419 81H560V0H55V53L237 334Q420 615 420 617Q413 618 387 618Q380 618 334 618T245 617H69',
    0x397: '86 0V694H190V399H517V694H621V0H517V323H190V0H86',
    0x398: '56 344Q56 430 86 502T164 619T271 690T388 716Q448 716 506 691T613 619T692 501T722 344Q722 188 624 84T389 -21Q252 -21 154 83T56 344ZM624 345Q624 423 597 488T513 596T380 639Q343 639 305 621T232 568T175 475T153 344Q153 216 222 136T388 56Q487 56 555 138T624 345ZM209 299V397H568V299H209',
    0x399: '87 0V694H191V0H87',
    0x39A: '88 0V694H188V519L189 343L525 694H638L375 419L651 0H541L309 351L188 225V0H88',
    0x39B: '294 606Q294 574 252 430T163 144T117 0H72Q28 0 28 1T141 348L254 694H357L469 348Q582 2 582 1T527 0L473 1L469 11Q469 13 427 141T343 411T296 599L294 610V606',
    0x39C: '92 0V694H228L233 680Q236 675 284 547T382 275T436 106Q446 149 497 292T594 558L640 680L645 694H782V0H689V305L688 606Q688 577 500 78L479 23H392L364 96Q364 97 342 156T296 280T246 418T203 544T186 609V588Q185 568 185 517T185 427T185 305V0H92',
    0x39D: '88 0V694H235L252 659Q261 639 364 428T526 84V694H619V0H472L455 35Q453 39 330 294T185 601L181 611V0H88',
    0x39E: '47 600V688H619V600H47ZM111 314V401H555V314H111ZM42 0V88H624V0H42',
    0x39F: '55 345Q55 504 149 609T361 715Q386 715 406 713Q521 696 600 592T680 344Q680 193 590 86T368 -22Q239 -22 147 84T55 345ZM276 59T368 59T518 146T576 360Q576 473 525 545T401 634Q371 637 362 637Q284 637 222 562T159 360T217 147',
    0x3A0: '86 0V691H621V0H517V615H190V0H86',
    0x3A1: '88 0V694H230Q347 693 370 692T410 686Q487 667 535 611T583 485Q583 409 527 348T379 276Q369 274 279 274H192V0H88ZM486 485Q486 523 471 551T432 593T391 612T357 621Q350 622 268 623H189V347H268Q350 348 357 349Q370 351 383 354T416 368T450 391T475 429T486 485',
    0x3A3: '55 0Q56 3 171 167T288 332Q288 334 172 474L55 615V694H666V614H428L190 615L412 347L322 218Q236 97 228 84L447 85H666V0H55',
    0x3A4: '36 608V688H644V608H518L392 609V0H288V609L162 608H36',
    0x3A5: '55 565Q59 625 105 670T219 716H222Q310 716 353 627Q376 583 386 524L389 510L393 532Q397 555 407 584T433 644T482 695T557 716Q621 716 669 673T722 565V555H629V563Q627 592 607 615T557 638Q530 638 511 629T479 598T459 553T447 488T442 411T441 319V202V0H337V202Q337 453 331 497Q313 634 226 638Q185 638 167 612T148 563V555H55V565',
    0x3A6: '666 347Q666 326 661 302T638 247T594 190T520 140T413 107H410V0H312V54Q312 107 311 107Q286 107 229 128T125 192Q55 260 55 347Q55 396 77 438T131 507T200 552T265 579T311 587Q312 587 312 641V694H410V587H413Q476 576 524 552T598 502T640 444T661 390T666 347ZM310 510Q305 510 291 507T252 492T208 464T172 416T157 347T171 279T204 233T247 205T286 190T310 184H312V347Q312 510 310 510ZM564 347Q564 385 549 416T514 463T470 491T433 505T414 509L410 510V184Q413 184 426 187T464 200T510 227T548 275T564 347',
    0x3A7: '14 0Q16 5 144 184T275 367L153 528Q121 571 88 615T42 674T28 694H150L228 584Q315 463 316 461L326 448L497 694H610L609 692Q606 689 492 528Q440 454 409 410T378 366Q378 365 515 182L652 0H531L326 292Q326 293 299 254T226 146L128 0H14',
    0x3A8: '340 187V694H438V187Q481 206 495 219Q518 239 533 267T553 332T560 386T562 435Q562 576 593 608Q608 621 637 621H670H722V545H719Q718 545 715 545T710 544Q679 536 666 487Q664 474 662 429T654 344T633 259T580 175T486 119Q450 109 438 108V0H340V108L326 110Q122 149 117 415Q116 460 111 487Q98 536 67 544Q65 544 62 544T58 545H55V621H107Q160 621 163 620Q191 613 202 573Q213 536 213 473T220 351T256 249Q262 239 270 230T285 216T301 205T316 197T329 192T340 187',
    0x3A9: '55 462Q55 561 141 638T359 716Q492 716 579 640T666 462Q666 407 642 347T579 222T529 126Q515 91 515 86Q517 85 528 85Q530 85 552 85T596 86H677V0H425V14Q429 79 465 168L492 228Q494 232 504 254T516 283T527 310T539 340T548 368T556 399T560 428T562 460Q562 531 510 585T361 639Q263 639 211 585T159 460Q159 422 170 378T192 309T229 228L256 168Q292 79 296 14V0H44V86H125Q146 86 168 86T193 85L206 86Q206 103 183 148T131 241T79 352T55 462',
    0x2013: '0 236V312H499V236H0',
    0x2014: '0 236V312H999V236H0',
    0x2015: '0 236V312H999V236H0',
    0x2017: '0 -114V-38H499V-114H0',
    0x2018: '90 568L140 694H189L174 633Q159 572 158 571Q158 569 173 569H188V471H90V568',
    0x2019: '90 596V694H188V597L139 471H89L104 532Q119 593 120 594Q120 596 105 596H90',
    0x201C: '174 568L224 694H273L258 633Q243 572 242 571Q242 569 257 569H272V471H174V568ZM368 568L418 694H467L452 633Q437 572 436 571Q436 569 451 569H466V471H368V568',
    0x201D: '33 596V694H131V597L82 471H32L47 532Q62 593 63 594Q63 596 48 596H33ZM227 596V694H325V597L276 471H226L241 532Q256 593 257 594Q257 596 242 596H227',
    0x2044: '423 750Q432 750 438 744T444 730Q444 725 271 248T92 -240Q85 -250 75 -250Q68 -250 62 -245T56 -231Q56 -221 230 257T407 740Q411 750 423 750',
    0x2206: '203 348L362 694H470L629 348Q789 2 790 1Q790 0 416 0T42 1Q43 2 203 348ZM630 98Q630 100 584 198T481 422T407 603L405 610L403 600Q388 544 191 122L180 99L405 98H630',
}, {});
