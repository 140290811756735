"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    RBarr: '\u2910',
    REG: '\u00AE',
    Racute: '\u0154',
    Rang: '\u27EB',
    Rarrtl: '\u2916',
    Rcaron: '\u0158',
    Rcedil: '\u0156',
    Rcy: '\u0420',
    ReverseElement: '\u220B',
    ReverseUpEquilibrium: '\u296F',
    Rho: '\u03A1',
    RightArrowBar: '\u21E5',
    RightDoubleBracket: '\u27E7',
    RightDownTeeVector: '\u295D',
    RightDownVectorBar: '\u2955',
    RightTeeVector: '\u295B',
    RightTriangleBar: '\u29D0',
    RightUpDownVector: '\u294F',
    RightUpTeeVector: '\u295C',
    RightUpVectorBar: '\u2954',
    RightVectorBar: '\u2953',
    RoundImplies: '\u2970',
    RuleDelayed: '\u29F4',
    rAarr: '\u21DB',
    rArr: '\u21D2',
    rAtail: '\u291C',
    rBarr: '\u290F',
    rHar: '\u2964',
    race: '\u223D\u0331',
    racute: '\u0155',
    radic: '\u221A',
    raemptyv: '\u29B3',
    rang: '\u27E9',
    rangd: '\u2992',
    range: '\u29A5',
    rangle: '\u27E9',
    raquo: '\u00BB',
    rarr: '\u2192',
    rarrap: '\u2975',
    rarrb: '\u21E5',
    rarrbfs: '\u2920',
    rarrc: '\u2933',
    rarrfs: '\u291E',
    rarrhk: '\u21AA',
    rarrlp: '\u21AC',
    rarrpl: '\u2945',
    rarrsim: '\u2974',
    rarrw: '\u219D',
    ratail: '\u291A',
    ratio: '\u2236',
    rationals: '\u211A',
    rbarr: '\u290D',
    rbbrk: '\u2773',
    rbrke: '\u298C',
    rbrksld: '\u298E',
    rbrkslu: '\u2990',
    rcaron: '\u0159',
    rcedil: '\u0157',
    rceil: '\u2309',
    rcub: '\u007D',
    rcy: '\u0440',
    rdca: '\u2937',
    rdldhar: '\u2969',
    rdquo: '\u201D',
    rdquor: '\u201D',
    rdsh: '\u21B3',
    real: '\u211C',
    realine: '\u211B',
    realpart: '\u211C',
    reals: '\u211D',
    rect: '\u25AD',
    reg: '\u00AE',
    rfisht: '\u297D',
    rfloor: '\u230B',
    rhard: '\u21C1',
    rharu: '\u21C0',
    rharul: '\u296C',
    rightarrow: '\u2192',
    rightarrowtail: '\u21A3',
    rightharpoondown: '\u21C1',
    rightharpoonup: '\u21C0',
    rightleftarrows: '\u21C4',
    rightleftharpoons: '\u21CC',
    rightsquigarrow: '\u219D',
    risingdotseq: '\u2253',
    rlarr: '\u21C4',
    rlhar: '\u21CC',
    rlm: '\u200F',
    rmoustache: '\u23B1',
    rnmid: '\u2AEE',
    roang: '\u27ED',
    roarr: '\u21FE',
    robrk: '\u27E7',
    ropar: '\u2986',
    roplus: '\u2A2E',
    rotimes: '\u2A35',
    rpar: '\u0029',
    rpargt: '\u2994',
    rppolint: '\u2A12',
    rrarr: '\u21C9',
    rsaquo: '\u203A',
    rsh: '\u21B1',
    rsqb: '\u005D',
    rsquo: '\u2019',
    rsquor: '\u2019',
    rthree: '\u22CC',
    rtrie: '\u22B5',
    rtrif: '\u25B8',
    rtriltri: '\u29CE',
    ruluhar: '\u2968',
    rx: '\u211E'
}, 'r');
