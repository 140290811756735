"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    CHcy: '\u0427',
    COPY: '\u00A9',
    Cacute: '\u0106',
    CapitalDifferentialD: '\u2145',
    Cayleys: '\u212D',
    Ccaron: '\u010C',
    Ccedil: '\u00C7',
    Ccirc: '\u0108',
    Cconint: '\u2230',
    Cdot: '\u010A',
    Cedilla: '\u00B8',
    Chi: '\u03A7',
    ClockwiseContourIntegral: '\u2232',
    CloseCurlyDoubleQuote: '\u201D',
    CloseCurlyQuote: '\u2019',
    Colon: '\u2237',
    Colone: '\u2A74',
    Conint: '\u222F',
    CounterClockwiseContourIntegral: '\u2233',
    cacute: '\u0107',
    capand: '\u2A44',
    capbrcup: '\u2A49',
    capcap: '\u2A4B',
    capcup: '\u2A47',
    capdot: '\u2A40',
    caps: '\u2229\uFE00',
    caret: '\u2041',
    caron: '\u02C7',
    ccaps: '\u2A4D',
    ccaron: '\u010D',
    ccedil: '\u00E7',
    ccirc: '\u0109',
    ccups: '\u2A4C',
    ccupssm: '\u2A50',
    cdot: '\u010B',
    cedil: '\u00B8',
    cemptyv: '\u29B2',
    cent: '\u00A2',
    centerdot: '\u00B7',
    chcy: '\u0447',
    checkmark: '\u2713',
    cir: '\u25CB',
    cirE: '\u29C3',
    cire: '\u2257',
    cirfnint: '\u2A10',
    cirmid: '\u2AEF',
    cirscir: '\u29C2',
    clubsuit: '\u2663',
    colone: '\u2254',
    coloneq: '\u2254',
    comma: '\u002C',
    commat: '\u0040',
    compfn: '\u2218',
    complement: '\u2201',
    complexes: '\u2102',
    cong: '\u2245',
    congdot: '\u2A6D',
    conint: '\u222E',
    coprod: '\u2210',
    copy: '\u00A9',
    copysr: '\u2117',
    crarr: '\u21B5',
    cross: '\u2717',
    csub: '\u2ACF',
    csube: '\u2AD1',
    csup: '\u2AD0',
    csupe: '\u2AD2',
    cudarrl: '\u2938',
    cudarrr: '\u2935',
    cularrp: '\u293D',
    cupbrcap: '\u2A48',
    cupcap: '\u2A46',
    cupcup: '\u2A4A',
    cupdot: '\u228D',
    cupor: '\u2A45',
    cups: '\u222A\uFE00',
    curarrm: '\u293C',
    curlyeqprec: '\u22DE',
    curlyeqsucc: '\u22DF',
    curren: '\u00A4',
    curvearrowleft: '\u21B6',
    curvearrowright: '\u21B7',
    cuvee: '\u22CE',
    cuwed: '\u22CF',
    cwconint: '\u2232',
    cwint: '\u2231',
    cylcty: '\u232D'
}, 'c');
