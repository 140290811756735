"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normal = void 0;
exports.normal = {
    0x20: [0, 0, .25],
    0x21: [.716, 0, .278],
    0x22: [.694, -0.379, .5],
    0x23: [.694, .194, .833],
    0x24: [.75, .056, .5],
    0x25: [.75, .056, .833],
    0x26: [.716, .022, .778],
    0x27: [.694, -0.379, .278],
    0x28: [.75, .25, .389],
    0x29: [.75, .25, .389],
    0x2A: [.75, -0.32, .5],
    0x2B: [.583, .082, .778],
    0x2C: [.121, .194, .278],
    0x2D: [.252, -0.179, .333],
    0x2E: [.12, 0, .278],
    0x2F: [.75, .25, .5],
    0x30: [.666, .022, .5],
    0x31: [.666, 0, .5],
    0x32: [.666, 0, .5],
    0x33: [.665, .022, .5],
    0x34: [.677, 0, .5],
    0x35: [.666, .022, .5],
    0x36: [.666, .022, .5],
    0x37: [.676, .022, .5],
    0x38: [.666, .022, .5],
    0x39: [.666, .022, .5],
    0x3A: [.43, 0, .278],
    0x3B: [.43, .194, .278],
    0x3C: [.54, .04, .778],
    0x3D: [.583, .082, .778],
    0x3E: [.54, .04, .778],
    0x3F: [.705, 0, .472],
    0x40: [.705, .011, .778],
    0x41: [.716, 0, .75],
    0x42: [.683, 0, .708],
    0x43: [.705, .021, .722],
    0x44: [.683, 0, .764],
    0x45: [.68, 0, .681],
    0x46: [.68, 0, .653],
    0x47: [.705, .022, .785],
    0x48: [.683, 0, .75],
    0x49: [.683, 0, .361],
    0x4A: [.683, .022, .514],
    0x4B: [.683, 0, .778],
    0x4C: [.683, 0, .625],
    0x4D: [.683, 0, .917],
    0x4E: [.683, 0, .75],
    0x4F: [.705, .022, .778],
    0x50: [.683, 0, .681],
    0x51: [.705, .193, .778],
    0x52: [.683, .022, .736],
    0x53: [.705, .022, .556],
    0x54: [.677, 0, .722],
    0x55: [.683, .022, .75],
    0x56: [.683, .022, .75],
    0x57: [.683, .022, 1.028],
    0x58: [.683, 0, .75],
    0x59: [.683, 0, .75],
    0x5A: [.683, 0, .611],
    0x5B: [.75, .25, .278],
    0x5C: [.75, .25, .5],
    0x5D: [.75, .25, .278],
    0x5E: [.694, -0.531, .5],
    0x5F: [-0.025, .062, .5],
    0x60: [.699, -0.505, .5],
    0x61: [.448, .011, .5],
    0x62: [.694, .011, .556],
    0x63: [.448, .011, .444],
    0x64: [.694, .011, .556],
    0x65: [.448, .011, .444],
    0x66: [.705, 0, .306, { ic: .066 }],
    0x67: [.453, .206, .5],
    0x68: [.694, 0, .556],
    0x69: [.669, 0, .278],
    0x6A: [.669, .205, .306],
    0x6B: [.694, 0, .528],
    0x6C: [.694, 0, .278],
    0x6D: [.442, 0, .833],
    0x6E: [.442, 0, .556],
    0x6F: [.448, .01, .5],
    0x70: [.442, .194, .556],
    0x71: [.442, .194, .528],
    0x72: [.442, 0, .392],
    0x73: [.448, .011, .394],
    0x74: [.615, .01, .389],
    0x75: [.442, .011, .556],
    0x76: [.431, .011, .528],
    0x77: [.431, .011, .722],
    0x78: [.431, 0, .528],
    0x79: [.431, .204, .528],
    0x7A: [.431, 0, .444],
    0x7B: [.75, .25, .5],
    0x7C: [.75, .249, .278],
    0x7D: [.75, .25, .5],
    0x7E: [.318, -0.215, .5],
    0xA0: [0, 0, .25],
    0xA3: [.714, .011, .769],
    0xA5: [.683, 0, .75],
    0xA8: [.669, -0.554, .5],
    0xAC: [.356, -0.089, .667],
    0xAE: [.709, .175, .947],
    0xAF: [.59, -0.544, .5],
    0xB0: [.715, -0.542, .5],
    0xB1: [.666, 0, .778],
    0xB4: [.699, -0.505, .5],
    0xB7: [.31, -0.19, .278],
    0xD7: [.491, -0.009, .778],
    0xF0: [.749, .021, .556],
    0xF7: [.537, .036, .778],
    0x131: [.442, 0, .278, { sk: .0278 }],
    0x237: [.442, .205, .306, { sk: .0833 }],
    0x2B9: [.56, -0.043, .275],
    0x2C6: [.694, -0.531, .5],
    0x2C7: [.644, -0.513, .5],
    0x2C9: [.59, -0.544, .5],
    0x2CA: [.699, -0.505, .5],
    0x2CB: [.699, -0.505, .5],
    0x2D8: [.694, -0.515, .5],
    0x2D9: [.669, -0.549, .5],
    0x2DA: [.715, -0.542, .5],
    0x2DC: [.668, -0.565, .5],
    0x300: [.699, -0.505, 0],
    0x301: [.699, -0.505, 0],
    0x302: [.694, -0.531, 0],
    0x303: [.668, -0.565, 0],
    0x304: [.59, -0.544, 0],
    0x306: [.694, -0.515, 0],
    0x307: [.669, -0.549, 0],
    0x308: [.669, -0.554, 0],
    0x30A: [.715, -0.542, 0],
    0x30B: [.701, -0.51, 0],
    0x30C: [.644, -0.513, 0],
    0x338: [.716, .215, 0],
    0x391: [.716, 0, .75],
    0x392: [.683, 0, .708],
    0x393: [.68, 0, .625],
    0x394: [.716, 0, .833],
    0x395: [.68, 0, .681],
    0x396: [.683, 0, .611],
    0x397: [.683, 0, .75],
    0x398: [.705, .022, .778],
    0x399: [.683, 0, .361],
    0x39A: [.683, 0, .778],
    0x39B: [.716, 0, .694],
    0x39C: [.683, 0, .917],
    0x39D: [.683, 0, .75],
    0x39E: [.677, 0, .667],
    0x39F: [.705, .022, .778],
    0x3A0: [.68, 0, .75],
    0x3A1: [.683, 0, .681],
    0x3A3: [.683, 0, .722],
    0x3A4: [.677, 0, .722],
    0x3A5: [.705, 0, .778],
    0x3A6: [.683, 0, .722],
    0x3A7: [.683, 0, .75],
    0x3A8: [.683, 0, .778],
    0x3A9: [.704, 0, .722],
    0x2000: [0, 0, .5],
    0x2001: [0, 0, 1],
    0x2002: [0, 0, .5],
    0x2003: [0, 0, 1],
    0x2004: [0, 0, .333],
    0x2005: [0, 0, .25],
    0x2006: [0, 0, .167],
    0x2009: [0, 0, .167],
    0x200A: [0, 0, .1],
    0x200B: [0, 0, 0],
    0x200C: [0, 0, 0],
    0x2013: [.285, -0.248, .5],
    0x2014: [.285, -0.248, 1],
    0x2015: [.285, -0.248, 1],
    0x2016: [.75, .25, .5],
    0x2017: [-0.025, .062, .5],
    0x2018: [.694, -0.379, .278],
    0x2019: [.694, -0.379, .278],
    0x201C: [.694, -0.379, .5],
    0x201D: [.694, -0.379, .5],
    0x2020: [.705, .216, .444],
    0x2021: [.705, .205, .444],
    0x2022: [.444, -0.055, .5],
    0x2026: [.12, 0, 1.172],
    0x2032: [.56, -0.043, .275],
    0x2033: [.56, 0, .55],
    0x2034: [.56, 0, .825],
    0x2035: [.56, -0.043, .275],
    0x2036: [.56, 0, .55],
    0x2037: [.56, 0, .825],
    0x203E: [.59, -0.544, .5],
    0x2044: [.75, .25, .5],
    0x2057: [.56, 0, 1.1],
    0x2060: [0, 0, 0],
    0x2061: [0, 0, 0],
    0x2062: [0, 0, 0],
    0x2063: [0, 0, 0],
    0x2064: [0, 0, 0],
    0x20D7: [.714, -0.516, .5],
    0x2102: [.702, .019, .722],
    0x210B: [.717, .036, .969, { ic: .272, sk: .333 }],
    0x210C: [.666, .133, .72],
    0x210D: [.683, 0, .778],
    0x210E: [.694, .011, .576, { sk: -0.0278 }],
    0x210F: [.695, .013, .54, { ic: .022 }],
    0x2110: [.717, .314, 1.052, { ic: .081, sk: .417 }],
    0x2111: [.686, .026, .554],
    0x2112: [.717, .017, .874, { ic: .161, sk: .306 }],
    0x2113: [.705, .02, .417, { sk: .111 }],
    0x2115: [.683, .02, .722],
    0x2118: [.453, .216, .636, { sk: .111 }],
    0x2119: [.683, 0, .611],
    0x211A: [.701, .181, .778],
    0x211B: [.717, .017, .85, { ic: .037, sk: .194 }],
    0x211C: [.686, .026, .828],
    0x211D: [.683, 0, .722],
    0x2124: [.683, 0, .667],
    0x2126: [.704, 0, .722],
    0x2127: [.684, .022, .722],
    0x2128: [.729, .139, .602],
    0x212C: [.708, .028, .908, { ic: .02, sk: .194 }],
    0x212D: [.685, .024, .613],
    0x2130: [.707, .008, .562, { ic: .156, sk: .139 }],
    0x2131: [.735, .036, .895, { ic: .095, sk: .222 }],
    0x2132: [.695, 0, .556],
    0x2133: [.721, .05, 1.08, { ic: .136, sk: .444 }],
    0x2135: [.694, 0, .611],
    0x2136: [.763, .021, .667, { ic: .02 }],
    0x2137: [.764, .043, .444],
    0x2138: [.764, .043, .667],
    0x2141: [.705, .023, .639],
    0x2190: [.511, .011, 1],
    0x2191: [.694, .193, .5],
    0x2192: [.511, .011, 1],
    0x2193: [.694, .194, .5],
    0x2194: [.511, .011, 1],
    0x2195: [.772, .272, .5],
    0x2196: [.72, .195, 1],
    0x2197: [.72, .195, 1],
    0x2198: [.695, .22, 1],
    0x2199: [.695, .22, 1],
    0x219A: [.437, -0.06, 1],
    0x219B: [.437, -0.06, 1],
    0x219E: [.417, -0.083, 1],
    0x21A0: [.417, -0.083, 1],
    0x21A2: [.417, -0.083, 1.111],
    0x21A3: [.417, -0.083, 1.111],
    0x21A6: [.511, .011, 1],
    0x21A9: [.511, .011, 1.126],
    0x21AA: [.511, .011, 1.126],
    0x21AB: [.575, .041, 1],
    0x21AC: [.575, .041, 1],
    0x21AD: [.417, -0.083, 1.389],
    0x21AE: [.437, -0.06, 1],
    0x21B0: [.722, 0, .5],
    0x21B1: [.722, 0, .5],
    0x21B6: [.461, 0, 1],
    0x21B7: [.46, 0, 1],
    0x21BA: [.65, .083, .778],
    0x21BB: [.65, .083, .778],
    0x21BC: [.511, -0.23, 1],
    0x21BD: [.27, .011, 1],
    0x21BE: [.694, .194, .417],
    0x21BF: [.694, .194, .417],
    0x21C0: [.511, -0.23, 1],
    0x21C1: [.27, .011, 1],
    0x21C2: [.694, .194, .417],
    0x21C3: [.694, .194, .417],
    0x21C4: [.667, 0, 1],
    0x21C6: [.667, 0, 1],
    0x21C7: [.583, .083, 1],
    0x21C8: [.694, .193, .833],
    0x21C9: [.583, .083, 1],
    0x21CA: [.694, .194, .833],
    0x21CB: [.514, .014, 1],
    0x21CC: [.671, .011, 1],
    0x21CD: [.534, .035, 1],
    0x21CE: [.534, .037, 1],
    0x21CF: [.534, .035, 1],
    0x21D0: [.525, .024, 1],
    0x21D1: [.694, .194, .611],
    0x21D2: [.525, .024, 1],
    0x21D3: [.694, .194, .611],
    0x21D4: [.526, .025, 1],
    0x21D5: [.772, .272, .611],
    0x21DA: [.611, .111, 1],
    0x21DB: [.611, .111, 1],
    0x21DD: [.417, -0.083, 1],
    0x21E0: [.437, -0.064, 1.334],
    0x21E2: [.437, -0.064, 1.334],
    0x2200: [.694, .022, .556],
    0x2201: [.846, .021, .5],
    0x2202: [.715, .022, .531, { ic: .035, sk: .0833 }],
    0x2203: [.694, 0, .556],
    0x2204: [.716, .215, .556],
    0x2205: [.772, .078, .5],
    0x2206: [.716, 0, .833],
    0x2207: [.683, .033, .833],
    0x2208: [.54, .04, .667],
    0x2209: [.716, .215, .667],
    0x220B: [.54, .04, .667],
    0x220C: [.716, .215, .667],
    0x220D: [.44, 0, .429, { ic: .027 }],
    0x220F: [.75, .25, .944],
    0x2210: [.75, .25, .944],
    0x2211: [.75, .25, 1.056],
    0x2212: [.583, .082, .778],
    0x2213: [.5, .166, .778],
    0x2214: [.766, .093, .778],
    0x2215: [.75, .25, .5],
    0x2216: [.75, .25, .5],
    0x2217: [.465, -0.035, .5],
    0x2218: [.444, -0.055, .5],
    0x2219: [.444, -0.055, .5],
    0x221A: [.8, .2, .833, { ic: .02 }],
    0x221D: [.442, .011, .778],
    0x221E: [.442, .011, 1],
    0x2220: [.694, 0, .722],
    0x2221: [.714, .02, .722],
    0x2222: [.551, .051, .722],
    0x2223: [.75, .249, .278],
    0x2224: [.75, .252, .278, { ic: .019 }],
    0x2225: [.75, .25, .5],
    0x2226: [.75, .25, .5, { ic: .018 }],
    0x2227: [.598, .022, .667],
    0x2228: [.598, .022, .667],
    0x2229: [.598, .022, .667],
    0x222A: [.598, .022, .667],
    0x222B: [.716, .216, .417, { ic: .055 }],
    0x222C: [.805, .306, .819, { ic: .138 }],
    0x222D: [.805, .306, 1.166, { ic: .138 }],
    0x222E: [.805, .306, .472, { ic: .138 }],
    0x2234: [.471, .082, .667],
    0x2235: [.471, .082, .667],
    0x223C: [.367, -0.133, .778],
    0x223D: [.367, -0.133, .778],
    0x2240: [.583, .083, .278],
    0x2241: [.467, -0.032, .778],
    0x2242: [.463, -0.034, .778],
    0x2243: [.464, -0.036, .778],
    0x2244: [.716, .215, .778],
    0x2245: [.589, -0.022, .778],
    0x2247: [.652, .155, .778],
    0x2248: [.483, -0.055, .778],
    0x2249: [.716, .215, .778],
    0x224A: [.579, .039, .778],
    0x224D: [.484, -0.016, .778],
    0x224E: [.492, -0.008, .778],
    0x224F: [.492, -0.133, .778],
    0x2250: [.67, -0.133, .778],
    0x2251: [.609, .108, .778],
    0x2252: [.601, .101, .778],
    0x2253: [.601, .102, .778],
    0x2256: [.367, -0.133, .778],
    0x2257: [.721, -0.133, .778],
    0x225C: [.859, -0.133, .778],
    0x2260: [.716, .215, .778],
    0x2261: [.464, -0.036, .778],
    0x2262: [.716, .215, .778],
    0x2264: [.636, .138, .778],
    0x2265: [.636, .138, .778],
    0x2266: [.753, .175, .778],
    0x2267: [.753, .175, .778],
    0x2268: [.752, .286, .778],
    0x2269: [.752, .286, .778],
    0x226A: [.568, .067, 1],
    0x226B: [.567, .067, 1],
    0x226C: [.75, .25, .5],
    0x226D: [.716, .215, .778],
    0x226E: [.708, .209, .778],
    0x226F: [.708, .209, .778],
    0x2270: [.801, .303, .778],
    0x2271: [.801, .303, .778],
    0x2272: [.732, .228, .778],
    0x2273: [.732, .228, .778],
    0x2274: [.732, .228, .778],
    0x2275: [.732, .228, .778],
    0x2276: [.681, .253, .778],
    0x2277: [.681, .253, .778],
    0x2278: [.716, .253, .778],
    0x2279: [.716, .253, .778],
    0x227A: [.539, .041, .778],
    0x227B: [.539, .041, .778],
    0x227C: [.58, .153, .778],
    0x227D: [.58, .154, .778],
    0x227E: [.732, .228, .778],
    0x227F: [.732, .228, .778],
    0x2280: [.705, .208, .778],
    0x2281: [.705, .208, .778],
    0x2282: [.54, .04, .778],
    0x2283: [.54, .04, .778],
    0x2284: [.716, .215, .778],
    0x2285: [.716, .215, .778],
    0x2286: [.636, .138, .778],
    0x2287: [.636, .138, .778],
    0x2288: [.801, .303, .778],
    0x2289: [.801, .303, .778],
    0x228A: [.635, .241, .778],
    0x228B: [.635, .241, .778],
    0x228E: [.598, .022, .667],
    0x228F: [.539, .041, .778],
    0x2290: [.539, .041, .778],
    0x2291: [.636, .138, .778],
    0x2292: [.636, .138, .778],
    0x2293: [.598, 0, .667],
    0x2294: [.598, 0, .667],
    0x2295: [.583, .083, .778],
    0x2296: [.583, .083, .778],
    0x2297: [.583, .083, .778],
    0x2298: [.583, .083, .778],
    0x2299: [.583, .083, .778],
    0x229A: [.582, .082, .778],
    0x229B: [.582, .082, .778],
    0x229D: [.582, .082, .778],
    0x229E: [.689, 0, .778],
    0x229F: [.689, 0, .778],
    0x22A0: [.689, 0, .778],
    0x22A1: [.689, 0, .778],
    0x22A2: [.694, 0, .611],
    0x22A3: [.694, 0, .611],
    0x22A4: [.668, 0, .778],
    0x22A5: [.668, 0, .778],
    0x22A8: [.75, .249, .867],
    0x22A9: [.694, 0, .722],
    0x22AA: [.694, 0, .889],
    0x22AC: [.695, 0, .611],
    0x22AD: [.695, 0, .611],
    0x22AE: [.695, 0, .722],
    0x22AF: [.695, 0, .722],
    0x22B2: [.539, .041, .778],
    0x22B3: [.539, .041, .778],
    0x22B4: [.636, .138, .778],
    0x22B5: [.636, .138, .778],
    0x22B8: [.408, -0.092, 1.111],
    0x22BA: [.431, .212, .556],
    0x22BB: [.716, 0, .611],
    0x22BC: [.716, 0, .611],
    0x22C0: [.75, .249, .833],
    0x22C1: [.75, .249, .833],
    0x22C2: [.75, .249, .833],
    0x22C3: [.75, .249, .833],
    0x22C4: [.488, -0.012, .5],
    0x22C5: [.31, -0.19, .278],
    0x22C6: [.486, -0.016, .5],
    0x22C7: [.545, .044, .778],
    0x22C8: [.505, .005, .9],
    0x22C9: [.492, -0.008, .778],
    0x22CA: [.492, -0.008, .778],
    0x22CB: [.694, .022, .778],
    0x22CC: [.694, .022, .778],
    0x22CD: [.464, -0.036, .778],
    0x22CE: [.578, .021, .76],
    0x22CF: [.578, .022, .76],
    0x22D0: [.54, .04, .778],
    0x22D1: [.54, .04, .778],
    0x22D2: [.598, .022, .667],
    0x22D3: [.598, .022, .667],
    0x22D4: [.736, .022, .667],
    0x22D6: [.541, .041, .778],
    0x22D7: [.541, .041, .778],
    0x22D8: [.568, .067, 1.333],
    0x22D9: [.568, .067, 1.333],
    0x22DA: [.886, .386, .778],
    0x22DB: [.886, .386, .778],
    0x22DE: [.734, 0, .778],
    0x22DF: [.734, 0, .778],
    0x22E0: [.801, .303, .778],
    0x22E1: [.801, .303, .778],
    0x22E2: [.716, .215, .778],
    0x22E3: [.716, .215, .778],
    0x22E6: [.73, .359, .778],
    0x22E7: [.73, .359, .778],
    0x22E8: [.73, .359, .778],
    0x22E9: [.73, .359, .778],
    0x22EA: [.706, .208, .778],
    0x22EB: [.706, .208, .778],
    0x22EC: [.802, .303, .778],
    0x22ED: [.801, .303, .778],
    0x22EE: [1.3, .03, .278],
    0x22EF: [.31, -0.19, 1.172],
    0x22F1: [1.52, -0.1, 1.282],
    0x2305: [.716, 0, .611],
    0x2306: [.813, .097, .611],
    0x2308: [.75, .25, .444],
    0x2309: [.75, .25, .444],
    0x230A: [.75, .25, .444],
    0x230B: [.75, .25, .444],
    0x231C: [.694, -0.306, .5],
    0x231D: [.694, -0.306, .5],
    0x231E: [.366, .022, .5],
    0x231F: [.366, .022, .5],
    0x2322: [.388, -0.122, 1],
    0x2323: [.378, -0.134, 1],
    0x2329: [.75, .25, .389],
    0x232A: [.75, .25, .389],
    0x23B0: [.744, .244, .412],
    0x23B1: [.744, .244, .412],
    0x23D0: [.602, 0, .667],
    0x24C8: [.709, .175, .902],
    0x250C: [.694, -0.306, .5],
    0x2510: [.694, -0.306, .5],
    0x2514: [.366, .022, .5],
    0x2518: [.366, .022, .5],
    0x2571: [.694, .195, .889],
    0x2572: [.694, .195, .889],
    0x25A0: [.689, 0, .778],
    0x25A1: [.689, 0, .778],
    0x25AA: [.689, 0, .778],
    0x25B2: [.575, .02, .722],
    0x25B3: [.716, 0, .889],
    0x25B4: [.575, .02, .722],
    0x25B5: [.716, 0, .889],
    0x25B6: [.539, .041, .778],
    0x25B8: [.539, .041, .778],
    0x25B9: [.505, .005, .5],
    0x25BC: [.576, .019, .722],
    0x25BD: [.5, .215, .889],
    0x25BE: [.576, .019, .722],
    0x25BF: [.5, .215, .889],
    0x25C0: [.539, .041, .778],
    0x25C2: [.539, .041, .778],
    0x25C3: [.505, .005, .5],
    0x25CA: [.716, .132, .667],
    0x25EF: [.715, .215, 1],
    0x25FB: [.689, 0, .778],
    0x25FC: [.689, 0, .778],
    0x2605: [.694, .111, .944],
    0x2660: [.727, .13, .778],
    0x2661: [.716, .033, .778],
    0x2662: [.727, .162, .778],
    0x2663: [.726, .13, .778],
    0x266D: [.75, .022, .389],
    0x266E: [.734, .223, .389],
    0x266F: [.723, .223, .389],
    0x2713: [.706, .034, .833],
    0x2720: [.716, .022, .833],
    0x2758: [.75, .249, .278],
    0x27E8: [.75, .25, .389],
    0x27E9: [.75, .25, .389],
    0x27EE: [.744, .244, .412],
    0x27EF: [.744, .244, .412],
    0x27F5: [.511, .011, 1.609],
    0x27F6: [.511, .011, 1.638],
    0x27F7: [.511, .011, 1.859],
    0x27F8: [.525, .024, 1.609],
    0x27F9: [.525, .024, 1.638],
    0x27FA: [.525, .024, 1.858],
    0x27FC: [.511, .011, 1.638],
    0x29EB: [.716, .132, .667],
    0x29F8: [.716, .215, .778],
    0x2A00: [.75, .25, 1.111],
    0x2A01: [.75, .25, 1.111],
    0x2A02: [.75, .25, 1.111],
    0x2A04: [.75, .249, .833],
    0x2A06: [.75, .249, .833],
    0x2A0C: [.805, .306, 1.638, { ic: .138 }],
    0x2A2F: [.491, -0.009, .778],
    0x2A3F: [.683, 0, .75],
    0x2A5E: [.813, .097, .611],
    0x2A7D: [.636, .138, .778],
    0x2A7E: [.636, .138, .778],
    0x2A85: [.762, .29, .778],
    0x2A86: [.762, .29, .778],
    0x2A87: [.635, .241, .778],
    0x2A88: [.635, .241, .778],
    0x2A89: [.761, .387, .778],
    0x2A8A: [.761, .387, .778],
    0x2A8B: [1.003, .463, .778],
    0x2A8C: [1.003, .463, .778],
    0x2A95: [.636, .138, .778],
    0x2A96: [.636, .138, .778],
    0x2AAF: [.636, .138, .778],
    0x2AB0: [.636, .138, .778],
    0x2AB5: [.752, .286, .778],
    0x2AB6: [.752, .286, .778],
    0x2AB7: [.761, .294, .778],
    0x2AB8: [.761, .294, .778],
    0x2AB9: [.761, .337, .778],
    0x2ABA: [.761, .337, .778],
    0x2AC5: [.753, .215, .778],
    0x2AC6: [.753, .215, .778],
    0x2ACB: [.783, .385, .778],
    0x2ACC: [.783, .385, .778],
    0x3008: [.75, .25, .389],
    0x3009: [.75, .25, .389],
    0xE006: [.43, .023, .222, { ic: .018 }],
    0xE007: [.431, .024, .389, { ic: .018 }],
    0xE008: [.605, .085, .778],
    0xE009: [.434, .006, .667, { ic: .067 }],
    0xE00C: [.752, .284, .778],
    0xE00D: [.752, .284, .778],
    0xE00E: [.919, .421, .778],
    0xE00F: [.801, .303, .778],
    0xE010: [.801, .303, .778],
    0xE011: [.919, .421, .778],
    0xE016: [.828, .33, .778],
    0xE017: [.752, .332, .778],
    0xE018: [.828, .33, .778],
    0xE019: [.752, .333, .778],
    0xE01A: [.634, .255, .778],
    0xE01B: [.634, .254, .778],
    0x1D400: [.698, 0, .869],
    0x1D401: [.686, 0, .818],
    0x1D402: [.697, .011, .831],
    0x1D403: [.686, 0, .882],
    0x1D404: [.68, 0, .756],
    0x1D405: [.68, 0, .724],
    0x1D406: [.697, .01, .904],
    0x1D407: [.686, 0, .9],
    0x1D408: [.686, 0, .436],
    0x1D409: [.686, .011, .594],
    0x1D40A: [.686, 0, .901],
    0x1D40B: [.686, 0, .692],
    0x1D40C: [.686, 0, 1.092],
    0x1D40D: [.686, 0, .9],
    0x1D40E: [.696, .01, .864],
    0x1D40F: [.686, 0, .786],
    0x1D410: [.696, .193, .864],
    0x1D411: [.686, .011, .862],
    0x1D412: [.697, .011, .639],
    0x1D413: [.675, 0, .8],
    0x1D414: [.686, .011, .885],
    0x1D415: [.686, .007, .869],
    0x1D416: [.686, .007, 1.189],
    0x1D417: [.686, 0, .869],
    0x1D418: [.686, 0, .869],
    0x1D419: [.686, 0, .703],
    0x1D41A: [.453, .006, .559],
    0x1D41B: [.694, .006, .639],
    0x1D41C: [.453, .006, .511],
    0x1D41D: [.694, .006, .639],
    0x1D41E: [.452, .006, .527],
    0x1D41F: [.7, 0, .351, { ic: .101 }],
    0x1D420: [.455, .201, .575],
    0x1D421: [.694, 0, .639],
    0x1D422: [.695, 0, .319],
    0x1D423: [.695, .2, .351],
    0x1D424: [.694, 0, .607],
    0x1D425: [.694, 0, .319],
    0x1D426: [.45, 0, .958],
    0x1D427: [.45, 0, .639],
    0x1D428: [.452, .005, .575],
    0x1D429: [.45, .194, .639],
    0x1D42A: [.45, .194, .607],
    0x1D42B: [.45, 0, .474],
    0x1D42C: [.453, .006, .454],
    0x1D42D: [.635, .005, .447],
    0x1D42E: [.45, .006, .639],
    0x1D42F: [.444, 0, .607],
    0x1D430: [.444, 0, .831],
    0x1D431: [.444, 0, .607],
    0x1D432: [.444, .2, .607],
    0x1D433: [.444, 0, .511],
    0x1D434: [.716, 0, .75, { sk: .139 }],
    0x1D435: [.683, 0, .759, { sk: .0833 }],
    0x1D436: [.705, .022, .715, { ic: .045, sk: .0833 }],
    0x1D437: [.683, 0, .828, { sk: .0556 }],
    0x1D438: [.68, 0, .738, { ic: .026, sk: .0833 }],
    0x1D439: [.68, 0, .643, { ic: .106, sk: .0833 }],
    0x1D43A: [.705, .022, .786, { sk: .0833 }],
    0x1D43B: [.683, 0, .831, { ic: .057, sk: .0556 }],
    0x1D43C: [.683, 0, .44, { ic: .064, sk: .111 }],
    0x1D43D: [.683, .022, .555, { ic: .078, sk: .167 }],
    0x1D43E: [.683, 0, .849, { ic: .04, sk: .0556 }],
    0x1D43F: [.683, 0, .681, { sk: .0278 }],
    0x1D440: [.683, 0, .97, { ic: .081, sk: .0833 }],
    0x1D441: [.683, 0, .803, { ic: .085, sk: .0833 }],
    0x1D442: [.704, .022, .763, { sk: .0833 }],
    0x1D443: [.683, 0, .642, { ic: .109, sk: .0833 }],
    0x1D444: [.704, .194, .791, { sk: .0833 }],
    0x1D445: [.683, .021, .759, { sk: .0833 }],
    0x1D446: [.705, .022, .613, { ic: .032, sk: .0833 }],
    0x1D447: [.677, 0, .584, { ic: .12, sk: .0833 }],
    0x1D448: [.683, .022, .683, { ic: .084, sk: .0278 }],
    0x1D449: [.683, .022, .583, { ic: .186 }],
    0x1D44A: [.683, .022, .944, { ic: .104 }],
    0x1D44B: [.683, 0, .828, { ic: .024, sk: .0833 }],
    0x1D44C: [.683, 0, .581, { ic: .182 }],
    0x1D44D: [.683, 0, .683, { ic: .04, sk: .0833 }],
    0x1D44E: [.441, .01, .529],
    0x1D44F: [.694, .011, .429],
    0x1D450: [.442, .011, .433, { sk: .0556 }],
    0x1D451: [.694, .01, .52, { sk: .167 }],
    0x1D452: [.442, .011, .466, { sk: .0556 }],
    0x1D453: [.705, .205, .49, { ic: .06, sk: .167 }],
    0x1D454: [.442, .205, .477, { sk: .0278 }],
    0x1D456: [.661, .011, .345],
    0x1D457: [.661, .204, .412],
    0x1D458: [.694, .011, .521],
    0x1D459: [.694, .011, .298, { sk: .0833 }],
    0x1D45A: [.442, .011, .878],
    0x1D45B: [.442, .011, .6],
    0x1D45C: [.441, .011, .485, { sk: .0556 }],
    0x1D45D: [.442, .194, .503, { sk: .0833 }],
    0x1D45E: [.442, .194, .446, { ic: .014, sk: .0833 }],
    0x1D45F: [.442, .011, .451, { sk: .0556 }],
    0x1D460: [.442, .01, .469, { sk: .0556 }],
    0x1D461: [.626, .011, .361, { sk: .0833 }],
    0x1D462: [.442, .011, .572, { sk: .0278 }],
    0x1D463: [.443, .011, .485, { sk: .0278 }],
    0x1D464: [.443, .011, .716, { sk: .0833 }],
    0x1D465: [.442, .011, .572, { sk: .0278 }],
    0x1D466: [.442, .205, .49, { sk: .0556 }],
    0x1D467: [.442, .011, .465, { sk: .0556 }],
    0x1D468: [.711, 0, .869, { sk: .16 }],
    0x1D469: [.686, 0, .866, { sk: .0958 }],
    0x1D46A: [.703, .017, .817, { ic: .038, sk: .0958 }],
    0x1D46B: [.686, 0, .938, { sk: .0639 }],
    0x1D46C: [.68, 0, .81, { ic: .015, sk: .0958 }],
    0x1D46D: [.68, 0, .689, { ic: .12, sk: .0958 }],
    0x1D46E: [.703, .016, .887, { sk: .0958 }],
    0x1D46F: [.686, 0, .982, { ic: .045, sk: .0639 }],
    0x1D470: [.686, 0, .511, { ic: .062, sk: .128 }],
    0x1D471: [.686, .017, .631, { ic: .063, sk: .192 }],
    0x1D472: [.686, 0, .971, { ic: .032, sk: .0639 }],
    0x1D473: [.686, 0, .756, { sk: .0319 }],
    0x1D474: [.686, 0, 1.142, { ic: .077, sk: .0958 }],
    0x1D475: [.686, 0, .95, { ic: .077, sk: .0958 }],
    0x1D476: [.703, .017, .837, { sk: .0958 }],
    0x1D477: [.686, 0, .723, { ic: .124, sk: .0958 }],
    0x1D478: [.703, .194, .869, { sk: .0958 }],
    0x1D479: [.686, .017, .872, { sk: .0958 }],
    0x1D47A: [.703, .017, .693, { ic: .021, sk: .0958 }],
    0x1D47B: [.675, 0, .637, { ic: .135, sk: .0958 }],
    0x1D47C: [.686, .016, .8, { ic: .077, sk: .0319 }],
    0x1D47D: [.686, .016, .678, { ic: .208 }],
    0x1D47E: [.686, .017, 1.093, { ic: .114 }],
    0x1D47F: [.686, 0, .947, { sk: .0958 }],
    0x1D480: [.686, 0, .675, { ic: .201 }],
    0x1D481: [.686, 0, .773, { ic: .032, sk: .0958 }],
    0x1D482: [.452, .008, .633],
    0x1D483: [.694, .008, .521],
    0x1D484: [.451, .008, .513, { sk: .0639 }],
    0x1D485: [.694, .008, .61, { sk: .192 }],
    0x1D486: [.452, .008, .554, { sk: .0639 }],
    0x1D487: [.701, .201, .568, { ic: .056, sk: .192 }],
    0x1D488: [.452, .202, .545, { sk: .0319 }],
    0x1D489: [.694, .008, .668, { sk: -0.0319 }],
    0x1D48A: [.694, .008, .405],
    0x1D48B: [.694, .202, .471],
    0x1D48C: [.694, .008, .604],
    0x1D48D: [.694, .008, .348, { sk: .0958 }],
    0x1D48E: [.452, .008, 1.032],
    0x1D48F: [.452, .008, .713],
    0x1D490: [.452, .008, .585, { sk: .0639 }],
    0x1D491: [.452, .194, .601, { sk: .0958 }],
    0x1D492: [.452, .194, .542, { sk: .0958 }],
    0x1D493: [.452, .008, .529, { sk: .0639 }],
    0x1D494: [.451, .008, .531, { sk: .0639 }],
    0x1D495: [.643, .007, .415, { sk: .0958 }],
    0x1D496: [.452, .008, .681, { sk: .0319 }],
    0x1D497: [.453, .008, .567, { sk: .0319 }],
    0x1D498: [.453, .008, .831, { sk: .0958 }],
    0x1D499: [.452, .008, .659, { sk: .0319 }],
    0x1D49A: [.452, .202, .59, { sk: .0639 }],
    0x1D49B: [.452, .008, .555, { sk: .0639 }],
    0x1D49C: [.717, .008, .803, { ic: .213, sk: .389 }],
    0x1D49E: [.728, .026, .666, { ic: .153, sk: .278 }],
    0x1D49F: [.708, .031, .774, { ic: .081, sk: .111 }],
    0x1D4A2: [.717, .037, .61, { ic: .128, sk: .25 }],
    0x1D4A5: [.717, .314, 1.052, { ic: .081, sk: .417 }],
    0x1D4A6: [.717, .037, .914, { ic: .29, sk: .361 }],
    0x1D4A9: [.726, .036, .902, { ic: .306, sk: .389 }],
    0x1D4AA: [.707, .008, .738, { ic: .067, sk: .167 }],
    0x1D4AB: [.716, .037, 1.013, { ic: .018, sk: .222 }],
    0x1D4AC: [.717, .017, .883, { sk: .278 }],
    0x1D4AE: [.708, .036, .868, { ic: .148, sk: .333 }],
    0x1D4AF: [.735, .037, .747, { ic: .249, sk: .222 }],
    0x1D4B0: [.717, .017, .8, { ic: .16, sk: .25 }],
    0x1D4B1: [.717, .017, .622, { ic: .228, sk: .222 }],
    0x1D4B2: [.717, .017, .805, { ic: .221, sk: .25 }],
    0x1D4B3: [.717, .017, .944, { ic: .187, sk: .278 }],
    0x1D4B4: [.716, .017, .71, { ic: .249, sk: .194 }],
    0x1D4B5: [.717, .016, .821, { ic: .211, sk: .306 }],
    0x1D504: [.696, .026, .718],
    0x1D505: [.691, .027, .884],
    0x1D507: [.685, .027, .832],
    0x1D508: [.685, .024, .663],
    0x1D509: [.686, .153, .611],
    0x1D50A: [.69, .026, .785],
    0x1D50D: [.686, .139, .552],
    0x1D50E: [.68, .027, .668, { ic: .014 }],
    0x1D50F: [.686, .026, .666],
    0x1D510: [.692, .027, 1.05],
    0x1D511: [.686, .025, .832],
    0x1D512: [.729, .027, .827],
    0x1D513: [.692, .218, .828],
    0x1D514: [.729, .069, .827],
    0x1D516: [.692, .027, .829],
    0x1D517: [.701, .027, .669],
    0x1D518: [.697, .027, .646, { ic: .019 }],
    0x1D519: [.686, .026, .831],
    0x1D51A: [.686, .027, 1.046],
    0x1D51B: [.688, .027, .719],
    0x1D51C: [.686, .218, .833],
    0x1D51E: [.47, .035, .5],
    0x1D51F: [.685, .031, .513],
    0x1D520: [.466, .029, .389],
    0x1D521: [.609, .033, .499],
    0x1D522: [.467, .03, .401],
    0x1D523: [.681, .221, .326],
    0x1D524: [.47, .209, .504],
    0x1D525: [.688, .205, .521],
    0x1D526: [.673, .02, .279],
    0x1D527: [.672, .208, .281],
    0x1D528: [.689, .025, .389],
    0x1D529: [.685, .02, .28],
    0x1D52A: [.475, .026, .767],
    0x1D52B: [.475, .022, .527],
    0x1D52C: [.48, .028, .489],
    0x1D52D: [.541, .212, .5],
    0x1D52E: [.479, .219, .489],
    0x1D52F: [.474, .021, .389],
    0x1D530: [.478, .029, .443],
    0x1D531: [.64, .02, .333, { ic: .015 }],
    0x1D532: [.474, .023, .517],
    0x1D533: [.53, .028, .512],
    0x1D534: [.532, .028, .774],
    0x1D535: [.472, .188, .389],
    0x1D536: [.528, .218, .499],
    0x1D537: [.471, .214, .391],
    0x1D538: [.701, 0, .722],
    0x1D539: [.683, 0, .667],
    0x1D53B: [.683, 0, .722],
    0x1D53C: [.683, 0, .667],
    0x1D53D: [.683, 0, .611],
    0x1D53E: [.702, .019, .778],
    0x1D540: [.683, 0, .389],
    0x1D541: [.683, .077, .5],
    0x1D542: [.683, 0, .778],
    0x1D543: [.683, 0, .667],
    0x1D544: [.683, 0, .944],
    0x1D546: [.701, .019, .778],
    0x1D54A: [.702, .012, .556],
    0x1D54B: [.683, 0, .667],
    0x1D54C: [.683, .019, .722],
    0x1D54D: [.683, .02, .722],
    0x1D54E: [.683, .019, 1],
    0x1D54F: [.683, 0, .722],
    0x1D550: [.683, 0, .722],
    0x1D56C: [.686, .031, .847],
    0x1D56D: [.684, .031, 1.044],
    0x1D56E: [.676, .032, .723],
    0x1D56F: [.683, .029, .982],
    0x1D570: [.686, .029, .783],
    0x1D571: [.684, .146, .722],
    0x1D572: [.687, .029, .927],
    0x1D573: [.683, .126, .851],
    0x1D574: [.681, .025, .655],
    0x1D575: [.68, .141, .652],
    0x1D576: [.681, .026, .789, { ic: .017 }],
    0x1D577: [.683, .028, .786],
    0x1D578: [.683, .032, 1.239],
    0x1D579: [.679, .03, .983],
    0x1D57A: [.726, .03, .976],
    0x1D57B: [.688, .223, .977],
    0x1D57C: [.726, .083, .976],
    0x1D57D: [.688, .028, .978],
    0x1D57E: [.685, .031, .978],
    0x1D57F: [.686, .03, .79, { ic: .012 }],
    0x1D580: [.688, .039, .851, { ic: .02 }],
    0x1D581: [.685, .029, .982],
    0x1D582: [.683, .03, 1.235],
    0x1D583: [.681, .035, .849],
    0x1D584: [.688, .214, .984],
    0x1D585: [.677, .148, .711],
    0x1D586: [.472, .032, .603],
    0x1D587: [.69, .032, .59],
    0x1D588: [.473, .026, .464],
    0x1D589: [.632, .028, .589],
    0x1D58A: [.471, .027, .472],
    0x1D58B: [.687, .222, .388],
    0x1D58C: [.472, .208, .595],
    0x1D58D: [.687, .207, .615],
    0x1D58E: [.686, .025, .331],
    0x1D58F: [.682, .203, .332],
    0x1D590: [.682, .025, .464],
    0x1D591: [.681, .024, .337],
    0x1D592: [.476, .031, .921],
    0x1D593: [.473, .028, .654],
    0x1D594: [.482, .034, .609],
    0x1D595: [.557, .207, .604],
    0x1D596: [.485, .211, .596],
    0x1D597: [.472, .026, .46],
    0x1D598: [.479, .034, .523],
    0x1D599: [.648, .027, .393, { ic: .014 }],
    0x1D59A: [.472, .032, .589, { ic: .014 }],
    0x1D59B: [.546, .027, .604],
    0x1D59C: [.549, .032, .918],
    0x1D59D: [.471, .188, .459],
    0x1D59E: [.557, .221, .589],
    0x1D59F: [.471, .214, .461],
    0x1D5A0: [.694, 0, .667],
    0x1D5A1: [.694, 0, .667],
    0x1D5A2: [.705, .011, .639],
    0x1D5A3: [.694, 0, .722],
    0x1D5A4: [.691, 0, .597],
    0x1D5A5: [.691, 0, .569],
    0x1D5A6: [.704, .011, .667],
    0x1D5A7: [.694, 0, .708],
    0x1D5A8: [.694, 0, .278],
    0x1D5A9: [.694, .022, .472],
    0x1D5AA: [.694, 0, .694],
    0x1D5AB: [.694, 0, .542],
    0x1D5AC: [.694, 0, .875],
    0x1D5AD: [.694, 0, .708],
    0x1D5AE: [.715, .022, .736],
    0x1D5AF: [.694, 0, .639],
    0x1D5B0: [.715, .125, .736],
    0x1D5B1: [.694, 0, .646],
    0x1D5B2: [.716, .022, .556],
    0x1D5B3: [.688, 0, .681],
    0x1D5B4: [.694, .022, .688],
    0x1D5B5: [.694, 0, .667],
    0x1D5B6: [.694, 0, .944],
    0x1D5B7: [.694, 0, .667],
    0x1D5B8: [.694, 0, .667],
    0x1D5B9: [.694, 0, .611],
    0x1D5BA: [.46, .01, .481],
    0x1D5BB: [.694, .011, .517],
    0x1D5BC: [.46, .01, .444],
    0x1D5BD: [.694, .01, .517],
    0x1D5BE: [.461, .01, .444],
    0x1D5BF: [.705, 0, .306, { ic: .041 }],
    0x1D5C0: [.455, .206, .5],
    0x1D5C1: [.694, 0, .517],
    0x1D5C2: [.68, 0, .239],
    0x1D5C3: [.68, .205, .267],
    0x1D5C4: [.694, 0, .489],
    0x1D5C5: [.694, 0, .239],
    0x1D5C6: [.455, 0, .794],
    0x1D5C7: [.455, 0, .517],
    0x1D5C8: [.46, .01, .5],
    0x1D5C9: [.455, .194, .517],
    0x1D5CA: [.455, .194, .517],
    0x1D5CB: [.455, 0, .342],
    0x1D5CC: [.46, .01, .383],
    0x1D5CD: [.571, .01, .361],
    0x1D5CE: [.444, .01, .517],
    0x1D5CF: [.444, 0, .461],
    0x1D5D0: [.444, 0, .683],
    0x1D5D1: [.444, 0, .461],
    0x1D5D2: [.444, .204, .461],
    0x1D5D3: [.444, 0, .435],
    0x1D5D4: [.694, 0, .733],
    0x1D5D5: [.694, 0, .733],
    0x1D5D6: [.704, .011, .703],
    0x1D5D7: [.694, 0, .794],
    0x1D5D8: [.691, 0, .642],
    0x1D5D9: [.691, 0, .611],
    0x1D5DA: [.705, .011, .733],
    0x1D5DB: [.694, 0, .794],
    0x1D5DC: [.694, 0, .331],
    0x1D5DD: [.694, .022, .519],
    0x1D5DE: [.694, 0, .764],
    0x1D5DF: [.694, 0, .581],
    0x1D5E0: [.694, 0, .978],
    0x1D5E1: [.694, 0, .794],
    0x1D5E2: [.716, .022, .794],
    0x1D5E3: [.694, 0, .703],
    0x1D5E4: [.716, .106, .794],
    0x1D5E5: [.694, 0, .703],
    0x1D5E6: [.716, .022, .611],
    0x1D5E7: [.688, 0, .733],
    0x1D5E8: [.694, .022, .764],
    0x1D5E9: [.694, 0, .733],
    0x1D5EA: [.694, 0, 1.039],
    0x1D5EB: [.694, 0, .733],
    0x1D5EC: [.694, 0, .733],
    0x1D5ED: [.694, 0, .672],
    0x1D5EE: [.475, .011, .525],
    0x1D5EF: [.694, .01, .561],
    0x1D5F0: [.475, .011, .489],
    0x1D5F1: [.694, .011, .561],
    0x1D5F2: [.474, .01, .511],
    0x1D5F3: [.705, 0, .336, { ic: .045 }],
    0x1D5F4: [.469, .206, .55],
    0x1D5F5: [.694, 0, .561],
    0x1D5F6: [.695, 0, .256],
    0x1D5F7: [.695, .205, .286],
    0x1D5F8: [.694, 0, .531],
    0x1D5F9: [.694, 0, .256],
    0x1D5FA: [.469, 0, .867],
    0x1D5FB: [.468, 0, .561],
    0x1D5FC: [.474, .011, .55],
    0x1D5FD: [.469, .194, .561],
    0x1D5FE: [.469, .194, .561],
    0x1D5FF: [.469, 0, .372],
    0x1D600: [.474, .01, .422],
    0x1D601: [.589, .01, .404],
    0x1D602: [.458, .011, .561],
    0x1D603: [.458, 0, .5],
    0x1D604: [.458, 0, .744],
    0x1D605: [.458, 0, .5],
    0x1D606: [.458, .205, .5],
    0x1D607: [.458, 0, .476],
    0x1D608: [.694, 0, .667],
    0x1D609: [.694, 0, .667, { ic: .029 }],
    0x1D60A: [.705, .01, .639, { ic: .08 }],
    0x1D60B: [.694, 0, .722, { ic: .025 }],
    0x1D60C: [.691, 0, .597, { ic: .091 }],
    0x1D60D: [.691, 0, .569, { ic: .104 }],
    0x1D60E: [.705, .011, .667, { ic: .063 }],
    0x1D60F: [.694, 0, .708, { ic: .06 }],
    0x1D610: [.694, 0, .278, { ic: .06 }],
    0x1D611: [.694, .022, .472, { ic: .063 }],
    0x1D612: [.694, 0, .694, { ic: .091 }],
    0x1D613: [.694, 0, .542],
    0x1D614: [.694, 0, .875, { ic: .054 }],
    0x1D615: [.694, 0, .708, { ic: .058 }],
    0x1D616: [.716, .022, .736, { ic: .027 }],
    0x1D617: [.694, 0, .639, { ic: .051 }],
    0x1D618: [.716, .125, .736, { ic: .027 }],
    0x1D619: [.694, 0, .646, { ic: .052 }],
    0x1D61A: [.716, .022, .556, { ic: .053 }],
    0x1D61B: [.688, 0, .681, { ic: .109 }],
    0x1D61C: [.694, .022, .688, { ic: .059 }],
    0x1D61D: [.694, 0, .667, { ic: .132 }],
    0x1D61E: [.694, 0, .944, { ic: .132 }],
    0x1D61F: [.694, 0, .667, { ic: .091 }],
    0x1D620: [.694, 0, .667, { ic: .143 }],
    0x1D621: [.694, 0, .611, { ic: .091 }],
    0x1D622: [.461, .01, .481],
    0x1D623: [.694, .011, .517, { ic: .022 }],
    0x1D624: [.46, .011, .444, { ic: .055 }],
    0x1D625: [.694, .01, .517, { ic: .071 }],
    0x1D626: [.46, .011, .444, { ic: .028 }],
    0x1D627: [.705, 0, .306, { ic: .188 }],
    0x1D628: [.455, .206, .5, { ic: .068 }],
    0x1D629: [.694, 0, .517],
    0x1D62A: [.68, 0, .239, { ic: .076 }],
    0x1D62B: [.68, .204, .267, { ic: .069 }],
    0x1D62C: [.694, 0, .489, { ic: .054 }],
    0x1D62D: [.694, 0, .239, { ic: .072 }],
    0x1D62E: [.455, 0, .794],
    0x1D62F: [.454, 0, .517],
    0x1D630: [.461, .011, .5, { ic: .023 }],
    0x1D631: [.455, .194, .517, { ic: .021 }],
    0x1D632: [.455, .194, .517, { ic: .021 }],
    0x1D633: [.455, 0, .342, { ic: .082 }],
    0x1D634: [.461, .011, .383, { ic: .053 }],
    0x1D635: [.571, .011, .361, { ic: .049 }],
    0x1D636: [.444, .01, .517, { ic: .02 }],
    0x1D637: [.444, 0, .461, { ic: .079 }],
    0x1D638: [.444, 0, .683, { ic: .079 }],
    0x1D639: [.444, 0, .461, { ic: .076 }],
    0x1D63A: [.444, .205, .461, { ic: .079 }],
    0x1D63B: [.444, 0, .435, { ic: .059 }],
    0x1D670: [.623, 0, .525],
    0x1D671: [.611, 0, .525],
    0x1D672: [.622, .011, .525],
    0x1D673: [.611, 0, .525],
    0x1D674: [.611, 0, .525],
    0x1D675: [.611, 0, .525],
    0x1D676: [.622, .011, .525],
    0x1D677: [.611, 0, .525],
    0x1D678: [.611, 0, .525],
    0x1D679: [.611, .011, .525],
    0x1D67A: [.611, 0, .525],
    0x1D67B: [.611, 0, .525],
    0x1D67C: [.611, 0, .525],
    0x1D67D: [.611, 0, .525],
    0x1D67E: [.621, .01, .525],
    0x1D67F: [.611, 0, .525],
    0x1D680: [.621, .138, .525],
    0x1D681: [.611, .011, .525],
    0x1D682: [.622, .011, .525],
    0x1D683: [.611, 0, .525],
    0x1D684: [.611, .011, .525],
    0x1D685: [.611, .007, .525],
    0x1D686: [.611, .007, .525],
    0x1D687: [.611, 0, .525],
    0x1D688: [.611, 0, .525],
    0x1D689: [.611, 0, .525],
    0x1D68A: [.439, .006, .525],
    0x1D68B: [.611, .006, .525],
    0x1D68C: [.44, .006, .525],
    0x1D68D: [.611, .006, .525],
    0x1D68E: [.44, .006, .525],
    0x1D68F: [.617, 0, .525],
    0x1D690: [.442, .229, .525],
    0x1D691: [.611, 0, .525],
    0x1D692: [.612, 0, .525],
    0x1D693: [.612, .228, .525],
    0x1D694: [.611, 0, .525],
    0x1D695: [.611, 0, .525],
    0x1D696: [.436, 0, .525, { ic: .011 }],
    0x1D697: [.436, 0, .525],
    0x1D698: [.44, .006, .525],
    0x1D699: [.437, .221, .525],
    0x1D69A: [.437, .221, .525, { ic: .02 }],
    0x1D69B: [.437, 0, .525],
    0x1D69C: [.44, .006, .525],
    0x1D69D: [.554, .006, .525],
    0x1D69E: [.431, .005, .525],
    0x1D69F: [.431, 0, .525],
    0x1D6A0: [.431, 0, .525],
    0x1D6A1: [.431, 0, .525],
    0x1D6A2: [.431, .228, .525],
    0x1D6A3: [.431, 0, .525],
    0x1D6A8: [.698, 0, .869],
    0x1D6A9: [.686, 0, .818],
    0x1D6AA: [.68, 0, .692],
    0x1D6AB: [.698, 0, .958],
    0x1D6AC: [.68, 0, .756],
    0x1D6AD: [.686, 0, .703],
    0x1D6AE: [.686, 0, .9],
    0x1D6AF: [.696, .01, .894],
    0x1D6B0: [.686, 0, .436],
    0x1D6B1: [.686, 0, .901],
    0x1D6B2: [.698, 0, .806],
    0x1D6B3: [.686, 0, 1.092],
    0x1D6B4: [.686, 0, .9],
    0x1D6B5: [.675, 0, .767],
    0x1D6B6: [.696, .01, .864],
    0x1D6B7: [.68, 0, .9],
    0x1D6B8: [.686, 0, .786],
    0x1D6BA: [.686, 0, .831],
    0x1D6BB: [.675, 0, .8],
    0x1D6BC: [.697, 0, .894],
    0x1D6BD: [.686, 0, .831],
    0x1D6BE: [.686, 0, .869],
    0x1D6BF: [.686, 0, .894],
    0x1D6C0: [.696, 0, .831],
    0x1D6C1: [.686, .024, .958],
    0x1D6E2: [.716, 0, .75, { sk: .139 }],
    0x1D6E3: [.683, 0, .759, { sk: .0833 }],
    0x1D6E4: [.68, 0, .615, { ic: .106, sk: .0833 }],
    0x1D6E5: [.716, 0, .833, { sk: .167 }],
    0x1D6E6: [.68, 0, .738, { ic: .026, sk: .0833 }],
    0x1D6E7: [.683, 0, .683, { ic: .04, sk: .0833 }],
    0x1D6E8: [.683, 0, .831, { ic: .057, sk: .0556 }],
    0x1D6E9: [.704, .022, .763, { sk: .0833 }],
    0x1D6EA: [.683, 0, .44, { ic: .064, sk: .111 }],
    0x1D6EB: [.683, 0, .849, { ic: .04, sk: .0556 }],
    0x1D6EC: [.716, 0, .694, { sk: .167 }],
    0x1D6ED: [.683, 0, .97, { ic: .081, sk: .0833 }],
    0x1D6EE: [.683, 0, .803, { ic: .085, sk: .0833 }],
    0x1D6EF: [.677, 0, .742, { ic: .035, sk: .0833 }],
    0x1D6F0: [.704, .022, .763, { sk: .0833 }],
    0x1D6F1: [.68, 0, .831, { ic: .056, sk: .0556 }],
    0x1D6F2: [.683, 0, .642, { ic: .109, sk: .0833 }],
    0x1D6F4: [.683, 0, .78, { ic: .026, sk: .0833 }],
    0x1D6F5: [.677, 0, .584, { ic: .12, sk: .0833 }],
    0x1D6F6: [.705, 0, .583, { ic: .117, sk: .0556 }],
    0x1D6F7: [.683, 0, .667, { sk: .0833 }],
    0x1D6F8: [.683, 0, .828, { ic: .024, sk: .0833 }],
    0x1D6F9: [.683, 0, .612, { ic: .08, sk: .0556 }],
    0x1D6FA: [.704, 0, .772, { ic: .014, sk: .0833 }],
    0x1D6FC: [.442, .011, .64, { sk: .0278 }],
    0x1D6FD: [.705, .194, .566, { sk: .0833 }],
    0x1D6FE: [.441, .216, .518, { ic: .025 }],
    0x1D6FF: [.717, .01, .444, { sk: .0556 }],
    0x1D700: [.452, .022, .466, { sk: .0833 }],
    0x1D701: [.704, .204, .438, { ic: .033, sk: .0833 }],
    0x1D702: [.442, .216, .497, { sk: .0556 }],
    0x1D703: [.705, .01, .469, { sk: .0833 }],
    0x1D704: [.442, .01, .354, { sk: .0556 }],
    0x1D705: [.442, .011, .576],
    0x1D706: [.694, .012, .583],
    0x1D707: [.442, .216, .603, { sk: .0278 }],
    0x1D708: [.442, 0, .494, { ic: .036, sk: .0278 }],
    0x1D709: [.704, .205, .438, { sk: .111 }],
    0x1D70A: [.441, .011, .485, { sk: .0556 }],
    0x1D70B: [.431, .011, .57],
    0x1D70C: [.442, .216, .517, { sk: .0833 }],
    0x1D70D: [.442, .107, .363, { ic: .042, sk: .0833 }],
    0x1D70E: [.431, .011, .571],
    0x1D70F: [.431, .013, .437, { ic: .08, sk: .0278 }],
    0x1D710: [.443, .01, .54, { sk: .0278 }],
    0x1D711: [.442, .218, .654, { sk: .0833 }],
    0x1D712: [.442, .204, .626, { sk: .0556 }],
    0x1D713: [.694, .205, .651, { sk: .111 }],
    0x1D714: [.443, .011, .622],
    0x1D715: [.715, .022, .531, { ic: .035, sk: .0833 }],
    0x1D716: [.431, .011, .406, { sk: .0556 }],
    0x1D717: [.705, .011, .591, { sk: .0833 }],
    0x1D718: [.434, .006, .667, { ic: .067 }],
    0x1D719: [.694, .205, .596, { sk: .0833 }],
    0x1D71A: [.442, .194, .517, { sk: .0833 }],
    0x1D71B: [.431, .01, .828],
    0x1D71C: [.711, 0, .869, { sk: .16 }],
    0x1D71D: [.686, 0, .866, { sk: .0958 }],
    0x1D71E: [.68, 0, .657, { ic: .12, sk: .0958 }],
    0x1D71F: [.711, 0, .958, { sk: .192 }],
    0x1D720: [.68, 0, .81, { ic: .015, sk: .0958 }],
    0x1D721: [.686, 0, .773, { ic: .032, sk: .0958 }],
    0x1D722: [.686, 0, .982, { ic: .045, sk: .0639 }],
    0x1D723: [.702, .017, .867, { sk: .0958 }],
    0x1D724: [.686, 0, .511, { ic: .062, sk: .128 }],
    0x1D725: [.686, 0, .971, { ic: .032, sk: .0639 }],
    0x1D726: [.711, 0, .806, { sk: .192 }],
    0x1D727: [.686, 0, 1.142, { ic: .077, sk: .0958 }],
    0x1D728: [.686, 0, .95, { ic: .077, sk: .0958 }],
    0x1D729: [.675, 0, .841, { ic: .026, sk: .0958 }],
    0x1D72A: [.703, .017, .837, { sk: .0958 }],
    0x1D72B: [.68, 0, .982, { ic: .044, sk: .0639 }],
    0x1D72C: [.686, 0, .723, { ic: .124, sk: .0958 }],
    0x1D72E: [.686, 0, .885, { ic: .017, sk: .0958 }],
    0x1D72F: [.675, 0, .637, { ic: .135, sk: .0958 }],
    0x1D730: [.703, 0, .671, { ic: .131, sk: .0639 }],
    0x1D731: [.686, 0, .767, { sk: .0958 }],
    0x1D732: [.686, 0, .947, { sk: .0958 }],
    0x1D733: [.686, 0, .714, { ic: .076, sk: .0639 }],
    0x1D734: [.703, 0, .879, { sk: .0958 }],
    0x1D736: [.452, .008, .761, { sk: .0319 }],
    0x1D737: [.701, .194, .66, { sk: .0958 }],
    0x1D738: [.451, .211, .59, { ic: .027 }],
    0x1D739: [.725, .008, .522, { sk: .0639 }],
    0x1D73A: [.461, .017, .529, { sk: .0958 }],
    0x1D73B: [.711, .202, .508, { ic: .013, sk: .0958 }],
    0x1D73C: [.452, .211, .6, { sk: .0639 }],
    0x1D73D: [.702, .008, .562, { sk: .0958 }],
    0x1D73E: [.452, .008, .412, { sk: .0639 }],
    0x1D73F: [.452, .008, .668],
    0x1D740: [.694, .013, .671],
    0x1D741: [.452, .211, .708, { sk: .0319 }],
    0x1D742: [.452, 0, .577, { ic: .031, sk: .0319 }],
    0x1D743: [.711, .201, .508, { sk: .128 }],
    0x1D744: [.452, .008, .585, { sk: .0639 }],
    0x1D745: [.444, .008, .682],
    0x1D746: [.451, .211, .612, { sk: .0958 }],
    0x1D747: [.451, .105, .424, { ic: .033, sk: .0958 }],
    0x1D748: [.444, .008, .686],
    0x1D749: [.444, .013, .521, { ic: .089, sk: .0319 }],
    0x1D74A: [.453, .008, .631, { sk: .0319 }],
    0x1D74B: [.452, .216, .747, { sk: .0958 }],
    0x1D74C: [.452, .201, .718, { sk: .0639 }],
    0x1D74D: [.694, .202, .758, { sk: .128 }],
    0x1D74E: [.453, .008, .718],
    0x1D74F: [.71, .017, .628, { ic: .029, sk: .0958 }],
    0x1D750: [.444, .007, .483, { sk: .0639 }],
    0x1D751: [.701, .008, .692, { sk: .0958 }],
    0x1D752: [.434, .006, .667, { ic: .067 }],
    0x1D753: [.694, .202, .712, { sk: .0958 }],
    0x1D754: [.451, .194, .612, { sk: .0958 }],
    0x1D755: [.444, .008, .975],
    0x1D756: [.694, 0, .733],
    0x1D757: [.694, 0, .733],
    0x1D758: [.691, 0, .581],
    0x1D759: [.694, 0, .917],
    0x1D75A: [.691, 0, .642],
    0x1D75B: [.694, 0, .672],
    0x1D75C: [.694, 0, .794],
    0x1D75D: [.716, .022, .856],
    0x1D75E: [.694, 0, .331],
    0x1D75F: [.694, 0, .764],
    0x1D760: [.694, 0, .672],
    0x1D761: [.694, 0, .978],
    0x1D762: [.694, 0, .794],
    0x1D763: [.688, 0, .733],
    0x1D764: [.716, .022, .794],
    0x1D765: [.691, 0, .794],
    0x1D766: [.694, 0, .703],
    0x1D768: [.694, 0, .794],
    0x1D769: [.688, 0, .733],
    0x1D76A: [.715, 0, .856],
    0x1D76B: [.694, 0, .794],
    0x1D76C: [.694, 0, .733],
    0x1D76D: [.694, 0, .856],
    0x1D76E: [.716, 0, .794],
    0x1D7CE: [.654, .01, .575],
    0x1D7CF: [.655, 0, .575],
    0x1D7D0: [.654, 0, .575],
    0x1D7D1: [.655, .011, .575],
    0x1D7D2: [.656, 0, .575],
    0x1D7D3: [.655, .011, .575],
    0x1D7D4: [.655, .011, .575],
    0x1D7D5: [.676, .011, .575],
    0x1D7D6: [.654, .011, .575],
    0x1D7D7: [.654, .011, .575],
    0x1D7E2: [.678, .022, .5],
    0x1D7E3: [.678, 0, .5],
    0x1D7E4: [.677, 0, .5],
    0x1D7E5: [.678, .022, .5],
    0x1D7E6: [.656, 0, .5],
    0x1D7E7: [.656, .021, .5],
    0x1D7E8: [.677, .022, .5],
    0x1D7E9: [.656, .011, .5],
    0x1D7EA: [.678, .022, .5],
    0x1D7EB: [.677, .022, .5],
    0x1D7EC: [.715, .022, .55],
    0x1D7ED: [.716, 0, .55],
    0x1D7EE: [.716, 0, .55],
    0x1D7EF: [.716, .022, .55],
    0x1D7F0: [.694, 0, .55],
    0x1D7F1: [.694, .022, .55],
    0x1D7F2: [.716, .022, .55],
    0x1D7F3: [.695, .011, .55],
    0x1D7F4: [.715, .022, .55],
    0x1D7F5: [.716, .022, .55],
    0x1D7F6: [.621, .01, .525],
    0x1D7F7: [.622, 0, .525],
    0x1D7F8: [.622, 0, .525],
    0x1D7F9: [.622, .011, .525],
    0x1D7FA: [.624, 0, .525],
    0x1D7FB: [.611, .01, .525],
    0x1D7FC: [.622, .011, .525],
    0x1D7FD: [.627, .01, .525],
    0x1D7FE: [.621, .01, .525],
    0x1D7FF: [.622, .011, .525],
};
