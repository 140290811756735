"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Jcirc: '\u0134',
    Jcy: '\u0419',
    Jsercy: '\u0408',
    Jukcy: '\u0404',
    jcirc: '\u0135',
    jcy: '\u0439',
    jsercy: '\u0458',
    jukcy: '\u0454'
}, 'j');
