"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    GJcy: '\u0403',
    GT: '\u003E',
    Gammad: '\u03DC',
    Gbreve: '\u011E',
    Gcedil: '\u0122',
    Gcirc: '\u011C',
    Gcy: '\u0413',
    Gdot: '\u0120',
    GreaterGreater: '\u2AA2',
    Gt: '\u226B',
    gE: '\u2267',
    gacute: '\u01F5',
    gammad: '\u03DD',
    gbreve: '\u011F',
    gcirc: '\u011D',
    gcy: '\u0433',
    gdot: '\u0121',
    ge: '\u2265',
    gel: '\u22DB',
    geq: '\u2265',
    geqq: '\u2267',
    geqslant: '\u2A7E',
    ges: '\u2A7E',
    gescc: '\u2AA9',
    gesdot: '\u2A80',
    gesdoto: '\u2A82',
    gesdotol: '\u2A84',
    gesl: '\u22DB\uFE00',
    gesles: '\u2A94',
    gg: '\u226B',
    ggg: '\u22D9',
    gjcy: '\u0453',
    gl: '\u2277',
    glE: '\u2A92',
    gla: '\u2AA5',
    glj: '\u2AA4',
    gnapprox: '\u2A8A',
    gneq: '\u2A88',
    gneqq: '\u2269',
    grave: '\u0060',
    gsim: '\u2273',
    gsime: '\u2A8E',
    gsiml: '\u2A90',
    gtcc: '\u2AA7',
    gtcir: '\u2A7A',
    gtlPar: '\u2995',
    gtquest: '\u2A7C',
    gtrapprox: '\u2A86',
    gtrarr: '\u2978',
    gtrdot: '\u22D7',
    gtreqless: '\u22DB',
    gtreqqless: '\u2A8C',
    gtrless: '\u2277',
    gtrsim: '\u2273',
    gvertneqq: '\u2269\uFE00',
    gvnE: '\u2269\uFE00'
}, 'g');
