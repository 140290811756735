"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Afr: '\uD835\uDD04',
    Bfr: '\uD835\uDD05',
    Cfr: '\u212D',
    Dfr: '\uD835\uDD07',
    Efr: '\uD835\uDD08',
    Ffr: '\uD835\uDD09',
    Gfr: '\uD835\uDD0A',
    Hfr: '\u210C',
    Ifr: '\u2111',
    Jfr: '\uD835\uDD0D',
    Kfr: '\uD835\uDD0E',
    Lfr: '\uD835\uDD0F',
    Mfr: '\uD835\uDD10',
    Nfr: '\uD835\uDD11',
    Ofr: '\uD835\uDD12',
    Pfr: '\uD835\uDD13',
    Qfr: '\uD835\uDD14',
    Rfr: '\u211C',
    Sfr: '\uD835\uDD16',
    Tfr: '\uD835\uDD17',
    Ufr: '\uD835\uDD18',
    Vfr: '\uD835\uDD19',
    Wfr: '\uD835\uDD1A',
    Xfr: '\uD835\uDD1B',
    Yfr: '\uD835\uDD1C',
    Zfr: '\u2128',
    afr: '\uD835\uDD1E',
    bfr: '\uD835\uDD1F',
    cfr: '\uD835\uDD20',
    dfr: '\uD835\uDD21',
    efr: '\uD835\uDD22',
    ffr: '\uD835\uDD23',
    gfr: '\uD835\uDD24',
    hfr: '\uD835\uDD25',
    ifr: '\uD835\uDD26',
    jfr: '\uD835\uDD27',
    kfr: '\uD835\uDD28',
    lfr: '\uD835\uDD29',
    mfr: '\uD835\uDD2A',
    nfr: '\uD835\uDD2B',
    ofr: '\uD835\uDD2C',
    pfr: '\uD835\uDD2D',
    qfr: '\uD835\uDD2E',
    rfr: '\uD835\uDD2F',
    sfr: '\uD835\uDD30',
    tfr: '\uD835\uDD31',
    ufr: '\uD835\uDD32',
    vfr: '\uD835\uDD33',
    wfr: '\uD835\uDD34',
    xfr: '\uD835\uDD35',
    yfr: '\uD835\uDD36',
    zfr: '\uD835\uDD37'
}, 'fr');
