"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bold = void 0;
var FontData_js_1 = require("../../FontData.js");
var bold_js_1 = require("../../../common/fonts/tex/bold.js");
exports.bold = FontData_js_1.AddPaths(bold_js_1.bold, {
    0x21: '89 629Q89 663 116 684T171 705Q215 705 237 681T260 634Q260 619 233 434T204 244Q201 237 175 237Q150 237 146 244Q144 248 117 433T89 629ZM90 86Q90 125 116 148T177 171Q211 169 235 146T259 86Q259 48 235 25T175 1Q138 1 114 24T90 86',
    0x22: '38 572T38 608T61 669T121 694Q167 694 196 657T225 559Q225 520 214 482T186 418T151 370T119 339T99 329T82 340T70 360Q70 365 74 369T92 385T122 414Q142 441 154 471T170 518L172 535L166 532Q160 530 148 527T122 523Q85 523 62 547ZM305 572T305 608T328 669T388 694Q434 694 463 657T492 559Q492 520 481 482T453 418T418 370T386 339T366 329T349 340T337 360Q337 365 341 369T359 385T389 414Q409 441 421 471T436 518L439 535L433 532Q427 530 415 527T389 523Q352 523 329 547',
    0x23: '64 362Q64 380 87 393H363L366 404Q379 443 390 480T409 542T424 590T435 628T443 655T451 674T458 686T467 692T478 694Q490 694 499 686T509 662Q505 643 427 395Q427 393 523 393H620L623 404Q630 426 652 498T691 624T711 681Q718 694 735 694Q748 694 757 685T766 662Q762 643 684 395Q684 393 777 393H871Q872 392 875 390T881 386T887 381T891 374T893 363Q893 345 871 333L767 332H664L660 319Q660 318 638 245T614 171Q614 169 742 169H871L877 165Q883 161 885 159T890 151T893 138Q893 120 871 109L732 108H594L590 95Q587 84 546 -46Q508 -175 505 -178Q498 -193 478 -193Q462 -193 455 -183T448 -164Q448 -156 530 106Q530 108 434 108H337L333 95Q330 84 289 -46Q251 -175 248 -178Q240 -193 222 -193Q206 -193 199 -183T191 -164Q191 -154 273 106Q273 108 180 108L87 109Q64 117 64 139Q64 156 87 169H293L321 262Q326 277 331 294T340 321L343 330Q343 332 215 332L87 333Q64 343 64 362ZM600 330Q600 332 504 332H407L403 319Q403 318 381 245T357 171Q357 169 453 169H550L578 262Q583 277 588 294T597 321L600 330',
    0x24: '64 494Q64 541 80 579T120 638T171 674T219 693T253 698H256V750H318V699H323Q355 694 380 686T433 663T480 620T506 556Q510 539 510 520Q510 480 488 463T440 445L422 447Q407 451 398 459Q370 478 370 515Q370 542 384 559T412 580L427 584Q424 589 418 596T386 617T324 636H318V434Q411 419 460 355T510 217Q510 196 507 175T492 122T461 67T404 23T318 -4V-56H256V-5H254Q252 -3 240 -3Q194 4 160 23T108 64T80 112T67 156T64 190Q64 218 81 240T134 262Q171 262 187 240T204 193T190 150T153 125Q146 125 144 123Q142 123 149 113T174 89T218 66Q247 58 255 58Q256 58 256 173V287L239 291Q160 308 112 365T64 494ZM255 636Q246 635 236 632T206 620T173 591T160 543Q160 472 256 448V542Q256 636 255 636ZM320 59Q324 59 333 61T356 70T384 89T406 120T415 167Q415 200 395 225T356 260T318 274V59H320',
    0x25: '65 549Q65 609 84 652T132 714T187 742T236 750Q265 750 296 734T355 697T431 661T541 644Q662 644 736 730Q751 749 767 749T790 739T797 719Q797 710 768 672T504 329Q212 -47 211 -48Q203 -55 191 -55Q161 -55 161 -25Q161 -17 163 -12L642 609Q608 595 542 595Q515 595 488 599T441 608T405 619T381 628L373 632Q373 630 375 619T380 589T383 548Q383 455 343 401T235 347Q217 347 198 351T154 368T110 403T78 462T65 549ZM320 549Q320 633 295 665T235 698H234Q214 698 196 674Q182 650 182 549Q182 509 183 486T190 441T207 409T238 399Q269 399 294 431T320 549ZM745 -56Q727 -56 708 -52T664 -35T620 0T588 59T575 146T588 232T620 291T663 325T708 343T747 347Q810 347 851 294T893 146Q893 89 879 48T841 -15T794 -46T745 -56ZM830 146Q830 230 805 262T745 295H744Q724 295 706 271Q692 247 692 146Q692 106 693 83T700 38T717 6T748 -4Q779 -4 804 28T830 146',
    0x26: '255 -11Q209 -11 164 4T84 56T48 146Q48 159 50 171Q57 197 72 218T99 249T152 292Q204 333 204 334L194 356Q185 379 176 421T166 511Q166 538 168 551Q182 613 226 654T332 704Q334 704 343 704T358 705Q412 702 444 661T476 565V559Q476 489 334 371L330 368L335 357Q382 272 485 165L496 154L506 163Q543 200 597 273L671 382H601V444H610L732 441Q821 441 830 444H836V382H741L709 335Q702 324 687 302T665 270T646 244T625 216T605 191T581 162T553 132L537 116Q544 109 557 98T605 69T673 51Q711 51 739 70T767 115V118H829V114Q829 70 786 30T668 -11Q570 -11 474 37L451 49L441 43Q352 -11 255 -11ZM415 564Q415 596 400 625T356 654Q329 654 310 634T285 588Q283 580 283 554Q283 475 309 417L325 431Q415 512 415 564ZM192 182Q192 126 213 89T279 51Q348 51 400 83L389 91Q362 112 338 137T295 186T264 229T240 265T227 286Q226 285 222 280T217 272T211 263T205 251T200 238T196 222T193 204T192 182',
    0x27: '74 572T74 608T97 669T157 694Q203 694 232 657T261 559Q261 520 250 482T222 418T187 370T155 339T135 329Q128 329 117 340T106 359Q106 365 117 375T144 399T176 440T203 505Q204 511 205 518T208 530V535L202 532Q196 530 184 527T158 523Q121 523 98 547',
    0x28: '103 166T103 251T121 412T165 541T225 639T287 708T341 750H356H361Q382 750 382 736Q382 732 365 714T323 661T274 576T232 439T214 250Q214 -62 381 -229Q382 -231 382 -234Q382 -249 360 -249H356H341Q314 -231 287 -207T226 -138T165 -41T121 89',
    0x29: '231 251Q231 354 214 439T173 575T123 661T81 714T64 735Q64 744 73 749H75Q77 749 79 749T84 750T90 750H105Q132 732 159 708T220 639T281 542T325 413T343 251T325 89T281 -40T221 -138T159 -207T105 -249H90Q80 -249 76 -249T68 -245T64 -234Q64 -230 81 -212T123 -160T172 -75T214 61T231 251',
    0x2A: '235 706Q235 724 251 737T287 750Q306 750 322 738T339 706Q339 685 318 580V579Q429 663 436 666Q441 668 449 668Q471 668 486 650T501 612Q501 582 478 572Q476 570 414 549L354 528L414 507Q420 505 430 502T445 497T458 492T470 488T479 483T487 478T493 471T497 463T500 454T501 443Q501 423 486 406T449 388H446Q435 388 370 437Q339 461 318 477V476Q339 371 339 350Q339 332 323 319T287 306T251 319T235 350Q235 371 256 476V477Q145 393 138 390Q133 388 125 388Q103 388 88 406T73 444Q73 474 96 484Q98 486 160 507L220 528L160 549Q154 551 144 554T129 559T116 564T104 568T95 573T87 578T81 585T77 593T74 602T73 613Q73 633 88 650T125 668H128Q139 668 204 619Q235 595 256 579V580Q235 685 235 706',
    0x2B: '64 232T64 250T87 281H416V444Q416 608 418 612Q426 633 446 633T475 613Q477 608 477 444V281H807Q808 280 811 278T817 274T823 269T827 262T829 251Q829 230 807 221L642 220H477V57Q477 -107 475 -112Q468 -131 446 -131Q425 -131 418 -112Q416 -107 416 57V220H251L87 221Q64 232 64 250',
    0x2C: '74 85Q74 120 97 145T159 171Q200 171 226 138Q258 101 258 37Q258 -5 246 -44T218 -109T183 -155T152 -184T135 -194Q129 -194 118 -183T106 -164Q106 -157 115 -149Q121 -145 130 -137T161 -100T195 -35Q197 -28 200 -17T204 3T205 11T199 9T183 3T159 0Q120 0 97 26T74 85',
    0x2D: '13 166V278H318V166H13',
    0x2E: '74 85Q74 121 99 146T156 171Q200 171 222 143T245 85Q245 56 224 29T160 1Q118 1 96 27T74 85',
    0x2F: '451 730Q460 750 479 750Q492 750 501 740T510 718Q508 708 318 244L122 -232Q112 -250 95 -250Q82 -250 73 -241T64 -218Q66 -205 258 261T451 730',
    0x3A: '74 359Q74 394 98 419T158 444Q200 444 222 417T245 358Q245 329 224 302T160 274Q116 274 95 301T74 359ZM74 85Q74 121 99 146T156 171Q200 171 222 143T245 85Q245 56 224 29T160 1Q118 1 96 27T74 85',
    0x3B: '74 359Q74 394 98 419T158 444Q200 444 222 417T245 358Q245 329 224 302T160 274Q116 274 95 301T74 359ZM74 50T74 86T97 146T158 171Q204 171 226 132T248 38Q248 -23 223 -80T171 -165T135 -194Q129 -194 118 -183T106 -164Q106 -163 106 -160L107 -158Q108 -155 121 -142T150 -107T177 -58Q189 -32 194 3Q195 6 193 6Q172 0 158 0Q121 0 98 25',
    0x3C: '797 -56Q797 -68 790 -76T767 -85H759L434 70Q108 226 105 229Q96 238 96 250Q96 263 105 272Q109 276 271 354T595 508T757 585Q763 587 766 587Q780 587 788 578T797 556Q797 544 788 535Q784 531 490 391L197 251Q213 242 359 173T644 37T788 -34Q797 -43 797 -56',
    0x3D: '87 333Q64 343 64 362Q64 383 84 391Q89 393 448 393H807Q808 392 811 390T817 386T823 381T827 374T829 363Q829 345 807 333H87ZM87 109Q64 118 64 139Q64 159 86 168Q89 169 448 169H807L812 166Q816 163 818 162T823 157T827 149T829 139Q829 118 807 109H87',
    0x3E: '127 -85Q110 -85 103 -75T96 -55Q96 -41 106 -34Q119 -24 308 65Q361 90 411 114L696 250L427 379Q106 533 103 537Q96 545 96 557Q96 568 104 577T128 587Q137 586 460 431T788 272Q797 263 797 250Q797 238 788 229Q785 226 459 70L135 -85H127',
    0x3F: '65 570Q65 628 119 664T259 700Q326 700 372 688T440 654T469 613T478 569Q478 505 412 465Q287 391 287 294V283Q287 250 284 244T263 237H256H249Q232 237 229 242T225 272V287Q227 364 253 418Q274 463 311 504Q335 530 335 575Q335 622 323 635T259 648Q231 648 209 644T179 636T170 630L172 628Q174 627 177 625T183 620T190 611T197 601T202 587T204 570Q204 539 185 519T134 499Q105 499 85 517T65 570ZM171 86Q171 125 197 148T258 171Q292 169 316 146T340 86Q340 48 316 25T256 1Q218 1 195 24T171 86',
    0x40: '64 347Q64 511 171 605T434 699Q487 699 500 698Q624 684 703 621T811 464Q828 414 828 344Q828 232 788 179T691 125Q673 125 657 127T628 132T606 140T588 148T576 156T568 162L566 164Q565 164 549 154T504 135T444 125Q349 125 284 183T218 347Q218 455 284 512T448 569Q554 569 610 479H638Q670 479 674 471Q676 468 676 340V258Q676 213 679 199T694 178Q701 174 713 177Q767 187 767 340Q767 489 678 569T446 649Q299 649 213 566T126 346Q126 307 134 269T166 189T225 116T320 65T455 45H463Q606 51 721 91L746 99H782H801Q829 99 829 85Q829 78 825 75T804 65Q800 63 797 62Q625 -6 451 -6Q271 -6 168 91T64 347ZM547 468Q526 493 504 505T444 517T377 476T346 347Q346 306 354 271T386 206T448 177Q505 177 547 226V468',
    0x5B: '128 -250V750H293V689H189V-189H293V-250H128',
    0x5C: '64 718Q63 731 72 740T94 750Q106 750 113 743Q118 741 122 732L318 256Q508 -208 510 -218Q511 -231 502 -240T480 -250Q460 -250 451 -230Q451 -229 259 238T64 718',
    0x5D: '25 689V750H190V-250H25V-189H129V689H25',
    0x5E: '207 632L287 694Q289 693 368 632T448 570T431 545T413 520Q410 520 350 559L287 597L224 559Q164 520 161 520Q160 520 143 544T126 570T207 632',
    0x5F: '0 -61V-10H574V-61H0',
    0x60: '114 634Q114 663 136 684T183 706Q191 706 196 705T208 700T219 693T232 681T245 666T262 645T282 620Q332 558 337 553Q338 552 318 527L299 503L223 543Q215 547 202 553T183 563T167 571T153 580T141 587T131 595T124 603T118 612T115 622T114 634',
    0x7B: '504 -207T504 -225T500 -246T476 -250H469Q257 -250 227 -145L225 -135L224 0Q224 15 224 30T224 59T224 84T224 106T223 122T223 133V137Q222 138 221 144T213 162T195 185Q171 206 141 215Q123 222 107 223T84 225T74 229T70 250T73 270T83 276T106 276T141 285Q171 294 195 315Q201 321 206 328T214 341T219 352T222 360L223 363V367Q223 371 223 378T223 394T224 415T224 441T224 470T224 501L225 636Q249 739 426 749Q428 749 443 749T466 750H473Q495 750 499 747T504 725T501 704T480 699Q381 693 357 645Q352 634 351 617T350 497V412Q350 350 338 329Q325 303 298 284T251 258T227 251Q226 251 226 250L227 249Q231 248 238 246T265 236T299 217T329 184T349 137Q350 131 350 3T352 -130Q358 -160 392 -178T480 -199Q497 -200 500 -203',
    0x7C: '160 -249Q138 -249 129 -225V250Q129 725 131 729Q139 750 159 750T190 725V-225Q181 -249 160 -249',
    0x7D: '70 726Q71 744 74 747T99 750H106Q323 750 349 636L350 501Q350 486 350 470T350 441T350 416T350 394T351 378T351 367V363Q352 362 353 356T361 338T379 315Q403 294 433 285Q451 278 467 277T490 275T500 271T504 250T501 230T491 224T468 224T433 215Q403 206 379 185Q373 179 368 172T360 159T355 148T352 140L351 137V133Q351 129 351 122T351 106T350 85T350 59T350 31T350 0L349 -135L347 -145Q317 -250 106 -250H99Q79 -250 75 -247T70 -226Q70 -208 73 -204T95 -199Q193 -193 217 -145Q222 -134 223 -117T224 3Q224 20 224 48T223 86Q223 145 237 175T301 232Q335 249 347 249Q348 249 348 250L347 251Q343 252 336 254T309 264T275 284T245 316T225 363Q224 369 224 497T222 631Q216 660 182 678T95 699Q77 700 74 704T70 726',
    0x7E: '343 202Q320 202 278 225T215 249Q181 249 146 214L134 202L115 219Q111 222 106 226T98 234L96 236Q158 306 165 313Q199 344 230 344Q239 344 244 343Q262 339 300 318T359 297Q393 297 428 332L440 344L459 327Q463 324 468 320T476 312L478 310Q416 240 409 233Q375 202 343 202',
    0xA8: '96 615Q96 650 120 672T178 695Q214 693 234 669T255 615Q255 583 232 559T176 535Q147 535 122 556T96 615ZM319 615Q319 651 343 673T399 695Q426 695 452 675T478 615Q478 578 454 557T395 535Q364 537 342 559T319 615',
    0xAC: '680 371Q683 369 688 366T695 361T698 356T701 346T701 332T702 308V216Q702 196 702 168T703 130Q703 90 697 76T671 61Q650 61 643 81Q641 86 641 198V310H364L87 311Q64 319 64 341Q64 362 84 369Q89 371 385 371H680',
    0xAF: '80 540V607H494V540H80',
    0xB0: '160 618Q160 653 193 677T279 702H284Q381 702 407 647Q414 634 414 618Q414 607 410 596T395 570T355 546T287 536T220 545T181 568T165 594T160 618ZM352 618Q352 645 341 652T301 659H292Q286 659 278 659T268 660Q247 660 236 653T224 638T222 619Q222 591 234 585T287 578Q315 578 326 580T345 590T352 618',
    0xB1: '64 328T64 346T87 377H416V542L417 707Q431 728 443 728Q467 728 475 709Q477 704 477 540V377H807Q808 376 811 374T817 370T823 365T827 358T829 347Q829 326 807 317L642 316H477V25H807Q808 24 811 22T817 18T823 13T827 6T829 -5Q829 -26 807 -35H87Q64 -24 64 -6T87 25H416V316H251L87 317Q64 328 64 346',
    0xB4: '391 706Q419 706 439 683T460 634Q460 608 441 593T366 550Q356 545 351 543L275 503L256 527Q236 552 237 553Q242 558 292 620Q299 629 309 641T324 659T336 673T346 685T354 693T363 699T371 703T380 705T391 706',
    0xB7: '74 251Q74 286 99 311T156 336Q200 336 222 308T245 250Q245 221 224 194T160 166T96 193T74 251',
    0xD7: '168 500Q168 515 178 522T195 530H198Q207 530 218 521T282 458Q312 428 331 409L447 294L563 409Q674 520 682 525Q687 529 695 529Q711 529 718 520T726 499V498Q726 489 720 481T666 427Q631 392 606 367L490 251L606 135Q717 23 721 17T726 2Q726 -9 719 -18T695 -28H692Q685 -28 674 -18T608 47Q581 74 563 92L447 207L331 91Q217 -22 208 -27Q206 -28 203 -28H197Q168 -28 168 2Q168 13 178 24T288 135L404 250L288 366Q177 479 173 485T168 500',
    0xF7: '344 495Q344 535 372 566T447 597Q490 597 519 566T548 495Q548 452 518 423T446 393Q404 393 374 423T344 495ZM87 221Q64 230 64 251T84 279Q89 281 448 281H806Q807 280 810 278T816 274T822 269T826 262T828 251Q828 230 806 221H87ZM344 -36T344 6T373 78T446 108Q487 108 517 79T548 6Q548 -35 519 -65T446 -96Q406 -96 375 -66',
    0x131: '247 0Q232 3 143 3Q132 3 106 3T56 1L34 0H26V46H42Q70 46 91 49Q100 53 102 60T104 102V205V293Q104 345 102 359T88 378Q74 385 41 385H30V408Q30 431 32 431L42 432Q52 433 70 434T106 436Q123 437 142 438T171 441T182 442H185V62Q190 52 197 50T232 46H255V0H247',
    0x237: '28 -163Q58 -168 64 -168Q124 -168 135 -77Q137 -65 137 141T136 353Q132 371 120 377T72 385H52V408Q52 431 54 431L58 432Q62 432 70 432T87 433T108 434T133 436Q151 437 171 438T202 441T214 442H218V184Q217 -36 217 -59T211 -98Q195 -145 153 -175T58 -205Q9 -205 -23 -179T-55 -117Q-55 -94 -40 -79T-2 -64T36 -79T52 -118Q52 -143 28 -163',
    0x2B9: '240 563Q278 563 304 539T331 480V473Q331 462 316 431T217 236Q199 200 174 151T136 78T123 50Q113 33 105 33Q101 33 72 45T38 60Q35 63 35 65Q35 77 101 293T171 517Q182 542 202 552T240 563',
    0x2C6: '207 632L287 694Q289 693 368 632T448 570T431 545T413 520Q410 520 350 559L287 597L224 559Q164 520 161 520Q160 520 143 544T126 570T207 632',
    0x2C7: '131 603Q130 604 136 618T150 646T158 659L223 635L287 611L351 635L416 659Q417 660 424 647T437 619T443 603Q440 601 364 558T287 515T210 558T131 603',
    0x2C9: '80 540V607H494V540H80',
    0x2CA: '391 706Q419 706 439 683T460 634Q460 608 441 593T366 550Q356 545 351 543L275 503L256 527Q236 552 237 553Q242 558 292 620Q299 629 309 641T324 659T336 673T346 685T354 693T363 699T371 703T380 705T391 706',
    0x2CB: '114 634Q114 663 136 684T183 706Q191 706 196 705T208 700T219 693T232 681T245 666T262 645T282 620Q332 558 337 553Q338 552 318 527L299 503L223 543Q215 547 202 553T183 563T167 571T153 580T141 587T131 595T124 603T118 612T115 622T114 634',
    0x2D8: '287 500Q208 500 155 558T102 689V694H153V685Q153 681 154 674T164 648T186 615T226 590T287 578Q347 578 382 611T421 685V694H472V689Q472 623 422 562T287 500',
    0x2D9: '202 610Q202 647 227 671T283 695Q324 695 348 669T372 610T350 551T287 525Q248 525 225 551T202 610',
    0x2DA: '160 618Q160 653 193 677T279 702H284Q381 702 407 647Q414 634 414 618Q414 607 410 596T395 570T355 546T287 536T220 545T181 568T165 594T160 618ZM352 618Q352 645 341 652T301 659H292Q286 659 278 659T268 660Q247 660 236 653T224 638T222 619Q222 591 234 585T287 578Q315 578 326 580T345 590T352 618',
    0x2DC: '343 552Q320 552 278 575T215 599Q181 599 146 564L134 552L115 569Q111 572 106 576T98 584L96 586Q158 656 165 663Q199 694 230 694Q239 694 244 693Q262 689 300 668T359 647Q393 647 428 682L440 694L459 677Q463 674 468 670T476 662L478 660Q416 590 409 583Q375 552 343 552',
    0x300: '-461 634Q-461 663 -439 684T-392 706Q-384 706 -379 705T-367 700T-356 693T-343 681T-330 666T-313 645T-293 620Q-243 558 -238 553Q-237 552 -257 527L-276 503L-352 543Q-360 547 -373 553T-392 563T-408 571T-422 580T-434 587T-444 595T-451 603T-457 612T-460 622T-461 634',
    0x301: '-184 706Q-156 706 -136 683T-115 634Q-115 608 -134 593T-209 550Q-219 545 -224 543L-300 503L-319 527Q-339 552 -338 553Q-333 558 -283 620Q-276 629 -266 641T-251 659T-239 673T-229 685T-221 693T-212 699T-204 703T-195 705T-184 706',
    0x302: '-368 632L-288 694Q-286 693 -207 632T-127 570T-144 545T-162 520Q-165 520 -225 559L-288 597L-351 559Q-411 520 -414 520Q-415 520 -432 544T-449 570T-368 632',
    0x303: '-232 552Q-255 552 -297 575T-360 599Q-394 599 -429 564L-441 552L-460 569Q-464 572 -469 576T-476 584L-479 586Q-417 656 -410 663Q-376 694 -345 694Q-336 694 -331 693Q-313 689 -275 668T-216 647Q-182 647 -147 682L-135 694L-116 677Q-112 674 -107 670T-100 662L-97 660Q-159 590 -166 583Q-200 552 -232 552',
    0x304: '-495 540V607H-81V540H-495',
    0x306: '-288 500Q-367 500 -420 558T-473 689V694H-422V685Q-422 681 -421 674T-411 648T-389 615T-349 590T-288 578Q-228 578 -193 611T-154 685V694H-103V689Q-103 623 -153 562T-288 500',
    0x307: '-373 610Q-373 647 -348 671T-292 695Q-251 695 -227 669T-203 610T-225 551T-288 525Q-327 525 -350 551T-373 610',
    0x308: '-479 615Q-479 650 -456 672T-397 695Q-361 693 -341 669T-320 615Q-320 583 -343 559T-399 535Q-428 535 -453 556T-479 615ZM-256 615Q-256 651 -232 673T-176 695Q-149 695 -123 675T-97 615Q-97 578 -121 557T-180 535Q-211 537 -233 559T-256 615',
    0x30A: '-415 618Q-415 653 -382 677T-296 702H-291Q-194 702 -168 647Q-161 634 -161 618Q-161 607 -165 596T-180 570T-220 546T-288 536T-355 545T-394 568T-410 594T-415 618ZM-223 618Q-223 645 -234 652T-274 659H-283Q-289 659 -297 659T-307 660Q-328 660 -339 653T-351 638T-353 619Q-353 591 -341 585T-288 578Q-260 578 -249 580T-230 590T-223 618',
    0x30B: '-389 511L-442 543Q-442 544 -424 606T-404 674Q-390 705 -361 713Q-360 713 -356 713T-349 714Q-340 714 -330 712Q-273 690 -273 644Q-273 621 -290 604L-342 554L-389 511ZM-198 511L-251 543Q-251 544 -233 606T-213 674Q-199 705 -170 713Q-169 713 -165 713T-158 714Q-127 714 -105 693T-82 647Q-82 638 -84 631T-89 618T-99 604T-112 590T-130 574T-151 554L-198 511',
    0x30C: '-444 603Q-445 604 -439 618T-425 646T-417 659L-352 635L-288 611L-224 635L-159 659Q-158 660 -151 647T-138 619T-132 603Q-135 601 -211 558T-288 515T-365 558T-444 603',
    0x338: '-705 -210Q-715 -210 -724 -203T-734 -179Q-734 -171 -732 -166Q-730 -163 -474 266T-215 698Q-208 711 -190 711Q-180 711 -171 704T-161 681Q-161 672 -164 667Q-171 654 -425 228T-683 -201Q-692 -210 -705 -210',
    0x2002: '',
    0x2003: '',
    0x2004: '',
    0x2005: '',
    0x2006: '',
    0x2009: '',
    0x200A: '',
    0x2013: '0 249V300H574V249H0',
    0x2014: '0 249V300H1149V249H0',
    0x2015: '0 249V300H1149V249H0',
    0x2016: '205 -225Q201 -234 199 -237T191 -244T175 -248T161 -246Q151 -240 146 -229Q145 -224 145 251Q145 725 146 730Q156 750 176 750Q193 748 205 727V-225ZM369 727L372 732Q375 737 377 740T385 747T398 750Q406 750 413 747Q423 740 428 730Q430 720 430 251Q430 -219 428 -229Q423 -240 413 -246Q408 -248 400 -248Q393 -248 388 -247T379 -242T375 -236T371 -230L369 -225V727',
    0x2017: '0 -61V-10H574V-61H0',
    0x2018: '58 461Q58 503 70 542T99 607T134 654T165 684T184 694T201 683T213 664Q213 658 202 648T175 624T143 583T116 518Q115 512 114 505T112 493L111 488Q132 500 161 500Q198 500 221 475T245 414T222 354T161 329Q112 329 85 369T58 461',
    0x2019: '74 572T74 608T97 669T157 694Q203 694 232 657T261 559Q261 520 250 482T222 418T187 370T155 339T135 329Q128 329 117 340T106 359Q106 365 117 375T144 399T176 440T203 505Q204 511 205 518T208 530V535L202 532Q196 530 184 527T158 523Q121 523 98 547',
    0x201C: '110 461Q110 502 121 541T150 606T185 653T217 684T235 694Q242 694 254 682T266 664Q266 659 254 648T226 623T193 578T167 511Q164 500 164 494T164 487Q188 500 212 500Q251 500 274 475T297 414Q297 378 274 354T212 329Q167 329 139 367T110 461ZM377 461Q377 502 388 541T417 606T452 653T484 684T502 694Q509 694 521 682T533 664Q533 659 521 648T493 623T460 578T434 511Q431 500 431 494T431 487Q455 500 479 500Q518 500 541 475T564 414Q564 378 541 354T479 329Q434 329 406 367T377 461',
    0x201D: '38 572T38 608T61 669T121 694Q167 694 196 657T225 559Q225 520 214 482T186 418T151 370T119 339T99 329T82 340T70 360Q70 365 74 369T92 385T122 414Q142 441 154 471T170 518L172 535L166 532Q160 530 148 527T122 523Q85 523 62 547ZM305 572T305 608T328 669T388 694Q434 694 463 657T492 559Q492 520 481 482T453 418T418 370T386 339T366 329T349 340T337 360Q337 365 341 369T359 385T389 414Q409 441 421 471T436 518L439 535L433 532Q427 530 415 527T389 523Q352 523 329 547',
    0x2020: '231 470Q232 471 232 473Q232 477 213 540T193 636Q192 642 192 651T204 677T239 700Q249 702 255 702Q300 702 315 660Q317 653 317 636Q317 603 298 539T279 472V470Q280 470 318 488T383 506Q408 506 423 493T442 467T446 444T443 421T424 396T383 382Q355 382 318 400T279 418Q278 416 285 392T303 334T316 284Q318 268 318 234Q318 149 311 45T296 -127T284 -203Q279 -211 255 -211Q237 -211 233 -210T226 -203Q222 -195 214 -129T199 41T192 234V245Q192 286 212 349Q233 413 231 418Q229 418 192 400T128 382Q102 382 86 396T67 421T64 444T67 466T86 492T128 506Q155 506 192 488T231 470',
    0x2021: '193 637Q193 663 206 679T231 698T255 702T279 699T304 679T317 637Q317 605 299 557T280 504Q280 503 281 503T320 521T382 539Q410 539 428 521T446 476Q446 454 432 434T383 414H377Q358 414 320 431T281 449L280 448Q280 444 298 396T317 316Q318 310 318 301T306 275T271 252Q261 250 255 250Q210 250 195 292Q193 299 193 316Q193 347 211 395T230 448Q230 449 229 449Q227 449 196 434Q151 414 133 414H127Q102 414 87 427T68 452T64 477Q64 503 81 521T127 539Q143 539 164 532T204 515T226 504Q230 502 230 504Q230 508 212 556T193 637ZM193 184Q193 210 206 226T231 245T255 249T279 246T304 226T317 184Q317 153 299 106T280 53Q280 51 282 51T322 68T383 86Q411 86 428 69T445 24T428 -21T382 -39Q358 -39 322 -22T282 -4Q280 -3 280 -3T280 -6Q281 -13 299 -59T317 -136Q318 -142 318 -151T306 -177T271 -200Q261 -202 255 -202Q210 -202 195 -160Q193 -153 193 -136Q193 -106 211 -60T230 -6Q230 -4 228 -4T188 -21T128 -39Q100 -39 83 -22T65 24Q65 53 82 69T127 86Q150 86 187 69T228 51Q230 50 230 50T230 53Q229 58 211 105T193 184',
    0x2022: '64 251Q64 303 80 344T121 409T175 448T230 469T275 474Q277 474 283 474T292 473Q385 473 447 415T510 251Q510 149 449 89T287 28T126 88T64 251',
    0x2026: '74 85Q74 121 99 146T156 171Q200 171 222 143T245 85Q245 56 224 29T160 1Q118 1 96 27T74 85ZM562 85Q562 121 587 146T644 171Q688 171 710 143T733 85Q733 56 712 29T648 1Q606 1 584 27T562 85ZM1050 85Q1050 121 1075 146T1132 171Q1176 171 1198 143T1221 85Q1221 56 1200 29T1136 1Q1094 1 1072 27T1050 85',
    0x2032: '240 563Q278 563 304 539T331 480V473Q331 462 316 431T217 236Q199 200 174 151T136 78T123 50Q113 33 105 33Q101 33 72 45T38 60Q35 63 35 65Q35 77 101 293T171 517Q182 542 202 552T240 563',
    0x203E: '80 540V607H494V540H80',
    0x2044: '451 730Q460 750 479 750Q492 750 501 740T510 718Q508 708 318 244L122 -232Q112 -250 95 -250Q82 -250 73 -241T64 -218Q66 -205 258 261T451 730',
    0x20D7: '406 694Q406 704 413 713T433 723Q448 723 454 719T467 701Q483 665 522 648Q542 637 542 619Q542 605 536 599T514 586Q468 569 431 532Q411 513 399 513Q386 513 378 522T369 543Q369 557 381 568Q385 572 387 574L400 588H228L56 589Q33 598 33 618Q33 636 56 649H426Q406 676 406 694',
    0x210F: '477 56Q477 48 479 46T490 43Q522 45 544 75T577 140Q582 156 585 159T605 162H611H622Q642 162 642 148Q642 138 632 114T602 62T550 13T478 -8Q429 -8 394 17T358 83Q358 95 395 199T433 350Q433 400 394 400H388H383Q335 400 291 363Q256 332 236 298Q233 293 202 170T169 40Q160 18 141 5T99 -8Q70 -8 58 9T45 39Q45 51 116 336L167 540H80V607H184L188 622H184Q183 622 179 622T169 623T157 624T146 624T136 624T131 625Q119 628 119 642Q119 647 123 661T129 679Q133 684 142 685T220 690Q293 694 307 694Q324 694 328 679Q328 673 311 607H494V540H294Q286 507 278 473T264 420L260 403Q260 400 269 408Q327 451 393 451H401H410Q425 451 439 450T476 442T515 424T544 391T556 337Q556 286 517 179T477 56',
    0x2113: '245 -19Q228 -19 212 -16T184 -6T162 9T143 27T129 46T118 66T111 84T106 99T102 111L101 116L69 89L36 62Q31 60 24 62Q-1 88 -1 98Q-1 101 1 105Q1 106 73 170L95 189V197Q95 242 112 317T159 476T241 624T353 701Q357 702 367 702Q428 702 444 641Q446 630 446 606Q446 454 241 246L215 220L212 203Q203 150 203 114Q203 113 203 106T204 95T205 82T209 67T214 54T223 43T236 35T253 32Q277 32 305 44T352 70T389 98T407 112Q409 113 412 113Q420 113 432 95Q445 77 443 70Q440 64 416 44T342 3T245 -19ZM387 615Q387 651 366 651Q342 651 321 604T276 470L241 331Q246 331 280 373T350 486T387 615',
    0x2118: '399 159Q410 159 421 151T433 126Q433 104 410 85Q408 84 410 78Q411 72 414 66T428 51T455 43Q483 43 506 55T543 83T568 125T584 166T594 206Q595 211 596 214Q610 273 610 301Q610 365 542 365H538Q483 365 429 344T337 292T269 229T225 175T210 150L255 99Q261 92 274 78T292 58T305 41T316 22T321 3T324 -23Q324 -87 283 -148T174 -210H171Q161 -210 152 -209T128 -201T101 -180T81 -141T72 -78Q72 -72 72 -60T73 -45Q79 4 102 65L108 81Q84 117 84 167Q84 273 140 367T269 461Q285 461 285 447Q285 440 282 431Q278 418 276 415T264 410Q228 404 201 336T174 219Q174 218 176 202L184 214Q252 303 348 360T549 417Q614 417 658 391T719 317Q726 292 726 260Q726 148 646 70T451 -8Q407 -8 377 17T346 92Q346 159 396 159H399ZM178 -160Q200 -160 216 -132T232 -75Q232 -63 228 -56T203 -26Q196 -18 192 -14Q185 -5 176 5T161 20T156 27L153 28Q151 28 146 8T137 -42T132 -89Q132 -160 178 -160',
    0x2135: '590 427Q581 427 579 433T575 450T568 470V468L532 288L541 281Q620 220 634 165L637 154V124Q637 74 628 46Q623 32 612 16T592 0Q580 0 578 19T569 69T538 121Q532 126 385 240T236 355Q234 355 231 338T225 291T222 237Q222 222 223 213T225 201T228 195T231 190Q238 179 261 160T300 119T316 73Q316 41 291 23T231 1Q226 0 149 0H98Q73 0 69 3T64 24Q64 43 67 47T85 51H89Q119 51 134 55T152 64T154 76Q154 95 125 141T96 220Q96 243 104 270T123 319T145 360T164 391T172 404T150 421T102 468T68 529L65 541V570Q65 620 74 648Q79 664 91 679T111 694Q122 694 123 675T132 625T164 573Q168 569 319 452T471 335Q471 337 486 409T502 488Q502 489 491 493T467 511T448 546V573Q448 602 452 624T462 659T474 680T486 691T493 694Q499 694 502 691T507 682T513 673Q517 667 534 651T557 630Q558 629 590 616T631 587Q638 577 638 543Q637 489 622 458T590 427',
    0x2190: '1063 281Q1084 268 1084 251Q1084 231 1063 221L649 220H235Q340 133 364 17Q368 1 368 -2Q368 -16 343 -17Q340 -17 338 -17H332Q317 -17 314 -14T305 6Q298 34 285 62T247 126T179 189T78 233Q64 237 64 251Q64 261 74 265T108 277T154 297Q212 328 251 379T305 495Q309 511 313 514T333 518H338Q363 517 367 510Q368 507 368 503Q368 500 364 484Q345 401 287 331Q254 295 235 282L649 281H1063',
    0x2191: '33 396Q14 396 14 423Q14 445 18 449T41 459Q72 466 99 478T147 505T185 537T215 571T238 604T254 635T264 661T270 678L272 685Q276 694 288 694Q298 692 300 689T307 672Q331 592 392 535T535 459Q553 454 557 450T561 423Q561 396 542 396Q531 396 501 405T418 443T329 517L319 529L318 179V-171Q307 -193 288 -193Q265 -193 258 -171L257 179V529L247 517Q209 473 158 444T76 405T33 396',
    0x2192: '65 251Q65 270 87 281H500L914 282Q881 304 842 357T785 482Q781 500 781 501Q781 512 792 517Q794 518 812 518H817Q832 518 835 515T844 495Q864 412 923 351T1065 270Q1085 263 1085 251Q1085 240 1077 236T1044 225T995 204Q937 173 898 122T844 6Q840 -10 836 -13T816 -17H811Q786 -16 782 -9Q781 -6 781 -2Q781 1 785 17Q813 138 914 220H500L87 221Q65 228 65 251',
    0x2193: '14 77Q14 104 33 104Q44 104 74 96T156 57T247 -17L257 -29V321Q257 669 259 675Q268 694 289 694Q295 693 300 691T307 686T313 679T318 672V321L319 -29L329 -17Q366 26 417 55T499 94T542 104Q561 104 561 77Q561 56 557 51T535 41Q481 28 438 0T370 -58T330 -119T308 -167T302 -187Q297 -194 288 -194Q278 -194 273 -186T265 -165T251 -127T220 -77Q151 15 41 41Q22 46 18 50T14 77',
    0x2194: '305 495Q309 511 313 514T333 518H338Q363 517 367 510Q368 507 368 503Q368 500 364 484Q345 401 287 331Q254 295 235 282L404 281H744L914 282Q880 305 839 362T785 484Q781 500 781 503Q781 517 806 518Q809 518 811 518H817Q832 518 835 515T844 495Q864 412 923 351T1065 270Q1085 263 1085 251Q1085 240 1077 236T1044 225T995 204Q937 173 898 122T844 6Q840 -10 836 -13T816 -17H811Q786 -16 782 -9Q781 -6 781 -2Q781 1 785 17Q813 138 914 220H235Q340 133 364 17Q368 1 368 -2Q368 -16 343 -17Q340 -17 338 -17H332Q317 -17 314 -14T305 6Q298 34 285 62T247 126T179 189T78 233Q64 237 64 251Q64 261 74 265T108 277T154 297Q212 328 251 379T305 495',
    0x2195: '33 469Q14 469 14 496Q14 518 18 522T41 532Q121 551 182 608T268 745Q275 767 288 767Q299 767 303 755T320 713T355 650Q424 558 535 532Q553 527 557 523T561 496Q561 469 542 469Q531 469 501 478T418 516T329 590L319 602L318 426V74L319 -102L329 -90Q366 -47 417 -18T499 21T542 31Q561 31 561 4Q561 -17 557 -22T535 -32Q454 -51 393 -108T307 -245Q300 -267 288 -267Q279 -267 274 -259T266 -238T250 -200T220 -150Q151 -58 41 -32Q22 -27 18 -23T14 4Q14 31 33 31Q44 31 74 23T156 -16T247 -90L257 -102V602L247 590Q209 546 158 517T76 478T33 469',
    0x2196: '429 724Q438 724 452 711T466 690Q466 681 452 671Q361 602 242 599H218L756 135Q832 69 913 -1T1036 -108L1077 -143Q1084 -151 1084 -163Q1084 -180 1074 -187T1054 -194H1052Q1043 -194 939 -105Q866 -42 812 5Q180 549 178 549V546Q179 542 179 539Q183 520 183 483Q183 435 172 390T149 323T130 296Q121 292 115 295Q85 312 85 328Q85 331 95 350T115 406T125 486Q125 540 110 583T79 648T64 675Q64 681 68 687T81 693Q87 693 94 690Q162 657 232 657Q296 657 349 681T420 722Q422 724 429 724',
    0x2197: '1069 693Q1076 693 1080 687T1085 675Q1085 671 1076 656T1055 621T1034 565T1024 486Q1024 442 1034 406T1054 351T1064 328Q1064 321 1057 313T1042 300L1034 295Q1030 293 1027 293Q1023 293 1020 295T1014 301T1009 308T1005 316T1001 324Q980 368 971 419Q966 442 966 484V492Q966 528 972 553Q971 553 757 368T328 -3T107 -191Q103 -193 94 -193Q78 -193 71 -184T64 -164Q64 -153 72 -143Q79 -136 382 124L934 599H909Q837 599 760 634T683 690Q683 697 696 710T722 724Q726 724 742 714T779 691T838 668T920 657Q959 657 991 666T1043 684T1069 693',
    0x2198: '65 663Q65 680 74 687T93 694H96Q104 694 118 683T204 610Q280 545 338 495Q969 -49 971 -49L970 -46Q970 -42 970 -39Q966 -20 966 18Q966 65 977 110T1001 177T1019 204Q1028 208 1034 205Q1064 188 1064 172Q1064 169 1054 150T1034 94T1024 14Q1024 -28 1033 -64T1054 -120T1075 -155T1085 -175Q1085 -181 1081 -187T1068 -193Q1062 -193 1055 -190Q987 -157 919 -157Q817 -157 739 -215Q727 -224 720 -224Q712 -224 697 -210Q683 -199 683 -190T697 -171Q788 -102 907 -99H931L393 365Q317 431 236 501T114 608L72 643Q65 651 65 663',
    0x2199: '80 -193Q73 -193 69 -187T64 -175Q64 -172 79 -150T109 -84T125 14Q125 58 115 94T95 149T85 172Q85 179 92 187T108 200L115 205Q119 207 122 207Q126 207 129 205T135 199T140 192T144 184T148 176Q169 132 178 81Q183 58 183 17Q183 -7 182 -24T178 -48L177 -53Q178 -53 389 129T816 498T1043 692Q1049 694 1054 694Q1070 694 1077 684T1085 664Q1085 653 1077 643Q1070 636 767 376L215 -98L240 -99Q312 -99 389 -134T466 -190Q466 -197 452 -210T429 -224Q422 -224 411 -215Q330 -157 229 -157Q190 -157 158 -166T106 -184T80 -193',
    0x21A6: '65 426Q74 448 95 448Q112 448 125 426V281H500L914 282Q881 304 842 357T785 482Q781 500 781 501Q781 512 792 517Q794 518 812 518H817Q832 518 835 515T844 495Q864 412 923 351T1065 270Q1085 263 1085 251Q1085 240 1077 236T1044 225T995 204Q937 173 898 122T844 6Q840 -10 836 -13T816 -17H811Q786 -16 782 -9Q781 -6 781 -2Q781 1 785 17Q813 138 914 220H500L135 221Q134 221 133 221T130 220H125V76Q115 54 95 54Q73 54 65 76V426',
    0x21A9: '1029 475Q1029 505 1068 505Q1129 501 1173 463T1218 363Q1218 296 1170 259T1066 221H1063L649 220H235Q340 133 364 17Q368 1 368 -2Q368 -16 343 -17Q340 -17 338 -17H332Q317 -17 314 -14T305 6Q298 34 285 62T247 126T179 189T78 233Q64 237 64 251Q64 261 74 265T108 277T154 297Q212 328 251 379T305 495Q309 511 313 514T333 518H338Q363 517 367 510Q368 507 368 503Q368 500 364 484Q345 401 287 331Q254 295 235 282L649 281H1063Q1105 284 1131 305T1158 361Q1158 385 1146 401Q1122 441 1063 444Q1029 446 1029 475',
    0x21AA: '225 221H218Q159 221 112 260T65 363Q65 431 116 468T221 505Q254 503 254 474Q254 456 245 450T216 443T188 438Q152 427 137 401Q125 385 125 362Q125 334 147 310Q171 288 221 281H632L1046 282Q1013 304 974 357T917 482Q913 500 913 501Q913 512 924 517Q926 518 944 518H949Q964 518 967 515T976 495Q996 412 1055 351T1197 270Q1217 263 1217 251Q1217 240 1209 236T1176 225T1127 204Q1069 173 1030 122T976 6Q972 -10 968 -13T948 -17H943Q918 -16 914 -9Q913 -6 913 -2Q913 1 917 17Q945 138 1046 220H632L225 221',
    0x21BC: '1063 281Q1084 268 1084 251Q1084 231 1063 221L572 220Q79 220 77 221Q64 225 64 244Q64 250 64 254T67 261T71 265T78 268T85 272Q142 302 189 345T258 421T296 484T315 516Q319 518 337 518Q358 518 363 512Q370 504 367 496Q360 469 319 404T219 290L209 282L636 281H1063',
    0x21BD: '1063 281Q1084 268 1084 251Q1084 231 1063 221L636 220H209L219 212Q278 162 319 97T367 5Q370 -3 363 -11Q358 -17 337 -17H332Q318 -17 314 -14T302 7Q278 55 246 95T185 160T130 202T88 228L70 237Q64 243 64 257Q64 274 75 279Q78 281 571 281H1063',
    0x21C0: '65 251Q65 270 87 281H513L940 282L930 290Q871 338 830 403T782 496Q779 510 791 517Q794 518 812 518H817Q831 518 835 515T847 494Q871 445 903 404T966 338T1022 298T1064 272T1083 259Q1085 255 1085 245Q1085 225 1072 221Q1070 220 578 220L87 221Q65 228 65 251',
    0x21C1: '84 279Q89 281 580 281Q1070 281 1074 279Q1085 275 1085 256Q1085 245 1083 241T1066 230Q919 153 847 7Q839 -11 835 -14T817 -17H812Q791 -17 786 -11Q779 -3 782 5Q789 31 830 96T930 212L940 220H513L87 221Q64 229 64 250Q64 272 84 279',
    0x21CC: '65 451Q65 470 87 481H513L940 482L930 490Q871 538 830 603T782 696Q779 710 791 717Q794 718 812 718H817Q831 718 835 715T847 694Q871 645 903 604T966 538T1022 498T1064 472T1083 459Q1085 455 1085 445Q1085 425 1072 421Q1070 420 578 420L87 421Q65 428 65 451ZM1063 281Q1084 268 1084 251Q1084 231 1063 221L636 220H209L219 212Q278 162 319 97T367 5Q370 -3 363 -11Q358 -17 337 -17H332Q318 -17 314 -14T302 7Q278 55 246 95T185 160T130 202T88 228L70 237Q64 243 64 257Q64 274 75 279Q78 281 571 281H1063',
    0x21D0: '1063 169L1068 166Q1072 163 1074 162T1079 157T1083 149T1085 139Q1085 118 1063 109L733 108H404L412 99Q455 50 488 -10Q498 -27 493 -37Q487 -46 465 -46H460Q446 -46 439 -39T426 -18T399 25T344 89Q239 194 99 229Q96 230 92 231T85 232T79 234T73 235T69 237T66 240T65 244T64 250Q64 267 90 271Q197 295 286 361T430 525Q439 542 442 544T460 547H465Q487 547 492 539Q496 531 496 530Q496 521 471 482T414 405L404 394L733 393H1063Q1064 392 1069 389T1076 384T1082 375T1085 362Q1085 344 1063 333L700 332H338L324 321Q283 290 230 264L205 250Q266 224 323 180L338 170L700 169H1063',
    0x21D1: '672 343Q672 326 670 320T657 313Q644 313 602 335Q577 349 557 361T527 381T509 395T499 403T495 406T494 118Q494 -166 492 -174Q484 -193 465 -193H463Q456 -193 453 -192T444 -186T433 -170V465L423 477Q407 495 394 514T367 554T351 579Q349 576 339 560T313 520T279 477L269 465V-22V-102Q269 -132 269 -145T268 -169T266 -180T260 -185T253 -191Q248 -193 239 -193H237Q218 -193 210 -174Q208 -166 208 118Q208 406 207 406L199 399Q191 392 165 374T100 335Q58 313 45 313Q35 313 33 319T30 343V349Q30 359 30 362T35 369T45 374T66 383T100 401Q267 499 333 680Q339 694 351 694Q361 694 365 687T380 652T407 597Q442 536 489 489T573 420T638 383T670 365Q672 361 672 343',
    0x21D2: '64 362Q64 380 87 393H416L745 394L735 405Q708 436 681 477T654 531Q654 547 679 547H684H689Q703 547 710 540T723 519T750 475T806 411Q914 303 1059 271Q1060 271 1063 270T1068 269T1072 268T1076 266T1079 264T1082 260T1083 256T1084 250Q1084 242 1080 238T1063 231T1035 225T992 211T934 185Q797 112 719 -24Q710 -40 706 -43T689 -46H684Q653 -46 653 -31Q653 -24 661 -10Q694 50 737 99L745 108H416L87 109Q64 117 64 139Q64 156 87 169H449L812 170L826 180Q842 193 860 204T892 223T918 237T937 246L944 250L919 264Q866 290 825 321L811 332H449L87 333Q64 343 64 362',
    0x21D3: '30 157Q30 174 32 180T46 187Q59 187 104 163Q154 136 198 101L207 94Q208 94 208 382Q208 666 210 674Q219 694 241 694Q254 692 262 683Q266 679 267 674Q269 658 269 522V35L279 23Q295 5 308 -14T335 -54T351 -79Q353 -76 363 -60T389 -20T423 23L433 35V671Q439 682 444 686T452 692T463 693H465Q484 693 492 674Q494 666 494 382Q494 94 495 94L504 101Q547 135 593 160T652 187Q665 191 671 177Q672 175 672 157Q672 137 669 134T636 116Q606 101 578 83T528 47T486 9T452 -30T424 -68T403 -103T387 -134T377 -159T370 -176L367 -184Q360 -194 351 -194Q345 -194 342 -192T334 -182T327 -166T315 -137T295 -97Q260 -36 213 11T129 80T63 117T32 136Q30 139 30 157',
    0x21D4: '336 497Q358 541 363 544Q367 547 379 547H384Q401 547 405 545Q418 538 414 525T389 474T346 408L335 393H814L803 408Q781 436 760 474T735 525T744 545Q748 547 765 547Q771 547 774 547T780 546T786 544T790 541T794 535T799 527T805 514T813 497Q841 446 877 406T950 340T1014 301T1068 276L1096 265Q1102 259 1102 251Q1102 240 1085 232Q981 195 902 121Q835 56 798 -25Q791 -40 787 -43T765 -46T744 -44Q735 -40 735 -30Q735 -15 760 28T806 98L814 108H335L343 98Q361 75 378 46T404 -1T414 -24Q418 -37 405 -44Q401 -46 384 -46T363 -43T351 -25Q314 56 247 121Q216 150 182 173T125 206T79 226T53 237Q47 243 47 251Q47 254 47 256T49 261T52 264T57 267T61 268T66 270T71 272Q246 335 336 497ZM985 251Q932 280 882 323L871 332H278Q264 321 253 311T237 297T214 282T164 251L176 244Q221 218 278 169H871Q928 218 973 244L985 251',
    0x21D5: '49 441Q30 441 30 464V471V480Q30 498 44 502Q237 573 331 750Q337 767 351 767Q360 767 368 753T400 702T460 629Q504 584 552 554T632 511T666 497Q672 493 672 471Q672 454 670 449Q664 441 653 441Q639 443 591 465T508 513L495 522L494 386V114L495 -22L508 -13Q543 12 591 34T653 59Q672 59 672 36V29V20Q672 2 658 -2Q465 -71 367 -257Q360 -267 351 -267Q343 -267 336 -257T320 -231T292 -187T242 -129Q198 -84 150 -54T70 -11T36 3Q30 7 30 29Q30 46 32 51Q38 59 49 59Q63 57 111 35T194 -13L208 -22V522L194 513Q159 488 111 466T49 441ZM422 584Q411 594 400 606T383 626T366 648T351 667Q349 665 339 652T314 620T280 584L269 573V-73L280 -84Q305 -108 351 -166Q353 -164 363 -151T389 -119T422 -84L433 -73V573L422 584',
    0x2200: '1 664Q-2 685 23 693H27Q46 693 54 680T102 578L148 475H492L533 570Q541 586 548 603T560 630T569 650T576 667T582 678T588 686T594 691T600 693T609 694Q622 694 631 684T639 662Q637 653 492 325T341 -8Q333 -16 320 -16Q306 -16 298 -8Q294 -4 147 326L1 656V664ZM464 414H319Q175 414 175 413L319 88L464 414',
    0x2203: '81 347Q81 359 84 363T104 378H513V633H300L87 634Q64 642 64 664Q64 685 84 692Q89 694 321 694H552Q571 681 574 669V25Q567 7 552 1H87Q64 12 64 30T87 61H513V317H308Q103 317 99 319Q81 328 81 347',
    0x2205: '285 711Q307 711 326 708T357 701T370 698Q371 698 375 710T383 735T389 750Q395 767 415 767Q431 767 438 757T446 738T436 701T426 670Q426 668 433 664Q468 633 489 588Q511 542 519 488T528 344Q528 286 524 243T508 150T466 63T394 6Q345 -17 287 -17Q265 -17 246 -14T216 -7T203 -4Q191 -47 183 -60T159 -73Q146 -73 137 -63T128 -44Q128 -38 138 -7L148 24L141 30Q134 35 120 49Q94 77 78 113T56 194T48 268T46 344Q46 388 47 416T56 494T78 577T122 644T194 694Q239 711 285 711ZM351 639Q350 639 346 642T337 648T325 654T306 658T283 660Q254 660 221 638T181 567Q171 513 171 375Q171 164 182 129L351 639ZM402 356Q402 516 395 555Q395 557 395 559T394 563T394 566L393 568L223 57Q252 34 286 34H288Q318 34 346 53T387 109Q402 152 402 329V356',
    0x2206: '901 12Q901 7 892 0H479Q65 0 62 2Q56 6 56 11Q56 14 242 347T433 685Q438 694 450 696Q454 698 480 698H506L523 687Q526 683 711 354T899 17Q901 13 901 12ZM653 137L427 538L202 137L315 136H540L653 137',
    0x2208: '97 251Q97 393 194 484T417 586Q418 586 436 586T482 586T538 587H648Q649 586 652 584T658 580T664 575T668 568T670 557Q670 536 648 527L534 526Q515 526 491 526T457 526T435 526T417 525T404 523T390 521T374 517Q298 498 243 447T167 324Q159 295 159 283Q159 281 403 281H648Q649 280 652 278T658 274T664 269T668 262T670 251Q670 230 648 221L403 220Q159 220 159 218Q159 206 166 182T190 122T247 50T341 -6Q380 -20 405 -22T534 -25H648Q649 -26 654 -29T661 -34T667 -43T670 -56Q670 -74 648 -85L541 -86Q419 -86 396 -82Q276 -65 187 24T97 251',
    0x2209: '126 -210Q116 -210 107 -203T97 -179Q97 -171 99 -166Q99 -165 111 -145T150 -80T203 8Q97 104 97 251Q97 393 194 484T417 586Q418 586 436 586T482 586T538 587H549Q565 614 582 643T608 685L616 698Q623 711 641 711Q651 711 660 704T670 681Q670 672 667 667Q666 666 661 657T644 627T620 587H648Q649 586 652 584T658 580T664 575T668 568T670 557Q670 536 648 527L584 526L437 281H648Q649 280 652 278T658 274T664 269T668 262T670 251Q670 230 648 221L403 220H401L283 23Q311 5 341 -6Q380 -20 405 -22T534 -25H648Q649 -26 654 -29T661 -34T667 -43T670 -56Q670 -74 648 -85L541 -86Q419 -86 396 -82Q320 -71 252 -29Q152 -197 148 -201Q139 -210 126 -210ZM235 62L330 220Q159 219 159 218Q159 196 176 150T235 62ZM366 281L513 526Q503 526 487 526T465 526T448 525T433 525T422 525T412 524T403 523T394 521T385 519T374 517Q298 498 243 447T167 324Q159 295 159 283Q159 281 366 281',
    0x220B: '96 251Q96 268 119 281H363Q607 281 607 283Q607 295 600 319T576 379T519 451T425 507Q386 521 361 523T233 526L119 527Q96 535 96 557Q96 578 116 585Q121 587 229 587Q238 587 257 587T288 588Q366 588 435 568T568 488Q670 388 670 251Q670 155 621 78T499 -39T345 -85Q336 -86 225 -86L119 -85Q96 -77 96 -55Q96 -38 119 -25H233Q356 -24 371 -21Q373 -21 393 -16Q468 3 523 55T599 177Q607 206 607 218Q607 220 363 220L119 221Q96 229 96 251',
    0x2212: '119 221Q96 230 96 251T116 279Q121 281 448 281H775Q776 280 779 278T785 274T791 269T795 262T797 251Q797 230 775 221H119',
    0x2213: '64 155Q64 172 87 185H416V476H251L87 477Q64 485 64 507Q64 528 84 535Q89 537 448 537H807Q808 536 811 534T817 530T823 525T827 518T829 507Q829 486 807 477L642 476H477V185H807Q808 184 811 182T817 178T823 173T827 166T829 155Q829 134 807 125L642 124H477V-39Q477 -203 475 -208Q466 -227 446 -227Q427 -227 417 -205L416 -41V124H251L87 125Q64 133 64 155',
    0x2215: '451 730Q460 750 479 750Q492 750 501 740T510 718Q508 708 318 244L122 -232Q112 -250 95 -250Q82 -250 73 -241T64 -218Q66 -205 258 261T451 730',
    0x2216: '64 718Q63 731 72 740T94 750Q106 750 113 743Q118 741 122 732L318 256Q508 -208 510 -218Q511 -231 502 -240T480 -250Q460 -250 451 -230Q451 -229 259 238T64 718',
    0x2217: '236 431Q237 447 251 459T287 472T323 459T338 431Q338 423 328 363L317 300Q318 300 340 317T392 356T435 387Q442 390 450 390Q470 390 485 374T501 335Q501 326 500 320T494 309T486 300T473 293T458 287T438 280T414 272L353 250L414 228Q422 225 436 221T457 214T472 208T485 201T493 192T499 181T501 166Q501 141 484 126T450 111Q447 111 445 111T441 111T437 112T433 114T428 117T422 121T414 127T404 135T391 145T374 158L317 200L328 137Q338 77 338 69Q336 52 321 40T287 28T253 40T236 69Q236 77 246 137L257 200Q256 200 234 183T182 144T139 113Q132 110 124 110Q104 110 89 126T73 165Q73 174 74 180T80 191T88 200T101 207T116 213T136 220T160 228L221 250L160 272Q152 275 138 279T117 286T102 292T89 299T81 308T75 319T73 334Q73 359 90 374T124 389Q127 389 129 389T133 389T137 388T141 386T146 383T152 379T160 373T170 365T183 355T200 342L257 300L246 363Q236 423 236 431',
    0x2218: '64 251Q64 303 80 344T121 409T175 448T230 469T275 474Q277 474 283 474T292 473Q385 473 447 415T510 251Q510 149 449 89T287 28T126 88T64 251ZM448 251Q448 325 405 369T286 413Q215 413 171 371T126 251Q126 177 168 133T287 89Q361 89 404 132T448 251',
    0x2219: '64 251Q64 303 80 344T121 409T175 448T230 469T275 474Q277 474 283 474T292 473Q385 473 447 415T510 251Q510 149 449 89T287 28T126 88T64 251',
    0x221A: '107 178Q100 178 89 188T78 207Q78 216 84 220Q85 221 124 248T207 304T260 338Q269 340 275 335Q276 334 370 156L463 -20L698 393Q928 800 935 811Q944 820 954 820Q972 820 980 811T988 789Q988 781 858 553Q776 409 718 306Q452 -166 447 -171Q439 -179 422 -180Q405 -180 400 -175Q399 -174 346 -73T241 128T187 229L151 205Q111 178 107 178',
    0x221D: '65 222Q65 282 88 329T144 401T208 438T261 451H273Q312 451 320 450Q456 431 526 330L537 316Q638 451 778 451Q813 451 830 445V388Q821 391 799 391Q758 391 721 377T660 342T618 301T592 266L584 251Q648 152 697 114Q748 74 804 74H806Q823 74 829 77Q830 77 830 38V-1L820 -3Q801 -7 786 -7H771Q699 -7 632 25T527 114L516 128Q414 -8 276 -8Q192 -8 129 56T65 222ZM256 53Q296 53 332 67T392 102T434 143T461 178L469 193Q405 292 356 330Q308 369 251 369H243Q196 369 156 328T116 221Q116 191 124 161T158 99T225 55Q234 53 256 53',
    0x221E: '65 219Q65 318 132 385T302 452Q473 452 573 331L589 312L596 320Q710 452 857 452Q948 452 1016 386T1084 225Q1084 125 1017 59T848 -8Q679 -8 576 113L560 132L553 124Q439 -8 292 -8Q200 -8 133 58T65 219ZM1033 224Q1033 291 987 340T875 389Q748 389 648 261Q641 253 642 251Q717 163 748 137Q813 81 880 81Q941 81 987 120T1033 224ZM275 56Q315 56 353 70T418 104T466 144T497 178L507 192Q507 193 474 230T441 269Q355 362 267 362Q210 362 163 324T116 221Q116 150 162 103T275 56',
    0x2220: '71 0L68 2Q65 3 63 5T58 11T55 20Q55 21 56 23V25Q55 27 55 30Q55 31 56 33V35Q55 37 55 40Q55 42 57 48Q67 63 346 381Q421 467 518 578Q607 680 623 697T647 714Q656 714 661 708T666 694V692Q676 687 676 674Q676 668 673 663Q672 662 637 622T534 503T400 350L147 61L386 60H653Q666 50 666 40V38Q676 31 676 20Q676 8 661 0H71',
    0x2223: '160 -249Q138 -249 129 -225V250Q129 725 131 729Q139 750 159 750T190 725V-225Q181 -249 160 -249',
    0x2225: '205 -225Q201 -234 199 -237T191 -244T175 -248T161 -246Q151 -240 146 -229Q145 -224 145 251Q145 725 146 730Q156 750 176 750Q193 748 205 727V-225ZM369 727L372 732Q375 737 377 740T385 747T398 750Q406 750 413 747Q423 740 428 730Q430 720 430 251Q430 -219 428 -229Q423 -240 413 -246Q408 -248 400 -248Q393 -248 388 -247T379 -242T375 -236T371 -230L369 -225V727',
    0x2227: '95 -16Q78 -16 71 -6T64 14Q64 20 65 22L212 308Q359 593 361 595Q370 604 385 604Q398 602 405 595Q407 593 554 308L701 22Q702 20 702 15Q702 1 693 -8T671 -17Q661 -17 651 -9Q647 -5 515 251L383 506L251 251Q119 -5 116 -8Q108 -16 95 -16',
    0x2228: '64 572Q64 585 72 594T94 604T116 595Q119 592 251 336L383 81L515 336Q647 592 651 596Q661 604 671 604Q684 604 693 595T702 572Q702 567 701 565L554 279Q407 -6 405 -8Q404 -9 401 -11T397 -14Q392 -16 383 -16H380Q369 -16 361 -8Q359 -6 212 279L65 565Q65 566 65 568T64 572',
    0x2229: '94 -16Q73 -16 64 8V209Q64 239 64 287Q65 418 69 432Q70 434 70 435Q84 487 125 523T216 575T299 597T354 603H372Q444 603 501 590T591 558T648 515T681 471T696 435Q696 434 697 432Q701 417 702 309Q702 303 702 287Q702 239 702 209V8Q693 -16 672 -16Q650 -16 643 3Q641 8 641 201Q641 397 640 403Q631 472 558 507T383 542Q339 542 298 535T219 511T156 468T126 403Q125 397 125 201Q125 8 123 3Q116 -16 94 -16',
    0x222A: '672 603Q693 603 702 579V378Q702 348 702 300Q701 169 697 155Q696 153 696 152Q676 78 593 31T383 -16Q265 -16 179 28T70 152Q70 153 69 155Q65 170 64 278Q64 285 64 300Q64 348 64 378Q64 579 65 583Q74 604 94 604T123 584Q125 579 125 386Q125 190 126 184Q135 115 210 80T383 44Q426 44 467 51T546 75T609 119T640 184Q641 190 641 386Q641 579 643 584Q650 603 672 603',
    0x222B: '204 -71Q204 -108 181 -124T137 -141Q132 -141 132 -142Q142 -161 154 -161Q164 -161 186 -152Q200 -145 210 -135T228 -107T241 -77T249 -38T254 -2T258 38T262 74Q282 265 334 489Q334 490 337 503T341 523T347 544T355 569T365 594T379 620T397 643T420 666T447 685T481 700Q511 711 539 711T587 696T616 656T628 612T632 573Q632 536 610 519T562 501Q534 501 513 519T492 571Q492 608 515 624T559 641Q564 641 564 642Q554 661 542 661Q532 661 510 652Q496 645 486 635T468 607T455 577T447 538T442 502T438 462T434 426Q414 235 362 11Q352 -35 347 -54T328 -101T291 -152Q235 -208 162 -211Q147 -211 136 -208T109 -196T83 -165T67 -108Q64 -94 64 -73Q64 -37 86 -19T134 -1Q162 -1 183 -19T204 -71',
    0x223C: '64 155Q64 210 84 262T150 353T257 391Q300 391 341 371T417 321T484 264T557 215T637 194Q702 194 745 244T788 367Q796 391 808 391Q815 391 821 381T828 353V342Q828 252 776 181T637 109Q594 109 552 129T476 179T409 236T336 285T256 306Q193 306 149 258T105 132Q98 109 86 109Q76 109 70 122T64 155',
    0x2240: '64 561Q64 570 76 576T108 583Q174 583 214 535T254 407Q254 368 238 324T202 248T166 173T149 92Q149 43 169 2T217 -39Q231 -40 242 -46T254 -60Q254 -69 241 -75T210 -82Q145 -82 105 -34T64 93Q64 133 80 177T116 253T152 328T169 408Q169 461 148 500T105 540Q92 540 78 545T64 561',
    0x2243: '64 295Q64 378 117 440T257 502Q298 502 339 485T416 443T486 394T560 352T637 335Q693 335 740 373T788 478Q796 502 808 502Q815 502 821 492T828 465V455Q828 365 771 308T640 250Q603 250 562 265T501 294T439 336L370 382Q308 417 256 417Q205 417 164 388T110 317Q110 316 109 304T107 286T103 270T97 255T86 250Q76 250 70 263T64 295ZM64 6T64 27T87 56H93Q99 56 110 56T137 56T173 56T217 56T267 57T323 57T383 57T448 57H807Q808 56 811 54T815 52T819 49T823 45T826 40T828 34T829 27Q829 7 807 -3H87Q64 6 64 27',
    0x2245: '64 402Q64 457 84 509T150 600T257 638Q300 638 341 618T417 569T484 511T557 462T637 441Q702 441 745 491T788 614Q796 638 808 638Q815 638 821 628T828 600V589Q828 499 776 428T637 356Q594 356 552 376T476 425T409 483T336 532T256 553Q193 553 149 505T105 379Q98 356 86 356Q76 356 70 369T64 402ZM87 197Q64 207 64 226Q64 247 84 255Q89 257 448 257H807Q808 256 811 254T817 250T823 245T827 238T829 227Q829 209 807 197H87ZM87 -27Q64 -18 64 3Q64 23 86 32Q89 33 448 33H807L812 30Q816 27 818 26T823 21T827 13T829 3Q829 -18 807 -27H87',
    0x2248: '64 345Q64 423 119 473T250 524Q301 524 356 503T451 455T542 407T636 385Q700 385 743 417T786 481Q786 493 791 508T807 524Q817 524 823 512T829 479Q829 404 776 352T638 300Q590 300 537 321T443 369T352 417T256 439Q207 439 166 417T110 359Q109 357 107 341T100 312T85 300Q77 300 71 313T64 345ZM64 77Q64 155 119 205T250 256Q302 256 357 235T451 187T541 139T636 117Q699 117 742 148T786 213Q786 231 792 243T808 256T823 242T829 208Q829 134 776 83T640 32Q591 32 537 53T443 101T352 149T256 171Q206 171 165 148T110 91Q109 89 107 73T100 44T85 32Q77 32 71 45T64 77',
    0x224D: '798 533Q812 533 820 524T829 502T819 480T769 440Q655 355 537 330Q492 322 447 322Q401 322 356 330Q289 344 219 381T118 443T73 481Q64 490 64 503Q64 517 72 525T94 533Q99 533 102 532Q107 531 138 507T209 456T314 405T446 382Q604 382 765 515Q788 533 798 533ZM95 -32Q81 -32 73 -23T64 -1Q64 10 74 21T124 61Q213 127 293 153T421 179L422 180Q424 180 426 180T432 180T441 180T452 179Q612 179 769 61Q811 29 820 19T829 -1Q829 -14 821 -23T798 -32Q788 -32 765 -14Q608 118 446 118Q287 118 128 -14Q105 -32 95 -32',
    0x2250: '87 333Q64 343 64 362Q64 383 84 391Q89 393 448 393H807Q808 392 811 390T817 386T823 381T827 374T829 363Q829 345 807 333H87ZM87 109Q64 118 64 139Q64 159 86 168Q89 169 448 169H807L812 166Q816 163 818 162T823 157T827 149T829 139Q829 118 807 109H87ZM362 635Q362 671 387 696T444 721Q488 721 510 693T533 635Q533 606 512 579T448 551Q406 551 384 577T362 635',
    0x2260: '189 -210Q179 -210 170 -203T160 -179Q160 -171 162 -166Q165 -163 327 109H87Q64 118 64 139Q64 159 86 168Q89 169 363 169L461 333H87Q64 343 64 362Q64 383 84 391Q89 393 448 393H496Q533 455 583 539T656 660T679 698Q686 711 704 711Q714 711 723 704T733 681Q733 672 730 667Q729 664 709 631T645 523T567 393H807Q808 392 811 390T817 386T823 381T827 374T829 363Q829 345 807 333H532L433 169H807L812 166Q816 163 818 162T823 157T827 149T829 139Q829 118 807 109H398Q217 -195 211 -201Q202 -210 189 -210',
    0x2261: '87 445Q64 454 64 475Q64 497 84 503Q89 505 448 505H807Q808 504 812 502T818 497T823 492T827 484T829 474Q829 456 807 445H87ZM87 221Q64 230 64 251T84 279Q89 281 448 281H807Q808 280 811 278T817 274T823 269T827 262T829 251Q829 230 807 221H87ZM64 6T64 27T87 56H93Q99 56 110 56T137 56T173 56T217 56T267 57T323 57T383 57T448 57H807Q808 56 811 54T815 52T819 49T823 45T826 40T828 34T829 27Q829 7 807 -3H87Q64 6 64 27',
    0x2264: '797 55Q797 45 790 35T767 25H759L434 180Q108 336 105 339Q96 348 96 360Q96 378 114 388Q126 394 439 544T757 695Q763 697 766 697Q780 697 788 688T797 666Q797 654 788 645Q784 641 507 509T197 361L466 232Q785 80 790 74Q797 66 797 55ZM119 -199Q96 -191 96 -169Q96 -160 102 -152T119 -140H124Q130 -140 140 -140T164 -140T197 -140T237 -140T283 -139T334 -139T389 -139T448 -139H775Q797 -153 797 -169Q797 -187 775 -199H119',
    0x2265: '127 25Q110 25 103 34T96 54Q96 66 105 75Q109 80 439 238L696 361Q113 637 105 645Q96 654 96 667Q96 679 104 688T128 697Q137 696 460 541T788 382Q797 373 797 360Q797 348 788 339Q785 336 459 180L135 25H127ZM119 -199Q96 -191 96 -169Q96 -160 102 -152T119 -140H124Q130 -140 140 -140T164 -140T197 -140T237 -140T283 -139T334 -139T389 -139T448 -139H775Q797 -153 797 -169Q797 -187 775 -199H119',
    0x226A: '734 -74T734 -86T727 -107T704 -116H702Q694 -116 584 -55Q473 7 380 58Q87 219 73 229Q64 238 64 250Q64 263 73 272Q87 282 380 443Q695 616 699 617H700Q718 617 726 607T734 588Q734 568 717 560Q705 554 435 404L157 250L439 94Q721 -61 726 -66Q734 -74 734 -86ZM1085 -74T1085 -86T1078 -107T1055 -116H1053Q1045 -116 935 -55Q824 7 731 58Q438 219 424 229Q415 238 415 250Q415 263 424 272Q438 282 731 443Q1046 616 1050 617H1051Q1069 617 1077 607T1085 588Q1085 568 1068 560Q1056 554 786 404L508 250L790 94Q1072 -61 1077 -66Q1085 -74 1085 -86',
    0x226B: '64 588Q64 600 72 609T94 618H95Q103 618 209 559Q322 496 419 443Q712 282 725 272Q734 263 734 250Q734 238 725 229Q714 220 415 55T110 -113Q103 -116 95 -116Q78 -116 71 -106T64 -86Q64 -74 72 -66Q77 -61 359 94L641 250L363 404Q277 452 173 509Q95 552 82 560T66 576V577Q64 585 64 588ZM415 588Q415 600 423 609T445 618H446Q454 618 560 559Q673 496 770 443Q1063 282 1076 272Q1085 263 1085 250Q1085 238 1076 229Q1065 220 766 55T461 -113Q454 -116 446 -116Q429 -116 422 -106T415 -86Q415 -74 423 -66Q428 -61 710 94L992 250L714 404Q628 452 524 509Q446 552 433 560T417 576V577Q415 585 415 588',
    0x227A: '797 -57Q797 -65 790 -75T766 -86Q748 -86 741 -74T733 -43T719 8T681 72Q647 112 588 141T475 185T343 207T230 216T136 219Q96 219 96 250Q96 280 132 280H136Q193 281 239 283T347 292T457 310T556 342T643 391T703 460T735 553Q741 585 763 585Q781 585 789 575T797 556Q797 540 792 513T758 434T682 345Q605 285 481 254L462 249Q483 246 526 233T633 185T733 104Q767 63 782 15T797 -57',
    0x227B: '96 556Q96 568 104 577T126 586Q152 586 158 553Q164 503 188 462T247 394T331 345T429 313T539 294T649 284T758 280H760Q797 280 797 250Q797 219 760 219H758Q627 217 529 204T347 160T216 77T158 -54Q152 -86 126 -86Q110 -86 103 -76T96 -57Q96 -41 101 -14T135 65T211 154Q288 214 412 245L431 250Q410 252 367 265T259 314T160 395Q127 435 112 483T96 556',
    0x2282: '96 251Q96 389 191 482T417 586Q418 586 428 586T456 586T496 586T546 587T601 587H775Q776 586 779 584T785 580T791 575T795 568T797 557Q797 536 775 527L597 526Q411 525 395 522Q390 521 370 516Q285 494 222 424T158 251Q158 131 246 53Q313 -9 408 -23Q417 -24 597 -25H775Q776 -26 781 -29T788 -34T794 -43T797 -56Q797 -74 775 -85H493Q407 -85 376 -79Q257 -55 177 35T96 251',
    0x2283: '96 -55Q96 -38 119 -25H296Q482 -24 498 -21Q503 -20 523 -15Q609 7 672 77T735 251T665 431T485 524Q476 525 296 526L119 527Q96 535 96 557Q96 578 116 585Q121 587 300 587Q451 586 476 585T522 579Q632 556 714 468T796 251Q796 112 695 13Q612 -65 497 -82Q473 -86 289 -86L119 -85Q96 -77 96 -55',
    0x2286: '96 361Q96 499 191 592T417 696Q418 696 428 696T456 696T496 696T546 697T601 697H775Q776 696 779 694T785 690T791 685T795 678T797 667Q797 646 775 637L597 636Q411 635 395 632Q390 631 370 626Q285 604 222 534T158 361Q158 241 246 163Q313 101 408 87Q417 86 597 85H775Q776 84 781 81T788 76T794 67T797 54Q797 36 775 25H493Q407 25 376 31Q257 55 177 145T96 361ZM149 -199Q127 -191 127 -169T149 -140H154Q160 -140 169 -140T192 -140T224 -140T262 -140T306 -139T354 -139T407 -139T463 -139H775Q776 -140 779 -142T785 -146T791 -151T795 -158T797 -169Q797 -190 775 -199H149',
    0x2287: '96 55Q96 72 119 85H296Q482 86 498 89Q503 90 523 95Q609 117 672 187T735 361T665 541T485 634Q476 635 296 636L119 637Q96 645 96 667Q96 688 116 695Q121 697 300 697Q451 696 476 695T522 689Q632 666 714 578T796 361Q796 222 695 123Q612 45 497 28Q473 24 289 24L119 25Q96 33 96 55ZM119 -199Q96 -190 96 -169T116 -141Q121 -139 433 -139H745Q766 -152 766 -170Q766 -190 745 -199H119',
    0x228E: '672 603Q693 603 702 579V378Q702 348 702 300Q701 169 697 155Q696 153 696 152Q676 78 593 31T383 -16Q265 -16 179 28T70 152Q70 153 69 155Q65 170 64 278Q64 285 64 300Q64 348 64 378Q64 579 65 583Q74 604 94 604T123 584Q125 579 125 386Q125 190 126 184Q135 115 210 80T383 44Q426 44 467 51T546 75T609 119T640 184Q641 190 641 386Q641 579 643 584Q650 603 672 603ZM353 412Q353 420 353 435T352 456Q352 483 358 495T385 507Q403 506 409 494T415 457Q415 451 415 436T414 411V341H558Q579 329 579 311Q579 289 558 281L486 280H414V136Q400 114 384 114Q363 114 354 136L353 208V280H281L209 281Q187 289 187 310Q187 328 209 341H353V412',
    0x2291: '127 25Q111 29 104 49V362L105 675Q114 693 127 696H132Q138 696 149 696T174 696T208 696T249 696T297 697T350 697T407 697T468 697H806Q828 683 828 666Q828 646 806 637L485 636H165V85H805Q806 84 809 82T813 80T817 77T821 73T824 68T826 62T827 55Q827 34 806 25H127ZM96 -190T96 -169T119 -140H125Q131 -140 141 -140T167 -140T201 -140T242 -140T290 -139T344 -139T402 -139T463 -139H805Q806 -140 809 -142T813 -144T817 -147T821 -151T824 -156T826 -162T827 -169Q827 -190 806 -199H119Q96 -190 96 -169',
    0x2292: '66 55Q66 74 89 85H728V636H408L88 637Q66 645 66 667T88 696H94Q99 696 110 696T135 696T169 696T210 696T258 697T311 697T368 697T429 697H767Q786 684 789 672V49Q782 31 767 25H88Q66 32 66 55ZM88 -199Q66 -191 66 -169Q66 -148 87 -140Q91 -139 433 -139H775Q776 -140 779 -142T783 -144T787 -147T791 -151T794 -156T796 -162T797 -169Q797 -189 775 -199H88',
    0x2293: '131 25Q121 1 100 1Q81 1 71 23L70 301Q70 579 72 583Q77 598 90 602Q95 604 385 604H674Q693 591 696 579V25Q686 1 665 1Q646 1 636 23L635 283V543H131V25',
    0x2294: '696 25Q689 7 674 1H93Q77 7 71 23L70 301Q70 579 72 583Q80 604 100 604T131 579V61H635V579Q644 603 666 603Q687 603 696 579V25',
    0x2295: '64 250Q64 350 98 426T189 546T307 610T434 632Q485 632 496 631Q572 621 635 592Q669 575 699 550T760 484T809 384T828 250Q828 77 725 -27T446 -132Q272 -132 168 -27T64 250ZM416 282V570H414Q341 564 285 535T202 475T156 397T134 332T128 287Q127 283 127 282H416ZM765 288Q760 344 743 389T700 462T647 512T589 543T538 560T499 568L483 570H478V282H766L765 288ZM416 -69V220H127Q130 195 131 189T138 155T150 115T168 76T196 35T234 0T286 -35Q337 -61 410 -69H416ZM483 -69Q554 -60 607 -33T687 21T733 93T756 156T764 209Q766 217 766 220H478V-69H483',
    0x2296: '64 250Q64 350 98 426T189 546T307 610T434 632Q485 632 496 631Q572 621 635 592Q669 575 699 550T760 484T809 384T828 250Q828 77 725 -27T446 -132Q272 -132 168 -27T64 250ZM765 288Q753 424 666 497T446 571T227 498T128 288L127 282H766L765 288ZM446 -70Q578 -70 666 4T765 213L766 220H127Q130 195 131 189T138 155T150 115T168 76T196 35T234 0T286 -35Q353 -70 446 -70',
    0x2297: '64 250Q64 350 98 426T189 546T307 610T434 632Q485 632 496 631Q572 621 635 592Q669 575 699 550T760 484T809 384T828 250Q828 77 725 -27T446 -132Q272 -132 168 -27T64 250ZM647 512Q567 571 447 571Q340 571 262 523Q237 507 237 505L342 399L447 295L657 505L647 512ZM298 356L192 461Q180 445 161 411Q126 341 126 251Q126 128 192 40L403 250L298 356ZM701 41Q704 41 719 63T750 138T767 250Q767 310 750 362T719 437T701 460L491 250L701 41ZM238 -5Q238 -8 261 -22T336 -53T447 -70Q567 -70 647 -11L657 -4L447 206L342 101Q238 -1 238 -5',
    0x2298: '64 250Q64 350 98 426T189 546T307 610T434 632Q485 632 496 631Q572 621 635 592Q669 575 699 550T760 484T809 384T828 250Q828 77 725 -27T446 -132Q272 -132 168 -27T64 250ZM657 505Q656 506 650 510T638 518T623 527T604 537T581 547T553 556T522 563T486 569T446 571Q305 571 216 487T126 251Q126 128 192 40L657 505ZM447 -70Q591 -70 679 16T767 250Q767 308 751 360T719 436T701 460L469 228Q238 -1 238 -5Q238 -8 261 -22T336 -53T447 -70',
    0x2299: '64 250Q64 350 98 426T189 546T307 610T434 632Q485 632 496 631Q572 621 635 592Q669 575 699 550T760 484T809 384T828 250Q828 77 725 -27T446 -132Q272 -132 168 -27T64 250ZM767 252Q767 395 681 483T446 571Q303 571 215 486T126 249Q126 107 212 19T446 -70Q596 -70 681 18T767 252ZM335 251Q335 297 368 329T441 361Q498 361 527 327T557 250Q557 202 525 171T446 140Q397 140 366 173T335 251',
    0x22A2: '65 672Q76 693 91 693Q115 693 123 674Q125 669 125 523V378H615Q618 376 622 373T628 369T632 366T635 362T636 356T637 347Q637 328 619 319Q615 317 370 317H125V171Q125 25 123 20Q114 1 94 1Q73 1 65 23V672',
    0x22A3: '64 327T64 347T89 378H577V525L578 672Q592 693 604 693Q629 693 638 669V25Q628 1 607 1Q588 1 578 23L577 170V317H88Q64 327 64 347',
    0x22A4: '64 664Q64 675 71 683T87 693H93Q99 693 110 693T137 693T173 693T217 694T267 694T323 694T383 694T448 694H807Q808 693 811 691T817 687T823 682T827 675T829 664Q829 643 807 634L642 633H477V25Q467 1 446 1Q427 1 417 23L416 328V633H251L87 634Q64 643 64 664',
    0x22A5: '65 31Q65 38 66 41T71 50T87 61H416V366L417 672Q431 693 443 693Q468 693 477 669V61H807Q808 60 811 58T817 54T823 49T827 42T829 31Q829 10 807 1H87Q65 10 65 31',
    0x22A8: '160 -249Q138 -249 129 -225V250Q129 725 131 729Q139 750 159 750T190 725V392Q219 393 537 393H896Q897 392 900 390T906 386T912 381T916 374T918 363Q918 345 896 333H190V169H896L900 166Q905 163 907 162T912 157T916 149T918 139Q918 118 896 109H190V-225Q181 -249 160 -249',
    0x22C4: '280 522Q281 523 285 523H289Q301 523 366 457Q404 420 431 393Q533 291 546 277T560 250Q560 239 548 226T431 108Q313 -10 304 -16Q297 -21 287 -21Q278 -21 275 -19Q270 -17 146 107T18 238Q15 242 15 251Q15 258 18 263Q20 268 145 392T274 519L280 522ZM388 350L288 449L188 350L89 250L288 52L487 250L388 350',
    0x22C5: '74 251Q74 286 99 311T156 336Q200 336 222 308T245 250Q245 221 224 194T160 166T96 193T74 251',
    0x22C6: '270 491Q274 502 287 502Q298 502 304 491Q304 486 323 396T342 303L438 314Q520 324 534 324Q540 324 545 320T550 307Q550 298 539 290T456 243Q377 198 377 197L416 111Q456 26 456 22Q457 21 457 18Q457 11 451 6T438 0H437Q432 0 415 16Q387 42 358 68L287 133L216 68Q193 47 167 23Q142 0 136 0Q129 0 123 5T117 18Q117 21 118 22Q118 26 158 111L197 197Q197 198 156 221T72 269T26 298Q24 304 24 307Q24 315 29 319T40 324Q53 324 136 314L232 303Q232 306 251 396T270 491',
    0x22C8: '906 251Q906 456 905 456Q550 252 549 251Q549 250 726 148T905 45T906 251ZM967 -14Q958 -38 939 -38H937Q928 -38 923 -35Q919 -34 748 64T500 209L71 -38Q69 -39 63 -39Q42 -39 33 -16V518Q45 540 63 540H65Q72 540 174 481Q247 439 302 407L500 292Q578 339 750 438T929 539H933Q958 539 967 515V-14ZM449 251L94 456Q93 456 93 251Q93 45 94 45L106 52Q119 59 139 71T186 98T242 131T301 165T357 197T404 225T437 244L449 251',
    0x22EE: '74 55Q74 91 99 116T156 141Q200 141 222 113T245 55Q245 26 224 -1T160 -29Q118 -29 96 -3T74 55ZM74 465Q74 501 99 526T156 551Q200 551 222 523T245 465Q245 436 224 409T160 381Q118 381 96 407T74 465ZM74 865Q74 901 99 926T156 951Q200 951 222 923T245 865Q245 836 224 809T160 781Q118 781 96 807T74 865',
    0x22EF: '74 251Q74 286 99 311T156 336Q200 336 222 308T245 250Q245 221 224 194T160 166T96 193T74 251ZM562 251Q562 286 587 311T644 336Q688 336 710 308T733 250Q733 221 712 194T648 166T584 193T562 251ZM1050 251Q1050 286 1075 311T1132 336Q1176 336 1198 308T1221 250Q1221 221 1200 194T1136 166T1072 193T1050 251',
    0x22F1: '129 785Q129 821 154 846T211 871Q255 871 277 843T300 785Q300 756 279 729T215 701Q173 701 151 727T129 785ZM576 485Q576 521 601 546T658 571Q702 571 724 543T747 485Q747 456 726 429T662 401Q620 401 598 427T576 485ZM1023 185Q1023 221 1048 246T1105 271Q1149 271 1171 243T1194 185Q1194 156 1173 129T1109 101Q1067 101 1045 127T1023 185',
    0x2308: '194 728Q199 743 216 749H220Q223 749 229 749T245 749T265 750T289 750T316 750T345 750H471Q472 749 477 746T484 741T490 732T493 719Q493 701 471 690L362 689H254V-224Q244 -248 223 -248T194 -226V728',
    0x2309: '317 -224Q307 -248 286 -248Q267 -248 257 -226L256 231V689H148L40 690Q17 698 17 720Q17 741 37 748Q42 750 169 750H295Q314 737 317 725V-224',
    0x230A: '194 728Q204 749 220 749Q245 749 254 725V-188H471Q472 -189 477 -192T484 -197T490 -206T493 -219Q493 -237 471 -248H216Q200 -242 194 -226V728',
    0x230B: '17 -219Q17 -201 40 -188H256V270L257 728Q271 749 283 749Q308 749 317 725V-224Q310 -242 295 -248H40L38 -247Q35 -246 34 -245T30 -243T25 -239T21 -234T18 -227T17 -219',
    0x2322: '95 108Q85 108 75 114T65 139Q65 159 129 227Q316 405 573 405Q654 405 729 387T854 344T950 286T1015 232T1053 191Q1078 160 1083 152Q1084 148 1084 139Q1084 121 1074 115T1054 108Q1040 108 1029 122T990 167T922 223Q819 291 680 309Q641 315 575 315Q508 315 469 309Q303 288 197 201Q168 179 148 155T118 119T95 108',
    0x2323: '1054 392Q1067 392 1076 384T1085 362Q1085 351 1079 342T1050 310Q983 243 901 200Q753 126 575 126Q494 126 420 141T298 176T205 225T140 272T100 310Q64 346 64 362Q64 370 67 374Q75 393 93 393Q107 393 124 375Q272 214 575 214Q877 214 1025 375Q1039 392 1054 392',
    0x2329: '127 243V259L223 491Q251 557 286 642Q318 719 324 732T340 748H341Q347 750 351 750Q365 750 373 740T382 723Q382 713 286 482L190 251Q190 249 286 20T382 -219Q382 -232 373 -240T352 -249Q332 -249 323 -229Q320 -220 223 10L127 243',
    0x232A: '64 720Q64 732 72 741T94 750Q106 750 113 743Q118 741 122 732L319 259V243L122 -231Q112 -249 95 -249Q83 -249 74 -240T64 -218Q64 -210 160 20L256 251L160 482Q64 715 64 720',
    0x25B3: '91 1Q69 10 69 31Q69 39 81 59T168 197Q327 447 485 697Q493 711 510 711Q523 711 532 702Q536 697 743 371T951 41Q953 35 953 31Q953 12 931 1H91ZM690 340Q651 401 604 476T534 586L512 621Q511 622 507 616Q498 604 332 342L154 62L333 61H689L867 62L690 340',
    0x25B5: '91 1Q69 10 69 31Q69 39 81 59T168 197Q327 447 485 697Q493 711 510 711Q523 711 532 702Q536 697 743 371T951 41Q953 35 953 31Q953 12 931 1H91ZM690 340Q651 401 604 476T534 586L512 621Q511 622 507 616Q498 604 332 342L154 62L333 61H689L867 62L690 340',
    0x25B9: '33 518Q45 540 63 540H65Q72 540 174 481Q247 439 302 407Q529 276 533 272Q542 263 542 250Q542 238 533 229Q528 224 304 95T71 -38Q69 -39 63 -39Q42 -39 33 -16V518ZM449 251L94 456Q93 456 93 251Q93 45 94 45L106 52Q119 59 139 71T186 98T242 131T301 165T357 197T404 225T437 244L449 251',
    0x25BD: '68 470Q68 481 75 489T91 499H93Q296 500 512 500H931Q932 499 937 496T945 490T950 482T953 469Q953 465 951 459Q950 455 743 129T532 -202Q524 -210 511 -210Q497 -210 489 -202Q486 -199 281 124T71 456Q68 462 68 470ZM154 439Q155 437 332 158T510 -122Q510 -123 533 -87T600 18T688 157Q866 437 866 438Q867 439 805 439T511 439H154',
    0x25BF: '68 470Q68 481 75 489T91 499H93Q296 500 512 500H931Q932 499 937 496T945 490T950 482T953 469Q953 465 951 459Q950 455 743 129T532 -202Q524 -210 511 -210Q497 -210 489 -202Q486 -199 281 124T71 456Q68 462 68 470ZM154 439Q155 437 332 158T510 -122Q510 -123 533 -87T600 18T688 157Q866 437 866 438Q867 439 805 439T511 439H154',
    0x25C3: '542 -14Q533 -38 514 -38H512Q503 -38 498 -35Q494 -34 270 95T42 229Q33 238 33 251Q33 259 35 264Q36 265 38 268T42 272Q48 278 271 407T504 539H508Q533 539 542 515V-14ZM481 251Q481 456 480 456Q125 252 124 251Q124 250 301 148T480 45T481 251',
    0x25EF: '65 42T65 250T204 584T574 711Q795 711 935 594Q955 577 974 555T1022 490T1067 385T1084 250Q1084 42 945 -84T574 -211T204 -85ZM1024 250Q1024 431 903 540T578 650Q482 650 404 627T274 565T189 474T140 366T125 250Q125 123 186 31T347 -106T573 -150Q772 -150 898 -45T1024 250',
    0x2660: '675 -18Q536 -18 527 62V70H477V55Q479 14 487 -21T502 -75T509 -101Q509 -120 491 -127Q487 -129 447 -129Q446 -129 439 -129T427 -130Q384 -130 384 -101Q384 -95 391 -76T406 -21T416 55V70H366Q364 52 360 40T342 14T300 -8T230 -17H218Q110 -17 75 117Q64 163 64 209Q64 290 116 357T261 495Q363 574 414 690Q425 719 445 719Q467 719 478 693Q507 627 547 578T623 503T702 438T777 357Q829 285 829 202V197Q826 128 808 81T762 15T714 -11T675 -18',
    0x2661: '65 491Q65 602 121 656T246 710Q375 710 440 624L447 615Q519 711 638 711Q723 711 775 652T828 491Q828 390 770 313T581 129Q539 95 514 63T483 14T469 -13T446 -24Q434 -24 427 -17T416 0T400 32T371 74Q352 97 310 131T229 199T151 276T89 374T65 491ZM249 649Q188 649 157 603T125 489Q125 409 181 338T352 176Q408 131 437 87L446 73L456 87Q479 121 507 147T579 207T659 278Q768 387 768 489Q768 506 766 524T756 566T731 611T687 642Q668 649 638 649Q609 649 593 644Q547 633 516 604T478 534Q473 505 447 505H445Q420 505 416 534Q407 577 372 608T285 648Q277 649 249 649',
    0x2662: '409 686Q410 688 412 691T415 696T418 701T421 706T424 709T427 713T431 715T435 717T440 718T446 719Q455 719 460 717T472 704T488 679T516 633T563 567Q624 485 687 422T787 330T826 296T828 282Q828 270 825 265T801 245Q696 161 612 59T477 -133Q465 -154 447 -154Q439 -154 434 -152T425 -146T414 -130T399 -104T372 -62T330 -3Q270 78 207 142T107 234T70 265Q64 274 64 282Q64 296 90 317Q284 472 409 686ZM749 282Q745 286 721 307T681 343T635 388T581 446T525 516T465 601Q462 606 457 613T450 624L447 627V628Q446 628 436 611T402 561T348 489T266 396T155 292L145 282Q147 280 185 245T257 177T343 79T442 -57Q446 -64 447 -64V-63Q450 -59 475 -22T530 56T619 160T749 282',
    0x2663: '240 527Q240 611 301 665T446 719T590 665T652 527Q652 431 571 373Q578 363 584 352T593 335T597 329L604 335Q611 341 617 345T637 356T667 366Q672 366 680 367T694 368Q767 368 814 310T861 177Q861 109 819 57T713 -12Q690 -17 656 -17Q535 -13 527 62V70H477V55Q479 14 487 -21T502 -75T509 -101Q509 -120 491 -127Q487 -129 447 -129Q446 -129 439 -129T427 -130Q384 -130 384 -101Q384 -95 391 -76T406 -21T416 55V70H366V62Q356 -12 237 -17Q130 -17 71 60Q32 111 32 178Q32 251 78 309T198 368Q217 368 233 364T260 354T279 343T291 333T296 329L300 336Q304 343 310 354T322 373Q240 432 240 527',
    0x266D: '230 480Q293 480 337 440T381 330V322Q381 240 323 161Q258 71 123 -11L114 -16L97 -17Q70 -17 66 -7Q64 -3 64 366V641Q64 717 65 731T75 748Q78 750 95 750Q117 750 122 742T127 694Q127 685 127 653T126 595V454Q183 480 230 480ZM242 333Q242 405 212 405H207Q147 405 130 370L127 364L126 219Q126 77 128 77Q133 82 140 90T167 127T202 183T229 253T242 333',
    0x266E: '345 -223Q333 -223 330 -214T327 -178V-116Q327 -23 326 -23L203 -82Q90 -134 77 -140Q65 -142 59 -130Q57 -126 57 295V595Q57 643 57 667T58 704T60 719T63 724Q93 741 101 741Q113 741 116 732T119 680V597Q119 467 120 467Q121 468 180 495T301 552T369 584Q381 586 387 574Q389 570 389 187V-88Q389 -132 389 -154T388 -188T386 -202T383 -206Q353 -223 345 -223ZM327 271Q327 421 326 421L120 323L119 173V23Q120 23 223 72L327 121V271',
    0x266F: '140 628Q151 628 154 620T158 591V549V484L166 488Q175 492 192 500T223 516L288 548V622V674Q288 681 288 685T289 693T289 699T291 703T295 707T298 709T304 712T311 716Q326 724 332 724Q343 724 346 715T350 685V644V579Q358 583 364 583Q376 583 380 574Q382 570 382 514V481Q382 459 380 454T363 441L350 435V135Q358 139 364 139Q376 139 380 130Q382 126 382 70V37Q382 15 380 10T363 -3L350 -9V-76Q350 -102 348 -106T328 -119Q312 -128 306 -128Q288 -128 288 -99V-77V-40L280 -44Q271 -48 254 -56T223 -72L158 -104V-150V-180Q158 -198 155 -202T135 -216Q119 -224 114 -224Q96 -224 96 -192V-172V-135Q86 -140 81 -140Q70 -140 66 -129Q64 -126 64 -70V-54Q64 -18 66 -12T83 3L96 9V309Q86 304 81 304Q70 304 66 315Q64 318 64 374V407Q64 429 66 434T83 447L96 453V602Q99 609 100 610T118 619Q134 628 140 628ZM288 254Q288 404 287 404L158 340V40L166 44Q175 48 192 56T223 72L288 104V254',
    0x2758: '160 -249Q138 -249 129 -225V250Q129 725 131 729Q139 750 159 750T190 725V-225Q181 -249 160 -249',
    0x27E8: '127 243V259L223 491Q251 557 286 642Q318 719 324 732T340 748H341Q347 750 351 750Q365 750 373 740T382 723Q382 713 286 482L190 251Q190 249 286 20T382 -219Q382 -232 373 -240T352 -249Q332 -249 323 -229Q320 -220 223 10L127 243',
    0x27E9: '64 720Q64 732 72 741T94 750Q106 750 113 743Q118 741 122 732L319 259V243L122 -231Q112 -249 95 -249Q83 -249 74 -240T64 -218Q64 -210 160 20L256 251L160 482Q64 715 64 720',
    0x27F5: '1063 221L649 220H235Q340 133 364 17Q368 1 368 -2Q368 -16 343 -17Q340 -17 338 -17H332Q317 -17 314 -14T305 6Q298 34 285 62T247 126T179 189T78 233Q64 237 64 251Q64 261 74 265T108 277T154 297Q212 328 251 379T305 495Q309 511 313 514T333 518H338Q363 517 367 510Q368 507 368 503Q368 500 364 484Q345 401 287 331Q254 295 235 282L649 281H1063L1065 280Q1079 281 1392 281H1719Q1720 280 1723 278T1729 274T1735 269T1739 262T1741 251Q1741 230 1719 221H1063',
    0x27F6: '119 221Q96 230 96 251T116 279Q121 281 448 281H1188L1602 282Q1569 304 1530 357T1473 482Q1469 500 1469 501Q1469 512 1480 517Q1482 518 1500 518H1505Q1520 518 1523 515T1532 495Q1552 412 1611 351T1753 270Q1773 263 1773 251Q1773 240 1765 236T1732 225T1683 204Q1625 173 1586 122T1532 6Q1528 -10 1524 -13T1504 -17H1499Q1474 -16 1470 -9Q1469 -6 1469 -2Q1469 1 1473 17Q1501 138 1602 220H1188L775 221H119',
    0x27F7: '1063 221L649 220H235Q340 133 364 17Q368 1 368 -2Q368 -16 343 -17Q340 -17 338 -17H332Q317 -17 314 -14T305 6Q298 34 285 62T247 126T179 189T78 233Q64 237 64 251Q64 261 74 265T108 277T154 297Q212 328 251 379T305 495Q309 511 313 514T333 518H338Q363 517 367 510Q368 507 368 503Q368 500 364 484Q345 401 287 331Q254 295 235 282L649 281H1476L1890 282Q1857 304 1818 357T1761 482Q1757 500 1757 501Q1757 512 1768 517Q1770 518 1788 518H1793Q1808 518 1811 515T1820 495Q1840 412 1899 351T2041 270Q2061 263 2061 251Q2061 240 2053 236T2020 225T1971 204Q1913 173 1874 122T1820 6Q1816 -10 1812 -13T1792 -17H1787Q1762 -16 1758 -9Q1757 -6 1757 -2Q1757 1 1761 17Q1789 138 1890 220H1476L1063 221',
    0x27F8: '1063 333L700 332H338L324 321Q283 290 230 264L205 250Q266 224 323 180L338 170L700 169H1063L1064 168Q1080 169 1423 169H1782L1786 166Q1791 163 1793 162T1798 157T1802 149T1804 139Q1804 118 1782 109H1063L733 108H404L412 99Q455 50 488 -10Q498 -27 493 -37Q487 -46 465 -46H460Q446 -46 439 -39T426 -18T399 25T344 89Q239 194 99 229Q96 230 92 231T85 232T79 234T73 235T69 237T66 240T65 244T64 250Q64 267 90 271Q197 295 286 361T430 525Q439 542 442 544T460 547H465Q487 547 492 539Q496 531 496 530Q496 521 471 482T414 405L404 394L733 393H1063Q1064 392 1065 392Q1081 393 1423 393H1782Q1783 392 1786 390T1792 386T1798 381T1802 374T1804 363Q1804 345 1782 333H1063',
    0x27F9: '87 109Q64 118 64 139Q64 159 86 168Q89 169 448 169H1169L1532 170L1546 180Q1562 193 1580 204T1612 223T1638 237T1657 246L1664 250L1639 264Q1586 290 1545 321L1531 332H1169L807 333H87Q64 343 64 362Q64 383 84 391Q89 393 448 393H1136L1465 394L1455 405Q1428 436 1401 477T1374 531Q1374 547 1399 547H1404H1409Q1423 547 1430 540T1443 519T1470 475T1526 411Q1634 303 1779 271Q1780 271 1783 270T1788 269T1792 268T1796 266T1799 264T1802 260T1803 256T1804 250Q1804 242 1800 238T1783 231T1755 225T1712 211T1654 185Q1517 112 1439 -24Q1430 -40 1426 -43T1409 -46H1404Q1373 -46 1373 -31Q1373 -24 1381 -10Q1414 50 1457 99L1465 108H1136L807 109H87',
    0x27FA: '1063 333L700 332H338L324 321Q283 290 230 264L205 250Q266 224 323 180L338 170L700 169H1425L1788 170L1802 180Q1818 193 1836 204T1868 223T1894 237T1913 246L1920 250L1895 264Q1842 290 1801 321L1787 332H1425L1063 333ZM733 393H1392L1721 394L1711 405Q1684 436 1657 477T1630 531Q1630 547 1655 547H1660H1665Q1679 547 1686 540T1699 519T1726 475T1782 411Q1890 303 2035 271Q2036 271 2039 270T2044 269T2048 268T2052 266T2055 264T2058 260T2059 256T2060 250Q2060 242 2056 238T2039 231T2011 225T1968 211T1910 185Q1773 112 1695 -24Q1686 -40 1682 -43T1665 -46H1660Q1629 -46 1629 -31Q1629 -24 1637 -10Q1670 50 1713 99L1721 108H1392L1063 109L733 108H404L412 99Q455 50 488 -10Q498 -27 493 -37Q487 -46 465 -46H460Q446 -46 439 -39T426 -18T399 25T344 89Q239 194 99 229Q96 230 92 231T85 232T79 234T73 235T69 237T66 240T65 244T64 250Q64 267 90 271Q197 295 286 361T430 525Q439 542 442 544T460 547H465Q487 547 492 539Q496 531 496 530Q496 521 471 482T414 405L404 394L733 393',
    0x27FC: '65 426Q74 448 95 448Q112 448 125 426V281H130L132 280H134Q162 281 448 281H1188L1602 282Q1569 304 1530 357T1473 482Q1469 500 1469 501Q1469 512 1480 517Q1482 518 1500 518H1505Q1520 518 1523 515T1532 495Q1552 412 1611 351T1753 270Q1773 263 1773 251Q1773 240 1765 236T1732 225T1683 204Q1625 173 1586 122T1532 6Q1528 -10 1524 -13T1504 -17H1499Q1474 -16 1470 -9Q1469 -6 1469 -2Q1469 1 1473 17Q1501 138 1602 220H1188L775 221H135Q133 220 130 220H125V76Q115 54 95 54Q73 54 65 76V426',
    0x29F8: '189 -210Q179 -210 170 -203T160 -179Q160 -171 162 -166Q164 -163 420 266T679 698Q686 711 704 711Q714 711 723 704T733 681Q733 672 730 667Q723 654 469 228T211 -201Q202 -210 189 -210',
    0x2A2F: '168 500Q168 515 178 522T195 530H198Q207 530 218 521T282 458Q312 428 331 409L447 294L563 409Q674 520 682 525Q687 529 695 529Q711 529 718 520T726 499V498Q726 489 720 481T666 427Q631 392 606 367L490 251L606 135Q717 23 721 17T726 2Q726 -9 719 -18T695 -28H692Q685 -28 674 -18T608 47Q581 74 563 92L447 207L331 91Q217 -22 208 -27Q206 -28 203 -28H197Q168 -28 168 2Q168 13 178 24T288 135L404 250L288 366Q177 479 173 485T168 500',
    0x2A3F: '39 655Q39 675 43 680T69 686Q110 684 225 684Q267 684 303 684T360 685T385 686Q401 686 405 680T409 651Q409 632 403 628T367 624H348H301V62H598V624H551H532Q502 624 496 628T490 651Q490 673 494 679T514 686Q518 686 558 685T675 684T792 685T836 686Q852 686 856 680T860 651Q860 632 854 628T818 624H799H752V62H799H809Q846 62 853 59T860 36V31V21Q860 6 850 2Q846 0 450 0H156Q75 0 60 1T40 11V18Q39 26 39 31Q39 54 44 58T82 63Q84 63 90 63T100 62H147V624H100H90Q53 624 46 627T39 650V655',
    0x2AAF: '796 54Q796 40 788 32T767 24Q741 24 735 57Q729 107 705 148T646 216T563 264T465 297T356 316T245 326T136 330H134Q96 330 96 360Q96 391 134 391H136Q193 392 239 394T347 403T457 421T556 453T643 502T703 571T735 664Q741 696 763 696Q781 696 789 686T797 667Q797 651 792 624T758 545T682 456Q605 396 481 365L462 360Q483 357 526 344T633 296T733 215Q767 173 781 128T796 54ZM119 -199Q96 -190 96 -169T116 -141Q121 -139 448 -139H775Q776 -140 779 -142T785 -146T791 -151T795 -158T797 -169Q797 -190 775 -199H119',
    0x2AB0: '127 24Q115 24 106 32T97 55Q97 95 124 156T211 265Q288 325 412 356L431 361Q410 363 367 376T259 425T160 506Q127 546 112 594T96 667Q96 679 104 688T126 697Q152 697 158 664Q164 614 188 573T247 505T331 456T429 424T539 405T649 395T758 391Q797 391 797 360Q797 330 761 330H758Q701 329 655 327T547 318T437 300T337 268T251 219T190 150T158 57Q151 24 127 24ZM119 -199Q96 -190 96 -169T116 -141Q121 -139 448 -139H775Q776 -140 779 -142T785 -146T791 -151T795 -158T797 -169Q797 -190 775 -199H119',
    0x3008: '127 243V259L223 491Q251 557 286 642Q318 719 324 732T340 748H341Q347 750 351 750Q365 750 373 740T382 723Q382 713 286 482L190 251Q190 249 286 20T382 -219Q382 -232 373 -240T352 -249Q332 -249 323 -229Q320 -220 223 10L127 243',
    0x3009: '64 720Q64 732 72 741T94 750Q106 750 113 743Q118 741 122 732L319 259V243L122 -231Q112 -249 95 -249Q83 -249 74 -240T64 -218Q64 -210 160 20L256 251L160 482Q64 715 64 720',
}, {
    0x2033: "\u2032\u2032",
    0x2034: "\u2032\u2032\u2032",
    0x2057: "\u2032\u2032\u2032\u2032",
    0x219A: "\u2190\u0338",
    0x219B: "\u2192\u0338",
    0x21AE: "\u2194\u0338",
    0x21CD: "\u21D0\u0338",
    0x21CE: "\u21D4\u0338",
    0x21CF: "\u21D2\u0338",
    0x2204: "\u2203\u0338",
    0x220C: "\u220B\u0338",
    0x2224: "\u2223\u0338",
    0x2226: "\u2225\u0338",
    0x2241: "\u223C\u0338",
    0x2244: "\u2243\u0338",
    0x2247: "\u2245\u0338",
    0x2249: "\u2248\u0338",
    0x2262: "\u2261\u0338",
    0x226D: "\u224D\u0338",
    0x226E: "<\u0338",
    0x226F: ">\u0338",
    0x2270: "\u2264\u0338",
    0x2271: "\u2265\u0338",
    0x2280: "\u227A\u0338",
    0x2281: "\u227B\u0338",
    0x2284: "\u2282\u0338",
    0x2285: "\u2283\u0338",
    0x2288: "\u2286\u0338",
    0x2289: "\u2287\u0338",
    0x22AC: "\u22A2\u0338",
    0x22AD: "\u22A8\u0338",
    0x22E2: "\u2291\u0338",
    0x22E3: "\u2292\u0338",
});
