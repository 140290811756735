"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    NJcy: '\u040A',
    Nacute: '\u0143',
    Ncaron: '\u0147',
    Ncedil: '\u0145',
    Ncy: '\u041D',
    NegativeMediumSpace: '\u200B',
    NegativeThickSpace: '\u200B',
    NegativeThinSpace: '\u200B',
    NegativeVeryThinSpace: '\u200B',
    NewLine: '\u000A',
    NoBreak: '\u2060',
    NonBreakingSpace: '\u00A0',
    Not: '\u2AEC',
    NotCongruent: '\u2262',
    NotCupCap: '\u226D',
    NotEqualTilde: '\u2242\u0338',
    NotGreaterFullEqual: '\u2267\u0338',
    NotGreaterGreater: '\u226B\u0338',
    NotGreaterLess: '\u2279',
    NotGreaterSlantEqual: '\u2A7E\u0338',
    NotGreaterTilde: '\u2275',
    NotHumpDownHump: '\u224E\u0338',
    NotHumpEqual: '\u224F\u0338',
    NotLeftTriangleBar: '\u29CF\u0338',
    NotLessGreater: '\u2278',
    NotLessLess: '\u226A\u0338',
    NotLessSlantEqual: '\u2A7D\u0338',
    NotLessTilde: '\u2274',
    NotNestedGreaterGreater: '\u2AA2\u0338',
    NotNestedLessLess: '\u2AA1\u0338',
    NotPrecedesEqual: '\u2AAF\u0338',
    NotReverseElement: '\u220C',
    NotRightTriangleBar: '\u29D0\u0338',
    NotSquareSubset: '\u228F\u0338',
    NotSquareSubsetEqual: '\u22E2',
    NotSquareSuperset: '\u2290\u0338',
    NotSquareSupersetEqual: '\u22E3',
    NotSubset: '\u2282\u20D2',
    NotSucceedsEqual: '\u2AB0\u0338',
    NotSucceedsTilde: '\u227F\u0338',
    NotSuperset: '\u2283\u20D2',
    NotTildeEqual: '\u2244',
    NotTildeFullEqual: '\u2247',
    NotTildeTilde: '\u2249',
    Ntilde: '\u00D1',
    Nu: '\u039D',
    nGg: '\u22D9\u0338',
    nGt: '\u226B\u20D2',
    nGtv: '\u226B\u0338',
    nLl: '\u22D8\u0338',
    nLt: '\u226A\u20D2',
    nLtv: '\u226A\u0338',
    nabla: '\u2207',
    nacute: '\u0144',
    nang: '\u2220\u20D2',
    nap: '\u2249',
    napE: '\u2A70\u0338',
    napid: '\u224B\u0338',
    napos: '\u0149',
    napprox: '\u2249',
    natural: '\u266E',
    naturals: '\u2115',
    nbsp: '\u00A0',
    nbump: '\u224E\u0338',
    nbumpe: '\u224F\u0338',
    ncap: '\u2A43',
    ncaron: '\u0148',
    ncedil: '\u0146',
    ncong: '\u2247',
    ncongdot: '\u2A6D\u0338',
    ncup: '\u2A42',
    ncy: '\u043D',
    ndash: '\u2013',
    ne: '\u2260',
    neArr: '\u21D7',
    nearhk: '\u2924',
    nearrow: '\u2197',
    nedot: '\u2250\u0338',
    nequiv: '\u2262',
    nesear: '\u2928',
    nesim: '\u2242\u0338',
    nexist: '\u2204',
    nexists: '\u2204',
    ngE: '\u2267\u0338',
    nge: '\u2271',
    ngeq: '\u2271',
    ngeqq: '\u2267\u0338',
    ngeqslant: '\u2A7E\u0338',
    nges: '\u2A7E\u0338',
    ngsim: '\u2275',
    ngt: '\u226F',
    ngtr: '\u226F',
    nhArr: '\u21CE',
    nhpar: '\u2AF2',
    ni: '\u220B',
    nis: '\u22FC',
    nisd: '\u22FA',
    niv: '\u220B',
    njcy: '\u045A',
    nlArr: '\u21CD',
    nlE: '\u2266\u0338',
    nldr: '\u2025',
    nle: '\u2270',
    nleftarrow: '\u219A',
    nleftrightarrow: '\u21AE',
    nleq: '\u2270',
    nleqq: '\u2266\u0338',
    nleqslant: '\u2A7D\u0338',
    nles: '\u2A7D\u0338',
    nless: '\u226E',
    nlsim: '\u2274',
    nlt: '\u226E',
    nltri: '\u22EA',
    nltrie: '\u22EC',
    nmid: '\u2224',
    notin: '\u2209',
    notinE: '\u22F9\u0338',
    notindot: '\u22F5\u0338',
    notinva: '\u2209',
    notinvb: '\u22F7',
    notinvc: '\u22F6',
    notni: '\u220C',
    notniva: '\u220C',
    notnivb: '\u22FE',
    notnivc: '\u22FD',
    npar: '\u2226',
    nparallel: '\u2226',
    nparsl: '\u2AFD\u20E5',
    npart: '\u2202\u0338',
    npolint: '\u2A14',
    npr: '\u2280',
    nprcue: '\u22E0',
    npre: '\u2AAF\u0338',
    nprec: '\u2280',
    npreceq: '\u2AAF\u0338',
    nrArr: '\u21CF',
    nrarrc: '\u2933\u0338',
    nrarrw: '\u219D\u0338',
    nrightarrow: '\u219B',
    nrtri: '\u22EB',
    nrtrie: '\u22ED',
    nsc: '\u2281',
    nsccue: '\u22E1',
    nsce: '\u2AB0\u0338',
    nshortmid: '\u2224',
    nshortparallel: '\u2226',
    nsim: '\u2241',
    nsime: '\u2244',
    nsimeq: '\u2244',
    nsmid: '\u2224',
    nspar: '\u2226',
    nsqsube: '\u22E2',
    nsqsupe: '\u22E3',
    nsub: '\u2284',
    nsubE: '\u2AC5\u0338',
    nsube: '\u2288',
    nsubset: '\u2282\u20D2',
    nsubseteq: '\u2288',
    nsubseteqq: '\u2AC5\u0338',
    nsucc: '\u2281',
    nsucceq: '\u2AB0\u0338',
    nsup: '\u2285',
    nsupE: '\u2AC6\u0338',
    nsupe: '\u2289',
    nsupset: '\u2283\u20D2',
    nsupseteq: '\u2289',
    nsupseteqq: '\u2AC6\u0338',
    ntgl: '\u2279',
    ntilde: '\u00F1',
    ntlg: '\u2278',
    ntriangleleft: '\u22EA',
    ntrianglelefteq: '\u22EC',
    ntriangleright: '\u22EB',
    ntrianglerighteq: '\u22ED',
    num: '\u0023',
    numero: '\u2116',
    numsp: '\u2007',
    nvHarr: '\u2904',
    nvap: '\u224D\u20D2',
    nvge: '\u2265\u20D2',
    nvgt: '\u003E\u20D2',
    nvinfin: '\u29DE',
    nvlArr: '\u2902',
    nvle: '\u2264\u20D2',
    nvlt: '\u003C\u20D2',
    nvltrie: '\u22B4\u20D2',
    nvrArr: '\u2903',
    nvrtrie: '\u22B5\u20D2',
    nvsim: '\u223C\u20D2',
    nwArr: '\u21D6',
    nwarhk: '\u2923',
    nwarrow: '\u2196',
    nwnear: '\u2927'
}, 'n');
