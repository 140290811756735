"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Wcirc: '\u0174',
    wcirc: '\u0175',
    wedbar: '\u2A5F',
    wedge: '\u2227',
    wedgeq: '\u2259',
    wp: '\u2118',
    wr: '\u2240',
    wreath: '\u2240'
}, 'w');
