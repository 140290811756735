"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    Ascr: '\uD835\uDC9C',
    Bscr: '\u212C',
    Cscr: '\uD835\uDC9E',
    Dscr: '\uD835\uDC9F',
    Escr: '\u2130',
    Fscr: '\u2131',
    Gscr: '\uD835\uDCA2',
    Hscr: '\u210B',
    Iscr: '\u2110',
    Jscr: '\uD835\uDCA5',
    Kscr: '\uD835\uDCA6',
    Lscr: '\u2112',
    Mscr: '\u2133',
    Nscr: '\uD835\uDCA9',
    Oscr: '\uD835\uDCAA',
    Pscr: '\uD835\uDCAB',
    Qscr: '\uD835\uDCAC',
    Rscr: '\u211B',
    Sscr: '\uD835\uDCAE',
    Tscr: '\uD835\uDCAF',
    Uscr: '\uD835\uDCB0',
    Vscr: '\uD835\uDCB1',
    Wscr: '\uD835\uDCB2',
    Xscr: '\uD835\uDCB3',
    Yscr: '\uD835\uDCB4',
    Zscr: '\uD835\uDCB5',
    ascr: '\uD835\uDCB6',
    bscr: '\uD835\uDCB7',
    cscr: '\uD835\uDCB8',
    dscr: '\uD835\uDCB9',
    escr: '\u212F',
    fscr: '\uD835\uDCBB',
    gscr: '\u210A',
    hscr: '\uD835\uDCBD',
    iscr: '\uD835\uDCBE',
    jscr: '\uD835\uDCBF',
    kscr: '\uD835\uDCC0',
    lscr: '\uD835\uDCC1',
    mscr: '\uD835\uDCC2',
    nscr: '\uD835\uDCC3',
    oscr: '\u2134',
    pscr: '\uD835\uDCC5',
    qscr: '\uD835\uDCC6',
    rscr: '\uD835\uDCC7',
    sscr: '\uD835\uDCC8',
    tscr: '\uD835\uDCC9',
    uscr: '\uD835\uDCCA',
    vscr: '\uD835\uDCCB',
    wscr: '\uD835\uDCCC',
    xscr: '\uD835\uDCCD',
    yscr: '\uD835\uDCCE',
    zscr: '\uD835\uDCCF'
}, 'scr');
