"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Entities = require("../Entities.js");
Entities.add({
    LJcy: '\u0409',
    LT: '\u003C',
    Lacute: '\u0139',
    Lang: '\u27EA',
    Laplacetrf: '\u2112',
    Lcaron: '\u013D',
    Lcedil: '\u013B',
    Lcy: '\u041B',
    LeftArrowBar: '\u21E4',
    LeftDoubleBracket: '\u27E6',
    LeftDownTeeVector: '\u2961',
    LeftDownVectorBar: '\u2959',
    LeftRightVector: '\u294E',
    LeftTeeArrow: '\u21A4',
    LeftTeeVector: '\u295A',
    LeftTriangleBar: '\u29CF',
    LeftUpDownVector: '\u2951',
    LeftUpTeeVector: '\u2960',
    LeftUpVectorBar: '\u2958',
    LeftVectorBar: '\u2952',
    LessLess: '\u2AA1',
    Lmidot: '\u013F',
    LowerLeftArrow: '\u2199',
    LowerRightArrow: '\u2198',
    Lstrok: '\u0141',
    Lt: '\u226A',
    lAarr: '\u21DA',
    lArr: '\u21D0',
    lAtail: '\u291B',
    lBarr: '\u290E',
    lE: '\u2266',
    lHar: '\u2962',
    lacute: '\u013A',
    laemptyv: '\u29B4',
    lagran: '\u2112',
    lang: '\u27E8',
    langd: '\u2991',
    langle: '\u27E8',
    laquo: '\u00AB',
    larr: '\u2190',
    larrb: '\u21E4',
    larrbfs: '\u291F',
    larrfs: '\u291D',
    larrhk: '\u21A9',
    larrpl: '\u2939',
    larrsim: '\u2973',
    lat: '\u2AAB',
    latail: '\u2919',
    late: '\u2AAD',
    lates: '\u2AAD\uFE00',
    lbarr: '\u290C',
    lbbrk: '\u2772',
    lbrke: '\u298B',
    lbrksld: '\u298F',
    lbrkslu: '\u298D',
    lcaron: '\u013E',
    lcedil: '\u013C',
    lceil: '\u2308',
    lcub: '\u007B',
    lcy: '\u043B',
    ldca: '\u2936',
    ldquo: '\u201C',
    ldquor: '\u201E',
    ldrdhar: '\u2967',
    ldrushar: '\u294B',
    ldsh: '\u21B2',
    leftarrow: '\u2190',
    leftarrowtail: '\u21A2',
    leftharpoondown: '\u21BD',
    leftharpoonup: '\u21BC',
    leftrightarrow: '\u2194',
    leftrightarrows: '\u21C6',
    leftrightharpoons: '\u21CB',
    leftrightsquigarrow: '\u21AD',
    leg: '\u22DA',
    leq: '\u2264',
    leqq: '\u2266',
    leqslant: '\u2A7D',
    les: '\u2A7D',
    lescc: '\u2AA8',
    lesdot: '\u2A7F',
    lesdoto: '\u2A81',
    lesdotor: '\u2A83',
    lesg: '\u22DA\uFE00',
    lesges: '\u2A93',
    lessapprox: '\u2A85',
    lesseqgtr: '\u22DA',
    lesseqqgtr: '\u2A8B',
    lessgtr: '\u2276',
    lesssim: '\u2272',
    lfisht: '\u297C',
    lfloor: '\u230A',
    lg: '\u2276',
    lgE: '\u2A91',
    lhard: '\u21BD',
    lharu: '\u21BC',
    lharul: '\u296A',
    lhblk: '\u2584',
    ljcy: '\u0459',
    ll: '\u226A',
    llarr: '\u21C7',
    llcorner: '\u231E',
    llhard: '\u296B',
    lltri: '\u25FA',
    lmidot: '\u0140',
    lmoustache: '\u23B0',
    lnapprox: '\u2A89',
    lneq: '\u2A87',
    lneqq: '\u2268',
    loang: '\u27EC',
    loarr: '\u21FD',
    lobrk: '\u27E6',
    longleftarrow: '\u27F5',
    longleftrightarrow: '\u27F7',
    longrightarrow: '\u27F6',
    looparrowleft: '\u21AB',
    lopar: '\u2985',
    loplus: '\u2A2D',
    lotimes: '\u2A34',
    lowbar: '\u005F',
    lozenge: '\u25CA',
    lozf: '\u29EB',
    lpar: '\u0028',
    lparlt: '\u2993',
    lrarr: '\u21C6',
    lrcorner: '\u231F',
    lrhar: '\u21CB',
    lrhard: '\u296D',
    lrm: '\u200E',
    lrtri: '\u22BF',
    lsaquo: '\u2039',
    lsh: '\u21B0',
    lsim: '\u2272',
    lsime: '\u2A8D',
    lsimg: '\u2A8F',
    lsqb: '\u005B',
    lsquo: '\u2018',
    lsquor: '\u201A',
    lstrok: '\u0142',
    ltcc: '\u2AA6',
    ltcir: '\u2A79',
    ltdot: '\u22D6',
    lthree: '\u22CB',
    ltlarr: '\u2976',
    ltquest: '\u2A7B',
    ltrPar: '\u2996',
    ltrie: '\u22B4',
    ltrif: '\u25C2',
    lurdshar: '\u294A',
    luruhar: '\u2966',
    lvertneqq: '\u2268\uFE00',
    lvnE: '\u2268\uFE00'
}, 'l');
